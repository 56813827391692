import { IRegion } from "../../types"

export enum Resolution {
  COUNTRY = 2,
  STATE = 3,
}

// NOTE: This is a hook in case we want to introduce a useMemo
export const useAssetModelRegions = (
  assetModelsRegions: IRegion[] | undefined,
) => {
  const countries: Record<string, IRegion> = {}
  const states: IRegion[] = []

  assetModelsRegions?.forEach((region) => {
    if (!region?.id) return

    if (region.resolution === Resolution.COUNTRY) {
      countries[region.id] = region
    } else if (region.resolution === Resolution.STATE) {
      states.push(region)
    }
  })

  // Append single states for state-less countries
  const statelessCountries = Object.values(countries).filter(
    (country) => !states.some((state) => state.parent_id === country.id),
  )
  statelessCountries.forEach((country) => {
    states.push({
      ...country,
      parent_id: country.id,
      resolution: Resolution.STATE,
    })
  })

  return {
    countries,
    states,
  }
}
