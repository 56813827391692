import { useTranslate } from "@tolgee/react"
import { gql } from "graphql-request"
import WidgetWrapper from "../../../../../climateui/components/Widgets/WidgetWrapper/WidgetWrapper"
import SeasonalCalendarWidget from "./SeasonalCalendarWidget"
import IWidgetDefaultProps from "./WidgetDefaultProps"

function CalendarWidgetWrapper(props: IWidgetDefaultProps) {
  const { id, onLoad, onMount, selectors, dashboardFilters } = props
  const { t } = useTranslate()

  return (
    <WidgetWrapper
      id={id}
      onLoad={onLoad}
      onMount={onMount}
      component={SeasonalCalendarWidget}
      selectedFilters={[
        "unit_system",
        "time_resolution",
        "risk_settings_ids",
        "location_ids",
        "category",
      ]}
      query={gql`
        query (
          $location_ids: [String]
          $risk_settings_ids: [String]
          $time_resolution: String
          $unit_system: String
          $category: String
        ) {
          alerts(
            filter: {
              location_ids: $location_ids
              risk_settings_ids: $risk_settings_ids
              time_resolution: $time_resolution
              category: $category
            }
          ) {
            results {
              processing_run {
                location {
                  name
                  id
                }
                risk_profile {
                  name
                  probability
                  type
                  hazard_profiles {
                    results {
                      hazard_variable {
                        readable_variable
                        dashboard_variable
                        readable_name
                        units(unit_system: $unit_system)
                        units_metric
                        units_imperial
                      }
                      type
                      conditional
                      threshold(unit_system: $unit_system)
                      window
                      logical_op
                    }
                  }
                  varieties {
                    results {
                      id
                    }
                  }
                }
                risk_setting_id
              }
              created_at
              updated_at
              start_date
              end_date
              duration
              min_risk_value
              max_risk_value
              avg_risk_value
              time_resolution
              category
              expected_magnitude
              expected_magnitude_list
              expected_percentile
              frequency
              last_occurrence
              trigger_threshold_list
            }
          }
        }
      `}
      selectors={{
        ...selectors,
        errorMessage: t("errorLoadingData"),
        reloadMessage: t("clickReload"),
        noResultsMessage: t("noResultCurrentFilters"),
        tryAnotherFilterMessage: t("tryAnotherFilter"),
      }}
      filters={dashboardFilters}
    />
  )
}

export default CalendarWidgetWrapper
