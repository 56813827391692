import { FC, ReactNode } from "react"

interface WrapperProps {
    children: ReactNode
}

export const Wrapper: FC<WrapperProps> = ({ children }) => {
    return (
        <div className="bg-light-bg dark:bg-dark-bg border border-gray-14 dark:border-gray-78 w-full h-full p-4 rounded-2xl">
            {children}
        </div>
    )
}
