// TODO: Convert this file to TypeScript
import http, { QuerySet } from "../../climateui/utils/http"
import config from "../config"

export const assetRegionModelGET = () =>
  http.get("/asset_region_model/", true, {}, config.yield_platform)

export const yieldOutlookQuerySet = new QuerySet(
  "/asset_region_model",
  ["get", "post", "put", "delete"],
  config.yield_platform,
)

export const yieldOutlookModelsGRAPHQL = (modelQuery, filters) =>
  http.post(process.env.REACT_APP_QUERY_URL + "/graphql", {
    query: modelQuery,
    variables: {
      ...filters,
      pagination: {
        all: true,
      },
    },
  })

export const seasonalStatsCropModelOutputSearchPOST = (data, _config = {}) =>
  http.post(
    "/seasonal_stats_crop_model_output/search/",
    data,
    true,
    _config,
    config.yield_platform,
  )

export const seasonalYieldOutlookLocationSearchPOST = (data, _config = {}) =>
  http.post(
    "/seasonal_yield_outlook_location/search/",
    data,
    true,
    _config,
    config.yield_platform,
  )

export const seasonalYieldOutlookLocationGRAPHQL = (filters) => {
  return http.post(process.env.REACT_APP_QUERY_URL + "/graphql", {
    query: `#graphql
            query($crop_model_ids: [String], $seasonal_status: String) {
                yield_locations(filter: { crop_model_ids: $crop_model_ids, seasonal_status: $seasonal_status }) {
                    results {
                        id
                        geo_id
                        location_id
                        location {
                            id
                            name
                            regions {
                                id
                                name
                                resolution
                            }
                        }
                        oldest_seasonal_date
                        newest_seasonal_date
                        model {
                            asset_id
                        }
                    }
                }
            }
            
        `,
    variables: {
      ...filters,
    },
  })
}
