import { useEffect, useState } from "react"
import { MONTH_INDEXES, MONTH_NAMES_TOLGEE } from "../../utils/constants"
import useOutsideComponentClickHandler from "../hooks/useOutsideComponentClickHandler"
import { ArrowBottom, CalendarIcon } from "../icons"
import { defaultInputClasses } from "./Inputs"

export const shadedMonthIndexes = (
    startMonthIndex: number | null,
    endMonthIndex: number | null
): number[] => {
    if (startMonthIndex === null || endMonthIndex === null) {
        if (startMonthIndex === null) {
            if (endMonthIndex === null) return []
            return [endMonthIndex]
        }
        return [startMonthIndex]
    }

    if (!MONTH_INDEXES.includes(startMonthIndex)) return []
    if (!MONTH_INDEXES.includes(endMonthIndex)) return [startMonthIndex]

    if (startMonthIndex <= endMonthIndex)
        return MONTH_INDEXES.slice(startMonthIndex, endMonthIndex + 1)
    return [
        ...MONTH_INDEXES.slice(startMonthIndex, 12),
        ...MONTH_INDEXES.slice(0, endMonthIndex + 1),
    ]
}

interface IMonthPickerButton {
    id: string
    open: boolean
    editingId: string
    placeholder: string
    handleClick: (id: string) => void
}

const MonthPickerButton = ({
    id,
    open,
    editingId,
    placeholder,
    handleClick,
}: IMonthPickerButton) => {
    return (
        <button
            className={[
                "flex flex-row items-center justify-between flex-1 gap-2",
                "h-[32px] rounded-md border bg-light-bg dark:bg-dark-bg px-2",
                "font-normal body-lg text-light-text dark:text-dark-text fill-gray-60",
                "transition-all duration-75",
                "hover:z-[1]",
                "focus:outline-none focus:ring focus:ring-accent",
                open && editingId === id
                    ? "border-accent"
                    : "border-gray-14 dark:border-gray-78 hover:border-gray-30",
            ].join(" ")}
            onClick={() => handleClick(id)}>
            {placeholder}
            <span className="w-5 h-5 fill-gray-60">
                <CalendarIcon />
            </span>
        </button>
    )
}

interface IMonthButton {
    month: string
    extraClasses: string
    handleClick: () => void
    handleFocus: () => void
}

const MonthButton = ({
    month,
    extraClasses,
    handleClick,
    handleFocus,
}: IMonthButton) => {
    return (
        <button
            key={"months-" + month}
            className={[
                "h-full w-full whitespace-nowrap body-md",
                "flex flex-row items-center justify-center relative",
                "z-20 focus:outline-accent",
                "cursor-pointer rounded-sm",
                extraClasses,
            ].join(" ")}
            onClick={handleClick}
            onFocus={handleFocus}
            onMouseEnter={handleFocus}>
            {month.slice(0, 3)}
        </button>
    )
}

interface IMonthRangePicker {
    startMonthIndex: number | null
    endMonthIndex: number | null
    startMonthPlaceholder?: string
    endMonthPlaceholder?: string
    popupPositionClass?: string
    handleChange: (indexes: (number | null)[]) => void
    MONTH_NAMES?: string[]
    prev?: string
    mid?: string
    after?: string
    compact?: boolean
}

const MonthRangePicker = ({
    startMonthIndex,
    endMonthIndex,
    startMonthPlaceholder = "",
    endMonthPlaceholder = "",
    popupPositionClass = "left-0",
    handleChange,
    MONTH_NAMES = MONTH_NAMES_TOLGEE,
    prev,
    mid,
    after,
    compact,
}: IMonthRangePicker) => {
    const [open, toggle] = useState(false)
    const [editing, setEditing] = useState("")
    const [hoverTill, setHoverTill] = useState<number | null>(null)
    const dropdownRef = useOutsideComponentClickHandler(
        () => open && toggle(false)
    )

    useEffect(() => {
        if (!open) setEditing("")
    }, [open])

    const edit = (startOrEnd: string) => {
        setHoverTill(null)
        if (editing === startOrEnd) {
            toggle(!open)
            return
        }
        setEditing(startOrEnd)
        toggle(true)
    }

    const isHoveredRange = (index: number) => {
        if (hoverTill === null) return false
        if (editing === "end") {
            if (startMonthIndex === null) return false
            return shadedMonthIndexes(startMonthIndex, hoverTill).includes(
                index
            )
        }
        if (editing === "start") {
            if (endMonthIndex === null) return false
            return shadedMonthIndexes(hoverTill, endMonthIndex).includes(index)
        }
    }

    const getBGClasses = (index: number) => {
        const isActive =
            shadedMonthIndexes(startMonthIndex, endMonthIndex).includes(
                index
            ) &&
            index !== startMonthIndex &&
            index !== endMonthIndex
        const isPossible =
            isHoveredRange(index) &&
            index !== startMonthIndex &&
            index !== endMonthIndex

        if (hoverTill === null) {
            if (index === startMonthIndex || index === endMonthIndex)
                return "bg-accent text-white"
            if (isActive)
                return "bg-accent-light text-gray-60 dark:text-gray-30"
            return "hover:bg-gray-5 dark:hover:bg-gray-78 text-gray-60 dark:text-gray-30"
        }
        if (
            (editing === "start" && index === endMonthIndex) ||
            (editing === "end" && index === startMonthIndex)
        ) {
            return "bg-accent text-white hover:bg-accent-dark"
        }
        if (
            (index === startMonthIndex || index === endMonthIndex) &&
            startMonthIndex !== null &&
            endMonthIndex !== null
        ) {
            if (editing === "start") {
                if (startMonthIndex < endMonthIndex) {
                    if (hoverTill <= startMonthIndex)
                        return "bg-accent-light text-gray-60 dark:text-gray-30 hover:bg-accent-medium"
                    return "bg-gray-5 hover:bg-gray-10 text-gray-30"
                }
                if (hoverTill <= startMonthIndex && hoverTill > endMonthIndex)
                    return "bg-accent-light text-gray-60 dark:text-gray-30 hover:bg-accent-medium"
                return "bg-gray-5 hover:bg-gray-10 text-gray-30"
            } else if (editing === "end") {
                if (startMonthIndex < endMonthIndex) {
                    if (hoverTill >= endMonthIndex)
                        return "bg-accent-light text-gray-60 dark:text-gray-30 hover:bg-accent-medium"
                    return "bg-gray-5 hover:bg-gray-10 text-gray-30"
                }
                if (hoverTill >= endMonthIndex && hoverTill < startMonthIndex)
                    return "bg-accent-light text-gray-60 dark:text-gray-30 hover:bg-accent-medium"
                return "bg-gray-5 hover:bg-gray-10 text-gray-30"
            }
        }
        if (isActive && isPossible)
            return "bg-accent-light text-gray-60 dark:text-gray-30 hover:bg-accent-medium"
        if (isActive) return "bg-gray-5 text-gray-30"
        if (isPossible)
            return "bg-gray-5 hover:bg-gray-10 text-gray-60 dark:text-gray-30"
        return "hover:bg-gray-5 dark:hover:bg-gray-78 text-gray-60 dark:text-gray-30"
    }

    return (
        <div className="flex flex-row items-center gap-1 body-md">
            {prev && <p>{prev}</p>}
            <div
                className="relative min-w-0 select-none w-fit"
                ref={dropdownRef}>
                {compact ? (
                    <button
                        onClick={() => edit("start")}
                        className={[
                            "ddBox",
                            "flex flex-row items-center justify-start",
                            "rounded-md",
                            "whitespace-nowrap",
                            ...defaultInputClasses,
                        ].join(" ")}>
                        <p className="inline">
                            <span
                                className={
                                    editing === "start" ? "font-bold" : ""
                                }>
                                {startMonthPlaceholder}
                            </span>
                            {" - "}
                            <span
                                className={
                                    editing === "end" ? "font-bold" : ""
                                }>
                                {endMonthPlaceholder}
                            </span>
                        </p>
                        <span className="w-6 h-6 -mr-1">
                            <ArrowBottom />
                        </span>
                    </button>
                ) : (
                    <div className="w-64 flex flex-row items-center gap-2">
                        <MonthPickerButton
                            id="start"
                            placeholder={startMonthPlaceholder}
                            handleClick={edit}
                            open={open}
                            editingId={editing}
                        />
                        {mid && <p>{mid}</p>}
                        <MonthPickerButton
                            id="end"
                            placeholder={endMonthPlaceholder}
                            handleClick={edit}
                            open={open}
                            editingId={editing}
                        />
                    </div>
                )}
                {open && (
                    <div
                        className={[
                            "absolute top-full",
                            popupPositionClass,
                            "bg-light-bg dark:bg-dark-bg z-30 rounded-lg elevation-2",
                            "h-40 w-64",
                            "border border-gray-14 dark:border-gray-78",
                        ].join(" ")}
                        onMouseLeave={() => setHoverTill(null)}>
                        <div className="w-full h-full grid gap-2 grid-cols-4 grid-rows-3 col-auto p-3">
                            {MONTH_NAMES.map((month, index) => (
                                <MonthButton
                                    key={"month-button-" + month}
                                    month={month}
                                    extraClasses={getBGClasses(index)}
                                    handleClick={() => {
                                        let indexes = []
                                        if (editing === "start") {
                                            if (index === startMonthIndex) {
                                                indexes = [null, endMonthIndex]
                                            } else {
                                                indexes = [index, endMonthIndex]
                                            }
                                        } else {
                                            if (index === endMonthIndex) {
                                                indexes = [
                                                    startMonthIndex,
                                                    null,
                                                ]
                                            } else {
                                                indexes = [
                                                    startMonthIndex,
                                                    index,
                                                ]
                                            }
                                        }
                                        handleChange(indexes)

                                        if (indexes.includes(null)) return

                                        if (editing === "end") toggle(false)
                                        else setEditing("end")
                                    }}
                                    handleFocus={() => setHoverTill(index)}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
            {after && <p>{after}</p>}
        </div>
    )
}

export default MonthRangePicker
