import { Customized } from "recharts"
import { Stage } from "../types"
import { DateTime } from "luxon"
import { getStageConfig } from "../riskOutlookUtils"
import TooltipV2 from "../../../../climateui/components/TooltipV2"

export function convertYearAgnosticDatesToAbsoluteDates(
  stages: Stage[],
  timeWindowStart: DateTime,
  timeWindowEnd: DateTime,
) {
  type ExpandedStage = Stage & {
    startDate: DateTime
    endDate: DateTime
    startDateStr: string
    endDateStr: string
  }

  const expandedStages: ExpandedStage[] = []

  // Calculate how many years we need to cover, and start one year earlier to catch stages that might cross the start
  const yearDiff = timeWindowEnd.year - timeWindowStart.year + 2
  const startYear = timeWindowStart.year - 1

  stages.forEach((stage) => {
    // For each year in the range, create a stage instance
    for (let yearOffset = 0; yearOffset < yearDiff; yearOffset++) {
      const startDate = DateTime.fromFormat(stage.start_date, "MM-dd")
        .startOf("day")
        .set({ year: startYear + yearOffset })
      let endDate = DateTime.fromFormat(stage.end_date, "MM-dd")
        .startOf("day")
        .set({ year: startYear + yearOffset })

      if (startDate > endDate) {
        // Stage crosses year boundary (e.g. Dec 15 - Jan 15)
        endDate = endDate.plus({ years: 1 })
      }

      // Only add the stage if it overlaps with our time window
      if (
        (startDate <= timeWindowEnd && endDate >= timeWindowStart) ||
        (startDate >= timeWindowStart && startDate <= timeWindowEnd) ||
        (endDate >= timeWindowStart && endDate <= timeWindowEnd)
      ) {
        expandedStages.push({
          ...stage,
          startDate,
          endDate,
          startDateStr: startDate.toFormat("yyyy MM dd"),
          endDateStr: endDate.toFormat("yyyy MM dd"),
        })
      }
    }
  })

  // Sort all stages by start date
  return expandedStages.sort((a, b) => {
    if (a.startDate < b.startDate) return -1
    else if (a.startDate > b.startDate) return 1
    else return 0
  })
}

// Helper function to calculate text width using canvas
const calculateTextWidth = (text: string, fontSize: number): number => {
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")
  if (!context) return 0

  context.font = `${fontSize}px Roboto` // Match your font family
  return context.measureText(text).width
}

// Function to truncate text to fit width
const truncateText = (
  text: string,
  maxWidth: number,
  fontSize: number,
): string => {
  const ellipsis = "..."
  const ellipsisWidth = calculateTextWidth(ellipsis, fontSize)

  // If even ellipsis won't fit, return empty string
  if (maxWidth < ellipsisWidth) return ""

  // If text fits, return it as is
  if (calculateTextWidth(text, fontSize) <= maxWidth) return text

  // Binary search for the maximum number of characters that fit
  let low = 0
  let high = text.length

  while (low < high - 1) {
    const mid = Math.floor((low + high) / 2)
    const truncated = text.slice(0, mid) + ellipsis
    if (calculateTextWidth(truncated, fontSize) <= maxWidth) {
      low = mid
    } else {
      high = mid
    }
  }

  return text.slice(0, low) + ellipsis
}

const renderRiskChartStages = (stages: Stage[], key?: string) => {
  return (
    <Customized
      component={({ xAxisMap, yAxisMap }: any) => {
        const xMin = xAxisMap[0].domain[0]
        const xMax = xAxisMap[0].domain[xAxisMap[0].domain.length - 1]

        const xMinDate = DateTime.fromFormat(xMin, "dd-MM-yyyy")
        const xMaxDate = DateTime.fromFormat(xMax, "dd-MM-yyyy")

        const contextualizedStages = convertYearAgnosticDatesToAbsoluteDates(
          stages,
          xMinDate,
          xMaxDate,
        )

        const filteredStages = contextualizedStages.filter((stage) => {
          return (
            (stage.startDate <= xMaxDate && stage.endDate >= xMinDate) ||
            (stage.startDate >= xMinDate && stage.startDate <= xMaxDate) ||
            (stage.endDate >= xMinDate && stage.endDate <= xMaxDate)
          )
        })

        const cleanedStages = filteredStages.map((stage) => ({
          ...stage,
          startDate: stage.startDate < xMinDate ? xMinDate : stage.startDate,
          endDate: stage.endDate > xMaxDate ? xMaxDate : stage.endDate,
          startDateStr: (stage.startDate < xMinDate
            ? xMinDate
            : stage.startDate
          ).toFormat("yyyy MM dd"),
          endDateStr: (stage.endDate > xMaxDate
            ? xMaxDate
            : stage.endDate
          ).toFormat("yyyy MM dd"),
        }))

        return (
          <g key="stages-render">
            {cleanedStages.map((stage) => {
              const startX = xAxisMap[0].scale(
                stage.startDate.toFormat("dd-MM-yyyy"),
              )
              const endX = xAxisMap[0].scale(
                stage.endDate.toFormat("dd-MM-yyyy"),
              )
              const y = yAxisMap[0].scale(0)
              const stageLabel = getStageConfig(stage.stage_name)?.label || ""

              // Calculate available width for text with padding
              const availableWidth = Math.max(endX - startX - 10, 30) // minimum 30px, with 5px padding on each side
              const fontSize = 12 // Fixed font size

              // Truncate text to fit available width
              const displayText = truncateText(
                stageLabel,
                availableWidth,
                fontSize,
              )

              return (
                <g key={`${stage.stage_name}_${key}`}>
                  <line
                    x1={startX}
                    x2={endX}
                    y1={y + 50}
                    y2={y + 50}
                    stroke={getStageConfig(stage.stage_name)?.color}
                    strokeWidth={4}
                  />
                  <foreignObject
                    x={(startX + endX) / 2 - availableWidth / 2}
                    y={y + 55}
                    width={availableWidth}
                    height={30}
                    style={{ overflow: "visible", pointerEvents: "none" }}>
                    <div
                      style={{
                        width: `${availableWidth}px`,
                        height: "100%",
                        pointerEvents: "all",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <TooltipV2
                        content={stageLabel}
                        position="top"
                        align="center">
                        <p
                          style={{
                            width: `${availableWidth}px`,
                            textAlign: "center",
                            cursor: "pointer",
                            fontSize: `${fontSize}px`,
                            lineHeight: "30px",
                            pointerEvents: "all",
                            margin: 0,
                            padding: 0,
                          }}
                          className="text-gray-90 dark:text-white">
                          {displayText}
                        </p>
                      </TooltipV2>
                    </div>
                  </foreignObject>
                </g>
              )
            })}
          </g>
        )
      }}
    />
  )
}

export default renderRiskChartStages
