import { useTranslate } from "@tolgee/react"
import { Dispatch, SetStateAction, useState } from "react"
import FixedLayer from "../../../../climateui/components/FixedLayer"
import { useOutsideComponentClickHandler } from "../../../../climateui/hooks"
import { ArrowBottom, CancelIcon } from "../../../../climateui/icons"
import PercentageInput from "../../../../components/PercentageInput"

const AlertProbabilityFilter = ({
  probability,
  setProbability,
}: {
  probability: number | undefined
  setProbability: Dispatch<SetStateAction<number | undefined>>
}) => {
  const { t } = useTranslate()
  const [open, setOpen] = useState<boolean>(false)
  const modalRef = useOutsideComponentClickHandler(() => setOpen(false))
  const formatter = Intl.NumberFormat(undefined, {
    maximumFractionDigits: 0,
  })

  const computeClasses = () => {
    let classString = ""
    if (probability && probability > 0) {
      classString = "fill-accent bg-accent-light text-accent"
      if (!open) classString += " border-accent-medium hover:border-accent"
    } else {
      classString = "text-gray-60 dark:text-gray-30 fill-gray-60"
      if (!open)
        classString +=
          " border-gray-14 dark:border-gray-78 hover:enabled:border-gray-30"
    }
    return classString
  }
  const formattedProb = probability
    ? formatter.format(probability * 100)
    : undefined
  return (
    <div
      ref={modalRef}
      className="relative">
      <div
        onClick={() => setOpen(!open)}
        className={[
          "bg-light-bg dark:bg-dark-bg flex flex-row items-center",
          "transition-all duration-75",
          "h-[32px] min-w-[60px] pl-[8px] pr-[2px] w-fit max-w-[200px]",
          "border-[1px] rounded-sm",
          "cursor-pointer disabled:cursor-not-allowed",
          "focus:outline-accent",
          "disabled:bg-gray-5 dark:disabled:bg-gray-60 disabled:text-gray-30 disabled:fill-gray-30",
          "text-left label-lg",
          open ? "border-accent" : "",
          computeClasses(),
        ].join(" ")}>
        <span>
          {!formattedProb
            ? t("probability", "Probability")
            : t("probabilityAbbr", "Prob.")}
        </span>
        {formattedProb ? (
          <div className="min-w-[20px] flex flex-nowrap items-center">
            <span className="font-medium">&nbsp;≥{formattedProb}% </span>
            <span
              className="w-5 h-5 hover:fill-accent fill-accent-medium"
              onClick={() => setProbability(undefined)}>
              <CancelIcon />
            </span>
          </div>
        ) : (
          <span className="w-[20px]">
            <ArrowBottom />
          </span>
        )}
      </div>
      {open && (
        <FixedLayer
          yPosition="bottom"
          xPosition="left">
          <div
            onKeyUp={(e) => {
              if (e.key === "Enter") setOpen(false)
            }}
            className="flex items-center justify-center min-w-[264px] py-3 px-4 bg-light-bg dark:bg-dark-bg shadow-md grow rounded-md border border-gray-14 dark:border-gray-78">
            <PercentageInput
              label={t("probability", "Probability") + " (≥)"}
              percentage={probability}
              setPercentage={setProbability}
            />
          </div>
        </FixedLayer>
      )}
    </div>
  )
}

export default AlertProbabilityFilter
