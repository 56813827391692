import { useTranslate } from "@tolgee/react"
import { MouseEventHandler, useState } from "react"
import { FixedElement } from "../../../../climateui/components"
import ImpactGraph from "./ImpactGraph"
import ImpactIcon from "./ImpactIcon"

interface IGraphLabel {
  xAxis: string
  yAxis: string
  mainSeries: string
  secondarySeries?: string
}
const useChartLabels = (_image: string) => {
  const { t } = useTranslate()
  const image = _image.replace(/\.\w{2,3}$/, "")
  return (
    (
      {
        OneTimeConsecutive: {
          xAxis: t("consecutiveDays", "Consecutive Days"),
          yAxis: t("impact", "Impact"),
          mainSeries: t("oneTimeImpact", "One Time Impact"),
        },
        ConstantConsecutive: {
          xAxis: t("consecutiveDays", "Consecutive Days"),
          yAxis: t("impact", "Impact"),
          mainSeries: t("initialImpact", "Initial Impact"),
        },
        OneTimeRolling: {
          xAxis: t("totalSum", "Total Sum"),
          yAxis: t("impact", "Impact"),
          mainSeries: t("oneTimeImpact", "One Time Impact"),
        },
        ConstantRolling: {
          xAxis: t("sumPerTimeWindow", "Sum per time window"),
          yAxis: t("impact", "Impact"),
          mainSeries: t("initialImpact", "Initial Impact"),
        },
        ConstantSurplus: {
          xAxis: t("surplusPerTimeWindow", "Surplus per time window"),
          yAxis: t("impact", "Impact"),
          mainSeries: t("initialImpact", "Initial Impact"),
          secondarySeries: t("impactPerSurplus", "Impact per surplus"),
        },
      } as Record<string, IGraphLabel>
    )[image] ?? {
      xAxis: "x",
      yAxis: "y",
      mainSeries: t("impact", "Impact"),
    }
  )
}

const ImpactOption = ({
  readableName,
  icon,
  image,
  key,
  selected,
  disabled,
  onClick,
}: {
  readableName: string
  aggregation?: string
  icon: string
  image: string
  key?: string | number
  selected?: boolean
  disabled?: boolean
  onClick?: MouseEventHandler
}) => {
  const { xAxis, yAxis, mainSeries, secondarySeries } = useChartLabels(image)
  const [hovering, setHovering] = useState(false)
  return (
    <div
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}>
      <FixedElement
        open={hovering}
        position="top"
        align="left"
        parentElement={
          <div
            key={key}
            onClick={onClick && !disabled ? onClick : undefined}
            className={[
              "flex flex-row items-center justify-center",
              "p-1 w-14 h-8",
              "border border-gray-14 dark:border-gray-78 rounded-sm",
              disabled
                ? "cursor-not-allowed border-gray-30 bg-gray-10 opacity-50"
                : "cursor-pointer",
              selected ? "border-accent bg-accent-light text-accent" : "",
              !disabled &&
                "hover:border-accent-medium hover:bg-accent-light hover:text-accent",
            ].join(" ")}>
            <ImpactIcon icon={icon} />
          </div>
        }>
        <div className="absolute w-48 h-48 p-3 shadow-md z-50 bg-light-bg dark:bg-dark-bg rounded-md flex flex-col -translate-y-48">
          <div className="p-1 mb-1 text-xs font-semibold text-light-text dark:text-dark-text grow-0">
            {readableName}
          </div>
          <div className="flex flex-row items-center h-full">
            <div className="flex items-end justify-center w-4 h-full grow-0">
              <div className="py-1 text-[0.65rem] w-inherit h-inherit text-gray-60 dark:text-gray-30 -rotate-90 -translate-y-12">
                {yAxis}
              </div>
            </div>
            <div className="flex flex-col h-full grow">
              <div className="w-full h-full">
                <ImpactGraph graph={image} />
              </div>
              <div className="py-1 text-[0.65rem] text-gray-60 dark:text-gray-30 grow-0">
                {xAxis}
              </div>
            </div>
            <div className="p-1 flex flex-col justify-start w-24 text-[.5rem] grow-0 h-full">
              <div className="mb-3 text-red">{mainSeries}</div>
              {secondarySeries && (
                <div className="text-violet">{secondarySeries}</div>
              )}
            </div>
          </div>
        </div>
      </FixedElement>
    </div>
  )
}

export default ImpactOption
