import { useTranslate } from "@tolgee/react"
import { Dispatch, SetStateAction, useContext } from "react"
import { Button } from "../../../../climateui/components"
import { useOutsideComponentClickHandler } from "../../../../climateui/hooks"
import { ToastContext } from "../../../../climateui/providers"
import EmptyModal from "../../../../climateui/providers/Modal/EmptyModal"
import { useAssets } from "../../../../providers"
import { IVariety } from "../../../../types"

const DeleteVarietyModal = ({
  variety,
  isOpen,
  setIsOpen,
  afterDeleteCallback = () => null,
}: {
  variety: IVariety
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  afterDeleteCallback?: () => void
}) => {
  /* HOOKS > START */
  const { t } = useTranslate()
  const modalRef = useOutsideComponentClickHandler(() => setIsOpen(false))
  const { deleteVariety } = useAssets()
  const { enqueueAlert } = useContext(ToastContext)
  /* HOOKS < END */

  /* METHODS > START */
  const save = async () => {
    await deleteVariety(variety.id)
    enqueueAlert(t("varietyDeleted", "Variety deleted successfully"))
    setIsOpen(false)
    afterDeleteCallback()
  }
  /* METHODS < END */
  return (
    <EmptyModal
      customClasses="relative rounded-lg bg-light-bg dark:bg-dark-bg w-[80vw] sm:w-[50vw] md:w-[40vw] xl:w-[30vw] h-fit"
      modalRef={modalRef}
      open={isOpen}>
      <div className="flex flex-col p-5 gap-4">
        {/* HEADER */}
        <div className="flex">
          <h1 className="title-lg">
            {t(
              "areYouSureDeleteAsset",
              "Are you sure you want to delete this asset?",
            )}
          </h1>
        </div>
        {/* BODY */}
        <p className="font-normal">
          {t("thisActionCantBeUndone", "This action can't be undone")}
        </p>
        {/* FOOTER */}
        <div className="flex gap-2">
          <Button
            type="secondary"
            onClick={() => setIsOpen(false)}
            extend={true}
            label={t("cancel", "Cancel")}
          />
          <Button
            onClick={save}
            extend={true}
            label={t("continue", "Continue")}
          />
        </div>
      </div>
    </EmptyModal>
  )
}

export default DeleteVarietyModal
