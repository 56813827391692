import { IPrimitivesDictionary } from "../climateui/types"
import { CustomResponse, isValidResponse } from "../climateui/utils/http"
import { AxiosError } from "axios"
import { ITimeSeries } from "../types"
import { DateTime } from "luxon"

export const dataOrFallback = <T>(
  response: CustomResponse | AxiosError | null | undefined,
  fallback: T,
) => {
  if (!isValidResponse(response)) return fallback
  return response.data.data || response.data
}
export const arrToDict = <T>(arr: T[], keyProp: string): Record<string, T> =>
  arr.reduce((prev: Record<string, T>, curr: T) => {
    const key = (curr as unknown as IPrimitivesDictionary)[keyProp]
    if (key) return { ...prev, [key as string]: curr }
    return prev
  }, {})

export const arrToDictWithCompoundKey = <T>(
  arr: T[],
  keyProps: string[],
): Record<string, T> =>
  arr.reduce((prev: Record<string, T>, curr: T) => {
    const keys = keyProps.map(
      (prop) => (curr as unknown as IPrimitivesDictionary)[prop],
    )
    if (keys.every((key) => key !== undefined)) {
      const compoundKey = keys.join("-")
      return { ...prev, [compoundKey]: curr }
    }
    return prev
  }, {})

export const arrToRank = <T>(
  arr: T[],
  keyProp: string,
): Record<string, number> =>
  arr.reduce((prev: Record<string, number>, curr: T, idx: number) => {
    const key = (curr as unknown as IPrimitivesDictionary)[keyProp] as string
    if (!key) return prev
    return { ...prev, [key]: idx }
  }, {})

export function aggregateDailyToWeekly(timeSeries: ITimeSeries) {
  const result: ITimeSeries = {
    data: [],
    time: [],
    duration: [],
  }
  let accum = 0
  let weekDate = undefined
  let duration = 0

  for (let i = 0; i < timeSeries.data.length; i++) {
    duration++
    accum += timeSeries.data[i]
    const date = new Date(timeSeries.time[i])
    if (weekDate === undefined) weekDate = date
    if (date.getDay() === 1) {
      weekDate = date
    }

    if (date.getDay() === 0) {
      result.data.push(accum)
      accum = 0
      result.duration?.push(duration)
      duration = 0
      result.time.push(weekDate)
    }
  }
  if (accum !== 0 && weekDate) {
    result.data.push(accum)
    result.duration?.push(duration)
    result.time.push(weekDate)
  }

  return result
}

export function formatDate(date: Date | string | DateTime): string {
  let formattedDate: DateTime
  if (typeof date === "string") {
    formattedDate = DateTime.fromJSDate(new Date(date))
  } else if (date instanceof Date) {
    formattedDate = DateTime.fromJSDate(date)
  } else {
    formattedDate = date
  }
  const year = formattedDate.year
  const month = formattedDate.month.toString().padStart(2, "0")
  const day = formattedDate.day.toString().padStart(2, "0")

  if (typeof date === "object" && "toISODate" in date && "toISOTime" in date) {
    const time = date.toISOTime().slice(0, 5)
    return `${year}/${month}/${day} ${time}`
  }
  return `${year}/${month}/${day}`
}

// As seen on: https://sentry.io/answers/map-function-for-objects-instead-of-arrays/
// 2023-09-29
export const objectMap = <T>(obj: Record<string, T>, fn: (value: T) => T) =>
  Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, fn(v)]))

export const getLargestTercile = (terciles?: Record<string, number>) => {
  if (!terciles) return [undefined, undefined]
  return Object.values(terciles).reduce(
    (maxValue, tercile, idx) => {
      if (tercile > maxValue[1]) return [idx - 1, tercile]
      return maxValue
    },
    [Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER],
  )
}

export const getDirectionOutlook = (terciles?: Record<string, number>) => {
  if (!terciles) return -2

  if (terciles["prob_0.00-0.33"] >= 0.5) {
    return -1
  }
  if (terciles["prob_0.33-0.67"] >= 0.5) {
    return 0
  }
  if (terciles["prob_0.67-1.00"] >= 0.5) {
    return 1
  }

  return -2
}

function roundToDecimal(number: number) {
  return Math.round(number * 10) / 10
}

export const getIsMeanInMass = (
  direction: number,
  mean: number,
  historical: Record<string, Array<number>>,
) => {
  // Possible Directions are -1, 0 and 1.
  // If -1 then mean < yield_terciles[0],
  // if 0 then yield_terciles [0] < mean < yield_terciles[1],
  // if 1 then mean > yield_terciles[1]

  const yield_terciles = historical.yield_terciles
  mean = roundToDecimal(mean)
  switch (direction) {
    case -1:
      return mean <= roundToDecimal(yield_terciles[0]) ? direction : -2
    case 0:
      return roundToDecimal(yield_terciles[0]) < mean &&
        mean < roundToDecimal(yield_terciles[1])
        ? direction
        : -2
    case 1:
      return mean >= roundToDecimal(yield_terciles[1]) ? direction : -2
  }

  return -2
}
