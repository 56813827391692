import { useTranslate } from "@tolgee/react"
import _ from "lodash"
import { useQueryClient } from "react-query"
import { ILabel } from "../../../../climateui/types"
import { isValidResponse } from "../../../../climateui/utils/http"
import { BulkUpload } from "../../../../components"
import {
  DUPLICATED,
  ERROR,
  UPLOADED,
} from "../../../../components/BulkUpload/consts/cellStatus"
import { IValueError } from "../../../../components/BulkUpload/types"
import { useToInt, useToFloat } from "../../../../components/BulkUpload/utils"
import useAssetsQuery from "../../../../hooks/useAssetsQuery"
import useVarietiesQuery from "../../../../hooks/useVarietiesQuery"
import { useAccount } from "../../../../providers/AccountProvider"
import {
  IAsset,
  IHazardVariable,
  IImpactFunction,
  RiskProfileInput,
  IVariety,
} from "../../../../types"
import {
  assetQuerySet,
  hazardVariableQuerySet,
  impactFunctionQuerySet,
  riskProfileQuerySet,
  rpLabelsQuerySet,
} from "../../../../utils/networking"

const LABEL_COLORS = [
  "var(--color-primary)",
  "#1C9690",
  "#2187C1",
  "#8E3FF3",
  "#464B86",
  "#55DDE0",
  "#E43F6F",
  "#FF99C8",
  "#C9E4CA",
  "#FFCF00",
]

const pendingLabels: Record<string, Promise<string>> = {}
const normalizeLabel = (rawLabel: string) => {
  return rawLabel
    .trim() // Remove any trailing or leading whitespace
    .replace(" ", "_") // Replace <space> for lowercase
    .toLowerCase() // Set the label to lowercase
}

const RiskProfileBulkUpload = () => {
  const { selectedAccount } = useAccount()
  const { t } = useTranslate()
  const toFloat = useToFloat()
  const toInt = useToInt()
  const queryClient = useQueryClient()

  const { data: assets } = useAssetsQuery(selectedAccount)
  const { data: varieties } = useVarietiesQuery(assets)

  return (
    <>
      {selectedAccount && (
        <BulkUpload<RiskProfileInput>
          onParse={(items) =>
            Promise.all(
              items.map(async ([item, updateStatus]) => {
                // If undefined, register that as an error
                if (!item) return updateStatus(ERROR)
                const creationResponse = await riskProfileQuerySet.post(
                  "",
                  item,
                )
                if (!isValidResponse(creationResponse)) {
                  switch (creationResponse?.response?.status) {
                    case 409:
                      return updateStatus(DUPLICATED)
                    case 500:
                    default:
                      return updateStatus(ERROR)
                  }
                }
                return updateStatus(UPLOADED)
              }),
            ).then(() => {
              queryClient.invalidateQueries(["riskProfiles"])
            })
          }
          columns={{
            account_id: selectedAccount,
            name: {
              headerName: "Name",
            },
            probability: {
              headerName: "Probability",
              toValue: async (prob) => toFloat(prob, { isPercentage: true }),
            },
            hazard_profiles: {
              nestedDelimiter: ";",
              hazard_variable_id: {
                headerName: "Variable",
                prefetch: async () => {
                  const res = await hazardVariableQuerySet.get("")
                  if (!isValidResponse(res)) return
                  // Return hazards indexed by name
                  return (res.data as IHazardVariable[]).reduce(
                    (prev: Record<string, IHazardVariable>, curr) => {
                      const name = curr.readable_name.trim().toLowerCase()
                      return { ...prev, [name]: curr }
                    },
                    {},
                  )
                },
                toValue: async (variableName, data) => {
                  const hazards = data as Record<string, IHazardVariable>
                  if (!hazards)
                    return {
                      code: 500,
                      message: t(
                        "couldntRetrieveHazards",
                        "Couldn't retrieve hazards",
                      ),
                    }
                  const hazard = hazards[variableName.trim().toLowerCase()]
                  if (!hazard)
                    return {
                      code: 400,
                      message: t(
                        "unsupportedXHazard",
                        'Variable "{variableName}" is not supported',
                        { variableName },
                      ),
                    }
                  return hazard.id
                },
              },
              conditional: {
                headerName: "Risk Condition",
                toValue: async (operator) => {
                  const lcOperator = operator.trim().toLowerCase()
                  switch (lcOperator) {
                    case "less":
                    case "less than":
                    case "<":
                      return "<"
                    case "less or equal":
                    case "less than or equal":
                    case "less than or equal to":
                    case "<=":
                      return "<="
                    case "greater":
                    case "greater than":
                    case ">":
                      return ">"
                    case "greater or equal":
                    case "greater than or equal":
                    case "greater than or equal to":
                    case ">=":
                      return ">="
                    case "equal":
                    case "equal to":
                    case "=":
                      return "="
                    default:
                      return {
                        code: 400,
                        message: t(
                          "operatorNotSupported",
                          "Conditional operator not supported",
                        ),
                      }
                  }
                },
              },
              type: {
                headerName: "Type",
                toValue: async (riskType) => riskType.trim().toLowerCase(),
              },
              threshold: {
                headerName: "Threshold",
                toValue: async (threshold) => toFloat(threshold),
              },
              window: {
                headerName: "Days",
                toValue: async (window) => toInt(window),
              },
              logical_op: "start",
              aggregation: "consecutive",
            },
            impact_profile: {
              impact_function_id: {
                headerName: "Impact Function Name",
                prefetch: async () => {
                  const res = await impactFunctionQuerySet.get("")
                  if (!isValidResponse(res)) return
                  return (res.data as IImpactFunction[]).reduce(
                    (prev: Record<string, IImpactFunction>, curr) => {
                      const name = curr.readable_name.trim().toLowerCase()
                      return { ...prev, [name]: curr }
                    },
                    {},
                  )
                },
                toValue: async (impactName, data) => {
                  const impactFns = data as Record<string, IImpactFunction>
                  if (!impactFns)
                    return {
                      code: 500,
                      message: t(
                        "couldntRetrieveImpactFns",
                        "Couldn't retrieve impact functions",
                      ),
                    }
                  const impactFn = impactFns[impactName.trim().toLowerCase()]
                  if (!impactFn)
                    return {
                      code: 400,
                      message: t(
                        "unsupportedXFunction",
                        'Function "{impactName}" is not supported',
                        { impactName },
                      ),
                    }
                  return impactFn.id
                },
              },
              initial_impact: {
                headerName: "Initial Impact",
                toValue: async (initialImpact) =>
                  toFloat(initialImpact, {
                    isPercentage: true,
                  }),
              },
              marginal_impact: {
                headerName: "Marginal Impact",
                toValue: async (marginalImpact) =>
                  toFloat(marginalImpact, {
                    isPercentage: true,
                  }),
              },
              max_impact: {
                headerName: "Max Impact",
                toValue: async (maxImpact) =>
                  toFloat(maxImpact, { isPercentage: true }),
              },
            },
            labels: {
              headerName: "Labels (optional)",
              optional: true,
              prefetch: async () => {
                const res = await rpLabelsQuerySet.get("")
                if (!isValidResponse(res)) return
                return (res.data as ILabel[]).reduce(
                  (prev: Record<string, ILabel>, curr) => {
                    return {
                      ...prev,
                      [curr.name.toLowerCase()]: curr,
                    }
                  },
                  {},
                )
              },
              toValue: async (rawLabels, data) => {
                const existingLabels = data as Record<string, ILabel>
                if (!existingLabels)
                  return {
                    code: 500,
                    message: t(
                      "couldntRetrieveLabels",
                      "Couldn't retrieve labels from the server",
                    ),
                  }
                const labelNames = new Set(
                  rawLabels.trim().split(",").map(normalizeLabel),
                ) // Raw labels
                const labelIDs: string[] = [] // Existing labels
                const rowPendingLabels: string[] = [] // New labels

                // Filter into separate arrays existing and non-existing labels
                labelNames.forEach((labelName) => {
                  // Skip empty/undefined labels
                  if (!labelName) return
                  // Determine if the label already exists in our db
                  const existingLabel = existingLabels[labelName]
                  if (existingLabel?.id) {
                    // Add the label to the list of future labels
                    labelIDs.push(existingLabel.id)
                  } else {
                    // Add a promise to create this label if it doesn't exist yet
                    if (pendingLabels[labelName] === undefined) {
                      pendingLabels[labelName] = rpLabelsQuerySet
                        .post("", {
                          name: labelName,
                          color: _.sample(LABEL_COLORS),
                          account_id: selectedAccount,
                        })
                        .then((response) => {
                          if (!isValidResponse(response)) return
                          return response.data[0].id
                        })
                    }
                    // Add the label to the pending ones for the row
                    rowPendingLabels.push(labelName)
                  }
                })

                // For the remaining labels, create them
                const newLabels = await Promise.all(
                  rowPendingLabels.map((labelName) => pendingLabels[labelName]),
                )
                return [...newLabels, ...labelIDs]
              },
            },
            varieties: {
              headerName: "Assets",
              optional: true,
              prefetch: async () => {
                // TODO: Maybe leverage the context/providers  ¯\_(ツ)_/¯
                // Get all assets
                const assetsResponse = await assetQuerySet.get("")
                // If there are no assets, exit
                if (!isValidResponse(assetsResponse)) return
                const assetsDict = (assetsResponse.data as IAsset[]).reduce(
                  (prev: Record<string, IAsset>, curr) => ({
                    ...prev,
                    [curr.id]: curr,
                  }),
                  {},
                )
                // Get all varieties
                if (!varieties) return
                return Object.values(varieties).reduce(
                  (prev: Record<string, IVariety>, curr) => {
                    const asset = assetsDict[curr.asset_id]
                    // Exit if the asset is undefined
                    if (!asset) return prev
                    const name = `${asset.name} (${curr.name})`.toLowerCase()
                    return { ...prev, [name]: curr }
                  },
                  {},
                )
              },
              toValue: async (rawVarieties, data) => {
                if (!rawVarieties || rawVarieties.length === 0)
                  return [] as string[]
                const varietiesDict = data as Record<string, IVariety>
                if (!varietiesDict)
                  return {
                    code: 500,
                    message: t(
                      "couldntRetrieveVarieties",
                      "Couldn't retrieve varieties",
                    ),
                  }
                const errors: IValueError[] = []
                const results: string[] = []
                const splitVarieties = rawVarieties.split(",")
                splitVarieties.forEach((varietyName) => {
                  const varietyID =
                    varietiesDict[varietyName.trim().toLowerCase()]?.id
                  if (!varietyID) {
                    errors.push({
                      code: 404,
                      message: t(
                        "varietyXNotFound",
                        "Variety {varietyName} not found",
                        { varietyName },
                      ),
                    })
                    return
                  }
                  results.push(varietyID)
                })
                if (errors.length > 0) return errors
                return results
              },
            },
          }}
        />
      )}
    </>
  )
}
export default RiskProfileBulkUpload
