import { T, useTranslate } from "@tolgee/react"
import { DateTime } from "luxon"
import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import {
  Button,
  Checkbox,
  ColorPickerButton,
  DatePicker,
  LabelAndInput,
  Table,
  TogglableRightSidePanel,
  Tooltip,
} from "../../../climateui/components"
import { GenericInput } from "../../../climateui/components/Inputs"
import { useOutsideComponentClickHandler } from "../../../climateui/hooks"
import { CalendarIcon, CancelIcon, TrashIcon } from "../../../climateui/icons"
import { formattedColorsMatrixForColorPicker } from "../../../climateui/utils/colors"
import { LocalizationContext } from "../../../providers/LocalizationProvider"
import { RiskProfilesContext } from "../../../providers/RiskProfilesProvider"
import { useSeasonalCalendar } from "../../../providers/SeasonalCalendarProvider"
import { IPlannedRisk, IRiskProfile, IStage, IStrategy } from "../../../types"
import { daysBetween } from "../../../utils"
import { formatDateLocalizedMonthNames } from "../../../utils/dates"
import { columns } from "./editingStageTableUtils"
import StageRisksTableFilters from "./StageRisksTableFilters"
import {
  EDITION_ACTIONS,
  MIN_STAGE_DURATION,
  NEW_STAGE_DURATION,
  updateStageRiskProfiles,
} from "./utils"

function EditingStage() {
  const { t } = useTranslate()
  const {
    editingStage,
    setEditingStage,
    modifiableStrategies,
    setModifiableStrategies,
    deleteStageOrRisk,
  } = useSeasonalCalendar()
  const { riskProfiles, riskProfilesObj } = useContext(RiskProfilesContext)
  const { monthNames } = useContext(LocalizationContext)

  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const datePickerRef = useOutsideComponentClickHandler(() => {
    if (datePickerOpen) setDatePickerOpen(false)
  })

  const [globalFilter, setGlobalFilter] = useState("")
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})
  const [columnFilters, setColumnFilters] = useState<
    { id: string; value: unknown }[]
  >([])

  let strategy: IStrategy = {}
  let isEditing = true
  let locationName = ""
  if (editingStage) {
    strategy = modifiableStrategies[editingStage.strategyId ?? ""]
    isEditing = editingStage.index !== -1
    locationName = strategy.location_name ?? ""
  }

  const firstStageName = useMemo(() => {
    if (strategy) {
      return strategy.stages?.find(
        (item: IStage) => item.is_first_stage === true,
      )?.name
    }
    return undefined
  }, [editingStage?.strategyId, modifiableStrategies])

  const dummyDate = new Date()
  let formattedStartDate = formatDateLocalizedMonthNames(dummyDate, monthNames)

  dummyDate.setDate(dummyDate.getDate() + NEW_STAGE_DURATION)
  let formattedEndDate = formatDateLocalizedMonthNames(dummyDate, monthNames)

  const [modifiableStage, setModifiableStage] = useState<IStage | undefined>(
    editingStage || {},
  )

  if (modifiableStage?.start_date && modifiableStage?.end_date) {
    formattedStartDate = formatDateLocalizedMonthNames(
      modifiableStage.start_date,
      monthNames,
    )
    formattedEndDate = formatDateLocalizedMonthNames(
      modifiableStage.end_date,
      monthNames,
    )
  }

  useEffect(() => {
    setModifiableStage(editingStage || undefined)
    const newRowSelection: Record<string, boolean> = {}
    strategy.planned_risks?.forEach((plannedRisk) => {
      if (editingStage?.id === plannedRisk.stage_id) {
        newRowSelection[plannedRisk.risk_profile_id ?? ""] = true
      }
    })
    setRowSelection(newRowSelection)
  }, [editingStage])

  const cancelEditing = () => {
    setEditingStage(undefined)
    setGlobalFilter("")
    setColumnFilters([])
    setRowSelection({})
  }

  const isFormValid = () => {
    // TODO: More validation
    const newDuration = daysBetween(
      modifiableStage?.start_date ?? dummyDate,
      modifiableStage?.end_date ?? dummyDate,
    )
    return (
      modifiableStage?.name &&
      modifiableStage?.name !== "" &&
      newDuration > MIN_STAGE_DURATION
    )
  }

  const save = () => {
    if (!isFormValid()) return // TODO: toast error
    const newStages: IStage[] = [...(strategy.stages ?? [])]
    const newStage: IStage = {
      ...modifiableStage,
      duration: daysBetween(
        modifiableStage?.start_date ?? dummyDate,
        modifiableStage?.end_date ?? dummyDate,
      ),
      action: modifiableStage?.action ?? EDITION_ACTIONS.edited,
    }

    if (isEditing) {
      const index = newStages.findIndex((stage) => stage.id === newStage.id)
      if (index !== -1) newStages[index] = newStage
    } else {
      newStages.push({
        ...newStage,
        index: undefined,
      })
    }

    const newRisks: IPlannedRisk[] = [...(strategy.planned_risks ?? [])]
    const strategyId: string = (newStage.strategyId as string) ?? ""
    const selectedRiskProfiles = Object.keys(rowSelection)
    selectedRiskProfiles.forEach((id: string) => {
      const alreadyExists = newRisks.find((risk) => {
        return risk.stage_id === editingStage?.id && risk.risk_profile_id === id
      })
      if (alreadyExists) return

      const riskProfile = riskProfilesObj[id]
      newRisks.push({
        ...riskProfile,
        risk_profile_id: riskProfile.id,
        frontend_id: uuidv4(),
        start_date: new Date(modifiableStage?.start_date ?? ""),
        end_date: new Date(modifiableStage?.end_date ?? ""),
        duration: daysBetween(
          modifiableStage?.start_date ?? dummyDate,
          modifiableStage?.end_date ?? dummyDate,
        ),
        strategyId,
        stage_id: editingStage?.id ?? undefined,
        action: EDITION_ACTIONS.added,
        id: uuidv4(),
      })
    })
    const actualNewRisks: IPlannedRisk[] = []
    newRisks.forEach((risk) => {
      if (
        risk.stage_id !== editingStage?.id ||
        (risk.stage_id === editingStage?.id &&
          selectedRiskProfiles.includes(risk.risk_profile_id ?? ""))
      ) {
        if (editingStage) updateStageRiskProfiles(editingStage, newStage, risk)
        actualNewRisks.push(risk)
      } else deleteStageOrRisk?.(risk, "risks")
    })
    setModifiableStrategies({
      ...modifiableStrategies,
      [strategyId]: {
        ...modifiableStrategies[strategyId],
        stages: newStages,
        planned_risks: actualNewRisks,
        action: EDITION_ACTIONS.edited,
      },
    })
    cancelEditing()
  }

  const sortedRiskProfiles = useMemo(() => {
    let sortedRiskProfiles: IRiskProfile[] = [...riskProfiles]

    sortedRiskProfiles = sortedRiskProfiles.filter((riskProfile) => {
      return riskProfile.type === "custom"
    })

    sortedRiskProfiles.sort((a, b) => {
      if (rowSelection[a.id] && rowSelection[b.id]) return 0
      if (rowSelection[a.id]) return -1
      if (rowSelection[b.id]) return 1
      return 0
    })
    return sortedRiskProfiles
  }, [riskProfiles, rowSelection])

  return (
    <TogglableRightSidePanel
      doShow={editingStage !== undefined}
      onCancel={cancelEditing}>
      {modifiableStage && (
        <div className="h-full w-full flex flex-col">
          {/* HEADER */}
          <div className="flex flex-row items-center justify-between px-4 pt-4 pb-1 grow-0 shrink-0">
            <div className="flex flex-col">
              <h3 className="title-sm text-light-text dark:text-dark-text">
                {isEditing
                  ? t("editStage", "Edit Stage")
                  : t("newStage", "New Stage")}
              </h3>
              <h6 className="body-sm text-gray-60 dark:text-gray-30">
                {locationName}
              </h6>
            </div>
            <div className="flex flex-row items-center gap-2">
              {isEditing && (
                <span
                  className="w-6 h-6 cursor-pointer fill-gray-60 hover:scale-110 hover:fill-red transition-all duration-75"
                  onClick={modifiableStage.deleteStage}>
                  <TrashIcon />
                </span>
              )}
              <span
                className="w-6 h-6 cursor-pointer fill-gray-60 hover:scale-110 transition-all duration-75"
                onClick={cancelEditing}>
                <CancelIcon />
              </span>
            </div>
          </div>
          {/* STAGE INFO */}
          <div className="flex flex-col p-4 gap-3 grow-0 shrink-0">
            <LabelAndInput
              label={t("name", "Name")}
              input={
                <GenericInput
                  icon={
                    <ColorPickerButton
                      selectedColor={modifiableStage.color ?? ""}
                      setSelectedColor={(color: string) =>
                        setModifiableStage({
                          ...modifiableStage,
                          color,
                        })
                      }
                      colors={formattedColorsMatrixForColorPicker}
                      popupMarginClasses="mr-8 -mb-20"
                    />
                  }
                  name="name"
                  id="name"
                  placeholder={t("newStage")}
                  type="text"
                  value={modifiableStage.name}
                  handleChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setModifiableStage({
                      ...modifiableStage,
                      name: event.target.value,
                    })
                  }}
                />
              }
            />
            <Tooltip
              doShow={!editingStage?.is_first_stage && !!firstStageName}
              content={t(
                "stageSelected",
                "{stageName} already selected as first stage",
                {
                  stageName: firstStageName,
                },
              )}>
              <div className="h-6 mr-1 flex items-center">
                <Checkbox
                  disabled={!editingStage?.is_first_stage && !!firstStageName}
                  checked={modifiableStage.is_first_stage}
                  onChange={() =>
                    setModifiableStage({
                      ...modifiableStage,
                      is_first_stage: !modifiableStage.is_first_stage,
                    })
                  }
                />
                <span className="text-gray-60 dark:text-gray-30 label-sm grow whitespace-nowrap ml-1">
                  {t("firstStage", "First stage")}
                </span>
              </div>
            </Tooltip>
            <div className="relative">
              <LabelAndInput
                label={t("duration", "Duration")}
                input={
                  <div
                    className="flex flex-row items-center justify-start cursor-pointer rounded border-[1px] border-gray-14 dark:border-gray-78 w-fit py-1 pl-2.5 pr-2 text-gray-60 dark:text-gray-30 h-[42px]"
                    onClick={() => setDatePickerOpen(true)}>
                    {formattedStartDate && formattedEndDate ? (
                      <span>
                        {formattedStartDate + " - " + formattedEndDate}
                      </span>
                    ) : (
                      <T keyName="notSchedule">No schedule</T>
                    )}
                    <span className="block w-6 h-6 ml-2 fill-gray-60">
                      <CalendarIcon />
                    </span>
                  </div>
                }
              />
              {datePickerOpen && (
                <div
                  ref={datePickerRef}
                  className="absolute flex flex-row items-center top-full inset-x-0 z-20">
                  <DatePicker
                    clearMessage=""
                    range
                    initialDate={DateTime.fromJSDate(
                      modifiableStage.start_date ?? dummyDate,
                    )}
                    initialEndDate={DateTime.fromJSDate(
                      modifiableStage.end_date ?? dummyDate,
                    )}
                    onChange={(
                      inputStartDate: DateTime | undefined,
                      inputEndDate: DateTime | undefined,
                    ) => {
                      if (inputStartDate && inputEndDate) {
                        const newStartDate = new Date(
                          inputStartDate.year,
                          inputStartDate.month - 1,
                          inputStartDate.day,
                        )
                        const newEndDate = new Date(
                          inputEndDate.year,
                          inputEndDate.month - 1,
                          inputEndDate.day,
                        )
                        setModifiableStage({
                          ...modifiableStage,
                          start_date: newStartDate,
                          end_date: newEndDate,
                        })
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          {/* SEPARATOR */}
          <div className="w-full my-2 border-b border-gray-14 dark:border-gray-78 grow-0 shrink-0"></div>
          {/* RISKS */}
          <div className="flex flex-col p-4 gap-2 grow flex-1 min-h-0">
            <h6 className="label-lg shrink-0 grow-0">
              {t("addRiskProfiles", "Add Risk Profiles")}
            </h6>
            <StageRisksTableFilters
              setColumnFilters={setColumnFilters}
              setGlobalFilter={setGlobalFilter}
              columnFilters={columnFilters}
            />
            <div className="grow overflow-y-auto">
              <Table
                // TODO: Check this extraClasses not working
                extraClasses="[&_th]:sticky [&_th]:top-0"
                data={sortedRiskProfiles}
                columns={columns}
                state={{
                  rowSelection,
                  globalFilter,
                  columnFilters,
                  hiddenColumns: ["labels", "variable", "name"],
                }}
                getRowId={(rp) => rp.id}
                setRowSelection={setRowSelection}
                setGlobalFilter={setGlobalFilter}
                setColumnFilters={setColumnFilters}></Table>
            </div>
            <div className="grow-0 shrink-0">
              <Button
                onClick={save}
                label={t("save", "Save")}
                disabled={!isFormValid()}
                extend
              />
            </div>
          </div>
        </div>
      )}
    </TogglableRightSidePanel>
  )
}

export default EditingStage
