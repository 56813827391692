import { ReactNode } from "react"

const iconLibrary: Record<string, () => ReactNode> = {
  OneTimeImpactIcon: () => (
    <svg
      width="35"
      height="21"
      viewBox="0 0 35 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.58691 0.859375V20.6904"
        stroke="#B3B6BA"
        strokeWidth="2"
      />
      <path
        d="M35 20L1 20"
        stroke="#B3B6BA"
        strokeWidth="2"
      />
      <path
        d="M1 20H15.3699L19.6301 2L23.4247 20H35"
        stroke="#1A232F"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  ),
  ConstantImpactIcon: () => (
    <svg
      width="38"
      height="22"
      viewBox="0 0 38 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.29297 1V20.831"
        stroke="#B3B6BA"
        strokeWidth="2"
      />
      <path
        d="M37.3828 20.6055H1.00056"
        stroke="#B3B6BA"
        strokeWidth="2"
      />
      <path
        d="M37.3823 1H19.1152V20.3803H1"
        stroke="#1A232F"
        strokeWidth="2"
      />
    </svg>
  ),
  ConstantSurplusIcon: () => (
    <svg
      width="39"
      height="23"
      viewBox="0 0 39 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.58789 1.85938V21.6904"
        stroke="#B3B6BA"
        strokeWidth="2"
      />
      <path
        d="M37.6777 21.4648H1.29548"
        stroke="#B3B6BA"
        strokeWidth="2"
      />
      <path
        d="M37.6772 1.85938L19.4102 9.50024V21.2397H1.29492"
        stroke="#1A232F"
        strokeWidth="2"
      />
    </svg>
  ),
}
const ImpactIcon = ({ icon }: { icon: string }) => {
  // Remove any extension they may contain
  const normIcon = icon.replace(/\.\w{2,3}$/, "")
  return <span>{iconLibrary[normIcon]()}</span>
}

export default ImpactIcon
