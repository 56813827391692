import { ParseResult, ParseError } from "papaparse"
export type ParseResult = typeof ParseResult
export type ParseError = typeof ParseError
export interface IValueError {
  code: number // Follows HTTP code conventions
  message: string
}

export type CSVError = IValueError | IValueError[]
export type RowError = Record<string, CSVError | undefined>

export const isValueError = (value: unknown): value is IValueError =>
  value !== undefined &&
  value !== null &&
  !!(value as IValueError).code &&
  !!(value as IValueError).message

export const isRowError = (value: unknown): value is RowError =>
  !!value && typeof value === "object"
    ? isValueError(Object.values(value)[0])
    : false

export const isCSVError = (value: unknown): value is CSVError =>
  isValueError(value) ||
  (Array.isArray(value) && (value.length ? isValueError(value[0]) : false))

export interface IColDef<T> {
  headerName: string
  optional?: boolean
  prefetch?: () => Promise<unknown> | CSVError
  toValue?: (rawValue: string, data?: unknown) => Promise<T | CSVError>
}

export const isColDef = (value: unknown): value is IColDef<unknown> =>
  !!(value as IColDef<unknown>).headerName

export type ColumnsConfig<T> = T extends (infer Element)[]
  ? {
      [Property in keyof Element]:
        | IColDef<Element[Property]>
        | ColumnsConfig<Element[Property]>
        | Element[Property]
    } & {
      nestedDelimiter?: string | RegExp
    }
  : {
      [Property in keyof T]:
        | IColDef<T[Property]>
        | ColumnsConfig<T[Property]>
        | T[Property]
    }

export const isColConfig = (col: unknown): col is ColumnsConfig<unknown> =>
  Object.values(col as ColumnsConfig<unknown>).some(
    (nestedCol) => !!(nestedCol as IColDef<unknown>).headerName,
  )

export type ParsedRow = Record<string, [string, CSVError | undefined]>

export interface IMessages {
  emptyCell?: string
  nestedValuesMismatch?: string
  prefetching?: string
  readyToParse?: string
  parsing?: string
  hasErrors?: string
}
