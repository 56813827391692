import { FC } from "react"

interface DefaultTickProps {
    value: string
}

export const DefaultTick: FC<DefaultTickProps> = ({ value }) => {
    return (
        <span className="body-sm text-gray-60 dark:text-gray-30">{value}</span>
    )
}

export const DefaultYTick: FC<DefaultTickProps> = ({ value }) => {
    return (
        <div className="w-full h-full flex justify-end items-center pr-1">
            <DefaultTick value={value} />
        </div>
    )
}

export const DefaultXTick: FC<DefaultTickProps> = ({ value }) => {
    return (
        <div className="w-full h-full flex justify-center items-center">
            <DefaultTick value={value} />
        </div>
    )
}

/* Helper functions */
export const onRenderDefaultYTick = (value: string) => {
    return <DefaultYTick value={value} />
}

export const onRenderDefaultXTick = (value: string) => {
    return <DefaultXTick value={value} />
}
