import { Outlet } from "react-router-dom"

// interface IDashboard {
//     id: string
//     name: string
//     type: "location" | "regional"
// }

function DashboardParentView() {
  // TODO: use query get dashboards once dashboard service is connected
  // const [dashboards, setDashboards] = useState<IDashboard[]>([])
  // const { t } = useTranslate()

  // useEffect(() => {
  //     // TODO: fix sidebar, for some reason child rounts won't show initially unless a render
  //     // is triggered, same happens with Planning Tool sidebar
  //     setTimeout(() => {
  //         setDashboards([
  //             {
  //                 id: "template1",
  //                 type: "regional",
  //                 name: t("regionalMonitoringLabel"),
  //             },
  //             {
  //                 id: "template2",
  //                 type: "location",
  //                 name: t("locationMonitoringLabel"),
  //             },
  //         ])
  //     }, 100)
  // }, [])

  // TODO: (dashboards) => dashboardRoutes

  // const dashboardRoutes: IRoute[] = dashboards.map((dashboard) => {
  //     return {
  //         path: dashboard.id,
  //         label: dashboard.name,
  //     }
  // })

  return (
    <div className="overflow-y-auto w-full h-full print:overflow-y-visible!important print:h-auto">
      <Outlet />
      {/* <PortalComponent portalId="dashboardsPortal">
                    {dashboardRoutes.length > 0 && (
                        <ChildRoutes routes={dashboardRoutes} />
                    )}
                </PortalComponent> */}
    </div>
  )
}

export default DashboardParentView
