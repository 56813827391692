import { SVGProps } from "react"

const WithinIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        className={"fill-inherit " + props.className}
        xmlns="http://www.w3.org/2000/svg">
        <rect
            x="7"
            y="8"
            width="10"
            height="2"
            fill={props.fill}
        />
        <rect
            x="7"
            y="14"
            width="10"
            height="2"
            fill={props.fill}
        />
    </svg>
)

export default WithinIcon
