import { useTranslate } from "@tolgee/react"
import { useMemo } from "react"
import {
  TableActionInteface,
  TableActions,
} from "../../../climateui/components/Table/tableUtils"
import { CancelIcon, EditIcon, TrashIcon } from "../../../climateui/icons"
import { IInsightsLocation } from "../../../types"
import { useAssetDependentSteps } from "./OnboardingAssetDependentLayoutProvider"

function OnboardingLocationsTableActions({
  rowData,
}: {
  rowData: IInsightsLocation
}) {
  const { t } = useTranslate()
  const {
    setAssetNewLocations,
    setSelectedExistingLocationsIds,
    setEditingLocation,
  } = useAssetDependentSteps()

  const tableActions = useMemo(() => {
    const tableActions: TableActionInteface[] = []
    tableActions.push({
      tooltip: rowData.id
        ? t("removeImportedLocation", "Remove Imported Location")
        : t("deleteLocation", "Delete Location"),
      icon: rowData.id ? <CancelIcon /> : <TrashIcon />,
      onClick: () => {
        if (rowData.id) {
          setSelectedExistingLocationsIds((prev) => {
            if (!rowData.id) return prev

            const next = [...prev]
            const index = next.indexOf(rowData.id)
            if (index > -1) next.splice(index, 1)
            return next
          })
        } else {
          setAssetNewLocations((prev) => {
            const next = [...prev]
            const index = next.findIndex(
              (element) => element.name === rowData.name,
            )
            if (index > -1) next.splice(index, 1)
            return next
          })
        }
      },
    })

    if (!rowData.id) {
      tableActions.push({
        tooltip: t("editLocation", "Edit Location"),
        icon: <EditIcon />,
        onClick: () => {
          setEditingLocation(rowData)
        },
      })
    }

    return tableActions
  }, [rowData])

  return (
    <TableActions
      rowData={rowData}
      actions={tableActions}
    />
  )
}

export default OnboardingLocationsTableActions
