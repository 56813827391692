import { useTranslate } from "@tolgee/react"
import { Button } from "../climateui/components"
import { useAuthNavigation } from "../providers/AuthGuards"

function ForbiddenView() {
  const { t } = useTranslate()
  const { navigateToAllowed } = useAuthNavigation()

  return (
    <div className="flex flex-col items-center justify-center h-full w-full bg-light-bg dark:bg-dark-bg">
      <div className="w-36 -mt-8">
        <img
          alt="not-view-permission"
          src="/images/climateai/not-view-permissions.png"
        />
      </div>

      <h1 className="title-lg text-light-text dark:text-dark-text pt-4 max-w-[360px] text-center">
        {t(
          "youDontHaveViewPermissions",
          "You don't have permissions to view this page",
        )}
      </h1>
      <div className="pt-6">
        <Button
          label={t("takeMeHome", "Take me home")}
          onClick={() => navigateToAllowed()}
        />
      </div>
    </div>
  )
}

export default ForbiddenView
