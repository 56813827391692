import { DefaultParamType, TFnType, useTranslate } from "@tolgee/react"
import { createRoot } from "react-dom/client"
import mapboxgl from "../../../../climateui/components/Map/mapboxgl"
import { IMapPin } from "../../../../climateui/components/Map/utils"
import { RiskIconHandler } from "../../../../climateui/icons/riskIcons"
import {
  IInsightsLocation,
  IMapAlertPopUp,
  IRiskProfile,
} from "../../../../types"
import { formatDateRange, TimeResolutionValues } from "../utils"

const ALERT_CATEGORIES = ["warning", "watch"] as const
export type alertCategoriesEnum = (typeof ALERT_CATEGORIES)[number]
const alertColorPalettes = {
  watch: {
    backgroundColor: "#FFF8E5",
    fillColor: "#CC9300",
  },
  warning: {
    backgroundColor: "#FCE9EB",
    fillColor: "#BF1728",
  },
}

const RiskConditionRow = ({
  alertCategory = "warning",
  riskProfile,
  alert,
}: {
  alertCategory: alertCategoriesEnum
  riskProfile: IRiskProfile
  alert?: IMapAlertPopUp
}) => {
  const alertColorStyle = alertColorPalettes[alertCategory]

  if (!alert) return null

  const time_resolution = (
    ["daily", "weekly", "monthly"].includes(alert.time_resolution)
      ? alert.time_resolution
      : "daily"
  ) as TimeResolutionValues

  return (
    <div className="flex flex-row w-full gap-2">
      <div
        className="p-[7px] shrink-0 grow-0 w-9 h-9 rounded-sm"
        style={{
          fill: alertColorStyle.fillColor,
          backgroundColor: alertColorStyle.backgroundColor,
        }}>
        <RiskIconHandler hazardProfiles={riskProfile.hazard_profiles} />
      </div>
      <div className="flex-wrap body-sm">
        <div className="text-light-text dark:text-dark-text font-medium">
          {riskProfile.name}
        </div>
        <div className="text-light-text dark:text-dark-text">
          {formatDateRange(time_resolution, alert.start_date, alert.end_date)}
        </div>
      </div>
    </div>
  )
}

const AlertsPopUp = (props: {
  t: TFnType<DefaultParamType, string> // Tolgee Translation
  alertCategory: alertCategoriesEnum
  location: IInsightsLocation
  alerts: IMapAlertPopUp[]
  riskProfiles: Record<string, IRiskProfile>
}) => {
  const MAX_ALERTS = 3
  const { location, riskProfiles, alerts, t, alertCategory } = props

  const surplus = (alerts || []).length - MAX_ALERTS
  return (
    <div className="w-64 -m-1">
      <div className="flex flex-col">
        <div className="flex font-medium text-left text-light-text dark:text-dark-text body-md">
          {location.name}
        </div>
        {(alerts || []).length === 0 && (
          <div className="flex flex-wrap body-sm">
            <span className="text-green">
              {t(
                "noAlertsForCurrentSelection",
                "No alerts for the current filters selection",
              )}
            </span>
            <span className="text-gray-60 dark:text-gray-30">
              {t(
                "clickPinToLocationDashboard",
                "Click pin to go to Location dashboard",
              )}
            </span>
          </div>
        )}
      </div>
      <div>
        <div className="flex flex-col my-3 w-fit gap-y-2">
          {(alerts || []).slice(0, MAX_ALERTS).map((alert) => (
            <div key={alert.id}>
              <RiskConditionRow
                alertCategory={alertCategory}
                alert={alert}
                riskProfile={riskProfiles[alert.risk_profile_id]}
              />
            </div>
          ))}
        </div>
        {surplus > 0 && (
          <div className="mb-2 text-xs text-center text-gray-60 dark:text-gray-30 gap-2">
            {surplus} {t("more", "more")}
          </div>
        )}
      </div>
    </div>
  )
}

export const useAlertsMarkerPopup = () => {
  const { t } = useTranslate()
  return {
    getAlertsMarkerPopup: (pin: IMapPin) => {
      // we can't use the static render anymore for this since
      // there's need for listening events inside the AlertsPopup comp
      const popupDOMContainer = document.createElement("div")
      const popupRoot = createRoot(popupDOMContainer)

      popupRoot.render(
        <AlertsPopUp
          location={pin.data.location}
          alertCategory={pin.data.category}
          alerts={pin.data.alerts}
          riskProfiles={pin.data.riskProfiles}
          t={t}
        />,
      )

      return new mapboxgl.Popup({
        className: "font-roboto z-full",
        offset: 0,
        closeButton: false,
      }).setDOMContent(popupDOMContainer)
    },
  }
}
