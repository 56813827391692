import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { IRoute } from "../../climateui/types"
import { useAccount, useAuth } from "../../providers"

const appendAssetPath = (
  pathname: string | undefined,
  assetPath: string | undefined,
) => {
  if (!pathname || !assetPath) return
  // DISCUSSION: Should this be a variable or a constant instead of a literal?
  const expectedPathLeaf = /outlook\/?$/
  if (!expectedPathLeaf.exec(pathname)) return
  if (pathname.endsWith("/")) return pathname + assetPath
  return pathname + "/" + assetPath
}

export const useNavigateToFirstModel = (
  subRoutes: IRoute[],
  navigateToFirstModel: boolean,
) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useAuth()
  const { selectedAccount } = useAccount()

  useEffect(() => {
    if (!selectedAccount || subRoutes.length === 0) return
    const newPath = appendAssetPath(location.pathname, subRoutes[0].path)
    if (!newPath || !navigateToFirstModel) return
    navigate(newPath)
  }, [selectedAccount, user, subRoutes])
}
