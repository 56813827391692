import { useContext, useEffect, useMemo, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { LogOutIcon } from "../climateui/icons"
import { ToastContext } from "../climateui/providers"
import { TopBar, UserCircleMenuDropdown } from "../components"
import { AccountSelect, useAuth } from "../providers"
import { protectedRoutes } from "../utils/appRoutes"
import { UserForm } from "../views/Users/components"

// TYPES
import { useTranslate } from "@tolgee/react"
import { IRoute } from "../climateui/types"
import { useRoutesFeatureFlags } from "../hooks"
import { useAccount } from "../providers/AccountProvider"
import { useAuthNavigation } from "../providers/AuthGuards"
import { isAllowedToRoute } from "../providers/AuthProvider"
import { LocalizationContext } from "../providers/LocalizationProvider"

const appPaths = ["", "/"]

function AppLayout() {
  const auth = useAuth()
  const acc = useAccount()
  const routesFeatureFlags = useRoutesFeatureFlags()

  const { navigateToAllowed } = useAuthNavigation()
  const { t } = useTranslate()

  const { language } = useContext(LocalizationContext)
  const { enqueueAlert } = useContext(ToastContext)

  const navigate = useNavigate()

  const user = auth.user
  const dropdownName = user?.name || user?.email
  const [openSettings, setOpenSettings] = useState<boolean>(false)

  const dropdownFirstLetter = dropdownName ? dropdownName[0] : undefined
  const dropdownOptions = [
    {
      label: t("userSettings"),
      onClick: () => setOpenSettings(!openSettings),
    },
    {
      label: t("resetPassword"),
      onClick: () => {
        if (user?.email) {
          auth.requestPasswordReset(user.email)
          enqueueAlert(t("resetPasswordEmailAction"))
        } else navigate("/restore-password")
      },
    },
    {
      label: t("logout"),
      onClick: () => auth.logout(),
      icon: <LogOutIcon />,
    },
  ]

  const allowedTopBarOptions = useMemo(() => {
    const result: IRoute[] = []
    if (location.pathname.includes("account-not-configured")) return result
    protectedRoutes.forEach((option) => {
      const isAllowed = isAllowedToRoute(option, acc, auth, routesFeatureFlags)

      // TODO: Add custom tab reports check
      if (option.labelKey && isAllowed) {
        result.push({
          ...option,
          label: t(option.labelKey) || option.label,
        })
      }
    })
    return result
  }, [auth.roles, acc.accountPermissions, acc.selectedAccount, language])

  useEffect(() => {
    if (
      appPaths.includes(location.pathname) &&
      acc.selectedAccount &&
      !acc.loadingAccountInfo
    )
      navigateToAllowed()
  }, [acc.selectedAccount, location.pathname, acc.loadingAccountInfo])

  return (
    <>
      <div className="flex flex-col w-full h-screen">
        <TopBar
          left={<AccountSelect />}
          options={allowedTopBarOptions}
          right={
            <UserCircleMenuDropdown
              firstLetter={dropdownFirstLetter}
              name={dropdownName}
              options={dropdownOptions}
              colorClass="bg-light-bg/[18%]"
            />
          }
        />
        <div id="tab-global-banner"></div>
        <Outlet />
        {user && (
          <UserForm
            doShow={openSettings}
            editedUser={user}
            onCancel={() => {
              setOpenSettings(false)
            }}
          />
        )}
      </div>
      {/*
                INFO: Portal below was introduced to fix the sidebar
                showing over full-screen StepsNavigationLayout
            */}
      <div id="global-full-screen-element"></div>
    </>
  )
}

export default AppLayout
