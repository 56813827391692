import { useTranslate } from "@tolgee/react"
import { useMemo } from "react"
import LoadingAnimation from "../../../../climateui/components/LoadingAnimation"
import { capitalizeFirstCharacterOfEachWord } from "../../../../utils/wordHelper"
import {
  RiskCategory,
  getCurrentFullSeasonStats,
  getUpcomingRiskStats,
} from "../riskOutlookScoreUtils"
import { categoryToColor } from "../riskOutlookUtils"
import { IRiskCountryOverviewModel } from "../types"

const RiskOverviewScoreDisplay = (props: {
  data: IRiskCountryOverviewModel[]
  loading: boolean
  isFullSeason: boolean
}) => {
  const { t } = useTranslate()
  const { data, loading, isFullSeason } = props

  const risksBySeasonality = useMemo(() => {
    const result: Record<
      string,
      { hazard: string; score: RiskCategory; drivers: string[] }
    > = {}

    if (loading) return result

    const hazards = data?.[0]?.default_geography?.hazards ?? []

    // Get upcoming risk
    const upcomingRisk = getUpcomingRiskStats(hazards)
    if (upcomingRisk) {
      result["upcoming-risk"] = {
        hazard: upcomingRisk.hazard,
        score: upcomingRisk.score,
        drivers: upcomingRisk.drivers,
      }
    }

    // Get seasonal risks
    if (hazards.length > 0) {
      // Get number of seasons from first hazard
      const seasonCount = hazards[0].seasonal_stats.results.length

      for (let i = 0; i < seasonCount; i++) {
        const seasonLabel = hazards[0].seasonal_stats.results[i].season_label
        const seasonRisk = getCurrentFullSeasonStats(hazards, i)

        if (seasonRisk) {
          result[seasonLabel] = {
            hazard: seasonRisk.hazard,
            score: seasonRisk.score,
            drivers: seasonRisk.drivers,
          }
        }
      }
    }
    return result
  }, [data, loading])

  if (loading) {
    return (
      <div className="flex justify-center mt-5 body-lg">
        <LoadingAnimation />
      </div>
    )
  }

  // Get the appropriate risk data based on isFullSeason
  const seasonKeys = Object.keys(risksBySeasonality)
    .filter((key) => key !== "upcoming-risk")
    .sort((a, b) => a.localeCompare(b)) // Sort lexicographically to get earliest season first

  const selectedSeasonKey = isFullSeason ? seasonKeys[0] ?? "" : "upcoming-risk"
  const nextSeasonKey = isFullSeason ? seasonKeys[1] : null

  const riskData = risksBySeasonality[selectedSeasonKey]
  const nextSeasonData = nextSeasonKey
    ? risksBySeasonality[nextSeasonKey]
    : null

  if (!riskData) {
    return null
  }

  const title = isFullSeason
    ? `Cumulative risk for the ${selectedSeasonKey} season`
    : "Overall upcoming risk"

  return (
    <div
      className="flex flex-col justify-between"
      style={{ height: "100%" }}>
      <div className="label-lg">{`${title}`}</div>
      <div
        className={`text-${
          categoryToColor[riskData.score]
        } flex flex-1 items-center justify-center`}>
        <div
          className={`bg-${
            categoryToColor[riskData.score]
          } h-3 rounded-full w-3 mr-1`}></div>
        <div className="title-lg text-center">{t(riskData.score)}</div>
      </div>
      {riskData.score !== "lowRisk" && riskData.drivers.length > 0 && (
        <div className="w-full">
          Driven by{" "}
          {riskData.drivers.map((driver, index) => (
            <>
              <span
                key={`overview-driver-${driver}`}
                className={`text-${categoryToColor[riskData.score]} font-bold`}>
                {t(driver, capitalizeFirstCharacterOfEachWord(driver))}
              </span>
              {index < riskData.drivers.length - 1 ? ", " : ""}
              {index === riskData.drivers.length - 2 ? " and " : ""}
            </>
          ))}
        </div>
      )}
      {/* Only when next season exist */}
      {isFullSeason && nextSeasonData && (
        <>
          <div className="w-full border-t border-gray-14 dark:border-gray-78 my-2" />
          <div className="text-gray-500 text-sm">
            <span
              className={`font-bold text-${
                categoryToColor[nextSeasonData.score]
              }`}>
              {t(nextSeasonData.score)}
            </span>
            {nextSeasonData.score !== "lowRisk" &&
              nextSeasonData.drivers.length > 0 && (
                <span>
                  {" driven by "}
                  {nextSeasonData.drivers.map((driver, index) => (
                    <span key={`next-season-driver-${driver}`}>
                      <span
                        className={`text-${
                          categoryToColor[nextSeasonData.score]
                        } font-bold`}>
                        {t(driver, capitalizeFirstCharacterOfEachWord(driver))}
                      </span>
                      {index < nextSeasonData.drivers.length - 1 && (
                        <span>
                          {index === nextSeasonData.drivers.length - 2
                            ? " and "
                            : ", "}
                        </span>
                      )}
                    </span>
                  ))}
                </span>
              )}
            <span>
              {` for the `}
              <span className="font-bold">{nextSeasonKey}</span>
              {` season`}
            </span>
          </div>
        </>
      )}
    </div>
  )
}

export default RiskOverviewScoreDisplay
