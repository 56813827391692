import { useEffect, useState } from "react"
import { SelectionStatus } from "../utils/constants"

const CheckboxIcon = (props: { status: string; fill?: string }) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                d={
                    props.status === SelectionStatus.FULL ||
                    props.status === SelectionStatus.CHECKED
                        ? "M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.11 21 21 20.1 21 19V5C21 3.9 20.11 3 19 3ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                        : props.status === SelectionStatus.SOME
                          ? "M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM17 13H7V11H17V13Z"
                          : "M19 19H5V5H19V19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
                }
                fill={props.fill}
            />
        </svg>
    )
}

const toggleCheckbox = (status: string) => {
    if (status === SelectionStatus.SOME || status === SelectionStatus.EMPTY)
        return SelectionStatus.FULL
    return SelectionStatus.EMPTY
}

export const fromSelectionStatusToBool = (status: string) => {
    return status === SelectionStatus.FULL
}

const Checkbox = ({
    id = "",
    checked,
    status,
    onChange = (val) => console.log(val),
    disabled = false,
    extraWrapperClasses = "",
}: {
    id?: string
    checked?: boolean
    status?: string
    onChange: (val: string) => void
    disabled?: boolean
    extraWrapperClasses?: string
}) => {
    if (status === undefined) {
        if (checked) status = SelectionStatus.FULL
        else status = SelectionStatus.EMPTY
    }

    const [innerStatus, setInnerStatus] = useState(status)

    useEffect(() => {
        setInnerStatus(status as string)
    }, [status])

    return (
        <button
            id={id}
            className={[
                "w-full h-full max-w-[36px]",
                "fill-gray-60 cursor-pointer",
                "active:fill-gray-60",
                "disabled:opacity-75 disabled:cursor-not-allowed ",
                extraWrapperClasses,
            ].join(" ")}
            disabled={disabled}
            onClick={(e) => {
                e.preventDefault()
                const newStatus = toggleCheckbox(innerStatus)
                setInnerStatus(newStatus)
                onChange(newStatus)
            }}>
            <CheckboxIcon status={innerStatus} />
        </button>
    )
}

export default Checkbox
