import { LineProps, AreaProps } from "recharts"

/* Domain entities types */
/* 
    All future chart components that are library-dependent should be added here
*/
export enum PlotComponentTypes {
    Line = "line",
    Area = "area",
    Directionality = "directionality",
}

/* Configuration I */
/* 
    This configuration contains all the rendering 
    configuration for each component. This will make
    switching chart libraries in the future fairly 
    easy.
*/

/* Pending to remove the config interfaces from domain, to coupled */
export interface IConfigLinePlotComponent extends Omit<LineProps, "dataKey"> {}

export interface IConfigAreaPlotComponent extends Omit<AreaProps, "dataKey"> {}

export interface IConfigDirectionalityPlotComponent
    extends Omit<LineProps, "dataKey"> {
    svgIcon: string // How should we render svg components?
}

/* Components I */

/* Plot Components */
export interface IGenericPlotComponent<TMetaData> {
    id: string
    type: PlotComponentTypes
    xLabel: string // Do I need this prop?
    yLabel: string // Do I need this prop?
    xLabelId: string
    yLabelId: string
    metadata: TMetaData
}

export interface ILinePlotComponent<TMetaData>
    extends IGenericPlotComponent<TMetaData> {
    type: PlotComponentTypes.Line
    x: string[]
    y: number[]
    config: IConfigLinePlotComponent
}

export interface IAreaPlotComponent<TMetaData>
    extends IGenericPlotComponent<TMetaData> {
    type: PlotComponentTypes.Area
    x: string[]
    y: {
        y0: number
        y1: number
    }[]
    config: IConfigAreaPlotComponent
}

export interface IDirectionalityPlotComponent<TMetaData>
    extends IGenericPlotComponent<TMetaData> {
    type: PlotComponentTypes.Directionality
    x: string[]
    y: number[]
    config: IConfigDirectionalityPlotComponent
}

/* Data Source Entities */
/* 
    Used to create a contract between the Plot Components 
    and the data source passed to them
*/

export interface IGenericPlotComponentDTO {
    id: string
    xLabel: string
    yLabel: string
    xLabelId: string
    yLabelId: string
}

export interface ILinearPlotComponentDataSource
    extends IGenericPlotComponentDTO {
    x: string[]
    y: number[]
}

export interface IBandPlotComponentDataSource extends IGenericPlotComponentDTO {
    x: string[]
    y: {
        y0: number
        y1: number
    }[]
}
