import { isValidResponse } from "../../../climateui/utils/http"
import { IProcessingRun } from "../../../types"
import { consumerAlertQuerySet } from "../../../utils/networking"

export const DEFAULT_DASHBOARD_TEMPLATE_IDS = ["location", "portfolio"]
export const DEFAULT_DASHBOARD_PROMISE_HARVESTER_DELAY = 200

export function formatDashboardLabel(name: string, scope: string) {
  name = name.replaceAll(" ", "_")
  name = name.toLowerCase()
  return `${name}_${scope}`
}

async function getProcessingRuns(riskIds: string[], locationsIds: string[]) {
  const locsIdsString = JSON.stringify(locationsIds)
  locsIdsString.replaceAll('"', '\\"')
  const riskIdsString = JSON.stringify(riskIds)
  riskIdsString.replaceAll('"', '\\"')

  const processingRuns = await consumerAlertQuerySet
    .post("/search", {
      filter_by: {
        and: [
          {
            field_name: "processing_run.ProcessingRun.risk_id",
            operator: "in",
            field_value: riskIdsString,
          },
          {
            field_name: "processing_run.ProcessingRun.location_id",
            operator: "in",
            field_value: locsIdsString,
          },
        ],
      },
      pagination: {
        all: true,
        page: 1,
        per_page: 0,
      },
    })
    .then((response) => {
      if (!isValidResponse(response)) return []
      // return response.data.meta.total
      return response.data
    })

  return processingRuns
}

export async function matchRiskSettingsToProcessingRuns(
  locationsRiskProfileRelation: Record<string, string[]>,
  riskIds: string[],
  alertsIds: string[],
) {
  const nonRepeatingAlertsIds = new Set(alertsIds)
  const locationsIds = Object.keys(locationsRiskProfileRelation)

  // filteredProcessingRuns --> Filters the processingRuns so that it only contains
  // processing runs related to the risk settings that were sent in the getRiskSettings function
  const processingRuns = await getProcessingRuns(riskIds, locationsIds)

  const filteredProcessingRuns =
    processingRuns?.data?.filter((processRun: IProcessingRun) => {
      // return alertsIds.includes(processRun.risk_setting_id)
      return nonRepeatingAlertsIds.has(processRun.risk_setting_id)
    }) || []

  // Stores the number of processing runs retrieved in the previous process
  const numProcessingRuns = filteredProcessingRuns.length
  console.info(
    "n processing runs",
    numProcessingRuns,
    "out of",
    alertsIds.length,
  )
  return numProcessingRuns == alertsIds.length
}
