import { PropTypes } from "prop-types"
import getColorAndSymbol from "../../../utils/icons/getColorAndSymbol"
import LoadingAnimation from "../../LoadingAnimation"
import NoResultsState from "../../NoResultsState"

function SearchWidget(props) {
    const {
        title,
        value,
        description,
        icon,
        loading,
        error,
        empty,
        reload,
        enableMultiLines = false,
        errorMessage,
        reloadMessage,
        noResultsMessage,
        tryAnotherFilterMessage,
    } = props

    const renderBody = () => {
        if (loading) {
            return (
                <div className="flex justify-center mt-5 body-lg">
                    <LoadingAnimation />
                </div>
            )
        }
        if (empty) {
            return (
                <NoResultsState
                    noResultsMessage={noResultsMessage}
                    tryAnotherFilterMessage={tryAnotherFilterMessage}
                />
            )
        }
        if (error) {
            return (
                <>
                    <p className="flex justify-center items-center text-[18px] mt-3">
                        {errorMessage}
                    </p>
                    <div className="flex items-center justify-center">
                        <button
                            onClick={reload}
                            className="text-[14px] underline text-accent">
                            {reloadMessage}
                        </button>
                    </div>
                </>
            )
        }
        return (
            <div className="flex-wrap text-center">
                <div className="flex justify-center mt-3 gap-x-2">
                    {icon ? (
                        <div className="w-[28px]">
                            {getColorAndSymbol(icon, "widget", {
                                above: "green",
                                below: "red",
                                within: "yellow-dark",
                            })}
                        </div>
                    ) : (
                        <></>
                    )}
                    <p
                        className={
                            enableMultiLines
                                ? "line-clamp-3 title-sm overflow-y-auto"
                                : "truncate title-lg "
                        }>
                        {value}
                    </p>
                </div>
                <p className="text-[14px] text-gray-60 dark:text-gray-30 -mb-3 truncate">
                    {description}
                </p>
            </div>
        )
    }

    return (
        <div className="w-full h-full font-roboto bg-light-bg dark:bg-dark-bg border border-1 border-gray-14 dark:border-gray-78 rounded-lg p-[14px] mb-5">
            <h4 className={"label-lg " + (loading ? "text-gray-30" : "")}>
                {title}
            </h4>
            {renderBody()}
        </div>
    )
}

SearchWidget.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    value: PropTypes.string,
    description: PropTypes.string,
    loading: PropTypes.bool,
    enableMultiLines: PropTypes.bool,
    error: PropTypes.bool,
    empty: PropTypes.bool,
    errorMessage: PropTypes.string,
    reload: PropTypes.func,
    reloadMessage: PropTypes.string,
    noResultsMessage: PropTypes.string,
    tryAnotherFilterMessage: PropTypes.string,
}

export default SearchWidget
