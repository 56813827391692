import { useTranslate } from "@tolgee/react"
import { useEffect, useMemo, useState } from "react"
import { MultiSelectFilter } from "../../../../climateui/components"
import {
  IBooleanDictionary,
  ILabel,
  IPrimitivesDictionary,
} from "../../../../climateui/types"
import { useAccount } from "../../../../providers/AccountProvider"
import { useLocations } from "../../../../providers/LocationsProvider"
import { IInsightsLocation } from "../../../../types"

function LocationsTableFilters({
  setColumnFilters,
  columnFilters,
  showAccountFilter = false,
  showAssetFilter = true,
  showLocationFilter = true,
  showLabelFilter = true,
  locationsSubset = [],
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setColumnFilters: (newColumnFilters: any) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columnFilters: any
  showAccountFilter?: boolean
  showAssetFilter?: boolean
  showLocationFilter?: boolean
  showLabelFilter?: boolean
  locationsSubset?: IInsightsLocation[]
}) {
  const {
    locations,
    locationLabels,
    locationRegions,
    locationAccounts,
    locationVarieties,
  } = useLocations()
  const { selectedAccount } = useAccount()

  const [locationFilter, setLocationFilter] = useState<IBooleanDictionary>({})
  const [regionFilter, setRegionFilter] = useState<IBooleanDictionary>({})
  const [accountFilter, setAccountFilter] = useState<IBooleanDictionary>({})
  const [labelFilter, setLabelFilter] = useState<IBooleanDictionary>({})
  const [varietyFilter, setVariteyFilter] = useState<IBooleanDictionary>({})

  const { locationNames, regionNames, labelNames, varietyNames } =
    useMemo(() => {
      const locNames = {} as IPrimitivesDictionary
      let locs: IInsightsLocation[] = locations
      let regNames = locationRegions
      let labelNames = locationLabels
      let varietyNames = locationVarieties

      //If there's a subset of locations sent, use that to build the dropdowns
      if (locationsSubset.length > 0) {
        locs = locationsSubset

        const regs: IPrimitivesDictionary = {}
        const labels: IPrimitivesDictionary = {}
        const varieties: Record<string, string> = {}

        locs.forEach((loc) => {
          regs[loc.region?.full_name as string] = loc.region
            ?.full_name as string

          loc.labels.forEach((label) => {
            labels[(label as ILabel).id as string] = (label as ILabel).name
          })

          loc.varieties?.forEach((variety) => {
            varieties[variety.id] = variety.name
          })
        })
        regNames = regs
        labelNames = labels
        varietyNames = varieties
      }

      locs.forEach((loc) => {
        locNames[loc.id as string] = loc.name
      })
      return {
        locationNames: locNames,
        regionNames: regNames,
        labelNames,
        varietyNames,
      }
    }, [locations, locationsSubset])

  useEffect(() => {
    // Upon changing selected account, reset table row selection and filtering
    setLocationFilter({})
    setRegionFilter({})
    setAccountFilter({})
    setLabelFilter({})
    setVariteyFilter({})
  }, [selectedAccount])

  const { t } = useTranslate()

  return (
    <>
      {showLocationFilter && Object.keys(locationNames).length ? (
        <MultiSelectFilter
          filterName={t("location")}
          canSearch
          leftRightClass="left-0"
          textAlignClass="text-left"
          selected={locationFilter}
          options={
            {
              ...locationNames,
            } as { [key: string]: string }
          }
          placeholder={t("location")}
          searchPlaceholder={t("search")}
          setSelected={(obj) => {
            if (Object.keys(obj).includes("all") && obj["all"]) {
              setLocationFilter({ all: true })
              setColumnFilters(
                columnFilters.concat([
                  {
                    id: "name",
                    value: [],
                  },
                ]),
              )
            } else {
              setLocationFilter(obj)
              const tempObj = {
                id: "name",
                value: Object.keys(obj),
              }
              setColumnFilters(columnFilters.concat([tempObj]))
            }
          }}
        />
      ) : (
        <></>
      )}
      {Object.keys(locationNames).length ? (
        <MultiSelectFilter
          filterName={t("region")}
          canSearch
          leftRightClass="left-0"
          textAlignClass="text-left"
          selected={regionFilter}
          options={
            {
              ...regionNames,
            } as { [key: string]: string }
          }
          placeholder={t("region")}
          searchPlaceholder={t("search")}
          setSelected={(obj) => {
            if (Object.keys(obj).includes("all") && obj["all"]) {
              setRegionFilter({ all: true })
              setColumnFilters(
                columnFilters.concat([
                  {
                    id: "region",
                    value: [],
                  },
                ]),
              )
            } else {
              setRegionFilter(obj)
              const tempObj = {
                id: "region",
                value: Object.keys(obj),
              }
              setColumnFilters(columnFilters.concat([tempObj]))
            }
          }}
        />
      ) : (
        <></>
      )}
      {showAccountFilter && Object.keys(locationAccounts).length ? (
        <MultiSelectFilter
          filterName={t("account")}
          canSearch
          leftRightClass="left-0"
          textAlignClass="text-left"
          selected={accountFilter}
          options={
            {
              ...locationAccounts,
            } as { [key: string]: string }
          }
          placeholder={t("account")}
          searchPlaceholder={t("search")}
          setSelected={(obj) => {
            if (Object.keys(obj).includes("all") && obj["all"]) {
              setAccountFilter({ all: true })
              setColumnFilters(
                columnFilters.concat([
                  {
                    id: "account",
                    value: [],
                  },
                ]),
              )
            } else {
              setAccountFilter(obj)
              const tempObj = {
                id: "account",
                value: Object.keys(obj),
              }
              setColumnFilters(columnFilters.concat([tempObj]))
            }
          }}
        />
      ) : (
        <></>
      )}
      {showAssetFilter && Object.keys(locationVarieties).length ? (
        <MultiSelectFilter
          filterName={t("asset")}
          canSearch
          leftRightClass="left-0"
          textAlignClass="text-left"
          selected={varietyFilter}
          keepOrder
          options={
            {
              none: "None",
              ...varietyNames,
            } as { [key: string]: string }
          }
          placeholder={t("asset")}
          searchPlaceholder={t("search")}
          setSelected={(obj) => {
            if (Object.keys(obj).includes("all") && obj["all"]) {
              setVariteyFilter({ all: true })
              setColumnFilters(
                columnFilters.concat([
                  {
                    id: "varieties",
                    value: [],
                  },
                ]),
              )
            } else {
              setVariteyFilter(obj)
              const tempObj = {
                id: "varieties",
                value: Object.keys(obj),
              }
              setColumnFilters(columnFilters.concat([tempObj]))
            }
          }}
        />
      ) : (
        <></>
      )}
      {showLabelFilter && Object.keys(locationLabels).length ? (
        <MultiSelectFilter
          filterName={t("label")}
          canSearch
          leftRightClass="left-0"
          textAlignClass="text-left"
          selected={labelFilter}
          options={
            {
              ...labelNames,
            } as { [key: string]: string }
          }
          placeholder={t("label")}
          searchPlaceholder={t("search")}
          setSelected={(obj) => {
            if (Object.keys(obj).includes("all") && obj["all"]) {
              setLabelFilter({ all: true })
              setColumnFilters(
                columnFilters.concat([
                  {
                    id: "labels",
                    value: [],
                  },
                ]),
              )
            } else {
              setLabelFilter(obj)
              const tempObj = {
                id: "labels",
                value: Object.keys(obj),
              }
              setColumnFilters(columnFilters.concat([tempObj]))
            }
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default LocationsTableFilters
