import { useTranslate } from "@tolgee/react"
import { gql } from "graphql-request"
import WidgetWrapper from "../../../../climateui/components/Widgets/WidgetWrapper/WidgetWrapper"
import { GenericPageHeader } from "../../../../components"
import { ResponsivePaddingWrapper } from "../../../../layouts/TabLayout"
import { useAssets } from "../../../../providers"
import { useRiskOutlook } from "../provider"

import { DateTime } from "luxon"
import RiskOverviewWidget from "./RiskOverviewWidget"

const RiskOverview = () => {
  const { t } = useTranslate()
  const { isLoading, countries } = useRiskOutlook()
  const { allAssets } = useAssets()

  const riskOverviewQuery = gql`
    query ($region_ids: [String], $asset_ids: [String]) {
      risk_outlook_model: yield_outlook_model(
        filter: {
          region_ids: $region_ids
          asset_ids: $asset_ids
          risk_status: "active"
        }
      ) {
        results {
          region_id
          asset_id
          stages {
            start_date
            end_date
            stage_name
          }
          default_geography {
            hazards {
              id
              hazard_name
              daily_stats: upcoming_risk_daily_stats {
                results {
                  id
                  init_time
                  data {
                    index_value
                    probability_low
                    probability_mid
                    probability_high
                  }
                }
              }
            }
          }
        }
      }
    }
  `

  return (
    <ResponsivePaddingWrapper>
      <div className="flex flex-col h-full overflow-hidden grow gap-4">
        <GenericPageHeader
          pageTitle={t(
            "RiskOutlookSummary",
            "Upcoming risk level to production by country",
          )}
          right={undefined}
          bottom={
            <h1 className="w-full text-end body-sm text-gray-60 dark:text-gray-30">
              {`${t("refreshed")} ${DateTime.now().toLocaleString(
                DateTime.DATE_FULL,
              )}
                            `}
            </h1>
          }
          bottomSectionPadding="pt-0"
        />
        <div className="overflow-y-auto h-3/4">
          <WidgetWrapper
            component={RiskOverviewWidget}
            query={riskOverviewQuery}
            selectors={{
              $data: "risk_outlook_model.results[]",
            }}
            filters={[
              {
                propName: "region_ids",
                value: countries && Object.keys(countries),
                loading: isLoading,
              },
              {
                propName: "asset_ids",
                value: allAssets && Object.keys(allAssets),
              },
            ]}
          />
        </div>
        <h1 className="body-sm text-gray-60 dark:text-gray-30">
          {t(
            "contactToAddRiskCropDescription",
            "Please email your Account Executive or Customer Success contact to add a new crop to Seasonal Risk Outlook.",
          )}
        </h1>
      </div>
    </ResponsivePaddingWrapper>
  )
}

export default RiskOverview
