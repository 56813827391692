import { Row, Table } from "@tanstack/react-table"
import LabelItem from "../../../climateui/components/Labels/LabelItem"
import { CheckboxCell } from "../../../climateui/components/Table/tableUtils"
import { AsteriskIcon } from "../../../climateui/icons"
import { RiskIconHandler } from "../../../climateui/icons/riskIcons"
import { useRiskProfiles } from "../../../providers/RiskProfilesProvider"
import { IRiskProfile } from "../../../types"
import { ThresholdExtract } from "../../../views/Admin/RiskProfiles/riskProfilesTableUtils"

export function StageRiskProfileCell({
  riskProfile,
  inverse = false,
}: {
  riskProfile: IRiskProfile
  inverse?: boolean
}) {
  const { riskProfilesObj } = useRiskProfiles()
  const doExist = !!riskProfilesObj[riskProfile.id]

  return (
    <div className="flex flex-col py-2 gap-1">
      <div
        className={[
          "flex flex-row items-center gap-2",
          inverse ? "justify-start" : "justify-between",
        ].join(" ")}>
        {inverse && (
          <span
            className={[
              "w-5 h-5",
              !doExist ? "fill-gray-30" : "fill-gray-60",
            ].join(" ")}>
            <RiskIconHandler hazardProfiles={riskProfile.hazard_profiles} />
          </span>
        )}

        <div className="flex flex-row items-center gap-1.5">
          <h6 className="label-lg">{riskProfile.name}</h6>
          {!doExist && (
            <span className="fill-accent w-3.5 h-3.5">
              <AsteriskIcon />
            </span>
          )}
        </div>

        {!inverse && (
          <span
            className={[
              "w-5 h-5",
              !doExist ? "fill-gray-30" : "fill-gray-60",
            ].join(" ")}>
            <RiskIconHandler hazardProfiles={riskProfile.hazard_profiles} />
          </span>
        )}
      </div>
      <div className={["flex flex-col gap-1", inverse ? "pl-7" : ""].join(" ")}>
        <ul className="flex flex-col text-gray-60 dark:text-gray-30">
          {riskProfile.hazard_profiles.map((hazardProfile) => (
            <li key={hazardProfile.id}>
              <ThresholdExtract
                hazardProfile={hazardProfile}
                riskType={riskProfile.type}
              />
            </li>
          ))}
        </ul>
        <div className="flex flex-row flex-wrap gap-2">
          {riskProfile.labels?.map((label) => (
            <LabelItem
              label={label}
              key={label.id}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export const columns = [
  {
    id: "selection",
    header: ({ table }: { table: Table<IRiskProfile> }) => (
      <CheckboxCell<IRiskProfile>
        rowData={null}
        status={
          table.getIsAllRowsSelected()
            ? "full"
            : table.getIsSomeRowsSelected()
            ? "half"
            : "empty"
        }
        onChange={table.getToggleAllRowsSelectedHandler() as () => null}
        isGlobal
      />
    ),
    cell: ({ row }: { row: Row<IRiskProfile> }) => (
      <CheckboxCell
        rowData={row.original}
        status={
          row.getIsSelected()
            ? "full"
            : row.getIsSomeSelected()
            ? "half"
            : "empty"
        }
        onChange={row.getToggleSelectedHandler() as () => null}
      />
    ),
  },
  {
    id: "riskProfile",
    cell: ({ row }: { row: Row<IRiskProfile> }) => (
      <StageRiskProfileCell riskProfile={row.original} />
    ),
  },
  {
    accessorKey: "labels",
    filterFn: (row: Row<IRiskProfile>, _: string, filterValue: string[]) => {
      if (filterValue.length === 0) return true
      if (!row.original.labels) return false
      for (const label of row.original.labels) {
        let labelID = ""
        if (typeof label === "string") labelID = label
        else labelID = label.id || ""
        if (filterValue.includes(labelID)) return true
      }
      return false
    },
  },
  {
    accessorKey: "variable",
    filterFn: (
      row: Row<IRiskProfile>,
      columnsId: string,
      filterValue: string,
    ) => {
      if (filterValue === "") return true
      for (const hazard_profile of row.original.hazard_profiles) {
        const variable = hazard_profile.hazard_variable
        if (filterValue === variable.id) return true
      }
      return false
    },
  },
  {
    accessorKey: "name",
    cell: ({ row }: { row: Row<IRiskProfile> }) => {
      return row.original.name
    },
  },
]
