import { DateTime } from "luxon"
import { colorsArr } from "../../../../climateui/utils/colors"
import {
  TimelineAction,
  ITimelineStage,
  IVarietyStage,
} from "../../../../types"

export function buildTimelineStagesFromVarietyStages(
  stages: IVarietyStage[],
  initial_date: Date,
  row_id: string,
  action?: TimelineAction,
): ITimelineStage[] {
  const luxonDate = DateTime.fromJSDate(initial_date)

  return stages.map((stage, index) => {
    return {
      id: stage.id,
      row_id,
      name: stage.name,
      color: stage.color ?? colorsArr[index % colorsArr.length],
      // days_from_start: stage.days_from_start,
      start_date: luxonDate.plus({ days: stage.days_from_start }).toJSDate(),
      duration: stage.duration - 1, // For new BE inclusive behavior
      riskProfileStages: stage.stage_risk_profiles.map((stageRiskProfile) => {
        return {
          id: stageRiskProfile.id,
          row_id,
          risk_profile_id: stageRiskProfile.risk_profile_id,
          risk_profile: stageRiskProfile.risk_profile,
        }
      }),
      action,
    }
  })
}
