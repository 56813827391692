import { useQuery } from "react-query"
import { stageRiskProfileQuerySet } from "../utils/networking"
import { isValidResponse } from "../climateui/utils/http"
import { IRiskProfileAssetMetadata } from "../types"

export function useRiskSettingAssetData(
  riskSettingData: Record<string, string> | undefined,
) {
  return useQuery({
    queryKey: ["riskSettingAssetData"].concat(
      riskSettingData
        ? Object.keys(riskSettingData).map((id: string) => id)
        : [],
    ),
    queryFn: () =>
      stageRiskProfileQuerySet.post({
        path: "/unfolded/search",
        data: {
          filter_by: {
            and: [
              {
                field_name: "stage_risk_profile.StageRiskProfile.id",
                operator: "in",
                field_value: riskSettingData
                  ? Object.keys(riskSettingData)
                  : [],
              },
            ],
          },
        },
      }),
    enabled: !!riskSettingData,
    select: (response) => {
      if (!isValidResponse(response)) return {}
      const riskSettingAssets: Record<string, IRiskProfileAssetMetadata> = {}

      response.data.data.forEach((item: IRiskProfileAssetMetadata) => {
        const riskSettingId = riskSettingData?.[item.id]
        if (!riskSettingId) return
        riskSettingAssets[riskSettingId] = item
      })

      return riskSettingAssets
    },
  })
}
