import React, { useMemo, useState } from "react"
import {
  Bar,
  Cell,
  ComposedChart,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { AxisInterval } from "recharts/types/util/types"
import LoadingAnimation from "../../../../climateui/components/LoadingAnimation"
import ProbabilityHover from "../../../../climateui/components/Widgets/SeasonalWidgets/ProbabilityHover"
import { getProbabilityHoverItems } from "../riskOutlookUtils"
import renderRiskChartReferences from "./renderRiskChartReferences"
import RiskOutlookChartHeader from "./RiskOutlookChartHeader"
import { useChartMouseThreshold } from "./useChartMouseThreshold"
import { LegendInterface } from "./ChartLegend"

interface Point {
  // id: string
  label: string
  value: number
  color?: string
  y?: number
  y0?: number
  y1?: number
  yMin?: number
  yMid?: number
  yMax?: number
}

export interface Plot {
  id: string
  visualization: "bar"
  points: Point[]
  stackId?: string
}

export interface RiskOutlookBarChartProps {
  data: {
    plot: Plot[]
  }
  stacks?: string[]
  domain?: [number, number]
  xTickFormatter?: (value: string) => string
  xAxisInterval?: AxisInterval | number
  loading?: boolean
  legends?: LegendInterface[]
  xLabel?: string
  description?: string
  tooltip?: string
  title?: string
  icon?: string
  isFullSeason?: boolean
  lowerBound?: number
  upperBound?: number
}

interface TooltipProps {
  active?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any[]
  dataKey: string
  lowerBound: number
  upperBound: number
}

const RiskOutlookTooltip: React.FC<TooltipProps> = ({
  active,
  payload,
  dataKey,
  lowerBound,
  upperBound,
}) => {
  if (!active || !payload?.length) return null

  const plot = payload.find((p) => p.name === dataKey)
  if (!plot) return null

  const {
    label,
    index_value = 0,
    probability_low = 0,
    probability_medium = 0,
    probability_high = 0,
  } = plot?.payload?.[`${dataKey}-meta`]?.meta ?? {}

  return (
    <div className="bg-light-bg dark:bg-dark-bg p-2.5 rounded shadow border border-gray-14 dark:border-gray-78">
      <ProbabilityHover
        title="Probability Breakdown by Risk Index"
        leftHeader={label ?? `Risk Index`}
        rightHeader={(index_value ?? 0).toFixed(2).toString()}
        items={getProbabilityHoverItems(
          probability_low ?? 0,
          probability_medium ?? 0,
          probability_high ?? 0,
          lowerBound ?? 0,
          upperBound ?? 0,
          false,
        )}
      />
    </div>
  )
}

const RiskOutlookBarChart: React.FC<RiskOutlookBarChartProps> = ({
  data,
  stacks,
  isFullSeason,
  domain = [0, 100],
  xTickFormatter,
  xAxisInterval,
  loading,
  legends,
  xLabel,
  description,
  tooltip,
  title,
  icon,
  lowerBound = 70,
  upperBound = 90,
}) => {
  // stack (year) selected
  const [stack, setStack] = useState<null | string>(null)
  const transformedData = useMemo(() => {
    // Create a map to store combined values for each label
    const combinedData = new Map()

    // Process each plot's points
    data?.plot?.forEach((plot) => {
      plot.points.forEach((point) => {
        if (!combinedData.has(point.label)) {
          combinedData.set(point.label, {
            name: point.label,
          })
        }
        // Add this plot's value and color to the combined data
        const entry = combinedData.get(point.label)
        entry[`${plot.stackId ?? ""}_${plot.id}`] = point.value
        entry[`${plot.stackId ?? ""}_${plot.id}-color`] = point.color
        entry[`${plot.stackId ?? ""}_${plot.id}-meta`] = point
      })
    })

    // Convert map to array
    return Array.from(combinedData.values())
  }, [data.plot])

  // thresholds for the probability hover
  const { top, right } = useChartMouseThreshold({ width: 350, height: 350 })

  if (loading) {
    return (
      <div className="flex justify-center items-center mt-36 body-lg">
        <LoadingAnimation />
      </div>
    )
  }

  if (transformedData.length === 0) {
    return <div>No data points available for the chart.</div>
  }

  return (
    <>
      <RiskOutlookChartHeader
        title={title}
        tooltip={tooltip}
        icon={icon}
        description={description}
        legends={legends}
      />
      <div>
        <ResponsiveContainer
          width="100%"
          height={350}>
          <ComposedChart
            data={transformedData}
            margin={{ left: 60, bottom: 20, right: 50 }}>
            {/* <Legend
              verticalAlign="top"
              align="right"
              layout="horizontal"
              content={() => renderCustomLegend({ legends })}
            /> */}
            <XAxis
              axisLine={{ stroke: "#dfdfdf" }}
              tickLine={false}
              dataKey="name"
              type="category"
              allowDuplicatedCategory={false}
              strokeWidth={0}
              interval={xAxisInterval ?? 0}
              fontSize={12}
              tick={{ fill: "currentColor" }}
              className="text-gray-60 dark:text-gray-30"
              tickFormatter={xTickFormatter}>
              {xLabel && (
                <Label
                  value={xLabel}
                  position="insideBottom"
                  className="text-gray-60 dark:text-gray-30"
                  fill="currentColor"
                />
              )}
            </XAxis>

            <YAxis
              padding={{ top: 10 }}
              domain={domain}
              axisLine={false}
              tickLine={false}
              tick={false}
              strokeWidth={0}
            />

            <ReferenceLine
              y={0}
              stroke={"#aaa"}
            />

            {/* Low / Medium / High */}
            {renderRiskChartReferences({
              lowerBound,
              upperBound,
            })}

            {stacks?.map((stackId) => {
              return (
                <Bar
                  key={`${stackId}_risk-score-past-events-bar`}
                  dataKey={`${stackId}_risk-score-past-events`}
                  stackId={stackId}
                  barSize={stacks.length > 1 ? 25 : 35}
                  data={transformedData}
                  onMouseEnter={() => stack !== stackId && setStack(stackId)}>
                  {transformedData.map((entry) => (
                    <Cell
                      key={`cell-past-events-${entry.label}`}
                      fill={
                        entry[`${stackId}_risk-score-past-events-color`] ||
                        "#004957"
                      }
                      // Wrong typings for this prop in the recharts library
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      radius={
                        entry[`${stackId}_risk-score-forecast`] === 0
                          ? [4, 4, 0, 0]
                          : [0, 0, 0, 0]
                      }
                    />
                  ))}
                </Bar>
              )
            })}

            {stacks?.map((stackId) => {
              return (
                <Bar
                  key={`${stackId}_risk-score-forecast-bar`}
                  dataKey={`${stackId}_risk-score-forecast`}
                  stackId={stackId}
                  barSize={stacks.length > 1 ? 25 : 35}
                  data={transformedData}
                  onMouseEnter={() => stack !== stackId && setStack(stackId)}>
                  {transformedData.map((entry) => (
                    <Cell
                      key={`cell-forecast-${entry.label}`}
                      fill={
                        entry[`${stackId}_risk-score-forecast-color`] ||
                        "#BF1728"
                      }
                      // Wrong typings for this prop in the recharts library
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      radius={[4, 4, 0, 0]}
                    />
                  ))}
                </Bar>
              )
            })}

            <Tooltip
              allowEscapeViewBox={{ y: true, x: true }}
              reverseDirection={{ y: !top, x: right }}
              offset={10}
              // animation props
              isAnimationActive={true}
              animationDuration={100}
              animationEasing="ease"
              wrapperStyle={{
                zIndex: 1000,
              }}
              formatter={(value, name) => {
                if (typeof value === "number") {
                  return [value.toFixed(2), name]
                }
                return [value, name]
              }}
              content={({ active, payload }) => {
                return (
                  <RiskOutlookTooltip
                    active={active}
                    payload={payload}
                    dataKey={
                      isFullSeason
                        ? `${stack}_risk-score-past-events`
                        : `${stack}_risk-score-forecast`
                    }
                    lowerBound={lowerBound}
                    upperBound={upperBound}
                  />
                )
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}

export default RiskOutlookBarChart
