import { createContext, ReactNode, useContext, useMemo, useState } from "react"
import { useQuery } from "react-query"
import { IBooleanDictionary } from "../climateui/types"
import { CustomResponse, isValidResponse } from "../climateui/utils/http"
import { IPlannedRisk, IStage, IStrategy } from "../types"
import { DAY_MS } from "../utils/constants"
import { gddsGET } from "../utils/networking"

const DAY_START_OF_WEEK = 1 // Monday
export const FIXED_PX_PER_DAY_12_M = 3.17
export const FIXED_PX_PER_DAY_6_M = FIXED_PX_PER_DAY_12_M * 2

function getDaysFromFirstDayOfWeekOfCurrentYear(date: Date, dayOfWeek: number) {
  // Found online

  // Gets the first day of the current year
  const now = new Date()
  const yearStart = new Date(now.getFullYear(), 0, 1)

  // Calculates the number of days until the first occurrence of the specified day of the week
  const daysToDayOfWeek = (dayOfWeek - yearStart.getDay() + 7) % 7
  const firstDayOfWeek = new Date(
    yearStart.getFullYear(),
    0,
    1 + daysToDayOfWeek,
  )

  // Calculates the number of days until the given date from the first occurrence of the specified day of the week
  const diff = date.getTime() - firstDayOfWeek.getTime()
  const oneDay = DAY_MS
  const daysFromFirstDayOfWeek = Math.floor(diff / oneDay)

  return daysFromFirstDayOfWeek
}
function weeksPassed(date: Date) {
  const startDate = new Date(date.getFullYear(), 0, 1)
  const days = Math.floor(
    (date.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000),
  )

  return Math.ceil(days / 7)
}

interface IStrategyCommonProps {
  modifiableStrategies: Record<string, IStrategy>
  setModifiableStrategies: (newStrategies: Record<string, IStrategy>) => void

  deleteStrategy?: (strategy: IStrategy) => void
  duplicateStrategy?: (strategy: IStrategy) => void
  deleteStageOrRisk?: (
    stageOrRisk: IStage | IPlannedRisk,
    type: "stages" | "risks",
  ) => void
}

interface ISeasonalCalendarProvider extends IStrategyCommonProps {
  weekNumbers: number[]
  months: Date[]
  weeksOffset: number
  monthsOffset: number
  timelineStartDate: Date

  dragAuxDate?: Date
  setDragAuxDate: (dragAuxDate?: Date) => void
  openStrategies: IBooleanDictionary
  setOpenStrategies: (openStrategies: IBooleanDictionary) => void

  isEditingCalendar: boolean
  pxPerDay: number
  setPxPerDay: (pxPerDay: number) => void

  focusedStage?: string
  setFocusedStage: (stageId?: string) => void
  focusedPlannedRisk?: string
  setFocusedPlannedRisk: (riskId?: string) => void
  hoveredPlannedRisk?: string
  setHoveredPlannedRisk: (riskId?: string) => void
  editingStage?: IStage
  setEditingStage: (stage?: IStage) => void

  furthestForecastDayNumber: number
}

interface ISeasonalCalendarProviderProps extends IStrategyCommonProps {
  children: ReactNode
  editMode?: boolean
  startDate?: Date
}

const SeasonalCalendarContext = createContext({} as ISeasonalCalendarProvider)

export const useSeasonalCalendar = () => useContext(SeasonalCalendarContext)

function SeasonalCalendarProvider({
  children,
  editMode = false,
  startDate = new Date(),
  modifiableStrategies,
  setModifiableStrategies,
  deleteStrategy,
  duplicateStrategy,
  deleteStageOrRisk,
}: ISeasonalCalendarProviderProps) {
  const [dragAuxDate, setDragAuxDate] = useState<Date>()
  const [openStrategies, setOpenStrategies] = useState<IBooleanDictionary>({})
  const [focusedStage, setFocusedStage] = useState<string | undefined>()
  const [editingStage, setEditingStage] = useState<IStage>()
  const [focusedPlannedRisk, setFocusedPlannedRisk] = useState<
    string | undefined
  >()
  const [hoveredPlannedRisk, setHoveredPlannedRisk] = useState<
    string | undefined
  >()
  const [pxPerDay, setPxPerDay] = useState<number>(
    editMode ? FIXED_PX_PER_DAY_12_M : FIXED_PX_PER_DAY_6_M,
  )

  const { weekNumbers, months, weeksOffset, monthsOffset } = useMemo(() => {
    let weeksCount = weeksPassed(startDate)
    const weekNumbers: number[] = []
    const months: Date[] = []

    let weeksOffset = 0
    let monthsOffset = 0
    let day = new Date()
    for (let i = 0; i < 365; i++) {
      day = new Date(startDate)
      day.setDate(startDate.getDate() + i)

      // WEEKS LOGIC
      if (day.getDay() === DAY_START_OF_WEEK) {
        // Push the number of the week without capping it to 52 weeks
        // That logic is made on render
        weekNumbers.push(weeksCount + 1)
        weeksCount++
      }
      if (weekNumbers.length === 0) {
        weeksOffset++
      }

      // MONTHS LOGIC
      if (day.getDate() === 1) {
        months.push(day)
      }
      if (months.length === 0) {
        monthsOffset++
      }
    }
    if (months.length === 11) {
      day.setDate(startDate.getDate() + 366)
      months.push(day)
    }
    return {
      weekNumbers,
      months,
      weeksOffset,
      monthsOffset,
    }
  }, [startDate])

  // Dummy call to get the last day of forecast available
  const { data: gddsQuery } = useQuery(
    "gddsQuery",
    () => {
      return gddsGET(10, 10)
    },
    {
      enabled: true,
      staleTime: DAY_MS, // Avoids making this call for every plan we load during 1 day.
    },
  )
  // Set the furthest forecast day number we have relative to the first
  // weekday of the current year
  const { furthestForecastDayNumber } = useMemo(() => {
    if (
      gddsQuery === undefined ||
      gddsQuery === null ||
      !isValidResponse(gddsQuery as CustomResponse)
    ) {
      // default date if gddQuery call fails
      const d = new Date()
      d.setMonth(d.getMonth() + 6)
      return {
        furthestForecastDayNumber: getDaysFromFirstDayOfWeekOfCurrentYear(
          d,
          DAY_START_OF_WEEK,
        ),
      }
    }

    // Get the last date of forecast that GDD has
    const lastForecastDate = (gddsQuery as CustomResponse).data.time.slice(-1)
    // Calculate the number of the week for that date, and set it
    return {
      furthestForecastDayNumber: getDaysFromFirstDayOfWeekOfCurrentYear(
        new Date(lastForecastDate),
        DAY_START_OF_WEEK,
      ),
    }
  }, [gddsQuery])

  const providerValue = useMemo(() => {
    return {
      weekNumbers,
      months,
      weeksOffset,
      monthsOffset,
      timelineStartDate: startDate,

      dragAuxDate,
      setDragAuxDate,
      openStrategies,
      setOpenStrategies,

      isEditingCalendar: editMode,
      pxPerDay,
      setPxPerDay,

      focusedStage,
      setFocusedStage,
      focusedPlannedRisk,
      setFocusedPlannedRisk,
      hoveredPlannedRisk,
      setHoveredPlannedRisk,
      editingStage,
      setEditingStage,

      modifiableStrategies,
      setModifiableStrategies,

      furthestForecastDayNumber,

      deleteStrategy,
      duplicateStrategy,
      deleteStageOrRisk,
    }
  }, [
    weekNumbers,
    months,
    weeksOffset,
    monthsOffset,
    focusedStage,
    focusedPlannedRisk,
    hoveredPlannedRisk,
    editingStage,
    openStrategies,
    dragAuxDate,
    editMode,
    modifiableStrategies,
    furthestForecastDayNumber,
    pxPerDay,
  ])

  return (
    <SeasonalCalendarContext.Provider value={providerValue}>
      {children}
    </SeasonalCalendarContext.Provider>
  )
}

export default SeasonalCalendarProvider
