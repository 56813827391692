import { Transition } from "@headlessui/react"
import { useTranslate } from "@tolgee/react"
import { Fragment, useContext, useMemo, useRef, useState } from "react"
import { DraggableElement } from "../../../climateui/components"
import { RiskIconHandler } from "../../../climateui/icons/riskIcons"
import { LocalizationContext } from "../../../providers/LocalizationProvider"
import { useSeasonalCalendar } from "../../../providers/SeasonalCalendarProvider"
import { UnitConversionContext } from "../../../providers/UnitConversionProvider"
import { IPlannedRisk } from "../../../types"
import { daysBetween } from "../../../utils"
import DraggableItem from "./DraggableItem"
import { MIN_STAGE_DURATION, moveDragAuxDate, moveStartDate } from "./utils"

function RiskOptions({
  risk,
  daysFromTimelineStart,
  setPxFromTimelineStart,
  setDuration,
  duration,
  updateRisk,
}: {
  risk: IPlannedRisk
  daysFromTimelineStart: number
  setPxFromTimelineStart: (px: number) => void
  setDuration: (duration: number) => void
  duration: number
  updateRisk: (risk: IPlannedRisk) => void
}) {
  const { timelineStartDate, pxPerDay, setDragAuxDate, dragAuxDate } =
    useSeasonalCalendar()
  const riskDuration = risk.duration ?? MIN_STAGE_DURATION

  return (
    <>
      <DraggableElement
        draggable
        draggingCallback={(pxMoved: number) => {
          const daysMoved = Math.round(pxMoved / pxPerDay)
          if (riskDuration - daysMoved < MIN_STAGE_DURATION) return

          const riskStartDate = new Date(risk.start_date ?? "")
          const movedDate = new Date(riskStartDate)
          movedDate.setDate(movedDate.getDate() + daysMoved)
          if (daysBetween(timelineStartDate, movedDate) < 0) return

          moveStartDate(
            daysFromTimelineStart,
            daysMoved,
            0,
            pxPerDay,
            setPxFromTimelineStart,
          )
          moveDragAuxDate(
            (risk.start_date ?? new Date())?.toISOString(),
            timelineStartDate,
            daysMoved,
            0,
            setDragAuxDate,
          )
          setDuration(riskDuration - daysMoved)
        }}
        dragEndCallback={() => {
          updateRisk({
            ...risk,
            start_date: dragAuxDate,
            duration,
          })
          setDragAuxDate(undefined)
        }}>
        <span className="absolute w-2 h-2 rounded-full cursor-col-resize -left-1 bg-gray-90"></span>
      </DraggableElement>
      <DraggableElement
        draggable
        draggingCallback={(pxMoved: number) => {
          const daysMoved = Math.round(pxMoved / pxPerDay)
          if (riskDuration + daysMoved < MIN_STAGE_DURATION) return

          const stageDate = new Date(risk.start_date ?? "")
          stageDate.setDate(stageDate.getDate() + riskDuration + daysMoved)
          setDragAuxDate(stageDate)
          setDuration(riskDuration + daysMoved)
        }}
        dragEndCallback={() => {
          updateRisk({
            ...risk,
            duration,
          })
          setDragAuxDate(undefined)
        }}>
        <span className="absolute w-2 h-2 rounded-full cursor-col-resize -right-1 bg-gray-90"></span>
      </DraggableElement>
    </>
  )
}

const getDatesRangeName = (
  startDate: Date,
  duration: number,
  monthNames: string[],
) => {
  const endDate = new Date(startDate)
  endDate.setDate(endDate.getDate() + duration - 1)

  const startDateMonthName = monthNames[startDate.getMonth()].slice(0, 3)
  const endDateMonthName = monthNames[endDate.getMonth()].slice(0, 3)

  return (
    startDateMonthName +
    " " +
    startDate.getDate() +
    " - " +
    endDateMonthName +
    " " +
    endDate.getDate()
  )
}

const topTip = (
  <>
    <div className="absolute w-4 h-4 bg-light-bg dark:bg-dark-bg top-[2px] left-1/2 -translate-x-1/2 rotate-45 border border-gray-14 dark:border-gray-78 elevation-1"></div>
    <div className="absolute w-8 h-4 bg-light-bg dark:bg-dark-bg top-[9px] left-1/2 -translate-x-1/2 rounded-lg"></div>
  </>
)
const bottomTip = (
  <>
    <div className="absolute w-4 h-4 bg-light-bg dark:bg-dark-bg bottom-[2px] left-1/2 -translate-x-1/2 -rotate-45 border border-gray-14 dark:border-gray-78 elevation-1"></div>
    <div className="absolute w-8 h-4 bg-light-bg dark:bg-dark-bg bottom-[9px] left-1/2 -translate-x-1/2 rounded-lg"></div>
  </>
)

function RiskImpactDetail({
  date,
  duration,
  risk,
}: {
  date: Date
  duration: number
  risk: IPlannedRisk
}) {
  const { t } = useTranslate()
  const { pxPerDay, timelineStartDate } = useSeasonalCalendar()
  const { convertUnits, getUnit } = useContext(UnitConversionContext)
  const { monthNames } = useContext(LocalizationContext)

  const daysFromTimelineStart = daysBetween(timelineStartDate, date)
  const pxFromTimelineStart = daysFromTimelineStart * pxPerDay
  // Only render the visible width of the bar
  const visibleDays =
    daysFromTimelineStart > 0 ? duration : duration + daysFromTimelineStart
  const mainStyle = {
    width: pxPerDay * visibleDays + "px",
    left: Math.max(pxFromTimelineStart, 0) + "px",
  }

  const [open, setOpen] = useState(false)
  let detailTimeout: NodeJS.Timeout
  function hideDetail() {
    if (open) {
      detailTimeout = setTimeout(() => {
        setOpen(false)
      }, 200)
    }
  }

  function showDetail() {
    clearTimeout(detailTimeout)
    return !open && setOpen(true)
  }

  const riskWidth = parseFloat(mainStyle.width)
  const riskLeft = parseFloat(mainStyle.left)
  const popoverStyle = {
    left: riskLeft + riskWidth / 2 + "px",
  }

  // X dynamic card position logic
  let cardTranslateClasses = ""
  if (daysFromTimelineStart < 7) cardTranslateClasses = "translate-x-[42%]"
  else if (daysFromTimelineStart < 14)
    cardTranslateClasses = "translate-x-[40%]"
  else if (daysFromTimelineStart < 28)
    cardTranslateClasses = "translate-x-[20%]"

  // Y dynamic card position logic
  const cardContainerRef = useRef<HTMLDivElement>(null)
  const { positionClasses, cardTip } = useMemo(() => {
    const screenHeight = window.innerHeight
    const containerBottom =
      cardContainerRef.current?.getBoundingClientRect().bottom || 200
    let positionClasses = "top-full -translate-y-3 pt-2"
    let cardTip = topTip
    if (screenHeight - containerBottom < 200) {
      positionClasses = "bottom-full translate-y-3 pb-2"
      cardTip = bottomTip
    }
    return {
      positionClasses,
      cardTip,
    }
  }, [cardContainerRef, open])

  const getRiskValue = (risk: IPlannedRisk) => {
    const item = risk.hazard_profiles ? risk.hazard_profiles[0] : null
    if (!item) return "-"
    return (
      item.conditional +
      convertUnits(
        item.threshold,
        item.hazard_variable,
        "convertUnits",
      ).toFixed(2) +
      getUnit(item.hazard_variable)
    )
  }

  const hasImpactValues =
    (risk.observed_impact !== null &&
      risk.observed_impact !== undefined &&
      risk.observed_impact !== 0) ||
    (risk.expected_impact !== null &&
      risk.expected_impact !== undefined &&
      risk.expected_impact !== 0)

  return (
    <>
      <div
        className={[
          "absolute -translate-y-1/2 top-1/2 h-4",
          "rounded-sm",
          "transition-all duration-75",
          "border border-white",
          `${
            hasImpactValues
              ? "bg-gray-30/80 hover:bg-gray-60/80"
              : "bg-gray-5/80 hover:bg-gray-14/80"
          }`,
          "z-30",
        ].join(" ")}
        style={mainStyle}
        ref={cardContainerRef}
        onMouseEnter={showDetail}
        onMouseLeave={hideDetail}></div>

      <Transition
        show={open}
        as={Fragment}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-75"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div
            className={"absolute z-40 -translate-x-1/2 " + positionClasses}
            style={popoverStyle}>
            <div
              onMouseEnter={showDetail}
              onMouseLeave={hideDetail}
              className={[
                "bg-light-bg dark:bg-dark-bg overflow-hidden",
                "border border-gray-14 dark:border-gray-78 rounded-lg",
                "elevation-1 relative",
                "w-fit p-4 pl-3 min-w-0",
                "flex flex-row items-start gap-2",
                cardTranslateClasses,
              ].join(" ")}>
              <span className="grow-0 shrink-0 w-6 h-6 fill-gray-60">
                <RiskIconHandler hazardProfiles={risk.hazard_profiles} />
              </span>

              <div className="grow h-full text-left text-light-text dark:text-dark-text">
                <h3 className="body-lg whitespace-nowrap truncate pr-5">
                  {risk.name}
                </h3>
                <p className="body-sm text-gray-60 dark:text-gray-30 mt-1 whitespace-nowrap truncate">
                  {getDatesRangeName(date, duration, monthNames)} •{" "}
                  {risk.location_name ?? t("myLocation")}
                </p>

                <div className="flex items-center justify-between border-b border-gray-14 dark:border-gray-78 mt-3 gap-3 pb-1">
                  <div className="label-sm text-gray-60 dark:text-gray-30 whitespace-nowrap">
                    {risk.hazard_profiles &&
                      t(risk.hazard_profiles[0].hazard_variable.readable_name)}
                  </div>
                  <div className="body-lg">{getRiskValue(risk)}</div>
                </div>
              </div>
            </div>
            {cardTip}
          </div>
        </Transition.Child>
      </Transition>
    </>
  )
}

function Risk({
  risk,
  updateRisk,
  globalCalendarLeft,
}: {
  risk: IPlannedRisk
  updateRisk: (newRisk: IPlannedRisk) => void
  globalCalendarLeft: number
}) {
  const {
    focusedStage,
    focusedPlannedRisk,
    setFocusedPlannedRisk,
    setFocusedStage,
    isEditingCalendar,
  } = useSeasonalCalendar()

  const isFocused = risk.frontend_id === focusedPlannedRisk
  const isStageFocused = focusedStage && risk.stage_id === focusedStage
  const allFocused = focusedPlannedRisk === risk.strategyId

  const doShowRiskDetail = !isEditingCalendar

  return (
    <>
      <DraggableItem
        item={risk}
        updateItem={updateRisk}
        isFocused={isFocused}
        globalCalendarLeft={globalCalendarLeft}
        renderElement={({
          cursorClass,
          mainStyle,
          setPxFromTimelineStart,
          daysFromTimelineStart,
          duration,
          setDuration,
        }) => (
          <div
            className={[
              "absolute -translate-y-1/2 top-1/2 z-20",
              "flex flex-row items-center justify-center",
              "rounded-sm",
              "border border-white",
              "text-[14px] h-4",
              doShowRiskDetail ? "bg-gray-10/80" : "bg-gray-30/80",
              isStageFocused ? "bg-gray-60/80" : "",
              isEditingCalendar && (isFocused || allFocused)
                ? cursorClass + " bg-gray-60/80"
                : "cursor-pointer",
            ].join(" ")}
            onClick={() => {
              if (isEditingCalendar) {
                setFocusedPlannedRisk(risk.frontend_id)
                setFocusedStage(undefined)
              }
            }}
            style={mainStyle}>
            {isFocused && isEditingCalendar && (
              <RiskOptions
                risk={risk}
                daysFromTimelineStart={daysFromTimelineStart}
                setPxFromTimelineStart={setPxFromTimelineStart}
                setDuration={setDuration}
                duration={duration}
                updateRisk={updateRisk}
              />
            )}
          </div>
        )}
      />
      {doShowRiskDetail && (
        <RiskImpactDetail
          date={risk?.start_date as Date}
          duration={risk?.duration ?? 0}
          risk={risk}
        />
      )}
    </>
  )
}

export default Risk
