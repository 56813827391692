/* This is a temporary fix to have unique ids for each plot */

export enum DASHBOARD_VARIABLES {
    TEMP_MAX = "temp_max",
    TEMP_MIN = "temp_min",
    TEMP_MEAN = "temp_mean",
    PRECIPITATION = "precipitation",
    PRECIPITATION_SUM = "precipitation_sum",
    SOIL_TEMPERATURE = "soil_temperature",
    HUMIDITY = "humidity",
    SOLAR_RADIATION = "solar_radiation",
    EVAPOTRANSPIRATION = "evapotranspiration",
    WIND_SPEED = "wind_speed",
}

export enum DATA_SOURCE_VIZUALIZATION_OPTIONS {
    LINE = "line",
    AREA = "area",
    CANDLE = "candle",
    DIRECTIONALITY = "directionality",
    DIRECTIONALITY_QUANTILES = "directionality_quantiles",
}

export interface IQuantiles {
    "quantile_0.05"?: number
    "quantile_0.25"?: number
    "quantile_0.50"?: number
    "quantile_0.75"?: number
    "quantile_0.95"?: number
}

export interface ILineAttributes {
    ensemble_mean: number
}

export interface IAreaAttributes extends IQuantiles {}

export interface ICandleAttributes extends IQuantiles {}

export interface IPoint<TAttribute> {
    attributes: TAttribute
    date: string
    id: number
}

export type TypeDataSourcePoints<TAttribute> = IPoint<TAttribute>[]

export interface IPlotModel<TAttribute> {
    id: string // Added on the fly - Pending to ask backend to add this prop on their end
    color: string
    data_version: string
    dataset: string
    granularity: string
    opacity: number
    points: TypeDataSourcePoints<TAttribute>
    statistics: string[]
    type: string
    units: string
    variable: string
    visualization: string
}

// Specific interface implementations for each visualization type
export interface IPlotModelGeneric
    extends Omit<
        IPlotModel<ILineAttributes | IAreaAttributes | ICandleAttributes>,
        "visualization"
    > {
    visualization: DATA_SOURCE_VIZUALIZATION_OPTIONS
}

export interface IPlotModelLine
    extends Omit<IPlotModel<ILineAttributes>, "visualization"> {
    visualization: DATA_SOURCE_VIZUALIZATION_OPTIONS.LINE
}

export interface IPlotModelArea
    extends Omit<IPlotModel<IAreaAttributes>, "visualization"> {
    visualization: DATA_SOURCE_VIZUALIZATION_OPTIONS.AREA
}

export interface IPlotModelCandle
    extends Omit<IPlotModel<ICandleAttributes>, "visualization"> {
    visualization: DATA_SOURCE_VIZUALIZATION_OPTIONS.CANDLE
}
