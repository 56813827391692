import { Dispatch, SetStateAction, useEffect } from "react"
import { useRiskProfiles } from "../../../../providers/RiskProfilesProvider"
import {
  ConditionForm,
  HazardCondition,
  HazardDSCondition,
} from "./HazardCondition"

type Conditions = ConditionForm[][]
type RiskComponentMappingType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: React.ComponentType<any>
}
type HazardFieldProps = {
  hazards: Conditions
  updateHazards: Dispatch<SetStateAction<Conditions>>
  onLoadStateChange?: (loadState: boolean) => void
  canEditAll?: boolean
  riskType: string
}
export default function HazardField({
  hazards: conditions,
  updateHazards: updateConditions,
  onLoadStateChange,
  canEditAll = true,
  riskType,
}: HazardFieldProps) {
  /* QUERIES */
  const { hazardVariables, areHazardVariablesLoading: areVariablesLoading } =
    useRiskProfiles()

  useEffect(() => {
    if (!onLoadStateChange) return
    onLoadStateChange(areVariablesLoading)
  }, [areVariablesLoading])

  const RiskComponentMapping: RiskComponentMappingType = {
    ds_defined: HazardDSCondition,
    custom: HazardCondition,
  }

  const Component = RiskComponentMapping[riskType] || HazardCondition

  return (
    // This preventDefault is needed to avoid delegating the click event to the first SingleSelect
    <div onClick={(ev) => ev.preventDefault()}>
      {conditions.map((groupConditions, gid) => {
        return (
          <div key={gid + "groups"}>
            <label className="label-sm text-gray-60 dark:text-gray-30">
              <div className="flex items-center justify-between"></div>
              <div className="flex flex-col items-start">
                {groupConditions.map((condForm, id) => (
                  <div key={id + "cond"}>
                    <Component
                      id={id}
                      hazardVariables={hazardVariables}
                      groupId={gid}
                      conditions={conditions}
                      updateConditions={updateConditions}
                      form={condForm}
                      canEditAll={canEditAll}
                    />
                  </div>
                ))}
              </div>
            </label>
          </div>
        )
      })}
    </div>
  )
}
