import { Outlet } from "react-router-dom"
import TabLayout from "../../layouts/TabLayout"
import adminChildRoutes from "./routes"

const adminPaths = ["/admin", "/admin/"]

function AdminTab() {
  return (
    <TabLayout
      redirectPaths={adminPaths}
      basePath="/admin"
      hideOverflow={true}
      sidebarOptions={adminChildRoutes}
      withPadding={false}>
      <Outlet />
    </TabLayout>
  )
}

export default AdminTab
