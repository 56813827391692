import Papa from "papaparse"

export const buildCSVString = (
  csvHeaderSection: string,
  csvHeaders: string[],
  csvData: (string | number)[][],
) => {
  return (
    csvHeaderSection +
    csvHeaders.join(",") +
    "\n" +
    csvData.map((line) => line.join(",")).join("\n")
  )
}

export const downloadTemplateCSV = (
  element: HTMLAnchorElement,
  csvData: (string | number)[][],
  fileName: string,
) => {
  if (element === null) return

  // Set its path to a blob that downloads the template data
  element.href = window.URL.createObjectURL(
    new Blob([Papa.unparse(csvData)], {
      type: "text/csv;charset=utf-8;",
    }),
  )

  // Set the file name by using the download attribute
  element.setAttribute("download", fileName)

  // The file will be downloaded by the browser's default <a> onclick action right after this method executes
}

export const CSV_MULTIPLE_THINGS_SEPARATOR = ";"
