import { useTranslate } from "@tolgee/react"
import { useNavigate } from "react-router-dom"
import { BackgroundAndCardLayout, Button } from "../../climateui/components"

const RestorePasswordConfirmation = () => {
  const navigate = useNavigate()
  const { t } = useTranslate()
  return (
    <BackgroundAndCardLayout copyright={t("copyright")}>
      <div className="pb-2 leading-tight text-center text-light-text dark:text-dark-text title-sm">
        {t("passwordChangedSuccess")}
      </div>
      <Button
        label={t("login")}
        type="primary"
        extend={true}
        onClick={() => {
          navigate("/login")
        }}
      />
    </BackgroundAndCardLayout>
  )
}

export default RestorePasswordConfirmation
