import {
  useState,
  useEffect,
  ReactNode,
  createContext,
  useContext,
} from "react"
import { isCustomResponse } from "../climateui/utils/http"
import { hexToHsl, hslToHex } from "../climateui/utils/colors"
import dashboard from "../skins/template/dashboard"
import page from "../skins/template/page"
import styles from "../skins/template/styles"
import {
  applyDashboardSettings,
  applyPageSettings,
  applyStyles,
} from "../skins/whitelabel"
import { IWhiteLabelPlatform } from "../types"
import { whiteLabelsGET } from "../utils/networking"
import defaultSkin from "../skins/template"
import { CAILoadingScreen } from "../components"

export interface ISkinContext {
  skin: IWhiteLabelPlatform | undefined
}

const SkinContext = createContext<ISkinContext>({} as ISkinContext)
export const useSkin = () => useContext(SkinContext)

function SkinProvider(props: { children: ReactNode }) {
  const { children } = props
  const [loading, setLoading] = useState(true)
  const [skin, setSkin] = useState<IWhiteLabelPlatform | undefined>(undefined)

  const getStylesObject = (skin: IWhiteLabelPlatform) => {
    if (skin === undefined) return styles

    const hsl = hexToHsl(skin.primary_color as string)
    // -10L as the design guide
    hsl.l = hsl.l - 10
    const darkHex = hslToHex(hsl)

    return {
      // Primary color
      "color-primary": skin.primary_color,
      "color-primary-dark": darkHex,
      // Play with opacity. See: https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
      "color-primary-medium": skin.primary_color + "66",
      "color-primary-light": skin.primary_color + "1A",
      // Secondary color
      "color-secondary": "#1c9690",
      "color-secondary-dark": "#146b67",
      "color-secondary-medium": "#a4d5d3",
      "color-secondary-light": "#e8f5f4",
      // Topbar color
      "color-topbar": skin.top_nav_color || "#1A232F",
      // Background image
      "background-signin": `url(${skin.wallpaper})`,
    }
  }

  const getPageSettingsObject = (skin: IWhiteLabelPlatform) => {
    if (skin === undefined) return page
    return {
      // query selector
      "title": {
        // attribute to change
        innerHTML: skin.name,
      },
      "#favicon": {
        href: skin.favicon,
      },
      "#apple-favicon": {
        href: skin.favicon,
      },
    }
  }

  const getDashboardSettingsObject = (skin: IWhiteLabelPlatform) => {
    if (skin === undefined) return dashboard
    return {
      skin: skin.id,
      Logo: () => <img src={skin.logo as string} />,
      trackDemoEmail: true,
      signInLogo: () => (
        <div className="max-w-[200px]">
          <img src={skin.logo as string} />
        </div>
      ),
      appTitleKey: skin.name,
      whiteLabelAccountLogo: skin.logo,
    }
  }

  useEffect(() => {
    // Load skin
    //Set hostname to ClimateAi's whitelabel if running locally
    const hostname: string =
      process.env.REACT_APP_ENV === "local" ||
      process.env.REACT_APP_ENV === "sh"
        ? "climate-platform-dev.web.app"
        : window.location.hostname

    whiteLabelsGET().then((data) => {
      // If accounts data is undefined or an AxiosError, exit
      if (data && isCustomResponse(data)) {
        // If the data is undefined or empty, exit
        let _skin = undefined

        _skin =
          data.data &&
          data.data.length > 0 &&
          data.data.find(
            (whiteLabelPlatform: IWhiteLabelPlatform) =>
              hostname === whiteLabelPlatform.domain,
          )

        // Apply styles
        applyStyles(getStylesObject(_skin))
        // Apply page settings
        applyPageSettings(getPageSettingsObject(_skin))
        // Apply dashboard settings
        applyDashboardSettings(getDashboardSettingsObject(_skin))
        // Done loading skin!

        setSkin(_skin)
        setLoading(false)
      }
    })
  }, [])

  if (loading) return <CAILoadingScreen />
  else
    return (
      <SkinContext.Provider
        value={{
          skin: skin || defaultSkin,
        }}>
        {children}
      </SkinContext.Provider>
    )
}

export default SkinProvider
