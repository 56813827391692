import {
    DragEvent,
    MouseEvent,
    RefObject,
    TouchEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react"

const getPaddingRightFromPixels = (px: number, maxX: number) => {
    return maxX - px
}
const getPaddingLeftFromPixels = (px: number, minX: number) => {
    return px - minX
}
const getPaddingRightFromIndex = (
    index: number,
    step: number,
    count: number
) => {
    return step * (count - index)
}
const getPaddingLeftFromIndex = (index: number, step: number) => {
    return step * index
}

const SliderSlider = ({
    side = "left",
    dragStart,
    dragging,
    dragEnd,
    isDragging,
    extraClasses,
}: {
    side: string
    dragStart: (side: string) => void
    dragEnd: (event: DragEvent<HTMLElement> | TouchEvent<HTMLElement>) => void
    dragging: (event: DragEvent<HTMLElement> | TouchEvent<HTMLElement>) => void
    isDragging: boolean
    extraClasses: string
}) => {
    const selfRef = useRef() as RefObject<HTMLDivElement>

    const [auxElement, setAuxElement] = useState<HTMLElement>()

    const dragOrTouchStart = (
        e: DragEvent<HTMLElement> | TouchEvent<HTMLElement>
    ) => {
        dragStart(side)
        if (!selfRef || selfRef.current === undefined) return
        const fakeLink = (selfRef.current as HTMLElement).cloneNode(
            true
        ) as HTMLElement
        fakeLink.style.opacity = "0"
        setAuxElement(fakeLink)
        document.body.appendChild(fakeLink)
        ;(e as DragEvent<HTMLElement>)?.dataTransfer?.setDragImage(
            fakeLink,
            0,
            0
        )
    }
    const dragOrTouchEnd = (
        e: DragEvent<HTMLElement> | TouchEvent<HTMLElement>
    ) => {
        document.body.removeChild(auxElement as Node)
        dragEnd(e)
    }

    return (
        <div
            ref={selfRef}
            className="w-[0px] flex flex-row justify-center"
            draggable={true}
            onDragStart={dragOrTouchStart}
            onDrag={dragging}
            onDragEnd={dragOrTouchEnd}
            onTouchStart={dragOrTouchStart}
            onTouchMove={dragging}
            onTouchEnd={dragOrTouchEnd}>
            <div
                className={[
                    "shrink-0 h-[14px] w-[14px] rounded-full z-10",
                    "transition-all duration-100",
                    "hover:z-20 hover:elevation-1",
                    "bg-light-bg dark:bg-dark-bg border-[2px] border-accent",
                    isDragging ? "cursor-grabbing" : "cursor-pointer",
                    extraClasses,
                ].join(" ")}></div>
        </div>
    )
}

const DUMMY = { dummy: "dummy" }

const Slider = ({
    id = "",
    options = [],
    selectedBottomIndex = 0,
    selectedTopIndex = 0,
    isDouble = false,
    setSelectedIndexes = () => null,
    showTicks = true,
    fitToWidth = true,
}: {
    id: string
    options: string[] | number[]
    selectedBottomIndex: number
    selectedTopIndex: number
    isDouble: boolean
    setSelectedIndexes: (indexes: number[] | number) => void | null
    showTicks?: boolean
    fitToWidth?: boolean
}) => {
    const [paddingLeft, setPaddingLeft] = useState("0px")
    const [paddingRight, setPaddingRight] = useState("0px")
    const [isDragging, setIsDragging] = useState(false)
    const [endX, setEndX] = useState<number>()
    const [draggedElement, setDraggedElement] = useState("right")
    const [resized, setResized] = useState({ ...DUMMY })

    useEffect(() => {
        const handleResize = () => {
            setResized({ ...DUMMY })
        }
        window.addEventListener("force-resize", handleResize)
        window.addEventListener("resize", handleResize)
        // Wait for the sliders to be created in the DOM
        const auxTimeout = setTimeout(() => handleResize(), 100)
        return () => {
            clearTimeout(auxTimeout)
            window.removeEventListener("force-resize", handleResize)
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const { minX, maxX, step, count } = useMemo(() => {
        const count = options.length - 1
        const minX =
            document
                .getElementById(id + "-slider-step-0")
                ?.getBoundingClientRect()?.x ?? 0
        const maxX =
            document
                .getElementById(id + "-slider-step-" + count)
                ?.getBoundingClientRect()?.x ?? 0
        return {
            count,
            minX,
            maxX,
            step: (maxX - minX) / count,
        }
    }, [options, id, resized])

    useEffect(() => {
        setPaddingLeft(step * selectedBottomIndex + "px")
        setPaddingRight(step * (count - selectedTopIndex) + "px")
    }, [minX, maxX, step, count, selectedTopIndex, selectedBottomIndex])

    const bottomX = useMemo(
        () => minX + step * selectedBottomIndex,
        [minX, step, selectedBottomIndex]
    )
    const topX = useMemo(
        () => (isDouble ? minX + step * selectedTopIndex : maxX),
        [minX, step, selectedTopIndex, maxX, isDouble]
    )

    const dragStart = (de: string) => {
        setDraggedElement(de)
        setIsDragging(true)
    }
    const dragging = useCallback(
        (e: DragEvent<HTMLElement> | TouchEvent<HTMLElement>) => {
            const auxEndX = (e as DragEvent<HTMLElement>).pageX
            if (auxEndX === 0) return

            if (draggedElement === "right") {
                let r = auxEndX
                if (r > maxX) r = maxX
                else if (r < bottomX + step) r = bottomX + step
                setPaddingRight(getPaddingRightFromPixels(r, maxX) + "px")
            } else if (draggedElement === "left") {
                let l = auxEndX
                if (l < minX) l = minX
                else if (l > (isDouble ? topX - step : topX))
                    l = isDouble ? topX - step : topX
                setPaddingLeft(getPaddingLeftFromPixels(l, minX) + "px")
            }
        },
        [draggedElement, maxX, minX, bottomX, topX, step, isDouble]
    )
    const dragEnd = (e: DragEvent<HTMLElement> | TouchEvent<HTMLElement>) => {
        setIsDragging(false)
        setEndX((e as DragEvent<HTMLElement>).pageX)
    }

    const tickClick = useCallback(
        (index: number) => {
            const leftDiff = Math.abs(selectedBottomIndex - index)
            const rightDiff = Math.abs(selectedTopIndex - index)

            if (isDouble && rightDiff < leftDiff) {
                setSelectedIndexes([selectedBottomIndex, index])
            } else {
                setSelectedIndexes(isDouble ? [index, selectedTopIndex] : index)
            }
        },
        [maxX, minX, bottomX, topX, step, isDouble]
    )

    const barClick = useCallback(
        (e: MouseEvent<HTMLElement>) => {
            const auxEndX = (e as DragEvent<HTMLElement>).pageX
            if (auxEndX === 0) return

            const leftDiff = Math.abs(bottomX - auxEndX)
            const rightDiff = Math.abs(topX - auxEndX)

            if (isDouble && rightDiff < leftDiff) {
                let r = auxEndX
                if (r > maxX) r = maxX
                else if (r < bottomX + step) r = bottomX + step
                setDraggedElement("right")
                setEndX(r)
            } else {
                let l = auxEndX
                if (l < minX) l = minX
                else if (l > (isDouble ? topX - step : topX))
                    l = isDouble ? topX - step : topX
                setDraggedElement("left")
                setEndX(l)
            }
        },
        [maxX, minX, bottomX, topX, step, isDouble]
    )

    const snapSliderIndex = useCallback(() => {
        for (let i = 0; i <= count; i++) {
            const leftTick = minX + i * step
            const rightTick = minX + (i + 1) * step

            if (endX && endX >= leftTick && endX < rightTick) {
                const leftDiff = Math.abs(endX - leftTick)
                const rightDiff = Math.abs(endX - rightTick)
                return leftDiff > rightDiff ? i + 1 : i
            }
        }
        return
    }, [endX, count, minX, step])

    useEffect(() => {
        if (!endX) return
        const initValue = snapSliderIndex()
        if (draggedElement === "right") {
            let r = endX
            if (r > maxX) r = count
            else if (r < bottomX + step) r = selectedBottomIndex + 1
            else r = initValue ?? selectedTopIndex
            setPaddingRight(getPaddingRightFromIndex(r, step, count) + "px")
            setSelectedIndexes([selectedBottomIndex, r])
        } else if (draggedElement === "left") {
            let l = endX
            if (l < minX) l = 0
            else if (l > (isDouble ? topX - step : topX))
                l = isDouble ? selectedTopIndex - 1 : count
            else l = initValue ?? selectedBottomIndex
            setPaddingLeft(getPaddingLeftFromIndex(l, step) + "px")
            setSelectedIndexes(isDouble ? [l, selectedTopIndex] : l)
        }
    }, [endX])

    return (
        <div
            className="flex flex-row items-center w-full py-3 cursor-pointer group"
            onClick={barClick}>
            <div className="relative flex flex-row items-center justify-between w-full h-1 rounded-full bg-gray-14 group-hover:bg-gray-3 dark:bg-gray-88 dark:hover:bg-gray-880/60">
                {options.map((option, index) => (
                    <div
                        className="relative w-0 h-0"
                        id={id + "-slider-step-" + index}
                        key={id + "-slider-step-" + index}>
                        <button
                            onClick={() => tickClick(index)}
                            className={[
                                "w-1 h-1",
                                "focus:ring-accent ring-4 ring-transparent",
                                "focus:outline-accent",
                                "bg-gray-30 rounded-full",
                                "select-none pointer-events-none",
                                "absolute -translate-y-1/2 -translate-x-1/2",
                                !showTicks ? "opacity-0" : "",
                            ].join(" ")}></button>
                        <div
                            className={[
                                "body-sm text-gray-60 dark:text-gray-30",
                                "select-none pointer-events-none",
                                "absolute -translate-x-1/2 top-2",
                                index === 0 && fitToWidth
                                    ? "translate-x-0"
                                    : "",
                                index === options.length - 1 && fitToWidth
                                    ? "-translate-x-full"
                                    : "",
                                !showTicks ? "opacity-0" : "",
                            ].join(" ")}>
                            {option}
                        </div>
                    </div>
                ))}
                <div
                    className={
                        "absolute h-1 flex flex-row items-center w-full overflow-visible " +
                        (isDragging ? "" : "transition-all duration-75")
                    }
                    style={{ paddingLeft, paddingRight }}>
                    <SliderSlider
                        side="left"
                        dragStart={dragStart}
                        dragging={dragging}
                        dragEnd={dragEnd}
                        isDragging={isDragging}
                        extraClasses={
                            isDouble && selectedTopIndex === selectedBottomIndex
                                ? "-translate-x-1/3 hover:scale-[1.1]"
                                : ""
                        }
                    />
                    {isDouble && (
                        <>
                            <div className="h-1 grow bg-accent"></div>
                            <SliderSlider
                                side="right"
                                dragStart={dragStart}
                                dragging={dragging}
                                dragEnd={dragEnd}
                                isDragging={isDragging}
                                extraClasses={
                                    isDouble &&
                                    selectedTopIndex === selectedBottomIndex
                                        ? "translate-x-1/3 hover:scale-[1.1]"
                                        : ""
                                }
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Slider
