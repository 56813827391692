export function capitalizeFirstCharacter(input_string) {
  return input_string[0].toUpperCase() + input_string.substring(1)
}

export function capitalizeFirstCharacterOfEachWord(sentence) {
  const words = sentence.split(" ")

  return words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1)
    })
    .join(" ")
}

export function toCamelCase(str) {
  // validate
  if (!str || typeof str !== "string")
    throw new TypeError("You must pass a valid string.")

  return str
    .split("-")
    .map((w, i) => (i === 0 && w) || w[0].toUpperCase() + w.substring(1))
    .join("")
}

export function normalizeString(str) {
  if (!str) return str

  return str.trim().toLowerCase()
}

export function toLabelName(str) {
  if (!str) return str
  return normalizeString(str).replace(" ", "_")
}
