import { useTranslate } from "@tolgee/react"
import { ReactNode, useContext, useEffect } from "react"
import { yieldOutlookQuerySet } from "../../../utils/networking/yield"
import { useUI } from "../../../providers/UIProvider"
import {
  useProviderBase,
  YieldOutlookContext,
} from "../../../hooks/YieldOutlook/useProviderBase"

export const useYieldOutlook = () => useContext(YieldOutlookContext)

function YieldOutlookProvider({ children }: Readonly<{ children: ReactNode }>) {
  /* PROVIDERS ------------------------------------------------------------ */
  const { t } = useTranslate()

  const { setSecondSidebarOptions } = useUI()
  /* HOOKS ---------------------------------------------------------------- */
  // TODO add the `productStatusKey` prop once Adapt models' status
  // are updated
  const providerValue = useProviderBase({
    querySet: yieldOutlookQuerySet,
    productKey: "adapt_yield_outlook",
    navigateToFirstModel: true,
  })
  const { subRoutes } = providerValue
  /* LIFECYCLE HOOKS ------------------------------------------------------ */
  useEffect(() => {
    setSecondSidebarOptions((prev) => ({
      ...prev,
      climate: {
        ...prev["climate"],
        outlook: {
          title: t("yieldOutlook", "Yield Outlook"),
          routes: subRoutes,
          createRoutePath: (route) => {
            const basePath = "/climate/outlook"
            if (!route) return basePath

            return (
              basePath + "/" + (typeof route === "string" ? route : route.path)
            )
          },
          emptyMsg: "",
          plusButtonTooltip: "",
          autoEnterFirstOption: true,
          cannotGoBackToMain: true,
        },
      },
    }))
  }, [subRoutes])

  return (
    <YieldOutlookContext.Provider value={providerValue}>
      {children}
    </YieldOutlookContext.Provider>
  )
}

export default YieldOutlookProvider
