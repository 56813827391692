import { ReactNode } from "react"
import { MapLayersIcon, MinusIcon, PlusIcon } from "../../icons"

interface ICustomMapControls {
    map: mapboxgl.Map
    toggleStyle?: () => void
    miniMap?: ReactNode
}

const CustomMapControls = ({
    map,
    toggleStyle,
    miniMap,
}: ICustomMapControls) => {
    return (
        <div className="absolute z-20 bottom-2 right-2 flex flex-col items-end">
            <div className="cursor-pointer rounded-lg w-[36px] flex flex-col justify-center items-center overflow-hidden border-[1px] border-gray-14 dark:border-gray-78 divide-y-[1px] divide-gray-10">
                <div
                    className="w-full h-[36px] bg-light-bg dark:bg-dark-bg hover:bg-gray-10 flex justify-center items-center"
                    onClick={() => {
                        map.zoomIn()
                    }}>
                    <span className="w-[24px] h-[24px] fill-gray-60">
                        <PlusIcon />
                    </span>
                </div>
                <div
                    className="w-full h-[36px] bg-light-bg dark:bg-dark-bg hover:bg-gray-10 flex justify-center items-center"
                    onClick={() => {
                        map.zoomOut()
                    }}>
                    <span className="w-[24px] h-[24px] fill-gray-60">
                        <MinusIcon />
                    </span>
                </div>
            </div>
            {toggleStyle !== undefined && (
                <div
                    className="cursor-pointer rounded-lg mt-2 flex justify-center items-center border-[1px] border-gray-14 dark:border-gray-78 overflow-hidden"
                    onClick={toggleStyle}>
                    {miniMap ? (
                        miniMap
                    ) : (
                        <div className="bg-light-bg dark:bg-dark-bg h-[36px] w-[36px] flex justify-center items-center hover:bg-gray-10 ">
                            <span className="w-[24px] h-[24px] fill-gray-60">
                                <MapLayersIcon />
                            </span>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default CustomMapControls
