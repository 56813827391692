import { useTranslate } from "@tolgee/react"
import { isEmpty } from "lodash"
import Lottie from "lottie-react"
import { useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"
import { useOutletContext } from "react-router-dom"
import { Table } from "../../../climateui/components"
import EmptyModal from "../../../climateui/providers/Modal/EmptyModal"
import { useToast } from "../../../climateui/providers/Toast/ToastContextProvider"
import { isCustomResponse } from "../../../climateui/utils/http"
import climateAILogoIconAnimation from "../../../components/ClimateAi_Symbol_Animation.json"
import useAnalogsSearchLocationQuery from "../../../hooks/useAnalogsSearchLocationQuery"
import { useAnalogsQueue, useAuth } from "../../../providers"
import { useAccount } from "../../../providers/AccountProvider"
import { useExplorationTool } from "../../../providers/ExplorationToolProvider"
import { useLocale } from "../../../providers/LocalizationProvider"
import { useLocations } from "../../../providers/LocationsProvider"
import { IAnalog, IAnalogsSearchPayload } from "../../../types"
import { HOUR_MS } from "../../../utils/constants"
import { analogsSearchPOST } from "../../../utils/networking"
import ExplorationToolSearchBar from "./components/ExplorationToolSearchBar"
import {
  addToMyLocationTableColumn,
  analogsTableColumns,
  formatAnalogsMonths,
} from "./explorationUtils"

const buildDecades = (payload: IAnalogsSearchPayload) => {
  if (!payload.discovery_decades) return ""
  return payload.discovery_decades[0] + "-" + payload.discovery_decades[1]
}

const buildVariableIndexes = (rawAnalog: Record<string, number | number[]>) => {
  return {
    t2m_max: +rawAnalog.t2m_max,
    t2m_min: +rawAnalog.t2m_min,
    tp: +rawAnalog.tp,
  }
}

export default function ExplorationToolSearchBySimilarity() {
  const { createAnalogsQuery, setRememberedAnalogsSearch } = useAnalogsQueue()
  const { selectedAccount } = useAccount()
  const auth = useAuth()
  const { selectedLocationId } = useExplorationTool()
  const { locationsObj } = useLocations()
  const { enqueueAlert } = useToast()
  const {
    payload: searchConfiguration,
    setPayload: setSearchConfiguration,
    setAnalogs,
    analogsRows,
    analogsPerformed,
  } = useOutletContext<{
    payload: IAnalogsSearchPayload
    setPayload: (payload: IAnalogsSearchPayload) => void
    setAnalogs: (analogs: IAnalog[]) => void
    analogsRows: IAnalog[]
    analogsPerformed: boolean
  }>()
  const { monthNames } = useLocale()
  const { t } = useTranslate()

  const [isLoadingAnalogsQuery, setIsLoadingAnalogsQuery] =
    useState<boolean>(false)

  const { payload, noExclusionRadiusPayload } = useMemo(() => {
    if (!searchConfiguration)
      return { payload: undefined, noExclusionRadiusPayload: undefined }

    const newPayload = { ...searchConfiguration } // To do not mess with state directly
    const newPayloadNoExRa = { ...newPayload }
    if (newPayloadNoExRa?.exclusionRadius !== undefined)
      delete newPayloadNoExRa.exclusionRadius
    return {
      payload: newPayload,
      noExclusionRadiusPayload: newPayloadNoExRa,
    }
  }, [searchConfiguration])

  const { data: searchQuery } = useQuery({
    queryKey: ["analogs", JSON.stringify(noExclusionRadiusPayload)],
    queryFn: () => {
      setIsLoadingAnalogsQuery(true)
      return analogsSearchPOST(noExclusionRadiusPayload)
    },
    enabled: noExclusionRadiusPayload !== undefined,
    staleTime: HOUR_MS,
  })

  const [analogsSearchLocation, setAnalogsSearchLocation] = useState<string>("")

  const displayError = () => {
    enqueueAlert(
      t(
        "thereWasAnErrorSearchingAnalogs",
        "There was an error while searching for your analogs.",
      ),
    )
    setIsLoadingAnalogsQuery(false)
  }

  useEffect(() => {
    if (!payload || !searchQuery || !isCustomResponse(searchQuery)) return

    if (!searchQuery.data || !searchQuery.data.location) displayError()
    else {
      setAnalogsSearchLocation(searchQuery.data.location)
      createAnalogsQuery(searchQuery.data.location, { ...payload })
    }
  }, [searchQuery])

  const { data: analogsQuery } = useAnalogsSearchLocationQuery(
    analogsSearchLocation,
  )

  useEffect(() => {
    if (!payload || !analogsQuery || !isCustomResponse(analogsQuery)) return

    if (analogsQuery.status === 204 || analogsQuery.status === 500)
      // 204 - analog not found
      // 500 - server error
      displayError()
    else if (analogsQuery.status === 202 && analogsQuery.data.location)
      // 202 - analog is being processed
      setAnalogsSearchLocation(analogsQuery.data.location)
    else if (analogsQuery.status === 200 && analogsQuery.data.data) {
      // 200 - data retrieved successfully
      const newAnalogs: IAnalog[] = []
      const queryData = analogsQuery.data.data
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      queryData.forEach((rawAnalog: any) => {
        const [lat, lon] = rawAnalog.coord
          .replaceAll("(", "") // improve with chatGPT
          .replaceAll(")", "")
          .replaceAll(" ", "")
          .split(",")
        newAnalogs.push({
          lat: +lat,
          lon: +lon,
          decades: buildDecades(payload),
          months: formatAnalogsMonths(rawAnalog.analog_month, monthNames),
          value: rawAnalog.value,
          variableIndexes: buildVariableIndexes(rawAnalog),
          refLat: payload.lat,
          refLon: payload.lon,
        })
      })
      setAnalogs(newAnalogs)
      setRememberedAnalogsSearch({
        ...payload,
      })
      setIsLoadingAnalogsQuery(false)
    }
  }, [analogsQuery])

  const locationName =
    selectedLocationId &&
    locationsObj &&
    !isEmpty(locationsObj) &&
    locationsObj[selectedLocationId].name
      ? locationsObj[selectedLocationId].name
      : ""

  const columns = useMemo(() => {
    const columns = [...analogsTableColumns]
    if (selectedAccount && auth.hasRole(selectedAccount, "admin"))
      columns.push(addToMyLocationTableColumn)
    return columns
  }, [selectedAccount])

  return (
    <>
      <div className="flex flex-col w-full h-full border-t border-gray-14 dark:border-gray-78">
        <ExplorationToolSearchBar searchCallback={setSearchConfiguration} />
        <div className="flex flex-col p-4 overflow-hidden gap-2 grow">
          <h3 className="title-sm text-light-text dark:text-dark-text shrink-0">
            {selectedLocationId
              ? t("locationsSimilarToNAME", { locationName })
              : t("searchForSimilarLocations")}
          </h3>

          <div className="overflow-y-auto grow">
            <Table<IAnalog>
              columns={columns}
              data={analogsRows}
              noDataMessage={
                analogsPerformed && !isLoadingAnalogsQuery
                  ? t("noAnalogsFound", "We could not found any analogs")
                  : t("performAnAnalogsSearch", "Perform an Analogs Search")
              }
            />
          </div>
        </div>
      </div>

      <EmptyModal
        open={isLoadingAnalogsQuery}
        customClasses="h-[200px] w-[360px] relative rounded-lg bg-light-bg dark:bg-dark-bg p-4">
        <div className="w-16">
          <Lottie animationData={climateAILogoIconAnimation} />
        </div>
        <h3 className="mt-2 title-sm text-light-text dark:text-dark-text">
          {t("discoveringSimilarLocations", "Discovering Similar Locations...")}
        </h3>
        <p className="mt-3 body-md text-gray-60 dark:text-gray-30">
          {t(
            "lookingForSimilarLocations",
            "We are looking for locations that match your search criteria.",
          )}
        </p>
      </EmptyModal>
    </>
  )
}
