import { useTranslate } from "@tolgee/react"
import { isEmpty } from "lodash"
import { ReactNode, useContext, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Button, LabelAndInput } from "../../../../climateui/components"
import {
  MultiDropdownSelect,
  SingleDropdownSelect,
} from "../../../../climateui/components/Inputs"
import { LegacyMultiSelectLikeToggler } from "../../../../climateui/components/Inputs/DropdownSelect/togglers"
import MonthRangePicker, {
  shadedMonthIndexes,
} from "../../../../climateui/components/MonthRangePicker"
import { useOutsideComponentClickHandler } from "../../../../climateui/hooks"
import { LocationPinIcon } from "../../../../climateui/icons"
import { IBooleanDictionary } from "../../../../climateui/types"
import getVariableIcon from "../../../../climateui/utils/icons/getVariableIcon"
import { useAnalogsQueue } from "../../../../providers"
import { useExplorationTool } from "../../../../providers/ExplorationToolProvider"
import {
  LocalizationContext,
  useLocale,
} from "../../../../providers/LocalizationProvider"
import { useLocations } from "../../../../providers/LocationsProvider"
import { useUnits } from "../../../../providers/UnitConversionProvider"
import { IAnalogsSearchPayload } from "../../../../types"
import {
  COUNTRIES_AND_CONTINENTS_FOR_ANALOGS,
  MONTH_INDEXES,
} from "../../../../utils/constants"
import AdvancedSearchModal from "./AdvancedSearchModal"

const getGenericDecadesInv = (
  decadesRange: number[],
  options: number[],
  def: number[],
) => {
  if (decadesRange.length === 0) return def
  return [
    options.findIndex((option) => option === decadesRange[0]),
    options.findIndex((option) => option === decadesRange[1]),
  ]
}

const getGenericDecades = (
  decadesIndexes: number[],
  options: number[],
  def: number[],
) => {
  if (decadesIndexes.length === 0) return def
  return [options[decadesIndexes[0]], options[decadesIndexes[1]]]
}
const getDictGeneric = (
  selected: IBooleanDictionary,
  options: Record<string, string>,
  def: string[],
) => {
  const sel = Object.keys(selected)
  const opt = Object.keys(options)
  if (sel.length === opt.length || sel.length === 0) return def
  return sel
}

const SCENARIO = "usual"
const EXCLUSION_RADIUS = "500"
const MONTH_RANGE_INDEXES = [0, 11]
const DECADE_OPTIONS = [2010, 2020, 2030, 2040, 2050, 2060]
const REFERENCE_INDEXES = [0, 1]
const DISCOVERY_INDEXES = [1, 2]
const REGION_OPTIONS: Record<string, string> = {}
COUNTRIES_AND_CONTINENTS_FOR_ANALOGS.forEach((country) => {
  REGION_OPTIONS[country] = country
})
const REGIONS = Object.keys(REGION_OPTIONS).reduce(
  (acc, curr) => ({ ...acc, [curr]: true }),
  {},
)

function ExplorationToolSearchBar({
  searchCallback,
}: {
  searchCallback: (payload: IAnalogsSearchPayload) => void
}) {
  const { t } = useTranslate()
  const { selectedLocationId } = useExplorationTool()
  const { locations, locationsObj } = useLocations()
  const { language } = useLocale()
  const { unitName } = useUnits()
  const { monthNames } = useContext(LocalizationContext)
  const { rememberedAnalogsSearch } = useAnalogsQueue()
  const [params, setParams] = useSearchParams()

  const instantSearch = params.get("instantSearch")

  const LOCATION_OPTIONS = useMemo(() => {
    const LOCATION_OPTIONS: Record<string, string> = {}
    locations.forEach(
      (location) => (LOCATION_OPTIONS[location.id || ""] = location.name),
    )
    return LOCATION_OPTIONS
  }, [locations])
  const { VARIABLE_OPTIONS, VARIABLE_ICONS, SCENARIO_OPTIONS } = useMemo(
    () => ({
      VARIABLE_OPTIONS: {
        t2m_max: t("maxTemperature", "Max Temp"),
        t2m_min: t("minTemperature", "Min Temp"),
        tp: t("precipitation", "Precipitation"),
      } as Record<string, string>,
      VARIABLE_ICONS: {
        t2m_max: getVariableIcon("t2m_max"),
        t2m_min: getVariableIcon("t2m_min"),
        tp: getVariableIcon("tp"),
      } as Record<string, ReactNode>,
      SCENARIO_OPTIONS: {
        worst: t("highEmissionScenario", "High emission scenario"),
        usual: t("middleOfTheRoad", "Middle of the road"),
        best: t("lowEmissionScenario", "Low emission scenario"),
      } as Record<string, string>,
    }),
    [language],
  )
  const EXCLUSION_OPTIONS = useMemo(
    () =>
      ({
        0: (unitName === "metric" ? 0 : 10) + "", // + "" to make it string
        250: (unitName === "metric" ? 250 : 155) + "",
        500: (unitName === "metric" ? 500 : 311) + "",
        750: (unitName === "metric" ? 750 : 466) + "",
        1000: (unitName === "metric" ? 1000 : 621) + "",
      } as Record<string, string>),
    [language, unitName],
  )

  const VARIABLES = useMemo(
    () =>
      Object.keys(VARIABLE_OPTIONS).reduce(
        (acc, curr) => ({ ...acc, [curr]: true }),
        {},
      ),
    [VARIABLE_OPTIONS],
  )

  const [showAdvancedMode, setShowAdvancedMode] = useState(false)
  const advancedModeModalRef = useOutsideComponentClickHandler(() => {
    if (showAdvancedMode) setShowAdvancedMode(false)
  })

  const [locationId, setLocationId] = useState<string | null | undefined>(
    selectedLocationId ?? "",
  )
  const [regions, setRegions] = useState<IBooleanDictionary>({ ...REGIONS })
  const [variables, setVariables] = useState<IBooleanDictionary>({
    ...VARIABLES,
  })
  const [referenceDecades, setReferenceDecades] = useState<number[]>([
    ...REFERENCE_INDEXES,
  ])
  const [discoveryDecades, setDiscoveryDecades] = useState<number[]>([
    ...DISCOVERY_INDEXES,
  ])
  const [scenario, setScenario] = useState<string | null | undefined>(SCENARIO)
  const [exclusionRadius, setExclusionRadius] = useState<
    string | null | undefined
  >(EXCLUSION_RADIUS)
  const [monthsRange, setMonthsRange] = useState<(number | null)[]>([
    ...MONTH_RANGE_INDEXES,
  ])

  const [prevPayload, setPrevPayload] = useState<IAnalogsSearchPayload>()

  const toBooleanDict = (items?: string[]): IBooleanDictionary => {
    if (!items) return {}
    return items.reduce((acc, curr) => ({ ...acc, [curr]: true }), {})
  }

  useEffect(() => {
    if (rememberedAnalogsSearch) {
      const payload = rememberedAnalogsSearch
      const location = locations.find((loc) => {
        return loc.latitude === payload.lat && loc.longitude === payload.lon
      })
      if (location?.id) setLocationId(location.id)

      setRegions(
        payload.regions?.includes("Global")
          ? { ...REGIONS }
          : toBooleanDict(payload.regions),
      )

      setVariables(toBooleanDict(payload.variables))

      setReferenceDecades(
        getGenericDecadesInv(payload.reference_decades ?? [], DECADE_OPTIONS, [
          2010,
        ]),
      )
      setDiscoveryDecades(
        getGenericDecadesInv(
          payload.discovery_decades ?? [],
          DECADE_OPTIONS,
          [2020, 2030],
        ),
      )

      setScenario(payload.scenario ?? SCENARIO)
      setExclusionRadius(payload.exclusionRadius ?? EXCLUSION_RADIUS)
      let monthsRange = payload.months
      if (monthsRange)
        monthsRange = [
          monthsRange[0] - 1,
          monthsRange[monthsRange.length - 1] - 1,
        ]
      setMonthsRange(monthsRange ?? [...MONTH_RANGE_INDEXES])
    }
  }, [rememberedAnalogsSearch])

  useEffect(() => {
    if (selectedLocationId) setLocationId(selectedLocationId)
  }, [selectedLocationId])

  const discoveryDecadesPayload = useMemo(
    () => getGenericDecades(discoveryDecades, DECADE_OPTIONS, [2020, 2030]),
    [discoveryDecades],
  )
  const referenceDecadesPayload = useMemo(
    () => getGenericDecades(referenceDecades, DECADE_OPTIONS, [2010]),
    [referenceDecades],
  )
  const regionsPayload = useMemo(
    () => getDictGeneric(regions, REGION_OPTIONS, ["Global"]),
    [regions],
  )
  const variablesPayload = useMemo(
    () =>
      getDictGeneric(variables, VARIABLE_OPTIONS, ["t2m_max", "t2m_min", "tp"]),
    [variables],
  )
  const monthsPayload = useMemo(() => {
    const [start, end] = monthsRange
    const months = shadedMonthIndexes(start, end).map((index) => index + 1)
    if (months.length === 0) return MONTH_INDEXES.map((index) => index + 1)
    return months
  }, [monthsRange])

  const buildPayload = () => {
    return {
      lat:
        locationId && !isEmpty(locationsObj)
          ? locationsObj[locationId].latitude
          : 0,
      lon:
        locationId && !isEmpty(locationsObj)
          ? locationsObj[locationId].longitude
          : 0,
      scenario,
      reference_decades: referenceDecadesPayload,
      discovery_decades: discoveryDecadesPayload,
      regions: regionsPayload,
      months: monthsPayload,
      variables: variablesPayload,
      exclusionRadius,
    } as IAnalogsSearchPayload
  }

  const defaultPayload = useMemo(() => {
    return buildPayload()
  }, [locationId, locationsObj])

  const buildAnalogRequest = () => {
    if (!locationId || !locationsObj || isEmpty(locationsObj)) return

    setParams({ locationId })
    const payload: IAnalogsSearchPayload = buildPayload()
    setPrevPayload(payload)
    searchCallback(payload)
    setShowAdvancedMode(false)
  }

  useEffect(() => {
    if (instantSearch) buildAnalogRequest()
  }, [instantSearch, locationId])

  const varOptsArr = Object.keys(variables)
  const allVariablesSelected =
    varOptsArr.length === Object.keys(VARIABLE_OPTIONS).length
  const someVariablesSelected = varOptsArr.length > 0
  let allVariablesCheckboxStatus = "empty"
  if (allVariablesSelected) allVariablesCheckboxStatus = "full"
  else if (someVariablesSelected) allVariablesCheckboxStatus = "half"

  const { locationChange, basicChanges, advancedChanges, defaultChanges } =
    useMemo(() => {
      const hasChanges = {
        locationChange: false,
        basicChanges: false,
        advancedChanges: false,
        defaultChanges: false,
      }
      const checkChanges = (
        payload: IAnalogsSearchPayload,
        basicChangesName: "basicChanges" | "defaultChanges",
        advancedChangesName: "advancedChanges" | "defaultChanges",
      ) => {
        hasChanges[basicChangesName] ||=
          JSON.stringify(payload.regions) !== JSON.stringify(regionsPayload)
        hasChanges[basicChangesName] ||=
          JSON.stringify(payload.variables) !== JSON.stringify(variablesPayload)
        hasChanges[basicChangesName] ||=
          JSON.stringify(payload.discovery_decades) !==
          JSON.stringify(discoveryDecadesPayload)

        hasChanges[advancedChangesName] ||= payload.scenario !== scenario
        hasChanges[advancedChangesName] ||=
          JSON.stringify(payload.reference_decades) !==
          JSON.stringify(referenceDecadesPayload)
        hasChanges[advancedChangesName] ||=
          JSON.stringify(payload.months) !== JSON.stringify(monthsPayload)
        hasChanges[advancedChangesName] ||=
          payload.exclusionRadius !== exclusionRadius
      }

      checkChanges(defaultPayload, "defaultChanges", "defaultChanges")
      if (!locationId || !prevPayload || isEmpty(locationsObj)) {
        if (!prevPayload) hasChanges.basicChanges = true
        return hasChanges
      }

      const { latitude: lat, longitude: lon } = locationsObj[locationId]
      hasChanges.locationChange ||=
        prevPayload.lat !== lat || prevPayload.lon !== lon
      checkChanges(prevPayload, "basicChanges", "advancedChanges")
      hasChanges.basicChanges ||= hasChanges.advancedChanges

      return hasChanges
    }, [
      locationId,
      locationsObj,
      prevPayload,
      defaultPayload,
      regionsPayload,
      variablesPayload,
      discoveryDecadesPayload,
      referenceDecadesPayload,
      monthsPayload,
      exclusionRadius,
      scenario,
    ])
  const canSubmit = locationId !== "" && (basicChanges || locationChange)

  const resetToDefault = () => {
    setRegions({ ...REGIONS })
    setVariables({ ...VARIABLES })
    setReferenceDecades([...REFERENCE_INDEXES])
    setDiscoveryDecades([...DISCOVERY_INDEXES])
    setScenario(SCENARIO)
    setExclusionRadius(EXCLUSION_RADIUS)
    setMonthsRange([...MONTH_RANGE_INDEXES])
  }

  return (
    <>
      {/* BASIC SEARCH ////////////////////////////////////////////////////////////////// */}
      <div
        className={[
          "relative text-light-text dark:text-dark-text",
          "shrink-0 grow-0",
          "h-24 bg-gray-3 dark:bg-gray-88 w-full",
          "border-b border-gray-14 dark:border-gray-78",
          "flex items-center",
        ].join(" ")}>
        <div
          className={[
            "px-4 w-full flex flex-row items-center",
            "[&_.ddBox]:h-[42px]",
            "[&_.ddBox]:text-left",
            "[&_.ddBox]:w-full",
            "[&_.ddBox]:rounded-md",
          ].join(" ")}>
          <LabelAndInput
            extraClasses={[
              "w-40",
              "[&_.ddBox]:rounded-r-none",
              !locationId
                ? "[&_button]:ring-offset-1 [&_button]:ring-4 [&_button]:ring-accent [&_button]:animate-[pulse_1s_3] z-10"
                : "",
            ].join(" ")}
            label={t("similarTo", "Similar to")}
            input={
              <SingleDropdownSelect
                options={LOCATION_OPTIONS}
                selected={locationId}
                setSelected={setLocationId}
                leadingIcon={
                  locationId === "" ? undefined : <LocationPinIcon />
                }
                placeholder={t("select", "Select")}
              />
            }
          />
          <LabelAndInput
            extraClasses="w-36 [&_.ddBox]:rounded-none"
            label={t("basedOn", "Based on")}
            input={
              <MultiDropdownSelect
                placeholder={t("allVariables", "All Variables")}
                searchPlaceholder={t("search")}
                options={VARIABLE_OPTIONS}
                optionIcons={VARIABLE_ICONS}
                selectAllOption={t("allVariables", "All Variables")}
                selected={variables}
                setSelected={setVariables}
                customToggler={LegacyMultiSelectLikeToggler}
              />
            }
          />
          <LabelAndInput
            extraClasses="w-30 [&_.ddBox]:rounded-none"
            label={t("during", "During")}
            input={
              <MonthRangePicker
                compact
                startMonthIndex={monthsRange[0]}
                endMonthIndex={monthsRange[1]}
                handleChange={setMonthsRange}
                MONTH_NAMES={monthNames}
                startMonthPlaceholder={
                  monthsRange[0] !== null && monthNames[monthsRange[0]]
                    ? monthNames[monthsRange[0]].slice(0, 3)
                    : t("start", "Start")
                }
                endMonthPlaceholder={
                  monthsRange[1] !== null && monthNames[monthsRange[1]]
                    ? monthNames[monthsRange[1]].slice(0, 3)
                    : t("end", "End")
                }
              />
            }
          />
          <LabelAndInput
            extraClasses="w-32 [&_.ddBox]:rounded-none"
            label={t("inRegion", "In region")}
            input={
              <MultiDropdownSelect
                placeholder={t("global", "Global")}
                searchPlaceholder={t("search")}
                options={REGION_OPTIONS}
                selectAllOption={t("global", "Global")}
                selected={regions}
                setSelected={setRegions}
                customToggler={LegacyMultiSelectLikeToggler}
              />
            }
          />
          <LabelAndInput
            extraClasses="grow"
            label=" "
            input={
              <Button
                disabled={!canSubmit}
                onClick={buildAnalogRequest}
                extraClasses="rounded-l-none w-full"
                label={t("search", "Search")}></Button>
            }
          />
        </div>

        <div
          className="absolute top-2 right-4 link label-sm w-"
          onClick={() => setShowAdvancedMode(true)}>
          {t("advanced", "Advanced")}
          {advancedChanges && "*"}
        </div>
      </div>

      {/* ADVANCED SEARCH ////////////////////////////////////////////////////////////////// */}
      <AdvancedSearchModal
        showAdvancedMode={showAdvancedMode}
        setShowAdvancedMode={setShowAdvancedMode}
        advancedModeModalRef={advancedModeModalRef}
        monthsRange={monthsRange}
        setMonthsRange={setMonthsRange}
        locationId={locationId}
        setLocationId={setLocationId}
        regions={regions}
        setRegions={setRegions}
        scenario={scenario}
        setScenario={setScenario}
        variables={variables}
        setVariables={setVariables}
        referenceDecades={referenceDecades}
        setReferenceDecades={setReferenceDecades}
        discoveryDecades={discoveryDecades}
        setDiscoveryDecades={setDiscoveryDecades}
        exclusionRadius={exclusionRadius}
        setExclusionRadius={setExclusionRadius}
        canSubmit={canSubmit}
        defaultChanges={defaultChanges}
        allVariablesCheckboxStatus={allVariablesCheckboxStatus}
        DECADE_OPTIONS={DECADE_OPTIONS}
        REGION_OPTIONS={REGION_OPTIONS}
        LOCATION_OPTIONS={LOCATION_OPTIONS}
        SCENARIO_OPTIONS={SCENARIO_OPTIONS}
        VARIABLE_OPTIONS={VARIABLE_OPTIONS}
        EXCLUSION_OPTIONS={EXCLUSION_OPTIONS}
        VARIABLE_ICONS={VARIABLE_ICONS}
        buildAnalogRequest={buildAnalogRequest}
        resetToDefault={resetToDefault}
      />
    </>
  )
}

export default ExplorationToolSearchBar
