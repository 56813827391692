import { useTranslate } from "@tolgee/react"
import { useEffect, useMemo, useRef, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import {
  BackgroundAndCardLayout,
  Button,
  Checkbox,
  LabelAndInput,
} from "../../climateui/components"
import { GenericInput } from "../../climateui/components/Inputs"
import { useAuth, useSkin } from "../../providers"

function Login() {
  const auth = useAuth()
  const { skin } = useSkin()

  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslate()

  const [keepLoggedIn, setKeepLoggedIn] = useState(
    !!localStorage.getItem("@flag/remember-me") || true,
  )

  const [errors, setErrors] = useState({})

  const emailInputRef = useRef()
  const passwordInputRef = useRef()

  const loginFormSubmit = async (e) => {
    e.preventDefault()
    // TODO: Read this flag to enable/disable this behavior
    if (keepLoggedIn) localStorage.setItem("@flag/remember-me", "true")
    else localStorage.removeItem("@flag/remember-me")
    const errorObj = await auth.login(
      emailInputRef.current.value,
      passwordInputRef.current.value,
    )
    if (errorObj.error) {
      setErrors({
        email: errorObj.errorMessage,
        password: errorObj.errorMessage,
      })
    }
  }

  useEffect(() => {
    if (!auth.user) return
    if (location.state?.from)
      navigate(location.state.from.pathname + location.state.from.search)
    else navigate("/")
  }, [auth.user])

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Enter") {
        loginFormSubmit(event)
      }
    }

    document.addEventListener("keyup", handleKeyDown)

    return () => {
      document.removeEventListener("keyup", handleKeyDown)
    }
  }, [])

  const validateEmail = () => {
    const email = emailInputRef.current.value
    const newErrors = { ...errors }
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      delete newErrors.email
    } else {
      newErrors.email = t("emailFormatError")
    }
    setErrors(newErrors)
  }

  const isLoading = useMemo(
    () => auth.isLoginLoading && !auth.user,
    [auth.user, auth.isLoginLoading],
  )

  return (
    <BackgroundAndCardLayout copyright={t("copyright")}>
      <div className="pt-2 title-sm">
        {t(skin.welcome_string, "Welcome to ClimateLens™")}
      </div>

      {process.env.REACT_APP_ENV !== "production" &&
        process.env.REACT_APP_ENV !== "prod" && (
          <Button
            label={t("tryADemo")}
            extend={true}
            onClick={() => {
              auth.login("dev-demo@climate.ai", "demo")
            }}
          />
        )}
      <div className="h-[1px] w-full border-b-[1px] border-gray-14 dark:border-gray-78"></div>

      <form
        className="flex flex-col self-stretch space-y-4"
        noValidate
        onSubmit={loginFormSubmit}>
        <LabelAndInput
          label={t("emailInputLabel")}
          input={
            <GenericInput
              error={errors.email}
              placeholder={t("emailPlaceholder")}
              type="email"
              id="email"
              name="email"
              handleBlur={validateEmail}
              inputRef={emailInputRef}
            />
          }
        />
        <LabelAndInput
          label={t("passwordInputLabel")}
          input={
            <GenericInput
              error={errors.password}
              placeholder="********"
              type="password"
              id="password"
              name="password"
              inputRef={passwordInputRef}
            />
          }
        />

        <Link
          className="self-start no-underline body-md text-accent hover:text-accent-dark active:text-accent hover:underline"
          to="/restore-password">
          {t("forgotPassword")}
        </Link>

        <label className="flex flex-row items-center h-5 cursor-pointer select-none gap-1 text-light-text dark:text-dark-text">
          <span className="w-5 h-5">
            <Checkbox
              status={keepLoggedIn ? "full" : "empty"}
              onChange={(newStatus) => {
                setKeepLoggedIn(newStatus === "full")
              }}
            />
          </span>
          <div className="body-sm">{t("keepMeLoggedIn")}</div>
        </label>

        <Button
          label={isLoading ? t("loading") : t("login")}
          type="primary"
          extend={true}
          submit
          disabled={isLoading}
        />
      </form>
    </BackgroundAndCardLayout>
  )
}

export default Login
