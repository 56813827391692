import LocationDashboard from "../templates/LocationDashboard"
import RegionalDashboard from "../templates/RegionalDashboard"
import { useMemo } from "react"
import { useDashboard } from "../../../../providers/DashboardProvider"
import { IDashboard } from "../../../../types"
import TableDashboardView from "./TableDashboardView"
import DashboardView from "./DashboardView"
import { getQueryParam } from "../../../../utils/queryParams"
import { DashboardActionsProvider } from "./DashboardActionsProvider"

function GenericDashboardView() {
  const { workingDashboard } = useDashboard()

  const dashboardLocations = useMemo(() => {
    const locations = workingDashboard?.locations ?? []
    return locations
  }, [workingDashboard?.id])

  const DashboardComponent =
    workingDashboard?.dtype === "Location"
      ? LocationDashboard
      : workingDashboard?.dtype === "Regional"
        ? RegionalDashboard
        : () => null

  const DashboardBox = useMemo(() => {
    const paginationParam = getQueryParam("pagination")
    const isPaginated = !paginationParam ? true : paginationParam === "true"
    if (!isPaginated) {
      return TableDashboardView
    } else {
      return DashboardView
    }
  }, [])

  return (
    <DashboardActionsProvider>
      <DashboardBox workingDashboard={workingDashboard as IDashboard}>
        <DashboardComponent customLocations={dashboardLocations} />
      </DashboardBox>
    </DashboardActionsProvider>
  )
}

export default GenericDashboardView
