import {
  createContext,
  useEffect,
  useState,
  ReactNode,
  useContext,
} from "react"
import { ILabel } from "../climateui/types"
import queryClient, { accountLabelsGET, labelPOST } from "../utils/networking"
import { normalizeString } from "../utils/wordHelper"
import {
  isEmptyString,
  LABEL_COLORS,
} from "../views/Admin/Locations/LocationsUtil"
import { useAccount } from "./AccountProvider"

// TYPES
import { isCustomResponse, isValidResponse } from "../climateui/utils/http"
import { useQuery } from "react-query"

export interface LabelsContextInteface {
  generateBulkUploadLabelStructure: (
    labelNames: string[][],
  ) => Promise<string[][] | undefined>
  labels: ILabel[]
  queryLabels: () => void
}

export const LabelsContext = createContext({} as LabelsContextInteface)
export const useLabels = () => useContext(LabelsContext)

const MAX_LABELS_QUERY_TRIES = 3

function LabelsProvider({ children }: { children: ReactNode }) {
  const { selectedAccount } = useAccount()
  const [labels, setLabels] = useState<ILabel[]>([])

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["locationsLabels", selectedAccount],
    queryFn: () => accountLabelsGET(selectedAccount),
    enabled: !!selectedAccount,
    retry: MAX_LABELS_QUERY_TRIES,
  })

  useEffect(() => {
    if (isLoading || isFetching) return

    if (!isValidResponse(data)) setLabels([])
    else setLabels(data.data)
  }, [data, isLoading, isFetching])

  const generateBulkUploadLabelStructure = async (labelNames: string[][]) => {
    const labelStructure = [] as string[][]
    const newlyAddedLabels = {} as { [key: string]: string }
    let currentColor = 0

    try {
      // First, check if the label we need already exists on this account
      for (let i = 0; i < labelNames.length; i++) {
        labelStructure.push([])
        for (const rawLabel of labelNames[i]) {
          const currLabelName = normalizeString(rawLabel)

          if (isEmptyString(currLabelName)) continue

          const labelMissing = labels.every((label) => {
            if (normalizeString(label.name) === currLabelName) {
              labelStructure[i].push(label.id as string)
              return false
            }
            return true
          })

          if (labelMissing) {
            // First Check if we already added  this label during this method
            if (newlyAddedLabels[currLabelName]) {
              labelStructure[i].push(newlyAddedLabels[currLabelName])
            } else {
              // It is missing indeed, time to add it
              const newLabel = {
                name: currLabelName,
                color: LABEL_COLORS[currentColor],
                account_id: selectedAccount,
              }

              // Hit endpoint to add label to account
              const res = await labelPOST(newLabel)
              if (res && isCustomResponse(res) && res.status == 201) {
                const createdLabel = res.data[0]
                newlyAddedLabels[createdLabel.name] = createdLabel.id
                labelStructure[i].push(createdLabel.id)
              } else {
                throw new Error(
                  `Failed to add new label '${currLabelName}' to account ${selectedAccount}`,
                )
              }
            }
          }
          currentColor = (currentColor + 1) % LABEL_COLORS.length
        }
      }
    } catch (err) {
      console.error(err ? err : "Something went wrong.")
      return [] as string[][]
    }
    return labelStructure
  }

  return (
    <LabelsContext.Provider
      value={{
        labels,
        generateBulkUploadLabelStructure,
        queryLabels: () =>
          queryClient.invalidateQueries(["locationsLabels", selectedAccount]),
      }}>
      {children}
    </LabelsContext.Provider>
  )
}

export default LabelsProvider
