import { SVGProps } from "react"

const CandleMedian = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                width="20"
                height="20"
                fill="none"
            />
            <path
                d="M9.99902 18.3337L9.99902 1.66699"
                stroke={props.fill + "40"}
                strokeWidth="2"
            />
            <path
                d="M6.7266 1.66699H6.66634L13.333 1.66699"
                stroke={props.fill + "40"}
                strokeWidth="2"
            />
            <path
                d="M6.66634 18.3335H13.333"
                stroke={props.fill + "40"}
                strokeWidth="2"
            />
            <path
                d="M4.33301 5.1665H15.6663V14.8332H4.33301V5.1665Z"
                fill="none"
                stroke={props.fill + "40"}
                strokeWidth="2"
            />
            <path
                d="M3.33366 10H16.667"
                stroke={props.fill}
                strokeWidth="2"
            />
        </svg>
    )
}

export default CandleMedian
