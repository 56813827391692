import { useMemo } from "react"
import { LoadingScreen } from "../climateui/components"
import { useSkin } from "../providers"
import Lottie from "lottie-react"
import climateAILogoIconAnimation from "../components/ClimateAi_Symbol_Animation.json"
import { LoadingCircleIcon } from "../climateui/icons"
import { useTranslate } from "@tolgee/react"
import { capitalizeFirstCharacter } from "../utils/wordHelper"

function CAILoadingScreen() {
  const { skin } = useSkin()
  const { t } = useTranslate()

  const loader = useMemo(() => {
    if (!skin || skin?.name === "ClimateAi")
      return (
        <div className="flex flex-col h-36 justify-center mb-9">
          <span className="w-36">
            <Lottie animationData={climateAILogoIconAnimation} />
          </span>
        </div>
      )

    return (
      <div className="flex flex-col h-36 justify-center mb-9">
        <span className="fill-accent w-20">
          <LoadingCircleIcon />
        </span>
      </div>
    )
  }, [skin?.name])

  return (
    <LoadingScreen
      message={capitalizeFirstCharacter(t("loading"))}
      element={loader}
    />
  )
}

export default CAILoadingScreen
