import Color from "color"
import { IPolygonGroup } from "./types"

const ShadedRegionsMapLegend = ({
    title,
    data,
}: {
    title?: string
    data: Record<string, IPolygonGroup>
}) => {
    return (
        <div className="left-2 bottom-10 p-3 absolute z-20 rounded-sm shadow-md bg-light-bg dark:bg-dark-bg min-w-[92.71px] min-h-[100px]">
            {title && (
                <h2 className="mb-2 font-medium body-md text-light-text dark:text-dark-text w-[92.71px]">
                    {title}
                </h2>
            )}
            <div className="flex flex-col gap-1">
                {Object.entries(data).map(([label, labelGroup]) => {
                    return (
                        <div
                            className="flex items-center gap-2 body-sm"
                            key={label}>
                            <div
                                style={{
                                    backgroundColor: Color(
                                        labelGroup.style?.fillColor
                                    )
                                        .alpha(0.1)
                                        .hexa(),
                                    borderWidth: "1.5px",
                                    borderColor: labelGroup.style?.fillColor,
                                }}
                                className="w-3 h-3"></div>
                            {label}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ShadedRegionsMapLegend
