import { Outlet } from "react-router-dom"
import TabLayout from "../../layouts/TabLayout"
import { useCustomReports } from "../../providers"

function CustomTab() {
  const { reportsRoutes } = useCustomReports()

  return (
    <TabLayout sidebarOptions={reportsRoutes}>
      <Outlet />
    </TabLayout>
  )
}

export default CustomTab
