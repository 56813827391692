import {
    ReactNode,
    createContext,
    useState,
    useEffect,
    useContext,
} from "react"
import Toast, { IMinimalAlert, IAlert } from "./Toast"

const GENERIC_ERROR_MESSAGE = "There was an error. Please try again."
const PERMISSIONS_ERROR_MESSAGE =
    "Your account does not have permission to do this action."
const NETWORK_ERROR_MESSAGE = "There is a connectivity issue. Please try again."

interface IToastContext {
    dequeueAlert: () => void
    enqueueAlert: (alert: IAlert | string) => void
    enqueueMsgOrDefault: (msg: string, def: IAlert | string) => void
    enqueueError: (msg: string) => void
    enqueuePermissionError: (msg: string) => void
    enqueueNetworkError: (msg: string) => void
    enqueueDevAlert: (msg: string, log: string) => void
}

export const ToastContext = createContext<IToastContext>({} as IToastContext)

export const useToast = () => useContext(ToastContext)

function ToastProvider({ children }: { children: ReactNode | ReactNode[] }) {
    const [activeAlert, setActiveAlert] = useState<IAlert | null>(null)
    const [alertsQueue, setAlertsQueue] = useState<IMinimalAlert[]>([])

    useEffect(() => {
        if (alertsQueue.length > 0) {
            setActiveAlert(alertsQueue[0] as IAlert)
        } else {
            setActiveAlert(null)
        }
    }, [alertsQueue])

    const dequeueAlert = () => {
        setActiveAlert(null)
        const newAlerts = [...alertsQueue]
        newAlerts.shift()
        setAlertsQueue(newAlerts)
    }

    const enqueueAlert = (alert: IAlert | string) => {
        if (typeof alert === "string") {
            setAlertsQueue((prevAlertsQueue) => {
                prevAlertsQueue.push({
                    content: alert,
                })
                return [...prevAlertsQueue]
            })
            return
        }

        setAlertsQueue((prevAlertsQueue) => {
            prevAlertsQueue.push(alert)
            return [...prevAlertsQueue]
        })
    }

    const enqueueMsgOrDefault = (msg: string, def: IAlert | string) => {
        if (msg && typeof msg === "string" && msg.length > 0) {
            enqueueAlert(msg)
            return
        }
        enqueueAlert(def)
    }

    const enqueueError = (msg: string) => {
        enqueueMsgOrDefault(msg, GENERIC_ERROR_MESSAGE)
    }

    const enqueuePermissionError = (msg: string) => {
        enqueueMsgOrDefault(msg, PERMISSIONS_ERROR_MESSAGE)
    }

    const enqueueNetworkError = (msg: string) => {
        enqueueMsgOrDefault(msg, NETWORK_ERROR_MESSAGE)
    }

    const enqueueDevAlert = (msg: string, log: string) => {
        if (process.env.REACT_APP_STAGE !== "production") {
            enqueueAlert(msg)
            console.log(msg, log)
        }
    }

    return (
        <ToastContext.Provider
            value={{
                dequeueAlert,
                enqueueAlert,
                enqueueDevAlert,
                enqueueError,
                enqueueMsgOrDefault,
                enqueueNetworkError,
                enqueuePermissionError,
            }}>
            {children}
            {activeAlert !== null && (
                <Toast
                    content={activeAlert.content}
                    onCloseAlert={() => dequeueAlert()}
                    duration={activeAlert?.duration}
                    remaining={alertsQueue.length}
                    showTimer={activeAlert?.showTimer}
                />
            )}
        </ToastContext.Provider>
    )
}

export default ToastProvider
