import http from "../../climateui/utils/http"

export const accountSearchPOST = (payload) =>
  http.post("/account/search/", payload)

export const accountSwitchPOST = (accountID) =>
  http.post(`/auth/switch_account/${accountID}`)

export const accountConfigsGET = ({ queryKey }) => {
  const accountId = queryKey[1]
  return http.get(`/account_config/account/${accountId}`, true, {})
}

export const accountIsOnboardedPOST = (accountId, is_onboarded) =>
  http.post(`/account/${accountId}/is_onboarded`, { is_onboarded })
