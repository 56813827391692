import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    OnChangeFn,
    Row,
    SortingState,
    useReactTable,
} from "@tanstack/react-table"
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import PaginationControls from "./PaginationControls"
import { fuzzyFilter } from "./tableUtils"
import { ICustomTableState } from "./types"
import TableHeader from "./utilityComponents/TableHeader"

export interface IReactTable<T> {
    data: T[]
    columns: ColumnDef<T, ReactNode>[]
    state?: ICustomTableState
    setRowSelection?: Dispatch<SetStateAction<Record<string, boolean>>>
    setGlobalFilter?: Dispatch<SetStateAction<string>>
    setColumnFilters?: Dispatch<
        SetStateAction<{ id: string; value: unknown }[]>
    >
    rowSelectionFilter?: (rowData: Row<T>) => boolean
    extraClasses?: string
    allFilteredMessage?: string
    noDataMessage?: string
    tableFilteredCallback?: (
        filteredRows: any,
        filteredPageRows?: any
    ) => void | null
    doTableFilterCallbackCount?: number
    getRowId?: (originalRow: T, index: number, parent?: Row<T>) => string
    customFilterFn?: (row: Row<T>, columnId: string, value: string) => boolean
    setSorting?: OnChangeFn<SortingState>
    enableMultiSort?: boolean
    sortDescFirst?: boolean
    hideHeaders?: boolean
    paginationOptions?: Record<string, string>
    outOfText?: string
    footer?: ReactNode
}

function Table<T>({
    data,
    columns,
    state,
    setRowSelection,
    setGlobalFilter,
    customFilterFn,
    setColumnFilters,
    rowSelectionFilter,
    setSorting,
    extraClasses = "",
    allFilteredMessage = "No matching results",
    noDataMessage = "No data available",
    tableFilteredCallback = () => null,
    doTableFilterCallbackCount = 0,
    getRowId = undefined,
    sortDescFirst = false,
    hideHeaders = false,
    paginationOptions,
    outOfText = "of",
    footer = null,
    enableMultiSort,
}: IReactTable<T>): JSX.Element {
    // Dummy state to trick react state updates scheduler
    // and always have the latest table info
    const [dummy, setDummy] = useState<number>(0)
    const [searchParams, setSearchParams] = useSearchParams()

    // Read the page and pageSize from the URL
    const currentPage = Number(searchParams.get("pageIndex")) || 0
    const pageSize = Number(searchParams.get("pageSize")) || 10

    const table = useReactTable<T>({
        data,
        columns,
        state: {
            ...state,
            pagination: {
                pageIndex: currentPage,
                pageSize: pageSize,
            },
        },
        enableRowSelection: rowSelectionFilter,
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: customFilterFn ?? fuzzyFilter<T>,
        onColumnFiltersChange: setColumnFilters,
        onSortingChange: setSorting,
        manualSorting: !!state?.sorting,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        ...(paginationOptions
            ? {
                  getPaginationRowModel: getPaginationRowModel(),
              }
            : {}),
        getRowId: getRowId,
        debugTable: false,
        sortDescFirst,
        enableMultiSort,
    })

    //Check if pageIndex in URL is valid
    useEffect(() => {
        if (table.getPageCount())
            if (currentPage < table.getPageCount()) {
                table.setPageIndex(currentPage)
            } else {
                setSearchParams((prev) => {
                    prev.set("pageIndex", "0")
                    return prev
                })
            }
    }, [table.getPageCount()])

    useEffect(() => {
        if (paginationOptions) {
            table.setPageSize(+Object.keys(paginationOptions)[0])
            setDummy((prev) => prev + 1)
        }
    }, [paginationOptions])

    useEffect(() => {
        if (paginationOptions) {
            tableFilteredCallback(
                table.getFilteredRowModel().rows,
                table.getPaginationRowModel().rows
            )
        } else {
            tableFilteredCallback(table.getRowModel().rows)
        }
    }, [doTableFilterCallbackCount, dummy])

    return (
        <div className="flex flex-col h-full">
            <div
                className={
                    "overflow-x-auto " +
                    extraClasses +
                    (paginationOptions ? "" : " grow")
                }>
                <table className="relative w-full border-separate table-auto text-light-text dark:text-dark-text border-spacing-0">
                    {!hideHeaders && (
                        <thead className="font-bold label-lg">
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <TableHeader
                                            key={header.id}
                                            state={state}
                                            headerGroup={headerGroup}
                                            header={header}
                                        />
                                    ))}
                                </tr>
                            ))}
                        </thead>
                    )}
                    <tbody className="font-normal body-lg">
                        {table.getRowModel().rows.map((row) => (
                            <tr
                                key={row.id}
                                className={
                                    "text-[14px] group" +
                                    (row.getIsSelected()
                                        ? " bg-gray-5 dark:bg-gray-86"
                                        : " bg-light-bg dark:bg-dark-bg hover:bg-gray-3 dark:bg-gray-90 dark:hover:bg-gray-88") +
                                    ((row.original as any).rowClass
                                        ? " " + (row.original as any).rowClass
                                        : "")
                                }>
                                {row.getVisibleCells().map(
                                    (cell) =>
                                        !state?.hiddenColumns?.includes(
                                            cell.id.split("_")[1]
                                        ) && (
                                            <td
                                                key={cell.id}
                                                className={
                                                    "h-16 text-[14px] border-b border-gray-14 dark:border-gray-78 pl-1"
                                                }>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </td>
                                        )
                                )}
                            </tr>
                        ))}
                        {table.getRowModel().rows.length === 0 && (
                            <tr>
                                <td
                                    className="h-16 px-2 text-center border-b border-gray-14 dark:border-gray-78"
                                    colSpan={columns.length}>
                                    {state &&
                                    (state.globalFilter !== "" ||
                                        (state.columnFilters &&
                                            state.columnFilters.length !== 0))
                                        ? allFilteredMessage
                                        : noDataMessage}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {/* TABLE FOOTER */}
                {footer}
            </div>

            {paginationOptions && (
                <PaginationControls
                    selectedPageSize={table.getState().pagination.pageSize}
                    paginationOptions={paginationOptions}
                    handlePageSizeChange={(newPageSize) => {
                        table.setPageSize(newPageSize)
                        // Update the pageSize in URL when it changes
                        setSearchParams((prev) => {
                            prev.set("pageSize", newPageSize.toString())
                            return prev
                        })
                    }}
                    handleFirstClick={() => {
                        table.setPageIndex(0)
                        // Update the page index to 0 in URL
                        setSearchParams((prev) => {
                            prev.set("pageIndex", "0")
                            return prev
                        })
                    }}
                    handlePrevClick={() => {
                        table.previousPage()
                        // Update the page index in URL when moving to the previous page
                        setSearchParams((prev) => {
                            prev.set(
                                "pageIndex",
                                (
                                    table.getState().pagination.pageIndex - 1
                                ).toString()
                            )
                            return prev
                        })
                    }}
                    handleNextClick={() => {
                        table.nextPage()
                        // Update the page index in URL when moving to the next page
                        setSearchParams((prev) => {
                            prev.set(
                                "pageIndex",
                                (
                                    table.getState().pagination.pageIndex + 1
                                ).toString()
                            )
                            return prev
                        })
                    }}
                    handleLastClick={() => {
                        const lastPage = table.getPageCount() - 1
                        table.setPageIndex(lastPage)
                        // Update the page index in URL to the last page
                        setSearchParams((prev) => {
                            prev.set("pageIndex", lastPage.toString())
                            return prev
                        })
                    }}
                    changeCallback={() => {
                        setDummy((prev) => prev + 1)
                    }}
                    backwardDisabled={!table.getCanPreviousPage()}
                    forwardDisabled={!table.getCanNextPage()}
                    currentPage={table.getState().pagination.pageIndex + 1}
                    totalPages={table.getPageCount()}
                    outOfText={outOfText}
                />
            )}
        </div>
    )
}

export default Table
