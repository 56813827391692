import { useCallback, useState } from "react"
import { useMutation } from "react-query"
import { ILabel } from "../../../../climateui/types"
import { CAIResponse, isValidResponse } from "../../../../climateui/utils/http"
import { ICSVDataCell } from "../../../../components/Bulk Upload/bulkUploadUtils"
import { useAssets } from "../../../../providers"
import { useAccount } from "../../../../providers/AccountProvider"
import {
  IAsset,
  RiskProfileInput,
  IRiskProfile,
  IVariety,
} from "../../../../types"
import {
  assetQuerySet,
  rpLabelsQuerySet,
  varietyQuerySet,
} from "../../../../utils/networking"

const parseRawHazardVariable = (
  hazardVariables: Record<string, string>,
  rawHazard?: string | number,
) => {
  if (!rawHazard) return ""
  if (typeof rawHazard === "number") return ""
  const hazardVarName = rawHazard.trim().toLowerCase()
  const parsedHazardVariable = Object.entries(hazardVariables).find(
    ([, variableName]) =>
      variableName.trim().toLowerCase().includes(hazardVarName),
  )
  if (!parsedHazardVariable) throw Error("Hazard Variable not found")
  return parsedHazardVariable[0]
}

const parseRawImpactFunction = (
  impactFunctions: Record<string, string>,
  rawFunction?: string | number,
) => {
  if (!rawFunction) return ""
  if (typeof rawFunction === "number") return ""
  const impactFunctionName = rawFunction.trim().toLowerCase()
  const parsedImpactFunction = Object.entries(impactFunctions).find(
    ([, functionName]) =>
      functionName.trim().toLowerCase().includes(impactFunctionName),
  )
  if (!parsedImpactFunction) throw Error("Impact Function not found")
  return parsedImpactFunction[0]
}

const parseRawConditional = (rawConditional?: string | number) => {
  if (!rawConditional) return ""
  if (typeof rawConditional === "number") return ""
  const normalizedConditional = rawConditional.trim().toLowerCase()
  switch (normalizedConditional) {
    case "over":
    case "more than":
    case "greater than":
      return ">"
    case "more than or equal to":
    case "greater than or equal to":
      return ">="
    case "under":
    case "less than":
      return "<"
    case "less than or equal to":
      return "<="
    case "equal":
    case "equal to":
      return "="
    default:
      throw Error("Comparison not supported")
  }
}

const parseRawNumber = (value?: string | number): number => {
  if (!value) return NaN
  if (typeof value === "string") return parseFloat(value)
  return value
}

const parseRawPercentage = (value?: string | number): number => {
  if (!value) return NaN
  if (typeof value === "string")
    if (/^-?\d+(\.\d+)?%$/.test(value)) {
      const stringValue = value
      return parseFloat(stringValue.slice(0, -1)) / 100.0
    }
  return parseFloat(<string>value)
}

const trimAndLower = (value?: string | number): string => {
  if (!value) return ""
  if (typeof value === "number") return trimAndLower(value.toString())
  return value.trim().toLowerCase().replace(" ", "_")
}

const useRawLabelsParser = () => {
  // Assign the mutation request
  const { selectedAccount } = useAccount()
  const { mutateAsync: addLabel } = useMutation((label: ILabel) =>
    rpLabelsQuerySet.post("", { ...label, account_id: selectedAccount }),
  )
  const [refetchLabels, setRefetchLabels] = useState(false)
  const getAllLabels = useCallback(async () => {
    const response = await rpLabelsQuerySet.get("", undefined, {
      account_id: selectedAccount || "",
    })
    if (isValidResponse(response)) return response.data
    return []
  }, [refetchLabels])
  return async (rawLabels?: string) => {
    if (!rawLabels || !selectedAccount) return
    const allLabels = await getAllLabels()
    const labelsArr = new Set(
      rawLabels.split(",").map((label) => trimAndLower(label)),
    )
    const allLabelsDict = allLabels.reduce(
      (prev: Record<string, ILabel>, curr: ILabel) => ({
        ...prev,
        [trimAndLower(curr.name)]: curr,
      }),
      {},
    )
    const existingLabels: string[] = []
    const newLabels = new Set<string>()
    labelsArr.forEach((label) => {
      const existingLabel = allLabelsDict[label]
      if (existingLabel && existingLabel.id) {
        existingLabels.push(existingLabel.id)
      } else {
        newLabels.add(label)
      }
    })
    const pendingLabels: Promise<CAIResponse>[] = []
    newLabels.forEach((labelName) =>
      pendingLabels.push(
        addLabel({
          name: labelName,
          // TODO: Randomize colors
          color: "#F06000",
        }),
      ),
    )
    if (newLabels.size > 0) setRefetchLabels(!refetchLabels)
    const results = await Promise.all(pendingLabels)
    results.forEach((response) => {
      if (!isValidResponse(response)) return console.error(response)
      existingLabels.push(response.data.id)
    })
    return existingLabels
  }
}
const extractVarietyNames = (rawVariety?: string) => {
  if (!rawVariety) return
  const [assetName, _varietyName] = rawVariety.split(" (")
  if (!assetName || !_varietyName) return
  const varietyName = _varietyName.replace(")", "")
  return [assetName.trim(), varietyName.trim()]
}

const getVarietyNameFilter = (varietyName: string) => {
  if (varietyName.toUpperCase() === "DEFAULT")
    return {
      field_name: "variety.Variety.is_default",
      operator: "eq",
      field_value: true,
    }
  return {
    field_name: "variety.Variety.name",
    operator: "eq",
    field_value: varietyName,
  }
}
const useRawVarietiesParser = () => {
  const { selectedAccount } = useAccount()
  return useCallback(
    async (rawVarieties?: string): Promise<string[] | undefined> => {
      if (!rawVarieties) return
      const splitVarieties = rawVarieties.split(",")
      const requests = splitVarieties.map(async (rawVariety) => {
        const assetNames = extractVarietyNames(rawVariety)
        if (!assetNames) return
        const [assetName, varietyName] = assetNames
        const response = await assetQuerySet
          .post("/search", {
            filter_by: {
              and: [
                {
                  field_name: "asset.Asset.name",
                  operator: "eq",
                  field_value: assetName,
                },
              ],
            },
          })
          .then((res) => {
            if (!isValidResponse(res)) return res
            const assets = res.data.data as IAsset[]
            if (assets.length === 0) return undefined
            const assetID = assets[0].id
            return varietyQuerySet.post("/search", {
              filter_by: {
                and: [
                  {
                    field_name: "variety.Variety.asset_id",
                    operator: "eq",
                    field_value: assetID,
                  },
                  {
                    field_name: "variety.Variety.account_id",
                    operator: "in",
                    field_value: [selectedAccount, "Default"],
                  },
                  {
                    ...getVarietyNameFilter(varietyName),
                  },
                ],
              },
            })
          })
        if (!isValidResponse(response)) return
        const varieties = response.data.data as IVariety[]
        return varieties.length > 0 ? varieties[0].id : undefined
      })
      const varietyIDs = (await Promise.all(requests)).filter((id) => !!id)
      if (varietyIDs.length !== splitVarieties.length) return
      return varietyIDs as string[]
    },
    [selectedAccount],
  )
}

/* INDEX CONSTANTS */
const NAME = 0
const HAZARD_VAR = 1
const RISK_COND = 2
const TYPE = 3
const THRESHOLD = 4
const WINDOW = 6
const VARIETIES = 7
const LABELS = 8
const PROBABILITY = 9
const IMPACT_FUNC = 10
const IMPACT_INITIAL = 11
const IMPACT_MARGINAL = 12
const IMPACT_MAX = 13

export const VARIETY_NOT_FOUND = 1404
type ParseError = { error: number }
export const isParseError = (val: unknown): val is ParseError =>
  val !== undefined &&
  val !== null &&
  !!(val as ParseError).error &&
  typeof (val as ParseError).error === "number"

export const useRawRiskProfileParser = () => {
  const labelParser = useRawLabelsParser()
  const varietyParser = useRawVarietiesParser()
  return {
    parseOnlyLabels: async (data: ICSVDataCell[][]) => {
      const rawLabels = data.map((row) => row[LABELS].value).join(",")
      await labelParser(rawLabels)
    },
    parser: async (
      raw: ICSVDataCell[],
      hazardVariables: Record<string, string>,
      impactFunctions: Record<string, string>,
      accountId?: string,
    ): Promise<RiskProfileInput | ParseError | undefined> => {
      if (!accountId) return undefined
      try {
        const varieties = await varietyParser(raw[VARIETIES].value as string)
        return {
          // Account ID
          account_id: accountId,
          // Risk Name
          name: raw[NAME].value?.toString() ?? "Untitled",
          // Variable
          hazard_profiles: [
            {
              hazard_variable_id: parseRawHazardVariable(
                hazardVariables,
                raw[HAZARD_VAR].value,
              ),
              // Risk Condition
              conditional: parseRawConditional(raw[RISK_COND].value),
              // Type
              type: trimAndLower(raw[TYPE].value),
              // Threshold
              threshold: parseRawNumber(raw[THRESHOLD].value),
              // Consecutive Days/Window
              window: parseRawNumber(raw[WINDOW].value),
              // TODO: Get this value from the user input when more options are supported
              aggregation: "consecutive",
              logical_op: "start",
            },
          ],
          impact_profile: {
            impact_function_id: parseRawImpactFunction(
              impactFunctions,
              raw[IMPACT_FUNC].value,
            ),
            initial_impact: parseRawPercentage(raw[IMPACT_INITIAL].value),
            marginal_impact: parseRawPercentage(raw[IMPACT_MARGINAL].value),
            max_impact: parseRawPercentage(raw[IMPACT_MAX].value),
          },
          labels: (await labelParser(raw[LABELS].value as string)) ?? [],
          probability: parseRawPercentage(raw[PROBABILITY].value),
          varieties: varieties ?? [],
        }
      } catch (err) {
        console.debug(err)
        return undefined
      }
    },
  }
}

const DOUBLE_QUOTES = /([^\\])(")/g
const COMMAS = /,/
export const escapeCSVString = (input: string) => {
  let newStr = input

  // Escape any double quotes
  if (DOUBLE_QUOTES.test(newStr))
    newStr = newStr.replace(DOUBLE_QUOTES, '$1"$2')
  // Escape any commas
  if (COMMAS.test(newStr)) newStr = `"${newStr}"`

  return newStr
}

// TODO: Get the unit based on the users preferences
// TODO: Support stacked risk profiles
const getRiskProfileUnit = (riskProfile: IRiskProfile) => {
  const normType = riskProfile.hazard_profiles[0].type.trim().toLowerCase()
  switch (normType) {
    case "relative":
      return "%"
    case "absolute":
    default:
      return riskProfile.hazard_profiles[0].hazard_variable.units_metric
  }
}

// TODO: Support i18n
const parseConditional = (conditional: string) => {
  switch (conditional) {
    case "<":
      return "Less than"
    case ">":
      return "Greater than"
    case "=":
    default:
      return "Equal to"
  }
}

// TODO: Support stacked risk profiles
// TODO: Add new rows here
export const useRiskProfileToCSVRow = () => {
  const { varieties } = useAssets()
  return (riskProfile?: IRiskProfile) => {
    if (!riskProfile) return ""
    let csvRow = `${escapeCSVString(riskProfile.name)}`
    csvRow += `,${escapeCSVString(
      riskProfile.hazard_profiles
        .map(({ hazard_variable }) => hazard_variable.readable_name)
        .join("; "),
    )}`
    csvRow += `,${parseConditional(
      riskProfile.hazard_profiles
        .map(({ conditional }) => conditional)
        .join("; "),
    )}`
    csvRow += `,${escapeCSVString(
      riskProfile.hazard_profiles.map(({ type }) => type).join("; "),
    )}`
    csvRow += `,${riskProfile.hazard_profiles
      .map(({ threshold }) => threshold)
      .join("; ")}`
    csvRow += `,${getRiskProfileUnit(riskProfile)}`
    csvRow += `,${riskProfile.hazard_profiles
      .map(({ window }) => window)
      .join("; ")}`
    csvRow += `,"${riskProfile.varieties.reduce((prev, { id }, idx) => {
      if (!varieties) return prev
      const variety = varieties[id]
      if (!variety) return prev
      const name = `${variety.asset.name} (${variety.name})`
      if (idx > 0) return `${prev}, ${name}`
      return name
    }, "")}"`
    csvRow += `,"${riskProfile.labels.reduce(
      (prev: string, label: ILabel, index: number) => {
        let res = label.name
        if (index > 0) res = `${prev}, ${res}`
        return res
      },
      "",
    )}"`
    csvRow += `,${riskProfile.probability * 100}%`
    csvRow += `,${riskProfile.impact_profile.impact_function.readable_name}`
    csvRow += `,${riskProfile.impact_profile.initial_impact * 100}%`
    csvRow += `,${riskProfile.impact_profile.marginal_impact * 100}%`
    csvRow += `,${riskProfile.impact_profile.max_impact * 100}%`
    return csvRow
  }
}
