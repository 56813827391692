import { DateTime } from "luxon"
import React, { useMemo, useState, useCallback } from "react"
import {
  Area,
  ComposedChart,
  Label,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { AxisInterval } from "recharts/types/util/types"
import LoadingAnimation from "../../../../climateui/components/LoadingAnimation"
import ProbabilityHover from "../../../../climateui/components/Widgets/SeasonalWidgets/ProbabilityHover"
import RiskOutlookIcon from "../../../../climateui/icons/variableIcons/RiskOutlookIcon"
import { getProbabilityHoverItems } from "../riskOutlookUtils"
import { Stage } from "../types"
import renderRiskChartReferences from "./renderRiskChartReferences"
import renderRiskChartStages from "./renderRiskChartStages"
import RiskOutlookChartHeader from "./RiskOutlookChartHeader"
import { useChartMouseThreshold } from "./useChartMouseThreshold"

interface Point {
  id: string
  date: string
  y?: number
  y0?: number
  y1?: number
  meta: any
}

interface Plot {
  id: string
  visualization: "line" | "area"
  color: string
  points: Point[]
}

interface LegendInterface {
  type: "line" | "area" | "dot" | "line-dashed"
  label: string
  color: string
}

interface RiskOutlookChartProps {
  data: {
    plot: Plot[]
  }
  isFullSeason?: boolean
  stages: Stage[]
  dottedSeparatorDate?: string
  highlightedDate?: string
  verticalLineDate?: string
  verticalLineColor?: string
  showDots?: boolean
  adjustYAxis?: boolean
  domain?: [number, number]
  xTickFormatter?: (value: { date: string }) => string
  xAxisInterval?: AxisInterval | number
  loading?: boolean
  lowerBound?: number
  upperBound?: number
  legends?: LegendInterface[]
  xLabel?: string
  description?: string
  tooltip?: string
  title?: string
  icon?: string
}

// Memoized tooltip content component
const TooltipContent = React.memo(
  ({
    payload = [],
    isFullSeason = false,
    lowerBound,
    upperBound,
  }: {
    payload?: any[]
    isFullSeason?: boolean
    lowerBound: number
    upperBound: number
  }) => {
    if (!payload?.length) return null

    const date = payload[0]?.payload?.date
    const yMin = payload[0]?.payload?.yMin
    const yMid = payload[0]?.payload?.yMid
    const yMax = payload[0]?.payload?.yMax
    const value = payload[0]?.payload?.y
    const seasonLabel =
      payload[0]?.payload?.meta?.season_label ?? "Not available"
    const isForecast = payload[0]?.payload.meta.is_forecast ?? false

    if (isFullSeason && !isForecast) {
      return (
        <div className="bg-light-bg dark:bg-dark-bg p-[10px] rounded shadow border border-gray-14 dark:border-gray-78">
          <ProbabilityHover
            title=""
            rightTitle={seasonLabel}
            leftTitle={"Season"}
            items={[
              {
                title: "Risk Index",
                description: "Historical",
                value: value?.toFixed(2) ?? "",
                icon: () => <RiskOutlookIcon />,
              },
            ]}
          />
        </div>
      )
    }

    return (
      <div className="bg-light-bg dark:bg-dark-bg p-[10px] rounded shadow border border-gray-14 dark:border-gray-78">
        <ProbabilityHover
          title="Probability Breakdown by Risk Index"
          leftHeader="Risk Index"
          rightHeader={value?.toFixed(2) ?? ""}
          leftTitle={isFullSeason ? "Season" : "Date"}
          rightTitle={isFullSeason ? seasonLabel : date}
          items={getProbabilityHoverItems(
            yMin ?? 0,
            yMid ?? 0,
            yMax ?? 0,
            lowerBound ?? 0,
            upperBound ?? 0,
            false,
          )}
        />
      </div>
    )
  },
)

TooltipContent.displayName = "TooltipContent"

const RiskOutlookChart: React.FC<RiskOutlookChartProps> = React.memo(
  ({
    data,
    isFullSeason,
    stages,
    dottedSeparatorDate,
    highlightedDate,
    verticalLineDate,
    verticalLineColor = "#000000",
    showDots = true,
    adjustYAxis = true,
    domain,
    xTickFormatter,
    xAxisInterval,
    loading,
    lowerBound,
    upperBound,
    legends,
    xLabel,
    description,
    tooltip,
    title,
    icon,
  }) => {
    const [showCroppedData, setShowCroppedData] = useState(true)

    // Memoize transformed data calculation
    const transformedData = useMemo(() => {
      if (!data?.plot || data.plot?.length === 0) {
        return []
      }

      let processedData = data.plot.flatMap((plot) => {
        const points = plot.points.reduce((acc: Point[], point, index) => {
          // Add current point
          acc.push({
            ...point,
            [plot.id]:
              plot.visualization === "area" ? [point.y0, point.y1] : point.y,
          })

          // Check if there's a next point and if there's a gap
          if (index < plot.points.length - 1) {
            const currentDate = DateTime.fromFormat(point.date, "dd-MM-yyyy")
            const nextDate = DateTime.fromFormat(
              plot.points[index + 1].date,
              "dd-MM-yyyy",
            )
            const diffInDays = nextDate.diff(currentDate, "days").days

            // If there's a gap of more than 1 day
            if (diffInDays > 1) {
              // Add a copy of the current point for the next day
              acc.push({
                ...point,
                date: currentDate.plus({ days: 1 }).toFormat("dd-MM-yyyy"),
                [plot.id]:
                  plot.visualization === "area"
                    ? [point.y0, point.y1]
                    : point.y,
              })
            }
          }

          return acc
        }, [])

        return points
      })

      // Apply cropping if enabled and not in full season mode
      if (!isFullSeason && showCroppedData) {
        const twoWeeksAgo = DateTime.now().minus({ weeks: 2 })
        processedData = processedData.filter((point) => {
          const pointDate = DateTime.fromFormat(point.date, "dd-MM-yyyy")
          return pointDate >= twoWeeksAgo
        })
      }

      return processedData
    }, [data, showCroppedData, isFullSeason])

    // thresholds for the probability hover
    const { top, right } = useChartMouseThreshold({ width: 350, height: 350 })

    const handleShowFullSeasonToggle = useCallback(() => {
      setShowCroppedData((prev) => !prev)
    }, [])

    if (loading) {
      return (
        <div className="flex justify-center items-center mt-36 body-lg">
          <LoadingAnimation />
        </div>
      )
    }

    if (transformedData.length === 0) {
      return <div>No data points available for the chart.</div>
    }

    return (
      <div className="w-full bg-light-bg dark:bg-dark-bg border rounded-lg font-roboto border-1 border-gray-14 dark:border-gray-78 p-[14px]">
        <RiskOutlookChartHeader
          title={title}
          tooltip={tooltip}
          icon={icon}
          description={description}
          legends={legends}
          actions={
            !isFullSeason
              ? [
                  {
                    text: showCroppedData ? "Show past risk" : "Hide past risk",
                    onClick: handleShowFullSeasonToggle,
                  },
                ]
              : []
          }
        />
        <div>
          <ResponsiveContainer
            width="100%"
            height={400}>
            <ComposedChart
              data={transformedData}
              margin={{ left: 50, bottom: stages ? 50 : 0 }}>
              <XAxis
                allowDataOverflow
                axisLine={false}
                tickLine={false}
                padding={{ right: 5, left: 5 }}
                dataKey="date"
                type="category"
                allowDuplicatedCategory={false}
                strokeWidth={0}
                interval={xAxisInterval ?? 0}
                fontSize={14}
                tick={{ fill: "currentColor" }}
                className="text-gray-60 dark:text-gray-30"
                tickFormatter={(value) => {
                  return xTickFormatter ? xTickFormatter(value) : value
                }}>
                {xLabel && (
                  <Label
                    value={xLabel}
                    position="insideBottom"
                    className="text-gray-60 dark:text-gray-30"
                    fill="currentColor"
                  />
                )}
              </XAxis>

              <YAxis
                padding={{ top: 10, bottom: 10 }}
                domain={
                  adjustYAxis
                    ? [
                        (dataMin: number) =>
                          Math.min(
                            dataMin,
                            ...transformedData.flatMap((point) =>
                              Object.values(point).filter(
                                (value): value is number =>
                                  typeof value === "number",
                              ),
                            ),
                          ) ?? 0,
                        (dataMax: number) =>
                          Math.max(
                            dataMax,
                            ...transformedData.flatMap((point) =>
                              Object.values(point).filter(
                                (value): value is number =>
                                  typeof value === "number",
                              ),
                            ),
                          ) ?? 0,
                      ]
                    : domain
                }
                axisLine={false}
                tickLine={false}
                strokeWidth={0}
                tick={false}
              />

              {data.plot.map((plot) => {
                if (plot.visualization === "line") {
                  return (
                    <React.Fragment key={`fragment_${plot.id}`}>
                      <Line
                        type="linear"
                        dataKey={plot.id}
                        stroke={plot.color}
                        strokeWidth={2}
                        isAnimationActive={false}
                        dot={
                          showDots
                            ? (props) => {
                                const { cx, cy, payload } = props

                                const isForecast =
                                  payload.meta.is_forecast ?? false
                                const dotRadius = isForecast ? 5 : 2
                                const dotOpacity = isForecast ? 1 : 0.2

                                return (
                                  <circle
                                    cx={cx}
                                    cy={cy}
                                    r={dotRadius}
                                    opacity={dotOpacity}
                                    fill={plot.color}
                                  />
                                )
                              }
                            : false
                        }
                        strokeDasharray={"0"}
                        data={transformedData.filter((point) => {
                          return (
                            !dottedSeparatorDate ||
                            DateTime.fromFormat(point.date, "dd-MM-yyyy") <=
                              DateTime.fromISO(dottedSeparatorDate ?? "")
                          )
                        })}
                      />
                      {dottedSeparatorDate && (
                        <Line
                          type="linear"
                          dataKey={plot.id}
                          stroke={plot.color}
                          isAnimationActive={false}
                          dot={
                            showDots
                              ? (props) => {
                                  const { cx, cy } = props
                                  return (
                                    <circle
                                      cx={cx}
                                      cy={cy}
                                      r={2}
                                      fill={plot.color}
                                    />
                                  )
                                }
                              : false
                          }
                          strokeWidth={2}
                          strokeDasharray="3 3"
                          data={transformedData.filter((point) => {
                            return (
                              DateTime.fromFormat(
                                point.date,
                                "dd-MM-yyyy",
                              ).toMillis() >=
                              DateTime.fromISO(
                                dottedSeparatorDate ?? "",
                              ).toMillis()
                            )
                          })}
                        />
                      )}
                    </React.Fragment>
                  )
                } else if (plot.visualization === "area") {
                  return (
                    <Area
                      dot={false}
                      key={plot.id}
                      type="linear"
                      dataKey={plot.id}
                      stroke={"none"}
                      fill={plot.color}
                      fillOpacity={0.3}
                    />
                  )
                }
                return null
              })}
              <Tooltip
                allowEscapeViewBox={{ y: true, x: true }}
                reverseDirection={{ y: !top, x: right }}
                offset={10}
                // animation props
                isAnimationActive={true}
                animationDuration={100}
                animationEasing="ease"
                wrapperStyle={{
                  zIndex: 1000,
                }}
                formatter={(value, name) => {
                  if (typeof value === "number") {
                    return [value.toFixed(2), name]
                  }
                  return [value, name]
                }}
                content={({ payload }) => (
                  <TooltipContent
                    payload={payload}
                    isFullSeason={isFullSeason}
                    lowerBound={lowerBound ?? 0}
                    upperBound={upperBound ?? 0}
                  />
                )}
              />

              {verticalLineDate && (
                <ReferenceLine
                  x={DateTime.fromISO(verticalLineDate).toFormat("dd-MM-yyyy")}
                  stroke={verticalLineColor}
                  strokeWidth={2}
                  isFront={true}
                  label={{
                    value: "Today",
                    position: "bottom",
                    offset: 7,
                    style: {
                      fontWeight: 500,
                      fontSize: 14,
                    },
                  }}
                />
              )}

              {/* Low / Medium / High */}
              {renderRiskChartReferences({
                lowerBound: lowerBound ?? 70,
                upperBound: upperBound ?? 90,
              })}

              {/* Stages */}
              {stages && renderRiskChartStages(stages, icon)}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    )
  },
)

RiskOutlookChart.displayName = "RiskOutlookChart"

export default RiskOutlookChart
