export const defaultInputClasses = [
    "w-full h-[42px] rounded-md border border-gray-14 dark:border-gray-78 bg-light-bg dark:bg-dark-bg px-2",
    "font-normal body-lg text-light-text dark:text-dark-text placeholder:text-gray-30 fill-gray-60",
    "transition-all duration-75",
    "enabled:hover:border-gray-30 enabled:hover:z-[1]",
    "enabled:active:border-accent enabled:focus:border-accent",
    "focus:outline-none",
    "disabled:bg-gray-5 dark:disabled:bg-gray-60 disabled:cursor-not-allowed disabled:text-gray-30 disabled:fill-gray-30",
]

export const alwaysBorderRedClasses = "border-red hover:border-red"

export const MIN_NUMBER_OF_OPTIONS_TO_ALLOW_SEARCH = 5
