import { useTimeline } from ".."
import { PlusIcon } from "../../../climateui/icons"
import { colors } from "../../../climateui/utils/colors"
import { daysBetween } from "../../../utils"
import StageColorBoxes from "./StageColorBoxes"
import { useTimelineRow } from "./TimelineRow"

export function TimelineNewStage({
  date,
  duration,
}: {
  date: Date
  duration: number
}) {
  const { pxPerDay, timelineStartDate } = useTimeline()
  const { stageHeight } = useTimelineRow()
  const daysFromTimelineStart = daysBetween(timelineStartDate, date)

  const mainStyle = {
    width: pxPerDay * duration + "px",
    left: daysFromTimelineStart * pxPerDay + "px",
    height: stageHeight + "px",
  }

  return (
    <div
      className={[
        "absolute left-0",
        "flex flex-row items-center justify-center",
        "rounded-sm group body-md",
        "pointer-events-none",
        "fill-gray-30 text-light-text dark:text-dark-text",
      ].join(" ")}
      style={mainStyle}>
      <span className="w-6 h-6">
        <PlusIcon />
      </span>
      <StageColorBoxes
        isFocused={false}
        color={colors.gray[60]}
      />
    </div>
  )
}
