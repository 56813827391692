import { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { REMEMBERED_FILTERS } from "../../../utils/constants"

const rememberedFiltersToReset = REMEMBERED_FILTERS.riskProfiles

function RiskProfilesTab() {
  // state to avoid useFilter to set its values from
  // localStorage before this tab load cleanup.
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    Object.values(rememberedFiltersToReset).forEach((rememberedFilterKey) =>
      localStorage.removeItem(rememberedFilterKey),
    )
    setLoading(false)
  }, [])

  if (loading) return null
  return <Outlet />
}

export default RiskProfilesTab
