import { useTranslate } from "@tolgee/react"
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useQuery } from "react-query"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { ModalContext, ToastContext } from "../climateui/providers"
import { IRoute } from "../climateui/types"
import { areResTypeAndDataValid, CustomResponse } from "../climateui/utils/http"
import { useMemoQuery } from "../hooks"
import { IDashboard } from "../types"
import queryClient, {
  accountDashboardsGET,
  dashboardDELETE,
  dashboardGET,
  dashboardPOST,
  dashboardPUT,
} from "../utils/networking"
import { capitalizeFirstCharacter } from "../utils/wordHelper"
import { dashboardAdditionAndEditionRoutes } from "../views/Seasonal/Dashboards/routes"
import { DEFAULT_DASHBOARD_TEMPLATE_IDS } from "../views/Seasonal/Dashboards/utils"
import { useAccount } from "./AccountProvider"
import { useAuthNavigation } from "./AuthGuards"
import { useAuth } from "./AuthProvider"
import { LocationsContext } from "./LocationsProvider"
import { useUI } from "./UIProvider"

export interface IDashboardsContext {
  dashboardRoutes: IRoute[]
  dashboardsObject: Record<string, IDashboard>
  dashboards: IDashboard[]
  cancelDashboardAdditionOrEdition: () => void
  goToStep: (next: string) => void
  goToNewDashboard: () => void
  navigateToAllowedDashboardRoute: () => void
  activeDashboardId: string | undefined
  loadingDashboards: boolean
  dragAuxDate?: Date
  setDragAuxDate: (newDate: Date | undefined) => void
  isEditingDashboard: boolean
  workingDashboard?: IDashboard
  setWorkingDashboard: (dashboard?: IDashboard) => void
  originalDashboard?: IDashboard
  setOriginalDashboard: (dashboard?: IDashboard) => void
  createOrEditDashboard: (
    dashboard?: IDashboard,
    doNavigate?: boolean,
  ) => Promise<boolean>
  deleteDashboard: (dashboard: IDashboard) => void
}
export const DashboardContext = createContext<IDashboardsContext>(
  {} as IDashboardsContext,
)
export const useDashboard = () => useContext(DashboardContext)

function buildDashboardRoute(dashboard: IDashboard) {
  return {
    path: dashboard.id + "",
    label: dashboard.title ?? "Unnamed Dashboard",
  }
}

function DashboardProvider({
  children,
  isEditingDashboard = true,
}: {
  children: ReactNode
  isEditingDashboard?: boolean
}) {
  const { navigateToAllowed } = useAuthNavigation()
  const { dashboardId } = useParams()
  const { selectedAccount } = useAccount()
  const { setSecondSidebarOptions } = useUI()
  const auth = useAuth()
  const { confirmationModal } = useContext(ModalContext)
  const { locationsObj } = useContext(LocationsContext)
  const { enqueueAlert } = useContext(ToastContext)

  const navigate = useNavigate()
  const { t } = useTranslate()
  const location = useLocation()

  const [workingDashboard, setWorkingDashboard] = useState<IDashboard>()
  const [originalDashboard, setOriginalDashboard] = useState<IDashboard>()
  const [dragAuxDate, setDragAuxDate] = useState<Date>()

  const [dashboard] = useMemoQuery<IDashboard>(
    ["dashboard", dashboardId],
    () => {
      if (dashboardId && DEFAULT_DASHBOARD_TEMPLATE_IDS.includes(dashboardId)) {
        return Promise.resolve({
          data: {
            account_id: "",
            title: `${capitalizeFirstCharacter(dashboardId)} Dashboard`,
            description: `Default ${dashboardId} dashboard.`,
            dtype: dashboardId === "location" ? "Location" : "Regional",
            locations: [],
            id: dashboardId,
            report_schedules: [],
          },
          status: 200,
          statusText: "OK",
          headers: {},
          config: {},
          request: {},
        })
      } else return dashboardGET(dashboardId)
    },
    {
      enabled: !!dashboardId && !!selectedAccount,
    },
    undefined,
    {},
  )

  const dashboardNewFormat = {
    title: "",
    description: "",
    account_id: selectedAccount,
    created_by_email: auth.user?.email,
    updated_by_email: auth.user?.email,
  }

  const {
    refetch,
    data,
    isLoading: loadingDashboards,
  } = useQuery(["dashboards", selectedAccount], accountDashboardsGET, {
    enabled: !!selectedAccount,
  })

  const { dashboardRoutes, dashboardsObject, dashboards } = useMemo(() => {
    const obj: Record<string, IDashboard> = {}
    const routes: IRoute[] = []
    const dashboards: IDashboard[] = []
    if (data) {
      const d = (data as CustomResponse).data
      if (d && d.length > 0) {
        d.forEach((dashboard: IDashboard) => {
          if (dashboard.id) {
            obj[dashboard.id] = dashboard
            routes.push(buildDashboardRoute(dashboard))
            dashboards.push(dashboard)
          }
        })
      }
    }

    routes.sort((a, b) => ((a.label as string) > (b.label as string) ? 1 : -1))

    if (data) {
      // after sorting, insert default dashboards at the beginning
      routes.unshift({
        path: "portfolio",
        label: "Default Portfolio Dashboard",
        customClasses: {
          normal: "text-gray-60 dark:text-gray-30 underline",
          active: "text-accent underline font-bold",
        },
      })
      routes.unshift({
        path: "location",
        label: "Default Location Dashboard",
        customClasses: {
          normal: "text-gray-60 dark:text-gray-30 underline",
          active: "text-accent underline font-bold",
        },
      })
    }

    return {
      dashboardRoutes: routes,
      dashboardsObject: obj,
      dashboards,
    }
  }, [data])

  useEffect(() => {
    if (selectedAccount) {
      refetch()
    }
  }, [selectedAccount])

  useEffect(() => {
    if (dashboard && locationsObj) {
      if (dashboardId) {
        setWorkingDashboard({ ...dashboard })
      } else {
        setWorkingDashboard(dashboardNewFormat)
      }
    }
  }, [dashboard, locationsObj])

  useEffect(() => {
    if (
      selectedAccount &&
      !loadingDashboards &&
      location.pathname.includes("/seasonal/dashboards") &&
      !location.pathname.includes("/seasonal/dashboards/new") &&
      dashboardRoutes.length > 0 &&
      dashboardId &&
      !dashboardRoutes.find((d) => d.path === dashboardId) &&
      !DEFAULT_DASHBOARD_TEMPLATE_IDS.includes(dashboardId)
    ) {
      // if account changes and dashboardId is no longer in
      // dashboard routes
      // go to first dashboard available
      navigate("/seasonal/dashboards/" + dashboardRoutes[0].path)
    }
  }, [selectedAccount, dashboardRoutes, dashboardId, loadingDashboards])

  const addOrEditActions = useMemo(() => {
    // ADDING /////////////////////////////////////////////////////////////
    if (location.pathname.includes("new"))
      return {
        closeModalConfig: {
          title: t("areYouSureCloseNewDashboard"),
          text: t("closeNewDashboardConsequences"),
          onCancel: () => null,
          onContinue: () => {
            setWorkingDashboard(dashboardNewFormat)
            navigate("/seasonal/dashboards")
          },
          onContinueLabel: t("goOut"),
          onCancelLabel: t("cancel"),
        },
        nextRoute: "/seasonal/dashboards/new",
        baseRoute: "/seasonal/dashboards/new",
      }
    // EDITING ////////////////////////////////////////////////////////////
    return {
      closeModalConfig: {
        title: t("areYouSureCloseEditDashboard"),
        text: t("allYourChangesWillBeLost"),
        onCancel: () => null,
        onContinue: () => {
          setWorkingDashboard({ ...originalDashboard })
          navigate("/seasonal/dashboards")
        },
        onContinueLabel: t("goOut"),
        onCancelLabel: t("cancel"),
      },
      nextRoute: "/seasonal/dashboards/" + dashboardId + "/edit",
      baseRoute: "/seasonal/dashboards/" + dashboardId + "/edit",
    }
  }, [location.pathname])

  const goToNewDashboard = () => {
    navigate("/seasonal/dashboards/new")
  }

  const cancelDashboardAdditionOrEdition = () => {
    confirmationModal(addOrEditActions.closeModalConfig)
  }

  const goToStep = (next: string) => {
    navigate(addOrEditActions.nextRoute + "/" + next)
  }

  const navigateToAllowedDashboardRoute = () => {
    navigateToAllowed(
      dashboardAdditionAndEditionRoutes,
      addOrEditActions.baseRoute,
    )
  }

  const createOrEditDashboard = async (
    dashboardData?: IDashboard,
    doNavigate = true,
  ) => {
    // use optional dashboard argument if you don't want the
    // payload to depend on the current workingDashboard state
    let dashboard = dashboardData ?? workingDashboard
    // if there's no dashbaord id, it means we need to create it
    const isEditing = !!dashboard?.id
    let fn: typeof dashboardPOST | typeof dashboardPUT = dashboardPOST
    let successMessages = [
      "DASHBOARDCreatedSuccessfully",
      "{Dashboard} created successfully!",
    ]

    if (isEditing) {
      // add props and delete schedule obj (not needed for updates)
      dashboard = {
        ...dashboard,
        updated_by_email: auth.user?.email,
      }
      delete dashboard.report_schedules

      // fn => update
      fn = dashboardPUT
      // messages
      successMessages = [
        "DASHBOARDEditedSuccessfully",
        "{Dashboard} edited successfully!",
      ]
    }

    const res = (await fn(dashboard)) as CustomResponse
    if (!areResTypeAndDataValid(res)) return false

    // POST responses come as a string of results (for some reason)
    const data = (isEditing ? res.data : res.data[0]) ?? []

    if (!data.id) return false

    // sets dashboard state
    setWorkingDashboard(data)

    // invalidate and refetch dashboard queries
    await Promise.allSettled([
      queryClient.invalidateQueries(["dashboards", selectedAccount]),
      // isEditing
      queryClient.invalidateQueries(["dashboard", dashboardId]),
      refetch(),
    ])

    // navigate to dashboard
    if (doNavigate) navigate("/seasonal/dashboards/" + data.id)

    enqueueAlert(
      t(successMessages[0], successMessages[1], {
        dashboard: dashboard?.title ?? t("theDashboard", "The dashboard"),
      }),
    )
    return true
  }

  const deleteDashboard = useCallback(
    (dashboard: IDashboard) => {
      dashboardDELETE(dashboard.id)
        .then((res) => {
          if (res?.status == 204 || areResTypeAndDataValid(res)) {
            queryClient.invalidateQueries(["dashboards", selectedAccount])
            enqueueAlert(
              t(
                "DashboardDeletedSuccessfully",
                "{dashboard} deleted successfully!",
                {
                  dashboard:
                    dashboard.title ?? t("theDashboard", "The dashboard"),
                },
              ),
            )
          }
          refetch()
        })
        .catch(() => {
          enqueueAlert(
            t("DashboardDeletionFailed", "Error deleting dashboard."),
          )
        })
    },
    [refetch],
  )

  const dashboardProviderValue = useMemo(() => {
    return {
      dashboardRoutes,
      dashboardsObject,
      dashboards,
      cancelDashboardAdditionOrEdition,
      goToStep,
      goToNewDashboard,
      navigateToAllowedDashboardRoute,
      activeDashboardId: dashboardId,
      loadingDashboards,
      dragAuxDate,
      setDragAuxDate,
      isEditingDashboard,
      workingDashboard,
      setWorkingDashboard,
      originalDashboard,
      setOriginalDashboard,
      createOrEditDashboard,
      deleteDashboard,
    }
  }, [
    dashboardRoutes,
    dashboardsObject,
    dashboards,
    cancelDashboardAdditionOrEdition,
    goToStep,
    goToNewDashboard,
    navigateToAllowedDashboardRoute,
    dashboardId,
    loadingDashboards,
    dragAuxDate,
    setDragAuxDate,
    isEditingDashboard,
    workingDashboard,
    setWorkingDashboard,
    originalDashboard,
    setOriginalDashboard,
    createOrEditDashboard,
  ])

  useEffect(() => {
    setSecondSidebarOptions((prev) => ({
      ...prev,
      seasonal: {
        ...prev["seasonal"],
        dashboards: {
          title: t("dashboards", "Dashboards"),
          routes: dashboardRoutes,
          createRoutePath: (route) => {
            const basePath = "/seasonal/dashboards"
            if (!route) return basePath
            return (
              basePath + "/" + (typeof route === "string" ? route : route.path)
            )
          },
          emptyMsg: "",
          plusButtonTooltip: t("newDashboard", "New Dashboard"),
          autoEnterFirstOption: true,
        },
      },
    }))
  }, [dashboardRoutes])

  return (
    <DashboardContext.Provider value={dashboardProviderValue}>
      {children}
    </DashboardContext.Provider>
  )
}

export default DashboardProvider
