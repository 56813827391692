import { useTranslate } from "@tolgee/react"
import { useState } from "react"
import { Button } from "../../../../../climateui/components"
import { useOutsideComponentClickHandler } from "../../../../../climateui/hooks"
import { CancelIcon } from "../../../../../climateui/icons"
import EmptyModal from "../../../../../climateui/providers/Modal/EmptyModal"
import PercentageInput from "../../../../../components/PercentageInput"

export const BulkProbabilityForm = ({
  open,
  onCancel,
  onSave,
}: {
  open: boolean
  onCancel: () => void
  onSave: (probability: number) => void
}) => {
  /* HOOKS > START */
  const { t } = useTranslate()
  /* HOOKS < END */

  /* STATE > START */

  const [probability, setProbability] = useState<number | undefined>(0.01)
  /* STATE < END */

  const modalRef = useOutsideComponentClickHandler(onCancel)

  return (
    <EmptyModal
      open={open}
      customClasses="w-[420px] relative rounded-lg bg-light-bg dark:bg-dark-bg">
      <div
        ref={modalRef}
        className="flex flex-col h-[215px]">
        {/* TITLE BAR */}
        <div className="flex flex-row justify-between flex-none p-4 border-b border-gray-14 dark:border-gray-78">
          <h1 className="title-sm">
            {t("bulkEditProbability", "Bulk Edit Probability")}
          </h1>
          <div
            onClick={onCancel}
            className="w-6 h-6 cursor-pointer fill-gray-60"
            role="button">
            <CancelIcon />
          </div>
        </div>
        <div className="flex items-center justify-center px-4 grow">
          <PercentageInput
            label={t("probability", "Probability")}
            percentage={probability}
            setPercentage={setProbability}
          />
        </div>

        {/* BOTTOM BAR */}
        <div className="flex flex-row justify-end flex-none p-4 border-t border-gray-14 dark:border-gray-78">
          <Button
            onClick={() => onSave(probability ?? 0)}
            label={t("save", "Save")}
          />
        </div>
      </div>
    </EmptyModal>
  )
}
