import { ArrayParam } from "use-query-params"

export function getQueryParam(param: string) {
  const queryParams = new URLSearchParams(location.search)
  for (const [key, value] of queryParams) {
    if (key === param) {
      return value
    }
  }
  return null
}

export function getQueryParams() {
  const queryParams = new URLSearchParams(location.search)
  return queryParams
}

export const SetParam = {
  encode: (_set: Set<string>) => ArrayParam.encode(Array.from(_set)),
  decode: (value: string | (string | null)[] | null | undefined) => {
    const cleanArray = (ArrayParam.decode(value) ?? []).filter(
      (x) => x !== null,
    ) as string[]
    return new Set(cleanArray)
  },
}
