const NoResultsState = (props: {
    noResultsMessage: string
    tryAnotherFilterMessage: string
}) => {
    const { noResultsMessage, tryAnotherFilterMessage } = props
    return (
        <div className="mt-3">
            <p className="flex justify-center items-center body-lg">
                {noResultsMessage}
            </p>
            <p className="flex justify-center items-center body-sm text-gray-60 dark:text-gray-30">
                {tryAnotherFilterMessage}
            </p>
        </div>
    )
}

export default NoResultsState
