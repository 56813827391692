import { debounce } from "lodash"
import {
  createContext,
  useEffect,
  useState,
  ReactNode,
  useMemo,
  useContext,
  useCallback,
} from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { IRoute } from "../climateui/types"

export interface ISecondSidebarOption {
  title: string
  routes: IRoute[]
  createRoutePath: (route?: IRoute | string) => string
  emptyMsg: string
  plusButtonTooltip: string
  autoEnterFirstOption?: boolean
  cannotGoBackToMain?: boolean
}

type SecondSidebarOptions = Record<string, Record<string, ISecondSidebarOption>>

export interface IUIProvider {
  sidebarOpen: boolean
  setSidebarOpen: (value: boolean) => void
  showSecondSidebar: boolean
  setShowSecondSidebar: (value: boolean) => void
  secondSidebarOptions: SecondSidebarOptions
  setSecondSidebarOptions: React.Dispatch<
    React.SetStateAction<SecondSidebarOptions>
  >
  activeSecondSidebarOptions?: ISecondSidebarOption
  lastVisitedViewsPerTab: Record<string, string>
  setSameNavigationCount: React.Dispatch<React.SetStateAction<number>>
}

const UIContext = createContext({} as IUIProvider)

export const useUI = () => useContext(UIContext)

function UIProvider({ children }: { children: ReactNode }) {
  const location = useLocation()
  const navigate = useNavigate()
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true)
  const [showSecondSidebar, setShowSecondSidebar] = useState<boolean>(true)
  const [secondSidebarOptions, setSecondSidebarOptions] =
    useState<SecondSidebarOptions>({})
  const [lastVisitedViewsPerTab, setLastVisitedViewsPerTab] = useState<
    Record<string, string>
  >({})
  const [sameNavigationCount, setSameNavigationCount] = useState(0)

  const activeSecondSidebarOptions = useMemo(() => {
    const paths = location.pathname.split("/")
    const tab = paths[1]
    const selectedRoute = paths[2]

    return secondSidebarOptions[tab]?.[selectedRoute]
  }, [location.pathname, secondSidebarOptions])

  useEffect(() => {
    if (
      sidebarOpen &&
      activeSecondSidebarOptions?.autoEnterFirstOption &&
      activeSecondSidebarOptions?.routes[0]?.path &&
      location.pathname.split("/").length <= 3
    ) {
      navigate(
        location.pathname + "/" + activeSecondSidebarOptions.routes[0].path,
      )
      setShowSecondSidebar(true)
    }

    if (activeSecondSidebarOptions?.routes?.length === 0) {
      setShowSecondSidebar(false)
    }
  }, [sameNavigationCount, activeSecondSidebarOptions?.title])

  const closeSecondSidebar = useCallback(
    debounce((pathname, assoPath) => {
      if (pathname === assoPath) setShowSecondSidebar(false)
    }, 100), // assuming react useEffects and navigation is faster that 100 ms
    [],
  )

  useEffect(() => {
    if (!activeSecondSidebarOptions) return

    let assoPath = activeSecondSidebarOptions.createRoutePath("")
    if (assoPath[assoPath.length - 1] === "/")
      assoPath = assoPath.substring(0, assoPath.length - 1)

    let locationPath = location.pathname
    if (locationPath[locationPath.length - 1] === "/")
      locationPath = locationPath.substring(0, locationPath.length - 1)
    if (locationPath.split("/").length >= 4) setShowSecondSidebar(true)

    closeSecondSidebar(location.pathname, assoPath)
  }, [location.pathname, activeSecondSidebarOptions])

  useEffect(() => {
    const paths = location.pathname.split("/")
    if (paths.length < 2) return

    paths.shift() // remove first empty string
    const tab = paths.shift() as string // "seasonal" | "climate" ...
    const page = paths.join("/") // the rest
    setLastVisitedViewsPerTab((prev) => ({ ...prev, [tab]: page }))
  }, [location.pathname])

  useEffect(() => {
    // force resize
    setTimeout(() => {
      const _event = new CustomEvent("force-resize", { bubbles: true })
      window.dispatchEvent(_event)
    }, 100)
  }, [sidebarOpen])

  const providerValue = useMemo(
    () => ({
      sidebarOpen,
      setSidebarOpen,
      showSecondSidebar,
      setShowSecondSidebar,
      secondSidebarOptions,
      setSecondSidebarOptions,
      activeSecondSidebarOptions,
      lastVisitedViewsPerTab,
      setSameNavigationCount,
    }),
    [
      sidebarOpen,
      secondSidebarOptions,
      activeSecondSidebarOptions,
      showSecondSidebar,
      lastVisitedViewsPerTab,
    ],
  )

  return (
    <UIContext.Provider value={providerValue}>{children}</UIContext.Provider>
  )
}

export default UIProvider
