import { ReactNode } from "react"

function PageHeader({
  title,
  subtitle,
  paragraph,
  secondaryAction,
  primaryAction,
}: {
  title?: string | ReactNode
  subtitle?: string
  paragraph?: string
  secondaryAction?: ReactNode
  primaryAction?: ReactNode
}) {
  return (
    <div className="flex flex-row items-center justify-between gap-4 shrink-0 h-[74px]">
      <div className="flex flex-col gap-2">
        {title && (
          <div className="title-lg text-light-text dark:text-dark-text">
            {title}
          </div>
        )}
        {subtitle && (
          <p className="body-md text-gray-60 dark:text-gray-30">{subtitle}</p>
        )}
      </div>

      <div className="flex flex-row items-center gap-2">
        {paragraph && (
          <p className="body-sm text-gray-60 dark:text-gray-30 pr-2">
            {paragraph}
          </p>
        )}
        {secondaryAction}
        {primaryAction}
      </div>
    </div>
  )
}

export default PageHeader
