import { MouseEvent, useState } from "react"
import { useTimeline } from ".."
import { colors } from "../../../climateui/utils/colors"
import { ITimelineStage, TIMELINE_EDITION_ACTIONS } from "../../../types"
import MoveAllHandle from "./MoveAllHandle"
import PeriodTimeline from "./PeriodTimeline"
import { TimelineNewStage } from "./TimelineNewStage"
import { useTimelineRow } from "./TimelineRow"
import TimelineStage from "./TimelineStage"

const NEW_STAGE_DURATION = 14

function TimelineStages() {
  const {
    isEditing,
    pxPerDay,
    timelineStartDate,
    setDragAuxDate,
    setFocusedStage,
    setEditingStage,
  } = useTimeline()
  const { row, totalLevels, stageHeight, stagesLevels, riskProfilesIds, open } =
    useTimelineRow()

  const stages = row.stages ?? []

  const [newStageStartDate, setNewStageStartDate] = useState<Date>()

  const newStageStartDateOffset = Math.round(NEW_STAGE_DURATION / 2)
  const handleHovering = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (!isEditing) return
    if (e.pageX === 0) return
    const boundingBox = e.currentTarget.getBoundingClientRect()
    const pxMoved = e.pageX - boundingBox.x
    const daysMoved = Math.round(pxMoved / pxPerDay)
    const newStartDate = new Date(timelineStartDate)
    newStartDate.setDate(
      newStartDate.getDate() + daysMoved - newStageStartDateOffset,
    )
    setNewStageStartDate(newStartDate)
    setDragAuxDate(newStartDate)
  }

  const handleHoverEnd = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (!isEditing) return
    setNewStageStartDate(undefined)
    setFocusedStage(undefined)
    setDragAuxDate(undefined)
  }

  const newStage = () => {
    if (!newStageStartDate || !isEditing) return
    const endDate = new Date(newStageStartDate)
    endDate.setDate(endDate.getDate() + NEW_STAGE_DURATION)
    const newId = row.id + new Date().toISOString() // INFO: unique temp-FE id
    setFocusedStage(newId)
    setEditingStage({
      name: "",
      start_date: newStageStartDate,
      duration: NEW_STAGE_DURATION,
      row_id: row.id,
      color: colors.gray[60], // TODO: Maybe random color
      id: newId,
      action: TIMELINE_EDITION_ACTIONS.ADDED,
      riskProfileStages: [],
    })
  }

  return (
    <div>
      {/* STAGES SECTION */}
      <div
        className="relative"
        style={{
          height: (totalLevels || 1) * stageHeight + "px",
        }}>
        {/* TIMELINE */}
        {/* <WeeklyTimeline /> */}
        <PeriodTimeline daysPerPeriod={10} />
        {/* Add Stage hover section */}
        {isEditing && (
          <div
            className={
              "absolute inset-0 z-20 " + (isEditing ? "cursor-pointer" : "")
            }
            onMouseMove={handleHovering}
            onMouseLeave={handleHoverEnd}
            onClick={newStage}>
            {/* Add Stage component */}
            {newStageStartDate && (
              <TimelineNewStage
                date={newStageStartDate}
                duration={NEW_STAGE_DURATION}
              />
            )}
          </div>
        )}
        {isEditing && <MoveAllHandle />}
        {/* STAGES */}
        {stages.map((stage: ITimelineStage, index: number) => {
          return (
            <TimelineStage
              key={stage.id}
              level={stagesLevels[stage.id]}
              index={index}
              stage={stage}
            />
          )
        })}
      </div>

      {/* RISKS PORTALS SECTION */}
      <div
        className={[
          "shrink-0",
          "transition-all duration-75 border-t",
          open
            ? "border-gray-14 dark:border-gray-78 max-h-max"
            : "max-h-0 border-transparent overflow-hidden",
        ].join(" ")}>
        {riskProfilesIds.map((riskProfileId) => {
          return (
            <div
              id={"row-" + row.id + "-risk-" + riskProfileId}
              key={"row-" + row.id + "-risk-" + riskProfileId}
              className="h-10 relative">
              {/* <WeeklyTimeline /> */}
              <PeriodTimeline daysPerPeriod={10} />
            </div>
          )
        })}

        {/* Hidden while prod defines if we want the "Add Risk Profile" modal */}
        <div className="h-10 relative hidden">
          <PeriodTimeline daysPerPeriod={10} />
        </div>
      </div>
    </div>
  )
}

export default TimelineStages
