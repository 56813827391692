function StageColorBoxes({
  isFocused,
  color,
}: {
  isFocused: boolean
  color: string
}) {
  const boxesClasses = "absolute inset-0 border rounded-sm pointer-events-none"

  return (
    <>
      <div
        className={[
          boxesClasses,
          isFocused ? "hidden" : "group-hover:hidden",
        ].join(" ")}
        style={{
          borderColor: color,
          opacity: 0.4,
        }}></div>
      <div
        className={[
          boxesClasses,
          isFocused ? "block" : " hidden group-hover:block",
        ].join(" ")}
        style={{
          borderColor: color,
          opacity: 1,
        }}></div>
      <div
        className={[boxesClasses, "border-transparent"].join(" ")}
        style={{
          backgroundColor: color,
          opacity: 0.12,
        }}></div>
    </>
  )
}

export default StageColorBoxes
