import { useTranslate } from "@tolgee/react"
import { TooltipV2 } from ".."
import getVariableIcon from "../../utils/icons/getVariableIcon"

interface IToggleButton {
    key: string | number
    value: string
    disabled?: boolean
    description?: string
    // icon?: React.ReactNode
}

export type TToggleOptions = Record<string, IToggleButton>

function ToggleSelector(props: {
    readonly options: TToggleOptions
    readonly selectedOptions: string[]
    readonly enabledOptions?: string[]
    readonly updateSelectedOptions: (currElement: string) => void
    readonly showIcons?: boolean
}) {
    const { t } = useTranslate()
    const {
        options,
        selectedOptions,
        enabledOptions,
        updateSelectedOptions,
        showIcons = false,
    } = props
    const optionsKeys = Object.keys(options)

    return (
        <div className="flex flex-row items-center justify-center gap-0">
            {optionsKeys.map((optionKey) => {
                const option = options[optionKey]

                const isSelected: boolean = selectedOptions.includes(optionKey)
                const isDisabled: boolean = option.disabled ?? false

                // TODO modularize this later
                const leftOption = optionKey == optionsKeys[0]
                const rightOption =
                    optionKey == optionsKeys[optionsKeys.length - 1]

                return (
                    <TooltipV2
                        containerClasses={`relative flex items-center justify-center group transition-all duration-200 w-fit`}
                        position={"top"}
                        content={option.description ?? t(option.value)}
                        key={`option_tooltip_${optionKey}`}>
                        <button
                            disabled={isDisabled}
                            key={`option_button_${optionKey}`}
                            className={[
                                "p-1 px-3 border-[1px] border-x-[0.5px] transition-all duration-200 relative",
                                "label-lg text-center text-[14px] h-[33px] ",
                                (isSelected &&
                                    !isDisabled &&
                                    "border-accent-medium text-accent bg-accent-light fill-accent ") ??
                                    "",
                                ((!isSelected || isDisabled) &&
                                    "border-gray-14 dark:border-gray-78 bg-light-bg dark:bg-dark-bg fill-gray-60 hover:fill-accent hover:text-accent text-gray-60 dark:text-gray-30 ") ??
                                    "",
                                // (isDisabled && "bg-gray-10") ?? "",
                                "disabled:bg-gray-5 dark:disabled:bg-gray-60 disabled:text-gray-30 disabled:fill-gray-30 disabled:cursor-not-allowed ",
                                leftOption ? "rounded-l-md border-l-[1px]" : "",
                                rightOption
                                    ? "rounded-r-md border-r-[1px]"
                                    : "",
                            ].join(" ")}
                            onClick={() => {
                                updateSelectedOptions(optionKey)
                            }}>
                            {/* TODO change this later to make use of option.icon */}
                            {showIcons
                                ? getVariableIcon(optionKey)
                                : t(option.value)}
                        </button>
                    </TooltipV2>
                )
            })}
        </div>
    )
}

export default ToggleSelector
