import { useTranslate } from "@tolgee/react"
import { gql } from "graphql-request"
import { DateTime } from "luxon"
import WidgetWrapper from "../../../../climateui/components/Widgets/WidgetWrapper/WidgetWrapper"
import { GenericPageHeader } from "../../../../components"
import { ResponsivePaddingWrapper } from "../../../../layouts/TabLayout"
import { useAssets } from "../../../../providers"
import { useYieldOutlook } from "../provider"
import YieldOverviewWidget from "./YieldOverviewWidget"

export interface IStageYieldOverview {
  stage_name: string
  start_date: Date | string
  end_date: Date | string
}

export interface IYieldCountryOverviewModel {
  asset_id: string
  region_id: string
  stages: IStageYieldOverview[]
  newest_seasonal_date: string
  oldest_seasonal_date: string
  stats: {
    results: {
      deviation_mean: number
      tercile_probabilities: {
        "prob_0.00-0.33": number
        "prob_0.33-0.67": number
        "prob_0.67-1.00": number
      }
    }[]
  }
}

export interface IYieldOverview {
  asset_id: string
  asset_name: string
  asset_models: IYieldCountryOverviewModel[]
}

const yieldOverviewQuery = gql`
  query ($region_ids: [String], $asset_ids: [String], $init_time: String) {
    yield_outlook_model(
      filter: {
        region_ids: $region_ids
        asset_ids: $asset_ids
        seasonal_status: "active"
      }
    ) {
      results {
        region_id
        stats: seasonal_stats(filter: { startDate: $init_time }) {
          results {
            tercile_probabilities
            deviation_mean
          }
        }
        asset_id
        newest_seasonal_date
        oldest_seasonal_date
        stages(filter: { current_date: $init_time }) {
          stage_name
          start_date
          end_date
        }
      }
    }
  }
`

const YieldOverview = () => {
  const { t } = useTranslate()
  const { countries, isLoading } = useYieldOutlook()
  const { allAssets } = useAssets()
  const startingWeekDate = DateTime.now().startOf("week")

  return (
    <ResponsivePaddingWrapper>
      <div className="flex flex-col h-full overflow-hidden grow gap-4">
        <GenericPageHeader
          pageTitle={t("yieldOutlookSummary")}
          right={undefined}
          bottom={
            <h1 className="w-full text-end body-sm text-gray-60 dark:text-gray-30">
              {`${t("refreshed")} ${startingWeekDate.toLocaleString(
                DateTime.DATE_FULL,
              )}
                            `}
            </h1>
          }
          bottomSectionPadding="pt-0"
        />
        <div className="overflow-y-auto h-3/4">
          <WidgetWrapper
            component={YieldOverviewWidget}
            query={yieldOverviewQuery}
            selectors={{ $data: "yield_outlook_model.results[]" }}
            filters={[
              {
                propName: "region_ids",
                value: countries && Object.keys(countries),
                loading: isLoading,
              },
              {
                propName: "asset_ids",
                value: allAssets && Object.keys(allAssets),
              },
              {
                propName: "init_time",
                value: startingWeekDate.toUTC().toISODate(),
              },
            ]}
          />
        </div>
        <h1 className="body-sm text-gray-60 dark:text-gray-30">
          {t("contactToAddCropDescription")}
        </h1>
      </div>
    </ResponsivePaddingWrapper>
  )
}

export default YieldOverview
