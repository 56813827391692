import { useTranslate } from "@tolgee/react"
import { gql } from "graphql-request"
import GenericChartWidget from "../../../../../climateui/components/Widgets/SeasonalWidgets/GenericChartWidget"
import WidgetWrapper from "../../../../../climateui/components/Widgets/WidgetWrapper/WidgetWrapper"
import IWidgetDefaultProps from "./WidgetDefaultProps"

function VariableChartWidget(props: IWidgetDefaultProps) {
  const { id, onLoad, onMount, dashboardFilters, selectors, actions } = props
  const { t } = useTranslate()
  return (
    <WidgetWrapper
      id={id}
      onLoad={onLoad}
      onMount={onMount}
      selectedFilters={[
        "unit_system",
        "time_resolution",
        "risk_settings_ids",
        "granularity",
        "config",
        "location_ids",
        "start_date",
        "end_date",
      ]}
      component={GenericChartWidget}
      query={gql`
        query (
          $location_ids: [String]
          $config: [WeatherConfig]!
          $granularity: String
          $risk_settings_ids: [String]
          $time_resolution: String
          $unit_system: String
          $start_date: String
          $end_date: String
        ) {
          locations(filter: { location_ids: $location_ids }) {
            results {
              name
              latitude
              longitude
              weather(
                filter: {
                  config: $config
                  granularity: $granularity
                  unit_system: $unit_system
                  start_date: $start_date
                  end_date: $end_date
                }
              ) {
                data
              }
              varieties {
                results {
                  name
                  asset {
                    name
                  }
                }
              }
              labels {
                name
              }
            }
          }
          alerts(
            filter: {
              risk_settings_ids: $risk_settings_ids
              location_ids: $location_ids
              time_resolution: $time_resolution
            }
          ) {
            results {
              start_date
              end_date
              duration
              avg_risk_value
              time_resolution
              category
              expected_magnitude
              expected_magnitude_list
              expected_percentile
              frequency
              last_occurrence
              trigger_threshold_list
              processing_run {
                risk_setting_id
                location {
                  name
                  id
                }
                risk_profile {
                  id
                  name
                  probability
                  type
                  hazard_profiles {
                    results {
                      id
                      window
                      threshold(unit_system: $unit_system)
                      type
                      logical_op
                      conditional
                      hazard_variable {
                        dashboard_variable
                        readable_name
                        units(unit_system: $unit_system)
                        units_metric
                        units_imperial
                      }
                    }
                  }
                  varieties {
                    results {
                      name
                      asset {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      selectors={{
        ...selectors,
        $items: "locations.results[0].weather.data",
        $alerts: "alerts.results",
        $varieties: "locations.results[0].varieties.results",
        $labels: "locations.results[0].labels",
        $location: "locations.results[0]",
        errorMessage: t("errorLoadingData"),
        reloadMessage: t("clickReload"),
        noResultsMessage: t("noResultCurrentFilters"),
        tryAnotherFilterMessage: t("tryAnotherFilter"),
      }}
      actions={{
        translationFn: t,
        ...actions,
      }}
      filters={dashboardFilters}
    />
  )
}

export default VariableChartWidget
