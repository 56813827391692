import { useQuery } from "react-query"
import { timelinePOST } from "../utils/networking/asset"

const useVarietyTimelineQuery = (varietyId?: string) => {
  return useQuery({
    queryKey: ["timeline", varietyId],
    enabled: !!varietyId,
    queryFn: () => (varietyId ? timelinePOST(varietyId) : null),
  })
}

export default useVarietyTimelineQuery
