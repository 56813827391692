import { useEffect, ReactNode } from "react"
import { useAccount } from "./AccountProvider"
import { useAuth } from "./AuthProvider"

function PendoWrapper({ children }: { children: ReactNode }) {
  const { user } = useAuth()
  const { accountsObject, selectedAccount } = useAccount()

  useEffect(() => {
    const url = new URL(location.href)
    const params = new URLSearchParams(url.search)

    // if it's a generated report, then return
    if (
      !user ||
      !accountsObject ||
      !selectedAccount ||
      params.get("pagination") === "false" ||
      params.get("pagination") === "0"
    )
      return
    // We might want to add more variables or more information.
    const pendo_object = {
      visitor_id: user.email,
      account_id: accountsObject[selectedAccount]?.name,
    }
    window["wrapper_pendo"](pendo_object)
  }, [user, selectedAccount])

  return children
}

export default PendoWrapper
