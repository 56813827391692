import { useTranslate } from "@tolgee/react"
import { Button } from "../../../../climateui/components"
import { CalendarIcon, CancelIcon, PlusIcon } from "../../../../climateui/icons"
import { useDashboard } from "../../../../providers/DashboardProvider"
import { IScheduler } from "../../../../types"
import { formatDate } from "../../../../utils/transform"
import { getInfoFromSchedule } from "../SchedulerTableUtils"
import ScheduleTableActions from "./ScheduleTableActions"

interface IScheduleDashboard {
  onCancel?: () => void
  newSchedule?: () => void
}

export function OpenScheduleDashboardForm({
  onCancel,
  newSchedule,
}: IScheduleDashboard) {
  const { t } = useTranslate()
  const { workingDashboard } = useDashboard()

  return (
    <div className="absolute z-50 bg-light-bg dark:bg-dark-bg rounded-md border border-gray-14 dark:border-gray-78 mt-12 divide-y-[1px] divide-gray-14 dark:divide-gray-78 w-96">
      <div className="flex w-full justify-between font-roboto p-3">
        <h1 className="title-lg">{"Scheduled Reports"}</h1>
        <div
          className="w-[20px] cursor-pointer"
          onClick={onCancel}>
          <CancelIcon />
        </div>
      </div>
      <div className="gap-y-3 p-3 overflow-auto max-h-72">
        {workingDashboard?.report_schedules?.length == 0 ? (
          <div className="align-middle text-center my-6 flex-wrap text-gray-60 dark:text-gray-30 ">
            <h1>{"No Existing Schedules"}</h1>
            <h1> {"Click on 'New Schedule'"}</h1>
          </div>
        ) : (
          workingDashboard?.report_schedules?.map((schedule: IScheduler) => {
            const { recurrence, timeAndDay } = getInfoFromSchedule(schedule)
            return (
              <div
                className="flex-wrap bg-light-bg dark:bg-dark-bg rounded-md border border-gray-14 dark:border-gray-78 p-3 mb-3 font-roboto"
                key={schedule.id}>
                <div className="flex w-full justify-between">
                  <h1 className="text-[18px]">{schedule?.subject}</h1>
                  <ScheduleTableActions rowData={schedule} />
                </div>
                <div className="flex text-[14px] text-gray-60 dark:text-gray-30">
                  <span className="block w-5 h-5 fill-gray-60 mr-1 -ml-[2px]">
                    <CalendarIcon />
                  </span>
                  <h1>{`${recurrence}, ${timeAndDay}`}</h1>
                </div>
                <h1 className="text-[14px] text-gray-60 dark:text-gray-30">{`Last Updated ${formatDate(
                  schedule.updated_at as string,
                )}`}</h1>
              </div>
            )
          })
        )}
      </div>
      <div className="flex justify-end w-full mt-1 p-3">
        <Button
          icon={undefined}
          label={t("cancel")}
          type={"secondary"}
          extraClasses={"mr-2 mt-3"}
          onClick={onCancel}
        />
        <Button
          icon={<PlusIcon />}
          label={t("newSchedule")}
          extraClasses={"mt-3"}
          onClick={newSchedule}
        />
      </div>
    </div>
  )
}

export default OpenScheduleDashboardForm
