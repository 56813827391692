import { useTimeline } from ".."
import { IPrimitivesDictionary } from "../../../climateui/types"
import { ITimelineStage } from "../../../types"
import { useTimelineRow } from "./TimelineRow"

function TimelineRiskProfileStage({
  mainStyle,
  stage,
}: {
  mainStyle: IPrimitivesDictionary
  stage: ITimelineStage
}) {
  const { focusedStage, setFocusedStage, isEditing } = useTimeline()
  const { row } = useTimelineRow()

  const isFocused = focusedStage === stage.id
  const allFocused = focusedStage === row.id

  return (
    <div
      className={[
        "absolute -translate-y-1/2 top-1/2 z-20",
        "flex flex-row items-center justify-center",
        "bg-gray-30/80 rounded-sm",
        "border border-white",
        "text-[14px] h-4",
        isEditing && (isFocused || allFocused) ? "bg-gray-60/80 z-30" : "",
      ].join(" ")}
      onMouseEnter={() => {
        if (isEditing) {
          setFocusedStage(stage.id)
        }
      }}
      style={mainStyle}></div>
  )
}

export default TimelineRiskProfileStage
