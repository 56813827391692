import { useTranslate } from "@tolgee/react"
import _ from "lodash"
import {
  FocusEvent,
  KeyboardEvent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "../../../climateui/components"
import { BellIcon, EditIcon, TrashIcon } from "../../../climateui/icons"
import { useModal } from "../../../climateui/providers/Modal/ModalContextProvider"
import { useToast } from "../../../climateui/providers/Toast/ToastContextProvider"
import {
  areResTypeAndDataValid,
  CustomResponse,
} from "../../../climateui/utils/http"
import { GenericPageHeader } from "../../../components"
import SeasonalCalendar from "../../../components/SeasonalCalendar/SeasonalCalendar"
import { useIsFlagEnabled } from "../../../hooks"
import { useAuth } from "../../../providers"
import { useAccount } from "../../../providers/AccountProvider"
import { usePlanningTool } from "../../../providers/PlanningToolProvider"
import SeasonalCalendarProvider from "../../../providers/SeasonalCalendarProvider"
import { useUI } from "../../../providers/UIProvider"
import queryClient, {
  planAlertSettingsPUT,
  planDELETE,
  planPUT,
} from "../../../utils/networking"
import { COMMON_INPUT_CLASSES } from "./components/PlanNameAndDescription"
import { usePlan } from "./PlanProvider"
import { constructRiskSettings, createEditedPlanObject } from "./utils"

const PlanView = () => {
  const { goToEditPlan, activePlanId } = usePlanningTool()
  const {
    workingPlan,
    originalStrategiesObj,
    modifiableStrategiesObj,
    setModifiableStrategiesObj,
    deletedStagesOrRisks,
    recalculatePlanData,
    isEditingPlan,
    deleteStrategy,
    duplicateStrategy,
    deleteStageOrRisk,
  } = usePlan()
  const { setSameNavigationCount } = useUI()
  const { selectedAccount } = useAccount()
  const { confirmationModal } = useModal()
  const { enqueueAlert } = useToast()
  const auth = useAuth()

  const navigate = useNavigate()
  const { t } = useTranslate()

  const [isLoadingAlertSettings, setIsLoadingAlertSettings] = useState(false)
  const fsTurnIntoAlerts = useIsFlagEnabled(
    "ui_planning_tool_into_alert_settings",
  )

  const deletePlan = () => {
    confirmationModal({
      title: t("areYouSureDeletePlan", "Delete this operational plan?"),
      text: t(
        "planWillBeDeletedPermanently",
        "This plan will be deleted permanently.",
      ),
      onContinueLabel: t("continue"),
      onCancelLabel: t("cancel"),
      onContinue: () => {
        planDELETE(activePlanId)
          .then((res) => {
            if (res?.status != 200 || !areResTypeAndDataValid(res)) return

            navigate("..")
            setSameNavigationCount((prev) => prev + 1)
            enqueueAlert(
              t("PLANDeletedSuccessfully", "Plan deleted successfully!", {
                plan: workingPlan?.name || t("thePlan", "Plan"),
              }),
            )
          })
          .catch(() => {
            enqueueAlert(t("PLANDeletionFailed", "Error deleting plan."))
          })
          .finally(() => {
            queryClient.invalidateQueries(["plans", selectedAccount])
          })
      },
    })
  }

  const saveNameOrDescEdition = (
    prevValue: string,
    newValue: string,
    propName: string,
  ) => {
    if (prevValue === newValue || newValue === "") return
    planPUT(
      createEditedPlanObject(
        {
          ...workingPlan,
          [propName]: newValue,
          created_by: auth.user?.email || "empty@mail.com",
          account_id: selectedAccount,
        },
        _.cloneDeep(originalStrategiesObj),
        _.cloneDeep(modifiableStrategiesObj),
        [...deletedStagesOrRisks],
      ),
    ).then((res) => {
      if (!areResTypeAndDataValid(res)) {
        enqueueAlert(
          t(
            "thereWasAnErrorUpdatingThePlanInfo",
            "There was an error updating the plan information",
          ),
        )
      }
      const data = (res as CustomResponse).data || []
      if (data.id) {
        queryClient.invalidateQueries(["plans", selectedAccount])
        queryClient.invalidateQueries(["plan", activePlanId])
      }
    })
  }

  const handleKeyPressOnInput = (
    keyCode: string,
    inputRef: RefObject<HTMLInputElement>,
    prevValue: string,
  ) => {
    if (keyCode === "Enter") {
      inputRef.current?.blur()
    } else if (keyCode === "Escape") {
      if (inputRef.current) inputRef.current.value = prevValue
      inputRef.current?.blur()
    }
    // else ignore
  }

  const nameRef = useRef<HTMLInputElement>(null)
  const descRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (nameRef.current) nameRef.current.value = workingPlan?.name || ""
    if (descRef.current) descRef.current.value = workingPlan?.description || ""
  }, [workingPlan])

  const nameInput = (
    <input
      aria-label="planNameInput"
      ref={nameRef}
      className={COMMON_INPUT_CLASSES + " w-full"}
      placeholder={t("newPlan")}
      onBlur={(e: FocusEvent<HTMLInputElement>) => {
        saveNameOrDescEdition(workingPlan?.name || "", e.target.value, "name")
      }}
      onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => {
        handleKeyPressOnInput(e.code, nameRef, workingPlan?.name || "")
      }}
    />
  )
  const descriptionInput = (
    <input
      aria-label="planDescriptionInput"
      ref={descRef}
      className={COMMON_INPUT_CLASSES + " w-full"}
      placeholder={t("clickToAddDescription")}
      onBlur={(e: FocusEvent<HTMLInputElement>) => {
        saveNameOrDescEdition(
          workingPlan?.description || "",
          e.target.value,
          "description",
        )
      }}
      onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => {
        handleKeyPressOnInput(e.code, descRef, workingPlan?.description || "")
      }}
    />
  )

  const handleAlertSettings = () => {
    const plannedRisks = constructRiskSettings(workingPlan, selectedAccount)

    if (plannedRisks && plannedRisks.length > 0) {
      setIsLoadingAlertSettings(true)
      planAlertSettingsPUT(plannedRisks).then((res) => {
        if (res?.status === 200 && areResTypeAndDataValid(res)) {
          enqueueAlert(
            t(
              "successTurningIntoAlertSettings",
              "Alert settings created successfully",
            ),
          )
          queryClient.invalidateQueries(["allAlertSettings", selectedAccount])
        } else {
          enqueueAlert(
            t(
              "thereWasAnErrorTurningIntoAlertSettings",
              "There was an error creating your alert settings",
            ),
          )
        }
      })
    }
  }

  return (
    <div className="flex flex-col h-full">
      <GenericPageHeader
        pageTitle={nameInput}
        pageSubtitle={descriptionInput}
        right={
          <div className="flex flex-row items-center gap-2">
            <button
              aria-label="deletePlan"
              className="w-6 mr-2 fill-gray-60 hover:scale-110 transition-all duration-75 hover:fill-red"
              onClick={deletePlan}>
              <TrashIcon />
            </button>
            <Button
              icon={<EditIcon />}
              label={t("edit")}
              type="secondary"
              onClick={() => {
                goToEditPlan(activePlanId as string, location.pathname)
                recalculatePlanData()
              }}
            />

            {auth.hasRole(selectedAccount ?? "", "Admin") &&
            fsTurnIntoAlerts ? (
              <Button
                icon={<BellIcon />}
                label={t("Turn into Alert Settings")}
                type="secondary"
                onClick={() => {
                  handleAlertSettings()
                }}
                disabled={isLoadingAlertSettings}
              />
            ) : (
              <></>
            )}
          </div>
        }
        bottom={undefined}
      />
      <div className="overflow-auto bg-light-bg dark:bg-dark-bg grow z-10">
        <SeasonalCalendarProvider
          deleteStrategy={deleteStrategy}
          duplicateStrategy={duplicateStrategy}
          deleteStageOrRisk={deleteStageOrRisk}
          editMode={isEditingPlan}
          modifiableStrategies={modifiableStrategiesObj}
          setModifiableStrategies={setModifiableStrategiesObj}>
          <SeasonalCalendar />
        </SeasonalCalendarProvider>
      </div>
    </div>
  )
}

export default PlanView
