import { useTranslate } from "@tolgee/react"
import { useEffect, useMemo, useState } from "react"
import {
  Button,
  DebounceSearchInput,
  Table,
} from "../../../../climateui/components"
import { IMap, IMapPin } from "../../../../climateui/components/Map/utils"
import {
  IBooleanDictionary,
  IPrimitivesDictionary,
} from "../../../../climateui/types"
import { GenericPageHeader } from "../../../../components"
import { usePaginationFlag } from "../../../../hooks"
import { HalfMapLayout } from "../../../../layouts"
import { ResponsivePaddingWrapper } from "../../../../layouts/TabLayout"
import { useAuth } from "../../../../providers"
import { useAccount } from "../../../../providers/AccountProvider"
import { useLocations } from "../../../../providers/LocationsProvider"
import { usePlanningTool } from "../../../../providers/PlanningToolProvider"
import { IInsightsLocation } from "../../../../types"
import { buildLocationColumns } from "../../../Admin/Locations/adminLocationsTableUtils"
import LocationsTableFilters from "../../../Admin/Locations/components/LocationsTableFilters"
import { usePlan } from "../PlanProvider"

const planLocationsColumns = [
  "selection",
  "name",
  "region",
  "varieties",
  "labels",
]
const columns = buildLocationColumns(planLocationsColumns)

function PlanLocations() {
  const auth = useAuth()
  const { selectedAccount } = useAccount()
  const { goToStep } = usePlanningTool()
  const { workingPlan, setWorkingPlan } = usePlan()
  const {
    locations,
    loadingLocations,
    tableFilteredCallback,
    filteredPageLocationPins,
  } = useLocations()
  const [globalFilter, setGlobalFilter] = useState("")
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [rowSelection, setRowSelection] = useState<any>({})
  const [columnFilters, setColumnFilters] = useState<
    { id: string; value: string[] | unknown }[]
  >([])

  useEffect(() => {
    if (
      workingPlan?.selectedLocationsAssetVarieties &&
      Object.keys(workingPlan.selectedLocationsAssetVarieties).length > 0
    ) {
      const newRowSelection: IPrimitivesDictionary = {}
      Object.keys(workingPlan.selectedLocationsAssetVarieties).forEach(
        (locId: string) => {
          newRowSelection[locId] = true
        },
      )
      setRowSelection(newRowSelection)
    }
  }, [workingPlan?.selectedLocationsAssetVarieties])

  const next = () => {
    const prevSelectedLocationsAssets =
      workingPlan?.selectedLocationsAssetVarieties || {}

    const selectedLocationsAssetVarieties: Record<string, IBooleanDictionary> =
      {}
    Object.keys(rowSelection).forEach((selectedLocationId) => {
      selectedLocationsAssetVarieties[selectedLocationId] = {
        ...(prevSelectedLocationsAssets[selectedLocationId] || {}),
      }
    })
    setWorkingPlan({
      ...workingPlan,
      selectedLocationsAssetVarieties,
    })
    goToStep("assets")
  }

  const { t } = useTranslate()

  const [doTableFilterCallbackCount, setDoTableFilterCallbackCount] =
    useState(0)

  useEffect(() => {
    // INFO: doTableFilterCallbackCount is useful to update the map pins
    // to what the table is showing. Maybe this could be done less erizo.
    setDoTableFilterCallbackCount(doTableFilterCallbackCount + 1)
  }, [locations, globalFilter, columnFilters])

  const { pins }: IMap = useMemo(() => {
    const newPins: IMapPin[] = [...filteredPageLocationPins]
    newPins.forEach((pin) => {
      if (pin.id && rowSelection[pin.id]) {
        pin.pinStyle = "map-pin-white.png"
      } else {
        pin.pinStyle = "map-pin.png"
      }
    })
    return { pins: newPins }
  }, [filteredPageLocationPins, rowSelection])

  const pageSizeOpts = usePaginationFlag({
    rowTolgeeKey: "locations",
  })

  return (
    <HalfMapLayout
      mapProps={{
        pins: pins,
        mapConfigs: {
          minZoom: 1.1,
          maxZoom: 18,
          renderWorldCopies: false,
        },
      }}>
      <ResponsivePaddingWrapper extraClasses="p-3 px-4 sm:p-3 sm:px-5 elevation-1 border-r border-gray-14 dark:border-gray-78">
        <div className="flex flex-col h-full grow gap-2">
          <GenericPageHeader
            pageTitle={t("selectDesiredLocation")}
            extraClasses="[&_h2]:title-sm"
            right={
              <div className="flex flex-row items-center gap-2">
                <div className="w-48">
                  <DebounceSearchInput
                    placeholder={t("search")}
                    onSearch={setGlobalFilter}
                  />
                </div>

                <Button
                  label={t("continue")}
                  disabled={
                    !rowSelection || Object.keys(rowSelection)?.length === 0
                  }
                  onClick={next}
                />
              </div>
            }
            bottom={
              <LocationsTableFilters
                setColumnFilters={setColumnFilters}
                columnFilters={columnFilters}
              />
            }
            bottomSectionPadding={"pt-2"}
          />
          <div className="overflow-y-auto grow -mr-4 sm:-mr-8">
            <Table<IInsightsLocation>
              data={locations}
              columns={columns}
              paginationOptions={pageSizeOpts}
              state={{
                rowSelection,
                globalFilter,
                columnFilters,
                hiddenColumns: [],
              }}
              getRowId={(location: IInsightsLocation, index: number) =>
                location?.id || index.toString()
              }
              setRowSelection={setRowSelection}
              setGlobalFilter={setGlobalFilter}
              setColumnFilters={setColumnFilters}
              noDataMessage={
                loadingLocations
                  ? t("loadingLocations", "Loading locations...")
                  : t("thereAreNoLocationsROLE", {
                      role: auth.hasRole(selectedAccount ?? "", "Admin")
                        ? "Admin"
                        : "User",
                    })
              }
              extraClasses=""
              tableFilteredCallback={tableFilteredCallback}
              doTableFilterCallbackCount={doTableFilterCallbackCount}
            />
          </div>
        </div>
      </ResponsivePaddingWrapper>
    </HalfMapLayout>
  )
}

export default PlanLocations
