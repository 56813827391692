import { LinePlotComponent } from "../../../entities/PlotComponents/LinePlotComponent"
import { AreaPlotComponent } from "../../../entities/PlotComponents/AreaPlotComponent"
import { DirectionalityPlotComponent } from "../../../entities/PlotComponents/DirectionalityPlotComponent"
import { LinearPlotComponentDTO } from "../entities/LinearPlotComponentDTO"
import { BandPlotComponentDTO } from "../entities/BandPlotComponentDTO"

import {
    IChartPlotComponents,
    PlotComponentDictionary,
    TypePlotComponentLabels,
} from "../../../types"
import {
    DASHBOARD_VARIABLES,
    DATA_SOURCE_VIZUALIZATION_OPTIONS,
    IPlotModelArea,
    IPlotModelGeneric,
    IPlotModelLine,
} from "./types"
import {
    getDashboardVariableEnumValue,
    isAreaDataSource,
    isLineDataSource,
} from "./helper"
import {
    TypeChartPlotUnion,
    TypeMapChart,
    areEqualValues,
    mergeDoubleYValues,
    isLinePlotComponent,
    isAreaPlotComponent,
    isDirectionalityPlotComponent,
} from "../../../utils/helpers"
import dashboardVariables from "../../../../../../views/Seasonal/Dashboards/dashboardVariables"

export interface DataSourceMetaData {
    variable: DASHBOARD_VARIABLES
    visualization: DATA_SOURCE_VIZUALIZATION_OPTIONS
    color: string
    data_version: string
    granularity: string
    opacity: number
    statistics: string[]
    type: string
    units: string
}

export class DataSourceChartPlotComponents
    implements IChartPlotComponents<DataSourceMetaData>
{
    title: string
    plotComponents: TypeMapChart<DataSourceMetaData>
    data: PlotComponentDictionary[]
    axisLabels: TypePlotComponentLabels

    constructor(
        title: string,
        labels: TypePlotComponentLabels,
        sourceChartData: IPlotModelGeneric[]
    ) {
        this.title = title
        this.plotComponents = new Map<
            string,
            TypeChartPlotUnion<DataSourceMetaData>
        >()
        this.data = []
        this.axisLabels = {
            xLabel: labels.xLabel,
            yLabel: labels.yLabel,
            xLabelId: labels.xLabelId,
            yLabelId: labels.yLabelId,
        }
        this.init(sourceChartData, labels)
    }

    private init(
        sourceChartData: IPlotModelGeneric[],
        labels: TypePlotComponentLabels
    ) {
        for (let i = 0; i < sourceChartData.length; i++) {
            const sourceData = sourceChartData[i]

            if (isLineDataSource(sourceData)) {
                this.initLineChart(sourceData, labels)
            } else if (isAreaDataSource(sourceData)) {
                this.initAreaChart(sourceData, labels)
            }
            /* Pending to implement candle and directionality */
        }
    }

    /* Init chart plot components */
    private initLineChart(
        chartData: IPlotModelLine,
        labels: TypePlotComponentLabels
    ) {
        const linearPlotDTO = new LinearPlotComponentDTO(
            chartData.id, // id
            labels.xLabelId, //xLabelId
            labels.xLabel, // xLabel
            chartData.id, // yLabelId
            dashboardVariables[chartData.variable].name, // yLabel
            chartData.points // Points
        )

        /* Think on a way to separate the config from the payload of the request */
        const lineChart = new LinePlotComponent(
            linearPlotDTO,
            {
                color: chartData.color,
                data_version: chartData.data_version,
                granularity: chartData.granularity,
                opacity: chartData.opacity,
                statistics: chartData.statistics,
                type: chartData.type,
                units: chartData.units,
                variable: getDashboardVariableEnumValue(chartData.variable),
                visualization: chartData.visualization,
            },
            {
                type: "linear",
                dot: false,
                fill: chartData.color,
                stroke: chartData.color,
                strokeWidth: 2,
                opacity: chartData.opacity,
            }
        )
        this.setChartComponentMap(lineChart)
        this.setComposedData(lineChart)
    }

    private initAreaChart(
        chartData: IPlotModelArea,
        labels: TypePlotComponentLabels
    ) {
        const bandChartDTO = new BandPlotComponentDTO(
            chartData.id, // id
            labels.xLabelId, //xLabelId
            labels.xLabel, // xLabel
            chartData.id, // yLabelId
            dashboardVariables[chartData.variable].name, // yLabel
            chartData.points // Points
        )

        /* Think on a way to separate the config from the payload of the request */
        const areaChart = new AreaPlotComponent(
            bandChartDTO,
            {
                color: chartData.color,
                data_version: chartData.data_version,
                granularity: chartData.granularity,
                opacity: chartData.opacity,
                statistics: chartData.statistics,
                type: chartData.type,
                units: chartData.units,
                variable: getDashboardVariableEnumValue(chartData.variable),
                visualization: chartData.visualization,
            },
            {
                fill: chartData.color,
                dot: false,
                activeDot: false,
                strokeWidth: 0,
                opacity: chartData.opacity,
            }
        )
        this.setChartComponentMap(areaChart)
        this.setComposedData(areaChart)
    }

    /* private initDirectionalityChart() {

    } */

    /* Helpers */
    private setChartComponentMap(
        chart: TypeChartPlotUnion<DataSourceMetaData>
    ) {
        /* Push Chart to map */
        const id = chart.yLabelId
        if (this.plotComponents.has(id)) {
            throw new Error(
                `ChartElements should not have repeated ids. Failed adding the id ${id} for map ${this.plotComponents}.`
            )
        }
        this.plotComponents.set(id, chart)
    }

    private setComposedData(chart: TypeChartPlotUnion<DataSourceMetaData>) {
        if (!areEqualValues<number>([chart.x.length, chart.y.length])) {
            throw new Error("The length for x[] and y[] are not the same.")
        }

        for (let i = 0; i < chart.x.length; i++) {
            if (!this.data[i]) {
                this.data[i] = {}
            }

            /* Write Y values */
            if (isAreaPlotComponent(chart)) {
                this.data[i][chart.yLabelId] = mergeDoubleYValues(
                    chart.y[i].y0,
                    chart.y[i].y1
                )
            } else if (isLinePlotComponent(chart)) {
                this.data[i][chart.yLabelId] = chart.y[i]
            } else if (isDirectionalityPlotComponent(chart)) {
                this.data[i][chart.yLabelId] = chart.y[i]
            }

            /* Write X values */
            if (!this.data[i][chart.xLabelId]) {
                /* Only first written values in the xLabelId will be recorded */
                this.data[i][chart.xLabelId] = chart.x[i]

                this.axisLabels.xLabel = chart.xLabel
                this.axisLabels.xLabelId = chart.xLabelId
            }
        }
    }
}
