import { useTranslate } from "@tolgee/react"
import { DateTime } from "luxon"
import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { useTimeline } from ".."
import {
  Button,
  ColorPickerButton,
  LabelAndInput,
  Table,
  TogglableRightSidePanel,
} from "../../../climateui/components"
import { GenericInput } from "../../../climateui/components/Inputs"
import { CancelIcon, TrashIcon } from "../../../climateui/icons"
import { formattedColorsMatrixForColorPicker } from "../../../climateui/utils/colors"
import { RiskProfilesContext } from "../../../providers/RiskProfilesProvider"
import {
  IRiskProfile,
  ITimelineRiskProfileStage,
  ITimelineRow,
  ITimelineStage,
  TIMELINE_EDITION_ACTIONS,
} from "../../../types"
import { daysBetween } from "../../../utils"
import { columns } from "../../SeasonalCalendar/components/editingStageTableUtils"
import StageRisksTableFilters from "../../SeasonalCalendar/components/StageRisksTableFilters"
import {
  getRiskProfileExistsOrNotSortingFunction,
  MIN_STAGE_DURATION,
  NEW_STAGE_DURATION,
} from "./utils"

function AddEditStage() {
  const { t } = useTranslate()
  const { riskProfilesObj } = useContext(RiskProfilesContext)
  const {
    editingStage,
    setEditingStage,
    rows,
    setRows,
    riskProfileOptsObj,
    riskProfileOpts,
  } = useTimeline()

  const [globalFilter, setGlobalFilter] = useState("")
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})
  const [columnFilters, setColumnFilters] = useState<
    { id: string; value: unknown }[]
  >([])

  const [modifiableStage, setModifiableStage] = useState(editingStage)

  let row: ITimelineRow = { id: "", data: {}, stages: [] }
  let isEditing = true
  let rowIndex = 0
  const dummyDate = new Date()
  let endDate = new Date()

  dummyDate.setDate(dummyDate.getDate() + NEW_STAGE_DURATION)

  if (editingStage && rows && modifiableStage) {
    rowIndex = rows.findIndex((row) => {
      return row.id === editingStage.row_id
    })
    if (rowIndex !== -1) {
      row = rows[rowIndex]
      isEditing = !editingStage.id.includes(editingStage.row_id)
    }

    endDate = new Date(modifiableStage.start_date)
    endDate.setDate(endDate.getDate() + modifiableStage.duration)
  }

  const sortedRiskProfilesToDisplay = useMemo<IRiskProfile[]>(() => {
    if (!editingStage) return []

    // RiskProfiles sorted based on:
    //     rowSelection
    //     existing or suggestion
    //     related or not to any stage
    const result: IRiskProfile[] = [...riskProfileOpts]

    // RiskProfiles related to any of the timeline stages
    const relatedRP: Record<string, boolean> = {}

    row.stages.forEach((stage) => {
      stage.riskProfileStages.forEach((riskProfileStage) => {
        const rpid = riskProfileStage.risk_profile_id
        relatedRP[rpid] = true
      })
    })

    const existsOrNotSort =
      getRiskProfileExistsOrNotSortingFunction(riskProfilesObj)

    result.sort((a, b) => {
      if (rowSelection[a.id] && rowSelection[b.id]) return existsOrNotSort(a, b)
      else if (rowSelection[a.id]) return -1
      else if (rowSelection[b.id]) return 1
      else if (relatedRP[a.id] && relatedRP[b.id]) return existsOrNotSort(a, b)
      else if (relatedRP[a.id]) return -1
      else if (relatedRP[b.id]) return 1

      return existsOrNotSort(a, b)
    })

    return result
  }, [riskProfileOpts, riskProfilesObj, rowSelection, row.stages])

  useEffect(() => {
    setModifiableStage(editingStage || undefined)
  }, [editingStage])

  useEffect(() => {
    const newRowSelection: Record<string, boolean> = {}
    modifiableStage?.riskProfileStages.forEach((riskProfileStage) => {
      newRowSelection[riskProfileStage.risk_profile_id] = true
    })
    setRowSelection((prevSelection) => {
      return {
        ...prevSelection,
        ...newRowSelection,
      }
    })
  }, [modifiableStage]) // Keep the selection updates separate from the stage modification.

  const cancelEditing = () => {
    setEditingStage(undefined)
    setGlobalFilter("")
    setColumnFilters([])
    setRowSelection({})
  }

  const isFormValid = () => {
    // TODO: More validation
    const newDuration = daysBetween(
      modifiableStage?.start_date ?? dummyDate,
      endDate ?? dummyDate,
    )
    return (
      modifiableStage?.name &&
      modifiableStage?.name !== "" &&
      newDuration > MIN_STAGE_DURATION
    )
  }

  const save = () => {
    if (!isFormValid() || !modifiableStage || !editingStage) return
    const newRiskProfileStages: ITimelineRiskProfileStage[] = []
    Object.keys(rowSelection).forEach((riskProfileId: string) => {
      newRiskProfileStages.push({
        id: riskProfileId, // The riskProfileStage real id can be ignored for now
        risk_profile_id: riskProfileId,
        row_id: row.id,
        risk_profile: riskProfileOptsObj[riskProfileId],
      })
    })
    const newStage: ITimelineStage = {
      ...modifiableStage,
      riskProfileStages: newRiskProfileStages,
      duration: daysBetween(
        modifiableStage.start_date ?? dummyDate,
        endDate ?? dummyDate,
      ),
      action: modifiableStage.action ?? TIMELINE_EDITION_ACTIONS.EDITED,
    }
    const newStages: ITimelineStage[] = [...(row.stages ?? [])]

    if (isEditing) {
      const index = newStages.findIndex((stage) => {
        return stage.id === editingStage.id
      })
      if (index !== -1) {
        newStages[index] = newStage
      }
    } else {
      newStages.push({ ...newStage, id: uuidv4() })
    }

    const newRow: ITimelineRow = {
      ...row,
      stages: newStages,
    }
    const newRows = [...(rows ?? [])]
    newRows[rowIndex] = newRow

    setRows?.(newRows)
    cancelEditing()
  }

  // days from start
  let daysFromStart = modifiableStage?.start_date
    ? daysBetween(new Date("1900-01-01"), modifiableStage.start_date) - 1
    : 0
  daysFromStart = daysFromStart < 0 ? 365 + daysFromStart : daysFromStart

  return (
    <TogglableRightSidePanel
      doShow={editingStage !== undefined}
      widthClass="w-[420px]"
      onCancel={cancelEditing}>
      {modifiableStage && (
        <div className="h-full w-full flex flex-col">
          {/* HEADER */}
          <div className="flex flex-row items-center justify-between px-4 pt-4 pb-1 grow-0 shrink-0">
            <div className="flex flex-col">
              <h3 className="title-sm text-light-text dark:text-dark-text">
                {isEditing
                  ? t("editStage", "Edit Stage")
                  : t("newStage", "New Stage")}
              </h3>
            </div>
            <div className="flex flex-row items-center gap-2">
              {isEditing && (
                <span
                  className="w-6 h-6 cursor-pointer fill-gray-60 hover:scale-110 hover:fill-red transition-all duration-75"
                  onClick={() => {
                    modifiableStage.deleteStage?.()
                    cancelEditing()
                  }}>
                  <TrashIcon />
                </span>
              )}
              <span
                className="w-6 h-6 cursor-pointer fill-gray-60 hover:scale-110 transition-all duration-75"
                onClick={cancelEditing}>
                <CancelIcon />
              </span>
            </div>
          </div>
          {/* STAGE INFO */}
          <div className="flex flex-col p-4 gap-3 grow-0 shrink-0">
            <LabelAndInput
              label={t("name", "Name")}
              input={
                <GenericInput
                  icon={
                    <ColorPickerButton
                      selectedColor={modifiableStage.color ?? ""}
                      setSelectedColor={(color: string) =>
                        setModifiableStage({
                          ...modifiableStage,
                          color,
                        })
                      }
                      colors={formattedColorsMatrixForColorPicker}
                      popupMarginClasses="mr-8 -mb-20"
                    />
                  }
                  name="name"
                  id="name"
                  placeholder={t("newStage")}
                  type="text"
                  value={modifiableStage.name}
                  handleChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setModifiableStage({
                      ...modifiableStage,
                      name: event.target.value,
                    })
                  }}
                />
              }
            />
            <div className="flex flex-row gap-2 relative">
              <LabelAndInput
                label={t("daysFromStart", "Days from start")}
                input={
                  <GenericInput
                    type="number"
                    min={0}
                    value={daysFromStart.toString()}
                    handleChange={(e) => {
                      let value = parseInt(e.target.value)
                      if (isNaN(value)) value = 0
                      setModifiableStage({
                        ...modifiableStage,
                        start_date: DateTime.fromJSDate(new Date("1900-01-01"))
                          .plus({ days: value + 1 })
                          .toJSDate(),
                      })
                    }}
                  />
                }
              />
              <LabelAndInput
                label={t("duration", "Duration (days)")}
                input={
                  <GenericInput
                    type="number"
                    min={MIN_STAGE_DURATION}
                    value={modifiableStage.duration}
                    handleChange={(e) => {
                      let value = parseInt(e.target.value)
                      if (isNaN(value)) value = 0
                      setModifiableStage({
                        ...modifiableStage,
                        duration: value,
                      })
                    }}
                  />
                }
              />
            </div>
          </div>
          {/* SEPARATOR */}
          <div className="w-full my-2 border-b border-gray-14 dark:border-gray-78 grow-0 shrink-0"></div>
          {/* RISKS */}
          <div className="flex flex-col p-4 gap-2 grow flex-1 min-h-0">
            <h6 className="label-lg shrink-0 grow-0">
              {t("addRiskProfiles", "Add Risk Profiles")}
            </h6>
            <StageRisksTableFilters
              setColumnFilters={setColumnFilters}
              setGlobalFilter={setGlobalFilter}
              columnFilters={columnFilters}
            />
            <div className="grow overflow-y-auto">
              <Table
                extraClasses="[&_th]:sticky [&_th]:top-0"
                data={sortedRiskProfilesToDisplay}
                columns={columns}
                getRowId={(riskProfile: IRiskProfile, index: number) =>
                  riskProfile.id || index.toString()
                }
                state={{
                  rowSelection,
                  globalFilter,
                  columnFilters,
                  hiddenColumns: ["labels", "variable", "name"],
                }}
                setRowSelection={setRowSelection}
                setGlobalFilter={setGlobalFilter}
                setColumnFilters={setColumnFilters}></Table>
            </div>
            <div className="grow-0 shrink-0">
              <Button
                onClick={save}
                label={t("save", "Save")}
                disabled={!isFormValid()}
                extend
              />
            </div>
          </div>
        </div>
      )}
    </TogglableRightSidePanel>
  )
}

export default AddEditStage
