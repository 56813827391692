import { useEffect, useState } from "react"

const Switch = ({
    checked = false,
    disabled = false,
    className = "",
    onChange = () => null,
    size = "sm",
}: {
    checked: boolean
    disabled?: boolean
    name?: string
    className?: string
    onChange: (checked?: boolean) => void | null
    size?: string
}) => {
    const [sizeClasses, setSizeClasses] = useState({
        dimensions: "w-[24px] h-[14px] p-[2px]",
        movingPadding: "pl-[12px]",
        innerWidth: "w-[10px]",
    })

    useEffect(() => {
        if (size === "lg") {
            setSizeClasses({
                dimensions: "w-[36px] h-[20px] p-[2px]",
                movingPadding: "pl-[18px]",
                innerWidth: "w-[16px]",
            })
        }
    }, [size])

    return (
        <div className={className}>
            <button
                className={[
                    "relative block border-box rounded-full transition-all duration-100 bg-accent border-accent overflow-hidden",
                    sizeClasses.dimensions,
                    checked ? sizeClasses.movingPadding : "bg-gray-30",
                    disabled
                        ? "opacity-50 cursor-not-allowed"
                        : "cursor-pointer",
                ].join(" ")}
                onClick={() => {
                    if (!disabled) {
                        onChange(!checked)
                    }
                }}>
                <div
                    className={[
                        sizeClasses.innerWidth,
                        "h-full rounded-full bg-light-bg dark:bg-dark-bg",
                    ].join(" ")}></div>
            </button>
        </div>
    )
}

export default Switch
