import { useTranslate } from "@tolgee/react"
import SearchWidget from "../../../../climateui/components/Widgets/SeasonalWidgets/SearchWidget"
import { IYieldStat } from "../../../../types"
import { getLargestTercile } from "../../../../utils/transform"
import IWidgetDefaultProps from "../../Dashboards/components/widgetLibrary/WidgetDefaultProps"

const isBelowNormal = (stat: IYieldStat) =>
  -1 === getLargestTercile(stat.tercile_probabilities)[0]

const BelowNormalLocationCountWidget = (
  props: {
    data?: IYieldStat[]
    loading: boolean
  } & IWidgetDefaultProps,
) => {
  const { t } = useTranslate()
  const { data } = props

  const belowNormalStats = data ? data.filter(isBelowNormal).length : 0
  return (
    <SearchWidget
      title={t(
        "locationsForcastedBelowNormal",
        "Locations Forecasted Below Normal",
      )}
      value={belowNormalStats}
      errorMessage={t("errorLoadingData")}
      reloadMessage={t("clickReload")}
      noResultsMessage={t("noResultCurrentFilters")}
      tryAnotherFilterMessage={t("tryAnotherFilter")}
      {...props}
    />
  )
}
export default BelowNormalLocationCountWidget
