import { useTranslate } from "@tolgee/react"
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import { Button } from "../../../climateui/components"
import EmptyModal from "../../../climateui/providers/Modal/EmptyModal"
import { useModal } from "../../../climateui/providers/Modal/ModalContextProvider"
import { isValidResponse } from "../../../climateui/utils/http"
import { StartDateInput } from "../../../components"
import { useIsFlagEnabled } from "../../../hooks"
import useSuggestedStagesAndRisksQuery from "../../../hooks/useSuggestedStagesAndRisksQuery"
import { useAssets, useLocations } from "../../../providers"
import {
  IAsset,
  IInsightsLocation,
  ISuggestedStageWithRisks,
  IVariety,
} from "../../../types"
import PageHeader from "../components/PageHeader"
import { IRiskProfileAndStage } from "../utils"
import TimelineStagesExampleSVG from "./TimelineStagesExampleSVG"

interface IAssetDependentStepHeader {
  pageTitle?: ReactNode
  paragraph?: string
  secondaryAction?: ReactNode
  primaryAction?: ReactNode
}

function useAssetDependentStep(
  assetName?: string,
  canContinue?: {
    riskProfiles: boolean
    locations: boolean
  },
  openStartDateModal?: () => void,
): IAssetDependentStepHeader {
  const { t } = useTranslate()
  const { confirmationModal } = useModal()
  const location = useLocation()
  const navigate = useNavigate()

  const value = useMemo(() => {
    const paths = location.pathname.split("/")
    const lastPath = paths[paths.length - 1]

    if (lastPath === "risk-profiles")
      return {
        pageTitle: (
          <h1>
            {t("riskProfilesFor", "Risk Profiles for")}{" "}
            <span className="text-accent">{assetName}</span>
          </h1>
        ),
        paragraph: t(
          "youCanEditRiskProfilesLater",
          "You will be able to edit/create custom risk profiles later in the admin settings",
        ),
        secondaryAction: (
          <Button
            label={t("back", "Back")}
            type="secondary"
            onClick={() => navigate("/onboarding")}
          />
        ),
        primaryAction: (
          <Button
            disabled={!canContinue?.riskProfiles}
            label={t("continue", "Continue")}
            onClick={openStartDateModal}
          />
        ),
      }
    else if (lastPath === "locations")
      return {
        pageTitle: (
          <h1>
            {t("locationsFor", "Locations for")}{" "}
            <span className="text-accent">{assetName}</span>
          </h1>
        ),
        paragraph: t(
          "youCanEditLocationsLater",
          "You will be able to edit/add locations later in the admin settings",
        ),
        secondaryAction: (
          <Button
            label={t("back", "Back")}
            type="secondary"
            onClick={() => navigate("risk-profiles")}
          />
        ),
        primaryAction: (
          <Button
            disabled={!canContinue?.locations}
            label={t("finishSetup", "Finish Setup")}
            onClick={() => {
              confirmationModal({
                title: t(
                  "finishSetupAndContinueToThePlatform",
                  "Finish setup and continue to the platform?",
                ),
                text: t(
                  "youCanAddLocationsAndAssetsLater",
                  "You will be able to add more locations and assets later in the admin settings.",
                ),
                onContinueLabel: t("finishSetup", "Finish Setup"),
                onCancelLabel: t("keepEditing", "Keep Editing"),
                onContinue() {
                  navigate("finish-setup")
                },
              })
            }}
          />
        ),
      }

    return {}
  }, [assetName, location, canContinue])

  return value
}

interface IAssetDependentSteps {
  asset?: IAsset
  assetNewLocations: IInsightsLocation[]
  setAssetNewLocations: React.Dispatch<
    React.SetStateAction<IInsightsLocation[]>
  >
  locationsCount: number
  canAddLocationsToOnboarding: boolean
  selectedExistingLocationsIds: string[]
  setSelectedExistingLocationsIds: React.Dispatch<
    React.SetStateAction<string[]>
  >
  riskProfilesPerVariable: Record<string, IRiskProfileAndStage[]>
  riskProfilesForDefaultVariety: IRiskProfileAndStage[]
  activeDefaultVariety?: IVariety
  isLoadingSuggestions: boolean

  workingRPPerVariable: Record<string, IRiskProfileAndStage[]>
  setWorkingRPPerVariable: React.Dispatch<
    Record<string, IRiskProfileAndStage[]>
  >
  isSetupProcessing: boolean
  setIsSetupProcessing: React.Dispatch<React.SetStateAction<boolean>>
  editingLocation?: IInsightsLocation
  setEditingLocation: React.Dispatch<
    React.SetStateAction<IInsightsLocation | undefined>
  >
  areGenericSuggestions: boolean
  initialDate?: Date
  suggestedStagesAndRiskProfiles: ISuggestedStageWithRisks[]
}

const AssetDependentStepsContext = createContext({} as IAssetDependentSteps)

export const useAssetDependentSteps = () =>
  useContext(AssetDependentStepsContext)

function OnboardingAssetDependentLayoutProvider() {
  const { t } = useTranslate()
  const { assetId } = useParams()
  const { allAssets: assets, caiDefaultVarieties } = useAssets()
  const { locations, canAddLocations } = useLocations()
  const { confirmationModal } = useModal()

  const [startDate, setStartDate] = useState<Date>()
  const [startDateModalOpen, setStartDateModalOpen] = useState(false)
  const canViewInitialDateInputModal = useIsFlagEnabled("feature_stage_manager")

  const activeDefaultVariety = useMemo(() => {
    if (!assetId) return undefined
    if (!caiDefaultVarieties) return undefined
    const assetDefaultVariety = Object.values(caiDefaultVarieties).find(
      (variety) => variety.asset_id === assetId,
    )
    return assetDefaultVariety // Variety or undefined
  }, [assetId, caiDefaultVarieties])

  // First suggestions API call for the selected asset default variety
  const {
    data: suggestionsData,
    isLoading,
    isFetching,
  } = useSuggestedStagesAndRisksQuery(activeDefaultVariety?.id)

  const requireGenericSuggestions =
    !isLoading &&
    !isFetching &&
    suggestionsData &&
    isValidResponse(suggestionsData) &&
    suggestionsData.data &&
    suggestionsData.data.stages.length === 0

  // Second suggestions API call for the 'generic' variety
  const { data: genericSuggestionsData, isLoading: genericIsLoading } =
    useSuggestedStagesAndRisksQuery(
      requireGenericSuggestions
        ? "generic" // this is the variety ID
        : undefined,
    )

  const [workingRPPerVariable, setWorkingRPPerVariable] = useState<
    Record<string, IRiskProfileAndStage[]>
  >({})

  const [editingLocation, setEditingLocation] = useState<IInsightsLocation>()

  const {
    riskProfilesPerVariable,
    areGenericSuggestions,
    suggestedStagesAndRiskProfiles,
  } = useMemo(() => {
    let areGenericSuggestions = false
    const riskProfilesPerVariable: Record<string, IRiskProfileAndStage[]> = {}

    if (isLoading || !suggestionsData || !isValidResponse(suggestionsData))
      return {
        riskProfilesPerVariable,
        areGenericSuggestions,
        suggestedStagesAndRiskProfiles: [],
      }

    let stages = suggestionsData.data.stages as ISuggestedStageWithRisks[]

    if (requireGenericSuggestions) {
      if (
        genericIsLoading ||
        !genericSuggestionsData ||
        !isValidResponse(genericSuggestionsData)
      )
        return {
          riskProfilesPerVariable,
          areGenericSuggestions,
          suggestedStagesAndRiskProfiles: [],
        }

      stages = genericSuggestionsData.data.stages as ISuggestedStageWithRisks[]
      areGenericSuggestions = true
    }
    stages.forEach((stage) => {
      stage.stage_risk_profiles.forEach((riskProfile) => {
        const currRiskProfile = riskProfile.risk_profile
        const hazardProfiles = currRiskProfile.hazard_profiles

        const variableKey =
          hazardProfiles.length === 1
            ? hazardProfiles[0].hazard_variable.readable_name
            : "Stacked Risks"

        if (!riskProfilesPerVariable[variableKey])
          riskProfilesPerVariable[variableKey] = []

        riskProfilesPerVariable[variableKey].push({
          riskProfile: {
            ...currRiskProfile,
          },
          stageName: stage.name,
        })
      })
    })

    return {
      riskProfilesPerVariable,
      areGenericSuggestions,
      suggestedStagesAndRiskProfiles: stages,
    }
  }, [suggestionsData, isLoading, genericSuggestionsData, genericIsLoading])

  const riskProfilesForDefaultVariety = useMemo(
    () =>
      Object.values(workingRPPerVariable).reduce((acc, curr) => {
        return [...acc, ...curr]
      }, []),
    [workingRPPerVariable],
  )

  useEffect(() => {
    setWorkingRPPerVariable({ ...riskProfilesPerVariable })
  }, [riskProfilesPerVariable])

  const navigate = useNavigate()

  const [assetNewLocations, setAssetNewLocations] = useState<
    IInsightsLocation[]
  >([])

  const [selectedExistingLocationsIds, setSelectedExistingLocationsIds] =
    useState<string[]>([])

  const [isSetupProcessing, setIsSetupProcessing] = useState(false)

  const locationsCount = useMemo(() => {
    return assetNewLocations.length + locations.length
  }, [assetNewLocations, locations])

  useEffect(() => {
    if (!assets || !assetId) return

    if (!assets[assetId]) navigate("/onboarding")
  }, [assetId, assets])

  const { pageTitle, paragraph, secondaryAction, primaryAction } =
    useAssetDependentStep(
      assets && assetId && assets[assetId] ? assets[assetId].name : "",
      {
        riskProfiles:
          !isLoading && Object.keys(riskProfilesPerVariable).length > 0,
        locations:
          assetNewLocations.length > 0 ||
          selectedExistingLocationsIds.length > 0,
      },
      canViewInitialDateInputModal
        ? () => setStartDateModalOpen(true)
        : () => navigate("locations"),
    )

  const providerValue = useMemo(() => {
    let asset = undefined
    if (assets && assetId && assets[assetId]) asset = assets[assetId]

    return {
      asset,
      assetNewLocations,
      setAssetNewLocations,
      locationsCount,
      // canAddLocationsToOnboarding: locationsCount < locationsMax && canAddLocations,
      canAddLocationsToOnboarding: canAddLocations,
      selectedExistingLocationsIds,
      setSelectedExistingLocationsIds,
      riskProfilesPerVariable,
      riskProfilesForDefaultVariety,
      activeDefaultVariety,
      isLoadingSuggestions: isLoading,
      workingRPPerVariable,
      setWorkingRPPerVariable,
      isSetupProcessing,
      setIsSetupProcessing,
      editingLocation,
      setEditingLocation,
      areGenericSuggestions,
      initialDate: startDate,
      suggestedStagesAndRiskProfiles,
    }
  }, [
    assets,
    assetId,
    assetNewLocations,
    locationsCount,
    selectedExistingLocationsIds,
    riskProfilesPerVariable,
    workingRPPerVariable,
    riskProfilesForDefaultVariety,
    activeDefaultVariety,
    isSetupProcessing,
    editingLocation,
    areGenericSuggestions,
    startDate,
    suggestedStagesAndRiskProfiles,
  ])

  return (
    <AssetDependentStepsContext.Provider value={providerValue}>
      <div className="h-full grow flex flex-col">
        <div className="px-6 shrink-0">
          <PageHeader
            title={pageTitle}
            paragraph={paragraph}
            secondaryAction={secondaryAction}
            primaryAction={primaryAction}
          />
        </div>
        <div className="grow overflow-hidden">
          <Outlet />
        </div>
      </div>
      <EmptyModal
        open={startDateModalOpen}
        customClasses="w-[456px] h-[354px]">
        <div className="p-4 flex flex-col h-full gap-2.5">
          <h2 className="title-lg">
            {t(
              "whenDoesYouOperationsStartForASSET",
              "When does your annual operations start for {asset}? ",
              {
                asset:
                  assets?.[assetId ?? ""].name ?? t("yourAsset", "your asset"),
              },
            )}
          </h2>

          <p className="body-md text-gray-60 dark:text-gray-30 grow">
            {t(
              "startDateForASSETInstructions",
              "Select a start day (i.e. planting day, season start, etc.) to set up risk profiles and stages for your {asset} locations.",
              {
                asset: assets?.[assetId ?? ""].name ?? t("asset", "asset"),
              },
            )}
          </p>

          <div className="flex flex-row items-center gap-3">
            <StartDateInput
              date={startDate}
              setDate={setStartDate}
              isYearAgnostic
              isSmall
              customPlaceholder={t("selectStartDate", "Select start date")}
              canClearInput
            />

            <p className="body-sm text-gray-60 dark:text-gray-30">
              {t("notSureDownloadOut", "Not sure? Download our")}{" "}
              <a
                className="link"
                target="_blank"
                rel="noreferrer"
                href="https://climate-ai-platform-prod-storage.s3.us-east-2.amazonaws.com/ClimateAi-Variety-StartDay-Guide.pdf">
                {t("guide", "guide")}
              </a>
            </p>
          </div>

          <TimelineStagesExampleSVG />

          <div className="flex flex-row items-center justify-between">
            <Button
              onClick={() => {
                confirmationModal({
                  title: t(
                    "areYouSureYouWantToSkip",
                    "Are you sure you want to skip?",
                  ),
                  text: t(
                    "weatherAlertsWillNotBeTriggeredStartDay",
                    "Weather alerts will not be triggered for this asset until a start day is configured later in the asset tab.",
                  ),
                  onContinueLabel: t("yesSkip", "Yes Skip"),
                  onCancelLabel: t("back", "Back"),
                  onContinue() {
                    setStartDateModalOpen(false)
                    setStartDate(undefined)
                    navigate("locations")
                  },
                })
              }}
              extraClasses="underline"
              label={t("skip", "Skip")}
              type="tertiary"
            />
            <div className="flex flex-row items-center gap-2">
              <Button
                label={t("back", "Back")}
                type="secondary"
                onClick={() => {
                  setStartDateModalOpen(false)
                  setStartDate(undefined)
                }}
              />
              <Button
                disabled={!startDate}
                label={t("continue", "Continue")}
                onClick={() => {
                  setStartDateModalOpen(false)
                  navigate("locations")
                }}
              />
            </div>
          </div>
        </div>
      </EmptyModal>
    </AssetDependentStepsContext.Provider>
  )
}

export default OnboardingAssetDependentLayoutProvider
