import { Transition } from "@headlessui/react"
import { useTranslate } from "@tolgee/react"
import { Fragment, ReactNode, useCallback, useMemo, useState } from "react"
import { useTimeline } from ".."
import {
  DraggableElement,
  PortalComponent,
  Tooltip,
} from "../../../climateui/components"
import { useOutsideComponentClickHandler } from "../../../climateui/hooks"
import { ThreeDotsIcon } from "../../../climateui/icons"
import { ITimelineStage, TIMELINE_EDITION_ACTIONS } from "../../../types"
import { daysBetween } from "../../../utils"
import { isValidDate } from "../../../views/Seasonal/PlanningTool/utils"
import DraggableItem from "./DraggableItem"
import StageColorBoxes from "./StageColorBoxes"
import TimelineRiskProfileStage from "./TimelineRiskProfileStage"
import { useTimelineRow } from "./TimelineRow"
import {
  closerToStartOrEndOfYear,
  isStageOverflowingYear,
  MIN_STAGE_DURATION,
  moveDragAuxDate,
  moveStartDate,
} from "./utils"

const STAGE_POPUP_LEFT_MIN_PX = 70

function StageOptions({
  stage,
  daysFromTimelineStart,
  setPxFromTimelineStart,
  setDuration,
  duration,
  updateStage,
  deleteStage,
  doShowOptions = false,
}: {
  stage: ITimelineStage
  daysFromTimelineStart: number
  setPxFromTimelineStart: (px: number) => void
  setDuration: (duration: number) => void
  duration: number
  updateStage: (stage: Partial<ITimelineStage>) => void
  deleteStage: () => void
  doShowOptions?: boolean
}) {
  const {
    timelineStartDate,
    pxPerDay,
    setDragAuxDate,
    dragAuxDate,
    setEditingStage,
  } = useTimeline()
  const stageDuration = stage.duration ?? 0
  const [optionsOpen, setOptionsOpen] = useState<boolean>(false)
  const optionsRef = useOutsideComponentClickHandler(() =>
    setOptionsOpen(false),
  )

  const { t } = useTranslate()

  const popupPositionClass =
    daysBetween(timelineStartDate, stage.start_date || new Date()) * pxPerDay >
    STAGE_POPUP_LEFT_MIN_PX
      ? "right-0"
      : "left-0"

  if (!doShowOptions)
    return (
      <div className="relative w-6 h-6 overflow-visible cursor-default shrink-0"></div>
    )

  return (
    <>
      <div className="relative w-6 h-6 overflow-visible cursor-default shrink-0">
        <span
          className="w-6 h-6 cursor-pointer hover:opacity-75 transition-all duration-75"
          onClick={() => {
            setOptionsOpen(true)
          }}>
          <ThreeDotsIcon />
        </span>

        <div ref={optionsRef}>
          <Transition
            show={optionsOpen}
            as={Fragment}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-75"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div
                className={[
                  "absolute z-40 overflow-hidden",
                  popupPositionClass,
                  "flex flex-col items-stretch",
                  "py-2 w-fit elevation-2",
                  "text-left body-md text-light-text dark:text-dark-text",
                  "bg-light-bg dark:bg-dark-bg border rounded-lg border-gray-14 dark:border-gray-78",
                ].join(" ")}>
                <button
                  className="px-2 py-1 cursor-pointer whitespace-nowrap hover:bg-gray-3 dark:bg-gray-90 dark:hover:bg-gray-88"
                  onClick={() => {
                    setEditingStage({
                      ...stage,
                      deleteStage,
                    })
                    setOptionsOpen(false)
                  }}>
                  {t("editStage", "Edit Stage")}
                </button>
                <button
                  className="px-2 py-1 cursor-pointer whitespace-nowrap hover:bg-gray-3 dark:bg-gray-90 dark:hover:bg-gray-88"
                  onClick={() => {
                    deleteStage()
                    setOptionsOpen(false)
                  }}>
                  {t("deleteStage", "Delete Stage")}
                </button>
              </div>
            </Transition.Child>
          </Transition>
        </div>
      </div>
      <DraggableElement
        draggable
        draggingCallback={(pxMoved: number) => {
          const daysMoved = Math.round(pxMoved / pxPerDay)
          if (stageDuration - daysMoved < MIN_STAGE_DURATION) return
          if (stageDuration - daysMoved >= 365) return

          const stageStartDate = new Date(stage.start_date ?? "")
          if (!isValidDate(stageStartDate)) return

          const movedDate = new Date(stageStartDate)
          movedDate.setDate(movedDate.getDate() + daysMoved)
          if (daysBetween(timelineStartDate, movedDate) < 0) return

          moveStartDate(
            daysFromTimelineStart,
            daysMoved,
            null,
            pxPerDay,
            setPxFromTimelineStart,
          )
          moveDragAuxDate(
            stageStartDate.toISOString(),
            timelineStartDate,
            daysMoved,
            null,
            setDragAuxDate,
          )
          setDuration(stageDuration - daysMoved)
        }}
        dragEndCallback={() => {
          updateStage({
            ...stage,
            start_date: dragAuxDate ?? timelineStartDate,
            duration,
          })
          setDragAuxDate(undefined)
        }}>
        <span
          className="absolute w-2 h-2 rounded-full cursor-col-resize -left-1"
          style={{
            backgroundColor: stage.color,
          }}></span>
      </DraggableElement>
      <DraggableElement
        draggable
        draggingCallback={(pxMoved: number) => {
          const daysMoved = Math.round(pxMoved / pxPerDay)
          if (stageDuration + daysMoved < MIN_STAGE_DURATION) return
          if (stageDuration + daysMoved >= 365) return

          const stageDate = new Date(stage.start_date ?? "")
          stageDate.setDate(stageDate.getDate() + stageDuration + daysMoved)
          setDragAuxDate(stageDate)
          setDuration(stageDuration + daysMoved)
        }}
        dragEndCallback={() => {
          updateStage({
            ...stage,
            duration,
          })
          setDragAuxDate(undefined)
        }}>
        <span
          className="absolute w-2 h-2 rounded-full cursor-col-resize -right-1"
          style={{
            backgroundColor: stage.color,
          }}></span>
      </DraggableElement>
    </>
  )
}

function UIStage({
  stage,
  updateStage,
  deleteStage,
  level,
  focusedClass,
  extraComponent,
}: {
  stage: ITimelineStage
  updateStage: (newStage: Partial<ITimelineStage>) => void
  deleteStage: () => void
  level: number
  focusedClass: string
  extraComponent?: ReactNode
}) {
  const {
    focusedStage,
    setFocusedStage,
    isEditing,
    setEditingStage,
    timelineStartDate,
  } = useTimeline()
  const { rowLeft, row, stageHeight } = useTimelineRow()

  const isFocused = stage.id === focusedStage
  const allFocused = focusedStage === stage.row_id

  let showTextRight = false
  if (stage.start_date && stage.start_date < timelineStartDate)
    showTextRight = true

  let tooltipPosition = "right"
  if (closerToStartOrEndOfYear(timelineStartDate, stage) > 0) {
    tooltipPosition = "left"
  }

  return (
    <DraggableItem
      item={stage}
      updateItem={updateStage}
      isFocused={isFocused}
      globalCalendarLeft={rowLeft}
      renderElement={({
        cursorClass,
        mainStyle,
        daysFromTimelineStart,
        duration,
        setDuration,
        setPxFromTimelineStart,
      }) => {
        return (
          <>
            <div
              className="absolute flex items-center justify-center"
              style={{
                ...mainStyle,
                height: stageHeight + "px",
                top: stageHeight * level + "px",
              }}>
              <Tooltip
                doShow
                customStyle="absolute flex items-center justify-center group transition-all duration-200 inset-0"
                content={stage.name ?? ""}
                position={tooltipPosition}
                wrapperClass="">
                <div
                  className={[
                    "absolute inset-0",
                    "flex flex-row items-center justify-between",
                    "rounded-sm pl-2 group body-md",
                    "transition-all duration-75",
                    "z-30",
                    isEditing ? cursorClass + " " + focusedClass : "z-30",
                  ].join(" ")}
                  onMouseEnter={() => {
                    if (isEditing) {
                      setFocusedStage(stage.id ?? "")
                    }
                  }}
                  onDoubleClick={() => {
                    setEditingStage({
                      ...stage,
                      deleteStage,
                    })
                  }}
                  style={{
                    color: stage.color,
                    fill: stage.color,
                  }}>
                  <div
                    className={[
                      "truncate grow",
                      "whitespace-nowrap",
                      showTextRight ? "text-right" : "text-left",
                    ].join(" ")}>
                    {stage.name}
                  </div>
                  {/* INFO: For background and border colors (Pure UI) */}
                  <StageColorBoxes
                    isFocused={(isFocused || allFocused) && isEditing}
                    color={stage.color ?? ""}
                  />
                  <StageOptions
                    doShowOptions={isFocused && isEditing}
                    stage={stage}
                    daysFromTimelineStart={daysFromTimelineStart}
                    setPxFromTimelineStart={setPxFromTimelineStart}
                    setDuration={setDuration}
                    duration={duration}
                    updateStage={updateStage}
                    deleteStage={deleteStage}
                  />

                  {extraComponent}
                </div>
              </Tooltip>
            </div>
            {stage.riskProfileStages.map((riskProfileStage) => {
              return (
                <PortalComponent
                  key={riskProfileStage.id}
                  portalId={
                    "row-" +
                    row.id +
                    "-risk-" +
                    riskProfileStage.risk_profile_id
                  }>
                  <TimelineRiskProfileStage
                    stage={stage}
                    mainStyle={mainStyle}
                  />
                </PortalComponent>
              )
            })}
          </>
        )
      }}
    />
  )
}

function TimelineStage({
  stage,
  index,
  level,
  extraComponent = undefined,
}: {
  stage: ITimelineStage
  index: number
  level: number
  extraComponent?: ReactNode
}) {
  const { focusedStage, timelineStartDate } = useTimeline()
  const { updateRow, row } = useTimelineRow()

  const isFocused = stage.id === focusedStage
  const allFocused = focusedStage === stage.row_id

  const focusedClass = isFocused || allFocused ? "z-40" : "cursor-pointer"

  const updateStage = useCallback(
    (newStage: Partial<ITimelineStage>) => {
      const newStages = [...row.stages]
      newStages[index] = { ...stage, ...newStage }
      newStages[index].action =
        newStages[index].action ?? TIMELINE_EDITION_ACTIONS.EDITED
      updateRow({ stages: newStages })
    },
    [index, row, stage],
  )

  const deleteStage = useCallback(() => {
    const newStages = [...row.stages]
    newStages.splice(index, 1)
    updateRow({ stages: newStages })
  }, [index, row, stage])

  const { extraUIStage, updateExtraStage } = useMemo(() => {
    let extraUIStage: ITimelineStage | undefined

    const stageOverflowingDirection = isStageOverflowingYear(
      timelineStartDate,
      stage,
    )

    if (stageOverflowingDirection !== 0) {
      const newStageStartDate = new Date(stage.start_date)
      newStageStartDate.setFullYear(
        newStageStartDate.getFullYear() - stageOverflowingDirection,
      )
      extraUIStage = {
        ...stage,
        start_date: newStageStartDate,
        wrappingCount: stage.wrappingCount
          ? stage.wrappingCount - stageOverflowingDirection
          : -stageOverflowingDirection,
      }
      if (!stage.isOverflowing)
        updateStage({
          isOverflowing: true,
        })
    } else if (stage.isOverflowing)
      updateStage({
        isOverflowing: false,
      })

    return {
      extraUIStage,
      updateExtraStage: (newStage: Partial<ITimelineStage>) => {
        let newStartDate = new Date()
        if (newStage.start_date) {
          newStartDate = new Date(newStage.start_date)
          newStartDate.setDate(newStartDate.getDate() + 1)
        }
        updateStage({ ...newStage, start_date: newStartDate })
      },
    }
  }, [stage, timelineStartDate])

  return (
    <>
      <UIStage
        stage={stage}
        updateStage={updateStage}
        deleteStage={deleteStage}
        level={level}
        focusedClass={focusedClass}
        extraComponent={extraComponent}
      />
      {extraUIStage && (
        <UIStage
          stage={extraUIStage}
          updateStage={updateExtraStage}
          deleteStage={deleteStage}
          level={level}
          focusedClass={focusedClass}
          extraComponent={extraComponent}
        />
      )}
    </>
  )
}

export default TimelineStage
