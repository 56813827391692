import {
    compareItems,
    RankingInfo,
    rankItem,
} from "@tanstack/match-sorter-utils"
import { Header, Row, sortingFns } from "@tanstack/react-table"
import { ReactNode, useContext, useState } from "react"
import { useOutsideComponentClickHandler } from "../../hooks"
import { ThreeDotsIcon } from "../../icons"
import { ToastContext } from "../../providers"
import Checkbox from "../Checkbox"
import { FixedPosition } from "../FixedElement"
import TooltipV2 from "../TooltipV2"

export interface TableActionInteface {
    disabled?: boolean
    onClick: () => void
    label?: string
    tooltip?: string
    icon?: ReactNode
}

const TableAction = ({
    tooltip,
    showTooltip,
    icon,
    children,
    onClick = () => null,
    disabled = false,
}: {
    tooltip?: string
    showTooltip?: boolean
    icon: ReactNode
    children?: ReactNode | ReactNode[]
    onClick?: () => void
    disabled?: boolean
}) => {
    return (
        <div className="relative shrink-0">
            <TooltipV2
                contentContainerClass="translate-x-6"
                content={tooltip as string}
                position="left"
                doShow={showTooltip && !disabled}>
                <button
                    disabled={disabled}
                    className={[
                        "relative h-6 w-6",
                        "enabled:hover:fill-gray-90 enabled:hover:scale-[1.08]",
                        "transition-all duration-100",
                        "disabled:fill-gray-30 disabled:text-gray-30 disabled:cursor-not-allowed",
                    ].join(" ")}
                    onClick={onClick}>
                    {icon && icon}
                </button>
            </TooltipV2>
            {children}
        </div>
    )
}

export const LinkCell = ({
    text,
    onClick = () => null,
}: {
    text: string
    onClick: () => null
}) => {
    return (
        <button
            className="underline cursor-pointer hover:font-bold whitespace-nowrap"
            onClick={onClick}>
            {text}
        </button>
    )
}

export const TruncatedToCopyCell = ({
    value,
    copySuccessMessage,
    maxWidthClass,
    tooltipPosition = "right",
}: {
    value?: string
    copySuccessMessage: string
    maxWidthClass: string
    tooltipPosition?: FixedPosition
}) => {
    const { enqueueAlert } = useContext(ToastContext)

    return (
        <TooltipV2
            position={tooltipPosition}
            content={value || ""}>
            <div
                className={
                    "whitespace-nowrap w-full truncate cursor-pointer " +
                    maxWidthClass
                }
                onClick={() => {
                    navigator.clipboard.writeText(value || "")
                    enqueueAlert(copySuccessMessage)
                }}>
                {value}
            </div>
        </TooltipV2>
    )
}

function MoreActions<T>({
    rowData,
    actions,
}: {
    rowData: T
    actions: TableActionInteface[]
}) {
    const [open, toggle] = useState(false)
    const containerRef = useOutsideComponentClickHandler(
        () => open && toggle(false)
    )

    return (
        <TableAction
            tooltip="More"
            showTooltip={!open}
            icon={<ThreeDotsIcon />}
            onClick={() => !open && toggle(!open)}>
            {open && (
                <div
                    ref={containerRef}
                    className="absolute right-0 z-10 py-1 bg-light-bg dark:bg-dark-bg border rounded-lg w-44 elevation-2 border-gray-14 dark:border-gray-78 label-lg">
                    {actions.map((action, index) => (
                        <button
                            key={
                                "table-more-action-" +
                                (rowData as any).id +
                                "-" +
                                index
                            }
                            className="w-full px-2 py-1 text-left enabled:hover:bg-gray-5 dark:hover:bg-gray-78 disabled:text-gray-30 disabled:cursor-not-allowed"
                            disabled={action.disabled}
                            onClick={() => {
                                toggle(false)
                                action.onClick()
                            }}>
                            {action.label}
                        </button>
                    ))}
                </div>
            )}
        </TableAction>
    )
}

export function TableActions<T>({
    rowData,
    actions,
    moreActions = [],
    justifyCenter = false,
}: {
    rowData: T
    actions: TableActionInteface[]
    moreActions?: TableActionInteface[]
    justifyCenter?: boolean
}) {
    return (
        <div
            className={[
                "flex flex-row items-center gap-2 fill-gray-60",
                justifyCenter ? "justify-center w-full" : "w-max",
            ].join(" ")}>
            {actions.map((action, index) => (
                <TableAction
                    key={"table-action-" + (rowData as any).id + "-" + index}
                    showTooltip={!!action.tooltip}
                    tooltip={action.tooltip as string}
                    disabled={action.disabled}
                    icon={action.icon as ReactNode}
                    onClick={action.onClick}
                />
            ))}
            {moreActions.length !== 0 && (
                <MoreActions
                    rowData={rowData}
                    actions={moreActions}
                />
            )}
        </div>
    )
}

export function CheckboxCell<T>({
    rowData,
    status,
    onChange = (newStatus) => null,
    isGlobal = false,
    disabled,
    isDisabled,
}: {
    rowData?: T | null
    status: string
    onChange?: (newStatus: string | { target: { checked: boolean } }) => void
    isGlobal?: boolean
    disabled?: boolean
    isDisabled?: (rowData?: T | null) => boolean
}) {
    return (
        <div className="grid place-content-center">
            <span className="w-6">
                <Checkbox
                    status={status}
                    disabled={isDisabled ? isDisabled(rowData) : disabled}
                    onChange={(newStatus) => {
                        if (!isGlobal) onChange(newStatus)
                        else {
                            const dummyEvent = {
                                target: { checked: newStatus === "full" },
                            }
                            onChange(dummyEvent)
                        }
                    }}
                />
            </span>
        </div>
    )
}

export function fuzzyFilter<T>(
    row: Row<T>,
    columnId: string,
    value: string,
    addMeta: (rank: object) => void
) {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
        itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
}

export function fuzzySort<T>(rowA: Row<T>, rowB: Row<T>, columnId: string) {
    let dir = 0

    // Only sort by rank if the column has ranking information
    if (rowA.columnFiltersMeta[columnId]) {
        dir = compareItems(
            rowA.columnFiltersMeta[columnId]! as RankingInfo,
            rowB.columnFiltersMeta[columnId]! as RankingInfo
        )
    }

    // Provide an alphanumeric fallback for when the item ranks are equal
    return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
}

/** Checks whether the subheaders for a given have an undefined or empty header
 * string value
 */
export const subHeadersAreEmpty = <T = unknown,>(
    header: Header<T, unknown>
): boolean => {
    if (header.subHeaders.length === 0) return true
    return header.subHeaders.every((subHeader) => {
        const headerComponent = subHeader.column.columnDef.header
        if (!!headerComponent) return false
        return subHeadersAreEmpty(subHeader)
    })
}

/** Retrieves the maximum depth for a given header
 */
export const getMaxHeaderDepth = <T = unknown,>(
    header: Header<T, unknown>
): number => {
    if (header.subHeaders.length === 0) return 0
    return header.subHeaders.reduce(
        (subHeaderMaxDepth, subHeader) =>
            subHeaderMaxDepth + getMaxHeaderDepth(subHeader),
        1
    )
}
