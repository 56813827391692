import { useState, useEffect } from "react"
import { throttle } from "lodash"

interface ThresholdConfig {
  width: number
  height: number
}

interface ThresholdState {
  top: boolean
  right: boolean
  bottom: boolean
  left: boolean
}

// Singleton manager for mouse position tracking
class MouseThresholdManager {
  // eslint-disable-next-line
  private static instance: MouseThresholdManager
  private readonly listeners: Set<(mouseX: number, mouseY: number) => void> =
    new Set()
  private mouseX = 0
  private mouseY = 0

  private constructor() {
    const throttledMouseMove = throttle((event: MouseEvent) => {
      this.mouseX = event.clientX
      this.mouseY = event.clientY
      this.notifyListeners()
    }, 100)

    window.addEventListener("mousemove", throttledMouseMove)
  }

  public subscribe(callback: (mouseX: number, mouseY: number) => void) {
    this.listeners.add(callback)
    // Initial call with current position
    callback(this.mouseX, this.mouseY)

    return () => {
      this.listeners.delete(callback)
    }
  }

  private notifyListeners() {
    this.listeners.forEach((listener) => listener(this.mouseX, this.mouseY))
  }

  public static getInstance(): MouseThresholdManager {
    if (!this.instance) {
      this.instance = new MouseThresholdManager()
    }
    return this.instance
  }
}

export function useChartMouseThreshold({ width, height }: ThresholdConfig) {
  const [thresholds, setThresholds] = useState<ThresholdState>({
    top: false,
    right: false,
    bottom: false,
    left: false,
  })
  useEffect(() => {
    const updateThresholds = (mouseX: number, mouseY: number) => {
      const newState = {
        top: mouseY - height < 0,
        right: window.innerWidth - width < mouseX,
        bottom: mouseY + height > window.innerHeight,
        left: mouseX - width < 0,
      }

      // Only call setThresholds if any threshold value has changed
      setThresholds((prev) => {
        const hasChanges = Object.entries(newState).some(
          ([key, value]) => prev[key as keyof ThresholdState] !== value,
        )
        return hasChanges ? newState : prev
      })
    }

    const unsubscribe =
      MouseThresholdManager.getInstance().subscribe(updateThresholds)
    return () => {
      unsubscribe()
    }
  }, [width, height])

  return thresholds
}
