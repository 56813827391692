import { ReactNode } from "react"

function DashboardView({ children }: { children: ReactNode }) {
  return (
    <div className="break-inside-auto flex-col items-center p-8 bg-gray-1.5 dark:bg-gray-88">
      {children}
    </div>
  )
}

export default DashboardView
