import { useTranslate } from "@tolgee/react"
import SearchWidget from "../../../../../climateui/components/Widgets/SeasonalWidgets/SearchWidget"
import { getLargestTercile } from "../../../../../utils/transform"
import IWidgetDefaultProps from "../../../../Seasonal/Dashboards/components/widgetLibrary/WidgetDefaultProps"
import { IYieldStat } from "../../../../../types"

const formatter = Intl.NumberFormat(undefined, {
  style: "percent",
  minimumFractionDigits: 0,
})
const getDirectionalityDescription = (directionality?: number) => {
  switch (directionality) {
    case -1:
      return "Below Normal"
    case 0:
      return "Within Normal"
    case 1:
      return "Above Normal"
    default:
      return "_"
  }
}

function CountryYieldOutlookWidget(
  props: {
    title?: string
    loading?: boolean
    data: { results: IYieldStat[] }
  } & IWidgetDefaultProps,
) {
  const { title, loading, data } = props
  const { t } = useTranslate()

  const [direction, _probability] = getLargestTercile(
    data?.results?.[0]?.tercile_probabilities,
  )
  const directionality = getDirectionalityDescription(direction)
  const iconDirection = direction ? direction.toString() : ""
  const probability = _probability
    ? `${formatter.format(_probability)} ${t("probability").toLowerCase()}`
    : ""

  return (
    <SearchWidget
      title={title}
      value={directionality}
      icon={iconDirection}
      loading={loading}
      description={probability}
      errorMessage={t("errorLoadingData")}
      reloadMessage={t("clickReload")}
      noResultsMessage={t("noResultCurrentFilters")}
      tryAnotherFilterMessage={t("tryAnotherFilter")}
      {...props}
    />
  )
}

export default CountryYieldOutlookWidget
