import { toCamelCase } from "../utils/wordHelper"

export function applyStyles(skin) {
  if (!skin || Object.keys(skin).length === 0)
    throw new TypeError("Style config is empty.")

  // styles for usage inside JS
  window.styles = {}
  for (let key in skin) {
    // apply value to style document
    document.documentElement.style.setProperty(`--${key}`, skin[key])
    window.styles[toCamelCase(key)] = skin[key]
  }
}

export function applyPageSettings(page) {
  if (!page || !Object.keys(page).length > 0)
    throw new TypeError("Page config is empty.")

  for (let key in page) {
    // select element
    const element = document.querySelector(key)
    if (!element) continue

    for (let attr in page[key]) {
      // change attrs accordingly
      element[attr] = page[key][attr]
    }
  }
}

export function applyDashboardSettings(dashboard) {
  if (!dashboard) throw new TypeError("Dashboard config is empty.")

  // transformations do not apply
  window.dashboard = dashboard
}
