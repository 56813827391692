import { useTranslate } from "@tolgee/react"
import React from "react"
import LoadingAnimation from "../../../../climateui/components/LoadingAnimation"

interface IAlertsCardProps {
  count: number | undefined
  alertType: string
  fillColor: string
}

const alertCategoryDescriptions: Record<string, string> = {
  warning: "warningAlertDescription",
  watch: "watchAlertDescription",
}

const LoadingState = () => {
  const { t } = useTranslate()

  return (
    <>
      <div className="flex items-center text-left body-md font-medium text-light-text dark:text-dark-text">
        <div className="-ml-[2px] body-md">
          <LoadingAnimation
            size={12}
            thickness={6}
          />
        </div>
      </div>
      <div className="ml-4 text-left body-sm font-light text-gray-60 dark:text-gray-30">
        {t("calculatingAlertsinLocs")}
      </div>
    </>
  )
}

const AlertsOrNoAlertsState = ({
  alertType,
  count,
  fillColor,
}: {
  alertType: string
  count: number
  fillColor: string
}) => {
  const { t } = useTranslate()

  return (
    <>
      <div className="flex items-center text-left body-md font-medium text-light-text dark:text-dark-text">
        {count !== 0 ? (
          <>
            <svg
              className="mr-2"
              width="9"
              height="9"
              viewBox="0 0 6 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle
                cx="3"
                cy="3"
                r="3"
                fill={fillColor}
              />
            </svg>
            <span>
              {t(alertType)} ({count})
            </span>
          </>
        ) : (
          <span className="text-green">
            {t("noCategoryAlerts", { category: t(alertType) })}
          </span>
        )}
      </div>
      <div
        className="ml-4 text-left body-sm text-gray-60 dark:text-gray-30"
        style={{ marginLeft: count !== 0 ? "16px" : "0px" }}>
        {count !== 0
          ? t(alertCategoryDescriptions[alertType])
          : t("zeroAlertsDetected")}
      </div>
    </>
  )
}

const AlertsCard: React.FC<IAlertsCardProps> = ({
  count,
  alertType,
  fillColor,
}) => {
  // TODO - discuss with Gerry if widths will vary depending on state
  // const width = alertType === "watch" ? "234px" : "195px"

  return (
    <div
      // style={{ width: width }}
      className="pt-2 w-[210px] rounded-md border bg-light-bg dark:bg-dark-bg p-3 box-border flex flex-col justify-between border-gray-14 dark:border-gray-78 ml-3">
      {count === undefined ? (
        <LoadingState />
      ) : (
        <AlertsOrNoAlertsState
          alertType={alertType}
          fillColor={fillColor}
          count={count}
        />
      )}
    </div>
  )
}

export default AlertsCard
