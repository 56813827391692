import { Row } from "@tanstack/react-table"
import { useTranslate } from "@tolgee/react"
import Color from "color"
import { DateTime } from "luxon"
import { useNavigate } from "react-router-dom"
import YieldIcon from "../../../climateui/icons/variableIcons/YieldIcon"
import getColorAndSymbol from "../../../climateui/utils/icons/getColorAndSymbol"
import { Translate } from "../../../components"
import {
  getDirectionalityDescription,
  getDirectionalityHighlightColor,
} from "../../../components/YieldOutlook/YieldOutlookTableWidget"
import { useUI } from "../../../providers/UIProvider"
import { getLargestTercile } from "../../../utils/transform"
import {
  IYieldCountryOverviewModel,
  IYieldOverview,
} from "./components/YieldOverview"
import { useYieldOutlook } from "./provider"

const OUT_OF_SEASON = "out_of_season"
const MONDAY_INDEX = 1
const formatter = Intl.NumberFormat(undefined, {
  style: "percent",
  maximumFractionDigits: 0,
})

const YieldCountryDescription = ({
  yieldModel,
  isOutOfSeason,
  dataError,
  translateFn,
  directionalityBehavior,
  currentSeason,
}: {
  yieldModel: IYieldCountryOverviewModel
  isOutOfSeason: boolean
  dataError: boolean
  translateFn: (key: string, fallback?: string) => string
  directionalityBehavior: number | undefined
  currentSeason: string
}) => {
  let yieldDescriptionString = ""
  const endOfStageDateStr = yieldModel?.stages?.[0]?.end_date as string
  if (isOutOfSeason && !!endOfStageDateStr) {
    const outOfSeasonEndDate = DateTime.fromFormat(
      endOfStageDateStr,
      "MM-dd",
    ).set({ year: DateTime.now().year })
    let inSeasonStartDate = outOfSeasonEndDate.plus({
      days: 1,
    })
    // Because we'll be uploading new data every monday in the case that
    // asset seasons start mid week we will shift the season start to the
    // next closest Monday
    if (inSeasonStartDate.weekday !== MONDAY_INDEX) {
      inSeasonStartDate = inSeasonStartDate
        .plus({
          weeks: 1,
        })
        .startOf("week")
    }
    yieldDescriptionString =
      "Outlook starts on " + inSeasonStartDate.toFormat("MM/dd")
  } else if (!isOutOfSeason && dataError) {
    yieldDescriptionString = translateFn(
      "forecastCurrUnavailable",
      "Forecast Currently Unavailable",
    )
  } else {
    yieldDescriptionString = `${translateFn(
      getDirectionalityDescription(directionalityBehavior as number) ?? "",
    )} (${formatter.format(yieldModel?.stats?.results[0]?.deviation_mean)})`
  }
  return (
    <div className="body-sm">
      <h1 className="font-medium">{yieldDescriptionString}</h1>
      <h1
        className={
          isOutOfSeason || dataError
            ? "text-gray-30"
            : "text-gray-60 dark:text-gray-30"
        }>
        {translateFn(currentSeason)}
      </h1>
    </div>
  )
}

function AssetNameCell({ assetName }: { readonly assetName: string }) {
  return (
    <div className="flex body-lg items-center">
      <div className="w-[20px] fill-gray-60 mx-2">
        <YieldIcon />
      </div>
      <h1>{assetName}</h1>
    </div>
  )
}

function AssetModelsCell({
  yieldModel,
  assetId,
}: {
  readonly yieldModel: IYieldCountryOverviewModel
  readonly assetId: string
}) {
  const { t } = useTranslate()
  const navigate = useNavigate()
  const { setShowSecondSidebar } = useUI()
  const { countries } = useYieldOutlook()

  const currentSeason = yieldModel?.stages[0]?.stage_name
  const isOutOfSeason = currentSeason === OUT_OF_SEASON
  const modelHasData = !!yieldModel?.newest_seasonal_date
  const tercileProbabilities: Record<string, number> =
    yieldModel?.stats?.results[0]?.tercile_probabilities

  const [directionalityBehavior] = getLargestTercile(tercileProbabilities)

  const dataError = directionalityBehavior === undefined && !isOutOfSeason

  // Box Color Styling - used for either directionality behaviors or 'out of season'
  const boxBaseColor = dataError
    ? "#B3B6BA"
    : isOutOfSeason
    ? "#DBDDDF"
    : getDirectionalityHighlightColor(directionalityBehavior as number)

  const boxColorStyles = {
    backgroundColor: !dataError
      ? Color(boxBaseColor).alpha(0.1).hexa()
      : "var(--bg-light)",
    color: Color(boxBaseColor).darken(0.2).hex(),
    borderColor: Color(boxBaseColor).alpha(0.4).hexa(),
  }

  const iconComponent =
    !isOutOfSeason ||
    (directionalityBehavior !== undefined &&
      getColorAndSymbol(directionalityBehavior.toString(), "table", {
        noSignal: "grey",
        above: "#23AF41",
        below: "#E42437",
        within: "#CC9300",
      }))

  return (
    <div
      onClick={() => {
        if (isOutOfSeason && !modelHasData) return
        navigate(
          assetId +
            "?region=" +
            yieldModel.region_id +
            "&date=" +
            yieldModel.newest_seasonal_date,
        )
        setShowSecondSidebar(true)
      }}
      className="border-[1px] rounded-md p-[10px] w-[170px] h-[80px]"
      style={{
        ...boxColorStyles,
        cursor: isOutOfSeason && !modelHasData ? "not-allowed" : "pointer",
      }}>
      <div className="flex justify-between">
        <h1
          className={
            isOutOfSeason
              ? "text-gray-60 dark:text-gray-30"
              : "text-light-text dark:text-dark-text"
          }>
          {countries[yieldModel.region_id]?.name}
        </h1>
        <div className="w-[20px]">{iconComponent}</div>
      </div>

      <YieldCountryDescription
        yieldModel={yieldModel}
        isOutOfSeason={isOutOfSeason}
        dataError={dataError}
        translateFn={t}
        directionalityBehavior={directionalityBehavior}
        currentSeason={currentSeason}
      />
    </div>
  )
}

export const columns = [
  {
    id: "asset",
    header: () => <Translate labelKey="crop" />,
    cell: ({ row }: { row: Row<IYieldOverview> }) => {
      return <AssetNameCell assetName={row.original.asset_name} />
    },
  },
  {
    id: "yield_expectations",
    header: () => <Translate labelKey="countryYieldExpectations" />,
    cell: ({ row }: { row: Row<IYieldOverview> }) => {
      const { countries } = useYieldOutlook()
      return (
        <div className="py-4 px-1 gap-2 flex items-center">
          {row.original.asset_models.map((model) => (
            <AssetModelsCell
              key={row.original.asset_id + countries[model?.region_id]?.name}
              yieldModel={model}
              assetId={row.original.asset_id}
            />
          ))}
        </div>
      )
    },
  },
]
