import {
    ReactNode,
    createContext,
    useState,
    useEffect,
    useContext,
} from "react"
import Modal, { IModal } from "./Modal"

interface IModalContext {
    dequeueModal: () => void
    enqueueModal: (modal: IModal) => void
    confirmationModal: (modal: IModal) => void
    aboutModal: (tab: string) => void
    infoModal: (modal: IModal) => void
}

export const ModalContext = createContext<IModalContext>({} as IModalContext)

export const useModal = () => useContext(ModalContext)

function ModalProvider({ children }: { children: ReactNode }) {
    const [activeModal, setActiveModal] = useState<IModal | null>(null)
    const [modalsQueue, setModalsQueue] = useState<IModal[]>([])

    useEffect(() => {
        if (modalsQueue.length > 0) {
            setActiveModal(modalsQueue[0])
        } else {
            setActiveModal(null)
        }
    }, [modalsQueue])

    const dequeueModal = () => {
        setActiveModal(null)
        const newModals = [...modalsQueue]
        newModals.shift()
        setModalsQueue(newModals)
    }

    const enqueueModal = (modal: IModal) => {
        setModalsQueue((prevModalsQueue) => {
            prevModalsQueue.push(modal)
            return [...prevModalsQueue]
        })
    }

    const confirmationModal = ({
        title,
        text,
        onCancel,
        onContinue,
        onContinueLabel,
        onCancelLabel,
    }: IModal) => {
        enqueueModal({
            title,
            text,
            onCancel,
            onContinue,
            onContinueLabel,
            onCancelLabel,
            type: "confirmation",
            closeModal: dequeueModal,
            doGrayBG: true,
        })
    }

    const aboutModal = (tab?: string) => {
        if (!tab) return
        enqueueModal({
            type: "about-" + tab,
            onContinueLabel: "OK",
            closeModal: dequeueModal,
            doGrayBG: true,
        })
    }

    const infoModal = ({
        title,
        text,
        onContinueLabel,
        onContinue = () => null,
    }: IModal) => {
        enqueueModal({
            title,
            text,
            onContinueLabel,
            type: "info",
            closeModal: dequeueModal,
            doGrayBG: true,
            onContinue: onContinue,
        })
    }

    return (
        <ModalContext.Provider
            value={{
                dequeueModal,
                enqueueModal,
                confirmationModal,
                aboutModal,
                infoModal,
            }}>
            {children}
            <Modal {...activeModal} />
        </ModalContext.Provider>
    )
}

export default ModalProvider
