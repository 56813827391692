import { useTranslate } from "@tolgee/react"
import { DateTime } from "luxon"
import { useState } from "react"
import { FixedElement } from "../climateui/components"
import DatePicker, {
  DateValidatorFn,
} from "../climateui/components/DatePicker/DatePicker"
import { useOutsideComponentClickHandler } from "../climateui/hooks"
import { CalendarIcon } from "../climateui/icons"

const DATE_FORMAT = "yyyy/MM/dd"
const MIN_DATE_RANGE_DAYS = 14
const MAX_PAST_YEARS = 1

type StartEndDateInputProps = {
  initialStartDate?: DateTime
  initialEndDate?: DateTime
  onChange: (startDate?: DateTime, endDate?: DateTime) => void
}

const StartEndDateInput = ({
  initialStartDate = DateTime.now().minus({ days: 15 }),
  initialEndDate = DateTime.now().minus({ days: 1 }),
  onChange,
}: StartEndDateInputProps) => {
  const [showStartDate, setShowStartDate] = useState(false)
  const [showEndDate, setShowEndDate] = useState(false)
  const { t } = useTranslate()
  const outsideClickRef = useOutsideComponentClickHandler(() => {
    setShowStartDate(false)
    setShowEndDate(false)
  })

  // Calculate min and max dates
  const minDate = DateTime.now().minus({ years: MAX_PAST_YEARS })
  const maxDate = DateTime.now().minus({ days: 1 })

  // Create date validators
  const startDateValidator: DateValidatorFn = (date) => {
    if (!date) return false
    // Must be at least 1 year ago
    if (date < minDate) return false
    // Must be at least 2 weeks before end date
    if (date >= initialEndDate.minus({ days: MIN_DATE_RANGE_DAYS }))
      return false
    return true
  }

  const endDateValidator: DateValidatorFn = (date) => {
    if (!date) return false
    // Must be at most yesterday
    if (date > maxDate) return false
    // Must be at least 2 weeks after start date
    if (date <= initialStartDate.plus({ days: MIN_DATE_RANGE_DAYS }))
      return false
    return true
  }

  const handleStartDateChange = (date?: DateTime) => {
    setShowStartDate(false)
    if (date) {
      // If new start date would make end date invalid, adjust end date
      const newEndDate = date.plus({ days: MIN_DATE_RANGE_DAYS })
      if (newEndDate > initialEndDate) {
        onChange(date, newEndDate)
      } else {
        onChange(date, initialEndDate)
      }
    } else {
      onChange(date, initialEndDate)
    }
  }

  const handleEndDateChange = (date?: DateTime) => {
    setShowEndDate(false)
    if (date) {
      // If new end date would make start date invalid, adjust start date
      const newStartDate = date.minus({ days: MIN_DATE_RANGE_DAYS })
      if (newStartDate < initialStartDate) {
        onChange(newStartDate, date)
      } else {
        onChange(initialStartDate, date)
      }
    } else {
      onChange(initialStartDate, date)
    }
  }

  return (
    <div
      className="flex"
      ref={outsideClickRef}>
      <FixedElement
        open={showStartDate}
        parentElement={
          <button
            tabIndex={0}
            className={[
              "flex flex-row items-center",
              "transition-all duration-75",
              "h-[32px] min-w-[60px] px-[8px] w-fit max-w-[200px]",
              "border-[1px] rounded-sm border-gray-14 dark:border-gray-78",
              "cursor-pointer disabled:cursor-not-allowed",
              "focus:outline-accent",
              "bg-light-bg dark:bg-dark-bg label-lg text-gray-60 dark:text-gray-30",
              "disabled:bg-gray-5 dark:disabled:bg-gray-60 disabled:text-gray-30 disabled:fill-gray-30",
            ].join(" ")}
            onClick={() => {
              setShowStartDate((oldShow) => !oldShow)
              setShowEndDate(false)
            }}>
            {initialStartDate.toFormat(DATE_FORMAT)}
            <span className="w-[20px] h-[20px] fill-gray-60 ml-1">
              <CalendarIcon />
            </span>
          </button>
        }>
        <DatePicker
          initialDate={initialStartDate}
          validator={startDateValidator}
          invalidMessage={t(
            "dateRangeInvalid",
            "Date must be at least 2 weeks before end date and within the last year",
          )}
          canClearInput={false}
          onChange={handleStartDateChange}
        />
      </FixedElement>
      <FixedElement
        open={showEndDate}
        parentElement={
          <button
            tabIndex={0}
            className={[
              "flex flex-row items-center",
              "transition-all duration-75",
              "h-[32px] min-w-[60px] px-[8px] w-fit max-w-[200px]",
              "border-[1px] rounded-sm border-gray-14 dark:border-gray-78",
              "cursor-pointer disabled:cursor-not-allowed",
              "focus:outline-accent",
              "bg-light-bg dark:bg-dark-bg label-lg text-gray-60 dark:text-gray-30",
              "disabled:bg-gray-5 dark:disabled:bg-gray-60 disabled:text-gray-30 disabled:fill-gray-30",
            ].join(" ")}
            onClick={() => {
              setShowEndDate((oldShow) => !oldShow)
              setShowStartDate(false)
            }}>
            {initialEndDate.toFormat(DATE_FORMAT)}
            <span className="w-[20px] h-[20px] fill-gray-60 ml-1">
              <CalendarIcon />
            </span>
          </button>
        }>
        <DatePicker
          initialDate={initialEndDate}
          validator={endDateValidator}
          invalidMessage={t(
            "dateRangeInvalid",
            "Date must be at least 2 weeks after start date and at most yesterday",
          )}
          canClearInput={false}
          onChange={handleEndDateChange}
        />
      </FixedElement>
    </div>
  )
}

export default StartEndDateInput
