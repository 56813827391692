import { useTranslate } from "@tolgee/react"
import { isDate } from "lodash"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Tooltip } from "../../climateui/components"
import { BoxIcon, CancelIcon, LoadingCircleIcon } from "../../climateui/icons"
import CheckmarkIcon from "../../climateui/icons/CheckmarkIcon"
import { ToastContext } from "../../climateui/providers"
import EmptyModal from "../../climateui/providers/Modal/EmptyModal"
import { useUI } from "../../providers/UIProvider"
import {
  isEmptyString,
  MAX_LAT,
  MAX_LON,
  MIN_LAT,
  MIN_LON,
} from "../../views/Admin/Locations/LocationsUtil"
import { VARIETY_NOT_FOUND } from "../../views/Admin/RiskProfiles/utils/csv"
import {
  IBulkUploadStatus,
  ICSVDataCell,
  modalStrings,
  UploadDataType,
} from "./bulkUploadUtils"

export interface IBulkUploadCSVParser {
  uploadDataType: UploadDataType
  data: ICSVDataCell[][] | undefined
  modalDimensions: { width: number; height: number }
  tableHeaderColumnData: {
    labelKey: string
    width: string
    extraStyle: string
    type: string
    required: boolean
  }[]
  handleImport: (
    data: ICSVDataCell[][],
    uploadStatusCallback: (status: IBulkUploadStatus) => void,
    doneUploadingCallback: (status: IBulkUploadStatus) => void,
  ) => Promise<void>
  handleCancel: () => void
  stayOnPage?: boolean
}

const LIST_COLUMNS = ["labels", "assets"]
const BulkUploadCSVParser = (props: IBulkUploadCSVParser) => {
  const {
    uploadDataType,
    tableHeaderColumnData,
    modalDimensions,
    data,
    handleImport,
    handleCancel,
    stayOnPage = false,
  } = props

  const { setSidebarOpen } = useUI()
  const { enqueueAlert } = useContext(ToastContext)

  const { t } = useTranslate()

  const getModalString = (
    key: string,
    options?: { count?: number; n?: number; m?: number },
  ) => {
    if (!modalStrings[uploadDataType][key]) return `${uploadDataType}:${key}`
    return t(modalStrings[uploadDataType][key], options)
  }
  useEffect(() => {
    if (data) {
      setSidebarOpen(false)
    }
  }, [data])

  const [modalTitle, setModalTitle] = useState<string>(
    getModalString("modalDisplayTitle"),
  )
  const [modalSubtitle, setModalSubtitle] = useState<string>(
    getModalString("modalDisplaySubtitle"),
  )
  const [readyToUpload, setReadyToUpload] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [doneUploading, setDoneUploading] = useState(false)
  const [uploadStatus, setUploadStatus] = useState({
    rows: [],
    current: 0,
  } as IBulkUploadStatus)
  const [totalErrors, setTotalErrors] = useState(0)

  let errorCount = 0
  const setCellError = (cell: ICSVDataCell, error: string) => {
    cell.error = error
    errorCount++
  }

  const validateCSVData = (data: ICSVDataCell[][]) => {
    errorCount = 0

    data.forEach((rowData, rowIndex) => {
      rowData.forEach((dataCellValue, colIndex) => {
        // Prevent extra data from the CSV from messing up parsing
        // -2 is because the first column of the table is the enumerating column, no data is displayed there
        if (colIndex > tableHeaderColumnData.length - 2) return

        // Restructure data so that it can hold error info, if needed
        if (typeof data[rowIndex][colIndex] !== "object")
          data[rowIndex][colIndex] = {
            value: dataCellValue as string | number | undefined,
            error: undefined,
          }

        // Grab a copy of that new dataCell instance
        const dataCell = data[rowIndex][colIndex]

        // If the value is a string, trim it
        if (typeof dataCell.value === "string")
          dataCell.value = dataCell.value.trim()

        // Data type validations //
        const cellIsEmpty = isEmptyString(dataCell.value as string)

        // Empty //
        if (cellIsEmpty && tableHeaderColumnData[colIndex + 1].required) {
          // This cell is empty && its a required cell, add the error!
          setCellError(dataCell, t("emptyRequiredCellError"))
        } else if (
          cellIsEmpty &&
          !tableHeaderColumnData[colIndex + 1].required
        ) {
          // This cell is empty, but it is not required; no further validation needed
          // return early
          return
        }

        // Numerical //
        else if (tableHeaderColumnData[colIndex + 1].type === "number") {
          // This is a numerical cell, try to parse it
          const parsedNumber = Number(dataCell.value)
          if (isNaN(parsedNumber)) {
            // Add error to this cell
            setCellError(dataCell, t("numericalOnlyCellError"))
          }
          // Now that we know we have a number on this cell, lets validate its value
          else if (
            tableHeaderColumnData[colIndex + 1].labelKey === "latitude"
          ) {
            if (parsedNumber > MAX_LAT || parsedNumber < MIN_LAT) {
              // This value is out of bounds for latitude, add error
              setCellError(dataCell, t("outOfBoundsLatitudeError"))
            }
          } else if (
            tableHeaderColumnData[colIndex + 1].labelKey === "longitude"
          ) {
            if (parsedNumber > MAX_LON || parsedNumber < MIN_LON) {
              // This value is out of bounds for longitude, add error
              setCellError(dataCell, t("outOfBoundsLongitudeError"))
            }
          }
        }

        // String //
        else if (tableHeaderColumnData[colIndex + 1].type === "string") {
          // Assert to TS that we are dealing with a string value
          dataCell.value = dataCell.value as string
          let listSplit = [] as string[]
          const currentLabel = tableHeaderColumnData[colIndex + 1].labelKey
          // The labels cell needs to be split using the '-' character
          // Split it before checking for special chars
          if (LIST_COLUMNS.includes(currentLabel)) {
            listSplit = dataCell.value.split("-")
            // Join with emptystring initially, so it can be properly tested for special chars
            dataCell.value = listSplit.join("")
          }

          // This is a string cell, check for special characters
          // Except for loc name, we accept special characters for those columns!
          if (currentLabel === "Location Name") {
            if (/[~`!#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(dataCell.value)) {
              // String has special characters, add error to this cell
              setCellError(dataCell, t("disallowedSpecialCharsCellError"))
            }
          }

          // Now we know we have a valid string, check if it has a Label value
          if (LIST_COLUMNS.includes(currentLabel)) {
            // Now join the label values properly with a comma, since it already passed special chars verification
            dataCell.value = listSplit.join(", ")
          }
        }

        // Date //
        else if (tableHeaderColumnData[colIndex + 1].type === "date") {
          // This is a date cell, check for formatting
          if (!isDate(dataCell.value as string)) {
            // String has special characters, add error to this cell
            setCellError(dataCell, t("dateValuesOnlyCellError"))
          }
        }
        // Percentage //
        else if (tableHeaderColumnData[colIndex + 1].type === "percentage") {
          // This is a percentage cell, check for formatting
          // This regular expression expresses a percentage X.X%
          // Supports negative numbers as well
          if (!/^-?\d+(\.\d+)?%$/.test(dataCell.value as string)) {
            // String has special characters, add error to this cell
            setCellError(dataCell, t("percentageValuesOnlyCellError"))
          }
          // Now check that number is between -100 and 100 for impact fields.
          // Only checking those fields as I don't know if all percentage fields
          // Will have this limitation. Might not be the safest implementation but
          // easier to read than N amount of ifs.
          if (
            [
              "impactFunctionInitial",
              "impactFunctionMarginal",
              "impactFunctionMax",
            ].indexOf(tableHeaderColumnData[colIndex + 1].labelKey) !== -1
          ) {
            const percentageString = dataCell.value as string
            const parsedNumber = Number(percentageString.slice(0, -1))
            if (100 < parsedNumber || parsedNumber < -100) {
              setCellError(dataCell, t("outOfBoundsPercentageError"))
            }
          }
          // Check if probability field is between 1 and less than 100
          if (
            ["probability"].indexOf(
              tableHeaderColumnData[colIndex + 1].labelKey,
            ) !== -1
          ) {
            const percentageString = dataCell.value as string
            const parsedNumber = Number(percentageString.slice(0, -1))
            if (100 < parsedNumber || parsedNumber < 1) {
              setCellError(dataCell, t("outOfBoundsPercentageError"))
            }
          }
        }

        // Update the cell instance
        data[rowIndex][colIndex] = dataCell
      })
    })

    const duplicateCount = uploadStatus.rows.reduce((prev: number, val) => {
      if (val === 409) return prev + 1 // if it is a duplicate
      return prev
    }, 0)

    if (!data.length) {
      // Empty CSV!
      setModalTitle(getModalString("emptyCSV"))
      setModalSubtitle(getModalString("addAtLeastOne"))
      setReadyToUpload(false)
    } else if (errorCount) {
      // There are errors on the file
      setModalTitle(`${errorCount} ` + t("errorsDetectedOnYourFile"))
      setModalSubtitle(t("pleaseVerifyAndCorrectCSVFile"))
      setReadyToUpload(false)
    } else if (duplicateCount > 0) {
      // There were some duplicates
      setModalTitle(getModalString("importN", { count: data.length }))
      setModalSubtitle(
        t(
          "duplicatesDetectedBulk",
          "{count, plural, =1 {One of the {type} already exists on your account. This row will not be imported} other {{count} {type} already exist on your account. These rows will not be imported}}",
          { count: duplicateCount, type: uploadDataType },
        ),
      )
    } else {
      // There are NO errors on the file
      setModalTitle(getModalString("importN", { count: data.length }))
      setModalSubtitle(getModalString("thisFileHasNoErrors"))
      setReadyToUpload(true)
    }
  }

  useEffect(() => {
    // Monitor the data being recieved; once we get a file, validate it
    if (data !== undefined) validateCSVData(data)
  }, [data, uploadStatus])

  const checkUploadResults = (status: IBulkUploadStatus) => {
    let errors = 0
    status.rows.forEach((rowStat) => {
      if (["error", 500].includes(rowStat)) errors++
    })
    return errors
  }

  const handleDoneUploading = (status: IBulkUploadStatus) => {
    const newTotalErrors = checkUploadResults(status)
    if (data === undefined) return
    if (!newTotalErrors) {
      setModalTitle(getModalString("doneImportingN", { count: data.length }))
      setModalSubtitle(getModalString("closeThisWindow"))
    } else {
      setModalTitle(
        getModalString("doneImportingN", {
          count: data.length - newTotalErrors,
        }),
      )
      setModalSubtitle(
        getModalString("thereWasAProblemWithN", {
          count: newTotalErrors,
        }),
      )
    }
    setTotalErrors(newTotalErrors)
    setDoneUploading(true)
  }

  const importData = () => {
    setUploading(true)
    setModalTitle(
      getModalString("importingNOfM", {
        n: uploadStatus.current + 1,
        m: data?.length,
      }),
    )
    setModalSubtitle(t("pleaseDoNotCloseThisWindow"))
    handleImport(
      data as ICSVDataCell[][],
      (status) => {
        setUploadStatus({ ...status })
        setModalTitle(
          getModalString("importingNOfM", {
            n: uploadStatus.current + 1,
            m: data?.length,
          }),
        )
      },
      handleDoneUploading,
    )
  }

  const resetStates = () => {
    // Reset state so that this component is guaranteed to re-render,
    // even if the exact same CSV file gets re-uploaded
    setModalTitle(getModalString("modalDisplayTitle"))
    setModalSubtitle(getModalString("modalDisplaySubtitle"))
    setReadyToUpload(false)
    setUploading(false)
    setDoneUploading(false)
    setTotalErrors(0)
    setUploadStatus({ rows: [], current: 0 })
    handleCancel()
  }

  const navigate = useNavigate()

  const toNextView = (importedCount: number) => {
    const toastMessage = getModalString("NImportedSuccessfully", {
      count: importedCount,
    })
    navigate(modalStrings[uploadDataType]["nextViewPath"], {
      state: {
        navigationData: {
          forceReload: true,
        },
      },
    })
    if (toastMessage !== "") enqueueAlert(toastMessage)
  }

  return (
    <EmptyModal
      open={data !== null && data !== undefined}
      customClasses="relative rounded-lg bg-light-bg dark:bg-dark-bg flex flex-col items-stretch"
      customStyle={{
        width: modalDimensions.width + "px",
        height: modalDimensions.height + "px",
      }}>
      <button
        className="absolute w-5 h-5 top-4 right-4"
        onClick={() => {
          // Reset state so that this component is guaranteed to re-render,
          // even if the exact same CSV file gets re-uploaded
          resetStates()
        }}>
        <CancelIcon />
      </button>

      <h1 className="pt-4 pl-5 pr-8 title-sm text-light-text dark:text-dark-text">
        {modalTitle}
      </h1>
      <span className="px-5 pt-1 text-[14px] text-gray-60 dark:text-gray-30 font-roboto">
        {modalSubtitle}
      </span>

      <div className="w-full h-full mt-3 overflow-auto border-b grow border-gray-14 dark:border-gray-78">
        <table className="relative border-separate table-fixed csv_table__content border-spacing-0 border-gray-14 dark:border-gray-78">
          <thead className="h-9 text-[14px] font-roboto font-bold text-light-text dark:text-dark-text">
            <tr>
              {tableHeaderColumnData.map((element, index) => {
                return (
                  <th
                    key={"key" + index}
                    id={`th${index}`}
                    className={
                      "top-0 bg-gray-5 dark:bg-gray-86 border border-gray-14 dark:border-gray-78 sticky z-full"
                    }>
                    <div
                      className={
                        "text-[14px] overflow-hidden whitespace-nowrap " +
                        element.width +
                        element.extraStyle
                      }>
                      {t(element.labelKey)}
                    </div>
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {data?.map((rowData, rowIndex) => {
              return (
                <tr
                  key={JSON.stringify(rowData)}
                  className={[
                    uploadStatus.rows[rowIndex] === 409
                      ? "text-gray-30"
                      : "text-light-text dark:text-dark-text", // duplicate
                  ].join(" ")}>
                  <td
                    key={JSON.stringify(rowData)}
                    headers={"th0"}
                    className={
                      "border-r border-b border-gray-14 dark:border-gray-78"
                    }>
                    {(!uploading ||
                      (uploading &&
                        uploadStatus.rows[rowIndex] === "on queue")) && (
                      <div
                        className={
                          "flex items-center justify-center text-[14px] text-gray-30 h-[31px] overflow-hidden whitespace-nowrap " +
                          tableHeaderColumnData[0].width
                        }>
                        {rowIndex + 1}
                      </div>
                    )}
                    {uploading &&
                      uploadStatus.rows[rowIndex] !== "on queue" && (
                        <div
                          className={
                            "flex items-center justify-center text-[14px] text-gray-30 h-[31px] overflow-visible whitespace-nowrap " +
                            tableHeaderColumnData[0].width
                          }>
                          {uploadStatus.rows[rowIndex] === "waiting" && (
                            <span className="w-5 h-5 fill-accent">
                              <LoadingCircleIcon />
                            </span>
                          )}
                          {uploadStatus.rows[rowIndex] === 201 && (
                            <span className="w-5 h-5 fill-green">
                              <CheckmarkIcon />
                            </span>
                          )}
                          {uploadStatus.rows[rowIndex] === 409 && (
                            <Tooltip
                              doShow={true}
                              position="right"
                              content={t("alreadyExists", "Already Exists")}>
                              <span className="w-5 h-5 fill-gray-30">
                                <CheckmarkIcon />
                              </span>
                            </Tooltip>
                          )}
                          {uploadStatus.rows[rowIndex] === "limitReached" && (
                            <Tooltip
                              doShow={true}
                              position="right"
                              content={t(
                                "locationsLimitReached",
                                "Account locations limit reached",
                              )}>
                              <span className="w-5 h-5 fill-red">
                                <CancelIcon />
                              </span>
                            </Tooltip>
                          )}
                          {uploadStatus.rows[rowIndex] ===
                            VARIETY_NOT_FOUND && (
                            <Tooltip
                              doShow={true}
                              position="right"
                              content={t("assetNotFound", "Asset not found")}>
                              <div className="items-center justify-center w-full h-full scale-[0.833]">
                                <span className="w-3 h-3 fill-red">
                                  <BoxIcon />
                                </span>
                              </div>
                            </Tooltip>
                          )}
                          {["error", 500].includes(
                            uploadStatus.rows[rowIndex],
                          ) && (
                            <span className="w-5 h-5">
                              <CancelIcon />
                            </span>
                          )}
                        </div>
                      )}
                  </td>
                  {rowData.map((dataCell, colIndex) => {
                    // Prevent extra data on the CSV from messing up rendering
                    // -2 is because the first column of the table is the enumerating column, no data is displayed there
                    if (colIndex > tableHeaderColumnData.length - 2) return
                    return dataCell.error === undefined ? (
                      <td
                        key={"key" + colIndex}
                        headers={`th${colIndex}`}
                        className={
                          "border-r border-b border-gray-14 dark:border-gray-78"
                        }>
                        <div
                          className={
                            "flex items-center text-[14px] h-[31px] pl-[5px] overflow-hidden whitespace-nowrap " +
                            tableHeaderColumnData[colIndex + 1].width
                          }>
                          {dataCell.value as string}
                        </div>
                      </td>
                    ) : (
                      <td
                        key={"key" + colIndex}
                        headers={`th${colIndex}`}
                        className={
                          "border-r border-b border-gray-14 dark:border-gray-78 bg-[#FCE6EA]"
                        }>
                        <Tooltip
                          position="top"
                          content={dataCell.error}>
                          <div
                            className={
                              "flex items-center text-[14px] text-[#E00028] h-[31px] pl-[5px] overflow-hidden whitespace-nowrap " +
                              tableHeaderColumnData[colIndex + 1].width
                            }>
                            {dataCell.value as string}
                          </div>
                        </Tooltip>
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="flex flex-row justify-end p-4">
        {!uploading && (
          <Button
            label={getModalString("modalDisplayTitle")}
            type="primary"
            onClick={importData}
            disabled={!readyToUpload}
          />
        )}
        {doneUploading && (
          <Button
            onClick={() => {
              if (stayOnPage) return handleCancel()
              return toNextView((data?.length ?? 0) - totalErrors)
            }}
            label={getModalString("back")}
            type="primary"
          />
        )}
      </div>
    </EmptyModal>
  )
}

export default BulkUploadCSVParser
