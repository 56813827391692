import {
  BellIcon,
  BlocksIcon,
  CalendarIcon,
  DashboardIcon,
} from "../../climateui/icons"
import RiskOutlookIcon from "../../climateui/icons/variableIcons/RiskOutlookIcon"
import YieldIcon from "../../climateui/icons/variableIcons/YieldIcon"
import { IRoute } from "../../climateui/types"
import NotFound from "../NotFound"
import AlertPage from "./Alerts/AlertPage"
import AlertsLayout from "./Alerts/AlertsLayout"
import DashboardParentView from "./Dashboards/components/DashboardParentView"
import dashboardChildRoutes from "./Dashboards/routes"
import PlanningTool from "./PlanningTool/PlanningTool"
import planningToolChildRoutes from "./PlanningTool/routes"
import RiskOutlookView from "./RiskOutlook"
import RiskOverview from "./RiskOutlook/components/RiskOverview"
import RiskRegionalDashboard from "./RiskOutlook/templates/RiskRegionalDashboard"
import SchedulerView from "./Scheduler/components/SchedulerView"
import YieldOutlookView from "./YieldOutlook"
import YieldOverview from "./YieldOutlook/components/YieldOverview"
import LocationDashboard from "./YieldOutlook/templates/LocationDashboard"
import RegionalDashboard from "./YieldOutlook/templates/RegionalDashboard"

const seasonalChildRoutes: IRoute[] = [
  {
    path: "alerts",
    label: "Alerts",
    labelKey: "alertsLabel",
    accountPermissions: ["monitor_alerts_revamp"],
    badgePortalId: "alertBadge",
    icon: <BellIcon />,
    element: <AlertsLayout />,
    children: [{ path: "", element: <AlertPage /> }],
    featureFlags: ["ui_new_alert_page"],
  },
  {
    path: "dashboards",
    label: "Dashboards",
    labelKey: "dashboardsLabel",
    accountPermissions: ["monitor_dashboards"],
    icon: <DashboardIcon />,
    element: <DashboardParentView />,
    children: dashboardChildRoutes,
    portalId: "dashboardsPortal",
  },
  {
    path: "schedules",
    label: "Scheduler",
    labelKey: "schedulerLabel",
    accountPermissions: ["monitor_schedules"],
    icon: <CalendarIcon />,
    element: <SchedulerView />,
    children: dashboardChildRoutes,
    featureFlags: ["ui_seasonal_schedules_page_enabled"],
  },
  {
    path: "plans",
    label: "Planning Tool",
    labelKey: "planningToolLabel",
    accountPermissions: ["planning_tool"],
    icon: <BlocksIcon />,
    element: <PlanningTool />,
    featureFlags: ["ui_seasonal_plans_page_enabled"],
    children: planningToolChildRoutes,
    portalId: "plansPortal",
  },
  {
    path: "outlook",
    label: "Yield Outlook",
    labelKey: "yieldOutlookLabel",
    accountPermissions: ["monitor_yield_outlook"],
    featureFlags: ["feature_monitor_yield_outlook"],
    icon: <YieldIcon />,
    element: <YieldOutlookView />,
    children: [
      {
        path: "",
        element: <YieldOverview />,
      },
      {
        path: ":assetId",
        labelKey: "assets",
        element: <RegionalDashboard />,
      },
      {
        featureFlags: ["feature_monitor_yield_outlook__locations"],
        path: "locations/:assetId",
        labelKey: "assets",
        element: <LocationDashboard />,
      },
    ],
  },
  {
    path: "risk_outlook",
    label: "Risk Outlook",
    labelKey: "riskOutlookLabel",
    accountPermissions: ["monitor_risk_outlook"],
    icon: <RiskOutlookIcon />,
    element: <RiskOutlookView />,
    children: [
      {
        path: "",
        element: <RiskOverview />,
      },
      {
        path: ":assetId",
        labelKey: "assets",
        element: <RiskRegionalDashboard />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]

export default seasonalChildRoutes
