import { DefaultParamType, TFnType } from "@tolgee/react"
import { ITableWidgetColumn } from "../../../climateui/components/Widgets/Table/TableWidget"

export const getColumns = ({
  t,
  units,
}: {
  t: TFnType<DefaultParamType, string>
  units: string
}): Record<
  string,
  ITableWidgetColumn & {
    headerDescription?: string
  }
> => ({
  // HISTORICAL DEVIATION
  historicalDeviation: {
    propName: "deviationHistorical",
    header: t("deviationHistoricalAbbr", "Baseline Deviation"),
    type: "number",
    selector: {
      value: "{{ stats.results[].deviation_mean[0] }} ",
      unit: "%",
    },
    columns: [
      {
        propName: "deviationHistorical",
        type: "number",
      },
    ],
  },
  // MEAN YIELD
  yieldOutlook: {
    propName: "meanYield",
    header: t("meanYield", "Mean Yield"),
    headerDescription: "Mass / Area",
    type: "number",
    selector: {
      value: "{{ stats.results[].outlook_mean[0] }}",
      unit: units,
    },
    columns: [
      {
        propName: "meanYield",
        type: "number",
      },
    ],
  },
})
