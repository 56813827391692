import { Row } from "@tanstack/react-table"
import { useTranslate } from "@tolgee/react"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { MultiSelectFilter } from "../../../climateui/components"
import {
  TableActions,
  TruncatedToCopyCell,
} from "../../../climateui/components/Table/tableUtils"
import { ArrowBottom, CancelIcon } from "../../../climateui/icons"
import { IBooleanDictionary } from "../../../climateui/types"
import { Translate } from "../../../components"
import { useAssets } from "../../../providers"
import { useLocations } from "../../../providers/LocationsProvider"
import { useUI } from "../../../providers/UIProvider"
import { IInsightsLocation, IPlan } from "../../../types"
import { formatDate } from "../../../utils/transform"
import PlanTableActions from "./components/PlanTableActions"
import { usePlan } from "./PlanProvider"

function PlanNameCell({ name, id }: { name: string; id: string }) {
  const navigate = useNavigate()
  const { setShowSecondSidebar } = useUI()

  return (
    <button
      onClick={() => {
        navigate(id + "")
        setShowSecondSidebar(true)
      }}
      className="text-left underline cursor-pointer">
      {name}
    </button>
  )
}

export const columns = [
  {
    accessorKey: "name",
    header: () => <Translate labelKey="name" />,
    cell: ({ row }: { row: Row<IPlan> }) => (
      <PlanNameCell
        name={row.original.name || ""}
        id={(row.original.id || "") as string}
      />
    ),
  },
  {
    accessorKey: "description",
    header: () => <Translate labelKey="description" />,
    cell: ({ row }: { row: Row<IPlan> }) => {
      const { t } = useTranslate()

      return (
        <TruncatedToCopyCell
          value={row.original.description}
          copySuccessMessage={t("SOMETHINGCopiedToClipboard", {
            something: t("planDescription"),
          })}
          maxWidthClass="max-w-[260px] w-[260px]"
          tooltipPosition="top"
        />
      )
    },
  },
  {
    accessorKey: "created_by",
    header: () => <Translate labelKey="createdBy" />,
    cell: ({ row }: { row: Row<IPlan> }) => {
      return <div className="body-md"> {row.original.created_by} </div>
    },
  },
  {
    accessorKey: "updated_at",
    header: () => <Translate labelKey="lastUpdated" />,
    cell: ({ row }: { row: Row<IPlan> }) => {
      let date_str = ""
      if (row.original.updated_at) {
        date_str = formatDate(row.original.updated_at)
      }

      return (
        <>
          <div className="body-md">{row.original.updated_by} </div>
          <div className="text-[11px] text-gray-60 dark:text-gray-30">
            {" "}
            {date_str}{" "}
          </div>
        </>
      )
    },
  },
  {
    accessorKey: " ",
    cell: ({ row }: { row: Row<IPlan> }) => {
      return <PlanTableActions rowData={row.original} />
    },
  },
]

export const LocationAssetsSelectCell = ({
  locationId,
}: {
  locationId: string
}) => {
  const { locationsObj } = useLocations()
  const { varieties: completeVarieties } = useAssets()
  const { workingPlan, setWorkingPlan, isStageManagerEnabled } = usePlan()

  const location = locationsObj[locationId]
  const varietyOptions: Record<string, string> = {}
  const unallowedVarietysOptions: string[] = []

  location.varieties.forEach((variety) => {
    if (
      variety.id &&
      variety.asset &&
      variety.asset.name &&
      completeVarieties
    ) {
      varietyOptions[variety.id] = `${variety.asset.name} (${variety.name})`

      const fullVariety = completeVarieties[variety.id]

      if (
        isStageManagerEnabled &&
        fullVariety &&
        Object.prototype.hasOwnProperty.call(fullVariety, "has_timeline") &&
        !fullVariety.has_timeline
      ) {
        unallowedVarietysOptions.push(variety.id)
      }
    }
  })

  const selectedLocationsAssetVarieties: IBooleanDictionary = useMemo(() => {
    return workingPlan?.selectedLocationsAssetVarieties &&
      workingPlan.selectedLocationsAssetVarieties[locationId]
      ? workingPlan.selectedLocationsAssetVarieties[locationId]
      : {}
  }, [workingPlan?.selectedLocationsAssetVarieties])
  const { t } = useTranslate()

  return (
    <MultiSelectFilter
      icon={<ArrowBottom />}
      filterName={t("variety")}
      options={varietyOptions}
      leftRightClass="left-0"
      setSelected={(selected) => {
        setWorkingPlan({
          ...workingPlan,
          selectedLocationsAssetVarieties: {
            ...workingPlan?.selectedLocationsAssetVarieties,
            [locationId]: selected,
          },
        })
      }}
      selected={selectedLocationsAssetVarieties}
      canSearch
      sortByKey={false}
      searchPlaceholder={t("search", "Search")}
      placeholder={t("selectAnOption", "Select an option")}
      shouldWrapToTop
      disabledKeysList={unallowedVarietysOptions}
      disabledOptionsMessage={t(
        "noTimelineOnAssetsTooltip",
        "Asset needs a Timeline. Please go to Admin > Asset page for setup.",
      )}
    />
  )
}
export const PlanAssetsTableActions = ({
  locationId,
}: {
  locationId: string
}) => {
  const { t } = useTranslate()
  const { setWorkingPlan } = usePlan()

  return (
    <TableActions
      rowData={{ locationId }}
      actions={[
        {
          icon: <CancelIcon />,
          tooltip: t("removeLocationFromPlan", "Remove location from plan"),
          onClick: () => {
            setWorkingPlan((prev) => {
              const newSelectedLocationsAssetVarieties = {
                ...prev?.selectedLocationsAssetVarieties,
              }
              delete newSelectedLocationsAssetVarieties[locationId]
              return {
                ...prev,
                selectedLocationsAssetVarieties:
                  newSelectedLocationsAssetVarieties,
              }
            })
          },
        },
      ]}
    />
  )
}

const planLocationAssetsColumn = {
  accessorKey: "assets",
  header: () => <Translate labelKey="assets" />,
  cell: ({ row }: { row: Row<IInsightsLocation> }) => (
    <LocationAssetsSelectCell locationId={row.original.id || ""} />
  ),
}
const planAssetsTableActionsColumn = {
  accessorKey: "removeLocation",
  header: undefined,
  cell: ({ row }: { row: Row<IInsightsLocation> }) => (
    <PlanAssetsTableActions locationId={row.original.id || ""} />
  ),
}
export const planAssetsColumns = [
  "name",
  "region",
  "labels",
  planLocationAssetsColumn,
  planAssetsTableActionsColumn,
]
