import { useTranslate } from "@tolgee/react"
import { useEffect, useMemo, useState } from "react"
import { Button } from "../climateui/components"
import { CancelIcon } from "../climateui/icons"
import EmptyModal from "../climateui/providers/Modal/EmptyModal"
import { ICoordinates, ILabel } from "../climateui/types"
import { LocationsCount } from "../components"
import { HalfMapLayout } from "../layouts"
import { useLocations } from "../providers"
import { useAccount } from "../providers/AccountProvider"
import { IInsightsLocation, ILocationEssentials, IVariety } from "../types"
import LocationForm from "../views/Admin/Locations/components/LocationForm"

interface IAddLocationModal {
  open: boolean
  customCoords?: ICoordinates
  customVarieties?: IVariety[]
  customLabels?: ILabel[]
  cancel: () => void
  handleSave: (location: IInsightsLocation) => void
  showCount?: boolean
  lockLatLon?: boolean
  hasGeocoder?: boolean
  customLocation?: IInsightsLocation
  assetsInputDisabled?: boolean
}

function AddLocationModal({
  open,
  customCoords,
  customVarieties = [],
  customLabels = [],
  cancel = () => null,
  handleSave = () => null,
  showCount = true,
  lockLatLon = false,
  hasGeocoder = false,
  customLocation,
  assetsInputDisabled = false,
}: IAddLocationModal) {
  const { t } = useTranslate()
  const { selectedAccount } = useAccount()
  const { locations } = useLocations()

  const [canSubmitLocationForm, setCanSubmitLocationForm] = useState<{
    state: boolean
    editedLocation: ILocationEssentials | null
  }>({
    state: false,
    editedLocation: null,
  })

  const [coords, setCoords] = useState<ICoordinates>({ lat: 0, lon: 0 })

  useEffect(() => {
    if (!customCoords) return
    setCoords({
      ...customCoords,
    })
  }, [customCoords])

  const handleSubmitForm = () => {
    const location = canSubmitLocationForm.editedLocation as IInsightsLocation

    // Prepare loc object so that it fits the endpoint's standards
    location.account_id = selectedAccount as string
    location.external_id = `A${selectedAccount}L${location.name}`

    handleSave(location)
  }

  const mapProps = useMemo(
    () => ({
      hasGeocoder,
      coords,
      setCoords,
      pins: [],
      lockLatLon,
      customFlyTo: coords, // To open the map in this location
    }),
    [coords, hasGeocoder, lockLatLon],
  )

  const location = useMemo(
    () => ({
      latitude: customCoords?.lat || 0,
      longitude: customCoords?.lon || 0,
      labels: customLabels,
      varieties: customVarieties,
      ...(customLocation ?? {}),
    }),
    [customCoords, customLocation, customVarieties, customLabels],
  )

  return (
    <EmptyModal
      open={open}
      customClasses="w-[740px] min-h-[480px] flex flex-col">
      <div className="flex flex-row items-center justify-between h-14 pr-2 pl-3 border-b border-gray-14 dark:border-gray-78 shrink-0">
        <div className="title-sm text-light-text dark:text-dark-text">
          {customLocation
            ? t("editLocation", "Edit location")
            : t("addToMyLocations", "Add to My Locations")}
        </div>
        <span
          className="w-6 h-6 fill-gray-60 hover:fill-red transition-colors duration-75 cursor-pointer"
          onClick={cancel}>
          <CancelIcon />
        </span>
      </div>
      <div className="pr-2 pb-2">
        <HalfMapLayout
          contentWidthClasses="w-1/2 p-3 pt-1 flex flex-col pt-3 overflow-y-auto"
          mapProps={mapProps}>
          {showCount && (
            <div className="flex flex-row items-start pb-3">
              <LocationsCount count={locations.length} />
            </div>
          )}
          {!lockLatLon && (
            <p className="body-md text-gray-60 dark:text-gray-30">
              {t("mapPinClickInstructions", "Click on the map to move pin")}
            </p>
          )}

          {selectedAccount && (
            <LocationForm
              mapPinCoords={coords}
              setMapPinCoords={setCoords}
              location={location}
              changeCallback={(isValid, location) =>
                setCanSubmitLocationForm({
                  state: isValid,
                  editedLocation: location,
                })
              }
              assetsInputDisabled={assetsInputDisabled}
              lockLatLon={lockLatLon}
            />
          )}
          <div className="grow"></div>
          <Button
            extraClasses="shrink-0"
            label={
              customLocation
                ? t("save", "Save")
                : t("addLocation", "Add Location")
            }
            disabled={!canSubmitLocationForm.state}
            onClick={handleSubmitForm}
            extend
          />
        </HalfMapLayout>
      </div>
    </EmptyModal>
  )
}

export default AddLocationModal
