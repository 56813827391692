import { IPlannedRisk, IStage } from "../../../types"
import { daysBetween } from "../../../utils"
import { compareDates } from "../../../utils/dates"

export const NEW_STAGE_DURATION = 14
export const MIN_STAGE_DURATION = 3
export const EDITION_ACTIONS = {
  added: "add",
  edited: "update",
  deleted: "delete",
  none: "",
}

export function sortRisksByDate(riskA: IPlannedRisk, riskB: IPlannedRisk) {
  // First criterion: Risk hazard variable
  if (!riskA.start_date || !riskB.start_date) return 0
  return riskA.start_date < riskB.start_date ? -1 : 1
}

// Not used anymore - Product's decision. Leaving here in case they retract.
export function sortRiskArraysByFirstRiskStartDate(
  riskArrayA: IPlannedRisk[],
  riskArrayB: IPlannedRisk[],
) {
  // First criterion: Risk hazard variable
  if (!riskArrayA[0].start_date || !riskArrayB[0].start_date) return 0
  return riskArrayA[0].start_date < riskArrayB[0].start_date ? -1 : 1
}

export const moveItemDates = (
  item: IPlannedRisk | IStage,
  daysMoved: number,
) => {
  const start = item.start_date ?? new Date()
  const end = item.end_date ?? new Date()
  start.setDate(start.getDate() + daysMoved)
  end.setDate(end.getDate() + daysMoved)
  item.start_date = start
  item.end_date = end
  item.action = item.action ?? EDITION_ACTIONS.edited
}

export function moveStartDate(
  daysFromTimelineStart: number,
  daysMoved: number,
  leastVal: number,
  pxPerDay: number,
  setPxFromTimelineStart: (px: number) => void,
) {
  let newLeft = daysFromTimelineStart + daysMoved
  newLeft = newLeft > leastVal ? newLeft : leastVal
  setPxFromTimelineStart(newLeft * pxPerDay)
}
export function moveDragAuxDate(
  stageStartDate: string,
  timelineStartDate: Date,
  daysMoved: number,
  leastVal: number,
  setDragAuxDate: (date: Date) => void,
) {
  const stageDate = new Date(stageStartDate)
  stageDate.setDate(stageDate.getDate() + daysMoved)
  if (daysBetween(timelineStartDate, stageDate) < leastVal) return
  setDragAuxDate(stageDate)
}

export const updateStageRiskProfiles = (
  prevStage: IStage,
  newStage: Partial<IStage>,
  plannedRisk: IPlannedRisk,
) => {
  if (
    plannedRisk.stage_id !== newStage.id ||
    !plannedRisk.start_date ||
    !plannedRisk.end_date ||
    !plannedRisk.duration ||
    !newStage.start_date ||
    !newStage.end_date ||
    !prevStage.start_date ||
    !prevStage.end_date
  )
    return

  const startDaysMoved = daysBetween(prevStage.start_date, newStage.start_date)
  const isFullyOverlapped =
    compareDates(prevStage.start_date, plannedRisk.start_date) === 0 &&
    compareDates(prevStage.end_date, plannedRisk.end_date) === 0

  // Stage was just dragged
  if (prevStage.duration === newStage.duration) {
    plannedRisk.start_date.setDate(
      plannedRisk.start_date.getDate() + startDaysMoved,
    )
    plannedRisk.end_date.setDate(
      plannedRisk.end_date.getDate() + startDaysMoved,
    )
  } else if (isFullyOverlapped) {
    plannedRisk.start_date = new Date(newStage.start_date)
    plannedRisk.end_date = new Date(newStage.end_date)
    plannedRisk.duration = daysBetween(
      plannedRisk.start_date,
      plannedRisk.end_date,
    )
  }

  plannedRisk.action = plannedRisk.action ?? EDITION_ACTIONS.edited
}
