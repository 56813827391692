import { ReactNode, useContext } from "react"
import { IMap } from "../climateui/components/Map/utils"
import { LocalizationContext } from "../providers/LocalizationProvider"
import { Map } from "../climateui/components"

interface IFullScreenMapLayoutProps {
  mapProps: IMap
  children?: ReactNode
}

function FullScreenMapLayout({
  mapProps,
  children,
}: IFullScreenMapLayoutProps) {
  const { language } = useContext(LocalizationContext)

  return (
    <div className="flex flex-row h-full w-full relative">
      {/* Optional children */}
      {children && <div>{children}</div>}
      {/* MAP Full Screen */}
      <div className="w-full h-full">
        <Map
          {...mapProps}
          language={language}
        />
      </div>
    </div>
  )
}

export default FullScreenMapLayout
