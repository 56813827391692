import { saveAs } from "file-saver"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import LoadingBar from "../../climateui/components/LoadingScreen/LoadingBar"
import { DownloadIcon } from "../../climateui/icons"
import {
  CustomReportType,
  useCustomReports,
} from "../../providers/CustomReportsProvider"

export function CustomReport() {
  const navigate = useNavigate()
  const { selectedCustomReport, reportsRoutes } = useCustomReports()

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
  }, [selectedCustomReport])

  const handleLoadedReport = () => {
    setIsLoading(false)
  }

  useEffect(() => {
    if (!selectedCustomReport && reportsRoutes.length > 0) {
      navigate("" + reportsRoutes[0].path)
    }
  }, [reportsRoutes])

  if (!selectedCustomReport) return <div>Please select a Custom Report</div>

  const report = selectedCustomReport
  const exportReport = () => {
    saveAs(
      report.url,
      report.name.replaceAll(" ", "_") +
        "." +
        (report.type === CustomReportType.PDF
          ? CustomReportType.PDF
          : CustomReportType.PNG),
    )
  }

  return (
    <div className="h-full overflow-hidden">
      {isLoading && <LoadingBar />}
      <div className="flex flex-col space-y-4 h-full min-w-[320px]">
        {/* REPORT HEADER */}
        <div className="flex flex-row items-center justify-between flex-0">
          <div className="grow">
            <div className="montserrat font-bold text-[16px]">
              {report.name}
            </div>
            {/* <div className="text-[14px] text-gray-60 dark:text-gray-30 pt-2">
                            Updated on:{" "}
                            {
                                new Date(report.updated_at)
                                    .toISOString()
                                    .split("T")[0]
                            }
                        </div> */}
          </div>
          {report.type !== "url" && (
            <button
              className="bg-accent pl-2 pr-4 py-1 rounded-lg font-bold text-white text-[16px] flex flex-row items-center space-x-2 hover:opacity-90"
              onClick={exportReport}>
              <span className="w-6 fill-white">
                <DownloadIcon />
              </span>
              <span>Export</span>
            </button>
          )}
        </div>
        {/* REPORT VIEWER */}
        <div className="flex flex-row justify-center grow">
          <div className="relative w-[1400px] bg-gray-10 rounded-lg flex flex-col justify-center items-center overflow-hidden elevation-1 border border-gray-14 dark:border-gray-78 resize">
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-transparent">
              {(report.type === CustomReportType.PDF ||
                report.type === CustomReportType.URL) && (
                <iframe
                  className="w-full h-full"
                  src={report.url}
                  title="report-iframe"
                  onLoad={handleLoadedReport}></iframe>
              )}
              {report.type === CustomReportType.PNG && (
                <img
                  className="object-contain w-full h-full"
                  src={report.url}
                  alt="report-png"
                  onLoad={handleLoadedReport}></img>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomReport
