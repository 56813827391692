const DynamicMagnitudeIcon = ({ magnitude }) => {
    const riskToNumber = {
        highRisk: -1,
        mediumRisk: 0,
        lowRisk: 1,
    }

    const getColor = (value) => {
        switch (value) {
            case "highRisk":
                return "#E42437" // Red
            case "mediumRisk":
                return "#FDB600" // Yellow
            case "lowRisk":
                return "#23AF41" // Green
            default:
                return "#B3B6BA" // Gray (default)
        }
    }

    const color = getColor(magnitude)

    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                d="M5 20V14H8V20H5Z"
                fill={color}
            />
            <path
                d="M11 20V9H14V20H11Z"
                fill={riskToNumber[magnitude] <= 0 ? color : "#B3B6BA"}
            />
            <path
                d="M17 20V4H20V20H17Z"
                fill={riskToNumber[magnitude] === -1 ? color : "#B3B6BA"}
            />
        </svg>
    )
}

export default DynamicMagnitudeIcon
