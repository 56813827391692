import { useMemo, createContext } from "react"
import { useQuery } from "react-query"
import { IRoute } from "../../climateui/types"
import { isValidResponse, QuerySet } from "../../climateui/utils/http"
import { arrToDict } from "../../climateui/utils/transform"
import { useAssets } from "../../providers"
import { IRegion, IAssetModel, IYieldOutlookLocationGQL } from "../../types"
import { regionsQuerySet } from "../../utils/networking"
import { useAssetModelRegions } from "./useAssetModelRegions"
import { ProductKeyEnum, useAssetModels } from "./useAssetModels"
import { useNavigateToFirstModel } from "./useNavigateToFirstModel"
import { useSubRoutes } from "./useSubRoutes"
import useLocationModels, { IAssetLocationModel } from "./useLocationModels"

export interface IYieldOutlookContext {
  countries: Record<string, IRegion>
  states: IRegion[]
  subRoutes: IRoute[]
  assetModels: IAssetModel[] | undefined
  locationModels: IYieldOutlookLocationGQL[] | undefined
  isLoading: boolean
  // units: string
  // setUnits: SetQueryParam<string | undefined | null>
  assetLocationModels: IAssetLocationModel[]
}
export const YieldOutlookContext = createContext<IYieldOutlookContext>({
  countries: {},
  states: [],
  assetModels: [],
  locationModels: [],
  subRoutes: [],
  isLoading: true,
  assetLocationModels: [],
})

const queryMissingCountries =
  (missingCountriesIDs: string[]) => async (): Promise<IRegion[]> =>
    regionsQuerySet
      .post(
        "/search/",
        {
          filter_by: {
            and: [
              {
                field_name: "region.Region.id",
                operator: "in",
                field_value: missingCountriesIDs,
              },
            ],
          },
        },
        undefined,
        {
          headers: {
            "X-Fields": "data { id, parent_id, name, resolution }",
          },
        },
      )
      .then((response) => {
        if (!isValidResponse(response)) return
        return response.data.data
      })
      .catch(() => [])

const getLocationsSupportedAssets = (
  locationModels: IYieldOutlookLocationGQL[] | undefined,
): Set<string> => {
  const supportedAssets = new Set<string>()
  if (!locationModels) return supportedAssets
  return locationModels?.reduce((prev, curr) => {
    // Skip if there is no location defined for this model
    if (curr.location == null) return prev
    // validate asset id exists and there's data available
    if (curr.model?.asset_id && curr.newest_seasonal_date)
      prev.add(curr.model.asset_id)
    return prev
  }, supportedAssets)
}

export const useProviderBase = ({
  querySet,
  productKey = "status",
  navigateToFirstModel,
}: {
  querySet: QuerySet
  productKey: ProductKeyEnum
  navigateToFirstModel: boolean
}) => {
  // Get all unfiltered assets
  const { allAssets } = useAssets()
  // Retrieve all asset models
  const {
    assetModels,
    assetModelsRegions,
    isPending: pendingAssetModels,
  } = useAssetModels({
    assets: allAssets,
    querySet,
    productKey,
  })

  // Get the supported regions
  const { countries, states } = useAssetModelRegions(assetModelsRegions)

  // Get country IDs from supported states with no
  // aggregated country model (edge case)
  const missingCountriesIDs = useMemo(() => {
    if (states.length === 0) return []
    const missingIDs = new Set<string>()
    states.forEach(({ parent_id }) => {
      if (!parent_id || countries[parent_id]) return
      missingIDs.add(parent_id)
    })
    return Array.from(missingIDs)
  }, [countries, states])

  // Get missing country data from location service
  // (/region/search endpoint)
  const {
    data: missingCountries,
    // isFetched: fetchedMissingCountries,
    isFetching: fetchingMissingCountries,
  } = useQuery({
    queryKey: ["outlookMissingCountries", missingCountriesIDs],
    queryFn: queryMissingCountries(missingCountriesIDs),
    enabled: missingCountriesIDs.length > 0,
  })

  // All countries (models & no models alike)
  const allCountries = missingCountries
    ? {
        ...countries,
        ...arrToDict(missingCountries, "id"),
      }
    : countries

  // DISCUSSION I need to better understand the purpose of this and
  // why it is here and not in the seasonal provider.
  const platformURL = new URL(window.location.href)
  const inSeasonalPage = platformURL.href.includes("seasonal")

  // Fetches all model locations from service + location data
  const { assetLocationModels, locationModels } = useLocationModels(
    inSeasonalPage,
    { assetModels },
  )

  // TODO refactor this, subroutes should be implemented at the provicer level
  // not at the provier base (reason for this is different dashboard views have
  // different ways to )
  const subRoutes = useSubRoutes(allAssets, assetModels, productKey, {
    locations: {
      // supported assets returns a set of available assets (if they have location ids)
      supportedAssets: getLocationsSupportedAssets(locationModels),
    },
  })
  useNavigateToFirstModel(subRoutes, navigateToFirstModel)

  const isLoading =
    pendingAssetModels ||
    (fetchingMissingCountries && missingCountriesIDs.length > 0)

  return useMemo(
    () => ({
      countries: allCountries,
      states,
      assetModels,
      locationModels,
      subRoutes,
      isLoading,
      assetLocationModels,
    }),
    [allCountries, states, assetModels, subRoutes, locationModels],
  )
}
