import { useQuery } from "react-query"
import { alertSettingsQuerySet } from "../utils/networking"
import { isValidResponse } from "../climateui/utils/http"

export function useRiskSettingData(riskSettingIds: string[]) {
  return useQuery({
    queryKey: ["riskSettings"].concat(riskSettingIds),
    queryFn: () =>
      alertSettingsQuerySet.post({
        path: "/search",
        data: {
          filter_by: {
            and: [
              {
                field_name: "risk_setting.RiskSetting.id",
                operator: "in",
                field_value: riskSettingIds,
              },
            ],
          },
          pagination: {
            all: true,
          },
        },
        config: {
          headers: {
            "X-Fields": "{data{id, stage_risk_profile_id}}",
          },
        },
      }),
    select: (response) => {
      if (!isValidResponse(response)) return {}
      const riskSettingStageRiskProfileIds: Record<string, string> = {}

      response.data.data.forEach(
        ({
          id,
          stage_risk_profile_id,
        }: {
          id: string
          stage_risk_profile_id: string
        }) => {
          riskSettingStageRiskProfileIds[stage_risk_profile_id] = id
        },
      )

      return riskSettingStageRiskProfileIds
    },
  })
}
