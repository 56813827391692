import { useTranslate } from "@tolgee/react"
import { CSVError } from "./types"

const ZERO_WIDTH_CHARS = /[\u200B-\u200D\uFEFF]/g
export const removeZeroWidthChars = (x: string) =>
  x.replace(ZERO_WIDTH_CHARS, "")
const useToNum = () => {
  const { t } = useTranslate()
  return (
    _num: string,
    parseFn: (a: string) => number | typeof NaN,
    opts?: {
      isPercentage?: boolean
    },
  ) => {
    let cleanStr = _num.trim()
    if (opts?.isPercentage) {
      cleanStr = cleanStr.replace("%", "")
    }
    const num = parseFn(cleanStr)
    if (isNaN(num))
      return {
        code: 400,
        message: t("notANumber", "Not a number"),
      }
    return num / (opts?.isPercentage ? 100 : 1)
  }
}
export const useToFloat = () => {
  const toNum = useToNum()
  return (
    _num: string,
    opts?: {
      isPercentage: boolean
    },
  ): number | CSVError => toNum(_num, parseFloat, opts)
}
export const useToInt = () => {
  const toNum = useToNum()
  return (_num: string): number | CSVError => toNum(_num, parseInt)
}

/**
 * NOTE: Doesn't support heterogeneous arrays
 */
export const transposeObjToArr = <
  T extends Record<string | number | symbol, unknown>,
>(
  arr: T[],
) => {
  // If the array is undefined or empty, return an empty object
  if (!arr || arr.length === 0) return {}
  // Get the keys of the underlying object
  const keys = Object.keys(arr[0])
  const obj: Record<string | number | symbol, unknown[]> = {}
  arr.forEach((item) => {
    keys.forEach((key) => {
      if (!obj[key]) obj[key] = []
      obj[key].push(item[key])
    })
  })
  return obj
}
