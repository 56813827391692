export const LABEL_COLORS = [
  "#f06000",
  "#1C9690",
  "#2187C1",
  "#8E3FF3",
  "#464B86",
  "#55DDE0",
  "#E43F6F",
  "#FF99C8",
  "#C9E4CA",
  "#FFCF00",
]

export const TEMPLATE_LOCATION_CSV_DATA = [
  "Mexico City",
  "19.2719",
  "-99.0954",
  "one_label;other_label", // labels must be the last element
]

export const isEmptyString = (string: string) => {
  return !string || /^\s*$/.test(string)
}

export const isDate = function (date: string) {
  return !isNaN(Date.parse(date))
}

export const EMPTY_LOCATION = {
  name: "",
  latitude: 10,
  longitude: 10,
  labels: [],
  varieties: [],
}

export const REQUIRED_LOCATION_KEYS: ("latitude" | "longitude" | "name")[] = [
  "name",
  "latitude",
  "longitude",
]

export const MIN_LAT = -90
export const MAX_LAT = 90
export const MIN_LON = -180
export const MAX_LON = 180

export const LOCATION_BULK_CSV_HEADER_COLUMN_DATA = [
  {
    labelKey: "#",
    width: "w-[30px] text-gray-30",
    extraStyle: "",
    type: "number",
    required: true,
  },
  {
    labelKey: "locationName",
    width: "w-[124px]",
    extraStyle: " text-left pl-[5px]",
    type: "string",
    required: true,
  },
  {
    labelKey: "latitude",
    width: "w-[110px]",
    extraStyle: " text-left pl-[5px]",
    type: "number",
    required: true,
  },
  {
    labelKey: "longitude",
    width: "w-[110px]",
    extraStyle: " text-left pl-[5px]",
    type: "number",
    required: true,
  },
  {
    labelKey: "labels",
    width: "w-[136px]",
    extraStyle: " text-left pl-[5px]",
    type: "string",
    required: false,
  },
]
