import { useTranslate } from "@tolgee/react"
import { useEffect, useMemo, useState } from "react"
import { Button } from "../../../../climateui/components"
import { SingleSelect } from "../../../../climateui/components/Inputs"
import { CancelIcon, PlusIcon } from "../../../../climateui/icons"
import NumberInput from "../../../../components/NumberInput"
import { useAuth } from "../../../../providers"
import { useUnits } from "../../../../providers/UnitConversionProvider"
import { IVarietyAttribute, VarietyMetadataInput } from "../../../../types"

const VarietyAttributeInput = ({
  selected,
  setAttributes,
  options,
  value,
  attributes,
}: {
  selected: string
  attributes: Record<string, number | undefined>
  setAttributes: (attribute: Record<string, number | undefined>) => void
  options: Record<string, string>
  value?: number
}) => {
  const filteredOptions = useMemo(
    () =>
      Object.entries(options).reduce((prev, [key, val]) => {
        // Skip options already in the attributes list
        if (attributes[key] !== undefined && selected !== key) return prev
        return {
          ...prev,
          [key]: val,
        }
      }, {}),
    [attributes, options],
  )
  const filteredAttributes = useMemo(
    () =>
      Object.entries(attributes).reduce((prev, [key, val]) => {
        // Skip attribute if it is the current one
        if (key === selected) return prev
        return {
          ...prev,
          [key]: val,
        }
      }, {}),
    [attributes],
  )

  const removeAttribute = () => setAttributes(filteredAttributes)
  /* This is assuming that the default unit for these attributes is
   * Celcius/Fahrenheit. Once we support fully custom units, this needs to
   * change */
  const { user } = useAuth()
  const getUnit = () => (user?.unit_type ? "°C" : "°F")
  const { convertUnits } = useUnits()
  const convert = (value: number, direction: "convertUnits" | "resetUnits") =>
    convertUnits(value, { units_metric: "C", units_imperial: "F" }, direction)
  const convertedValue = value
    ? Number(convert(value, "convertUnits").toFixed(2))
    : undefined

  return (
    <div className="flex flex-wrap items-center w-full h-10 gap-1 md:flex-nowrap group">
      <div className="text-sm">
        <SingleSelect
          options={filteredOptions}
          selected={selected || Object.keys(filteredOptions)[0]}
          setSelected={(key) => {
            setAttributes({
              ...filteredAttributes,
              [key]: value,
            })
          }}
        />
      </div>
      <div className="h-full w-fit">
        <NumberInput
          suffix={getUnit()}
          initialValue={convertedValue}
          onChange={(_val) =>
            setAttributes({
              ...filteredAttributes,
              [selected]: _val ? convert(_val, "resetUnits") : undefined,
            })
          }
        />
      </div>
      <span
        className="invisible w-5 h-5 cursor-pointer shrink-0 hover:fill-accent-dark fill-accent group-hover:visible"
        onClick={removeAttribute}>
        <CancelIcon />
      </span>
    </div>
  )
}
const VarietyAttributesForm = ({
  initialAttributes,
  options,
  onChange,
}: {
  initialAttributes?: VarietyMetadataInput[]
  options: Record<string, IVarietyAttribute>
  onChange: (varietyMetadata: VarietyMetadataInput[]) => void
}) => {
  /* STATE > START */
  const [attributes, _setAttributes] = useState<
    Record<string, number | undefined>
  >({})
  const setAttributes = (_attributes: Record<string, number | undefined>) => {
    // Set the state
    _setAttributes(_attributes)
    // Convert attributes to VarietyMetadataInput[]
    const varietyMetadata = Object.entries(_attributes).reduce(
      (prev: VarietyMetadataInput[], [key, value]) => {
        return [...prev, { key, value: value?.toString() }]
      },
      [],
    )
    // Trigger onChange
    onChange(varietyMetadata)
  }
  /* STATE < END */
  /* HOOKS > START */
  const { t } = useTranslate()
  useEffect(() => {
    if (!initialAttributes) return // nothing to do...
    // Convert VarietyMetadataInput[] to attributes skipping NaNs
    _setAttributes(
      initialAttributes.reduce(
        (prev: Record<string, number | undefined>, { key, value }) => {
          let newVal: number | undefined = undefined
          if (value) {
            const numVal = parseFloat(value)
            if (!isNaN(numVal)) newVal = numVal
          }
          return {
            ...prev,
            [key]: newVal,
          }
        },
        {},
      ),
    )
  }, [initialAttributes])
  /* HOOKS < END */
  /* MEMO > START */
  const simplifiedOpts = useMemo(
    () =>
      Object.entries(options).reduce((prev, [key, attr]) => {
        return {
          ...prev,
          [key]: attr.name,
        }
      }, {}),
    [options, attributes],
  )
  const firstOption = useMemo(() => {
    const optsKeys = Object.keys(simplifiedOpts)
    // Create set for attributes keys
    const attributeKeys = new Set(Object.keys(attributes))
    for (const opt of optsKeys) {
      if (!attributeKeys.has(opt)) return opt
    }
    return ""
  }, [simplifiedOpts, attributes])
  /* MEMO < END */
  return (
    <div className="w-full p-2 border h-fit rounded-md border-gray-14 dark:border-gray-78">
      <div className="flex flex-wrap w-full gap-1">
        {Object.entries(attributes).map(([key, val]) => (
          <VarietyAttributeInput
            key={key}
            selected={key}
            value={val}
            attributes={attributes}
            options={simplifiedOpts}
            setAttributes={setAttributes}
          />
        ))}
      </div>
      <Button
        icon={<PlusIcon />}
        type="tertiary"
        label={t("addAttribute", "Add Attribute")}
        disabled={!firstOption}
        onClick={() => {
          if (!firstOption) return
          setAttributes({
            ...attributes,
            [firstOption]: undefined,
          })
        }}
      />
    </div>
  )
}
export default VarietyAttributesForm
