import { IStage } from "../../../types"
import { formatYearAgnosticDate } from "../../../utils/dates"

interface YearRange {
  startYear: number
  endYear: number
}

interface StageDate {
  dateNum: number
  startNum: number
  endNum: number
}

function parseStageDates(
  firstStage: IStage,
  currentDateStr: string,
): StageDate {

  const startDateStr =  typeof firstStage.start_date === "string" ? firstStage.start_date : formatYearAgnosticDate(firstStage.start_date)
  const endDateStr =  typeof firstStage.end_date === "string" ? firstStage.end_date : formatYearAgnosticDate(firstStage.end_date)

  return {
    dateNum: parseInt(currentDateStr.replace("-", "")),
    startNum: parseInt(
      startDateStr.replace("-", ""),
    ),
    endNum: parseInt(
      endDateStr.replace("-", ""),
    ),
  }
}

function calculateYearRange(
  dates: StageDate,
  referenceDate: Date,
  isWrapping: boolean,
): YearRange {
  const endYear =
    dates.dateNum >= dates.endNum
      ? referenceDate.getFullYear()
      : referenceDate.getFullYear() - 1

  const startYear = isWrapping ? endYear - 1 : endYear

  return { startYear, endYear }
}

function handleInFirstStage(dates: StageDate, referenceDate: Date): YearRange {
  const endYear = referenceDate.getFullYear()
  const startYear = dates.startNum > dates.endNum ? endYear - 1 : endYear
  return { startYear, endYear }
}

function handleNextFirstStage(
  dates: StageDate,
  referenceDate: Date,
): YearRange {
  const endYear =
    dates.dateNum >= dates.endNum
      ? referenceDate.getFullYear() + 1
      : referenceDate.getFullYear()

  const startYear = dates.startNum > dates.endNum ? endYear - 1 : endYear

  return { startYear, endYear }
}

const isDateInRange = (date: string, ranges: string[][]) => {
  // Convert MM-DD to numeric format (MM * 100 + DD)
  const dateNum = parseInt(date.replace("-", ""))

  return ranges.some(([start, end]) => {
    const startNum = parseInt(start.replace("-", ""))
    const endNum = parseInt(end.replace("-", ""))

    return startNum <= endNum
      ? dateNum >= startNum && dateNum <= endNum // Normal case
      : dateNum >= startNum || dateNum <= endNum // Year-wrapping case
  })
}

const isClosestNextFirstStage = (
  date: string,
  stages: { start_date: string; is_first_stage: boolean }[],
): boolean => {
  const dateNum = parseInt(date.replace("-", ""), 10)

  // Convert stages to numerical format
  const stageNums = stages.map(({ start_date, is_first_stage }) => {

    const startDateStr =  typeof start_date === "string" ? start_date : formatYearAgnosticDate(start_date)

    return {
          start_date,
          startNum: parseInt(startDateStr.replace("-", ""), 10),
          is_first_stage,
        }
  })

  // Find future stages
  const futureStages = stageNums.filter((stage) => stage.startNum > dateNum)

  // Get the closest next stage
  const nextStage =
    futureStages.length > 0
      ? [...futureStages].sort((a, b) => a.startNum - b.startNum)[0] // Get closest future date
      : stageNums.reduce(
          (min, stage) => (stage.startNum < min.startNum ? stage : min),
          stageNums[0],
        ) // Wrap to earliest stage

  return nextStage.is_first_stage
}

export function determineFirstStageYear(
  stages: IStage[],
  referenceDate: Date,
): YearRange {
  const firstStage = stages.find((s) => s.is_first_stage)
  if (!firstStage) {
    throw new Error("No first stage found")
  }
  const currentDateStr = formatYearAgnosticDate(referenceDate)

  // Check if we're currently in a stage
  const stageRanges = stages.map(
    (stage) => {
      const startDateStr =  typeof stage.start_date === "string" ? stage.start_date : formatYearAgnosticDate(stage.start_date)
      const endDateStr =  typeof stage.end_date === "string" ? stage.end_date : formatYearAgnosticDate(stage.end_date)

      return [startDateStr, endDateStr]
    },
  )
  const inStage = isDateInRange(currentDateStr, stageRanges)

  if (inStage) {
    const dates = parseStageDates(firstStage, currentDateStr)

    // Check if we're currently in the first stage
    const inFirstStage =
      dates.startNum <= dates.endNum
        ? dates.dateNum >= dates.startNum && dates.dateNum <= dates.endNum
        : dates.dateNum >= dates.startNum || dates.dateNum <= dates.endNum

    if (inFirstStage) {
      return handleInFirstStage(dates, referenceDate)
    }

    return calculateYearRange(
      dates,
      referenceDate,
      dates.startNum > dates.endNum,
    )
  }

  // Case B: We are between stages
  const isNextFirst = isClosestNextFirstStage(
    currentDateStr,
    stages.map((s) => ({
      start_date: s.start_date as unknown as string,
      is_first_stage: s.is_first_stage ?? false,
    })),
  )

  const dates = parseStageDates(firstStage, currentDateStr)

  return isNextFirst
    ? handleNextFirstStage(dates, referenceDate)
    : calculateYearRange(dates, referenceDate, dates.startNum > dates.endNum)
}
