import WidgetWrapper from "../../../../../climateui/components/Widgets/WidgetWrapper/WidgetWrapper"
import { gql } from "graphql-request"
import WidgetDefaultProps from "./WidgetDefaultProps"
import { useTranslate } from "@tolgee/react"
import AlertSummaryTableWidget from "./AlertSummaryTableWidget"

const SELECTED_FILTERS = [
  "unit_system",
  "time_resolution",
  "risk_settings_ids",
  "location_ids",
  "category",
]

function AlertsTableWidgetWrapper(props: WidgetDefaultProps) {
  const { id, onLoad, onMount, selectors, dashboardFilters } = props
  const { t } = useTranslate()

  return (
    <WidgetWrapper
      id={id}
      onLoad={onLoad}
      onMount={onMount}
      selectedFilters={SELECTED_FILTERS}
      component={AlertSummaryTableWidget}
      query={gql`
        query (
          $location_ids: [String]
          $risk_settings_ids: [String]
          $time_resolution: String
          $unit_system: String
          $category: String
        ) {
          alerts(
            filter: {
              location_ids: $location_ids
              risk_settings_ids: $risk_settings_ids
              time_resolution: $time_resolution
              category: $category
            }
          ) {
            results {
              processing_run {
                location {
                  name
                  id
                }
                risk_setting_id
                risk_profile {
                  name
                  type
                  hazard_profiles {
                    results {
                      hazard_variable {
                        readable_variable
                        dashboard_variable
                        readable_name
                        units(unit_system: $unit_system)
                      }
                      type
                      conditional
                      threshold(unit_system: $unit_system)
                      window
                    }
                  }
                  varieties {
                    results {
                      name
                      asset {
                        name
                      }
                    }
                  }
                }
              }
              created_at
              start_date
              duration
              end_date
            }
          }
        }
      `}
      selectors={{
        ...selectors,
        $data: "alerts.results",
        errorMessage: t("errorLoadingData"),
        reloadMessage: t("clickReload"),
        noResultsMessage: t("noResultCurrentFilters"),
        tryAnotherFilterMessage: t("tryAnotherFilter"),
        columns: [
          {
            propName: "location",
            header: t("location"),
            type: "link",
            selector: {
              text: "{{ processing_run.location.name }}",
              href: "/seasonal/dashboards/location?locId={{processing_run.location.id}}",
            },
          },
          {
            propName: "risk",
            header: t("riskProfileTriggered"),
            type: "text",
            selector: {
              text: "{{ processing_run.risk_profile.name }}",
            },
          },
          {
            propName: "period",
            header: t("periodTrigger"),
            type: "text",
            selector: {
              text: "{{ Date(start_date) }} - {{ Date(end_date) }}",
            },
          },
        ],
      }}
      filters={dashboardFilters}
    />
  )
}

export default AlertsTableWidgetWrapper
