/* eslint-disable @typescript-eslint/no-explicit-any */
import { Customized, ReferenceLine } from "recharts"
import { TooltipV2 } from "../../../../climateui/components"
import { InfoIcon } from "../../../../climateui/icons"

const LEFT_PADDING = 60

export interface RenderRiskChartReferencesProps {
  lowerBound?: number
  upperBound?: number
}

const renderRiskChartReferences = ({
  lowerBound,
  upperBound,
}: RenderRiskChartReferencesProps) => {
  if (!lowerBound || !upperBound) {
    return null
  }

  return (
    <>
      {/* Reference Lines for Risk Outloks */}
      <Customized
        component={({ yAxisMap }: any) => {
          const y1 = yAxisMap[0].scale(0)

          return (
            <g>
              <text
                x={LEFT_PADDING}
                y={y1}
                textAnchor="start"
                className="text-gray-60 dark:text-white"
                fill="currentColor"
                fontSize={12}>
                Low
              </text>
            </g>
          )
        }}
      />
      <ReferenceLine
        y={lowerBound}
        stroke={"#aaa"}
        strokeDasharray={"3 3"}
      />
      <Customized
        component={({ yAxisMap }: any) => {
          const y1 = yAxisMap[0].scale(lowerBound)
          const y2 = yAxisMap[0].scale(upperBound)
          const middleY = (y1 + y2) / 2

          return (
            <g>
              <text
                x={LEFT_PADDING}
                y={middleY + 4}
                textAnchor="start"
                className="text-gray-60 dark:text-white"
                fill="currentColor"
                fontSize={12}>
                Medium
              </text>
            </g>
          )
        }}
      />
      <ReferenceLine
        y={upperBound}
        stroke={"#aaa"}
        strokeDasharray={"3 3"}
      />
      <Customized
        component={({ yAxisMap }: any) => {
          const y2 = yAxisMap[0].scale(100)

          return (
            <g>
              <text
                x={LEFT_PADDING}
                y={y2 + 12}
                textAnchor="start"
                className="text-gray-60 dark:text-white"
                fill="currentColor"
                fontSize={12}>
                High
              </text>
            </g>
          )
        }}
      />

      {/* Reference Lines */}
      <Customized
        component={(payload: any) => {
          return (
            <>
              {/* upper bound reference */}
              <line
                x1={LEFT_PADDING - 10}
                y1={payload.yAxisMap[0].scale(100)}
                x2={LEFT_PADDING - 10}
                y2={payload.yAxisMap[0].scale(upperBound)}
                stroke="#FF0000"
                strokeWidth={4}
              />
              {/* middle bound reference */}
              <line
                x1={LEFT_PADDING - 10}
                y1={payload.yAxisMap[0].scale(upperBound)}
                x2={LEFT_PADDING - 10}
                y2={payload.yAxisMap[0].scale(lowerBound)}
                stroke="#FFE602"
                strokeWidth={4}
              />
              {/* lower bound reference */}
              <line
                x1={LEFT_PADDING - 10}
                y1={payload.yAxisMap[0].scale(lowerBound)}
                x2={LEFT_PADDING - 10}
                y2={payload.yAxisMap[0].scale(0)}
                stroke="#11C602"
                strokeWidth={4}
              />
            </>
          )
        }}
      />

      <Customized
        component={({ yAxisMap }: any) => {
          const y1 = yAxisMap[0].scale(100)
          const y2 = yAxisMap[0].scale(0)
          const middleY = (y1 + y2) / 2

          return (
            <g>
              <text
                x={10}
                y={middleY}
                textAnchor="middle"
                className="text-gray-90 dark:text-white"
                fill="currentColor"
                fontWeight={500}
                fontSize={14}
                origin={0}
                transform={`rotate(-90, 10, ${middleY - 20})`}>
                Risk Index
              </text>
              <foreignObject
                x={12.5}
                y={middleY + 15}
                width={20}
                height={20}>
                <TooltipV2
                  position="top"
                  contentClass="w-[182px]"
                  content={
                    <>
                      Our severity levels are based on the frequency of events,
                      as compared to historical extremes:
                      <br />
                      <br />
                      · High: Conditions more extreme than a once-in-10-year
                      event.
                      <br />
                      <br />
                      · Medium: Conditions more severe than a once-in-4-year
                      event.
                      <br />
                      <br />· Low/None: Conditions that are not considered
                      severe.
                    </>
                  }>
                  <div className="w-[18px] h-[18px] ml-1 fill-gray-60 dark:fill-white cursor-pointer">
                    <InfoIcon />
                  </div>
                </TooltipV2>
              </foreignObject>
            </g>
          )
        }}
      />
    </>
  )
}

export default renderRiskChartReferences
