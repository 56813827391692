import { NavLink } from "react-router-dom"

const TopBarOption = ({ option }) => {
  return (
    <li>
      <NavLink
        className={({ isActive }) =>
          [
            "flex flex-row items-center justify-center",
            "transition-all px-[12px] h-[38px]",
            "text-gray-3",
            "rounded-md relative",
            isActive
              ? "font-bold bg-white/[18%] pointer-events-none"
              : "hover:bg-white/[6%]",
          ].join(" ")
        }
        id={option.path}
        to={option.path}>
        <div className="body-md">{option.label}</div>
      </NavLink>
    </li>
  )
}

function TopBar({ left, options, right }) {
  return (
    <div className="w-full border-b bg-topbar dark:bg-topbar-dark border-topbar gap-1 print:hidden">
      <div className="flex flex-row items-center justify-between w-full pl-2 pr-1 h-14 gap-6">
        <div className="flex flex-row items-center gap-[8px]">
          {left}
          <ul className="flex-row items-center hidden sm:flex gap-2">
            {options.map((option) => (
              <TopBarOption
                key={"option-" + option.path}
                option={option}
              />
            ))}
          </ul>
        </div>
        {right}
      </div>
      <div className="flex flex-row flex-wrap items-center pt-1 pb-2 justify-evenly sm:hidden">
        {options.map((option) => (
          <TopBarOption
            key={"option-" + option.path}
            option={option}
          />
        ))}
      </div>
    </div>
  )
}

export default TopBar
