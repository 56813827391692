import { Cell, ColumnDef } from "@tanstack/react-table"
import { useTranslate } from "@tolgee/react"
import { DateTime } from "luxon"
import { ReactNode } from "react"
import { TooltipV2 } from "../../../climateui/components"
import { arrToDict } from "../../../climateui/utils/transform"
import {
  IDecadalStat,
  isDecadalStat,
  ISeasonalStat,
  IYieldOutlookResponse,
} from "../../../types"
import { NOON } from "../../../utils/dates"

export const HEADER_DATE_FORMAT = "MMM d"
export const INIT_DATE_FORMAT = "y-MM-dd"
export const CELL_FALLBACK_VALUE = "-"
export const DEFAULT_WEEKS_NUM = 6

export interface ITerritory {
  id: string
  name: string
}
export type RollingOutlookData = {
  territory: { id: string; name: string }
} & Record<string, number>

export const formatter = Intl.NumberFormat(undefined, {
  maximumFractionDigits: 1,
})

export const YieldReportSiteCell = ({
  cell,
}: {
  cell: Cell<RollingOutlookData, ReactNode>
}) => {
  const value = cell.getValue()
  return <div className="p-2">{value}</div>
}
export const YieldReportCell = ({
  cell,
}: {
  cell: Cell<RollingOutlookData, ReactNode>
}) => {
  const _value = cell.getValue()
  let value
  switch (typeof _value) {
    case "number":
      value = formatter.format(_value)
      break
    case "string":
      value = _value
      break
    case "undefined":
    default:
      value = CELL_FALLBACK_VALUE
      break
  }
  return <div className="p-2">{value}</div>
}

export const dateToColumn = (
  date: DateTime,
): ColumnDef<RollingOutlookData, ReactNode> => ({
  id: date.toISO(),
  accessorKey: date.toFormat(INIT_DATE_FORMAT),
  header: date.toFormat(HEADER_DATE_FORMAT),
  cell: YieldReportCell,
})

export const getColumnDates = (
  outlookDate: DateTime,
  weeks: number,
): DateTime[] => {
  const totalWeeksRange = [...Array(weeks).keys()].reverse()
  return totalWeeksRange.map((weekOffset) =>
    outlookDate.minus({ weeks: weekOffset }),
  )
}

export const getMostCurrentReportDate = () =>
  DateTime.now().set(NOON).toUTC().startOf("week")

export const CurrentReportHeader = () => {
  const { t } = useTranslate()
  const date = getMostCurrentReportDate()
  return (
    <div>
      <TooltipV2
        position="top"
        content={date.toFormat(HEADER_DATE_FORMAT)}>
        {t("currentReport", "Current Report")}
      </TooltipV2>
    </div>
  )
}

const getIndexedStats = <Stat extends ISeasonalStat | IDecadalStat>(
  stats: Stat[],
) => {
  if (stats.length === 0) return {}
  const keyProp = isDecadalStat(stats[0]) ? "decade" : "init_time"
  return arrToDict(stats, keyProp) as Record<string, Stat>
}

export const getIndexedData = <StatType extends ISeasonalStat | IDecadalStat>(
  outlooks: IYieldOutlookResponse<StatType>[],
) =>
  outlooks.reduce(
    (indexedData, outlook) => {
      const key = outlook.territory.id
      if (key) {
        indexedData[key] = {
          stats: {
            ...indexedData[key]?.stats,
            ...getIndexedStats(outlook.stats.results),
          },
          territory: outlook.territory,
        }
      }
      return indexedData
    },
    {} as Record<
      string,
      {
        territory: ITerritory
        stats: Record<string, StatType>
      }
    >,
  )
