import React from "react"
import TooltipV2 from "../../../../climateui/components/TooltipV2"
import InfoIcon from "../../../../climateui/icons/InfoIcon"
import { getHazardIcon } from "../riskOutlookUtils"
import ChartLegend, { LegendInterface } from "./ChartLegend"

interface RiskOutlookChartHeaderProps {
  title?: string
  tooltip?: string
  icon?: string
  description?: string
  legends?: LegendInterface[]
  actions?: {
    text: string
    onClick: () => void
  }[]
}

const RiskOutlookChartHeader: React.FC<RiskOutlookChartHeaderProps> = ({
  title,
  tooltip,
  icon,
  description,
  legends,
  actions,
}) => {
  const IconComponent = icon ? getHazardIcon(icon) : null

  return (
    <>
      <div className="flex items-center mt-2 mb-2">
        {icon && IconComponent && (
          <span className="ml-4 mr-2 w-6 h-6 fill-gray-60 dark:fill-white">
            <IconComponent />
          </span>
        )}
        <p className="label-lg text-gray-78 dark:text-white">{title}</p>
        {tooltip && (
          <TooltipV2
            position="top"
            contentClass="w-[182px]"
            content={<>{tooltip}</>}>
            <div className="w-[18px] h-[18px] ml-1 fill-gray-60 dark:fill-white cursor-pointer">
              <InfoIcon />
            </div>
          </TooltipV2>
        )}
      </div>
      {description && (
        <div className="pl-12 mb-2.5 text-left text-sm text-gray-60 dark:text-gray-30">
          {description}
        </div>
      )}
      <ChartLegend legends={legends} />
      <div className="flex justify-end items-center pt-1">
        {actions &&
          actions.map((action) => {
            return (
              <button
                key={`chart_header_action_${action.text}`}
                onClick={action.onClick}
                className="text-accent text-sm">
                {action.text}
              </button>
            )
          })}
      </div>
    </>
  )
}

export default RiskOutlookChartHeader
