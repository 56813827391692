import { useTranslate } from "@tolgee/react"
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { PortalComponent, Tooltip } from "../../climateui/components"
import {
  AsteriskIcon,
  CollapseAllIcon,
  ExpandAllIcon,
} from "../../climateui/icons"
import { useRiskProfiles } from "../../providers/RiskProfilesProvider"
import { IRiskProfile, ITimelineRow, ITimelineStage } from "../../types"
import { DAY_MS } from "../../utils/constants"
import { TODAY_1900 } from "../../utils/dates"
import AddEditStage from "./components/AddEditStage"
import DragDate from "./components/DragDate"
import PeriodTimeline from "./components/PeriodTimeline"
import TimelineRow from "./components/TimelineRow"

const DAY_START_OF_WEEK = 1 // Monday
const PX_PER_DAY = 3.17

function weeksPassed(date: Date) {
  const startDate = new Date(date.getFullYear(), 0, 1)
  const days = Math.floor((date.getTime() - startDate.getTime()) / DAY_MS)

  return Math.ceil(days / 7)
}

interface ITimelineProvider {
  weekNumbers: number[]
  months: Date[]
  weeksOffset: number
  monthsOffset: number
  timelineStartDate: Date

  pxPerDay: number

  openRows: Record<string, boolean>
  setOpenRows: React.Dispatch<React.SetStateAction<Record<string, boolean>>>

  renderRowHeaders: (row: ITimelineRow) => ReactNode
  renderRowItem: (
    row: ITimelineRow,
    setRow: (row: Partial<ITimelineRow>) => void,
    riskProfile: IRiskProfile,
    accountRiskProfilesObj: Record<string, IRiskProfile>,
  ) => ReactNode

  isEditing: boolean

  dragAuxDate?: Date
  setDragAuxDate: React.Dispatch<React.SetStateAction<Date | undefined>>

  focusedStage?: string
  setFocusedStage: React.Dispatch<React.SetStateAction<string | undefined>>

  editingStage?: ITimelineStage
  setEditingStage: React.Dispatch<
    React.SetStateAction<ITimelineStage | undefined>
  >

  rows?: ITimelineRow[]
  setRows?: React.Dispatch<React.SetStateAction<ITimelineRow[]>>

  canCollapse: boolean
  isYearAgnostic: boolean

  riskProfileOptsObj: Record<string, IRiskProfile>
  riskProfileOpts: IRiskProfile[]
}

const TimelineContext = createContext({} as ITimelineProvider)

export const useTimeline = () => useContext(TimelineContext)

interface ITimeline {
  startDate?: Date
  headers: ReactNode
  subheaders?: ReactNode
  renderRowHeaders: (row: ITimelineRow) => ReactNode
  renderRowItem: (
    row: ITimelineRow,
    setRow: (row: Partial<ITimelineRow>) => void,
    riskProfile: IRiskProfile,
    accountRiskProfilesObj: Record<string, IRiskProfile>,
  ) => ReactNode
  rows?: ITimelineRow[]
  setRows?: React.Dispatch<React.SetStateAction<ITimelineRow[]>>
  isEditing?: boolean
  canCollapse?: boolean
  isYearAgnostic?: boolean
  timelineSubtitlePortal?: string
  suggestedRiskProfiles?: IRiskProfile[]
  isPrepopulated?: boolean
}

function Timeline({
  startDate = TODAY_1900,
  headers,
  subheaders,
  rows,
  setRows,
  renderRowHeaders,
  renderRowItem,
  isEditing = true,
  canCollapse = false,
  isYearAgnostic = true,
  timelineSubtitlePortal,
  suggestedRiskProfiles = [],
  isPrepopulated = false,
}: ITimeline) {
  const { t } = useTranslate()

  const [lastUIWeeklyTimeline, setLastUIWeeklyTimeline] =
    useState<ReactNode>(null)
  const [openRows, setOpenRows] = useState<Record<string, boolean>>({})
  const [dragAuxDate, setDragAuxDate] = useState<Date>()
  const [pxPerDay, setPxPerDay] = useState<number>(PX_PER_DAY)
  const [focusedStage, setFocusedStage] = useState<string>()
  const [editingStage, setEditingStage] = useState<ITimelineStage>()

  const { riskProfiles } = useRiskProfiles()

  const { riskProfileOptsObj, riskProfileOpts } = useMemo(() => {
    const result = [...riskProfiles, ...suggestedRiskProfiles]

    return {
      riskProfileOpts: result,
      riskProfileOptsObj: result.reduce((acc, curr) => {
        return { ...acc, [curr.id]: curr }
      }, {}),
    }
  }, [riskProfiles, suggestedRiskProfiles])

  useEffect(() => {
    // TODO: Find a less erizo way of doing this:
    // Always display the last UI WeeklyTimeline at the bottom
    // Tried:
    // - using order-last class (added it anyway in case 200 ms are not enough for some cases)
    setLastUIWeeklyTimeline(null)
    const timeout = setTimeout(() => {
      setLastUIWeeklyTimeline(
        <PortalComponent portalId="timelinePortal">
          <div className="order-last grow shrink">
            {/* <WeeklyTimeline /> */}
            <PeriodTimeline />
          </div>
        </PortalComponent>,
      )
    }, 100)
    return () => clearTimeout(timeout)
  }, [rows?.length])

  const { weekNumbers, months, weeksOffset, monthsOffset } = useMemo(() => {
    let weeksCount = weeksPassed(startDate)
    const year = startDate.getFullYear()
    const month = startDate.getMonth()
    const day = startDate.getDate()

    const weekNumbers: number[] = []
    const months: Date[] = []
    let weeksOffset = 0
    let monthsOffset = 0

    for (let i = 0; i < 365; i++) {
      const currDay = new Date(year, month, day + i)

      // WEEKS LOGIC
      if (currDay.getDay() === DAY_START_OF_WEEK) {
        // Push the number of the week without capping it to 52 weeks
        // That logic is made on render
        weekNumbers.push(weeksCount + 1)
        weeksCount++
      }
      if (weekNumbers.length === 0) {
        weeksOffset++
      }

      // MONTHS LOGIC
      if (currDay.getDate() === 1) {
        months.push(currDay)
      }
      if (months.length === 0) {
        monthsOffset++
      }
    }
    return {
      weekNumbers,
      months,
      weeksOffset,
      monthsOffset,
    }
  }, [startDate])

  const allRowsOpen = false

  const providerValue = useMemo(() => {
    return {
      isEditing,
      weekNumbers,
      weeksOffset,
      months,
      monthsOffset,
      timelineStartDate: startDate,
      pxPerDay,
      openRows,
      setOpenRows,
      renderRowHeaders,
      renderRowItem,
      dragAuxDate,
      setDragAuxDate,
      focusedStage,
      setFocusedStage,
      editingStage,
      setEditingStage,
      rows,
      setRows,
      canCollapse,
      isYearAgnostic,
      riskProfileOptsObj,
      riskProfileOpts,
    }
  }, [
    weekNumbers,
    weeksOffset,
    months,
    monthsOffset,
    startDate,
    pxPerDay,
    openRows,
    renderRowHeaders,
    renderRowItem,
    dragAuxDate,
    focusedStage,
    editingStage,
    rows,
    canCollapse,
    isYearAgnostic,
    riskProfileOptsObj,
    riskProfileOpts,
  ])

  const timelineSubtitle = useMemo(() => {
    if (suggestedRiskProfiles.length === 0 && !isPrepopulated) return null
    if (suggestedRiskProfiles.length === 0) {
      return (
        <div className="flex-row gap-1 items-center flex-wrap inline-flex text-gray-60 dark:text-gray-30 body-md">
          <p>
            {t("timelineNotSavedYet", "This timeline has not been saved yet.")}
          </p>
        </div>
      )
    }

    return (
      <div className="flex-row gap-1 items-center flex-wrap inline-flex text-gray-60 dark:text-gray-30 body-md">
        <p>
          {t(
            "timelineSubtitleSuggestedRiskProfilesP1",
            "Timeline was pre filled with suggestions for the selected asset. Recommended risk profiles, marked with a",
          )}
        </p>
        <span className="fill-accent w-4 h-4">
          <AsteriskIcon />
        </span>
        <p>
          {t(
            "timelineSubtitleSuggestedRiskProfilesP2",
            "will be created after saving.",
          )}
        </p>
      </div>
    )
  }, [suggestedRiskProfiles])

  const calendarSectionRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleWindowResize() {
      const calBoundingRect =
        calendarSectionRef.current?.getBoundingClientRect()
      const bodyWidth = document.body.clientWidth
      const calLeft = (calBoundingRect?.left ?? 0) + 22 // 2 pixels gray border (normal 20)
      const newPxPerDay = (bodyWidth - calLeft) / 365

      setPxPerDay(Math.max(newPxPerDay, PX_PER_DAY))
    }
    handleWindowResize()

    window.addEventListener("resize", handleWindowResize)

    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  return (
    <TimelineContext.Provider value={providerValue}>
      <div className="relative flex flex-row items-stretch h-full shrink-0 w-max bg-light-bg dark:bg-dark-bg">
        {/* LEFT NAV SECTION */}
        <div
          className={[
            "sticky left-0 z-60",
            "flex flex-col shrink-0 grow-0",
          ].join(" ")}>
          {/* Header row */}
          <div className="sticky top-0 z-40 border-b border-gray-14 dark:border-gray-78 box-border">
            <div
              className={[
                "flex flex-row items-center justify-start",
                "h-12 pl-1 pr-4",
                "border-b border-r",
                "bg-gray-3 dark:bg-gray-88 label-lg text-light-text dark:text-dark-text border-gray-14 dark:border-gray-78",
              ].join(" ")}>
              <div
                className={
                  "w-5 h-5 mr-2 fill-gray-60 " +
                  (!canCollapse ? "invisible pointer-events-none" : "")
                }
                onClick={() => null}>
                <Tooltip
                  content={
                    allRowsOpen
                      ? t("collapseAll", {
                          gender: "female",
                        })
                      : t("expandAll", {
                          gender: "female",
                        })
                  }
                  position="right">
                  {allRowsOpen ? <CollapseAllIcon /> : <ExpandAllIcon />}
                </Tooltip>
              </div>
              {headers}
            </div>
            <div className="w-full pr-4 bg-light-bg dark:bg-dark-bg border-r h-7 border-gray-14 dark:border-gray-78 body-sm text-gray-60 dark:text-gray-30">
              {subheaders}
            </div>
          </div>

          {/* Timeline rows */}
          <div className="flex flex-col bg-light-bg dark:bg-dark-bg border-r divide-y divide-gray-14 dark:divide-gray-78 border-gray-14 dark:border-gray-78 grow">
            {rows &&
              rows.map((row, index) => (
                <TimelineRow
                  key={row.id}
                  index={index}
                  row={row}
                />
              ))}
            <div className="bg-light-bg dark:bg-dark-bg grow shrink"></div>
          </div>
        </div>

        <div
          className="flex flex-row h-fit items-stretch bg-light-bg dark:bg-dark-bg"
          style={{
            width: (isEditing ? 28 : 0) + pxPerDay * 365 + "px",
            contain: "paint",
          }}>
          {/* CALENDAR LEFT "PADDING" SECTION */}
          {isEditing && (
            <div className="flex flex-col w-7 grow-0 shrink-0 bg-light-bg dark:bg-dark-bg">
              <div className="sticky top-0 z-50 grow-0 shrink-0">
                <div className="w-full h-12 border-b bg-gray-3 dark:bg-gray-88 border-gray-14 dark:border-gray-78"></div>
                <div className="w-full border-b h-7 border-gray-14 dark:border-gray-78 box-content"></div>
              </div>
              <div className="grow border-b border-gray-14 dark:border-gray-78"></div>
            </div>
          )}

          {/* ACTUAL CALENDAR SECTION */}
          <div
            className="flex flex-col text-center bg-light-bg dark:bg-dark-bg text-light-text dark:text-dark-text"
            ref={calendarSectionRef}>
            <div className="sticky top-0 z-50 border-b border-gray-14 dark:border-gray-78 box-border">
              {/* Months row */}
              <div className="flex flex-row items-center h-12 divide-x divide-gray-14 dark:divide-gray-78">
                <div className="flex flex-row items-center justify-center h-full border-b min-w-0 shrink grow bg-gray-3 dark:bg-gray-88 border-gray-14 dark:border-gray-78 font-medium">
                  Days from start
                </div>
              </div>

              {/* Weeks row */}
              <div className="h-7">
                {/* <WeeklyTimeline showWeekNumber={true} /> */}
                <PeriodTimeline
                  daysPerPeriod={10}
                  showPeriodNumber={true}
                />
              </div>
            </div>

            {/* Calendar content portal */}
            <div
              className="relative flex flex-col grow divide-y divide-gray-14 dark:divide-gray-78"
              style={{
                width: pxPerDay * 365 + "px",
              }}
              id="timelinePortal">
              <DragDate />
            </div>

            {/* Last WeeklyTimeline to show lines at the bottom (just UI) */}
            {lastUIWeeklyTimeline}
          </div>
        </div>
      </div>

      {editingStage && <AddEditStage />}

      {timelineSubtitlePortal && (
        <PortalComponent portalId={timelineSubtitlePortal}>
          {timelineSubtitle}
        </PortalComponent>
      )}
    </TimelineContext.Provider>
  )
}

export default Timeline
