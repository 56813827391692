import select from "@gizt/selector"
import fastHash from "fast-hash-code"
import _ from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"

import { array, func, object, string } from "prop-types"
import getGraphqlClient from "../../../utils/http/graphqlClient"

function WidgetWrapper(props) {
    const {
        id = "",
        query: _query,
        // url: _url,
        selectors: _selectors = {},
        component: Component = () => null,
        filters: _filters = [],
        actions,
        selectedFilters, // string [] | undefined
        onMount = () => undefined,
        onLoad = () => undefined,
    } = props

    // context: baseURL, visible, filters

    const [query, setQuery] = useState()
    const [filters, setFilters] = useState()
    const [selectors, setSelectors] = useState()
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const reloadWidget = useCallback(
        (query, filters) => {
            if (error) setError(false)
            setLoading(true)

            const abortController = new AbortController()
            const client = getGraphqlClient()

            // generate the variables from the filters
            const variables = filters.reduce((prev, curr) => {
                if (!curr.loading) prev[curr.propName] = curr.value

                return prev
            }, {})

            client
                .request({
                    document: query,
                    variables: variables,
                    signal: abortController.signal,
                })
                .then((response) => {
                    setError(false)
                    setLoading(false)
                    setData(response)
                    onLoad(id)
                })
                .catch((err) => {
                    if (
                        err instanceof DOMException &&
                        err.name == "AbortError"
                    ) {
                        // request was cancelled, widget is reloading
                        // don't show error
                        setError(false)
                    } else {
                        onLoad(id)
                        setError(true)
                        setLoading(false)
                    }
                })

            return { client, abortController }
        },
        [error]
    )

    // reload query if props change
    useEffect(() => {
        setQuery(_query)
    }, [fastHash(JSON.stringify(_query ?? null))])

    // reload query if selectors change
    useEffect(() => {
        setSelectors(_selectors)
    }, [fastHash(JSON.stringify(_selectors ?? null))])

    // reload filters if props change
    useEffect(() => {
        if (!selectedFilters) {
            setFilters(_filters)
            return
        }
        // Create a copy of _filters using the selectedFilters
        const filtersSelection = _filters.filter(({ propName }) =>
            selectedFilters.includes(propName)
        )

        // Compare the filters selection to the previously rendered option
        if (_.isEqual(filtersSelection, filters)) return

        // Set the filters if they're indeed different
        setFilters(filtersSelection)
    }, [_filters, selectedFilters])

    useEffect(() => {
        // registerPromiseFn()
        onMount(id)
    }, [])

    // reload widget data if query changes
    useEffect(() => {
        // filter are still loading, don't make the request
        if (!query || filters?.some((f) => f.loading)) return

        // remount in case the filters change
        onMount(id)
        const { abortController } = reloadWidget(query, filters)

        return () => {
            // handle cancel query on unmount
            abortController.abort()
        }
    }, [
        fastHash(JSON.stringify(query ?? null)),
        fastHash(JSON.stringify(filters ?? null)),
    ])

    // notify dashboard that widget has successfully load
    useEffect(() => {
        if (!loading && Object.keys(data).length > 0) {
            const widgetLoadingEvent = new CustomEvent("widget-done-loading")
            document.dispatchEvent(widgetLoadingEvent)
        }
    }, [data, loading])

    const { res: args, parsingErrors } = useMemo(() => {
        const res = {}
        const parsingErrors = []
        for (let key in selectors) {
            if (key.startsWith("$")) {
                if (loading || !data) continue
                // compute using gizt
                try {
                    res[key.slice(1, key.length)] = select(selectors[key], data)
                } catch (e) {
                    parsingErrors.push(e)
                }
            } else res[key] = selectors[key]
        }

        return { res, parsingErrors }
    }, [data, query, selectors, loading])

    return (
        <Component
            {...args}
            loading={loading}
            error={error}
            empty={parsingErrors.length > 0}
            reload={reloadWidget}
            actions={actions}
            __query={query}
            __data={data}
            __selectors={selectors}
        />
    )
}

// *We could go one step further and also consider being more
// specific on propTypes, such as providing the specific keys in each
// Object or elements types in arrays

WidgetWrapper.propTypes = {
    id: string,
    query: string,
    selectors: object,
    component: func,
    selectedFilters: array,
    filters: array,
    actions: object,
    className: string,
    onMount: func,
    onLoad: func,
}

export default WidgetWrapper
