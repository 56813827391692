const svgs = [
  // Below
  `<svg width="39" height="44" viewBox="0 0 39 44" fill="none" xmlns="http://www.w3.org/2000/svg"> <g filter="url(#filter0_d_2000_3991)"> <path d="M29.745 6.36754C23.8555 0.544154 14.3067 0.544154 8.41713 6.36754C2.52762 12.1909 2.52762 21.6325 8.41713 27.4559L19.0811 38L29.745 27.4559C35.6345 21.6325 35.6345 12.1909 29.745 6.36754Z" fill="#E42437"/> <path d="M8.76868 6.72308C14.4635 1.09231 23.6987 1.09231 29.3935 6.72308C35.0851 12.3508 35.0851 21.4727 29.3935 27.1003L19.0811 37.2969L8.76868 27.1003C3.07711 21.4727 3.07711 12.3508 8.76868 6.72308Z" stroke="white" stroke-linejoin="round"/> </g> <path d="M18.1667 10.3334L18.1667 20.475L13.5084 15.8167L12.3334 17L19 23.6667L25.6667 17L24.4917 15.825L19.8334 20.475L19.8334 10.3334L18.1667 10.3334Z" fill="white"/> <defs> <filter id="filter0_d_2000_3991" x="0" y="0" width="38.1621" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"> <feFlood flood-opacity="0" result="BackgroundImageFix"/> <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/> <feOffset dy="2"/> <feGaussianBlur stdDeviation="2"/> <feComposite in2="hardAlpha" operator="out"/> <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/> <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2000_3991"/> <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2000_3991" result="shape"/> </filter> </defs> </svg> `,
  // Within
  `<svg width="39" height="44" viewBox="0 0 39 44" fill="none" xmlns="http://www.w3.org/2000/svg"> <g filter="url(#filter0_d_2000_3991)"> <path d="M29.745 6.36754C23.8555 0.544154 14.3067 0.544154 8.41713 6.36754C2.52762 12.1909 2.52762 21.6325 8.41713 27.4559L19.0811 38L29.745 27.4559C35.6345 21.6325 35.6345 12.1909 29.745 6.36754Z" fill="#FDB600"/> <path d="M8.76868 6.72308C14.4635 1.09231 23.6987 1.09231 29.3935 6.72308C35.0851 12.3508 35.0851 21.4727 29.3935 27.1003L19.0811 37.2969L8.76868 27.1003C3.07711 21.4727 3.07711 12.3508 8.76868 6.72308Z" stroke="white" stroke-linejoin="round"/> </g> <rect x="23.1667" y="20.3333" width="8.33333" height="1.66667" transform="rotate(-180 23.1667 20.3333)" fill="white"/> <rect x="23.1667" y="15.3333" width="8.33333" height="1.66667" transform="rotate(-180 23.1667 15.3333)" fill="white"/> <defs> <filter id="filter0_d_2000_3991" x="0" y="0" width="38.1621" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"> <feFlood flood-opacity="0" result="BackgroundImageFix"/> <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/> <feOffset dy="2"/> <feGaussianBlur stdDeviation="2"/> <feComposite in2="hardAlpha" operator="out"/> <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/> <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2000_3991"/> <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2000_3991" result="shape"/> </filter> </defs> </svg> `,
  // Above
  `<svg width="39" height="44" viewBox="0 0 39 44" fill="none" xmlns="http://www.w3.org/2000/svg"> <g filter="url(#filter0_d_2000_3991)"> <path d="M29.745 6.36754C23.8555 0.544154 14.3067 0.544154 8.41713 6.36754C2.52762 12.1909 2.52762 21.6325 8.41713 27.4559L19.0811 38L29.745 27.4559C35.6345 21.6325 35.6345 12.1909 29.745 6.36754Z" fill="#23AF41"/> <path d="M8.76868 6.72308C14.4635 1.09231 23.6987 1.09231 29.3935 6.72308C35.0851 12.3508 35.0851 21.4727 29.3935 27.1003L19.0811 37.2969L8.76868 27.1003C3.07711 21.4727 3.07711 12.3508 8.76868 6.72308Z" stroke="white" stroke-linejoin="round"/> </g> <path d="M19.8333 23.6666L19.8333 13.525L24.4916 18.1833L25.6666 17L19 10.3333L12.3333 17L13.5083 18.175L18.1666 13.525L18.1666 23.6666L19.8333 23.6666Z" fill="white"/> <defs> <filter id="filter0_d_2000_3991" x="0" y="0" width="38.1621" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"> <feFlood flood-opacity="0" result="BackgroundImageFix"/> <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/> <feOffset dy="2"/> <feGaussianBlur stdDeviation="2"/> <feComposite in2="hardAlpha" operator="out"/> <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/> <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2000_3991"/> <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2000_3991" result="shape"/> </filter> </defs> </svg>`,
  // No Signal
  `<svg width="39" height="44" viewBox="0 0 39 44" fill="none" xmlns="http://www.w3.org/2000/svg"> <g filter="url(#filter0_d_2000_3991)"> <path d="M29.745 6.36754C23.8555 0.544154 14.3067 0.544154 8.41713 6.36754C2.52762 12.1909 2.52762 21.6325 8.41713 27.4559L19.0811 38L29.745 27.4559C35.6345 21.6325 35.6345 12.1909 29.745 6.36754Z" fill="gray"/> <path d="M8.76868 6.72308C14.4635 1.09231 23.6987 1.09231 29.3935 6.72308C35.0851 12.3508 35.0851 21.4727 29.3935 27.1003L19.0811 37.2969L8.76868 27.1003C3.07711 21.4727 3.07711 12.3508 8.76868 6.72308Z" stroke="white" stroke-linejoin="round"/> </g>  <defs> <filter id="filter0_d_2000_3991" x="0" y="0" width="38.1621" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"> <feFlood flood-opacity="0" result="BackgroundImageFix"/> <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/> <feOffset dy="2"/> <feGaussianBlur stdDeviation="2"/> <feComposite in2="hardAlpha" operator="out"/> <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/> <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2000_3991"/> <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2000_3991" result="shape"/> </filter> </defs> </svg>`,
]
export const pinElements = svgs.map((svg) => {
  const pin = document.createElement("div")
  pin.innerHTML = svg
  return pin
})
