import { IPrimitivesDictionary } from "../../../../climateui/types"
import { IInsightsLocation } from "../../../../types"
import { locationPUT } from "../../../../utils/networking"

export const bulkUpdate = (
  locations: IInsightsLocation[],
  payload: IPrimitivesDictionary,
) => {
  const requests = locations.map((location) =>
    locationPUT({
      location_id: location.id,
      payload: {
        ...location,
        ...payload,
      },
    }),
  )
  return Promise.all(requests)
}

export const bulkUpdateLabels = (
  locations: IInsightsLocation[],
  labels: string[],
  replace?: boolean,
) => {
  if (replace) return bulkUpdate(locations, { labels })

  const requests = locations.map((location) => {
    const mergedLabels = new Set(
      location.labels
        .map((label) => {
          if (typeof label === "string") return null // To ignore
          return label.id
        })
        .filter((label) => label !== null), // Ignore that label COULD be a string
    )
    labels.forEach((labelID) => mergedLabels.add(labelID))
    return locationPUT({
      location_id: location.id,
      payload: {
        ...location,
        labels: Array.from(mergedLabels),
      },
    })
  })

  return Promise.all(requests)
}
