import { DateTime } from "luxon"
import { getCalendarAlertInfo } from "../../../utils/calendar"

const alertCategoryFillColor: Record<string, string> = {
    warning: "#BF1728",
    watch: "#CC9300",
}

interface AlertItemProps {
    item: any // The alert item data type
    granularityWidth: number
    startDate: DateTime
    endDate: DateTime
    toolTip: (item: any) => JSX.Element
    width: number
}

const AlertItem = ({
    item,
    granularityWidth,
    startDate,
    endDate,
    toolTip,
    width,
}: AlertItemProps) => {
    const {
        AlertIcon,
        alertCategory,
        alertShortDescription,
        fitsCompleteAlert,
        leftSpace,
        alertDaysLength,
        startsBeforeToday,
    } = getCalendarAlertInfo(item, startDate, endDate)

    return (
        <div
            className="absolute h-full group"
            style={{
                width: alertDaysLength * granularityWidth + "px",
                left: granularityWidth * leftSpace + "px",
            }}>
            <span
                style={{
                    left:
                        width - leftSpace * granularityWidth > 340 ? "20%" : "",
                    right:
                        width - leftSpace * granularityWidth < 340 ? "20%" : "",
                }}
                className="absolute z-60 bottom-6 hidden group-hover:block text-[14px] bg-light-bg dark:bg-dark-bg rounded-md border-[1px] border-gray-14 dark:border-gray-78 elevation-2">
                {toolTip(item)}
            </span>
            <div className="flex">
                <div className="w-[18px] h-[18px] min-w-[18px] min-h-[18px]">
                    <AlertIcon
                        className="mr-2"
                        fill={
                            alertCategoryFillColor[alertCategory as string] ||
                            "#666D74"
                        }
                    />
                </div>
                {alertDaysLength * granularityWidth > 30 && (
                    <h1 className="body-sm truncate ml-1 text-gray-60 dark:text-gray-30">
                        {alertShortDescription}
                    </h1>
                )}
            </div>
            <div
                className={`rounded-sm h-[12px] ${
                    !fitsCompleteAlert ? "rounded-r-none" : ""
                } ${startsBeforeToday ? "rounded-l-none" : ""}`}
                style={{
                    backgroundColor: item.color,
                }}></div>
        </div>
    )
}

export default AlertItem
