import {
  categoryToColor,
  getRiskCategoryCoding,
  RISK_BOUNDS,
} from "./riskOutlookUtils"
import { IRiskCountryOverviewModel } from "./types"
import { capitalizeFirstCharacterOfEachWord } from "../../../utils/wordHelper"

type IHazardStat =
  IRiskCountryOverviewModel["default_geography"]["hazards"][number]
type IStatResponseSeasonal = IHazardStat["seasonal_stats"]["results"][number]
type IStatResponseDaily = IHazardStat["daily_stats"]["results"][number]
type IStatResponse = IStatResponseDaily | IStatResponseSeasonal
export type RiskCategory = keyof typeof categoryToColor

export type IHazardScore = {
  label?: string
  index_value?: number
  probability_high?: number
  probability_medium?: number
  probability_low?: number
  forecast_contribution?: number
  observed_contribution?: number
}

export function getHazardStatValues(statResponseItem: IStatResponse) {
  const result: IHazardScore = {}
  let ref = null
  if ("data" in statResponseItem && statResponseItem["data"]) {
    result["label"] = statResponseItem.init_time
    result["probability_medium"] = statResponseItem.data.probability_mid
    ref = statResponseItem.data
  } else if ("index_value" in statResponseItem) {
    result["label"] = statResponseItem.season_label
    result["forecast_contribution"] = statResponseItem.forecast_contribution
    result["observed_contribution"] = statResponseItem.observed_contribution
    result["probability_medium"] = statResponseItem?.probability_medium
    ref = statResponseItem
  }
  result["index_value"] = ref?.index_value
  result["probability_high"] = ref?.probability_high
  result["probability_low"] = ref?.probability_low
  return result
}

export function getHighestRiskStats(
  hazards: IHazardStat[],
  getStatsFunc: (hazard: IHazardStat) => IHazardScore,
): null | {
  hazard_key: string
  hazard: string
  score: RiskCategory
  drivers: string[]
} {
  if (hazards.length === 0) return null

  const sortedHazards = [...hazards].sort((a, b) => {
    const aStats = getStatsFunc(a)
    const bStats = getStatsFunc(b)
    return (bStats.index_value ?? 0) - (aStats.index_value ?? 0)
  })

  const highestScore = getRiskCategoryCoding(
    getStatsFunc(sortedHazards[0]).index_value ?? 0,
    RISK_BOUNDS,
  )

  const drivers = sortedHazards
    .filter((hazard) => {
      const stats = getStatsFunc(hazard)
      return (
        getRiskCategoryCoding(stats.index_value ?? 0, RISK_BOUNDS) ===
        highestScore
      )
    })
    .map((hazard) => capitalizeFirstCharacterOfEachWord(hazard.hazard_name))

  return {
    hazard_key: sortedHazards[0].hazard_key,
    hazard: sortedHazards[0].hazard_name,
    score: highestScore,
    drivers,
  }
}

export function getUpcomingRiskStats(hazards: IHazardStat[]) {
  return getHighestRiskStats(hazards, (hazard) =>
    getHazardStatValues(hazard.daily_stats.results[0]),
  )
}

export function getCurrentFullSeasonStats(hazards: IHazardStat[], pos = 0) {
  return getHighestRiskStats(hazards, (hazard) =>
    getHazardStatValues(hazard.seasonal_stats.results[pos]),
  )
}
