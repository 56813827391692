import { ReactNode, useEffect, useMemo, useRef, useState } from "react"
import { useOutsideComponentClickHandler } from "../../hooks"
import { ArrowBottom, CancelIcon } from "../../icons"
import { IBooleanDictionary } from "../../types"
import Checkbox from "../Checkbox"
import DebounceSearchInput from "../DebounceSearchInput"
import { getDropdownSorter } from "../utils"
import { MIN_NUMBER_OF_OPTIONS_TO_ALLOW_SEARCH } from "./utils"

const MultiSelect = ({
    icon = null,
    selected = {},
    options = {},
    optionIcons = {},
    allOption,
    setSelected = () => null,
    textAlignClass = "text-left",
    placeholder = "Select an option",
    leftRightClass = "right-0",
    disabled = false,
    canSearch = false,
    searchPlaceholder = "Search",
    showOptionsUntilSearch = false,
    showSelectedOnTop = false,
    shouldWrapToTop = false,
    sortByKey = true,
    keepOrder = false,
}: {
    icon?: ReactNode
    selected?: IBooleanDictionary
    options: Record<string, string>
    optionIcons?: Record<string, ReactNode>
    allOption?: string
    setSelected: (obj: IBooleanDictionary) => void
    textAlignClass?: string
    placeholder: string
    leftRightClass?: string
    disabled?: boolean
    canSearch?: boolean
    searchPlaceholder: string
    showOptionsUntilSearch?: boolean
    showSelectedOnTop?: boolean
    shouldWrapToTop?: boolean
    sortByKey?: boolean
    keepOrder?: boolean
}) => {
    const [open, setOpen] = useState(false)
    const [searchText, setSearchText] = useState("")

    const dropdownRef = useOutsideComponentClickHandler(() => setOpen(false))
    const inputRef = useRef<HTMLInputElement | null>(null)
    const textRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        setSearchText("")
        if (open) inputRef?.current?.focus()
    }, [open])

    const selectedKeysList = Object.keys(selected)

    const dropdownSort = getDropdownSorter({
        selectedKeysList,
        options,
        keepOrder,
        sortByKey,
        showSelectedOnTop,
    })
    const optionsKeysList = Object.keys(options)
        .filter((key) => {
            const value = options[key]
            if (!value) return false
            return value
                .toLocaleLowerCase()
                .includes(searchText.toLocaleLowerCase())
        })
        .sort(dropdownSort)

    const allSelected =
        Object.keys(selected).length === Object.keys(options).length
    const someSelected = Object.keys(selected).length > 0
    let allCheckboxStatus = "empty"
    if (allSelected) allCheckboxStatus = "full"
    else if (someSelected) allCheckboxStatus = "half"

    const select = (key: string | number) => {
        if (key === "all") {
            if (allSelected) setSelected({})
            else {
                const newSelected: IBooleanDictionary = optionsKeysList.reduce(
                    (acc, curr) => ({ ...acc, [curr]: true }),
                    {} as IBooleanDictionary
                )
                setSelected(newSelected)
            }
        } else {
            const newSelected = { ...selected }
            if (newSelected[key]) delete newSelected[key]
            else newSelected[key] = true
            setSelected(newSelected)
        }
        const element = inputRef.current
        // Return if the element is not defined
        if (!element) return
        element.focus()
    }

    const clearSelected = () => {
        setSelected({})
        setOpen(false)
    }

    const canActuallySearch =
        canSearch &&
        Object.keys(options).length > MIN_NUMBER_OF_OPTIONS_TO_ALLOW_SEARCH

    const cardContainerRef = useRef<HTMLButtonElement>(null)

    const { positionClasses, positionStyle } = useMemo(() => {
        // BOTTOM Options ---------------------------------------------------------------
        let positionClasses = "max-h-[400px] top-full"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let positionStyle: any = {}

        if (shouldWrapToTop) {
            const screenHeight = window.innerHeight
            const containerBottom =
                cardContainerRef.current?.getBoundingClientRect().bottom || 400 // 400 is the dropdown max height
            const spaceToBottom = screenHeight - containerBottom - 24 // 24 is the screen padding
            if (spaceToBottom < 200) {
                // TOP Options ----------------------------------------------------------
                positionClasses = "max-h-[200px] bottom-full"
                positionStyle = {}
            } else if (spaceToBottom < 400) {
                // SHRINK Options -------------------------------------------------------
                positionClasses = "top-full"
                positionStyle = {
                    maxHeight: spaceToBottom + "px",
                }
            }
        }

        return {
            positionClasses,
            positionStyle,
        }
    }, [cardContainerRef, open])

    return (
        <div
            ref={dropdownRef}
            className="relative w-full min-w-0 select-none text-light-text dark:text-dark-text">
            <button
                ref={cardContainerRef}
                disabled={disabled}
                className={[
                    "flex flex-row items-center",
                    "bg-light-bg dark:bg-dark-bg relative",
                    "transition-all duration-75",
                    "h-[42px] min-w-[60px] pl-[8px] pr-[2px] w-fit max-w-[200px]",
                    "border-[1px] rounded-md",
                    "cursor-pointer disabled:cursor-not-allowed",
                    "focus:outline-accent hover:z-[1]",
                    "disabled:bg-gray-5 dark:disabled:bg-gray-60 disabled:text-gray-30 disabled:fill-gray-30",
                    "disabled:pointer-events-none",
                    textAlignClass,
                    open
                        ? "border-accent z-[1]"
                        : "border-gray-14 dark:border-gray-78 hover:enabled:border-gray-30",
                    selectedKeysList.length > 0
                        ? ""
                        : "text-gray-60 dark:text-gray-30 fill-gray-60",
                ].join(" ")}
                onClick={() => setOpen(!open)}>
                {icon !== null && (
                    <span className="shrink-0 grow-0 w-[20px] mr-1 -ml-1">
                        {icon}
                    </span>
                )}
                <div
                    className="truncate pointer-events-none body-lg grow whitespace-nowrap"
                    ref={textRef}>
                    {selectedKeysList.length > 0
                        ? selectedKeysList.map((key) => options[key]).join(", ")
                        : placeholder}
                </div>
                {selectedKeysList.length > 0 &&
                    textRef &&
                    textRef.current &&
                    textRef?.current?.offsetWidth <
                        textRef?.current?.scrollWidth && (
                        <div className="label-lg">
                            ({selectedKeysList.length})
                        </div>
                    )}
                <span
                    className={
                        "shrink-0 grow-0 w-[20px]" +
                        (selectedKeysList.length > 0 ? " ml-1" : "")
                    }>
                    {selectedKeysList.length > 0 ? (
                        <span
                            className="fill-gray-30 hover:fill-gray-60"
                            onClick={clearSelected}>
                            <CancelIcon />
                        </span>
                    ) : (
                        <ArrowBottom />
                    )}
                </span>
            </button>
            {open && (
                <div
                    className={[
                        "absolute",
                        leftRightClass,
                        "bg-light-bg dark:bg-dark-bg z-30 rounded-lg elevation-2",
                        "h-fit pb-2 overflow-y-auto",
                        "border-[1px] border-gray-14 dark:border-gray-78",
                        !canActuallySearch ? "pt-2" : "",
                        positionClasses,
                    ].join(" ")}
                    style={positionStyle}>
                    {canActuallySearch && (
                        <div
                            className={
                                "p-2 min-w-[160px] " +
                                (showOptionsUntilSearch && searchText === ""
                                    ? "pb-0"
                                    : "pb-1")
                            }>
                            <DebounceSearchInput
                                onSearch={setSearchText}
                                onSubmit={() => {
                                    if (
                                        optionsKeysList.length > 0 &&
                                        searchText !== ""
                                    ) {
                                        setSearchText("")
                                        if (inputRef && inputRef.current)
                                            inputRef.current.value = ""
                                        select(optionsKeysList[0])
                                    }
                                }}
                                ms={100}
                                autoFocus={false}
                                placeholder={searchPlaceholder}
                                onFocusOrTypeOrClick={() => null}
                                inputRef={inputRef}
                            />
                        </div>
                    )}
                    {(!showOptionsUntilSearch ||
                        (showOptionsUntilSearch && searchText !== "")) &&
                        (optionsKeysList.length > 0 ? (
                            <>
                                {allOption && (
                                    <div
                                        className={[
                                            "flex flex-row items-center gap-2",
                                            "h-[32px] px-2 py-1 min-w-[40px] w-full",
                                            "hover:bg-gray-3 dark:bg-gray-90 dark:hover:bg-gray-88 cursor-pointer",
                                            textAlignClass,
                                            allSelected ? "bg-gray-5" : "",
                                        ].join(" ")}
                                        onClick={() => select("all")}>
                                        <span className="w-[28px] h-[28px] shrink-0">
                                            <Checkbox
                                                id="option-all"
                                                status={allCheckboxStatus}
                                                onChange={() => null}
                                                disabled={false}
                                                extraWrapperClasses="pointer-events-none"
                                            />
                                        </span>
                                        <p className="w-full truncate pointer-events-none whitespace-nowrap body-md">
                                            {allOption}
                                        </p>
                                    </div>
                                )}
                                {optionsKeysList.map((key, index) => (
                                    <div
                                        key={key + "-" + index}
                                        className={[
                                            "flex flex-row items-center gap-2",
                                            "h-[32px] px-2 py-1 min-w-[40px] w-full",
                                            "hover:bg-gray-3 dark:bg-gray-90 dark:hover:bg-gray-88 cursor-pointer",
                                            textAlignClass,
                                            selected[key] ? "bg-gray-5" : "",
                                            searchText !== "" && index === 0
                                                ? "bg-gray-3 dark:bg-gray-88"
                                                : "",
                                        ].join(" ")}
                                        onClick={() => select(key)}>
                                        <span className="w-[28px] h-[28px] shrink-0">
                                            <Checkbox
                                                id={"option-" + key}
                                                checked={
                                                    selected[key] ? true : false
                                                }
                                                status={
                                                    selected[key]
                                                        ? "full"
                                                        : "empty"
                                                }
                                                onChange={() => null}
                                                disabled={false}
                                                extraWrapperClasses="pointer-events-none"
                                            />
                                        </span>
                                        <div className="flex flex-row items-center gap-1">
                                            {optionIcons[key] && (
                                                <span className="w-4 h-4 fill-gray-60 shrink-0">
                                                    {optionIcons[key]}
                                                </span>
                                            )}
                                            <p className="w-full truncate pointer-events-none whitespace-nowrap body-md">
                                                {options[key] as ReactNode}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <button
                                disabled
                                className={[
                                    "flex flex-row items-center",
                                    "h-[32px] px-2 my-1 min-w-[40px] w-full",
                                    "cursor-not-allowed",
                                    textAlignClass,
                                ].join(" ")}>
                                <p className="w-full truncate pointer-events-none whitespace-nowrap body-md text-gray-30">
                                    No available options
                                </p>
                            </button>
                        ))}
                </div>
            )}
        </div>
    )
}

export default MultiSelect
