import {
  MultiSelectFilter,
  SingleSelectFilter,
} from "../../../../../climateui/components"
import ToggleSelector, {
  TToggleOptions,
} from "../../../../../climateui/components/Inputs/ToggleSelector"
// import { IPrimitivesDictionary } from "../../../../../climateui/types"
// import { IVariable } from "../../../../../types"
import { useTranslate } from "@tolgee/react"

export interface IGenericDashboardFiltersProps {
  // asset / variety
  varietiesDict: Record<string, string>
  selectedVarieties: string[]
  setSelectedVarieties: (varietiesArray: string[]) => void
  // variable filter
  variablesDict: TToggleOptions
  selectedVariables: string[]
  updateSelectedVariables: (v: string) => void
  // scope filter
  selectedScope: string
  setSelectedScope: (scope: string) => void
  scopesDict: Record<string, string>
}

function GenericDashboardFilters(props: IGenericDashboardFiltersProps) {
  const {
    //
    setSelectedVarieties,
    varietiesDict,
    selectedVarieties,
    //
    variablesDict,
    selectedVariables,
    updateSelectedVariables,
    //
    selectedScope,
    setSelectedScope,
    scopesDict,
  } = props

  const { t } = useTranslate()

  return (
    <>
      {/* Asset / Variety filter */}
      <MultiSelectFilter
        filterName={t("asset")}
        canSearch={true}
        searchPlaceholder="Search assets"
        leftRightClass="left-0"
        placeholder={
          Object.keys(varietiesDict).length === 0
            ? t("noAssetAssigned", "No assets assigned")
            : t("asset")
        }
        setSelected={(v) => setSelectedVarieties(Object.keys(v))}
        selected={[...selectedVarieties].reduce(
          (prev, curr) => {
            if (curr) prev[curr] = true
            return prev
          },
          {} as { [key: string]: boolean },
        )}
        options={
          {
            ...varietiesDict,
          } as { [key: string]: string }
        }
        disabled={Object.keys(varietiesDict).length === 0}
      />
      {/* Variable filter */}
      <ToggleSelector
        options={variablesDict}
        selectedOptions={selectedVariables}
        updateSelectedOptions={updateSelectedVariables}
        showIcons={true}
      />
      {/* Scope filter */}
      <SingleSelectFilter
        placeholder="Scopes"
        selected={selectedScope}
        setSelected={(v) => setSelectedScope(v.toString())}
        leftRightClass="left-0"
        options={
          {
            ...scopesDict,
          } as { [key: string]: string }
        }
        canSearch={false}
        keepOrder
      />
    </>
  )
}

export default GenericDashboardFilters
