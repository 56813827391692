import { Outlet } from "react-router-dom"
import YieldOutlookProvider from "./provider"

const YieldOutlookView = () => {
  return (
    <YieldOutlookProvider>
      <Outlet />
    </YieldOutlookProvider>
  )
}

export default YieldOutlookView
