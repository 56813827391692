// import { useTranslate } from "@tolgee/react"
import { ReactNode, useContext, useEffect } from "react"

import {
  useProviderBase,
  YieldOutlookContext,
} from "../../../hooks/YieldOutlook/useProviderBase"
import { yieldOutlookQuerySet } from "../../../utils/networking/yield"
import { useUI } from "../../../providers/UIProvider"

export const useRiskOutlook = () => useContext(YieldOutlookContext)

const RiskOutlookProvider = ({
  children,
}: Readonly<{ children: ReactNode }>) => {
  /* PROVIDERS ------------------------------------------------------------ */
  // const { t } = useTranslate()
  /* HOOKS ---------------------------------------------------------------- */
  const providerValue = useProviderBase({
    querySet: yieldOutlookQuerySet,
    productKey: "seasonal_risk_outlook",
    navigateToFirstModel: false,
  })

  const { subRoutes } = providerValue
  const { setSecondSidebarOptions } = useUI()

  useEffect(() => {
    setSecondSidebarOptions((prev) => ({
      ...prev,
      seasonal: {
        ...prev["seasonal"],
        risk_outlook: {
          title: "Risk Outlook",
          routes: subRoutes,
          createRoutePath: (route) => {
            const basePath = "/seasonal/risk_outlook"
            if (!route) return basePath
            return (
              basePath + "/" + (typeof route === "string" ? route : route.path)
            )
          },
          emptyMsg: "",
          plusButtonTooltip: "",
        },
      },
    }))
  }, [subRoutes])

  return (
    <YieldOutlookContext.Provider value={providerValue}>
      {children}
    </YieldOutlookContext.Provider>
  )
}

export default RiskOutlookProvider
