import { Transition } from "@headlessui/react"
import { Fragment, ReactNode } from "react"

const TogglableRightSidePanel = ({
    doShow,
    onCancel,
    children,
    widthClass = "w-[340px]",
}: {
    doShow: boolean
    onCancel?: () => void
    children: ReactNode
    widthClass?: string
}) => {
    return (
        <Transition
            show={doShow}
            as={Fragment}>
            <div className="fixed inset-0 z-full">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-75"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div
                        className="fixed bg-[#000000]/[.33] inset-0"
                        onClick={onCancel}></div>
                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-200"
                    enterFrom="w-0"
                    enterTo={widthClass}
                    leave="ease-in duration-75"
                    leaveFrom={widthClass}
                    leaveTo="w-0">
                    <div
                        className={
                            "fixed bottom-0 top-0 right-0" +
                            " bg-light-bg dark:bg-dark-bg shadow-md" +
                            " transition-all duration-150 ease-out" +
                            " overflow-hidden overflow-y-auto" +
                            widthClass
                        }>
                        <div className={"h-full " + widthClass}>{children}</div>
                    </div>
                </Transition.Child>
            </div>
        </Transition>
    )
}

export default TogglableRightSidePanel
