import { Transition } from "@headlessui/react"
import { Fragment } from "react"
import { Button } from "../../components"

export interface IModal {
    title?: string
    text?: string
    type?: string
    onCancel?: () => null | void
    onContinue?: () => null | void
    onContinueLabel?: string
    onCancelLabel?: string
    closeModal?: () => null | void
    doGrayBG?: boolean
    isAbsolute?: boolean
}

const Modal = ({
    title = "",
    text = "",
    type = "",
    onCancel = () => null,
    onContinue = () => null,
    onContinueLabel = "Continue",
    onCancelLabel = "Cancel",
    closeModal = () => null,
    doGrayBG = false,
    isAbsolute = false,
}: IModal) => {
    const handleCancelModal = () => {
        onCancel()
        closeModal()
    }

    const handleContinueModal = () => {
        onContinue()
        closeModal()
    }

    return (
        <Transition
            show={title !== ""}
            as={Fragment}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-75"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div
                    className={[
                        "z-full inset-0 flex flex-row justify-center items-center",
                        doGrayBG ? "bg-gray-90/[.5]" : "",
                        isAbsolute ? "absolute" : "fixed",
                        type === "loading" ? "text-center px-4" : "",
                    ].join(" ")}>
                    <div className="bg-light-bg dark:bg-dark-bg rounded-lg w-full max-w-[380px] min-h-[200px] p-[24px] flex flex-col items-stretch justify-between elevation-2 space-y-[24px]">
                        <div>
                            <h6 className="title-lg text-light-text dark:text-dark-text leading-tight">
                                {title}
                            </h6>
                            <p
                                className="font-roboto text-[14px] text-gray-60 dark:text-gray-30 mt-[10px] leading-relaxed"
                                dangerouslySetInnerHTML={{ __html: text }}></p>
                        </div>
                        <div className="flex flex-row items-center space-x-[8px]">
                            {type === "loading" ? (
                                <div className="flex flex-row justify-center w-full pb-4">
                                    <span className="w-20 animate-pulse">
                                        Loading...
                                    </span>
                                </div>
                            ) : (
                                <>
                                    {type === "confirmation" && (
                                        <Button
                                            extraClasses="flex-1"
                                            label={onCancelLabel}
                                            type="secondary"
                                            onClick={handleCancelModal}
                                        />
                                    )}
                                    <Button
                                        extraClasses="flex-1"
                                        label={onContinueLabel}
                                        type="primary"
                                        onClick={handleContinueModal}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Transition.Child>
        </Transition>
    )
}

export default Modal
