import { ReactElement } from "react"
import { TooltipProps } from "recharts"
import {
    NameType,
    Payload,
    ValueType,
} from "recharts/types/component/DefaultTooltipContent"
import { TypeMapChart } from "./helpers"

export type TypeTooltip<TMetaData> = {
    id: string
    x: number | string | Array<number | string> | undefined
    y: number | string | Array<number | string> | undefined
    metadata: TMetaData
}

export const renderTooltip = <TMetaData,>(
    data: TypeTooltip<TMetaData>[],
    onTooltipRender: (value: string) => ReactElement
): JSX.Element => {
    return onTooltipRender("content")
}

export const validateTooltipProps = (
    props: TooltipProps<ValueType, NameType>
) => {
    const { payload } = props
    if (payload === undefined) {
        throw new Error("Tooltip payload is undefined")
    }
    return { ...props, payload }
}

export const buildTooltipPlotComponents = <TMetaData,>(
    payload: Payload<ValueType, NameType>[],
    plotComponentsMap: TypeMapChart<TMetaData>,
    xLabel: string
): TypeTooltip<TMetaData>[] => {
    const tooltipPlotComponents = payload.map((item) => {
        if (item.dataKey === undefined) {
            throw new Error("datakey is undefined")
        }
        const plotComponent = plotComponentsMap.get(item.dataKey.toString())

        if (plotComponent === undefined) {
            throw new Error(
                `plotComponent is undefined for datakey ${item.dataKey.toString()}`
            )
        }

        return {
            id: plotComponent.id,
            x: xLabel,
            y: item.value,
            metadata: plotComponent.metadata,
        }
    })
    return tooltipPlotComponents
}
