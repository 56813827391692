import {
  applyStyles,
  applyPageSettings,
  applyDashboardSettings,
} from "../whitelabel"

import styles from "./styles"
import page from "./page"
import dashboard from "./dashboard"

applyStyles(styles)
applyPageSettings(page)
applyDashboardSettings(dashboard)

const defaultSkin = {
  name: "ClimateAi",
  welcome_string: "Welcome!",
  status: "live",
  primary_color: "#F06000",
  top_nav_color: "#004957",
}

export default defaultSkin
