import { EditIcon } from "../../../climateui/icons"
import { RiskIconHandler } from "../../../climateui/icons/riskIcons"
import { ThresholdExtract } from "../../Admin/RiskProfiles/riskProfilesTableUtils"
import { IRiskProfileAndStage } from "../utils"

function RiskAndStageSuggestion({
  riskProfileAndStage,
  isOpen,
  onEdit,
}: {
  riskProfileAndStage: IRiskProfileAndStage
  isOpen: boolean
  onEdit?: () => void
}) {
  const { riskProfile, stageName } = riskProfileAndStage
  const hazardProfiles = riskProfile.hazard_profiles
  const rowClass = `body-md overflow-hidden [&_div]:transition-all [&_div]:overflow-hidden ${
    isOpen ? "[&_div]:h-16" : "[&_div]:h-0"
  }`

  return (
    <tr className={rowClass}>
      <td style={isOpen ? {} : { borderBottom: "0px" }}></td>
      <td style={isOpen ? {} : { borderBottom: "0px" }}>
        <div className="flex flex-row gap-x-3 items-center">
          <span className="w-5 h-5 fill-gray-60">
            <RiskIconHandler hazardProfiles={hazardProfiles} />
          </span>
          <p>{riskProfile.name}</p>
        </div>
      </td>
      <td style={isOpen ? {} : { borderBottom: "0px" }}>
        <div className="flex flex-col gap-1 w-full justify-center">
          {hazardProfiles.map((hazardProfile) => (
            <span
              className="w-fit"
              key={hazardProfile.id}>
              <ThresholdExtract
                hazardProfile={hazardProfile}
                opts={{ detailed: true }}
                riskType={riskProfile.type}
              />
            </span>
          ))}
        </div>
      </td>
      <td style={isOpen ? {} : { borderBottom: "0px" }}>
        <div className="flex flex-row items-center">{stageName}</div>
      </td>
      <td style={isOpen ? {} : { borderBottom: "0px" }}>
        {onEdit && (
          <div
            className="w-5 h-5 fill-gray-60 hover:fill-gray-30 cursor-pointer"
            onClick={onEdit}>
            <EditIcon />
          </div>
        )}
      </td>
    </tr>
  )
}

export default RiskAndStageSuggestion
