import { useTranslate } from "@tolgee/react"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { TableActions } from "../../../../climateui/components/Table/tableUtils"
import { EditIcon, TrashIcon } from "../../../../climateui/icons"
import { ModalContext } from "../../../../climateui/providers"
import { useAccount } from "../../../../providers/AccountProvider"
import { useDashboard } from "../../../../providers/DashboardProvider"
import { IDashboard } from "../../../../types"

const DashboardTableActions = ({ rowData }: { rowData: IDashboard }) => {
  const navigate = useNavigate()
  const { t } = useTranslate()
  const { confirmationModal } = useContext(ModalContext)
  const { selectedAccount } = useAccount()

  const { setWorkingDashboard, deleteDashboard } = useDashboard()

  return (
    <TableActions
      rowData={rowData}
      actions={[
        {
          icon: <EditIcon />,
          onClick: () => {
            if (selectedAccount === rowData.account_id) {
              setWorkingDashboard({ ...rowData })
              navigate(rowData.id + "/edit/locations")
            }
          },
          tooltip: t("editDashboard"),
        },
        {
          tooltip: t("deleteDashboard"),
          icon: <TrashIcon />,
          onClick: () => {
            confirmationModal({
              title: t(
                "areYouSureDeleteDashboard",
                "Delete this custom dashboard?",
              ),
              text: t(
                "dashboardWillBeDeletedPermanently",
                "This dashboard will be deleted permanently.",
              ),
              onContinueLabel: t("continue"),
              onCancelLabel: t("cancel"),
              onContinue: () => {
                deleteDashboard(rowData)
              },
            })
          },
        },
      ]}
    />
  )
}

export default DashboardTableActions
