import { useTranslate } from "@tolgee/react"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { TemplateModal } from "."
import { LabelAndInput } from "../../../../../climateui/components"
import { GenericInput } from "../../../../../climateui/components/Inputs"
import { ModalContext } from "../../../../../climateui/providers"
import { IBooleanDictionary } from "../../../../../climateui/types"
import { useAssets } from "../../../../../providers"
import { varietyMetadataToInput } from "../../../../../types"

const EditDescription = ({
  isOpen,
  setIsOpen,
  selectedVarieties,
  setSelectedVarieties,
}: {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  selectedVarieties: IBooleanDictionary
  setSelectedVarieties: Dispatch<SetStateAction<IBooleanDictionary>>
}) => {
  /* HOOKS > START */
  const { confirmationModal } = useContext(ModalContext)
  const { t } = useTranslate()
  const { varieties, editVariety } = useAssets()
  /* HOOKS < END */

  /* STATE > START */
  const [newDescription, setNewDescription] = useState<string>()
  /* STATE < END */

  /* BULK EDIT DESCRIPTION > START */
  const applyDescription = () =>
    Promise.all(
      Object.keys(selectedVarieties).map((id) => {
        if (!varieties?.[id]) return
        const { variety_metadata, account_id, asset_id, name } = varieties[id]
        return editVariety({
          id,
          variety: {
            asset_id,
            account_id,
            name,
            description: newDescription,
            variety_metadata: variety_metadata.map(varietyMetadataToInput),
          },
        })
      }),
    )
  /* BULK EDIT DESCRIPTION < END */

  return (
    <TemplateModal
      title={t("bulkEditDescription", "Bulk Edit Description")}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      cleanUp={() => setNewDescription("")}
      setSelected={setSelectedVarieties}
      selected={selectedVarieties}
      onConfirm={() =>
        new Promise<void>((res) => {
          confirmationModal({
            title: t("overwriteAllDesc", "Overwrite all descriptions"),
            text: t(
              "areYouSureOverwriteAllDesc",
              "Are you sure you want to overwrite all these descriptions?",
            ),
            onContinue: () => {
              applyDescription().then(() => res())
            },
          })
        })
      }>
      <LabelAndInput
        label={t("description", "Description")}
        input={
          <GenericInput
            value={newDescription}
            handleChange={(ev) => setNewDescription(ev.target.value)}
          />
        }
      />
    </TemplateModal>
  )
}
export default EditDescription
