import { gql } from "graphql-request"
import { useMemo } from "react"
import GenericChartWidget from "../../climateui/components/Widgets/SeasonalWidgets/GenericChartWidget"
import WidgetWrapper from "../../climateui/components/Widgets/WidgetWrapper/WidgetWrapper"
import { IYieldOutlookWidgetProps } from "./types"
const queries = {
  adapt: gql`
    query (
      $scenario: String
      $region_ids: [String]
      $asset_ids: [String]
      $configs: [PlotConfig]
      $minDecade: Int
      $maxDecade: Int
    ) {
      yield_outlook_model(
        filter: { region_ids: $region_ids, asset_ids: $asset_ids }
      ) {
        results {
          stats: decadal_stats(
            filter: {
              minDecade: $minDecade
              maxDecade: $maxDecade
              scenario: $scenario
            }
          ) {
            plot(configs: $configs) {
              visualization
              color
              opacity
              points {
                id
                date
                value
                y
                y0
                y1
                directionality
                meta
              }
            }
          }
        }
      }
    }
  `,
  monitor: ``,
}

const YieldOutlookChart = ({
  filters,
  type,
  selectors,
}: IYieldOutlookWidgetProps) => {
  const selectedQuery = useMemo(() => queries[type], [type])
  return (
    <div className="w-full mb-[14px]">
      <WidgetWrapper
        component={GenericChartWidget}
        query={selectedQuery}
        filters={filters}
        selectors={selectors}
      />
    </div>
  )
}

export default YieldOutlookChart
