import { useMemo } from "react"
import { buildCSVString } from "../utils"

export default function useCSVData(
  formattedData: (string | number)[][],
  csvHeaders: string[],
  csvHeaderSection: string,
  isReady: boolean,
) {
  return useMemo(() => {
    if (!formattedData || !isReady) {
      return {
        csvHeaders: [],
        csvData: [],
        csvString: "",
      }
    }

    const csvString = buildCSVString(
      csvHeaderSection,
      csvHeaders,
      formattedData,
    )

    return {
      csvHeaders,
      csvData: formattedData,
      csvString,
    }
  }, [formattedData, csvHeaders, isReady])
}
