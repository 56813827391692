import { useTranslate } from "@tolgee/react"
import { useEffect, useMemo, useState } from "react"
import { LabelAndInput } from "../../../../climateui/components"
import {
  defaultInputClasses,
  MultiDropdownSelect,
} from "../../../../climateui/components/Inputs"
import { ICoordinates } from "../../../../climateui/types"
import { isValidResponse } from "../../../../climateui/utils/http"
import { StartDateInput } from "../../../../components"
import { useCoordsRegionQuery } from "../../../../hooks"
import { useAssets, useLocations } from "../../../../providers"
import { useLabels } from "../../../../providers/LabelsProvider"
import { areSameLocation } from "../../../../providers/LocationsProvider"
import {
  IInsightsLocation,
  ILocationEssentials,
  ILocationVariety,
  IVariety,
} from "../../../../types"
import { EMPTY_LOCATION, REQUIRED_LOCATION_KEYS } from "../LocationsUtil"
import {
  getDateFromAgnosticDate,
  getInitialDate,
  isEmptyInitialDate,
} from "../utils/varietyDates"
import LabelPicker from "./LabelPicker"

export default function LocationForm({
  location,
  changeCallback,
  mapPinCoords,
  setMapPinCoords,
  assetsInputDisabled = false,
  lockLatLon = false,
}: {
  location: ILocationEssentials
  changeCallback: (isValid: boolean, location: ILocationEssentials) => void
  mapPinCoords: ICoordinates
  setMapPinCoords: (mapPinCoords: ICoordinates) => void
  assetsInputDisabled?: boolean
  lockLatLon?: boolean
}) {
  const { t } = useTranslate()
  const { locations } = useLocations()
  const { labels, queryLabels } = useLabels()
  const { varietiesOptions, varieties, caiDefaultVarieties } = useAssets()

  const [selectedVarieties, setSelectedVarieties] = useState<
    Record<string, ILocationVariety>
  >({})

  const [editableLocation, setEditableLocation] = useState<ILocationEssentials>(
    {
      ...EMPTY_LOCATION,
    },
  )

  const { data, isLoading, isFetching } = useCoordsRegionQuery({
    lat: editableLocation.latitude,
    lon: editableLocation.longitude,
  })

  const regionName = useMemo(() => {
    if (isLoading || isFetching) return t("loading", "Loading...")

    if (!data) return ""
    if (!isValidResponse(data)) return ""

    return data.data.full_name
  }, [data, isLoading, isFetching])

  const isReqPropValid = (prop: "latitude" | "longitude" | "name") => {
    if (
      editableLocation[prop] === "" ||
      editableLocation[prop] === null ||
      editableLocation[prop] === undefined
    ) {
      return false
    }
    return true
  }

  // Returns existing location if duplicate is found
  // undefined if location does not exist
  const checkIfLocationIsDuplicate = (
    location: Pick<IInsightsLocation, "latitude" | "longitude">,
  ): IInsightsLocation | undefined => {
    return locations.find((loc) =>
      areSameLocation(
        {
          lat: location.latitude,
          lon: location.longitude,
        },
        {
          lat: loc.latitude,
          lon: loc.longitude,
        },
      ),
    )
  }

  const duplicatedLocation = useMemo(() => {
    const sameCoordsLoc = checkIfLocationIsDuplicate({
      latitude: mapPinCoords.lat,
      longitude: mapPinCoords.lon,
    })
    if (editableLocation.id === sameCoordsLoc?.id) return undefined

    return sameCoordsLoc
  }, [mapPinCoords])

  const isFormValid = () => {
    const result = { isValid: true, error: "" }

    // Check required fields
    for (const reqKey of REQUIRED_LOCATION_KEYS) {
      if (!isReqPropValid(reqKey)) {
        result.isValid = false
        result.error =
          result.error !== "" ? result.error + ", " + reqKey : reqKey
      }
    }

    // Check for duplicate location
    if (duplicatedLocation) result.isValid = false

    // Check for empty dates in selected varieties
    const hasEmptyDates = Object.values(selectedVarieties).some((variety) =>
      isEmptyInitialDate(variety.initial_date),
    )
    if (hasEmptyDates) {
      result.isValid = false
    }

    return result
  }

  // Editable Location is sent up to the AddEditLocationsView component
  // to be used for validation and to be saved
  useEffect(() => {
    changeCallback(isFormValid().isValid, { ...editableLocation })
  }, [editableLocation])

  // useEffect used for first time config of the state
  useEffect(() => {
    // selected location as editableLocation
    setEditableLocation({ ...location })

    // map config
    setMapPinCoords({ lat: location.latitude, lon: location.longitude })

    // varieties config
    const newSelectedVarieties: Record<string, ILocationVariety> = {}
    location.location_varieties?.forEach((locVariety) => {
      newSelectedVarieties[locVariety.variety_id] = locVariety
    })
    setSelectedVarieties(newSelectedVarieties)
  }, [])

  // When mapPinCoords changes above, update editableLocation here in the form
  useEffect(() => {
    setEditableLocation((prev) => ({
      ...prev,
      latitude: mapPinCoords.lat,
      longitude: mapPinCoords.lon,
    }))
  }, [mapPinCoords])

  // When selectedVarieties changes, append to editableLocation
  useEffect(() => {
    const newVarieties: IVariety[] = []
    Object.keys(selectedVarieties).forEach((key) => {
      if (varieties?.[key]) newVarieties.push(varieties[key])
    })
    setEditableLocation((prev) => ({
      ...prev,
      varieties: newVarieties,
      location_varieties: Object.values(selectedVarieties),
    }))
  }, [selectedVarieties])

  const constrainCoords = (value: number, constraint: number) => {
    return Math.min(Math.max(value, -constraint), constraint)
  }

  const handlePropInputChange = (value: number | string, prop: string) => {
    if (prop === "latitude" || prop === "longitude") {
      const newVal = constrainCoords(
        value as number,
        prop === "latitude" ? 90 : 180,
      )
      setMapPinCoords({
        ...mapPinCoords,
        [prop === "latitude" ? "lat" : "lon"]: newVal,
      })
      setEditableLocation({
        ...editableLocation,
        [prop]: newVal,
      })
    } else {
      setEditableLocation({
        ...editableLocation,
        [prop]: value,
      })
    }
  }

  // Set up placeholder text for assets dropdown
  // Default to "Select asset (Optional)"
  let assetsPlaceholder = t("selectAssetOptional", "Select asset (Optional)")
  const selectedAssetsCount = Object.keys(selectedVarieties).length
  const totalAssetsCount = Object.keys(varietiesOptions).length

  // If all assets are selected, show "All assets selected"
  if (selectedAssetsCount === totalAssetsCount)
    assetsPlaceholder = t("allAssetsSelected", "All assets selected")
  // If some assets are selected, show their names as comma-separated list
  else if (selectedAssetsCount > 0 && varieties)
    assetsPlaceholder = Object.keys(selectedVarieties)
      .map((varietyId) => {
        // Try to find variety in main varieties object
        let variety: IVariety | undefined = varieties[varietyId]
        if (variety) return `${variety.asset.name} (${variety.name})`

        // If not found, check default varieties
        variety = caiDefaultVarieties?.[varietyId]
        if (variety) return `${variety.asset.name} (${variety.name})`
        throw new Error("No Variety found")
      })
      .join(", ")

  return (
    <div className="mt-3 mb-4 space-y-4">
      <div className="flex flex-row flex-wrap items-center w-full gap-2">
        <LabelAndInput
          label={t("latitude")}
          extraClasses="flex-1 min-w-[100px]"
          input={
            <input
              onChange={(e) =>
                handlePropInputChange(e.target.value, "latitude")
              }
              value={mapPinCoords.lat}
              type="number"
              placeholder="12.34567"
              className={[...defaultInputClasses, "min-h-[36px] h-fit"].join(
                " ",
              )}
              disabled={lockLatLon}
            />
          }
        />
        <LabelAndInput
          label={t("longitude")}
          extraClasses="flex-1 min-w-[100px]"
          input={
            <input
              onChange={(e) =>
                handlePropInputChange(e.target.value, "longitude")
              }
              value={mapPinCoords.lon}
              type="number"
              placeholder="-87.65432"
              className={[...defaultInputClasses, "min-h-[36px] h-fit"].join(
                " ",
              )}
              disabled={lockLatLon}
            />
          }
        />

        {duplicatedLocation && (
          <div className="body-sm text-red">
            {t(
              "sameCoordsAsLOCATION",
              'Same coordinates as location "{location}"',
              {
                location: duplicatedLocation.name,
              },
            )}
          </div>
        )}
      </div>

      <LabelAndInput
        label={t("region")}
        input={
          <input
            value={regionName}
            type="text"
            disabled
            className={[...defaultInputClasses, "min-h-[36px] h-fit"].join(" ")}
          />
        }
      />

      <LabelAndInput
        label={t("locationName")}
        input={
          <input
            onChange={(e) => handlePropInputChange(e.target.value, "name")}
            value={editableLocation.name}
            type="text"
            className={[...defaultInputClasses, "min-h-[36px] h-fit"].join(" ")}
          />
        }
      />

      <LabelAndInput
        label={t("labels")}
        input={
          <div
            className={[...defaultInputClasses, "min-h-[36px] h-fit"].join(
              " ",
            )}>
            <LabelPicker
              labels={labels}
              refreshLabels={queryLabels}
              isNewLocation
              addLabelToLocCallback={(location) => {
                setEditableLocation({ ...location })
              }}
              locationData={{
                ...editableLocation,
              }}
              modifiable
            />
          </div>
        }
      />

      <LabelAndInput
        label={t("assets", "Assets")}
        extraClasses={[
          "[&_.ddBox]:h-[36px]",
          "[&_.ddBox]:text-left",
          "[&_.ddBox]:w-full",
          "[&_.ddBox]:rounded-md", // ddBox to style inner DropdownSelects
        ].join(" ")}
        input={
          <MultiDropdownSelect
            canSearch
            disabled={assetsInputDisabled}
            placeholder={assetsPlaceholder}
            searchPlaceholder={t("searchAsset", "Search Asset")}
            options={varietiesOptions}
            selected={Object.keys(selectedVarieties).reduce(
              (acc, key) => ({ ...acc, [key]: true }),
              {},
            )}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setSelected={(newSelected: any) => {
              const newSelectedVarieties: Record<string, ILocationVariety> = {}
              Object.entries(newSelected).forEach(([varietyId, isSelected]) => {
                if (isSelected) {
                  newSelectedVarieties[varietyId] = {
                    location_id: editableLocation.id ?? "",
                    variety_id: varietyId,
                    initial_date: getInitialDate(
                      varieties?.[varietyId]?.default_initial_date,
                    ),
                  }
                }
              })
              setSelectedVarieties(newSelectedVarieties)
            }}
          />
        }
      />

      {Object.keys(selectedVarieties).length > 0 && (
        <div className="mt-4 overflow-x-auto">
          <table className="relative w-full border-separate table-auto text-light-text dark:text-dark-text border-spacing-0">
            <thead className="font-bold label-lg bg-gray-3 dark:bg-gray-88">
              <tr>
                <th className="h-12 text-[14px] border-b border-gray-14 dark:border-gray-78 pl-1 text-left">
                  {t("asset", "Asset")}
                </th>
                <th className="h-12 text-[14px] border-b border-gray-14 dark:border-gray-78 pl-1 text-left">
                  {t("startDay", "Start Day")}
                </th>
              </tr>
            </thead>
            <tbody className="font-normal body-lg">
              {Object.entries(selectedVarieties).map(
                ([varietyId, locationVariety]) => {
                  const variety = varieties?.[varietyId]
                  if (!variety) return null

                  return (
                    <tr
                      key={varietyId}
                      className="text-[14px] group bg-light-bg dark:bg-dark-bg hover:bg-gray-3 dark:bg-gray-90 dark:hover:bg-gray-88">
                      <td className="h-16 text-[14px] border-b border-gray-14 dark:border-gray-78 pl-1">
                        {`${variety.asset.name} (${variety.name})`}
                      </td>
                      <td className="h-16 text-[14px] border-b border-gray-14 dark:border-gray-78 pl-1">
                        <StartDateInput
                          date={getDateFromAgnosticDate(
                            getInitialDate(locationVariety.initial_date),
                          )}
                          setDate={(date) => {
                            const newLocationVariety = {
                              ...locationVariety,
                              initial_date: date
                                ? `${String(date.getMonth() + 1).padStart(
                                    2,
                                    "0",
                                  )}-${String(date.getDate()).padStart(2, "0")}`
                                : locationVariety.initial_date,
                            }
                            setSelectedVarieties((prev) => ({
                              ...prev,
                              [varietyId]: newLocationVariety,
                            }))
                          }}
                          isInvalid={isEmptyInitialDate(
                            locationVariety.initial_date,
                          )}
                        />
                      </td>
                    </tr>
                  )
                },
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
