import { FC } from "react"
import { IconProps } from "./types"

export const LineIcon: FC<IconProps> = ({ backgroundColor, opacity }) => {
    return (
        <div
            style={{
                backgroundColor: backgroundColor,
                opacity: opacity,
            }}
            className="w-5 h-1 mx-2 border-0 rounded"
        />
    )
}
