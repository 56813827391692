import { useTranslate } from "@tolgee/react"
import { ReactNode, useContext, useEffect } from "react"
import { useUI } from "../../../providers/UIProvider"
import { yieldOutlookQuerySet } from "../../../utils/networking/yield"
import {
  useProviderBase,
  YieldOutlookContext,
} from "../../../hooks/YieldOutlook/useProviderBase"

export const useYieldOutlook = () => useContext(YieldOutlookContext)

const YieldOutlookProvider = ({
  children,
}: Readonly<{ children: ReactNode }>) => {
  /* PROVIDERS ------------------------------------------------------------ */
  const { t } = useTranslate()

  const { setSecondSidebarOptions } = useUI()
  /* HOOKS ---------------------------------------------------------------- */
  const providerValue = useProviderBase({
    querySet: yieldOutlookQuerySet,
    productKey: "seasonal_yield_outlook",
    navigateToFirstModel: false,
  })

  const { subRoutes } = providerValue
  /* LIFECYCLE HOOKS ------------------------------------------------------ */
  useEffect(() => {
    setSecondSidebarOptions((prev) => ({
      ...prev,
      seasonal: {
        ...prev["seasonal"],
        outlook: {
          title: t("yieldOutlook", "Yield Outlook"),
          createRoutePath: (route) => {
            const basePath = "/seasonal/outlook"
            if (!route) return basePath
            return `${basePath}/${
              typeof route === "string" ? route : route.path
            }`
          },
          routes: subRoutes,
          emptyMsg: "",
          plusButtonTooltip: "",
        },
      },
    }))
  }, [subRoutes])
  return (
    <YieldOutlookContext.Provider value={providerValue}>
      {children}
    </YieldOutlookContext.Provider>
  )
}

export default YieldOutlookProvider
