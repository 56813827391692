import { DefaultParamType, TFnType } from "@tolgee/react"
import { DateTime } from "luxon"
import { NavigateFunction } from "react-router-dom"
import {
  IAlert,
  IHazardProfile,
  IHazardVariable,
  IInsightsLocation,
  ILocationVariety,
  IRegion,
  IVariety,
  IVarietyStage,
} from "../../../types"
import { IPanelContent } from "./components/SidePanel"

const probabilityLabels: { [key: string]: string } = {
  "0-39": "Unlikely",
  "40-59": "Possible",
  "60-84": "Likely",
  "85-94": "Very likely",
  "95-97": "Extremely likely",
  "98-100": "Virtually certain",
}

const magnitudeLabels: { [key: string]: string } = {
  "0-39": "Below Marginal",
  "40-74": "Marginal",
  "75-89": "Significant",
  "90-95": "Major",
  "96-100": "Extreme",
}

export enum Scope {
  daily = 0,
  weekly = 1,
  monthly = 2,
}

export type TimeResolutionValues = keyof typeof Scope

export const getFirstRelevantDate = (
  alerts: Pick<IAlert, "start_date" | "end_date">[],
) => {
  const today = DateTime.now().startOf("day")
  if (alerts.length === 0) return today
  return (
    alerts.reduce(
      (firstDate: DateTime | undefined, { start_date, end_date }) => {
        const startDate = DateTime.fromISO(start_date)
        const endDate = DateTime.fromISO(end_date)
        if (endDate < today) return firstDate
        if (startDate <= today) return today
        if (!firstDate || firstDate > startDate) return startDate
        return firstDate
      },
      undefined,
    ) || today
  )
}

export const getLastRelevantDate = (
  alerts: Pick<IAlert, "start_date" | "end_date">[],
) => {
  const today = DateTime.now().startOf("day")
  if (alerts.length === 0) return today
  return (
    alerts.reduce((lastDate: DateTime | undefined, { end_date }) => {
      const endDate = DateTime.fromISO(end_date)
      if (endDate < today) return lastDate
      if (!lastDate || lastDate < endDate) return endDate
      return lastDate
    }, undefined) || today
  )
}

export const getProbabilityLabel = (probability: number) => {
  const roundedProbability = Math.round(probability)
  const rangeKey = Object.keys(probabilityLabels).find((range) => {
    const [min, max] = range.split("-").map(Number)
    return roundedProbability >= min && roundedProbability <= max
  })
  return rangeKey ? probabilityLabels[rangeKey] : "Unknown Probability"
}

export const getMagnitudeLabel = (percentile: number): string => {
  const persentil = Math.round(percentile * 100)
  const label = Object.keys(magnitudeLabels).find((range) => {
    const [min, max] = range.split("-").map(Number)
    return persentil >= min && persentil <= max
  })
  return label ? magnitudeLabels[label] : "Unknown percentile"
}

export const getMagnitudeDescription = (
  expectedMagnitudes: number[],
  hazardProfiles: IHazardProfile[],
  t: TFnType<DefaultParamType>,
  getUnit: (variable: IHazardVariable) => string,
) => {
  return expectedMagnitudes
    .map(
      (magnitude, index) =>
        magnitude.toFixed(1) +
        (hazardProfiles[index]?.hazard_variable.units ??
          getUnit(hazardProfiles[index]?.hazard_variable)),
    )
    .join(", ")
}

export const formatPercentile = (percentile: number): string => {
  const percentileValue = Math.round(percentile * 100)
  let suffix = "th"
  const exceptions = [11, 12, 13]
  const lastDigit = percentileValue % 10

  if (
    !exceptions.includes(percentileValue % 100) &&
    lastDigit >= 1 &&
    lastDigit <= 3
  ) {
    suffix = ["st", "nd", "rd"][lastDigit - 1]
  }
  return `${percentileValue}${suffix}`
}

export const formatConditionDescription = (
  translateFn: TFnType<DefaultParamType>,
  riskProfileType: string,
  name: string,
  conditionalSymbol: string,
  duration: number,
  threshold: string,
  units: string,
) => {
  let conditionalWord = ""
  switch (conditionalSymbol) {
    case ">":
      conditionalWord = "above"
      break
    case "<":
      conditionalWord = "below"
      break
    default:
      conditionalWord = "at"
  }
  const formattedDescription =
    riskProfileType === "custom"
      ? translateFn(
          "customRiskProfileDescription",
          `${name} ${conditionalWord} ${threshold}${units} for ${duration} days`,
          {
            name: name,
            conditional: conditionalWord,
            threshold: threshold,
            units: units,
            duration: duration,
          },
        )
      : translateFn(
          "absoluteRiskProfileDescription",
          `${name} considerably ${conditionalWord} location's historical average`,
          {
            name: name,
            conditional: conditionalWord,
          },
        )

  return formattedDescription
}

export const formatHazardDescription = (descriptionArray: string[]) => {
  let hazardDescriptionString = ""
  if (descriptionArray?.length <= 2) {
    hazardDescriptionString = descriptionArray.join(" and ")
  } else {
    descriptionArray.forEach((description, index) => {
      if (index !== descriptionArray.length - 2) {
        hazardDescriptionString += description + ", "
      } else {
        hazardDescriptionString += description + ", and "
      }
    })
  }
  return hazardDescriptionString
}

export const formatDateRange = (
  timeResolution: TimeResolutionValues,
  startDate: string,
  endDate: string,
): string => {
  const startDt = DateTime.fromISO(startDate).toLocal()
  const endDt = DateTime.fromISO(endDate).toLocal()

  switch (timeResolution) {
    case "daily":
      if (startDt.hasSame(endDt, "day")) {
        return startDt.toFormat("MMM d")
      } else if (startDt.hasSame(endDt, "month")) {
        return `${startDt.toFormat("MMM d")} - ${endDt.toFormat("d")}`
      } else {
        return `${startDt.toFormat("MMM d")} - ${endDt.toFormat("MMM d")}`
      }

    case "weekly":
      return `${startDt.startOf("week").toFormat("MMM d")} - ${endDt
        .endOf("week")
        .toFormat("MMM d")}`

    case "monthly":
      if (startDt.hasSame(endDt, "month")) {
        return startDt.toFormat("MMMM")
      } else {
        return `${startDt.toFormat("MMM")} - ${endDt.toFormat("MMM")}`
      }

    default:
      return ""
  }
}

export const formatter = new Intl.NumberFormat(undefined, {
  style: "percent",
  maximumFractionDigits: 0,
})

export const handleNavigateToDashboard = (
  items: IPanelContent[],
  navigate: NavigateFunction,

  scope?: Scope,
) => {
  const timeResolution = Scope[scope ?? 1]

  const params = new URLSearchParams()
  params.set("locId", items[0].location.id as string)
  items.forEach((item) => {
    item.riskProfile.hazard_profiles.forEach((hazardProfile) => {
      let dashboard_var = hazardProfile.hazard_variable.dashboard_variable
      if (dashboard_var === "precipitation_sum") {
        dashboard_var = "precipitation"
      }

      params.append("variables", dashboard_var)
    })
  })

  params.set("scope", timeResolution)

  const baseUrl = `/seasonal/dashboards/location`

  navigate(`${baseUrl}?${params.toString()}`)
}

export const sortByDate = (a: IPanelContent, b: IPanelContent) => {
  const startDateA = DateTime.fromISO(a.alert.start_date)
  const endDateA = DateTime.fromISO(a.alert.end_date)
  const durationA = endDateA.diff(startDateA)

  const startDateB = DateTime.fromISO(b.alert.start_date)
  const endDateB = DateTime.fromISO(b.alert.end_date)
  const durationB = endDateB.diff(startDateB)

  if (startDateA.valueOf() !== startDateB.valueOf()) {
    return startDateA.valueOf() - startDateB.valueOf()
  }
  return durationA.valueOf() - durationB.valueOf()
}

export const formatOccurrence = (
  translateFn: TFnType<DefaultParamType>,
  frequency: number,
  lastOccurrence: string,
) => {
  const frequencyString = translateFn("frequencyAlertEvent", {
    frequencyOfAlert: frequency,
  })
  if (lastOccurrence === "1900-01-01") {
    return [frequencyString, translateFn("neverOcurredLocation")]
  } else {
    const lastOccurrenceDate = DateTime.fromISO(lastOccurrence).toUTC()
    const lastOccurrenceString = translateFn("lastAlertOcurrence", {
      lastOcurrenceDate: lastOccurrenceDate.toFormat("MMMM yyyy"),
    })
    return [frequencyString, lastOccurrenceString]
  }
}

export const formatLastOccurrence = (
  translateFn: TFnType<DefaultParamType>,
  lastOccurrence: string,
) => {
  if (lastOccurrence === "1900-01-01") {
    return translateFn("neverOcurredLocation")
  } else {
    const lastOccurrenceDate = DateTime.fromISO(lastOccurrence).toUTC()
    const lastOccurrenceString = translateFn("lastAlertOcurrence", {
      lastOcurrenceDate: lastOccurrenceDate.toFormat("MMMM yyyy"),
    })
    return lastOccurrenceString
  }
}

export const matchesSelectedAssets = (
  selectedAssets: string[],
  varieties?: ILocationVariety[],
) => {
  return (
    varieties?.some(
      ({ variety_id }) =>
        typeof variety_id === "string" && selectedAssets.includes(variety_id),
    ) ?? false
  )
}

export const matchesSelectedRegions = (
  location: IInsightsLocation,
  selectedRegions: string[],
) => {
  return (
    location.regions?.some(
      (region: IRegion) => region.id && selectedRegions.includes(region.id),
    ) ||
    (location.region?.id && selectedRegions.includes(location.region.id))
  )
}

export const extractVarietyIds = (
  availableVarietiesPerAlert: Record<string, string[]>,
  riskProfilesObj: Record<string, IVariety>,
  riskProfileId: string,
): string[] => {
  const varietyIds: string[] = []
  const availableVarietyIds = Object.keys(availableVarietiesPerAlert)

  const profile = riskProfilesObj[riskProfileId]

  if (profile && availableVarietyIds.includes(profile.id)) {
    profile.stages?.forEach((stage: IVarietyStage) => {
      const varietyId = stage.variety_id
      if (!varietyIds.includes(varietyId)) {
        varietyIds.push(varietyId)
      }
    })
  }
  return varietyIds
}

export const extractMatchingValues = (
  varietyIds: string[],
  varietiesOptions: Record<string, string>,
): unknown[] => {
  const matchingValues = Object.entries(varietiesOptions)
    .filter(([id]) => varietyIds.includes(id))
    .map(([, value]) => ({ value }))
  return matchingValues
}

export const getCategoryStyle = (category: string) => {
  switch (category) {
    case "warning":
      return "bg-red-light fill-red-dark"
    case "watch":
      return "bg-yellow-light fill-yellow-dark"
    default:
      return "bg-gray-3 dark:bg-gray-88 fill-gray-60"
  }
}

export function getDailyTimeWindow() {
  // start: today
  // end: today + 15 days
  const start = DateTime.now().startOf("day")
  return [start, start.plus({ days: 16 })]
}

export function getWeeklyTimeWindow() {
  // start: get immediate next sunday + 1 week
  // end: start + 4 weeks - 1 day
  const start = DateTime.now()
    .startOf("week")
    .plus({ weeks: 2 })
    .minus({ days: 1 })
  return [start, start.plus({ weeks: 4 })]
}

export function getMonthlyTimeWindow() {
  // start: first day of date month of current date - 7 days
  // end: first day of start + 7 months
  const start = DateTime.now()
    .minus({ days: 6 })
    .plus({ month: 1 })
    .startOf("month")
  return [start, start.plus({ months: 5 }).endOf("month")]
}

export function getTimeWindowByGranularity(granularity: TimeResolutionValues) {
  switch (granularity) {
    case "weekly":
      return getWeeklyTimeWindow()
    case "monthly":
      return getMonthlyTimeWindow()
    case "daily":
    default:
      return getDailyTimeWindow()
  }
}
