import CustomReport from "./CustomReport"
import { IRoute } from "../../climateui/types"
import NotFound from "../NotFound"

const customChildRoutes: IRoute[] = [
  {
    path: "",
    element: <CustomReport />,
  },
  {
    path: ":reportId",
    element: <CustomReport />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]

export default customChildRoutes
