import HeatIcon from "../../../climateui/icons/variableIcons/HeatIcon"
import LowTemperatureIcon from "../../../climateui/icons/variableIcons/LowTemperatureIcon"
import DroughtIcon from "../../../climateui/icons/variableIcons/DroughtIcon"
import { PrecipitationIcon } from "../../../climateui/icons/weatherVariableIcons"
import RiskOutlookIcon from "../../../climateui/icons/variableIcons/RiskOutlookIcon"
import { capitalizeFirstCharacterOfEachWord } from "../../../utils/wordHelper"
import { DateTime } from "luxon"
import { YieldModelStage } from "../../../types"
import BelowIcon from "../../../climateui/icons/directionalityIcons/BelowIcon"
import WithinIcon from "../../../climateui/icons/directionalityIcons/WithinIcon"
import { ProbabilityHoverItemProps } from "../../../climateui/components/Widgets/SeasonalWidgets/ProbabilityHover"

// TODO hardcode for now
export const RISK_BOUNDS = [70, 90]

export const getRiskCategoryCoding = (value: number, bounds: number[]) => {
  if (bounds.length > 2) {
    return "highRisk"
  }

  if (value > bounds[1]) {
    return "highRisk"
  } else if (value < bounds[0]) {
    return "lowRisk"
  } else {
    return "mediumRisk"
  }
}

export const getDirectionalityHighlightColor = (directionality: string) => {
  switch (directionality) {
    case "highRisk":
      return "#E42437"
    case "mediumRisk":
      return "#FDB600"
    case "lowRisk":
      return "#23AF41"
  }
}

export const getHazardIcon = (hazard_key: string) => {
  switch (hazard_key) {
    case "drought":
      return DroughtIcon
    case "excessive_heat":
      return HeatIcon
    case "excessive_cold":
      return LowTemperatureIcon
    case "cold_stress":
      return LowTemperatureIcon
    case "excessive_rain":
      return PrecipitationIcon
    default:
      return RiskOutlookIcon
  }
}

export const categoryToColor = {
  highRisk: "red",
  mediumRisk: "yellow-dark",
  lowRisk: "green",
}

// Store stage-color mappings
const stageColorMap: Record<string, string> = {}

// Color palette for stages
const stageColors = [
  "#149CAB", // Teal
  "#38761D", // Green
  "#FF9900", // Orange
  "#AD8406", // Gold
  "#6B7280", // Gray
  "#9CA3AF", // Light gray
  "#4CBFAD", // Mint
  "#004957", // Dark teal
  "#23AF41", // Bright green
  "#FDB600", // Yellow
]

let colorIndex = 0

export const getStageColor = (stageName: string): string => {
  // Return existing color if stage has been seen before
  if (stageName in stageColorMap) {
    return stageColorMap[stageName]
  }

  // Assign next color in palette or default to gray if out of colors
  const color =
    colorIndex < stageColors.length ? stageColors[colorIndex] : "#6B7280"
  colorIndex++

  // Store the mapping
  stageColorMap[stageName] = color

  return color
}

export const getStageConfig = (stage: string) => {
  return {
    label: capitalizeFirstCharacterOfEachWord(stage.replaceAll("_", " ")),
    color: getStageColor(stage), // Dark gray for unknown stages
  }
}

export const getHazardConfig = (hazard_key: string, hazard_name: string) => {
  switch (hazard_key) {
    case "excessive_heat":
      return {
        hazard_risk: "excessive_heat",
        color: "#FF7100",
        title: "Damaging Heat",
      }
    case "excessive_cold":
      return {
        hazard_risk: "excessive_cold",
        color: "#039BE5",
        title: "Frost",
      }
    case "cold_stress":
      return {
        hazard_risk: "cold_stress",
        color: "#039BE5",
        title: "Cold Stress",
      }
    case "excessive_rain":
      return {
        hazard_risk: "excessive_rain",
        color: "#237CB5",
        title: "Excessive Rain",
      }
    case "drought":
      return {
        hazard_risk: "drought",
        color: "#A220B7",
        title: "Drought",
      }
    default:
      return {
        hazard_risk: hazard_key,
        color: "#4B4B4B",
        title: capitalizeFirstCharacterOfEachWord(hazard_name),
      }
  }
}

export function filterStagesByCurrentDate(
  stages: YieldModelStage[],
  currentDate: string,
) {
  const now = DateTime.fromISO(currentDate).startOf("day")
  const currentFullYear = now.year
  // adjust year agnostic stage to current year
  stages = stages.filter((stage) => {
    // stage.start_date and stage.end_date expects mm-dd format
    const startDate = DateTime.fromFormat(stage.start_date, "MM-dd").startOf(
      "day",
    )
    let endDate = DateTime.fromFormat(stage.end_date, "MM-dd").endOf("day")

    // If stage_name is out_of_season, adjust endDate to next Sunday
    if (stage.stage_name === "out_of_season") {
      endDate = endDate.plus({ days: 1 }).endOf("week")
    }

    // Adjust start and end dates to the current year
    let adjustedStartDate = startDate.set({ year: currentFullYear })
    let adjustedEndDate = endDate.set({ year: currentFullYear })

    // Handle cases where the stage crosses year boundary (e.g., Dec to Jan)
    if (adjustedStartDate > adjustedEndDate) {
      if (now < adjustedEndDate) {
        adjustedStartDate = adjustedStartDate.minus({ years: 1 })
      } else {
        adjustedEndDate = adjustedEndDate.plus({ years: 1 })
      }
    }

    // Check if 'now' is within the adjusted date range
    return now >= adjustedStartDate && now <= adjustedEndDate
  })

  return stages
}
export function getProbabilityHoverItems(
  yMin: number,
  yMid: number,
  yMax: number,
  lowerBound: number,
  upperBound: number,
  active = true,
): ProbabilityHoverItemProps[] {
  const maxValue = Math.max(yMin, yMid, yMax)
  return [
    {
      title: "High Severity",
      //   description: `Above ${upperBound.toFixed(0)}`,
      value: `${((yMax ?? 0) * 100).toFixed(0)}%`,
      icon: () => <BelowIcon className="rotate-180" />,
      active: active && yMax === maxValue,
      //   color: "#E42437",
    },
    {
      title: "Medium Severity",
      //   description: `Between ${lowerBound.toFixed(0)} and ${upperBound.toFixed(
      //     0,
      //   )}`,
      value: `${((yMid ?? 0) * 100).toFixed(0)}%`,
      icon: WithinIcon,
      active: active && yMid === maxValue,
      // color: "#FDB600",
    },
    {
      title: "Not Severe",
      //   description: `Below ${lowerBound.toFixed(0)}`,
      value: `${((yMin ?? 0) * 100).toFixed(0)}%`,
      icon: BelowIcon,
      active: active && yMin === maxValue,
      // color: "#23AF41",
    },
  ]
}
