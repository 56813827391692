import { IRoute } from "../../climateui/types"
import OnboardingAssetDependentLayoutProvider from "./components/OnboardingAssetDependentLayoutProvider"
import {
  OnboardingAssets,
  OnboardingLocations,
  OnboardingRiskProfiles,
  OnboardingFinishSetup,
} from "./steps"

export const assetDependentRoutes: IRoute[] = [
  {
    path: "risk-profiles",
    element: <OnboardingRiskProfiles />,
  },
  {
    path: "locations",
    element: <OnboardingLocations />,
  },
  {
    path: "finish-setup",
    element: <OnboardingFinishSetup />,
  },
]

const onboardingChildRoutes: IRoute[] = [
  {
    path: "",
    element: <OnboardingAssets />,
  },
  {
    path: ":assetId",
    element: <OnboardingAssetDependentLayoutProvider />,
    children: assetDependentRoutes,
  },
]

export default onboardingChildRoutes
