import { useTranslate } from "@tolgee/react"
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { ModalContext } from "../climateui/providers"
import { IRoute } from "../climateui/types"
import { accountPlansGET } from "../utils/networking"
import { IPlan } from "../types"
import { planAdditionAndEditionRoutes } from "../views/Seasonal/PlanningTool/routes"
import { useQuery } from "react-query"
import { useAuthNavigation } from "./AuthGuards"
import { useAccount } from "./AccountProvider"
import { CustomResponse } from "../climateui/utils/http"
import { useUI } from "./UIProvider"

export interface IPlansContext {
  planRoutes: IRoute[]
  plansObject: Record<string, IPlan>
  plans: IPlan[]
  goToEditPlan: (activePlanId: string, fromRoute: string) => void
  cancelPlanAdditionOrEdition: () => void
  goToStep: (next: string) => void
  goToNewPlan: () => void
  navigateToAllowedPlanRoute: () => void
  activePlanId: string | undefined
  loadingPlans: boolean
}
const PlanningToolContext = createContext<IPlansContext>({} as IPlansContext)
export const usePlanningTool = () => useContext(PlanningToolContext)

function buildPlanRoute(plan: IPlan) {
  return {
    path: plan.id + "",
    label: plan.name,
  }
}

function PlanningToolProvider({ children }: { children: ReactNode }) {
  const { navigateToAllowed } = useAuthNavigation()
  const { planId } = useParams()
  const { selectedAccount } = useAccount()
  const { setSecondSidebarOptions } = useUI()
  const { confirmationModal } = useContext(ModalContext)

  const _navigate = useNavigate()
  const navigate = (path: string) => _navigate("plans/" + path)
  const { t } = useTranslate()
  const location = useLocation()
  const [editReturnRoute, setEditReturnRoute] = useState<string>()

  const {
    refetch,
    data,
    isLoading: loadingPlans,
  } = useQuery(["plans", selectedAccount], accountPlansGET, {
    enabled: !!selectedAccount,
  })

  const { planRoutes, plansObject, plans } = useMemo(() => {
    const obj: Record<string, IPlan> = {}
    const planRoutes: IRoute[] = []
    const plans: IPlan[] = []

    if (data) {
      const d = (data as CustomResponse).data
      if (d && d.length > 0) {
        d.forEach((plan: IPlan) => {
          if (plan.id) {
            obj[plan.id] = plan
            planRoutes.push(buildPlanRoute(plan))
            plans.push(plan)
          }
        })
      }
    }

    const sortPlans = (a: IRoute, b: IRoute) => {
      if (!a.label || !b.label) return 0

      if (a.label > b.label) return 1
      else if (a.label < b.label) return -1
      return 0
    }
    planRoutes.sort(sortPlans)

    return {
      planRoutes,
      plansObject: obj,
      plans,
    }
  }, [data])

  useEffect(() => {
    if (selectedAccount) refetch()
  }, [selectedAccount])

  const addOrEditActions = useMemo(() => {
    // ADDING /////////////////////////////////////////////////////////////
    if (location.pathname.includes("new"))
      return {
        closeModalConfig: {
          title: t("areYouSureCloseNewPlan"),
          text: t("closeNewPlanConsequences"),
          onCancel: () => null,
          onContinue: () => {
            navigate("")
          },
          onContinueLabel: t("goOut"),
          onCancelLabel: t("cancel"),
        },
        nextRoute: "new",
        baseRoute: "plans/new",
      }
    // EDITING ////////////////////////////////////////////////////////////
    return {
      closeModalConfig: {
        title: t("areYouSureCloseEditPlan"),
        text: t("allYourChangesWillBeLost"),
        onCancel: () => null,
        onContinue: () => {
          _navigate(editReturnRoute || "plans/" + planId)
        },
        onContinueLabel: t("goOut"),
        onCancelLabel: t("cancel"),
      },
      nextRoute: planId + "/edit",
      baseRoute: "plans/" + planId + "/edit",
    }
  }, [location.pathname])

  const goToNewPlan = () => {
    navigate("new")
  }

  const goToEditPlan = (activePlanId: string, fromRoute: string) => {
    navigate(activePlanId + "/edit/stages-risks")
    setEditReturnRoute(fromRoute)
  }

  const cancelPlanAdditionOrEdition = () => {
    confirmationModal(addOrEditActions.closeModalConfig)
  }

  const goToStep = (next: string) => {
    navigate(addOrEditActions.nextRoute + "/" + next)
  }

  const navigateToAllowedPlanRoute = () => {
    navigateToAllowed(planAdditionAndEditionRoutes, addOrEditActions.baseRoute)
  }

  useEffect(() => {
    setSecondSidebarOptions((prev) => ({
      ...prev,
      seasonal: {
        ...prev["seasonal"],
        plans: {
          title: t("operationalPlans", "Operational Plans"),
          routes: planRoutes,
          createRoutePath: (route) => {
            const basePath = "/seasonal/plans"
            if (!route) return basePath

            return (
              basePath + "/" + (typeof route === "string" ? route : route.path)
            )
          },
          emptyMsg: "",
          plusButtonTooltip: t("newPlan", "New Plan"),
          autoEnterFirstOption: true,
        },
      },
    }))
  }, [planRoutes])

  return (
    <PlanningToolContext.Provider
      value={{
        planRoutes,
        plansObject,
        plans,
        goToEditPlan,
        cancelPlanAdditionOrEdition,
        goToStep,
        activePlanId: planId,
        goToNewPlan,
        navigateToAllowedPlanRoute,
        loadingPlans,
      }}>
      {children}
    </PlanningToolContext.Provider>
  )
}

export default PlanningToolProvider
