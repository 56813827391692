import { useTranslate } from "@tolgee/react"

function Translate({
  labelKey,
  customClass = "",
}: {
  labelKey: string
  customClass?: string
}) {
  const { t } = useTranslate()

  return <div className={customClass}>{t(labelKey)}</div>
}

export default Translate
