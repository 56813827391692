import { DefaultParamType, TFnType } from "@tolgee/react"
import { IVariable } from "../../../../types"
import dashboardVariables from "../../../../views/Seasonal/Dashboards/dashboardVariables"
import Candle50Icon from "../../../icons/candleIcons/Candle50"
import Candle90Icon from "../../../icons/candleIcons/Candle90"
import CandleMedian from "../../../icons/candleIcons/CandleMedian"
import { monthsArray } from "./genericChartWidgetUtils"

function BoxPlotHover(props: {
    t: TFnType<DefaultParamType, string>
    d: Record<string, any>
    items: any[]
    granularity: string
    importantDates: Record<string, Date>
}) {
    const { t, d, items, granularity, importantDates } = props
    const unit = items[0].units
    const dashboardVars = dashboardVariables as Record<string, IVariable>

    return (
        <div className="flex flex-wrap w-[300px] font-roboto text-[16px] m-[4px]">
            <h1 className="font-[600]">
                {granularity == "monthly"
                    ? monthsArray[importantDates.date.getMonth()] +
                      " " +
                      importantDates.date.getFullYear()
                    : importantDates.startWeek.toLocaleDateString("en-us", {
                          month: "short",
                          day: "numeric",
                      }) +
                      " to " +
                      importantDates.endWeek.toLocaleDateString("en-us", {
                          month: "short",
                          day: "numeric",
                      })}
            </h1>
            <h1 className="text-[14px] color-#666D74 mb-[5px] w-[100%]">
                {`${dashboardVars[d.item_data.variable].title}
                   ${t(d.item_data.dataset)}`}
            </h1>

            <div className="w-full flex justify-between items-center px-0 py-[5px] border-b border-gray-14 dark:border-gray-78 border-solid">
                <div className="flex">
                    <div className="w-[20px]">
                        <CandleMedian fill={d.scaled[0].color} />
                    </div>
                    <h1 className="text-[14px] font-[500] mx-[5px] text-gray-60 dark:text-gray-30">
                        Median
                    </h1>
                </div>
                <h1 className="text-[15px]">{d.yMid.toFixed(2) + unit}</h1>
            </div>

            <div className="w-full flex justify-between items-center px-0 py-[5px] border-b border-gray-14 dark:border-gray-78 border-solid">
                <div className="flex">
                    <div className="w-[20px]">
                        <Candle90Icon fill={d.scaled[0].color} />
                    </div>
                    <h1 className="text-[14px] font-[500] mx-[5px] text-gray-60 dark:text-gray-30">
                        90% Confidence
                    </h1>
                </div>

                <h1 className="text-[15px]">
                    {d.y0.toFixed(2) + unit + " to " + d.y1.toFixed(2) + unit}
                </h1>
            </div>

            <div className="w-full flex justify-between items-center px-0 py-[5px] border-b border-gray-14 dark:border-gray-78 border-solid">
                <div className="flex">
                    <div className="w-[20px]">
                        <Candle50Icon fill={d.scaled[0].color} />
                    </div>
                    <h1 className="text-[14px] font-[500] mx-[5px] text-gray-60 dark:text-gray-30">
                        50% Confidence
                    </h1>
                </div>

                <h1 className="text-[15px]">
                    {d.yMin.toFixed(2) +
                        unit +
                        " to " +
                        d.yMax.toFixed(2) +
                        unit}
                </h1>
            </div>
        </div>
    )
}

export default BoxPlotHover
