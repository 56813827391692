/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: define all any in the file
import { useTranslate } from "@tolgee/react"
import downloadSVG from "export-svg-with-styles"
import html2canvas from "html2canvas"
import { useRef, useState } from "react"
import { CSVLink } from "react-csv"
import { Button, Checkbox, TooltipV2 } from "../climateui/components"
import { useOutsideComponentClickHandler } from "../climateui/hooks"
import { DownloadIcon } from "../climateui/icons"
import { downloadBlob } from "../utils"

export const formatFilenameFieldAndVariable = (
  fieldName: string,
  variableName: string,
) => {
  // RegExp to remove consecutive hypens (-) and illegal filename characters
  const textRegex = new RegExp(
    /\B-+|\/|\*|#|%|&|{|}|\\|'|"|<|>|\?|\$|!|:|@|\||`|\+|\||=/g,
  )
  const formattedFilename = `${String(fieldName)
    .replaceAll(" ", "-")
    .replaceAll(textRegex, "")}_${String(variableName)
    .toLowerCase()
    .replaceAll("_", "")
    .replace("t2m", "")}`
  return formattedFilename
}

const ExportAsOption = ({
  onToggle,
  checked,
  id,
  name,
}: {
  onToggle: () => void
  checked: boolean
  id: string
  name: string
}) => {
  const { t } = useTranslate()
  return (
    <div
      className="flex flex-row items-center space-x-[10px] hover:translate-x-1 transition-all duration-200 cursor-pointer select-none"
      onClick={onToggle}>
      <div className="w-6 h-6">
        <Checkbox
          id={id}
          checked={checked}
          onChange={onToggle}
          status={(checked && "full") || "empty"}
        />
      </div>
      <label
        className="cursor-pointer pointer-events-none whitespace-nowrap text-light-text dark:text-dark-text"
        htmlFor={id}>
        {t("exportAs")}
        {"\u00a0"}
        <span className="font-bold">{name}</span>
      </label>
    </div>
  )
}

const initialState = {
  exportToPng: false,
  exportToCsv: false,
  visible: false,
}

const ExportButton = ({
  cols,
  data,
  isDisabled = false,
  fileName,
  wrapperClasses = "relative",
  buttonSizeClasses = "w-[36px] h-[36px]",
  csvString,
  isPNGEnabled = true,
  isCSVEnabled = true,
  idToExport = "",
}: {
  cols: any
  data: any
  isDisabled?: boolean
  fileName: string
  wrapperClasses?: string
  buttonSizeClasses?: string
  csvString: string
  isPNGEnabled?: boolean
  isCSVEnabled?: boolean
  idToExport?: string // ID of the DOM element to export as image (PNG)
}) => {
  const csvLinkRef = useRef<any>(null)
  const [state, setState] = useState({ ...initialState })

  let width = 0
  let height = 0
  const chart = document.getElementsByClassName("svg-chart-container")[0]
  if (chart) {
    const rect = chart.getBoundingClientRect()
    width = rect.width
    height = rect.height
  }

  const exportToPng = () => {
    if (idToExport !== "") {
      // Wait for the export popover to hide
      setTimeout(async () => {
        const element = document.getElementById(idToExport)
        const canvas = await html2canvas(element as HTMLElement, {
          backgroundColor: "lightgray",
        })
        const image = canvas.toDataURL("image/png", 1.0)
        downloadBlob(image, fileName + ".png")
      }, 200)
    } else {
      downloadSVG({
        width: width * 3,
        height: height * 3,
        svg: chart,
        filename: fileName,
      })
    }
  }
  const exportToCsv = () => {
    if (csvString) {
      const dataStr =
        "data:text/csv;charset=utf-8," + encodeURIComponent(csvString)
      downloadBlob(dataStr, fileName + ".csv")
    } else {
      ;(csvLinkRef.current as HTMLElement).click()
    }
  }

  function onVisibleChange() {
    setState({
      ...state,
      visible: !state.visible,
    })
  }

  const handleExportIconClick = () => {
    // If just one export option is available,
    // export directly with no need of options popup.
    if (isCSVEnabled && !isPNGEnabled) {
      exportToCsv()
    } else if (!isCSVEnabled && isPNGEnabled) {
      exportToPng()
    } else {
      onVisibleChange()
    }
  }

  const handleSubmitExport = () => {
    onVisibleChange()

    if (state.exportToPng) {
      exportToPng()
    }
    if (state.exportToCsv) {
      exportToCsv()
    }

    setState({ ...initialState })
  }

  const wrapperRef = useOutsideComponentClickHandler(() => {
    if (state.visible) setState({ ...state, visible: false })
  })

  const { t } = useTranslate()

  return (
    <div
      className={"hidden lg:block " + wrapperClasses}
      ref={wrapperRef}>
      {data.csv && (
        <>
          <TooltipV2
            doShow={!state.visible}
            content={t("export")}
            position="bottom">
            <button
              className={[
                "flex items-center justify-center relative group shrink-0",
                "font-roboto font-normal text-[16px]",
                buttonSizeClasses,
              ].join(" ")}
              onClick={handleExportIconClick}>
              <div className="h-[24px] w-[24px] group-hover:scale-[108%] fill-gray-60">
                <DownloadIcon />
              </div>
            </button>
          </TooltipV2>
          <CSVLink
            ref={csvLinkRef}
            data={data.csv}
            headers={cols.csv}
            className="hidden-link"
            filename={`${fileName}.csv`}
          />
        </>
      )}
      {state.visible && (
        <div className="absolute right-0 bg-light-bg dark:bg-dark-bg border-[1px] border-gray-14 dark:border-gray-78 w-fit p-4 rounded-lg z-full flex flex-col space-y-[12px] font-roboto text-[16px] font-normal elevation-2 overflow-hidden">
          {isPNGEnabled && (
            <ExportAsOption
              onToggle={() =>
                setState({
                  ...state,
                  exportToPng: !state.exportToPng,
                })
              }
              checked={state.exportToPng}
              id="exportToPng"
              name="PNG"
            />
          )}
          {isCSVEnabled && (
            <ExportAsOption
              onToggle={() =>
                setState({
                  ...state,
                  exportToCsv: !state.exportToCsv,
                })
              }
              checked={state.exportToCsv}
              id="exportToCsv"
              name="CSV"
            />
          )}

          <Button
            extend={true}
            label={t("exportNow")}
            onClick={handleSubmitExport}
            disabled={(!state.exportToCsv && !state.exportToPng) || isDisabled}
          />
        </div>
      )}
    </div>
  )
}

export default ExportButton
