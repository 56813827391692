import { useTranslate } from "@tolgee/react"
import { useState } from "react"
import { TableActions } from "../../../../climateui/components/Table/tableUtils"
import { EditIcon, TrashIcon } from "../../../../climateui/icons"
import { useAssets } from "../../../../providers"
import { IVariety } from "../../../../types"
import { isCaiDefaultVariety } from "../../../../utils"
import DeleteVarietyModal from "./DeleteVarietyModal"

const AssetTableActions = ({ data }: { data: IVariety }) => {
  /* HOOKS > START */
  const { t } = useTranslate()
  const { setSelectedVariety } = useAssets()
  /* HOOKS < END */
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  return (
    <div>
      {/* DELETE MODAL */}
      <DeleteVarietyModal
        variety={data}
        isOpen={isDeleteOpen}
        setIsOpen={setIsDeleteOpen}
      />
      <TableActions
        rowData={data}
        actions={[
          {
            icon: (
              <span
                className={[
                  isCaiDefaultVariety(data)
                    ? "cursor-not-allowed fill-gray-30"
                    : "",
                ].join(" ")}>
                <EditIcon />
              </span>
            ),
            onClick: () => {
              // Do nothing if it is a default asset
              if (isCaiDefaultVariety(data)) return
              setSelectedVariety(data)
            },
            tooltip: isCaiDefaultVariety(data)
              ? t("toEditAssetContactUs", "To edit this asset, contact us")
              : t("editAsset", "Edit Asset"),
          },
          {
            icon: (
              <span
                className={[
                  data.is_default ? "cursor-not-allowed fill-gray-30" : "",
                ].join(" ")}>
                <TrashIcon />
              </span>
            ),
            tooltip: data.is_default
              ? t("toDeleteAssetContactUs", "To delete this asset, contact us")
              : t("deleteAsset", "Delete Asset"),
            onClick: () => {
              // Do nothing if it is a default asset
              if (data.is_default) return
              setIsDeleteOpen(true)
            },
          },
        ]}
      />
    </div>
  )
}
export default AssetTableActions
