import { useQuery } from "react-query"
import { HOUR_MS } from "../utils/constants"
import { pointRegionPOST } from "../utils/networking"

const useCoordsRegionQuery = ({
  enabled = true,
  lat,
  lon,
}: {
  enabled?: boolean
  lat?: number
  lon?: number
}) => {
  const query = useQuery({
    enabled: enabled && !!lat && !!lon,
    queryKey: ["pointRegion", lat, lon],
    queryFn: () =>
      pointRegionPOST({
        lat: lat,
        lon: lon,
      }),
    staleTime: HOUR_MS,
  })

  return query
}

export default useCoordsRegionQuery
