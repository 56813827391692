import { gql } from "graphql-request"
import _ from "lodash"
import { ChartSeasonal } from "../../../climateui/components/ChartsRe/implementations/Seasonal/ChartSeasonal"
import { DataSourceChartPlotComponents } from "../../../climateui/components/ChartsRe/implementations/Seasonal/data/DataSourceChartPlotComponents"
import { IPlotModelGeneric } from "../../../climateui/components/ChartsRe/implementations/Seasonal/data/types"
import WidgetWrapper from "../../../climateui/components/Widgets/WidgetWrapper/WidgetWrapper"

interface IGenericChartWidget {
  loading: boolean
  title?: string
  labelY?: string
  items?: IPlotModelGeneric[] // Pending to add structure
  //   error: boolean
  //   empty: boolean
  //   reload: () => void
  //   units?: string
  //   alerts?: any[] // Pending to add structure
  //   labels?: any[] // Pending to add structure
  //   location?: string
  //   groupCandles?: boolean
  //   centerPoints?: boolean
  //   centerTicks?: boolean
  //   granularity?: "daily" | "hourly" | "daily" | "weekly" | "monthly"
  //   directionalChart?: boolean
  //   actions?: WdgtActions
  //   errorMessage?: string
  //   reloadMessage?: string
  //   noResultsMessage?: string
  //   tryAnotherFilterMessage?: string
  //   customHover?: null
  //   customLabels?: null
  //   enableCSVDownload?: boolean
  //   processItems?: boolean
  //   lineAreaChartProps?: object
}

const addItemId = (item: IPlotModelGeneric): IPlotModelGeneric => {
  item.id = item.variable + "_" + item.visualization + "_" + _.uniqueId()
  return item
}

const addItemsId = (items: IPlotModelGeneric[]): IPlotModelGeneric[] => {
  for (let i = 0; i < items.length; i++) {
    items[i] = addItemId(items[i])
  }
  return items
}

export const WrapperChart = (props: IGenericChartWidget) => {
  const { loading } = props
  if (props.items === undefined || props.title === undefined) {
    return <></>
  }
  // Add items Ids on the fly
  const chartComponents = new DataSourceChartPlotComponents(
    props.title,
    {
      xLabel: "Date",
      xLabelId: "date",
      yLabel: props.labelY ?? "",
      yLabelId: "",
    },
    addItemsId(props.items),
  )

  if (loading) {
    return <>Loading</>
  }
  return <ChartSeasonal chartPlotComponents={chartComponents} />
}

export const ChartTest = () => {
  const chartsFiltersMinMaxTemp = [
    {
      loading: false,
      propName: "location_ids",
      value: ["9299a7b3-1301-4b6a-948a-40fd6fb422e5"],
    },
    {
      loading: false,
      propName: "unit_system",
      value: "metric",
    },
    {
      propName: "granularity",
      value: "daily",
    },
    {
      loading: false,
      propName: "config",
      value: [
        {
          color: "#FF7100",
          dataset: "forecast",
          variable: "temp_max",
          visualization: "line",
        },
        {
          color: "#FF7100",
          dataset: "climatology",
          variable: "temp_max",
          visualization: "area",
        },
        {
          color: "#039BE5",
          dataset: "forecast",
          variable: "temp_min",
          visualization: "line",
        },
        {
          color: "#039BE5",
          dataset: "climatology",
          variable: "temp_min",
          visualization: "area",
        },
      ],
    },
  ]

  const chartsFiltersMeanTemp = [
    {
      loading: false,
      propName: "location_ids",
      value: ["9299a7b3-1301-4b6a-948a-40fd6fb422e5"],
    },
    {
      loading: false,
      propName: "unit_system",
      value: "metric",
    },
    {
      propName: "granularity",
      value: "daily",
    },
    {
      loading: false,
      propName: "config",
      value: [
        {
          color: "#A220B7",
          dataset: "forecast",
          variable: "temp_mean",
          visualization: "line",
        },
        {
          color: "#A220B7",
          dataset: "climatology",
          variable: "temp_mean",
          visualization: "area",
        },
      ],
    },
  ]

  /* Selectors pasa la data del query service como props al child */
  const selectorsMinMaxTemp = {
    centerPoints: false,
    directionalChart: false,
    granularity: "daily",
    groupCandles: true,
    labelY: "Min and Max Temperature (°C)‌‌‍‍‌‍‍‍‌",
    title: "Min and Max Temperature Chart‌‌‍‍‌‍‍‌‌",
    units: "°C",
  }

  const selectorsMeanTemp = {
    centerPoints: false,
    directionalChart: false,
    granularity: "daily",
    groupCandles: true,
    labelY: "Mean Temperature‌‍‍‌‌‍‍‌‌ (°C)",
    title: "Mean Temperature",
    units: "°C",
  }

  return (
    <div className="container m-auto w-100 h-[300px] px-12">
      <h1 className="display-lg mt-20">Charts Test</h1>
      <div className="space-y-8 pb-40 pt-20">
        <WidgetWrapper
          selectedFilters={[
            "unit_system",
            "risk_settings_ids",
            "granularity",
            "config",
            "location_ids",
          ]}
          component={WrapperChart}
          query={gql`
            query (
              $location_ids: [String]
              $config: [WeatherConfig]!
              $granularity: String
              $unit_system: String
            ) {
              locations(filter: { location_ids: $location_ids }) {
                results {
                  name
                  latitude
                  longitude
                  weather(
                    filter: {
                      config: $config
                      granularity: $granularity
                      unit_system: $unit_system
                    }
                  ) {
                    data
                  }
                  varieties {
                    results {
                      name
                      asset {
                        name
                      }
                    }
                  }
                  labels {
                    name
                  }
                }
              }
            }
          `}
          selectors={{
            ...selectorsMinMaxTemp,
            $items: "locations.results[0].weather.data",
            $alerts: "alerts.results",
            $varieties: "locations.results[0].varieties.results",
            $labels: "locations.results[0].labels",
            $location: "locations.results[0]",
          }}
          filters={chartsFiltersMinMaxTemp}
        />
        <WidgetWrapper
          selectedFilters={[
            "unit_system",
            "risk_settings_ids",
            "granularity",
            "config",
            "location_ids",
          ]}
          component={WrapperChart}
          query={gql`
            query (
              $location_ids: [String]
              $config: [WeatherConfig]!
              $granularity: String
              $unit_system: String
            ) {
              locations(filter: { location_ids: $location_ids }) {
                results {
                  name
                  latitude
                  longitude
                  weather(
                    filter: {
                      config: $config
                      granularity: $granularity
                      unit_system: $unit_system
                    }
                  ) {
                    data
                  }
                  varieties {
                    results {
                      name
                      asset {
                        name
                      }
                    }
                  }
                  labels {
                    name
                  }
                }
              }
            }
          `}
          selectors={{
            ...selectorsMeanTemp,
            $items: "locations.results[0].weather.data",
            $alerts: "alerts.results",
            $varieties: "locations.results[0].varieties.results",
            $labels: "locations.results[0].labels",
            $location: "locations.results[0]",
          }}
          filters={chartsFiltersMeanTemp}
        />
      </div>
    </div>
  )
}
