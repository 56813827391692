import { useTranslate } from "@tolgee/react"
import { useMemo } from "react"
import { IRoute } from "../../climateui/types"
import { IAsset, IAssetModel } from "../../types"
import { ProductKeyEnum } from "./useAssetModels"

interface ITerritoryConfig {
  supportedAssets: Set<string> // assets IDs
}

const PRODUCT_KEY_ROUTE_LABEL_MAP: Record<ProductKeyEnum, string> = {
  seasonal_risk_outlook: "Risk",
  seasonal_yield_outlook: "Yield",
  adapt_yield_outlook: "Yield",
  status: "",
}

// DISCUSSION: Sub routes should be handled in their respective
// providers, we could only abstract the logic for the asset matching
// but appart from that, the routing should be handled independently
export const useSubRoutes = (
  assets: Record<string, IAsset> | undefined,
  assetModels: IAssetModel[] | undefined,
  productKey: ProductKeyEnum,
  opts?: {
    locations?: ITerritoryConfig
  },
) => {
  const { t } = useTranslate()

  const distinctAssets = useMemo<(IAsset | undefined)[]>(() => {
    if (!assets || !assetModels) return []
    const ids = new Set<string>()

    // Get distinct asset ids from models
    assetModels.forEach((model) => {
      if (
        (productKey === "seasonal_yield_outlook" && model.newest_risk_date) ||
        (productKey === "seasonal_risk_outlook" &&
          model.default_geography?.newest_risk_date) ||
        productKey === "adapt_yield_outlook"
      )
        ids.add(model.asset_id)
    })

    // List of ordered asset objects
    return Array.from(ids)
      .map((id) => assets[id])
      .sort((a, b) => {
        if (!a?.name || !b?.name) return 0
        return a.name.localeCompare(b.name)
      })
  }, [assets, assetModels, productKey])

  return useMemo(() => {
    const routes: IRoute[] = []
    distinctAssets.forEach((asset) => {
      if (!asset) return
      const { id, name } = asset

      // regional yield and risk
      routes.push({
        path: id,
        label: t(
          `${productKey}_sidebar_item`,
          `{asset} Regional ${PRODUCT_KEY_ROUTE_LABEL_MAP[productKey]}`,
          {
            asset: name,
          },
        ),
      })

      // location yield
      if (opts?.locations?.supportedAssets.has(asset.id))
        routes.push({
          path: "locations/" + id,
          label: t(
            `${productKey}_location_sidebar_item`,
            `{asset} Location Yield`,
            {
              asset: name,
            },
          ),
        })
    })
    return routes
  }, [distinctAssets, productKey, opts?.locations?.supportedAssets?.size])
}
