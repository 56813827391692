import { useTranslate } from "@tolgee/react"
import { Dispatch, SetStateAction, useContext, useEffect, useMemo } from "react"
import { SingleSelectFilter } from "../../../../climateui/components"
import { SingleDropdownSelect } from "../../../../climateui/components/Inputs"
import { CancelIcon, PlusIcon } from "../../../../climateui/icons"
import { ModalContext } from "../../../../climateui/providers"
import NumberInput from "../../../../components/NumberInput"
import { useRiskProfiles } from "../../../../providers/RiskProfilesProvider"
import { UnitConversionContext } from "../../../../providers/UnitConversionProvider"
import { IHazardVariable } from "../../../../types"
import Divider from "./Divider"

export type ConditionForm = {
  hazard_variable_id: string
  conditional: string
  type: string
  threshold?: number
  window?: number
  aggregation: string
}

export const templateCondition = {
  hazard_variable_id: "",
  conditional: "",
  type: "",
  threshold: 0,
  window: 1,
  aggregation: "",
}

const MAX_THRESHOLDS = 5

type ConditionProps = {
  id: number
  groupId: number
  conditions: ConditionForm[][]
  updateConditions: Dispatch<SetStateAction<ConditionForm[][]>>
  form: ConditionForm
  hazardVariables?: IHazardVariable[]
  canEditAll?: boolean
  variant?: "default" | "ds"
}

const BaseCondition: React.FC<ConditionProps> = ({
  id,
  groupId,
  conditions,
  updateConditions,
  form,
  hazardVariables,
  canEditAll = true,
  variant = "default",
}) => {
  const { t } = useTranslate()
  const { getUnit } = useContext(UnitConversionContext)
  const { confirmationModal } = useContext(ModalContext)
  const { setSelectedHazard } = useRiskProfiles()

  // Configuration based on variant
  const comparisonOptions = useMemo(
    () => ({
      ">": variant === "ds" ? t("above") : t("greaterThan"),
      "<": variant === "ds" ? t("below") : t("lessThan"),
    }),
    [t, variant],
  )

  const name = useMemo(
    () =>
      variant === "ds"
        ? t("SmartThreshold", "Smart Threshold")
        : `${t("threshold", "Threshold")} ${id + 1}`,
    [t, id, variant],
  )

  const hazardVariablesOpts = useMemo<Record<string, string>>(() => {
    if (!hazardVariables) return {}
    return hazardVariables.reduce((prev, variable) => {
      if (
        id > 0 &&
        !!conditions[groupId][0].hazard_variable_id &&
        conditions[groupId][0].aggregation !== variable.aggregation
      )
        return prev
      return { ...prev, [variable.id]: variable.readable_name }
    }, {})
  }, [hazardVariables, conditions])

  const hazardVariableUnits = useMemo(() => {
    if (!hazardVariables) return {}
    return hazardVariables.reduce(
      (prev, variable) => ({ ...prev, [variable.id]: variable }),
      {} as Record<string, IHazardVariable>,
    )
  }, [hazardVariables])

  const hazardVariableAggregation = useMemo(() => {
    if (!hazardVariables) return {}
    return hazardVariables.reduce(
      (prev, variable) => ({ ...prev, [variable.id]: variable.aggregation }),
      {} as Record<string, string>,
    )
  }, [hazardVariables])

  const updateForm = (form: ConditionForm) => {
    const tmpConditions = [...conditions]
    tmpConditions[groupId][id] = { ...form }
    updateConditions(tmpConditions)
  }

  // Shared effect for initializing form
  useEffect(() => {
    if (hazardVariablesOpts[form.hazard_variable_id]) return
    const firstHazardVariableId = Object.keys(hazardVariablesOpts)[0]
    updateForm({
      ...form,
      hazard_variable_id: firstHazardVariableId,
      type: form.type || "absolute",
      conditional: form.conditional || ">",
      aggregation: hazardVariableAggregation[firstHazardVariableId],
    })
  }, [conditions, form, hazardVariableAggregation, hazardVariables])

  // Shared effect for hazard selection
  useEffect(() => {
    if (form.hazard_variable_id) {
      setSelectedHazard?.(form.hazard_variable_id)
    }
  }, [form.hazard_variable_id, setSelectedHazard])

  const isConsecutive = useMemo(
    () => form.aggregation === "consecutive",
    [form.aggregation],
  )

  const isRelative = useMemo(
    () => variant === "default" && form?.type === "relative",
    [form.type, variant],
  )

  const getThresholdMin = () => {
    if (variant !== "default") return undefined
    if (isRelative) return -1
    if (form) {
      const unit = getUnit(hazardVariableUnits[form.hazard_variable_id])
      return !RegExp(/^(°\s*)?[fFcC]$/).exec(unit) ? 0 : undefined
    }
  }

  const updateFormHazardVariable = (selected?: string) => {
    if (!selected) return
    updateForm({
      ...form,
      hazard_variable_id: selected,
      aggregation: hazardVariableAggregation[selected],
    })
  }

  const handleSelectChange: Dispatch<
    SetStateAction<string | null | undefined>
  > = (value) => {
    if (!value) return
    const selected = value as string
    if (
      conditions[groupId].length === 1 ||
      conditions[groupId][0].aggregation === hazardVariableAggregation[selected]
    ) {
      updateFormHazardVariable(selected)
    } else {
      confirmationModal({
        title: t(
          "changeAggregationTypeTitle",
          "Are you sure you want to choose this variable?",
        ),
        text: t(
          "changeAggregationTypeBody",
          "Since it uses a different type of aggregation it will clear your other thresholds and impact functions.",
        ),
        onContinue: () => {
          const tmpConditions = [...conditions]
          tmpConditions[groupId] = [tmpConditions[groupId][0]]
          updateConditions(tmpConditions)
          updateFormHazardVariable(selected)
        },
      })
    }
  }

  return (
    <div>
      {id > 0 && <Divider text="and" />}
      <span className="pb-1">{name}</span>
      <div className="flex flex-row px-2 my-1 border rounded border-gray-14 dark:border-gray-78">
        <div className="flex flex-col py-1">
          <div className="relative flex flex-row flex-wrap items-center font-normal body-md text-light-text dark:text-dark-text">
            {t("when")}
            <span className="m-1">
              <SingleDropdownSelect
                options={hazardVariablesOpts}
                selected={form.hazard_variable_id}
                setSelected={handleSelectChange}
                disabled={!canEditAll}
              />
            </span>

            {variant === "ds" ? t("isConsiderably") : t("is")}

            <span className="flex flex-row m-1">
              <SingleSelectFilter
                disabled={!canEditAll}
                selected={form.conditional}
                setSelected={(selected) =>
                  updateForm({ ...form, conditional: selected })
                }
                options={comparisonOptions}
              />
            </span>

            {variant === "default" && (
              <div className="flex flex-row items-stretch h-8 m-1">
                <NumberInput
                  initialValue={form.threshold}
                  max={isRelative ? 1 : undefined}
                  min={getThresholdMin()}
                  isPercentage={isRelative}
                  extraClasses="rounded-sm rounded-r-none"
                  onChange={(val) => updateForm({ ...form, threshold: val })}
                />
                <span className="flex flex-row items-center ml-1 font-normal body-md text-light-text dark:text-dark-text">
                  {form.hazard_variable_id && hazardVariableUnits
                    ? getUnit(hazardVariableUnits[form.hazard_variable_id])
                    : ""}
                </span>
              </div>
            )}

            {variant === "ds" && t("locationHistoricalAverage")}

            <span className="flex flex-row items-center m-1">
              <NumberInput
                initialValue={form.window}
                min={1}
                isInteger
                onChange={(val) => updateForm({ ...form, window: val })}
              />
            </span>

            {isConsecutive
              ? t(
                  variant === "ds"
                    ? "daysConsecutiveLikelyhood"
                    : "daysConsecutive",
                )
              : t(
                  variant === "ds"
                    ? "daysRollingWindowLikelyhood"
                    : "daysRollingWindow",
                )}
          </div>
        </div>
        {id > 0 && (
          <div
            className="relative cursor-pointer w-7 h-7 fill-gray-60"
            onClick={() => {
              const tmpConditions = [...conditions]
              tmpConditions[groupId].splice(id, 1)
              updateConditions(tmpConditions)
            }}>
            <CancelIcon />
          </div>
        )}
      </div>
      {conditions[groupId].length < MAX_THRESHOLDS &&
        id === conditions[groupId].length - 1 && (
          <button
            onClick={() => {
              const tmpConditions = [...conditions]
              tmpConditions[groupId].splice(id + 1, 0, { ...templateCondition })
              updateConditions(tmpConditions)
            }}
            className="flex flex-row items-center cursor-pointer text-accent">
            <span className="w-6 h-6 fill-accent">
              <PlusIcon />
            </span>
            {t("and", "And")}
          </button>
        )}
    </div>
  )
}

// Export wrapped components for each variant
export const HazardCondition = (props: ConditionProps) => (
  <BaseCondition
    variant="default"
    {...props}
  />
)
export const HazardDSCondition = (props: ConditionProps) => (
  <BaseCondition
    variant="ds"
    {...props}
  />
)
