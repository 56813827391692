import { useTranslate } from "@tolgee/react"
import { KeyboardEvent, useEffect, useRef } from "react"
import { EditIcon } from "../../../../climateui/icons"
import { usePlan } from "../PlanProvider"

export const COMMON_INPUT_CLASSES =
  "px-1 border border-transparent focus:outline-none focus:border-accent cursor-pointer focus:cursor-text rounded-md hover:border-gray-14 dark:border-gray-78 dark:bg-dark-bg placeholder:text-gray-30"

function PlanNameAndDescription() {
  const { workingPlan, setWorkingPlan } = usePlan()

  const { t } = useTranslate()

  const handleKeyUp = (event: KeyboardEvent, propName: string) => {
    const target = event.target as HTMLInputElement
    setWorkingPlan({
      ...workingPlan,
      [propName]: target.value,
    })
  }

  const nameRef = useRef<HTMLInputElement>(null)
  const descRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (nameRef.current) nameRef.current.value = workingPlan?.name ?? ""
    if (descRef.current) descRef.current.value = workingPlan?.description ?? ""
  }, [workingPlan])

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-row group relative">
        {!workingPlan?.name && (
          <span className="text-red title-lg absolute left-1 top-0">*</span>
        )}

        <input
          ref={nameRef}
          className={[
            "title-lg text-light-text dark:text-dark-text pr-7",
            "min-w-[280px]",
            !workingPlan?.name ? "pl-4" : "",
            COMMON_INPUT_CLASSES,
          ].join(" ")}
          defaultValue={workingPlan?.name}
          placeholder={t("clickToAddName", "Click to add a name")}
          onKeyUp={(event: KeyboardEvent) => {
            handleKeyUp(event, "name")
          }}
        />

        <span
          className={[
            workingPlan?.name
              ? "fill-gray-100 hidden group-hover:block"
              : "fill-gray-30",
            "w-5 z-50 -ml-7 shrink-0",
          ].join(" ")}>
          <EditIcon />
        </span>
      </div>

      <div className="flex flex-row group">
        <input
          ref={descRef}
          className={
            "label-sm text-gray-60 dark:text-gray-30 pr-5 " +
            "min-w-[220px] " +
            COMMON_INPUT_CLASSES
          }
          defaultValue={workingPlan?.description}
          placeholder={t("clickToAddDescription")}
          onKeyUp={(event: KeyboardEvent) => {
            handleKeyUp(event, "description")
          }}
        />

        <span
          className={[
            workingPlan?.description
              ? "fill-gray-100 hidden group-hover:block"
              : "fill-gray-30",
            "w-4 z-50 -ml-5 shrink-0",
          ].join(" ")}>
          <EditIcon />
        </span>
      </div>
    </div>
  )
}

export default PlanNameAndDescription
