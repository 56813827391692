import http, { QuerySet } from "../../climateui/utils/http"
import config from "../config"

export const locationListSearchGET = (data, config = {}) =>
  http.post("/location/location/search/", data, true, config)

export const getIdOrSelfIfString = (element) =>
  typeof element === "string" ? element : element.id

export const locationPOST = async (location) => {
  location.timezone = ""
  location = {
    ...location,
    varieties: location.location_varieties,
    labels: location.labels?.map((label) => getIdOrSelfIfString(label)) ?? [],
  }
  return await http.post("/location/location/", location)
}

export async function locationPUT({ location_id, payload }) {
  delete payload.active
  delete payload.created_at
  delete payload.updated_at
  delete payload.id
  delete payload.regions
  delete payload.region
  delete payload.popupText
  delete payload.regionName
  payload = {
    ...payload,
    varieties: [...payload.location_varieties],
    labels: payload.labels?.map((label) => getIdOrSelfIfString(label)) ?? [],
  }
  // do not send location_varieties to the backend since it's not a valid field
  delete payload.location_varieties
  return await http.put(`/location/location/${location_id}/`, payload)
}

export const locationDELETE = async (location_id) => {
  return await http.delete(`/location/location/${location_id}/`)
}

export const locationsBulkDELETE = async (locationIDs) => {
  return await Promise.all(locationIDs.map((locID) => locationDELETE(locID)))
}

// Depends on query service nested x fields.
// const locationMainXFields =
//     "id,name,account_id,latitude,longitude,timezone,active"
// const locationRegionsXFields = "regions{id,name,full_name,resolution}"
// const locationLabelsXFields = "labels{id,name,color}"
// const locationVarietiesXFields = "varieties"
// "{id,name,account_id,description,asset{id,name,description},is_default,variety_metadata}"

export const accountLocationsGRAPHQL = (account_ids) => {
  return http.post(
    process.env.REACT_APP_QUERY_URL + "/graphql",
    {
      query: `
            query($account_ids: [String]) {
                locations(filter: { account_ids: $account_ids }) {
                    results {
                        id
                        name
                        account_id
                        latitude
                        longitude
                        timezone
                        active
                        regions {
                            id
                            name
                            full_name
                            resolution
                        }
                        labels {
                            id
                            name
                            color
                        }
                        location_varieties {
                            location_id
                            variety_id
                            initial_date
                        }
                        varieties {
                            results {
                                id
                                name
                                description
                                asset {
                                    id
                                    name
                                    description
                                }
                                account_id
                                is_default
                                variety_metadata
                            }
                        }
                    }
                }
            }
        `,
      variables: { account_ids: account_ids },
    },
    // true,
    // {
    //     headers: {
    //         "X-Fields": `data {${locationMainXFields},${locationRegionsXFields},${locationLabelsXFields},${locationVarietiesXFields}}`,
    //     },
    // }
  )
}

export const regionsQuerySet = new QuerySet(
  "/region",
  ["get", "post", "put", "delete"],
  config.location_platform,
)

export const pointRegionPOST = async (mapCoordinates) => {
  return await http.post(`/location/region/point`, {
    latitude: parseFloat(mapCoordinates.lat),
    longitude: parseFloat(mapCoordinates.lon),
  })
}
