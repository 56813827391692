import { useState } from "react"
// TODO either bring utils inside the climateui or pass this by props in the admin-con
// import { formattedColorsMatrixForColorPicker } from "../../../utils/constants"
import { useOutsideComponentClickHandler } from "../../hooks"
import FixedLayer from "../FixedLayer"
import ColorPicker from "./ColorPicker"

function ColorPickerButton({
    selectedColor,
    // colors = formattedColorsMatrixForColorPicker,
    colors = [],
    setSelectedColor,
    popupMarginClasses = "mr-10 -mb-16",
}: {
    selectedColor: string
    colors: string[][]
    setSelectedColor: (color: string) => void
    popupMarginClasses?: string
}) {
    const [open, toggle] = useState<boolean>(false)
    const popupRef = useOutsideComponentClickHandler(() => {
        if (open) toggle(false)
    })
    return (
        <div
            className="relative w-full h-full rounded-sm cursor-pointer"
            onClick={() => toggle(!open)}
            ref={popupRef}>
            <div
                className="absolute rounded-sm inset-[2px]"
                style={{
                    backgroundColor: selectedColor,
                }}></div>
            {open && (
                <FixedLayer
                    xPosition="top"
                    yPosition="top">
                    <div
                        className={
                            "p-2 bg-light-bg dark:bg-dark-bg border rounded-lg elevation-2 border-gray-14 dark:border-gray-78 " +
                            popupMarginClasses
                        }>
                        <ColorPicker
                            selected={selectedColor}
                            colors={colors}
                            onColorSelect={(value: string) => {
                                setSelectedColor(value)
                            }}
                        />
                    </div>
                </FixedLayer>
            )}
        </div>
    )
}

export default ColorPickerButton
