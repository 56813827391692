import { ReactNode } from "react"

export interface IStep {
    name: string
    icon?: ReactNode
}

const Step = ({ type, step }: { type: string; step: IStep }) => {
    const { name, icon } = step

    return (
        <div className="relative flex flex-row items-center w-0 h-0 overflow-visible">
            <div className="w-[0px] flex flex-row justify-center">
                <div
                    className={[
                        "shrink-0 h-[20px] w-[20px] sm:h-[14px] sm:w-[14px] rounded-full z-10",
                        "relative border-[1.8px] bg-light-bg dark:bg-dark-bg",
                        type === "empty" ? "border-gray-30" : "border-accent",
                    ].join(" ")}>
                    {type === "full" && (
                        <div className="absolute rounded-full inset-[1.6px] bg-accent"></div>
                    )}
                </div>
            </div>
            <div
                className={[
                    "absolute top-3 -translate-x-1/2",
                    "whitespace-nowrap",
                    "flex flex-row items-center gap-2",
                    type === "current"
                        ? "text-light-text dark:text-dark-text label-lg fill-gray-60"
                        : "text-gray-60 dark:text-gray-30 body-md fill-gray-30",
                ].join(" ")}>
                {icon && <span className="w-5 h-5">{icon}</span>}
                <p>{name}</p>
            </div>
        </div>
    )
}

const Steps = ({
    steps,
    currentStep,
}: {
    steps: IStep[]
    currentStep: number
}) => {
    const sections = steps.length - 1

    if (currentStep >= steps.length) currentStep = steps.length - 1
    let orangeBarWidth = (100 / sections) * currentStep
    if (orangeBarWidth > 100) orangeBarWidth = 100

    return (
        <div className="relative flex flex-row items-center justify-between w-full h-1 -mt-3 group bg-gray-14">
            <div
                className="absolute left-0 h-1 bg-accent transition-all"
                style={{ width: orangeBarWidth + "%" }}></div>
            {steps.map((step, index) => (
                <Step
                    type={
                        index < currentStep
                            ? "full"
                            : index === currentStep
                            ? "current"
                            : "empty"
                    }
                    step={step}
                    key={step + "-" + index}
                />
            ))}
        </div>
    )
}

export default Steps
