import { useTranslate } from "@tolgee/react"
import { DateTime } from "luxon"
import { useContext, useState } from "react"
import { DatePicker, FixedElement } from "../climateui/components"
import { YEAR } from "../climateui/components/DatePicker"
import { useOutsideComponentClickHandler } from "../climateui/hooks"
import { CalendarIcon } from "../climateui/icons"
import { LocalizationContext } from "../providers/LocalizationProvider"

const formatDate = (date: Date, monthNames: string[], isSmall: boolean) => {
  let month = monthNames[date.getMonth()]
  const day = date.getDate()

  if (isSmall) month = month.slice(0, 3)
  return month + " " + day
}

function StartDateInput({
  date,
  setDate,
  isSmall = false,
  isYearAgnostic = true,
  canClearInput = true,
  customPlaceholder,
  isInvalid = false,
}: {
  date?: Date
  setDate: (date?: Date) => void
  isSmall?: boolean
  isYearAgnostic?: boolean
  canClearInput?: boolean
  customPlaceholder?: string
  isInvalid?: boolean
}) {
  const { t } = useTranslate()
  const { monthNames } = useContext(LocalizationContext)
  const [open, setOpen] = useState(false)

  const datePickerRef = useOutsideComponentClickHandler(() => setOpen(false))

  return (
    <div
      className="relative"
      ref={datePickerRef}>
      <FixedElement
        open={open}
        position="bottom"
        align={isSmall ? "left" : "center"}
        parentElement={
          <div
            className={[
              "w-fit min-w-full",
              isSmall ? "h-8" : "h-[42px]",
              "flex flex-row items-center justify-between",
              "cursor-pointer",
              "rounded-md border-[1px]",
              isInvalid ? "border-red" : " border-gray-14 dark:border-gray-78",
              "py-1 pl-1.5 pr-1",
              isInvalid ? "text-red" : "text-gray",
            ].join(" ")}
            onClick={() => (open ? null : setOpen(true))}>
            <span className="whitespace-nowrap">
              {!date && (
                <span className={isInvalid ? "text-red-500" : ""}>
                  {customPlaceholder || t("selectDate", "Select date")}
                </span>
              )}
              {date &&
                (isSmall ? t("startOn", "Start on") + " " : "") +
                  formatDate(date, monthNames, isSmall)}
            </span>
            <span
              className={`block w-6 h-6 ml-2 ${
                isInvalid ? "fill-red-500" : "fill-gray-60"
              }`}>
              <CalendarIcon />
            </span>
          </div>
        }>
        <DatePicker
          clearMessage={t("clearAll", "Clear All")}
          canClearInput={canClearInput}
          yearAgnostic={isYearAgnostic}
          initialDate={date ? DateTime.fromJSDate(date) : undefined}
          maxView={YEAR}
          onChange={(startDate) => {
            if (startDate) {
              const newStartDate = new Date(
                startDate.year,
                startDate.month - 1,
                startDate.day,
              )
              setDate(newStartDate)
            } else setDate(undefined)
            setOpen(false)
          }}
        />
      </FixedElement>
    </div>
  )
}

export default StartDateInput
