import http, { QuerySet } from "../../climateui/utils/http"
import config from "../config"
import { PRODUCT_IDS } from "../constants"

/* RISK PROFILES */
export const riskProfileQuerySet = new QuerySet(
  "/risk_profile",
  ["get", "post", "put", "delete"],
  config.risk_platform,
)

/* RISK PROFILES LABELS */
export const rpLabelsQuerySet = new QuerySet(
  "/label",
  ["get", "post", "put", "delete"],
  config.risk_platform,
)

/* RISK PROFILES TEMPLATES */
export const riskProfileTemplateQuerySet = new QuerySet(
  "/risk_profile_template",
  ["get"],
  config.risk_platform,
)

/* HAZARD VARIABLES */
export const hazardVariableQuerySet = new QuerySet(
  "/hazard_variable/",
  ["get"],
  config.risk_platform,
)

/* IMPACT FUNCTIONS */
export const impactFunctionQuerySet = new QuerySet(
  "/impact_function/",
  ["get"],
  config.risk_platform,
)

/* ALERT SETTINGS */
export const alertSettingsQuerySet = new QuerySet(
  "/risk_setting/",
  ["get", "post", "put", "delete"],
  config.risk_platform,
)

/* RISK PROFILES GRAPHQL */
export const riskProfilesGRAPHQL = (filter, stageName = "") =>
  http.post(process.env.REACT_APP_QUERY_URL + "/graphql", {
    query: `query($filter: RiskProfileFilter, $riskSettingsFilter: RiskSettingFilter, $activeRiskSettingsFilter: RiskSettingFilter, $stageFilter: StageFilter) {
            risk_profiles(filter: $filter) {
                    info {
                        count
                    }
                    results {
                      id
                      account_id
                      status
                      name
                      probability
                      type
                      created_at
                      updated_at
                      risk_settings(filter: $riskSettingsFilter) {
                        info {
                            count
                        }
                      }
                      active_risk_settings: risk_settings(filter: $activeRiskSettingsFilter) {
                        results {
                            location_id
                        }
                        info {
                            count
                        }
                      }
                      labels {
                        color
                        name
                        created_at
                        id
                        updated_at
                      }
                      hazard_profiles {
                        results {
                          id
                          risk_profile_id
                          hazard_variable_id
                          conditional
                          type
                          threshold
                          window
                          logical_op
                          created_at
                          updated_at
                          hazard_variable {
                            id
                            readable_name
                            readable_variable
                            dashboard_variable
                            zarr_variable
                            units
                            units_imperial
                            units_metric
                            aggregation
                            created_at
                            updated_at
                          }
                        }
                      }
                      impact_profile {
                        impact_function {
                          id
                          readable_name
                          backend_name
                          type
                          aggregation
                          created_at
                          updated_at
                        }
                        initial_impact
                        marginal_impact
                        max_impact
                        created_at
                        updated_at
                      }
                      varieties {
                        results {
                          id
                          name
                          description
                          asset {
                              id
                              name
                          }
                          account_id
                          variety_metadata
                          is_default
                          created_at
                          updated_at
                        }
                      }
                      stages(filter: $stageFilter) {
                        id
                        name
                        color
                        duration
                        days_from_start
                        variety_id
                      }
                    }
                  }
        }`,
    variables: {
      filter,
      riskSettingsFilter: {
        product_ids: [PRODUCT_IDS.ALERT_SETTINGS, PRODUCT_IDS.STAGE_MANAGER],
      },
      activeRiskSettingsFilter: {
        status: "active",
        product_ids: [PRODUCT_IDS.ALERT_SETTINGS, PRODUCT_IDS.STAGE_MANAGER],
      },
      stageFilter: {
        stage_name: stageName,
      },
    },
  })
/* HAZARD VARIABLES GRAPHQL */
export const hazardVariablesGRAPHQL = () =>
  http.post(process.env.REACT_APP_QUERY_URL + "/graphql", {
    query: `query {
            hazard_variables {
                results {
                  id
                  readable_name
                  readable_variable
                  dashboard_variable
                  units
                  units_imperial
                  units_metric
                  aggregation
                  created_at
                  updated_at
                }
          }
        }`,
  })
