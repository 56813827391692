import { v4 as uuidv4 } from "uuid"
import { IBooleanDictionary } from "../../../climateui/types"
import { colorsArr } from "../../../climateui/utils/colors"
import { EDITION_ACTIONS } from "../../../components/SeasonalCalendar/components/utils"
import {
  IHazardProfile,
  IInsightsLocation,
  IPlan,
  IPlannedRisk,
  IRiskProfile,
  IStage,
  IStrategy,
  ITimeSeries,
  IVariety,
  IVarietyMetadata,
} from "../../../types"
import {
  formatYearAgnosticDate,
  newStartAndEndDatesFromYearAgnosticDateString,
  TODAY,
} from "../../../utils/dates"
import { aggregateDailyToWeekly } from "../../../utils/transform"
import { daysBetween } from "./../../../utils"
import { determineFirstStageYear } from "./stageUtils"

// Functions used to merge our existing strategiesObj
// with the prepopulation results.
export const mergePrepopulates = (
  prepopulates: IStrategy[],
  strategiesObj: Record<string, IStrategy>,
  locationAssetIdStrategyMap: Record<string, string[]>,
  riskProfilesObj: Record<string, IRiskProfile>,
) => {
  const newStrategiesObj: Record<string, IStrategy> = {
    ...strategiesObj,
  }
  prepopulates.forEach((prepopulate: IStrategy) => {
    const locationVarietyAssetId =
      (prepopulate.location_id ?? "") + (prepopulate.asset_variety_id ?? "")

    locationAssetIdStrategyMap[locationVarietyAssetId].forEach((strategyId) => {
      const oldStages = newStrategiesObj[strategyId].stages ?? []
      const oldRisks = newStrategiesObj[strategyId].planned_risks ?? []

      // If we already have info, do not merge prepopulates
      if (oldStages.length !== 0 || oldRisks.length !== 0) return

      let stageColorIndex = 0
      const newStages: IStage[] = []
      const newRisks: IPlannedRisk[] = []
      prepopulate.stages?.forEach((stage: IStage) => {
        const start_date = new Date(stage.start_date ?? "")
        const end_date = new Date(stage.end_date ?? "")
        const newStageId = uuidv4()

        const stagePlannedRisks = stage.planned_risks
        delete stage.planned_risks
        newStages.push({
          ...stage,
          color: colorsArr[stageColorIndex % colorsArr.length],
          duration: daysBetween(start_date, end_date),
          start_date,
          end_date,
          strategyId,
          action: EDITION_ACTIONS.added,
          id: newStageId,
        })
        stageColorIndex = stageColorIndex + 1
        stagePlannedRisks?.forEach((prepopulate_risk: IPlannedRisk) => {
          const start_date = new Date(prepopulate_risk.start_date ?? "")
          const end_date = new Date(prepopulate_risk.end_date ?? "")
          if (riskProfilesObj[prepopulate_risk.risk_profile_id ?? ""])
            newRisks.push({
              ...prepopulate_risk,
              frontend_id: uuidv4(),
              duration: daysBetween(start_date, end_date),
              start_date,
              end_date,
              strategyId,
              name: riskProfilesObj[prepopulate_risk.risk_profile_id ?? ""]
                .name,
              action: EDITION_ACTIONS.added,
              stage_id: newStageId,
            })
        })
      })

      newStrategiesObj[strategyId].stages = newStages
      newStrategiesObj[strategyId].planned_risks = newRisks
    })
  })
  return newStrategiesObj
}

const calculateImpact = (arr: (number | null)[]) => {
  if (arr.every((element) => element === null)) return null
  return arr.reduce((a, b) => {
    if (a === null && b === null) {
      return 0
    }
    if (a === null || isNaN(a)) {
      return b
    }
    if (b === null || isNaN(b)) {
      return a
    }
    return a + b
  }, 0)
}

const IMPACT_POSSIBLE_VALUES = {
  no_data: null,
  calculating: undefined,
}

function getPlannedRiskImpactValue(value?: number[] | null | undefined) {
  /*
        Possible Impact Values:
        Num       : Existing Impact (0 included)
        '[]'      : No data
        null      : Still calculating
        undefined : Still calculating
    */
  if (value === undefined || value === null)
    return IMPACT_POSSIBLE_VALUES.calculating
  if (Array.isArray(value) && !value.length)
    return IMPACT_POSSIBLE_VALUES.no_data
  return calculateImpact(value)
}

class ImpactTracker {
  strategyExpected: (number | null)[]
  strategyObserved: (number | null)[]

  stillCalculatingObserved: boolean
  stillCalculatingExpected: boolean

  constructor() {
    this.strategyExpected = []
    this.strategyObserved = []
    this.stillCalculatingObserved = false
    this.stillCalculatingExpected = false
  }
}

class PlannedRisk {
  id?: string
  start_date?: Date
  end_date?: Date
  duration?: number
  name?: string
  hazard_profiles?: IHazardProfile[]

  risk_profile_id?: string
  observed_impact_time_series?: ITimeSeries | null
  expected_impact_time_series?: ITimeSeries | null
  observed_impact?: number | null
  expected_impact?: number | null

  frontend_id?: string // FE id for logic
  strategyId?: string | number // FE id for UI
  location_name?: string // To show in PlannedRisk hover
  action?: string // for PUT
  type?: string // action type [stages | risks]
  stage_id?: string

  constructor(planned_risk: IPlannedRisk, referenceDate?: Date) {
    this.id = planned_risk.id
    if (typeof planned_risk.start_date === "string") {
      const [startDate, endDate] =
        newStartAndEndDatesFromYearAgnosticDateString(
          planned_risk.start_date + "",
          planned_risk.end_date + "",
          referenceDate ?? TODAY,
        )
      this.start_date = startDate
      this.end_date = endDate
    } else {
      this.start_date = planned_risk.start_date as Date
      this.end_date = planned_risk.end_date as Date
    }
    this.duration = daysBetween(this.start_date, this.end_date)
    this.risk_profile_id = planned_risk.risk_profile_id ?? ""

    this.observed_impact_time_series = planned_risk.observed_impact_time_series
    this.expected_impact_time_series = planned_risk.expected_impact_time_series
    this.observed_impact = getPlannedRiskImpactValue(
      this.observed_impact_time_series?.data,
    )
    this.expected_impact = getPlannedRiskImpactValue(
      this.expected_impact_time_series?.data,
    )

    this.frontend_id = uuidv4()
    this.type = "risks"
    if (planned_risk.stage_id) this.stage_id = planned_risk.stage_id
  }

  setImpactValues(impactTracker: ImpactTracker) {
    const observed_time_series = this.observed_impact_time_series
    if (observed_time_series?.data && observed_time_series.data.length > 0) {
      this.observed_impact_time_series =
        aggregateDailyToWeekly(observed_time_series)
    }

    const expected_time_series = this.expected_impact_time_series
    if (expected_time_series?.data && expected_time_series.data.length > 0) {
      this.expected_impact_time_series =
        aggregateDailyToWeekly(expected_time_series)
    }

    // Only append to array if value is valid or null
    if (this.observed_impact === IMPACT_POSSIBLE_VALUES.calculating) {
      impactTracker.stillCalculatingObserved = true
    } else {
      impactTracker.strategyObserved.push(this.observed_impact)
    }

    // Only append to array if value is valid or null
    if (this.expected_impact === IMPACT_POSSIBLE_VALUES.calculating) {
      impactTracker.stillCalculatingExpected = true
    } else {
      impactTracker.strategyExpected.push(this.expected_impact)
    }
  }
}

class Stage {
  id?: string
  end_date?: Date
  start_date?: Date
  duration?: number
  color?: string
  name?: string
  is_first_stage?: boolean

  deleteStage?: () => void
  index?: number
  strategyId?: string | number
  action?: string
  type?: string

  constructor(stage: IStage, referenceDate?: Date) {
    this.id = stage.id
    if (typeof stage.start_date === "string") {
      const [startDate, endDate] =
        newStartAndEndDatesFromYearAgnosticDateString(
          stage.start_date + "",
          stage.end_date + "",
          referenceDate ?? TODAY,
        )
      this.start_date = startDate
      this.end_date = endDate
    } else {
      this.start_date = stage.start_date as Date
      this.end_date = stage.end_date as Date
    }
    this.duration = daysBetween(this.start_date, this.end_date)
    this.color = stage.color
    this.name = stage.name
    this.is_first_stage = stage.is_first_stage
    this.type = "stages"
  }
}

class Strategy {
  id: string
  asset_id: string
  asset_variety_id: string
  location_id: string
  region_id?: string

  description?: string

  planned_risks?: IPlannedRisk[]
  stages: IStage[] = []

  asset_variety_name?: string
  asset_variety_full_name?: string
  location_name?: string
  backend_id?: string
  action?: string
  can_calculate_GDD?: boolean

  constructor(
    strategy: IStrategy,
    riskProfilesObj?: Record<string, IRiskProfile>,
  ) {
    const strategyId = strategy.id ?? uuidv4()
    this.backend_id = strategy.backend_id ?? strategyId
    this.id = strategyId

    this.location_id = strategy.location_id ?? ""
    this.asset_id = strategy.asset_id ?? ""
    this.asset_variety_id = strategy.asset_variety_id ?? ""
    this.region_id = strategy.region_id

    this.description = strategy.description ?? ""

    // First check if GDD can be calculated
    const firstStage = strategy.stages?.find((s) => s.is_first_stage)
    this.can_calculate_GDD = firstStage !== undefined

    // Handle stages
    this.stages = []

    if (strategy.stages?.length) {
      if (!this.can_calculate_GDD || !firstStage) {
        strategy.stages.forEach((stage) => {
          if (!stage.start_date || !stage.end_date) return

          const newStage = new Stage(stage)
          newStage.strategyId = strategyId

          const startDateStr =  typeof stage.start_date === "string" ? stage.start_date : formatYearAgnosticDate(stage.start_date)
          const endDateStr =  typeof stage.end_date === "string" ? stage.end_date : formatYearAgnosticDate(stage.end_date)

          const [startDate, endDate] =
            newStartAndEndDatesFromYearAgnosticDateString(
              startDateStr,
              endDateStr,
              TODAY,
            )
          newStage.start_date = startDate
          newStage.end_date = endDate
          this.stages.push(newStage)
        })
      } else {
        const { startYear, endYear } = determineFirstStageYear(
          strategy.stages,
          TODAY,
        )

        const firstStageStartDateStr =  typeof firstStage.start_date === "string" ? firstStage.start_date : formatYearAgnosticDate(firstStage.start_date)
        const firstStageEndDateStr = typeof firstStage.end_date === "string" ? firstStage.end_date : formatYearAgnosticDate(firstStage.end_date)
        
        // Create the actual first stage dates using the determined years
        const [firstStageStartDate, firstStageEndDate] = newStartAndEndDatesFromYearAgnosticDateString(
          firstStageStartDateStr,
          firstStageEndDateStr,
          new Date(startYear, 0, 1)
        )

        strategy.stages.forEach((stage) => {
          if (!stage.start_date || !stage.end_date || !firstStage) return

          const startDateStr =  typeof stage.start_date === "string" ? stage.start_date : formatYearAgnosticDate(stage.start_date)
          const endDateStr =  typeof stage.end_date === "string" ? stage.end_date : formatYearAgnosticDate(stage.end_date)

          const newStage = new Stage(stage)
          newStage.strategyId = strategyId

          // If this is first stage, use calculated dates directly
          if (stage.is_first_stage) {
            newStage.start_date = firstStageStartDate
            newStage.end_date = firstStageEndDate
          } else {
            // For non-first stages, find next occurrence after first stage start
            const [startDate, endDate] = newStartAndEndDatesFromYearAgnosticDateString(
              startDateStr,
              endDateStr,
              firstStageStartDate
            )

            newStage.start_date = startDate
            newStage.end_date = endDate
          }

          this.stages.push(newStage)
        })
      }
    }

    // Handle planned risks
    this.planned_risks = []
    const impactTracker = new ImpactTracker()
    const setupPlannedRisk = (
      plannedRisk: IPlannedRisk,
      referenceDate?: Date,
    ) => {
      const risk_profile_id = plannedRisk.risk_profile_id ?? ""

      if (riskProfilesObj?.[risk_profile_id]) {
        const newPlannedRisk = new PlannedRisk(plannedRisk, referenceDate)
        newPlannedRisk.name = riskProfilesObj[risk_profile_id].name
        newPlannedRisk.hazard_profiles =
          riskProfilesObj[risk_profile_id].hazard_profiles
        newPlannedRisk.strategyId = strategyId

        newPlannedRisk.setImpactValues(impactTracker)

        if (this.planned_risks) this.planned_risks.push(newPlannedRisk)
      }
    }

    // Handle non-stage planned risks with TODAY as reference
    strategy.planned_risks?.forEach((plannedRisk) =>
      setupPlannedRisk(plannedRisk, TODAY),
    )

    // Handle stage-associated planned risks using the stage's start date as reference
    strategy.stages?.forEach((stage) => {
      // Find the corresponding processed stage from this.stages
      const processedStage = this.stages.find((s) => s.id === stage.id)
      if (!processedStage) return

      stage.planned_risks?.forEach((plannedRisk) => {
        plannedRisk.stage_id = stage.id
        setupPlannedRisk(plannedRisk, processedStage.start_date)
      })
    })
  }

  setLocationName(locationName: string) {
    this.location_name = locationName
    this.planned_risks?.forEach((planned_risk) => {
      planned_risk.location_name = locationName
    })
  }

  setAssetVarietyName(assetVarietyName: string) {
    this.asset_variety_name = assetVarietyName
  }

  setAssetVarietyFullName(assetVarietyFullName: string) {
    this.asset_variety_full_name = assetVarietyFullName
  }

  // For duplicatesLogic
  updateLocationAssetIdMap(
    locationAssetIdStrategyMap: Record<string, string[]>,
  ) {
    const locationVarietyAssetId = this.location_id + this.asset_variety_id
    const strategyIdsArr =
      locationAssetIdStrategyMap[locationVarietyAssetId] || []
    strategyIdsArr.push(this.id)
    locationAssetIdStrategyMap[locationVarietyAssetId] = strategyIdsArr
  }

  updateSelectedLocationsAssets(
    selectedLocationsAssetVarieties: Record<string, IBooleanDictionary>,
  ) {
    selectedLocationsAssetVarieties[this.location_id] = {
      ...selectedLocationsAssetVarieties[this.location_id],
      [this.asset_variety_id]: true,
    }
  }
}

function getVarietyFullName(variety: IVariety) {
  if (variety.asset) return `${variety.asset.name} (${variety.name})`
  return variety.name
}

// Function to build the strategies object from
// the selected locations and assets.
// Executed before prepopulation (used or not)
export const buildStrategies = (
  selectedLocationsAssetVarieties: Record<string, IBooleanDictionary>,
  strategiesObj: Record<string, IStrategy>,
  locationAssetIdStrategyMap: Record<string, string[]>,
  locationsObj: Record<string, IInsightsLocation>,
  riskProfilesObj: Record<string, IRiskProfile>,
  varieties: Record<string, IVariety>,
) => {
  const newStrategiesObj: Record<string, IStrategy> = {}
  const newLocationAssetIdStrategyMap: Record<string, string[]> = {}

  Object.keys(selectedLocationsAssetVarieties).forEach((locationId) => {
    const regionId = locationsObj[locationId].region?.id

    Object.keys(selectedLocationsAssetVarieties[locationId]).forEach(
      (assetVarietyID) => {
        const locationVarietyAssetId = locationId + assetVarietyID
        // when plan is new
        let strategyIds = [uuidv4()]

        if (locationAssetIdStrategyMap[locationVarietyAssetId]) {
          // when plan is being updated or a new plan goes from step 3 -> 2 -> 3
          strategyIds = locationAssetIdStrategyMap[locationVarietyAssetId]
        }
        strategyIds.forEach((strategyId) => {
          const strategy: IStrategy = strategiesObj?.[strategyId] ?? {}
          strategy.id = strategyId
          strategy.region_id = regionId
          strategy.location_id = locationId
          strategy.asset_id = varieties[assetVarietyID].asset_id
          strategy.asset_variety_id = assetVarietyID
          strategy.backend_id = strategiesObj[strategyId]?.backend_id
          const newStrategy = new Strategy(strategy, riskProfilesObj)

          if (varieties[newStrategy.asset_variety_id]) {
            newStrategy.setLocationName(
              locationsObj[newStrategy.location_id]?.name || "My location",
            )
            newStrategy.setAssetVarietyName(
              varieties[newStrategy.asset_variety_id]?.name || "",
            )
            newStrategy.setAssetVarietyFullName(
              getVarietyFullName(varieties[newStrategy.asset_variety_id]),
            )

            newStrategy.updateLocationAssetIdMap(newLocationAssetIdStrategyMap)

            newStrategiesObj[newStrategy.id] = newStrategy
          }
        })
      },
    )
  })
  return {
    newStrategiesObj,
    newLocationAssetIdStrategyMap,
  }
}

// Function executed when we enter a plan detail view
// to get the correct data format required by the FE,
// to calculate each level (strategy, risk) impact,
// and to build the selectedLocationsAssetVarieties object required
// by the first and second steps.
export const buildStrategiesObjFromBE = (
  strategies: IStrategy[], // from BE
  riskProfilesObj: Record<string, IRiskProfile>,
  locationsObj: Record<string, IInsightsLocation>,
  varieties: Record<string, IVariety>,
) => {
  const newStrategiesObj: Record<string, IStrategy> = {}
  const newLocationAssetIdStrategyMap: Record<string, string[]> = {}
  const newSelectedLocationsAssetVarieties: Record<string, IBooleanDictionary> =
    {}
  // Exclude strategies pointing to deleted locations
  strategies = strategies.filter(
    (strategy) => strategy?.location_id && strategy.location_id in locationsObj,
  )

  strategies.forEach((strategy: IStrategy) => {
    const newStrategy = new Strategy(strategy, riskProfilesObj)
    if (varieties[newStrategy.asset_variety_id]) {
      newStrategy.setLocationName(
        locationsObj[newStrategy.location_id]?.name || "My location",
      )
      newStrategy.setAssetVarietyName(
        varieties[newStrategy.asset_variety_id]?.name || "",
      )
      newStrategy.setAssetVarietyFullName(
        getVarietyFullName(varieties[newStrategy.asset_variety_id]),
      )
      newStrategy.updateLocationAssetIdMap(newLocationAssetIdStrategyMap)
      newStrategy.updateSelectedLocationsAssets(
        newSelectedLocationsAssetVarieties,
      )

      newStrategiesObj[newStrategy.id] = newStrategy
    }
  })

  return {
    newStrategiesObj,
    newLocationAssetIdStrategyMap,
    newSelectedLocationsAssetVarieties,
  }
}

function setAction(item: IStrategy | IPlannedRisk | IStage) {
  if (!item.action) {
    item.action = EDITION_ACTIONS.none
  } else if (item.action === EDITION_ACTIONS.added) {
    delete item.id
  }
}

export function cleanPlanPayload(plan: IPlan, isEditing = false) {
  if (isEditing) {
    plan.action = EDITION_ACTIONS.edited
    delete plan.created_by
  } else {
    delete plan.id
    delete plan.action
    delete plan.updated_at
  }
  delete plan.selectedLocationsAssetVarieties

  if (!plan.description) plan.description = ""

  plan.strategies?.forEach((strategy: IStrategy) => {
    if (isEditing) {
      strategy.id = strategy.backend_id
      setAction(strategy)
    } else {
      delete strategy.id
      delete strategy.action
    }
    delete strategy.asset_variety_name
    delete strategy.location_name
    delete strategy.backend_id
    delete strategy.asset_id

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const stagePlannedRisks: Record<string, any> = {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const otherPlannedRisks: any[] = []

    const editedStagesIds = new Set<string>([])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    strategy.planned_risks?.forEach((risk: any) => {
      if (isEditing) {
        setAction(risk)
        if (risk.action) editedStagesIds.add(risk.stage_id)
      } else {
        delete risk.id
        delete risk.action
      }
      delete risk.duration
      delete risk.hazard_profiles
      delete risk.name
      delete risk.frontend_id
      delete risk.strategyId
      delete risk.location_name
      delete risk.type
      delete risk.observed_impact
      delete risk.expected_impact
      delete risk.observed_impact_time_series
      delete risk.expected_impact_time_series
      delete risk.varieties
      delete risk.risk_settings
      delete risk.labels
      delete risk.impact_profile
      delete risk.active_risk_settings
      delete risk.account_id
      delete risk.status
      delete risk.probability

      risk.start_date = formatYearAgnosticDate(risk.start_date)
      risk.end_date = formatYearAgnosticDate(risk.end_date)
      if (risk.stage_id) {
        stagePlannedRisks[risk.stage_id] = [
          ...(stagePlannedRisks[risk.stage_id] ?? []),
          risk,
        ]
        delete risk.stage_id
      } else {
        otherPlannedRisks.push(risk)
      }
    })
    strategy.planned_risks = otherPlannedRisks

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    strategy.stages?.forEach((stage: any) => {
      if (stage.id && stagePlannedRisks[stage.id])
        stage.planned_risks = stagePlannedRisks[stage.id]

      if (isEditing) {
        setAction(stage)
        if (editedStagesIds.has(stage.id))
          stage.action = stage?.action || EDITION_ACTIONS.edited
      } else {
        delete stage.id
        delete stage.action
      }
      delete stage.duration
      delete stage.deleteStage
      delete stage.index
      delete stage.strategyId
      delete stage.type
      stage.start_date = formatYearAgnosticDate(stage.start_date)
      stage.end_date = formatYearAgnosticDate(stage.end_date)
    })
  })

  return plan
}

// Function used before the planPUT to clean the payload.
export function createEditedPlanObject(
  plan: IPlan,
  originalStrategiesObj: Record<string, IStrategy>,
  modifiableStrategiesObj: Record<string, IStrategy>,
  deletedStagesOrRisks: (IStage | IPlannedRisk)[],
) {
  // Merge deleted stages and risks if any
  deletedStagesOrRisks.forEach((deletedThing: IStage | IPlannedRisk) => {
    const strategyId: string | number = deletedThing.strategyId ?? ""
    if (modifiableStrategiesObj[strategyId]) {
      deletedThing.action = EDITION_ACTIONS.deleted
      const strategy = modifiableStrategiesObj[strategyId]
      strategy.stages = strategy.stages ?? []
      strategy.planned_risks = strategy.planned_risks ?? []
      if (deletedThing.type === "stages") {
        strategy.stages.push(deletedThing)
      } else if (deletedThing.type === "risks") {
        strategy.planned_risks.push(deletedThing)
      }
    }
    // else: Strategy was deleted
  })
  const strategies: IStrategy[] = []
  // Detect the added and deleted strategies by comparing
  // the original and modifiable objects.
  Object.keys(modifiableStrategiesObj).forEach((strategyId: string) => {
    if (!originalStrategiesObj[strategyId])
      modifiableStrategiesObj[strategyId].action = EDITION_ACTIONS.added
    strategies.push(modifiableStrategiesObj[strategyId])
  })
  Object.keys(originalStrategiesObj).forEach((strategyId: string) => {
    if (!modifiableStrategiesObj[strategyId]) {
      originalStrategiesObj[strategyId].action = EDITION_ACTIONS.deleted
      strategies.push(originalStrategiesObj[strategyId])
    }
  })

  const result = { ...plan } as IPlan
  result.strategies = strategies
  // Clean the data
  cleanPlanPayload(result, true)

  return result
}

export function sortStrategies(strategiesObj: Record<string, IStrategy>) {
  // Sort Strategies
  const sortedStrategiesObj = Object.keys(strategiesObj)
    .sort((a, b) => {
      const aLocation = strategiesObj[a].location_name ?? ""
      const bLocation = strategiesObj[b].location_name ?? ""
      const locCompare = aLocation.localeCompare(bLocation)
      if (locCompare !== 0) {
        return locCompare
      }
      const aAsset = strategiesObj[a].asset_variety_name ?? ""
      const bAsset = strategiesObj[b].asset_variety_name ?? ""
      return aAsset.localeCompare(bAsset)
    })
    .reduce(
      (accumulatedObj, key) => ({
        ...accumulatedObj,
        [key]: strategiesObj[key],
      }),
      {},
    )
  return sortedStrategiesObj
}

export function extractVarietyMetadata(
  metadata: IVarietyMetadata[],
): Record<string, string> {
  return metadata?.reduce((result: Record<string, string>, item) => {
    result[item.key] = item.value
    return result
  }, {})
}

export const isValidDate = (d: Date | undefined) => {
  return d instanceof Date && !isNaN(d.getTime())
}

export function constructRiskSettings(
  workingPlan: IPlan | undefined,
  accountId: string | undefined,
) {
  if (workingPlan === undefined || accountId === undefined) return
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resultArray: any[] = []

  workingPlan?.strategies?.forEach((strategy) => {
    const { location_id } = strategy

    // Map through planned_risks for each strategy
    strategy?.stages?.forEach((stage) => {
      stage.planned_risks?.forEach((risk) => {
        const { start_date, end_date, risk_profile_id } = risk
        // Create an object with the desired attributes and push it to the resultArray
        resultArray.push({
          location_id,
          risk_profile_id,
          accountId,
          start_date,
          end_date,
        })
      })
    })
  })
  return resultArray
}
