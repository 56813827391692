import { Transition } from "@headlessui/react"
import { useTranslate } from "@tolgee/react"
import {
  Fragment,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import {
  PortalComponent,
  Tooltip,
  TooltipV2,
} from "../../../climateui/components"
import {
  useOutsideComponentClickHandler,
  useTimedoutBooleanState,
} from "../../../climateui/hooks"
import {
  CancelIcon,
  ChevronArrow,
  InfoIcon,
  ThreeDotsIcon,
} from "../../../climateui/icons"
import { RiskIconHandler } from "../../../climateui/icons/riskIcons"
import { ModalContext } from "../../../climateui/providers"
import { colors } from "../../../climateui/utils/colors"
import { isCustomResponse } from "../../../climateui/utils/http"
import { useAccount } from "../../../providers/AccountProvider"
import { useAssets } from "../../../providers/AssetsProvider"
import { useAuth } from "../../../providers/AuthProvider"
import { LocationsContext } from "../../../providers/LocationsProvider"
import { useRiskProfiles } from "../../../providers/RiskProfilesProvider"
import { useSeasonalCalendar } from "../../../providers/SeasonalCalendarProvider"
import {
  IMaxImpactSettings,
  IPlannedRisk,
  IStage,
  IStrategy,
} from "../../../types"
import { HOUR_MS } from "../../../utils/constants"
import { TODAY } from "../../../utils/dates"
import { gddsGET } from "../../../utils/networking"
import { extractVarietyMetadata } from "../../../views/Seasonal/PlanningTool/utils"
import Stages from "./Stages"
import { sortRisksByDate } from "./utils"

const DESC_MAX_LENGTH = 70

export const smallInputClasses = [
  "w-full h-[24px] rounded-md border border-gray-14 dark:border-gray-78 bg-light-bg dark:bg-dark-bg px-2",
  "font-normal body-sm text-gray-60 dark:text-gray-30 placeholder:text-gray-30 fill-gray-60",
  "transition-all duration-75",
  "enabled:hover:border-gray-30 enabled:hover:z-[1]",
  "enabled:active:border-accent enabled:focus:border-accent",
  "focus:outline-none",
  "disabled:bg-gray-5 dark:disabled:bg-gray-60 disabled:cursor-not-allowed disabled:text-gray-30 disabled:fill-gray-30",
]

function RiskImpact({
  impact,
  isObserved = false,
}: {
  impact?: number | null
  isObserved?: boolean
}) {
  const { t } = useTranslate()
  let impactToDisplay: ReactNode | string = ""

  if (impact === undefined)
    impactToDisplay = (
      <TooltipV2
        position="top"
        content={t("loadingImpact", "Loading impact...")}>
        <div className="flex flex-row items-center justify-center title-lg text-gray-6">
          <span className="animate-pulse-fast">.</span>
          <span
            className="animate-pulse-fast"
            style={{ animationDelay: "70ms" }}>
            .
          </span>
          <span
            className="animate-pulse-fast"
            style={{ animationDelay: "140ms" }}>
            .
          </span>
        </div>
      </TooltipV2>
    )
  else if (impact === null)
    impactToDisplay = (
      <TooltipV2
        contentClass={isObserved ? "w-24" : "w-[102px]"}
        position="top"
        content={
          isObserved
            ? t("riskHasntYetOccurred", "Risk hasn't yet occurred")
            : t("beyondForecastRange", "Beyond 6 mo. forecast range")
        }>
        <div className="text-gray-30">N/A</div>
      </TooltipV2>
    )
  else {
    const impactStr = (impact * 100).toFixed(1) + "%"
    if (impactStr === "0.0%")
      impactToDisplay = <div className="text-gray-30">{impactStr}</div>
    else impactToDisplay = impactStr
  }

  return <div className="w-[65px]">{impactToDisplay}</div>
}

function RiskRow({
  risk,
  maxImpactSettings,
  removePlannedRisk,
  tooltipPosition,
}: {
  risk: IPlannedRisk
  maxImpactSettings: IMaxImpactSettings
  removePlannedRisk: () => void
  tooltipPosition: string
}) {
  const { t } = useTranslate()
  const { hasRole } = useAuth()
  const { selectedAccount } = useAccount()

  const { isEditingCalendar, setHoveredPlannedRisk, hoveredPlannedRisk } =
    useSeasonalCalendar()
  const { setCurrentRiskProfileById, setIsFormOpen } = useRiskProfiles()
  const { confirmationModal } = useContext(ModalContext)
  const navigate = useNavigate()

  function sendToRiskProfiles(risk: IPlannedRisk) {
    // TODO: Save plan if you leave!!
    confirmationModal({
      title: t("goToMyRiskProfiles", "Go to my Risk Profiles"),
      text: t(
        "navigateOutOfPlanningToolToRiskProfiles",
        "By clicking continue you will navigate out of the Strategy Tool and into your Risk Profiles page.",
      ),
      onContinueLabel: t("yes"),
      onCancelLabel: t("no"),
      onContinue: () => {
        setCurrentRiskProfileById(risk.risk_profile_id)
        setIsFormOpen(true)
        navigate("/admin/risk-profiles")
      },
    })
  }

  const { displayExpectedImpact, displayTrackingImpact } = useMemo(() => {
    // Max Impact can be an upper or lower bound so values must be capped accordingly.
    const maxImpactValue = maxImpactSettings.limit
    const isUpperBound = maxImpactSettings.type === "upper"

    const maxMinFunction = isUpperBound ? Math.min : Math.max

    const observed =
      risk.observed_impact !== null && risk.observed_impact !== undefined
        ? maxMinFunction(risk.observed_impact, maxImpactValue)
        : risk.observed_impact

    const expected =
      risk.expected_impact !== null && risk.expected_impact !== undefined
        ? maxMinFunction(maxImpactValue - (observed ?? 0), risk.expected_impact)
        : risk.expected_impact

    return {
      displayTrackingImpact: observed,
      displayExpectedImpact: expected,
    }
  }, [risk, maxImpactSettings])

  return (
    <div
      className={[
        "flex flex-row items-center",
        "h-8 pl-1 pr-4 group",
        "gap-2 body-md",
        hoveredPlannedRisk === risk.id ? "bg-gray-3 dark:bg-gray-88" : "",
      ].join(" ")}
      onMouseEnter={() => setHoveredPlannedRisk(risk.id)}>
      <div className="flex flex-row items-center gap-2 w-[280px] pr-2">
        <div className="w-5 h-5 shrink-0 grow-0">
          {isEditingCalendar && (
            <Tooltip
              position={tooltipPosition}
              content={t("removeRisk", "Remove Risk")}>
              <span
                className={[
                  "opacity-0",
                  "fill-gray-60",
                  "transition-all duration-75",
                  "group-hover:opacity-100 cursor-pointer",
                ].join(" ")}
                onClick={removePlannedRisk}>
                <CancelIcon />
              </span>
            </Tooltip>
          )}
        </div>
        <span className="w-5 h-5 fill-gray-60 shrink-0 grow-0">
          <RiskIconHandler hazardProfiles={risk["hazard_profiles"]} />
        </span>
        {hasRole(selectedAccount ?? "", "Admin") ? (
          <div
            className="underline truncate cursor-pointer underline-offset-1 whitespace-nowrap"
            onClick={() => sendToRiskProfiles(risk)}>
            {risk.name}
          </div>
        ) : (
          <div className="truncate grow whitespace-nowrap">{risk.name}</div>
        )}
      </div>
      {!isEditingCalendar && (
        <div className="flex flex-row items-center w-[130px] shrink-0 grow-0">
          <RiskImpact
            isObserved
            impact={displayTrackingImpact}
          />
          <RiskImpact impact={displayExpectedImpact} />
        </div>
      )}
    </div>
  )
}

function StrategyOptions({ strategy }: { strategy: IStrategy }) {
  const [optionsOpen, setOptionsOpen] = useState<boolean>(false)
  const optionsRef = useOutsideComponentClickHandler(() =>
    setOptionsOpen(false),
  )
  const { deleteStrategy, duplicateStrategy } = useSeasonalCalendar()
  const { t } = useTranslate()

  return (
    <div className="relative w-6 h-6 -mr-3 overflow-visible cursor-default shrink-0">
      <span
        className="w-6 h-6 cursor-pointer hover:opacity-75 transition-all duration-75 fill-gray-60"
        onClick={(e) => {
          e.stopPropagation()
          setOptionsOpen(true)
        }}>
        <ThreeDotsIcon />
      </span>

      <div ref={optionsRef}>
        <Transition
          show={optionsOpen}
          as={Fragment}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-75"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <ul
              className={[
                "absolute z-40 overflow-hidden",
                "right-0",
                "flex flex-col items-stretch",
                "py-2 w-fit elevation-2",
                "text-left body-md text-light-text dark:text-dark-text",
                "bg-light-bg dark:bg-dark-bg border rounded-lg border-gray-14 dark:border-gray-78",
              ].join(" ")}>
              {duplicateStrategy && (
                <li
                  className="px-2 py-1 cursor-pointer whitespace-nowrap hover:bg-gray-3 dark:bg-gray-90 dark:hover:bg-gray-88"
                  onClick={(e) => {
                    e.stopPropagation()
                    duplicateStrategy(strategy)
                    setOptionsOpen(false)
                  }}>
                  {t("duplicate", "Duplicate")}
                </li>
              )}
              {deleteStrategy && (
                <li
                  className="px-2 py-1 cursor-pointer whitespace-nowrap hover:bg-gray-3 dark:bg-gray-90 dark:hover:bg-gray-88"
                  onClick={(e) => {
                    e.stopPropagation()
                    deleteStrategy(strategy)
                    setOptionsOpen(false)
                  }}>
                  {t("delete", "Delete")}
                </li>
              )}
            </ul>
          </Transition.Child>
        </Transition>
      </div>
    </div>
  )
}

interface IStrategyProvider {
  open: boolean
  actualStrategy: IStrategy
  totalLevels: number
  stagesLevels: Record<string, number>
  stageHeight: number
  gddsDict: Record<string, number>
  gddForecastEndDate: Date
  isGDDLoading: boolean
}
const StrategyContext = createContext({} as IStrategyProvider)
export const useStrategy = () => useContext(StrategyContext)

function areStagesOverlapping(stage1: IStage, stage2: IStage) {
  if (!stage1.start_date || !stage1.duration || !stage2.start_date) return false
  const stage1StartDate = stage1.start_date
  const stage1EndDate = new Date(
    stage1StartDate.getFullYear(),
    stage1StartDate.getMonth(),
    stage1StartDate.getDate() + stage1.duration,
  )
  return stage2.start_date < stage1EndDate
}

function wrapStage(
  stagesMatrix: IStage[][],
  stage: IStage,
  level: number,
): void {
  const row = stagesMatrix[level]
  if (!row) {
    stagesMatrix[level] = [stage]
    return
  }
  const lastRowElement = row[row.length - 1]
  if (areStagesOverlapping(lastRowElement, stage))
    return wrapStage(stagesMatrix, stage, level + 1)
  row.push(stage)
}

function Strategy({
  strategy,
  strategyOrder,
  updateStrategy,
}: {
  strategy: IStrategy
  strategyOrder: number
  updateStrategy: (strategy: Partial<IStrategy>) => void
}) {
  const {
    isEditingCalendar,
    deleteStageOrRisk,
    openStrategies,
    setOpenStrategies,
  } = useSeasonalCalendar()
  const { riskProfilesObj } = useRiskProfiles()
  const toggle = (state: boolean) => {
    if (!strategy.id) return
    setOpenStrategies({
      ...openStrategies,
      [strategy.id]: state,
    })
  }
  const open =
    openStrategies && strategy.id && openStrategies[strategy.id]
      ? openStrategies[strategy.id]
      : false

  function getMaxImpactSettings(risk: IPlannedRisk) {
    if (!risk.risk_profile_id || !riskProfilesObj[risk.risk_profile_id])
      return {
        limit: -1,
        type: "lower",
      }
    return {
      limit: riskProfilesObj[risk.risk_profile_id].impact_profile?.max_impact,
      type:
        riskProfilesObj[risk.risk_profile_id]?.impact_profile?.marginal_impact >
        0
          ? "upper"
          : "lower",
    }
  }

  const tooltipCustomStyle =
    "relative flex items-center justify-left group transition-all duration-200 max-w-full w-fit"

  const { t } = useTranslate()

  const { sortedStages, stagesLevels, totalLevels, stageHeight, sortedRisks } =
    useMemo(() => {
      const stagesMatrix: IStage[][] = []
      const sortedStages = [...(strategy.stages ?? [])]
      sortedStages.sort((a, b) => {
        if (!a.start_date && !b.start_date) return 0
        if (!a.start_date) return 1
        if (!b.start_date) return -1
        if (a.start_date < b.start_date) return -1
        if (a.start_date > b.start_date) return 1
        return 0
      })

      let sortedRisks: IPlannedRisk[] = [...(strategy.planned_risks ?? [])]

      sortedRisks = sortedRisks.filter((risk) => {
        if (risk.risk_profile_id && riskProfilesObj[risk.risk_profile_id]) {
          return riskProfilesObj[risk.risk_profile_id].type === "custom"
        }
      })

      sortedRisks.sort(sortRisksByDate)

      for (const stage of sortedStages) {
        if (open) wrapStage(stagesMatrix, stage, 0)
        else {
          if (!stagesMatrix[0]) stagesMatrix.push([])
          stagesMatrix[0].push(stage)
        }
      }

      const stagesLevels: Record<string, number> = {}
      const totalLevels = stagesMatrix.length
      stagesMatrix.forEach((row, index) => {
        row.forEach((stage) => {
          if (!stage.id) return
          stagesLevels[stage.id] = index
        })
      })

      return {
        sortedStages,
        stagesLevels,
        totalLevels,
        stageHeight: totalLevels <= 1 ? 36 : 28,
        sortedRisks,
      }
    }, [strategy, open])

  function removePlannedRisk(index: number) {
    if (!deleteStageOrRisk) return

    const modified_risks = [...sortedRisks]
    const deletedThing = modified_risks[index]
    modified_risks.splice(index, 1)
    updateStrategy({
      planned_risks: modified_risks,
    })
    deleteStageOrRisk(deletedThing, "risks")
  }

  const { locationsObj } = useContext(LocationsContext)
  const { hasPermissions } = useAccount()
  const { varieties } = useAssets()

  const {
    data: gddsQuery,
    isLoading: isGDDLoading,
    isError: isGDDError,
    refetch: refetchGDD,
  } = useQuery(
    ["gddsQuery", strategy.location_id, strategy.asset_variety_id],
    () => {
      if (
        !strategy?.location_id ||
        !strategy.asset_variety_id ||
        !hasPermissions(["gdd_on_planning_tool"]) ||
        !varieties ||
        Object.keys(locationsObj).length === 0 ||
        Object.keys(varieties).length === 0
      )
        return null

      const location = locationsObj[strategy.location_id]
      const lat = location.latitude
      const lon = location.longitude

      // Obtain Asset Variety Metadata
      const metadataArray =
        varieties[strategy.asset_variety_id].variety_metadata
      const varietyMetadata = extractVarietyMetadata(metadataArray)

      return gddsGET(lat, lon, {
        base_temp: varietyMetadata?.gdd_base_temp,
        min_temp_threshold: varietyMetadata?.gdd_min_threshold,
        max_temp_threshold: varietyMetadata?.gdd_max_threshold,
      })
    },
    {
      staleTime: HOUR_MS,
    },
  )

  useEffect(() => {
    if (gddsQuery === null) refetchGDD()
  }, [gddsQuery, strategy])

  const { gddsDict, gddForecastEndDate, todayGDDString } = useMemo(() => {
    if (
      !hasPermissions(["gdd_on_planning_tool"]) ||
      isGDDError ||
      !isCustomResponse(gddsQuery) ||
      !gddsQuery.data
    )
      return {
        gddsDict: {},
        gddForecastEndDate: TODAY,
      }

    const gddsData = gddsQuery.data
    const data = gddsData.data
    const time = gddsData.time

    const gddsDict: Record<string, number> = {}
    let sum = 0
    let gddForecastEndDate = new Date()

    const stagesStartDate =
      sortedStages.length > 0
        ? sortedStages.reduce((prev: IStage, curr: IStage) => {
            return (prev.start_date ?? TODAY) < (curr.start_date ?? TODAY)
              ? prev
              : curr
          }).start_date ?? TODAY
        : TODAY

    for (let i = 0; i < time.length; i++) {
      const auxDate = new Date(time[i])
      if (auxDate < stagesStartDate) continue
      sum += data[i]
      gddsDict[auxDate.toDateString()] = sum
      gddForecastEndDate = auxDate
    }

    const todayGDD = gddsDict[new Date().toDateString()]
    let todayGDDString = ""
    if (todayGDD) {
      todayGDDString =
        (todayGDD.toFixed(0) || "Loading") +
        " " +
        t("gdds_to_date", "GDDs to date")
    }

    return {
      gddsDict,
      gddForecastEndDate,
      todayGDDString,
    }
  }, [sortedStages, gddsQuery, isGDDError, hasPermissions])

  const providerValue = useMemo(() => {
    return {
      open,
      actualStrategy: {
        ...strategy,
        stages: sortedStages,
        planned_risks: sortedRisks,
      },
      totalLevels,
      stagesLevels,
      stageHeight,
      gddsDict,
      gddForecastEndDate,
      isGDDLoading: isGDDLoading || gddsQuery === null,
    }
  }, [
    open,
    strategy,
    totalLevels,
    stagesLevels,
    sortedStages,
    sortedRisks,
    gddsDict,
    gddForecastEndDate,
    isGDDLoading,
    gddsQuery,
  ])

  const [descLengthError, setDescLengthError] = useTimedoutBooleanState()

  return (
    <StrategyContext.Provider value={providerValue}>
      <div className="flex flex-col">
        <div
          style={{
            height: (totalLevels || 1) * stageHeight + "px",
          }}>
          <div
            style={{
              height: 36 + "px",
            }}
            className="flex flex-row items-center justify-start pl-1 pr-4 text-left cursor-pointer h-9 text-light-text dark:text-dark-text"
            onClick={() => toggle(!open)}>
            <span
              className={[
                "w-5 h-5 shrink-0 grow-0 mr-2",
                "fill-gray-90 dark:fill-gray-3",
                "transition-all duration-75",
                !open ? "-rotate-90" : "",
              ].join(" ")}>
              {riskProfilesObj && Object.keys(riskProfilesObj).length > 0 && (
                <ChevronArrow />
              )}
            </span>

            <div className="w-[140px] grow-0 shrink-0 pr-2">
              <Tooltip
                position="right"
                content={strategy.location_name as string}
                customStyle={tooltipCustomStyle}>
                <h5 className="truncate whitespace-nowrap body-md">
                  {strategy.location_name}
                </h5>
              </Tooltip>
            </div>
            <div className="w-[120px] grow-0 shrink-0 pr-2">
              <Tooltip
                position="right"
                content={strategy.asset_variety_full_name as string}
                customStyle={tooltipCustomStyle}>
                <h6 className="truncate whitespace-nowrap body-md">
                  {strategy.asset_variety_full_name}
                </h6>
              </Tooltip>
            </div>
            {isEditingCalendar && <StrategyOptions strategy={strategy} />}
          </div>
        </div>

        <div
          className="relative flex flex-row items-center h-10 pl-8 pr-3 body-sm text-gray-60 dark:text-gray-30"
          style={
            open
              ? {
                  top: `-${(totalLevels - 1) * stageHeight - 8}px`,
                }
              : {}
          }>
          {isEditingCalendar ? (
            <TooltipV2
              containerClasses="w-full h-fit grow-0 shrink-0"
              doShow={
                !!strategy.description &&
                strategy.description.length >= DESC_MAX_LENGTH - 20 // -20 to let the user know the limit before reaching it.
              }
              content={
                strategy.description?.length === DESC_MAX_LENGTH
                  ? t("NCharactersMaximum", "{N} characters maximum", {
                      N: DESC_MAX_LENGTH,
                    })
                  : t("NCharactersLeft", "{N} characters left", {
                      N: DESC_MAX_LENGTH - (strategy.description?.length ?? 0),
                    })
              }>
              <input
                value={strategy.description}
                onChange={(e) => {
                  setDescLengthError(e.target.value.length > DESC_MAX_LENGTH)

                  if (e.target.value.length <= DESC_MAX_LENGTH) {
                    updateStrategy({
                      description: e.target.value,
                    })
                  }
                }}
                placeholder={t("description", "Description")}
                style={
                  descLengthError ? { borderColor: colors.red.DEFAULT } : {}
                }
                className={[
                  ...smallInputClasses,
                  descLengthError ? "animate-shake" : "",
                ].join(" ")}
              />
            </TooltipV2>
          ) : (
            <div className="w-full">
              <p>{strategy.description || ""}</p>
              {hasPermissions(["gdd_on_planning_tool"]) && (
                <div className="text-right text-sm">
                  {strategy.can_calculate_GDD ? (
                    <span className="text-gray-60 dark:text-gray-30">
                      {todayGDDString ? todayGDDString : ""}
                    </span>
                  ) : (
                    <>
                      <div className="flex items-center justify-end">
                        <TooltipV2
                          content={t(
                            "gddFirstStageTooltip",
                            "Mark a stage as First Stage to enable GDD calculations. The first stage serves as the starting point for accumulating GDDs.",
                          )}>
                          <div className="flex items-center justify-end gap-1 pt-1 cursor-help">
                            <p className="text-right text-xs text-red-medium">
                              {t(
                                "noFirstStageGddMessage",
                                "No 'First Stage' selected – GDDs cannot be calculated.",
                              )}
                            </p>
                            <div className="w-4 h-4 text-red-medium">
                              <InfoIcon />
                            </div>
                          </div>
                        </TooltipV2>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <div
          className={[
            "overflow-hidden",
            "transition-all duration-75 border-t",
            open
              ? "border-gray-14 dark:border-gray-78 max-h-max"
              : "max-h-0 border-transparent",
          ].join(" ")}>
          {sortedRisks.map((risk: IPlannedRisk, index: number) => (
            <RiskRow
              risk={risk}
              maxImpactSettings={getMaxImpactSettings(risk)}
              tooltipPosition={index === 0 ? "bottom-right" : "top-right"}
              removePlannedRisk={() => removePlannedRisk(index)}
              key={risk.id ?? risk.frontend_id}
            />
          ))}
        </div>

        <PortalComponent portalId="calendarMainPortal">
          <Stages
            strategy={providerValue.actualStrategy}
            updateStrategy={updateStrategy}
            open={open}
            strategyOrder={strategyOrder}
            canCalculateGDD={strategy.can_calculate_GDD ?? false}
          />
        </PortalComponent>
      </div>
    </StrategyContext.Provider>
  )
}

export default Strategy
