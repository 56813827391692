import { useTranslate } from "@tolgee/react"
import { gql } from "graphql-request"
import { useCallback, useMemo } from "react"
import LoadingAnimation from "../../../../climateui/components/LoadingAnimation"
import GenericChartWidget from "../../../../climateui/components/Widgets/SeasonalWidgets/GenericChartWidget"
import WidgetWrapper from "../../../../climateui/components/Widgets/WidgetWrapper/WidgetWrapper"
import { useLocations } from "../../../../providers"
import IWidgetDefaultProps from "../../../Seasonal/Dashboards/components/widgetLibrary/WidgetDefaultProps"
import { ITrendFilters } from "./TrendFilters"
import TrendHover from "./TrendHover"

const COLORS = [
  "var(--color-primary)",
  "#1C9690",
  "#2187C1",
  "#8E3FF3",
  "#464B86",
  "#55DDE0",
  "#E43F6F",
  "#FF99C8",
  "#C9E4CA",
  "#FFCF00",
]
const TrendsChartWidget = ({
  selectors,
  filters,
}: Omit<IWidgetDefaultProps, "dashboardFilters"> & {
  filters: ITrendFilters
}) => {
  const { t } = useTranslate()
  const { locationsObj } = useLocations()
  const customHover = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (d: Record<string, any>) => {
      const locationIDX = COLORS.indexOf(d.item_data.color)
      if (locationIDX == -1) return null
      const locationID = filters.selectedLocations[locationIDX]
      if (!locationID) return null
      const locationObj = locationsObj[locationID]
      if (!locationObj) return null
      d.item_data.locationName = locationObj.name
      return <TrendHover d={d} />
    },
    [locationsObj, filters],
  )
  // NOTE: If you want to support more variables, this selectedVariables
  // index has to change
  const config = filters.selectedLocations.map(
    (_, idx) => `{
                            variable: "${filters.selectedVariables[0]}",
                            scenario: "${filters.selectedScenario}",
                            decade: ${filters.selectedDecade},
                            color: "${COLORS[idx]}",
                        }`,
  )
  const customLabels = useMemo(
    () =>
      filters.selectedLocations.map((id, idx) => ({
        vis: "candle",
        color: COLORS[idx],
        customText: locationsObj[id]?.name ?? t("loading", "Loading"),
        opacity: 1,
      })),
    [locationsObj, filters],
  )
  const getLocationQuery = (locationID: string, idx: number) => {
    return `
        loc${idx}: locations(filter: { location_ids: ["${locationID}"] }) {
            results {
                trends(
                    filter: ${config[idx]}
                ) {
                    data
                }
            }
        }
        `
  }
  if (filters.selectedLocations.length === 0) {
    return (
      <div className="flex justify-center w-full h-full pb-6 border rounded-sm border-gray-14 dark:border-gray-78 body-lg">
        <LoadingAnimation />
      </div>
    )
  }
  return (
    <WidgetWrapper
      component={GenericChartWidget}
      query={gql`
                query  {
                    ${filters.selectedLocations.map(getLocationQuery).join(",")}
                }
            `}
      selectors={{
        ...selectors,
        customHover,
        customLabels,
        enableCSVDownload: false,
        $items: "loc*.results[].*.trends[].data[]",
      }}
      filters={[]}
    />
  )
}
export default TrendsChartWidget
