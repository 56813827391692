import { Row } from "@tanstack/react-table"
import { Translate } from "../../../components"
import { useNavigate } from "react-router-dom"
import { useDashboard } from "../../../providers/DashboardProvider"
import ScheduleTableActions from "./components/ScheduleTableActions"
import { IPrimitivesDictionary } from "../../../climateui/types"
import { IDashboardFilters, IScheduler } from "../../../types"

export const daysDict = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
} as IPrimitivesDictionary

function SchedulerNameCell({
  id,
  filters,
}: {
  id: string
  filters: IDashboardFilters[][]
}) {
  const navigate = useNavigate()
  const { dashboardsObject } = useDashboard()
  const url = new URL("https:/seasonal/dashboards?")
  const params = new URLSearchParams(url.search)

  if (filters?.[0]) {
    filters[0].forEach((filter) => {
      if (filter.prop === "locId") {
        params.append("locId", filter.value)
      }
      if (filter.prop == "scope") {
        params.append("scope", filter.value)
      }
      if (filter.prop == "variables") {
        params.append("variables", filter.value)
      }
      if (filter.prop === "region") {
        params.append("region", filter.value)
      }
    })
  }

  return (
    <button
      onClick={() =>
        navigate("/seasonal/dashboards/" + id + "?" + params.toString())
      }
      className="text-left underline cursor-pointer ">
      {dashboardsObject[id]?.title}
    </button>
  )
}

export function getInfoFromSchedule(schedule: IScheduler) {
  let recurrence, hour, time, timeAndDay
  hour = schedule.day_hour as number
  if ((schedule.day_hour as number) > 12) {
    hour = hour - 12
    time = "PM"
  } else if (schedule.day_hour === 12) {
    time = "PM"
  } else {
    time = "AM"
  }
  if (schedule.run_daily) {
    recurrence = "Daily"
    timeAndDay = `Every Day at ${hour}:00 ${time}`
  } else if ((schedule.week_day as number) >= 0) {
    recurrence = "Weekly"
    timeAndDay = `Every ${
      daysDict[schedule.week_day as number]
    } at ${hour}:00 ${time}`
  } else {
    recurrence = "Monthly"
    timeAndDay = `On the ${schedule.month_day}th at ${hour}:00 ${time}`
  }

  return { recurrence, hour, time, timeAndDay }
}

export const columns = [
  {
    accessorKey: "name",
    accessorFn: (row: IScheduler) => {
      return row.subject + " " + row.id
    },
    id: "name",
    filterFn: (
      row: Row<IScheduler>,
      columnId: string,
      filterValue: string[],
    ) => {
      if (!filterValue.length) return true
      return filterValue.includes(row.original.id + "")
    },
    header: () => <Translate labelKey="nameDashboard" />,
    cell: ({ row }: { row: Row<IScheduler> }) => (
      <SchedulerNameCell
        id={row.original.dashboard_id ?? ""}
        filters={row.original.filter_groups as IDashboardFilters[][]}
      />
    ),
  },
  {
    accessorKey: "email_subject",
    filterFn: (
      row: Row<IScheduler>,
      columnId: string,
      filterValue: string[],
    ) => {
      if (!filterValue.length) return true
      return filterValue.includes(row.original.id + "")
    },
    header: () => <Translate labelKey="emailSubject" />,
    cell: ({ row }: { row: Row<IScheduler> }) => {
      return <div className="body-md"> {row.original.subject}</div>
    },
  },
  {
    accessorKey: "recurrence",
    filterFn: (
      row: Row<IScheduler>,
      columnId: string,
      filterValue: string[],
    ) => {
      if (!filterValue.length) return true
      return filterValue.includes(row.original.id + "")
    },
    header: () => <Translate labelKey="recurrence" />,
    cell: ({ row }: { row: Row<IScheduler> }) => {
      const { recurrence, timeAndDay } = getInfoFromSchedule(row.original)

      return (
        <div className="body-md flex-wrap">
          <div>{recurrence}</div>
          <div>{timeAndDay}</div>
        </div>
      )
    },
  },
  {
    accessorKey: "emails",
    filterFn: (
      row: Row<IScheduler>,
      columnId: string,
      filterValue: string[],
    ) => {
      if (!filterValue.length) return true
      return filterValue.includes(row.original.id + "")
    },
    header: () => <Translate labelKey="emails" />,
    cell: ({ row }: { row: Row<IScheduler> }) => {
      const emailsList = row.original.emails ?? []
      return <div className="body-md"> {emailsList.length}</div>
    },
  },
  {
    accessorKey: " ",
    filterFn: (
      row: Row<IScheduler>,
      columnId: string,
      filterValue: string[],
    ) => {
      if (!filterValue.length) return true
      return filterValue.includes(row.original.id + "")
    },
    cell: ({ row }: { row: Row<IScheduler> }) => {
      return <ScheduleTableActions rowData={row.original} />
    },
  },
]
