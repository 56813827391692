import { decodeDelimitedArray, encodeDelimitedArray } from "use-query-params"
import { IBooleanDictionary } from "../../../../climateui/types"
const DELIM = ","

export const StringSetArrayParam = {
  encode: (params: Set<string>) => {
    if (!params) return undefined
    return encodeDelimitedArray(Array.from(params), DELIM)
  },
  decode: (params: string | (string | null)[] | null | undefined) => {
    if (!params) return new Set<string>()
    if (typeof params !== "string") return new Set<string>()
    const arr = decodeDelimitedArray(params, DELIM)?.filter(
      (val) => !!val,
    ) as string[]
    return new Set<string>(arr)
  },
}
export const BooleanDictionaryParam = {
  encode: (params: IBooleanDictionary) => {
    if (!params) return undefined
    return Object.keys(params)
      .filter((k) => params[k])
      .join(DELIM)
  },
  decode: (params: string | (string | null)[] | null | undefined) => {
    if (!params) return {}
    let rawString = ""
    if (typeof params !== "string") {
      rawString = params.filter((paramStr) => !!paramStr).join(DELIM)
    } else {
      rawString = params
    }
    const keys = rawString.split(DELIM)
    return keys.reduce((prev: IBooleanDictionary, curr: string) => {
      prev[curr] = true
      return prev
    }, {})
  },
}
