function ComputerSettingsCoolSVG() {
  return (
    <svg
      width="216"
      height="139"
      viewBox="0 0 216 139"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="179"
        y="129.998"
        width="160"
        height="112"
        transform="rotate(-180 179 129.998)"
        stroke="url(#paint0_linear_9534_8500)"
        strokeWidth="2"
      />
      <path
        d="M131 102L37 102"
        stroke="#065564"
        strokeOpacity="0.5"
        strokeWidth="2"
      />
      <path
        d="M131 77L37 77"
        stroke="#065564"
        strokeOpacity="0.5"
        strokeWidth="2"
      />
      <path
        d="M131 52.998L37 52.998"
        stroke="#065564"
        strokeOpacity="0.5"
        strokeWidth="2"
      />
      <circle
        cx="73"
        cy="53.498"
        r="7"
        fill="url(#paint1_linear_9534_8500)"
      />
      <circle
        cx="94"
        cy="101"
        r="7"
        fill="url(#paint2_linear_9534_8500)"
      />
      <circle
        cx="44"
        cy="77.5"
        r="7"
        fill="url(#paint3_linear_9534_8500)"
      />
      <path
        d="M0 130.998H198V133.998C198 136.759 195.761 138.998 193 138.998H5C2.23858 138.998 0 136.759 0 133.998V130.998Z"
        fill="url(#paint4_linear_9534_8500)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M172.783 110.6H185.814V104.324C189.467 103.535 192.878 102.097 195.921 100.135L200.361 104.576L209.575 95.3616L205.135 90.9216C207.097 87.8794 208.536 84.4684 209.325 80.8156H215.6V67.7848H209.325C208.536 64.1323 207.097 60.7215 205.136 57.6795L209.576 53.2401L200.361 44.0259L195.922 48.4653C192.879 46.5037 189.467 45.0649 185.814 44.2762V38L172.783 38V44.2776C169.131 45.0668 165.721 46.5052 162.68 48.4661L158.239 44.0256L149.025 53.2397L153.466 57.6807C151.505 60.7225 150.067 64.1328 149.279 67.7848H143L143 80.8156H149.279C150.068 84.4679 151.506 87.8785 153.467 90.9204L149.025 95.362L158.24 104.576L162.681 100.135C165.722 102.095 169.132 103.533 172.783 104.322V110.6ZM179.302 55.3374C168.83 55.3374 160.34 63.8273 160.34 74.3001C160.34 84.7729 168.83 93.2628 179.302 93.2628C189.775 93.2628 198.265 84.7729 198.265 74.3001C198.265 63.8273 189.775 55.3374 179.302 55.3374Z"
        fill="url(#paint5_linear_9534_8500)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M128 15.2606L128 21.9375H131.216C131.62 23.8091 132.357 25.5568 133.362 27.1156L131.087 29.3911L135.808 34.1124L138.083 31.8371C139.642 32.842 141.39 33.5791 143.261 33.9834V37.2H149.938L149.938 33.9836C151.809 33.5795 153.557 32.8426 155.115 31.838L157.391 34.1137L162.112 29.3925L159.837 27.1171C160.842 25.5579 161.58 23.8097 161.984 21.9375H165.2V15.2606H161.984C161.579 13.3892 160.842 11.6417 159.837 10.0831L162.113 7.80802L157.391 3.08672L155.116 5.36197C153.557 4.35721 151.81 3.62023 149.938 3.21606V0L143.261 2.91858e-07V3.21623C141.39 3.62056 139.642 4.35783 138.083 5.36295L135.808 3.08808L131.087 7.80938L133.362 10.0846C132.358 11.6428 131.621 13.3898 131.217 15.2606H128ZM154.4 18.6C154.4 14.2922 150.908 10.8 146.6 10.8C142.292 10.8 138.8 14.2922 138.8 18.6C138.8 22.9078 142.292 26.4 146.6 26.4C150.908 26.4 154.4 22.9078 154.4 18.6Z"
        fill="url(#paint6_linear_9534_8500)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9534_8500"
          x1="172"
          y1="124.498"
          x2="433.5"
          y2="310.498"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#065564" />
          <stop
            offset="1"
            stopColor="#065564"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9534_8500"
          x1="61.7451"
          y1="53.4981"
          x2="86.378"
          y2="58.5443"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#00A9C7" />
          <stop
            offset="1"
            stopColor="#00A9C7"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9534_8500"
          x1="82.7451"
          y1="101"
          x2="107.378"
          y2="106.046"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#00A9C7" />
          <stop
            offset="1"
            stopColor="#00A9C7"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9534_8500"
          x1="32.7451"
          y1="77.5"
          x2="57.378"
          y2="82.5462"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#00A9C7" />
          <stop
            offset="1"
            stopColor="#00A9C7"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint4_linear_9534_8500"
          x1="325.847"
          y1="142.811"
          x2="316.031"
          y2="75.9198"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#004957" />
          <stop
            offset="1"
            stopColor="#004957"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint5_linear_9534_8500"
          x1="35.5916"
          y1="170.648"
          x2="218.384"
          y2="193.451"
          gradientUnits="userSpaceOnUse">
          <stop
            stopColor="white"
            stopOpacity="0"
          />
          <stop
            offset="1"
            stopColor="#00AA94"
            stopOpacity="0.95"
          />
        </linearGradient>
        <linearGradient
          id="paint6_linear_9534_8500"
          x1="97.2318"
          y1="-55.0356"
          x2="85.5477"
          y2="38.6263"
          gradientUnits="userSpaceOnUse">
          <stop
            stopColor="white"
            stopOpacity="0"
          />
          <stop
            offset="1"
            stopColor="#00AA94"
            stopOpacity="0.95"
          />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default ComputerSettingsCoolSVG
