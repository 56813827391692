import { DateTime } from "luxon"
import Candle50Icon from "../../../../climateui/icons/candleIcons/Candle50"
import Candle90Icon from "../../../../climateui/icons/candleIcons/Candle90"
import CandleMedian from "../../../../climateui/icons/candleIcons/CandleMedian"
import dashboardVariables from "../../../../views/Seasonal/Dashboards/dashboardVariables"

const TrendHover = ({
  d,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  d: Record<string, any>
}) => {
  const unit = d.item_data.units
  const date = DateTime.fromMillis(d.x).toLocal()

  return (
    <div className="flex flex-wrap w-[300px] font-roboto text-[16px] m-[4px]">
      <h1 className="font-[600]">{d.item_data.locationName}</h1>
      <h1 className="text-[14px] color-#666D74 mb-[5px] w-[100%] capitalize">
        {date.monthLong +
          " - " +
          dashboardVariables[d.item_data.variable].title +
          " " +
          d.item_data.dataset}
      </h1>

      <div className="w-full flex justify-between items-center px-0 py-[5px] border-b border-gray-14 dark:border-gray-78 border-solid">
        <div className="flex">
          <div className="w-[20px]">
            <CandleMedian fill={d.scaled[0].color} />
          </div>
          <h1 className="text-[14px] font-[500] mx-[5px] text-gray-60 dark:text-gray-30">
            Median
          </h1>
        </div>
        <h1 className="text-[15px]">{d.yMid.toFixed(2) + unit}</h1>
      </div>

      <div className="w-full flex justify-between items-center px-0 py-[5px] border-b border-gray-14 dark:border-gray-78 border-solid">
        <div className="flex">
          <div className="w-[20px]">
            <Candle90Icon fill={d.scaled[0].color} />
          </div>
          <h1 className="text-[14px] font-[500] mx-[5px] text-gray-60 dark:text-gray-30">
            90% Confidence
          </h1>
        </div>

        <h1 className="text-[15px]">
          {d.y0.toFixed(2) + unit + " to " + d.y1.toFixed(2) + unit}
        </h1>
      </div>

      <div className="w-full flex justify-between items-center px-0 py-[5px] border-b border-gray-14 dark:border-gray-78 border-solid">
        <div className="flex">
          <div className="w-[20px]">
            <Candle50Icon fill={d.scaled[0].color} />
          </div>
          <h1 className="text-[14px] font-[500] mx-[5px] text-gray-60 dark:text-gray-30">
            50% Confidence
          </h1>
        </div>

        <h1 className="text-[15px]">
          {d.yMin.toFixed(2) + unit + " to " + d.yMax.toFixed(2) + unit}
        </h1>
      </div>
    </div>
  )
}

export default TrendHover
