import { useTranslate } from "@tolgee/react"
import { gql } from "graphql-request"
import { DateTime } from "luxon"
import { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import {
  BooleanParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params"
import { Checkbox, SingleSelectFilter } from "../../../../climateui/components"
import { DateValidatorFn } from "../../../../climateui/components/DatePicker/DatePicker"
import {
  MultiDropdownSelect,
  SingleDropdownSelect,
} from "../../../../climateui/components/Inputs"
import WidgetWrapper from "../../../../climateui/components/Widgets/WidgetWrapper/WidgetWrapper"
import { FilterIcon } from "../../../../climateui/icons"
import { GenericPageHeader } from "../../../../components"
import DirectionalPinsMap from "../../../../components/YieldOutlook/Map/DirectionalPinsMap"
import OutlookDateSelector from "../../../../components/YieldOutlook/OutlookDateSelector"
import RollingOutlookTable from "../../../../components/YieldOutlook/RollingOutlookTable"
import {
  RollingOutlookData,
  YieldReportSiteCell,
} from "../../../../components/YieldOutlook/RollingOutlookTable/utils"
import YieldOutlookStage from "../../../../components/YieldOutlook/YieldOutlookStage"
import YieldOutlookSummary from "../../../../components/YieldOutlook/YieldOutlookSummary"
import YieldOutlookTableWidget from "../../../../components/YieldOutlook/YieldOutlookTableWidget"
import { useIsFlagEnabled } from "../../../../hooks"
import { Resolution } from "../../../../hooks/YieldOutlook/useAssetModelRegions"
import useYieldUnits from "../../../../hooks/YieldOutlook/useYieldUnits"
import { useAccount, useAssets } from "../../../../providers"
import { IYieldOutlookLocationGQL } from "../../../../types"
import { NOON } from "../../../../utils/dates"
import { SetParam } from "../../../../utils/queryParams"
import BelowNormalLocationCountWidget from "../components/BelowNormalLocationCountWidget"
import PastReportsSwitch from "../components/PastReportsSwitch"
import { useYieldOutlook } from "../provider"

const YieldOutlookDashboard = () => {
  const { t } = useTranslate()
  const { selectedAccount } = useAccount()
  const { allAssets } = useAssets()
  const { assetId } = useParams()
  const assetModelName = allAssets?.[assetId as string]?.name

  const { assetModels, isLoading, locationModels } = useYieldOutlook()

  const { availableLocationModels, regionsDict } = useMemo(() => {
    // not available in case there's no data
    // filter available locations by the assetid
    const availableLocationModels = locationModels?.filter(
      (locModel) =>
        !!locModel.newest_seasonal_date &&
        locModel.model?.asset_id === assetId &&
        !!locModel.location,
    )

    const regionsDict =
      availableLocationModels?.reduce(
        (prev: Record<string, string>, curr: IYieldOutlookLocationGQL) => {
          // Skip this model if it has no regions associated to its location
          if (!curr.location?.regions) return prev

          const countryObj = curr.location.regions.find(
            (_region) => _region.resolution === Resolution.COUNTRY,
          )

          // Skip this model if cannot be linked to an existing country
          if (!countryObj?.id || !countryObj?.name) return prev

          // Add this country to the regions dict
          prev[countryObj.id] = countryObj.name

          return prev
        },
        {},
      ) ?? {}

    return { availableLocationModels, regionsDict }
  }, [locationModels, assetId])

  /*
   * Will be used later when adding schedules to Yield Dashboards :)
   * const yieldOutlookURL = new URL(window.location.href)
   * const urlParams = new URLSearchParams(yieldOutlookURL.search)
   */

  const regionParam = withDefault(StringParam, Object.keys(regionsDict)[0])
  const [selectedRegion, setSelectedRegion] = useQueryParam(
    "region",
    regionParam,
  )

  // TEMPORARY
  const isNewDirectionalityToggleEnabled = !!useIsFlagEnabled(
    "experiment_yield_outlook_new_directionality_logic",
  )
  const [toggleNewDirectonalityLogic, setToggleNewDirectonalityLogic] =
    useState(!!isNewDirectionalityToggleEnabled)

  const locationsParam = withDefault(SetParam, new Set<string>())
  const [_selectedLocations, setSelectedLocations] = useQueryParam(
    "location",
    locationsParam,
  )

  const locationOpts = useMemo(
    () =>
      availableLocationModels?.reduce(
        (prev: Record<string, string>, curr: IYieldOutlookLocationGQL) => {
          // Skip this model if it doesn't have regions associated to it
          if (!curr.location?.regions) return prev
          const isSelected = curr.location.regions.some(
            (region) => region.id === selectedRegion,
          )

          // Skip this model if it is not selected or the location has no name
          if (!isSelected || !curr.location.name) return prev

          // Add this location to the opts
          prev[curr.location_id] = curr.location.name

          return prev
        },
        {},
      ) ?? {},
    [availableLocationModels, selectedRegion, assetId],
  )

  const { selectedLocations, selectedLocationModelIds, startDate, endDate } =
    useMemo<{
      selectedLocations: string[] | Set<string>
      selectedLocationsSet: Set<string>
      selectedLocationModelIds: string[]
      startDate: DateTime | undefined
      endDate: DateTime | undefined
    }>(() => {
      // if none is selected, show all locations
      const selectedLocations =
        _selectedLocations.size === 0
          ? Object.keys(locationOpts)
          : _selectedLocations

      // from selected locations, filter the availableLocationModels
      const selectedLocationsSet = new Set(selectedLocations)
      const selectedLocationModels = availableLocationModels?.filter(
        (locModel) => selectedLocationsSet.has(locModel.location_id),
      )

      // get model ids
      const selectedLocationModelIds =
        selectedLocationModels?.map((locModel) => locModel.id) ?? []

      let startDate: DateTime | undefined = undefined
      let endDate: DateTime | undefined = undefined
      // get calendar time range
      selectedLocationModels?.forEach((locModel) => {
        const oldestDate = DateTime.fromISO(locModel.oldest_seasonal_date, {
          zone: "utc",
        })
        // Add one week, since the newest date would be the newest
        // init_time (which is the beginning of the forecasted period)
        const newestDate = DateTime.fromISO(locModel.newest_seasonal_date, {
          zone: "utc",
        }).plus({ weeks: 1 })

        if (!endDate || newestDate > endDate) endDate = newestDate
        if (!startDate || oldestDate < startDate) startDate = oldestDate
      })

      return {
        selectedLocations,
        selectedLocationsSet,
        selectedLocationModelIds,
        startDate,
        endDate,
      }
    }, [_selectedLocations, locationOpts])

  const dateParam = withDefault(
    StringParam,
    DateTime.now().set(NOON).toUTC().startOf("week").toISODate(),
  )
  const [date, setDate] = useQueryParam("date", dateParam)

  // Set the date to the newest available data based on the selected region
  // asset model

  const dateValidator: DateValidatorFn = (_date) => {
    // If there's no date, then it cannot be valid
    if (!_date) return false
    // Make sure that it occurs after the lower bound, if it exists
    const lowerBoundCond = !startDate || startDate <= _date
    // Make sure that it occurs before the upper bound, if it exists
    const upperBoundCond = !endDate || _date < endDate
    // If the date falls between the bounds (if they exist), then return true
    return lowerBoundCond && upperBoundCond
  }
  const pastReportsParam = withDefault(BooleanParam, false)
  const [showPastReports, setShowPastReports] = useQueryParam(
    "past",
    pastReportsParam,
  )

  // resets locs in account change
  useEffect(() => {
    if (!selectedAccount) return
    setSelectedLocations(new Set<string>())
  }, [selectedAccount])

  const { unitOptions, units, setUnits } = useYieldUnits(
    assetModels ?? [],
    selectedRegion,
    assetId ?? "",
    isLoading,
    t,
  )

  const yieldDashboardFilters = useMemo(() => {
    return [
      {
        propName: "current_time",
        // validate if today is between the available data timewindow
        value:
          (dateValidator(DateTime.now(), {}) && date) ||
          DateTime.now().toISODate(),
      },
      {
        propName: "init_time",
        value: date,
      },
      {
        propName: "asset_ids",
        value: [assetId],
        loading: isLoading,
      },
      {
        propName: "yield_location_ids",
        value: selectedLocationModelIds,
        loading: isLoading,
      },
      {
        propName: "country_id",
        value: selectedRegion,
        loading: isLoading,
      },
      {
        propName: "location_ids",
        value: selectedLocations,
        loading: isLoading,
      },
      {
        propName: "units",
        value: units,
        loading: isLoading,
      },
    ]
  }, [
    selectedRegion,
    selectedLocations,
    selectedLocationModelIds,
    assetId,
    units,
    isLoading,
  ])

  const rollingYieldOutlookTableColumns = [
    {
      id: "location",
      header: t("location", "Location"),
      accessorFn: (original: RollingOutlookData) => {
        return original.territory.name
      },
      cell: YieldReportSiteCell,
    },
  ]

  return (
    <div
      className="w-full h-full p-8 overflow-y-scroll overflow-x-hidden bg-gray-1.5 dark:bg-gray-88"
      id="yield-dashboard-container">
      <GenericPageHeader
        pageTitle={
          assetModelName && !isLoading ? (
            <div>
              {t("locationYield", "{asset} Location Yield", {
                asset: assetModelName,
              })}
            </div>
          ) : (
            <div className="text-gray-30">{t("loadingYieldDashboard")}</div>
          )
        }
      />
      <div className="mb-[14px] flex flex-wrap gap-2 align-baseline -mt-5">
        <SingleSelectFilter
          key="region"
          placeholder={t("region", "Region")}
          leftRightClass="left-0"
          selected={selectedRegion}
          setSelected={(v) => {
            setSelectedRegion(v)
            setSelectedLocations(new Set<string>())
          }}
          options={regionsDict}
        />
        <MultiDropdownSelect
          options={locationOpts}
          selected={_selectedLocations}
          setSelected={setSelectedLocations}
          canSearch={true}
          placeholder={t("location", "Location")}
          trailingIcon={<FilterIcon />}
          sort="lexical-by-value"
          canClear={true}
        />
        <OutlookDateSelector
          initialDate={DateTime.fromISO(date)}
          onChange={(startDate) => {
            if (!startDate) return
            setDate(startDate.set(NOON).toUTC().toISODate())
          }}
          dateValidator={dateValidator}
        />
        <SingleDropdownSelect
          options={unitOptions}
          selected={units.replace("/", "_")}
          setSelected={setUnits}
        />
        <PastReportsSwitch
          selected={showPastReports}
          setSelected={setShowPastReports}
        />
        {isNewDirectionalityToggleEnabled && (
          <div className="ddBox flex flex-row items-center transition-all duration-75 min-w-[60px] pl-[8px] pr-[2px] w-fit h-[32px] border-[1px] rounded-sm cursor-pointer disabled:cursor-not-allowed enabled:focus:outline-accent disabled:bg-gray-5 dark:disabled:bg-gray-60 disabled:text-gray-30 disabled:fill-gray-30  border-gray-14 dark:border-gray-78 hover:enabled:border-gray-30 bg-light-bg dark:bg-dark-bg fill-gray-60 text-gray-60 dark:text-gray-30">
            <Checkbox
              status={toggleNewDirectonalityLogic ? "full" : "empty"}
              onChange={() =>
                setToggleNewDirectonalityLogic(!toggleNewDirectonalityLogic)
              }
            />

            <div className="label-lg grow whitespace-nowrap">
              New Directionality Logic
            </div>
          </div>
        )}
      </div>
      <div className="flex gap-[14px] mb-[14px]">
        <div className="w-3/4 bg-light-bg dark:bg-dark-bg border rounded-lg font-roboto border-1 border-gray-14 dark:border-gray-78">
          {/* Map widget */}
          <WidgetWrapper
            selectedFilters={["init_time", "yield_location_ids"]}
            query={`#graphql
                            query (
                                $init_time: String
                                $yield_location_ids: [String]
                            ) {
                                yield_locations(filter: {
                                    # already filtered by country / models
                                    yield_location_ids: $yield_location_ids
                                }) {
                                    results {
                                        location {
                                            name
                                            latitude
                                            longitude
                                        }
                                        stats: seasonal_stats(filter: { init_time: $init_time }) {
                                            results {
                                                tercile_probabilities
                                                historical {
                                                    yield_terciles
                                                }
                                                outlook_mean
                                            }
                                        }
                                    }
                                }
                            }
                        `}
            filters={yieldDashboardFilters}
            component={DirectionalPinsMap}
            selectors={{
              $data: "yield_locations.results",
              title: t("directionalYieldOutlook", "Directional Yield Outlook"),
              toggleNewDirectonalityLogic: toggleNewDirectonalityLogic,
            }}
          />
        </div>
        <div className="w-1/3 grid gap-[14px] grid-cols-1 grid-rows-3">
          {/* this one stays the same */}
          <YieldOutlookSummary
            type="monitor"
            filters={yieldDashboardFilters}
            selectors={{
              title: `${t(
                "countryYieldSummary",
                "Country Yield Summary",
              )} - ${t("weighted", "Weighted")}`,
              $data: "yield_outlook_model.results[0].stats",
            }}
          />
          <WidgetWrapper
            selectedFilters={["init_time", "yield_location_ids"]}
            component={BelowNormalLocationCountWidget}
            query={`#graphql
                            query (
                                $init_time: String
                                $yield_location_ids: [String]
                            ) {
                                yield_locations(filter: { 
                                    # already filtered by country / models
                                    yield_location_ids: $yield_location_ids
                                }) {
                                    results {
                                        stats: seasonal_stats(filter: { init_time: $init_time }) {
                                            results {
                                                tercile_probabilities
                                            }
                                        }
                                    }
                                }
                            }
                        `}
            filters={yieldDashboardFilters}
            selectors={{
              $data: "yield_locations.results[].stats[].results[]",
            }}
          />
          <YieldOutlookStage
            type="monitor"
            filters={yieldDashboardFilters}
            selectors={{
              title: t("currentStage"),
              $data: "yield_outlook_model.results[0]",
            }}
          />
        </div>
      </div>
      <div>
        <WidgetWrapper
          selectedFilters={["units", "init_time", "yield_location_ids"]}
          component={YieldOutlookTableWidget}
          query={gql`
            query (
              $units: String
              $init_time: String
              $yield_location_ids: [String]
            ) {
              yield_locations(
                filter: {
                  # already filtered by country / models
                  yield_location_ids: $yield_location_ids
                }
              ) {
                results {
                  territory: location {
                    name
                  }
                  stats: seasonal_stats(
                    filter: { init_time: $init_time, units: $units }
                  ) {
                    results {
                      tercile_probabilities
                      outlook_distribution
                      impact_drivers {
                        name
                        mean_impact
                      }
                      historical {
                        yield_terciles
                      }
                      deviation_mean
                      outlook_mean
                    }
                  }
                }
              }
            }
          `}
          selectors={{
            title: t("yieldWidgetTable"),
            $data: "yield_locations.results[]",
            errorMessage: t("errorLoadingData"),
            reloadMessage: t("clickReload"),
            noResultsMessage: t("noResultCurrentFilters"),
            tryAnotherFilterMessage: t("tryAnotherFilter"),
            columns: [
              // LOCATION
              {
                propName: "territory",
                header: `${t("location", "Location")}`,
                type: "text",
                selector: {
                  text: "{{ territory.name }}",
                },
                columns: [
                  {
                    propName: "territory",
                    type: "text",
                  },
                ],
              },
            ],
            units,
            toggleNewDirectonalityLogic: toggleNewDirectonalityLogic,
          }}
          filters={yieldDashboardFilters}
        />
      </div>
      <div className="my-3">
        <RollingOutlookTable
          filters={yieldDashboardFilters}
          dashboardType="location"
          customColumns={rollingYieldOutlookTableColumns}
          show={showPastReports}
          loading={isLoading}
        />
      </div>
    </div>
  )
}

export default YieldOutlookDashboard
