import { SVGProps } from "react"

const Candle50Icon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                width="20"
                height="20"
                fill="none"
            />
            <path
                d="M10 18.3332L10 1.6665"
                stroke={props.fill + "40"}
                strokeWidth="1.79661"
            />
            <path
                d="M7.54519 1.6665H7.5L12.5 1.6665"
                stroke={props.fill + "40"}
                strokeWidth="1.79661"
            />
            <path
                d="M7.5 18.3333H12.5"
                stroke={props.fill + "40"}
                strokeWidth="1.79661"
            />
            <path
                d="M4.89831 6.89831H15.1017V13.1017H4.89831V6.89831Z"
                fill="none"
                stroke={props.fill}
                strokeWidth="1.79661"
            />
        </svg>
    )
}

export default Candle50Icon
