import { Logo as DefaultLogo } from "../../climateui/components"
import Logo from "./components/Logo"

const dashboard = {
  Logo: DefaultLogo,
  signInLogo: Logo,
  appTitleKey: "climate_app_title",
}

export default dashboard
