import { useQuery } from "react-query"
import { isValidResponse } from "../climateui/utils/http"
import { IAsset, IVariety } from "../types"
import { isCaiDefaultVariety } from "../utils"
import { varietyQuerySet } from "../utils/networking/asset"
import { arrToDict } from "../utils/transform"
import { useAreAllFlagsEnabled } from "./flagsmith"

const varietyAssetXFields = "asset{id,name}"
export const varietyMainXFields = `id,asset_id,name,description,account_id,is_default,default_initial_date,has_timeline,variety_metadata,${varietyAssetXFields}`

const useVarietiesQuery = (assets: Record<string, IAsset> | undefined) => {
  const areCaiDefaultVarietiesMigrated = useAreAllFlagsEnabled([
    "feature_stage_manager",
    "ops_stage_manager_are_varieties_migrated",
  ])
  return {
    ...useQuery({
      queryKey: ["varieties", assets],
      queryFn: () =>
        varietyQuerySet.post(
          "search",
          {
            filter_by: {
              and: [
                {
                  field_name: "variety.Variety.asset_id",
                  operator: "in",
                  field_value: JSON.stringify(Object.keys(assets ?? {})),
                },
              ],
            },
            order_by: ["variety.Variety.name"],
          },
          {},
          {
            headers: {
              "X-Fields": `data{${varietyMainXFields}}`,
            },
          },
          true,
        ),
      enabled: !!assets && Object.keys(assets).length > 0,
      select: (response) => {
        if (!isValidResponse(response) || !assets) return {}
        let rawVarieties: IVariety[] = response.data.data
        if (areCaiDefaultVarietiesMigrated)
          rawVarieties = rawVarieties.filter(
            (variety) => !isCaiDefaultVariety(variety),
          )

        return arrToDict<IVariety>(rawVarieties, "id")
      },
    }),
  }
}

export default useVarietiesQuery
