import { useTranslate } from "@tolgee/react"
import { useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import {
  BackgroundAndCardLayout,
  Button,
  LabelAndInput,
} from "../../climateui/components"
import { GenericInput } from "../../climateui/components/Inputs"
import { useToast } from "../../climateui/providers/Toast/ToastContextProvider"
import { useAuth } from "../../providers/AuthProvider"

const RestorePasswordEmail = () => {
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const { enqueueError, enqueueAlert } = useToast()

  const navigate = useNavigate()
  const location = useLocation()

  const { t } = useTranslate()

  const auth = useAuth()

  const handlePassword = async (event) => {
    event.preventDefault()
    setLoading(true)
    try {
      // TODO Add better styles to handle successful email sent and error handling, maybe a message before redirecting
      // TODO Link new auth microservice (firebase old)
      await auth.requestPasswordReset(email)
      setLoading(false)
      enqueueAlert(t("resetPasswordEmailAction"))
      setTimeout(() => {
        navigate("/")
      }, 5000)
    } catch (error) {
      setLoading(false)
      // TODO: Validate errors with codes instead of strings
      if (error.toString().includes("There is no user"))
        enqueueError(t("emailNotFount"))
      else enqueueError(error)
    }
  }

  const returnPath = location.state
    ? location.state.prevPath || "/login"
    : "/login"

  const [errors, setErrors] = useState({})

  const validateEmail = () => {
    const newErrors = { ...errors }
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      delete newErrors.email
    } else {
      newErrors.email = t("emailFormatError")
    }
    setErrors(newErrors)
  }

  return (
    <BackgroundAndCardLayout copyright={t("copyright")}>
      <div className="pb-0 leading-tight text-center title-sm text-light-text dark:text-dark-text">
        {t("passwordRecovery")}
      </div>
      <div className="pb-2 leading-relaxed text-center body-lg text-light-text dark:text-dark-text">
        {t("enterEmailAddress")}
      </div>
      <LabelAndInput
        label={t("emailInputLabel")}
        input={
          <GenericInput
            error={errors.email}
            handleChange={(e) => {
              setEmail(e.target.value)
            }}
            value={email}
            handleBlur={validateEmail}
            placeholder={t("emailPlaceholder")}
            type="email"
          />
        }
      />
      <div className="flex flex-row self-stretch pt-4 pb-2 space-x-2">
        <Link
          className="w-1/2"
          to={returnPath}>
          <Button
            label={t("cancel")}
            type="secondary"
            extend={true}
            onClick={() => null}
          />
        </Link>
        <Button
          extraClasses={"w-1/2" + (loading ? " cursor-wait" : "")}
          label={t("continue")}
          type="primary"
          extend={true}
          onClick={handlePassword}
          disabled={errors.email || loading}
        />
      </div>
    </BackgroundAndCardLayout>
  )
}

export default RestorePasswordEmail
