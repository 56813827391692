import { ReactNode, useMemo, createContext, useContext } from "react"
import { PortalComponent } from "../climateui/components"
import { isValidResponse } from "../climateui/utils/http"
import { useQuery } from "react-query"
import { useAccount } from "./AccountProvider"
import { useMemoQuery } from "../hooks"
import { triggeredAlertsPOST, alertSettingsQuerySet } from "../utils/networking"
import { IAlertSetting, ITrigger } from "../types"
import { PRODUCT_IDS } from "../utils/constants"

export interface IAlertsContext {
  loadingAlertSettings: boolean
  alertSettings: IAlertSetting[]
  alertSettingsIDs: string[]
  totalAlerts: number
}
export const AlertsContext = createContext<IAlertsContext>({} as IAlertsContext)
export const useAlerts = () => useContext(AlertsContext)

const TriggeredAlertsProvider = ({ children }: { children: ReactNode }) => {
  const { selectedAccount } = useAccount()

  const alertSettingsQueryResponse = useMemoQuery<IAlertSetting[]>(
    ["alertsAlertSettings", selectedAccount],
    () =>
      alertSettingsQuerySet.post("/search", {
        filter_by: {
          and: [
            {
              field_name: "risk_setting.RiskSetting.product_id",
              operator: "in",
              field_value: [
                PRODUCT_IDS.ALERT_SETTINGS,
                PRODUCT_IDS.STAGE_MANAGER,
              ],
            },
            {
              field_name: "risk_setting.RiskSetting.status",
              operator: "ilike",
              field_value: "active",
            },
          ],
        },
        pagination: {
          all: true,
        },
      }),
    {
      enabled: !!selectedAccount,
    },
    (data) => (data as { data: IAlertSetting[] }).data,
    [],
  )

  const alertSettings = alertSettingsQueryResponse[0]
  const loadingAlertSettings = alertSettingsQueryResponse[2]

  const alertSettingsIDs = useMemo<string[]>(() => {
    return alertSettings.map((alertSetting) => {
      return alertSetting.id
    })
  }, [alertSettings])

  const { data: unfilteredAlertsResponse } = useQuery({
    queryKey: ["unfilteredAlerts", selectedAccount, alertSettingsIDs],
    queryFn: () => {
      return triggeredAlertsPOST({
        filter_by: {
          and: [
            {
              field_name: "processing_run.ProcessingRun.account_id",
              operator: "eq",
              field_value: selectedAccount,
            },
            {
              field_name: "processing_run.ProcessingRun.risk_setting_id",
              operator: "in",
              field_value: JSON.stringify(alertSettingsIDs),
            },
            {
              field_name: "alert.Alert.category",
              operator: "ne",
              field_value: "No Action",
            },
          ],
        },
        pagination: {
          all: true,
        },
      })
    },
    enabled: !!alertSettingsIDs,
  })
  const totalAlerts = useMemo(() => {
    const groups = new Set<string>()
    if (!isValidResponse(unfilteredAlertsResponse)) return 0
    const { data: _data } = unfilteredAlertsResponse
    const data = _data.data as ITrigger[]
    data.forEach((trigger) => {
      const groupKey =
        trigger.processing_run.location_id +
        "-" +
        trigger.processing_run.risk_id
      groups.add(groupKey)
    })
    return groups.size
  }, [unfilteredAlertsResponse])

  const value = useMemo(
    () => ({
      loadingAlertSettings,
      alertSettings,
      alertSettingsIDs,
      totalAlerts,
    }),
    [loadingAlertSettings, alertSettings, alertSettingsIDs, totalAlerts],
  )
  return (
    <AlertsContext.Provider value={value}>
      {/* PORTALS > START */}
      {/* One of this portalComponent will be deleted when the old alert page is removed*/}
      <PortalComponent portalId="alertsBadge">
        {totalAlerts > 0 && (
          <div className="px-1.5 text-white rounded-full bg-red h-5">
            {totalAlerts}
          </div>
        )}
      </PortalComponent>

      {/* <PortalComponent portalId="alertBadge">
                {totalAlerts > 0 && (
                    <div className="px-1.5 text-white rounded-full bg-red h-5">
                        {totalAlerts}
                    </div>
                )}
            </PortalComponent> */}
      {children}
    </AlertsContext.Provider>
  )
}

export default TriggeredAlertsProvider
