const styles = {
  // Primary color
  "color-primary": "#f06000",
  "color-primary-dark": "#bd4c00",
  "color-primary-medium": "#f9bf99",
  "color-primary-light": "#feefe5",
  // Secondary color
  "color-secondary": "#1c9690",
  "color-secondary-dark": "#146b67",
  "color-secondary-medium": "#a4d5d3",
  "color-secondary-light": "#e8f5f4",
  // Topbar color
  "color-topbar": "#004957",
  // "background-signin": "url(/images/storm-bg.png)",
  // "background-signin": "url(/images/field-bg.jpeg)",
  "background-signin": "url(/images/field-bg.png)",
}

export default styles
