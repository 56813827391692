import { Row } from "@tanstack/react-table"
import { useTranslate } from "@tolgee/react"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Table } from "../../../climateui/components"
import { PlusIcon, UploadIcon } from "../../../climateui/icons"
import {
  LocationsCount,
  SelectLocationsModal,
  Translate,
} from "../../../components"
import AddLocationModal from "../../../components/AddLocationModal"
import { HalfMapLayout } from "../../../layouts"
import { locToPin, useLocations } from "../../../providers/LocationsProvider"
import { IInsightsLocation } from "../../../types"
import { buildLocationColumns } from "../../Admin/Locations/adminLocationsTableUtils"
import AssetsVarietiesCell from "../components/AssetsVarietiesCell"
import BulkUploadLocationsModal from "../components/BulkUploadLocationsModal"
import { useAssetDependentSteps } from "../components/OnboardingAssetDependentLayoutProvider"
import OnboardingLocationsTableActions from "../components/OnboardingLocationsTableAction"

const columns = buildLocationColumns([
  "name",
  "region",
  {
    id: "assets",
    header: () => <Translate labelKey="assets" />,
    cell: ({ row }: { row: Row<IInsightsLocation> }) => (
      <AssetsVarietiesCell rowData={row.original} />
    ),
    filterFn: (
      row: Row<IInsightsLocation>,
      _: string,
      filterValue: string[],
    ) => {
      if (filterValue.length === 0 || !row.original.assets) return true
      for (const asset of row.original.assets) {
        if (filterValue.includes(asset.id)) return true
      }
      return false
    },
  },
  {
    id: "actions",
    header: "",
    cell: ({ row }: { row: Row<IInsightsLocation> }) => (
      <OnboardingLocationsTableActions rowData={row.original} />
    ),
  },
])

function OnboardingLocations() {
  const { t } = useTranslate()
  const navigate = useNavigate()

  const { locationsObj, locations: accountLocations } = useLocations()
  const {
    asset,
    assetNewLocations,
    setAssetNewLocations,
    canAddLocationsToOnboarding,
    locationsCount,
    selectedExistingLocationsIds,
    setSelectedExistingLocationsIds,
    riskProfilesPerVariable,
    isLoadingSuggestions,
    editingLocation,
    setEditingLocation,
    activeDefaultVariety,
  } = useAssetDependentSteps()

  const [addLocationModalOpen, setAddLocationModalOpen] = useState(false)
  const [bulkUploadLocationsModalOpen, setBulkUploadLocationsModalOpen] =
    useState(false)
  const [selectLocationsModalOpen, setSelectLocationsModalOpen] =
    useState(false)

  const { locations, pins } = useMemo(() => {
    const locations = [...assetNewLocations]
    const pins = locations.map((location) => locToPin(location))

    selectedExistingLocationsIds.forEach((locationId) => {
      const location = { ...locationsObj[locationId] }
      location.assets = asset ? [asset] : []
      locations.push(location)
      pins.push(locToPin(location))
    })

    return {
      locations,
      pins,
    }
  }, [assetNewLocations, selectedExistingLocationsIds, locationsObj, asset])

  useEffect(() => {
    if (isLoadingSuggestions) return
    if (Object.keys(riskProfilesPerVariable).length === 0)
      navigate("../risk-profiles")
  }, [isLoadingSuggestions])

  useEffect(() => {
    if (editingLocation) setAddLocationModalOpen(true)
  }, [editingLocation])

  return (
    <HalfMapLayout
      contentWidthClasses="w-[760px] px-6 pb-6 flex flex-col gap-4 overflow-hidden"
      mapProps={{ pins }}>
      <p className="text-light-text dark:text-dark-text shrink-0">
        {t(
          "addOneOrMoreLocationsToMonitorFor",
          "Add one or more locations you want to monitor for",
        )}{" "}
        <span>{asset?.name}</span>
      </p>

      <div className="flex flex-row items-center gap-2 shrink-0">
        <Button
          disabled={!canAddLocationsToOnboarding}
          type="primary-small"
          label={t("newLocation")}
          icon={<PlusIcon />}
          onClick={() => setAddLocationModalOpen(true)}
        />
        {accountLocations.length > 0 && (
          <Button
            label={t("importExistingLocations", "Import Existing Locations")}
            type="secondary-small"
            onClick={() => setSelectLocationsModalOpen(true)}
          />
        )}
        <Button
          disabled={!canAddLocationsToOnboarding}
          label={t("upload")}
          icon={<UploadIcon />}
          type="secondary-small"
          onClick={() => setBulkUploadLocationsModalOpen(true)}
        />
        <div className="grow"></div>
        <LocationsCount count={locationsCount} />
      </div>

      <div className="grow overflow-hidden">
        <Table<IInsightsLocation>
          data={locations}
          columns={columns}
        />
      </div>

      <AddLocationModal
        hasGeocoder
        customLocation={editingLocation}
        assetsInputDisabled
        open={addLocationModalOpen}
        cancel={() => {
          setAddLocationModalOpen(false)
          setEditingLocation(undefined)
        }}
        showCount={false}
        customVarieties={activeDefaultVariety ? [activeDefaultVariety] : []}
        handleSave={(location) => {
          if (editingLocation) {
            setAssetNewLocations((prev) => {
              const next = [...prev]
              const index = next.findIndex(
                (element) => element.name === editingLocation.name,
              )
              next[index] = {
                ...location,
                assets: asset ? [asset] : [],
              }
              return next
            })
            setEditingLocation(undefined)
          } else {
            setAssetNewLocations((prev) => [
              ...prev,
              {
                ...location,
                assets: asset ? [asset] : [],
              },
            ])
          }
          setAddLocationModalOpen(false)
        }}
      />

      <BulkUploadLocationsModal
        open={bulkUploadLocationsModalOpen}
        close={() => setBulkUploadLocationsModalOpen(false)}
      />

      <SelectLocationsModal
        open={selectLocationsModalOpen}
        close={() => setSelectLocationsModalOpen(false)}
        save={(locationsIds) => {
          setSelectedExistingLocationsIds(locationsIds)
          setSelectLocationsModalOpen(false)
        }}
        prevSelectedIds={selectedExistingLocationsIds}
      />
    </HalfMapLayout>
  )
}

export default OnboardingLocations
