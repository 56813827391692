import { useEffect, useState } from "react"
import { useOutsideComponentClickHandler } from "../../../climateui/hooks"
import { CancelIcon, FileIcon, SearchIcon } from "../../../climateui/icons"
import { OrSeparator } from "../../../components"
/*
 * TODO: Delete after rehaul completion
import {
    BULK_UPLOAD_FILE_EXTENSIONS,
    handleDragOver,
    handleFileChange,
    handleFileDrop,
    showError,
} from "../../../components/Bulk Upload/bulkUploadUtils"
*/
import { useTranslate } from "@tolgee/react"
import EmptyModal from "../../../climateui/providers/Modal/EmptyModal"
import { downloadURL } from "../../../components/Bulk Upload/bulkUploadUtils"
import { useMemoQuery } from "../../../hooks"
import { useAccount } from "../../../providers/AccountProvider"
import { IRiskProfileTemplate } from "../../../types"
import { riskProfileTemplateQuerySet } from "../../../utils/networking"
import RiskProfileBulkUpload from "./components/RiskProfileBulkUpload"

export interface IBulkUploadRiskProfiles {
  onBulkUploadFileChange: (file: File, successAction: () => void) => void
  onCancel: () => void
  isOpen: boolean
}

function TemplateDownloadLink({
  name,
  signedUrl,
}: {
  name: string
  signedUrl: string
}) {
  return (
    <a
      className="p-1 flex items-center border border-gray-14 dark:border-gray-78 w-[210px] rounded-sm text-gray-60 dark:text-gray-30 font-medium text-sm cursor-pointer"
      onClick={() => downloadURL(signedUrl, `${name.replace(" ", "_")}.csv`)}
      href="#">
      <div className="inline-block w-[20px] align-text-bottom mr-[6px] shrink-0 fill-gray-60">
        <FileIcon />
      </div>
      <span className="truncate">{name}</span>
    </a>
  )
}

const RISK_PROFILE_TEMPLATE =
  "https://climate-ai-platform-prod-storage.s3.us-east-2.amazonaws.com/platform_risk/risk_profile_templates/%5BBulk%20Upload%5D%20Risk%20Profile%20Template%20-%20Absolute%20Risk%20Profiles.csv"

function BulkUploadRiskProfiles({
  isOpen,
  /* TODO: Delete after rehaul completion
    onBulkUploadFileChange,
    */
  onCancel,
}: IBulkUploadRiskProfiles) {
  const [
    ,
    /* TODO: Delete after rehaul completion
    bulkUploadFile,
    */ setBulkUploadFile,
  ] = useState<File | null | undefined>(null)
  /* TODO: Delete after rehaul completion
    const [bulkUploadFileError, setBulkUploadFileError] = useState("")
    */

  /* TODO: Delete after rehaul completion
    const bulkUploadFileInputRef = useRef<HTMLInputElement>(null)
    */

  const { t } = useTranslate()
  const { selectedAccount } = useAccount()

  const ref = useOutsideComponentClickHandler(() => onCancel())

  /* NETWORKING > START */
  const TEMPLATE_REFRESH = 5 // 5 mins
  const [riskProfileTemplates] = useMemoQuery<IRiskProfileTemplate[]>(
    ["riskProfileTemplates", selectedAccount],
    () => riskProfileTemplateQuerySet.get(""),
    {
      refetchInterval: TEMPLATE_REFRESH * 60 * 1000,
    },
    undefined,
    [],
  )
  /* NETWORKING < END */

  // Reset the file reference when closed
  useEffect(() => {
    if (!isOpen) setBulkUploadFile(null)
  }, [isOpen])

  return (
    <EmptyModal
      modalRef={ref}
      open={isOpen}>
      <div className="flex flex-col h-full">
        {/* HEADER */}
        <div className="p-4 flex justify-between title-sm pb-[5px] border-b border-[#E6E7E8]">
          {t("importRiskProfiles", "Import Risk Profiles")}
          <div>
            <div
              className="inline-block w-[20px] cursor-pointer"
              onClick={() => onCancel()}>
              <CancelIcon />
            </div>
          </div>
        </div>
        {/* TEMPLATES */}
        <div className="flex flex-col h-24 p-4 grow">
          {/* CREATE RISK PROFILES */}
          <div className="grow-0">
            <div className="text-gray-60 dark:text-gray-30 roboto text-sm mb-[20px]">
              {t("createRiskProfilesBy")}
            </div>
            <div className="flex">
              <TemplateDownloadLink
                name={t("bulkUploadDownloadTemplate")}
                signedUrl={RISK_PROFILE_TEMPLATE}
              />
            </div>
          </div>
          <OrSeparator label={t("or")} />
          {/* RISK LIBRARY */}
          <div className="flex flex-col h-24 grow">
            <div className="flex justify-between pb-[5px] text-gray-60 dark:text-gray-30 text-sm">
              {t(
                "leverageClimateAiRiskLibrary",
                "Leverage ClimateAi Risk Library",
              )}
              <div>
                <div
                  className="inline-block text-gray-60 dark:text-gray-30 roboto underline font-normal text-xs mr-[20px] cursor-pointer"
                  onClick={() => {
                    // TODO: Download ALL Risk Profile Crop Templates
                    console.log(
                      "TODO: Download ALL Risk Profile Crop Templates",
                    )
                  }}>
                  {t("downloadAll", "Download all")}
                </div>
                <div
                  className="inline-block w-[20px] cursor-pointer"
                  onClick={() =>
                    console.log(
                      "TODO: Add search functionality for risk library templates",
                    )
                  }>
                  <SearchIcon />
                </div>
              </div>
            </div>
            <div className="flex h-24 overflow-x-hidden overflow-y-scroll grid grid-cols-2 grid-flow-row gap-2 grow">
              {riskProfileTemplates.map((template: IRiskProfileTemplate) => (
                <TemplateDownloadLink
                  key={template.id}
                  name={t(template.name)}
                  signedUrl={template.signed_url}
                />
              ))}
            </div>
          </div>
        </div>
        {/* UPLOAD */}
        <div className="flex flex-col gap-2 p-4 mt-[-15px]">
          <div>{t("upload")}</div>
          <RiskProfileBulkUpload />
          {/*
                    <div className="pt-[8px]">
                        <div
                            onDrop={(e) =>
                                handleFileDrop(
                                    e,
                                    setBulkUploadFile,
                                    setBulkUploadFileError,
                                    onBulkUploadFileChange
                                )
                            }
                            onDragOver={(e) => handleDragOver(e)}
                            className={
                                "grid place-items-center w-full h-[64px] border-dashed border border-gray-14 dark:border-gray-78 rounded-sm p-[8px] text-gray-30 text-[16px] cursor-pointer bg-gray-3 dark:bg-gray-88 hover:bg-gray-5 dark:hover:bg-gray-78" +
                                " body-lg font-normal" +
                                (showError(bulkUploadFileError)
                                    ? " border-red-500"
                                    : "")
                            }
                            onClick={() =>
                                bulkUploadFile
                                    ? setBulkUploadFile(null)
                                    : bulkUploadFileInputRef?.current?.click()
                            }>
                            {bulkUploadFile ? (
                                <>
                                    {bulkUploadFile.name}
                                    <div className="w-[20px]">
                                        <CancelIcon />
                                    </div>
                                </>
                            ) : (
                                t("dragDropFileInstructions")
                            )}
                        </div>
                        <input
                            onClick={() => {
                                if (bulkUploadFileInputRef.current)
                                    bulkUploadFileInputRef.current.value = ""
                            }}
                            onChange={(e) =>
                                handleFileChange(
                                    e,
                                    setBulkUploadFile,
                                    setBulkUploadFileError,
                                    onBulkUploadFileChange
                                )
                            }
                            ref={bulkUploadFileInputRef}
                            type="file"
                            accept={BULK_UPLOAD_FILE_EXTENSIONS.join(", ")}
                            className="hidden"></input>
                        {showError(bulkUploadFileError) && (
                            <span className="block text-red-500 text-[12px] pt-[1px] w-[292px] text-center">
                                {bulkUploadFileError}
                            </span>
                        )}
                    </div>
                    */}
        </div>
      </div>
    </EmptyModal>
  )
}

export default BulkUploadRiskProfiles
