import { Cell, Row, Table } from "@tanstack/react-table"
import { useTranslate } from "@tolgee/react"
import { Tooltip } from "../../climateui/components"
import {
  CancelIcon,
  CheckmarkIcon,
  LoadingCircleIcon,
  PlusIcon,
} from "../../climateui/icons"
import {
  DUPLICATED,
  ERROR,
  UNKNOWN,
  UPLOADED,
  LOADING,
  UPLOADED_OVERFLOW,
} from "./consts/cellStatus"
import { ParsedRow } from "./types"
const StatusCell = ({
  getValue,
}: {
  cell: Cell<ParsedRow, unknown>
  row: Row<ParsedRow>
  table: Table<ParsedRow>
  getValue: () => any // eslint-disable-line @typescript-eslint/no-explicit-any
  renderValue: () => any // eslint-disable-line @typescript-eslint/no-explicit-any
}) => {
  const { t } = useTranslate()
  const rowStatus = getValue()[0]
  return (
    <div className="flex flex-col w-5 h-5">
      {rowStatus === ERROR && (
        <span className="fill-red">
          <CancelIcon />
        </span>
      )}
      {rowStatus === UNKNOWN && (
        <span className="fill-gray-30">
          <PlusIcon />
        </span>
      )}
      {rowStatus === LOADING && (
        <span className="fill-accent">
          <LoadingCircleIcon />
        </span>
      )}
      {rowStatus === DUPLICATED && (
        <Tooltip
          position="right"
          content={t("alreadyExists", "Already exists")}>
          <span className="fill-gray-30">
            <CheckmarkIcon />
          </span>
        </Tooltip>
      )}
      {rowStatus === UPLOADED && (
        <Tooltip
          position="right"
          content={t("uploaded", "Uploaded")}>
          <span className="fill-green">
            <CheckmarkIcon />
          </span>
        </Tooltip>
      )}
      {rowStatus === UPLOADED_OVERFLOW && (
        <Tooltip
          position="right"
          content={t(
            "youHaveReachedYourAccountLimit",
            "You have reached your account limit",
          )}>
          <span className="fill-gray-30">
            <CheckmarkIcon />
          </span>
        </Tooltip>
      )}
    </div>
  )
}
export default StatusCell
