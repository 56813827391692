import { RowSelectionState } from "@tanstack/react-table"
import { useTranslate } from "@tolgee/react"
import { useContext, useEffect, useMemo, useState } from "react"
import { useMutation } from "react-query"
import { useLocation, useNavigate } from "react-router-dom"
import {
  Button,
  DebounceSearchInput,
  Table,
  TooltipV2,
} from "../../../climateui/components"
import { PlusIcon, TrashIcon } from "../../../climateui/icons"
import { ModalContext, ToastContext } from "../../../climateui/providers"
import { ILabel } from "../../../climateui/types"
import { isValidResponse } from "../../../climateui/utils/http"
import {
  BulkLabelsForm,
  ExportButton,
  GenericPageHeader,
  LocationsCount,
} from "../../../components"
import { usePaginationFlag } from "../../../hooks"
import useCSVData from "../../../hooks/useCSVData"
import { ResponsivePaddingWrapper } from "../../../layouts/TabLayout"
import { useAccount } from "../../../providers/AccountProvider"
import { LabelsContext } from "../../../providers/LabelsProvider"
import { useLocations } from "../../../providers/LocationsProvider"
import { IInsightsLocation } from "../../../types"
import queryClient, {
  labelDELETE,
  labelPOST,
  labelPUT,
  locationsBulkDELETE,
} from "../../../utils/networking"
import {
  buildLocationColumns,
  defaultLocationColumns,
  formatLocationsCSVData,
  LOCATIONS_CSV_HEADERS,
} from "./adminLocationsTableUtils"
import LocationsTableFilters from "./components/LocationsTableFilters"
import { bulkUpdateLabels } from "./utils/bulkActions"

const columns = buildLocationColumns(defaultLocationColumns, {
  labels: { modifiable: true },
})

export interface IBrowserLocationState {
  navigationData: { forceReload: boolean }
}

const LocationsTableView = () => {
  const {
    locations,
    childLocations,
    loadingLocations,
    tableFilteredCallback,
    filteredLocations,
    filteredChildLocations,
    canAddLocations,
  } = useLocations()
  const { queryLabels, labels } = useContext(LabelsContext)
  const { enqueueAlert } = useContext(ToastContext)
  const { confirmationModal } = useContext(ModalContext)
  const { selectedAccount, accountsObject } = useAccount()
  const [globalFilter, setGlobalFilter] = useState("")
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const [columnFilters, setColumnFilters] = useState<
    { id: string; value: unknown }[]
  >([])
  const navigate = useNavigate()
  const { state: browserLocationState } = useLocation()
  const { t } = useTranslate()
  const [showBulkLabels, setShowBulkLabels] = useState(false)
  const [isBulkActionLoading, setIsBulkActionLoading] = useState(false)

  // Helper function to get selected locations
  const getSelectedLocations = (
    locations: IInsightsLocation[],
    rowSelection: RowSelectionState,
  ) => {
    return locations.filter(
      (location) => location?.id && rowSelection[location.id] !== undefined,
    )
  }

  // Columns
  const allLocations = useMemo(
    () => [...locations, ...(childLocations || [])],
    [locations, childLocations],
  )
  const allFilteredLocations = useMemo(
    () => [...filteredLocations, ...(filteredChildLocations || [])],
    [filteredLocations, filteredChildLocations],
  )

  // CSV Data
  const locationsTableCSVHeaders = LOCATIONS_CSV_HEADERS()
  const { csvHeaders, csvData, csvString } = useCSVData(
    formatLocationsCSVData(allFilteredLocations),
    locationsTableCSVHeaders,
    "",
    allFilteredLocations.length > 0,
  )

  // Pagination options
  const pageSizeOpts = usePaginationFlag({ rowTolgeeKey: "locations" })

  // Reset table state logic
  const resetTable = () => {
    setGlobalFilter("")
    setRowSelection({})
    setColumnFilters([])
  }

  // Handling browser location state
  useEffect(() => {
    const navData = (browserLocationState as IBrowserLocationState)
      ?.navigationData
    if (navData?.forceReload) {
      queryClient.invalidateQueries(["locations"])
      queryLabels()
    }
    window.history.replaceState({}, document.title)
  }, [browserLocationState])

  // Mutation hooks for label management
  const { mutateAsync: bulkUpdateLabelsMut } = useMutation(
    async ({
      locations,
      labels,
      replace,
    }: {
      locations: IInsightsLocation[]
      labels: string[]
      replace?: boolean
    }) => bulkUpdateLabels(locations, labels, replace),
  )
  const { mutateAsync: editLabel } = useMutation(
    (label: ILabel) => labelPUT({ label_id: label.id, label }),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["locationsLabels", selectedAccount]),
    },
  )
  const { mutateAsync: addNewLabel } = useMutation(
    (label: ILabel) => labelPOST({ ...label, account_id: selectedAccount }),
    {
      onSuccess: (response) => {
        if (!isValidResponse(response)) return
        queryClient.invalidateQueries(["locationsLabels", selectedAccount])
      },
    },
  )
  const { mutateAsync: deleteLabel } = useMutation(
    (labelID: string) => labelDELETE(labelID),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["locationsLabels", selectedAccount]),
    },
  )

  const handleDeleteConfirmation = async () => {
    try {
      const responseArr = await locationsBulkDELETE(Object.keys(rowSelection))
      const someFailed = responseArr.some(
        (response) => !isValidResponse(response),
      )
      queryClient.invalidateQueries(["locations"])
      resetTable()
      enqueueAlert(
        someFailed
          ? t(
              "someLocationsFailedToDelete",
              "Some locations failed to be deleted, please try again.",
            )
          : t("NLocationsDeletedSuccessfully", {
              count: responseArr.length,
            }),
      )
    } catch (err) {
      console.error(err)
      enqueueAlert(t("thereWasAnError", "There was an unexpected error."))
    }
  }

  // Helper function for deletion logic
  const handleDeleteLocations = () => {
    confirmationModal({
      title: t(
        "areYouSureLocationBulkDelete",
        "Are you sure you want to delete the selected location(s)?",
      ),
      text: t("thisActionCantBeUndone"),
      onContinueLabel: t("delete"),
      onCancelLabel: t("cancel"),
      onContinue: () => {
        handleDeleteConfirmation()
      },
    })
  }

  // Extracted Bulk Label Save Logic
  const handleBulkLabelsSave = async (
    labels: ILabel[],
    replace?: boolean | undefined,
  ) => {
    setIsBulkActionLoading(true)
    try {
      const selectedLocations = getSelectedLocations(locations, rowSelection)
      await bulkUpdateLabelsMut({
        locations: selectedLocations,
        labels: labels.map((label) => label.id ?? ""),
        replace,
      })

      queryClient.invalidateQueries(["locations"])
      enqueueAlert(
        t("nLabelsAdded", "Labels added successfully", {
          count: labels.length,
        }),
      )
      setShowBulkLabels(false)
    } catch (err) {
      console.error(err)
      enqueueAlert(t("thereWasAnError", "There was an unexpected error."))
    } finally {
      setIsBulkActionLoading(false)
    }
  }

  return (
    <ResponsivePaddingWrapper extraClasses="elevation-1 border-r border-gray-14 dark:border-gray-78 !pr-4">
      <div className="flex flex-col h-full grow gap-2">
        <GenericPageHeader
          pageTitle={
            t("myLocations") +
            (allLocations.length > 0 ? ` (${allLocations.length})` : "")
          }
          right={
            <div className="flex flex-row items-center gap-1">
              {allFilteredLocations.length > 0 && (
                <ExportButton
                  csvString={csvString}
                  fileName="locations"
                  isPNGEnabled={false}
                  data={{ csv: csvData }}
                  cols={{ csv: csvHeaders }}
                />
              )}
              <div className="w-[140px]">
                <DebounceSearchInput
                  placeholder={t("search")}
                  onSearch={setGlobalFilter}
                />
              </div>
              <TooltipV2
                doShow={!canAddLocations}
                position="bottom"
                align="right"
                contentClass="w-48 text-left"
                content={t(
                  "noMoreLocationsAllowed",
                  "Your account reached the limit of locations, delete one or contact us to upgrade",
                )}>
                <Button
                  disabled={!canAddLocations}
                  onClick={() => navigate("./add")}
                  label={t("newLocation")}
                  icon={<PlusIcon />}
                />
              </TooltipV2>
            </div>
          }
          bottomSectionPadding=""
          bottom={
            <div className="flex flex-col gap-2 w-full">
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row flex-wrap items-center gap-1">
                  <LocationsTableFilters
                    setColumnFilters={setColumnFilters}
                    columnFilters={columnFilters}
                    showAccountFilter={
                      !!selectedAccount &&
                      !!accountsObject[selectedAccount]?.children?.length
                    }
                  />
                </div>
                <LocationsCount count={locations.length} />
              </div>

              {Object.keys(rowSelection).length > 0 && (
                <div className="flex flex-row gap-1">
                  <div className="px-4 label-lg rounded bg-gray-5 dark:bg-gray-86 text-light-text dark:text-dark-text flex flex-row items-center h-[32px]">
                    {Object.keys(rowSelection).length}{" "}
                    {t("selected", "Selected")}
                  </div>
                  <Button
                    label={t("bulkAddLabel", "Bulk Add Label")}
                    type="secondary-small"
                    disabled={isBulkActionLoading}
                    onClick={() => setShowBulkLabels(true)}
                  />
                  <Button
                    label=""
                    type="secondary-small"
                    icon={<TrashIcon />}
                    onClick={handleDeleteLocations}
                  />
                </div>
              )}
            </div>
          }
        />
        {loadingLocations && <div className="loading-screen__loader" />}
        <div className="min-h-0 mt-3 grow">
          <Table<IInsightsLocation>
            tableFilteredCallback={tableFilteredCallback}
            columns={columns}
            data={allLocations}
            getRowId={(location: IInsightsLocation, index: number) =>
              location?.id ?? index.toString()
            }
            noDataMessage={
              loadingLocations
                ? t("loadingLocations", "Loading locations...")
                : t(
                    "thereAreNoLocationsClickOnNewLocation",
                    "There are no locations, click on New location to add one.",
                  )
            }
            paginationOptions={pageSizeOpts}
            outOfText={t("of", "of")}
            state={{
              rowSelection,
              globalFilter,
              columnFilters,
              hiddenColumns: accountsObject[selectedAccount as string]?.children
                ?.length
                ? []
                : ["account"],
            }}
            setRowSelection={setRowSelection}
            setGlobalFilter={setGlobalFilter}
            rowSelectionFilter={(row) =>
              row.original.account_id === selectedAccount
            }
            setColumnFilters={setColumnFilters}
            enableMultiSort={false}
          />
        </div>
      </div>
      <BulkLabelsForm
        open={showBulkLabels}
        onCancel={() => setShowBulkLabels(false)}
        onSave={handleBulkLabelsSave}
        allLabels={labels}
        editLabel={editLabel}
        addNewLabel={addNewLabel}
        deleteLabel={deleteLabel}
      />
    </ResponsivePaddingWrapper>
  )
}

export default LocationsTableView
