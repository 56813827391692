import { useTranslate } from "@tolgee/react"
import { useEffect, useMemo } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { IStep } from "../../../climateui/components/Steps"
import FullScreen from "../../../components/FullScreen"
import { StepsNavigationLayout } from "../../../layouts"
import { useDashboard } from "../../../providers/DashboardProvider"
import DashboardNameAndDescription from "./components/DashboardNameAndDescription"
import { dashboardAdditionAndEditionRoutes } from "./routes"

const AddEditDashboard = () => {
  const { cancelDashboardAdditionOrEdition, navigateToAllowedDashboardRoute } =
    useDashboard()

  const location = useLocation()
  const { t } = useTranslate()

  const { steps, allowedPathTerminations } = useMemo(() => {
    const steps: IStep[] = []
    const allowedPathTerminations: string[] = []
    dashboardAdditionAndEditionRoutes.forEach((route) => {
      steps.push({ name: t(route.labelKey || "") })
      allowedPathTerminations.push(route.path || "")
    })
    return { steps, allowedPathTerminations }
  }, [])

  useEffect(() => {
    const paths = location.pathname.split("/")
    const lastPath = paths[paths.length - 1]
    if (!allowedPathTerminations.includes(lastPath))
      navigateToAllowedDashboardRoute()
  }, [])

  const currentStep = useMemo(() => {
    let step = 0
    const paths = location.pathname.split("/")
    const lastPath = paths[paths.length - 1]
    dashboardAdditionAndEditionRoutes.forEach((route, index) => {
      if (route.path === lastPath) {
        step = index
      }
    })
    return step
  }, [location])

  return (
    <FullScreen>
      <StepsNavigationLayout
        left={<DashboardNameAndDescription />}
        steps={steps}
        currentStep={currentStep}
        onCancel={cancelDashboardAdditionOrEdition}>
        <Outlet />
      </StepsNavigationLayout>
    </FullScreen>
  )
}

export default AddEditDashboard
