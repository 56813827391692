import { useEffect, useMemo, useRef, useState } from "react"
import { DebounceSearchInput } from ".."
import { CancelIcon, CheckmarkIcon, EditIcon, TrashIcon } from "../../icons"
import { ILabel } from "../../types"
import ColorPickerButton from "../ColorPicker/ColorPickerButton"
import LabelItem from "./LabelItem"

export function normalizeString(str: string) {
    if (!str) return str

    return str.trim().toLowerCase()
}

export interface ILabelsForm {
    labels: ILabel[]
    onDeleteLabel?: (label: ILabel) => void
    onSelectNewLabel?: (label: ILabel) => void
    onSelectExistingLabel?: (label: ILabel) => void
    onEditLabel?: (label: ILabel) => void
    colors: string[][]
    searchLabel?: string
    specialCharsLabel?: string
    notFoundLabel?: string
    pressEnterToCreateOneLabel?: string
}

function LabelsForm({
    labels = [],
    onDeleteLabel = () => void 0,
    onSelectNewLabel = () => void 0,
    onSelectExistingLabel = () => void 0,
    onEditLabel = () => void 0,
    colors,
    searchLabel = "Search",
    specialCharsLabel = "Some special characters are not allowed",
    notFoundLabel = "No labels found.",
    pressEnterToCreateOneLabel = "Press enter to create one.",
}: ILabelsForm) {
    const [inputValue, setInputValue] = useState("")
    const DEFAULT_COLOR = "#f06000"
    const [selectedColor, setSelectedColor] = useState(DEFAULT_COLOR)
    const [editedLabel, setEditedLabel] = useState<ILabel | null>(null)

    const editedLabelInputRef = useRef<HTMLInputElement>(null)

    const isExactMatch = useMemo(() => {
        const normInput = normalizeString(inputValue)
        return labels.find(({ name }) => normalizeString(name) === normInput)
    }, [labels, inputValue])

    useEffect(() => {
        if (isExactMatch) {
            setSelectedColor(isExactMatch.color)
        } else {
            setSelectedColor(DEFAULT_COLOR)
        }
    }, [isExactMatch])

    const _labels =
        labels.length > 0
            ? labels
                  .filter((label) =>
                      label?.name
                          ?.toLocaleLowerCase()
                          .includes(inputValue.toLocaleLowerCase())
                  )
                  .map((label) => (
                      <div
                          key={`label_${label?.id}_${label.color}`}
                          className="flex items-center justify-between h-[32px] pl-3 pr-1 flex-row hover:bg-gray-3 dark:bg-gray-90 dark:hover:bg-gray-88 group">
                          <LabelItem
                              label={label}
                              onClick={() => {
                                  onSelectExistingLabel(label)
                              }}
                          />
                          <div className="flex flex-row opacity-0 group-hover:opacity-100 gap-1">
                              <span
                                  className="w-5 h-5 cursor-pointer fill-gray-60 hover:scale-110 transition-all duration-100"
                                  onClick={() => {
                                      setEditedLabel(label)
                                      setSelectedColor(label.color)
                                  }}>
                                  <EditIcon />
                              </span>
                              <span
                                  className="w-5 h-5 cursor-pointer fill-gray-60 hover:scale-110 transition-all duration-100"
                                  onClick={() => onDeleteLabel(label)}>
                                  <TrashIcon />
                              </span>
                          </div>
                      </div>
                  ))
            : []

    const triggerLabelEdit = (text: string) => {
        if (
            text &&
            !labels
                .map((label) =>
                    label.id !== editedLabel?.id ? label.name : ""
                )
                .includes(text)
        ) {
            const newLabel = {
                ...editedLabel,
                name: text,
                color: selectedColor,
            } as ILabel
            if (
                text !== editedLabel?.name ||
                selectedColor !== editedLabel?.color
            )
                onEditLabel(newLabel)
            setEditedLabel(null)
            setSelectedColor(DEFAULT_COLOR)
        }
    }

    const specialCharsLabelNode = (
        <p className="text-[11px] font-roboto italic text-gray-30 leading-3 pt-1">
            {specialCharsLabel}
        </p>
    )

    return (
        <div className="flex flex-col w-48 overflow-hidden bg-light-bg dark:bg-dark-bg border rounded-lg border-gray-14 dark:border-gray-78 elevation-2 max-h-40">
            <div className="px-3 pt-3 pb-2 bg-light-bg dark:bg-dark-bg grow-0 shrink-0">
                {editedLabel ? (
                    <>
                        <DebounceSearchInput
                            icon={
                                <ColorPickerButton
                                    selectedColor={selectedColor}
                                    setSelectedColor={setSelectedColor}
                                    colors={colors}
                                />
                            }
                            autoFocus
                            defaultValue={editedLabel.name}
                            onSubmit={(text) => triggerLabelEdit(text)}
                            inputRef={editedLabelInputRef}
                            disallowedCharacters={[
                                ".",
                                ",",
                                "$",
                                "#",
                                "[",
                                "]",
                                "/",
                                "{",
                                "}",
                            ]}
                        />
                        {specialCharsLabelNode}
                        <div className="flex flex-row justify-end gap-2">
                            <span
                                className="w-5 h-5 cursor-pointer fill-gray-60 hover:scale-110 transition-all duration-100"
                                onClick={() => {
                                    setEditedLabel(null)
                                    setSelectedColor(DEFAULT_COLOR)
                                }}>
                                <CancelIcon />
                            </span>
                            <span
                                className="w-5 h-5 cursor-pointer fill-gray-60 hover:scale-110 transition-all duration-100"
                                onClick={() =>
                                    triggerLabelEdit(
                                        editedLabelInputRef.current?.value
                                            ? editedLabelInputRef.current?.value
                                            : ""
                                    )
                                }>
                                <CheckmarkIcon />
                            </span>
                        </div>
                    </>
                ) : (
                    <>
                        <DebounceSearchInput
                            onSearch={(text) => setInputValue(text)}
                            onSubmit={(text) => {
                                if (!text) return
                                if (isExactMatch) {
                                    onSelectExistingLabel(isExactMatch)
                                    return
                                }
                                onSelectNewLabel({
                                    name: text,
                                    color: selectedColor,
                                })
                                labels.push({
                                    name: text,
                                    color: selectedColor,
                                })
                            }}
                            autoFocus
                            ms={200}
                            placeholder={searchLabel}
                            disallowedCharacters={[
                                ".",
                                ",",
                                "$",
                                "#",
                                "[",
                                "]",
                                "/",
                                "{",
                                "}",
                            ]}
                            icon={
                                <ColorPickerButton
                                    selectedColor={selectedColor}
                                    setSelectedColor={setSelectedColor}
                                    colors={colors}
                                />
                            }
                        />
                        {specialCharsLabelNode}
                    </>
                )}
            </div>
            {!editedLabel && (
                <div className="pb-2 overflow-y-auto">
                    {_labels.length > 0 ? (
                        _labels
                    ) : (
                        <p className="px-3 pb-3 text-left label-sm text-light-text dark:text-dark-text">
                            {notFoundLabel} {pressEnterToCreateOneLabel}
                        </p>
                    )}
                </div>
            )}
        </div>
    )
}

export default LabelsForm
