import { PrimitiveAny } from "../../../../climateui/types"
import { isValidResponse } from "../../../../climateui/utils/http"
import { historicalGET } from "../../../../utils/networking/weather"
// TODO: Move this function to a more generic file
import { removeZeroWidthChars } from "../../../../components/BulkUpload/utils"
import Papa from "papaparse"

interface IHistoricalAttribute {
  units: string
  values: number
}
interface IHistoricalDataPoint {
  date: string
  type: string
  id: number
  attributes: Record<string, IHistoricalAttribute>
}

type IHistoricalJSONPoint = Record<string, PrimitiveAny>

const unitsEquivalence: Record<string, string> = {
  degrees_C: "°C",
  percent: "%",
}

const getAttributesValuePairs = ({ attributes: attrs }: IHistoricalDataPoint) =>
  Object.keys(attrs).reduce((prev: Record<string, PrimitiveAny>, key) => {
    prev[key] = attrs[key].values
    return prev
  }, {})

const getAttributesUnitsPairs = ({ attributes: attrs }: IHistoricalDataPoint) =>
  Object.keys(attrs).reduce((prev: Record<string, PrimitiveAny>, key) => {
    prev[key] = unitsEquivalence[attrs[key].units] ?? attrs[key].units
    return prev
  }, {})

const translateKeys = (
  obj: Record<string, unknown>,
  translationFn: (key: string) => string,
) =>
  Object.keys(obj).reduce((prev: typeof obj, key) => {
    const translatedKey = removeZeroWidthChars(
      translationFn(removeZeroWidthChars(key.trim())),
    )
    prev[translatedKey] = obj[key]
    return prev
  }, {})

const historicalToJSON = (
  data: IHistoricalDataPoint[],
  translationFn: (key: string) => string,
) =>
  data.reduce((prev: Record<string, IHistoricalJSONPoint>, curr) => {
    // For each date, initiate it on the `prev` dictionary
    // with the date and the translated keys
    prev[curr.date] = {
      [translationFn("date")]: curr.date,
      ...translateKeys(getAttributesValuePairs(curr), translationFn),
    } as IHistoricalJSONPoint
    return prev
  }, {})

const exportHistoricalData = async (
  locationID: string,
  translationFn: (key: string) => string,
) => {
  // Get the response
  const response = await historicalGET(locationID)
  // If the response is not valid, then exit with undefined
  if (!isValidResponse(response)) return
  // Convert the data response into a Papaparse JSON object
  const historicalJSON = historicalToJSON(response.data.data, translationFn)
  // Obtain the units for the attributes
  const historicalUnits = translateKeys(
    getAttributesUnitsPairs(response.data.data[0]),
    translationFn,
  )
  // Add variable/variable unit pair (just for formatting)
  const formattedHistoricalUnits = {
    [translationFn("variable")]: translationFn("variable_unit"),
    ...historicalUnits,
  }
  // Generate the units header
  const unitsHeader = Papa.unparse([formattedHistoricalUnits])
  // Append the units header to the rest of the data
  return (
    unitsHeader +
    "\n\n" +
    Papa.unparse(Object.values(historicalJSON), {
      header: false,
    })
  )
}
export default {
  exportHistoricalData,
}
