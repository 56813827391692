import { useMemo } from "react"
import LoadingAnimation from "../../../../climateui/components/LoadingAnimation"
import { IRiskCountryOverviewModel } from "../types"
import RiskOutlookBarChart, { Plot } from "./RiskOutlookBarChart"
import { getHazardConfig } from "../riskOutlookUtils"
import { getHazardStatValues } from "../riskOutlookScoreUtils"
import { LegendInterface } from "./ChartLegend"

export interface RiskScoreChartProps {
  data: IRiskCountryOverviewModel[]
  loading: boolean
  isFullSeason: boolean
  lowerBound?: number
  upperBound?: number
}

const getBarColor = (value: number, lowerBound: number, upperBound: number) => {
  if (value >= upperBound) {
    return "#E42437" // High risk - Red
  } else if (value >= lowerBound) {
    return "#FDB600" // Medium risk - Yellow
  } else {
    return "#1A8431" // Low risk - Green
  }
}

export function RiskScoreChart({ ...props }: Readonly<RiskScoreChartProps>) {
  const {
    data,
    loading,
    isFullSeason,
    lowerBound = 70,
    upperBound = 90,
    ...rest
  } = props

  const { result: chartData, stacks } = useMemo(() => {
    const result: { plot: Plot[] } = { plot: [] }
    let stacks = new Set<string>()

    if (!data?.[0]?.default_geography?.hazards) return { result, stacks: [] }

    const hazards = [...data[0].default_geography.hazards].sort((a, b) =>
      a.hazard_name.localeCompare(b.hazard_name),
    )

    function getSeasonalDataPoints(
      hazards: IRiskCountryOverviewModel["default_geography"]["hazards"],
      resultIndex: number,
      prop: "forecast_contribution" | "observed_contribution",
      color: string,
    ) {
      return hazards.map((hazard) => {
        const { hazard_key, hazard_name } = hazard
        const statValues = getHazardStatValues(
          hazard.seasonal_stats.results[resultIndex],
        )
        return {
          label: getHazardConfig(hazard_key, hazard_name).title,
          value: statValues[prop] ?? 0,
          color,
          meta: statValues,
        }
      })
    }

    if (isFullSeason) {
      // iterate the hazards[0] and results
      hazards[0].seasonal_stats.results.forEach((res, index) => {
        stacks.add(res.season_label)
        result.plot.push({
          id: "risk-score-past-events",
          visualization: "bar",
          stackId: res.season_label,
          points: getSeasonalDataPoints(
            hazards,
            index,
            "observed_contribution",
            "#A4DFD4",
          ),
        })
        result.plot.push({
          id: "risk-score-forecast",
          visualization: "bar",
          stackId: res.season_label,
          points: getSeasonalDataPoints(
            hazards,
            index,
            "forecast_contribution",
            index === 0 ? "#004957" : "#3AA692",
          ),
        })
      })
    } else {
      stacks.add("upcoming")
      result.plot.push({
        id: "risk-score-forecast",
        visualization: "bar",
        stackId: "upcoming",
        points: hazards.map((hazard) => {
          const { hazard_key, hazard_name } = hazard
          const statValues = getHazardStatValues(hazard.daily_stats.results[0])
          return {
            label: getHazardConfig(hazard_key, hazard_name).title,
            value: statValues.index_value ?? 0,
            color: getBarColor(statValues.index_value ?? 0, 70, 90),
            meta: statValues,
          }
        }),
      })
    }

    // Sort stacks lexicographically
    stacks = new Set([...stacks].sort((a, b) => a.localeCompare(b)))
    return { result, stacks: Array.from(stacks) }
  }, [data, isFullSeason, lowerBound, upperBound])

  const stackLegends = stacks.map((stack) => stack.replaceAll("20", ""))
  const legends: LegendInterface[] = []
  if (isFullSeason) {
    legends.push({
      type: "dot",
      color: "#A4DFD4",
      label: `Past (${stackLegends[0]})`,
    })
    stackLegends.forEach((stack, index) => {
      legends.push({
        type: "dot",
        color: index !== 0 || stackLegends.length === 1 ? "#004957" : "#3AA692",
        label: `Upcoming (${stack})`,
      })
    })
  } else {
    legends.push({
      type: "dot",
      color: "#1A8431",
      label: "Risk level",
    })
  }

  if (loading) {
    return (
      <div className="flex justify-center mt-5 body-lg">
        <LoadingAnimation />
      </div>
    )
  }

  return (
    <RiskOutlookBarChart
      data={chartData}
      isFullSeason={isFullSeason}
      title={
        isFullSeason
          ? "Past & Future severity breakdown by factor"
          : "Risk level by hazard"
      }
      domain={[0, 100]}
      stacks={stacks}
      legends={legends}
      {...rest}
    />
  )
}
