import { Row } from "@tanstack/react-table"
import RiskOutlookIcon from "../../../climateui/icons/variableIcons/RiskOutlookIcon"

import { Translate } from "../../../components"

import { useTranslate } from "@tolgee/react"
import Color from "color"
import { DateTime } from "luxon"
import { useNavigate } from "react-router-dom"
import DynamicMagnitudeIcon from "../../../climateui/icons/DynamicMagnitudeIcon"
import { useUI } from "../../../providers/UIProvider"
import { useRiskOutlook } from "./provider"
import {
  filterStagesByCurrentDate,
  getDirectionalityHighlightColor,
  getRiskCategoryCoding,
  getStageConfig,
} from "./riskOutlookUtils"
import { IRiskCountryOverviewModel, IRiskOverview } from "./types"
import _ from "lodash"

const OUT_OF_SEASON = "out_of_season"

// ... (similar to YieldOverviewTableUtils.tsx, but adapted for Risk)
function AssetNameCell({ assetName }: { readonly assetName: string }) {
  return (
    <div className="flex body-lg items-center">
      <div className="w-[20px] fill-gray-60 mx-2">
        <RiskOutlookIcon />
      </div>
      <h1>{assetName}</h1>
    </div>
  )
}

function AssetModelsCell({
  riskModel,
  assetId,
}: {
  readonly riskModel: IRiskCountryOverviewModel
  readonly assetId: string
}) {
  const { t } = useTranslate()
  const navigate = useNavigate()
  const { setShowSecondSidebar } = useUI()
  const { countries } = useRiskOutlook()

  const filteredStages = filterStagesByCurrentDate(
    riskModel?.stages,
    DateTime.now().toFormat("yyyy-MM-dd"),
  )
  const currentSeason = filteredStages[0]?.stage_name
  const isOutOfSeason = currentSeason === OUT_OF_SEASON
  const modelHasData = !!riskModel

  const riskCategory = getRiskCategoryCoding(
    riskModel?.highest_risk_score,
    riskModel?.bounds,
  )
  const dataError = riskCategory === undefined && !isOutOfSeason

  let boxBaseColor: string | undefined
  if (dataError) boxBaseColor = "#B3B6BA"
  else if (isOutOfSeason) boxBaseColor = "#DBDDDF"
  else boxBaseColor = getDirectionalityHighlightColor(riskCategory)

  const boxColorStyles = {
    backgroundColor: !dataError
      ? Color(boxBaseColor).alpha(0.1).hexa()
      : "var(--bg-light)",
    color: Color(boxBaseColor).darken(0.2).hex(),
    borderColor: Color(boxBaseColor).alpha(0.4).hexa(),
  }

  let riskDescription = ""

  if (isOutOfSeason) {
    const endDate = DateTime.fromFormat(
      riskModel.stages[0].end_date as string,
      "MM-dd",
    ).set({ year: DateTime.now().year })
    riskDescription = "Outlook starts on " + endDate.toFormat("MM/dd")
  } else {
    riskDescription = `${t(riskCategory ?? "")} `
  }

  function handleCardClick() {
    if (isOutOfSeason && !modelHasData) return
    navigate(assetId + "?region=" + riskModel.region_id)
    setShowSecondSidebar(true)
  }

  const stageConfig = getStageConfig(currentSeason)

  return (
    <div
      onClick={handleCardClick}
      onKeyDown={handleCardClick}
      role="button"
      tabIndex={0}
      className="border-[1px] rounded-md p-[10px] w-[170px] h-[80px]"
      style={{
        ...boxColorStyles,
        cursor: isOutOfSeason && !modelHasData ? "not-allowed" : "pointer",
      }}>
      <div className="flex flex-col">
        <div className="flex justify-between">
          <h1
            className={
              isOutOfSeason
                ? "text-gray-60 dark:text-gray-30"
                : "text-light-text dark:text-dark-text"
            }>
            {countries[riskModel?.region_id]?.name}
          </h1>
          {!isOutOfSeason && riskCategory !== undefined && (
            <div className="w-[20px]">
              <DynamicMagnitudeIcon magnitude={riskCategory} />
            </div>
          )}
        </div>

        {isOutOfSeason && (
          <>
            <div className="flex body-md">
              <h1 className="font-medium">Out of Season</h1>
            </div>
            <div className="flex body-sm">
              <p>{riskDescription}</p>
            </div>
          </>
        )}

        {!isOutOfSeason && (
          <>
            <div className="flex justify-center body-md">
              <h1>{riskDescription}</h1>
            </div>
            <div
              className={`flex body-sm truncate ${
                isOutOfSeason || dataError
                  ? "text-gray-30"
                  : "text-gray-60 dark:text-gray-30"
              }`}>
              {stageConfig.label.length > 20
                ? stageConfig.label.substring(0, 20) + "..."
                : stageConfig.label}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export const columns = [
  {
    id: "asset",
    header: () => <Translate labelKey="crop" />,
    cell: ({ row }: { row: Row<IRiskOverview> }) => {
      return <AssetNameCell assetName={row.original.asset_name} />
    },
  },
  {
    id: "risk_outlook",
    header: () => <Translate labelKey="risk_to_production_by_country" />,
    cell: ({ row }: { row: Row<IRiskOverview> }) => {
      const { countries } = useRiskOutlook()
      return (
        <div className="py-4 px-1 gap-2 flex items-center">
          {row.original.asset_models.map((model) => (
            <AssetModelsCell
              key={row.original.asset_id + countries[model?.region_id]?.name}
              riskModel={model}
              assetId={row.original.asset_id}
            />
          ))}
        </div>
      )
    },
  },
]
