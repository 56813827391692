import { useQuery } from "react-query"
import { isValidResponse } from "../climateui/utils/http"
import { IAsset, IVariety } from "../types"
import { varietyQuerySet } from "../utils/networking/asset"
import { arrToDict } from "../utils/transform"
import { varietyMainXFields } from "./useVarietiesQuery"

const useCAIDefaultVarietiesQuery = (
  assets: Record<string, IAsset> | undefined,
) => {
  return useQuery({
    queryKey: ["cai_varieties", assets],
    queryFn: () =>
      varietyQuerySet.post(
        "search",
        {
          filter_by: {
            and: [
              {
                field_name: "variety.Variety.asset_id",
                operator: "in",
                field_value: JSON.stringify(Object.keys(assets ?? {})),
              },
              {
                field_name: "variety.Variety.account_id",
                operator: "eq",
                field_value: "Default",
              },
            ],
          },
          order_by: ["variety.Variety.name"],
        },
        {},
        {
          headers: {
            "X-Fields": `data{${varietyMainXFields}}`,
          },
        },
        true,
      ),
    enabled: !!assets && Object.keys(assets).length > 0,
    select: (response) => {
      if (!isValidResponse(response)) return {}
      const rawVarieties: IVariety[] = response.data.data

      return arrToDict<IVariety>(rawVarieties, "id")
    },
  })
}
export default useCAIDefaultVarietiesQuery
