import { ReactNode } from "react"
import { Steps } from "../climateui/components"
import { IStep } from "../climateui/components/Steps"
import { CancelIcon } from "../climateui/icons"

interface IStepsNavigationLayout {
  children: ReactNode
  left: ReactNode
  steps: IStep[]
  currentStep: number | null
  onCancel?: () => void
}

const StepsNavigationLayout = ({
  children,
  left,
  steps,
  currentStep,
  onCancel,
}: IStepsNavigationLayout) => {
  return (
    <div className="flex flex-col w-full h-full divide-y divide-gray-14 dark:divide-gray-78">
      <div className="grow-0 shrink-0 h-[80px] flex flex-row items-center px-5 justify-between">
        <div
          className={[
            "overflow-hidden",
            steps.length > 0 ? "shrink flex-1" : "grow",
          ].join(" ")}>
          {left}
        </div>
        {steps.length > 0 && (
          <div className="flex-1 px-20 text-center shrink-0">
            <Steps
              steps={steps}
              currentStep={currentStep || 0}
            />
          </div>
        )}
        <div className="flex items-center justify-end flex-1 shrink">
          {onCancel && (
            <button
              className="w-6 fill-gray-60 hover:scale-110 transition-all duration-75"
              onClick={onCancel}>
              <CancelIcon />
            </button>
          )}
        </div>
      </div>

      <div className="grow overflow-hidden">{children}</div>
    </div>
  )
}

export default StepsNavigationLayout
