import { FC } from "react"

interface DefaultTitleProps {
    value: string
}

export const DefaultTitle: FC<DefaultTitleProps> = ({ value }) => {
    return (
        <h3 className="pb-4 label-lg text-light-text dark:text-dark-text">
            {value}
        </h3>
    )
}

/* Helper functions */
export const onRenderDefaultTitle = (value: string) => {
    return <DefaultTitle value={value} />
}
