const page = {
  // query selector
  "title": {
    // attribute to change
    innerHTML: "ClimateLens",
  },
  "#favicon": {
    href: "/images/climateai/favicon.ico",
  },
  "#apple-favicon": {
    href: "/images/climateai/apple-touch-icon.png",
  },
}

export default page
