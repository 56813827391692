import { gql } from "graphql-request"
import WidgetWrapper from "../../climateui/components/Widgets/WidgetWrapper/WidgetWrapper"
import CountryYieldOutlookWidget from "../../views/Climate/YieldOutlook/components/widgetLibrary/CountryYieldOutlookWidget"
import { IYieldOutlookWidgetProps } from "./types"

const queries = {
  adapt: gql`
    query (
      $decade: Int
      $scenario: String
      $region_ids: [String]
      $asset_ids: [String]
    ) {
      yield_outlook_model(
        filter: { region_ids: $region_ids, asset_ids: $asset_ids }
      ) {
        results {
          stats: decadal_stats(
            filter: { scenario: $scenario, decade: $decade }
          ) {
            results {
              scenario
              decade
              tercile_probabilities
            }
          }
        }
      }
    }
  `,
  monitor: gql`
    query (
      $country_id: String
      $asset_ids: [String]
      $init_time: String
      $units: String
    ) {
      yield_outlook_model(
        filter: {
          region_ids: [$country_id]
          asset_ids: $asset_ids
          seasonal_status: "active"
        }
      ) {
        results {
          stats: seasonal_stats(
            filter: { init_time: $init_time, units: $units }
          ) {
            results {
              tercile_probabilities
            }
          }
        }
      }
    }
  `,
}
const YieldOutlookSummary = ({
  filters,
  selectors,
  type,
}: IYieldOutlookWidgetProps) => {
  return (
    <WidgetWrapper
      selectedFilters={[
        "decade",
        "scenario",
        "region_ids",
        "asset_ids",
        "init_time",
        "country_id",
      ]}
      component={CountryYieldOutlookWidget}
      query={queries[type]}
      filters={filters}
      selectors={selectors}
    />
  )
}
export default YieldOutlookSummary
