// NOTE: Candidate for ClimateUI

import _ from "lodash"
import { Dispatch, SetStateAction } from "react"
import { Slider } from "../climateui/components"
import NumberInput from "./NumberInput"

const PercentageInput = ({
  percentage,
  setPercentage,
  label,
}: {
  percentage: number | undefined
  setPercentage: Dispatch<SetStateAction<number | undefined>>
  label?: string
}) => {
  return (
    <div className="flex flex-col grow">
      {/* SLIDER LABEL */}
      <div className="flex items-center justify-between">
        {label && (
          <span className="label-sm text-gray-60 dark:text-gray-30">
            {label}
          </span>
        )}
        <NumberInput
          min={0.01}
          max={1}
          isPercentage
          initialValue={percentage}
          onChange={(val) => {
            if (val) setPercentage(val)
          }}
        />
      </div>
      {/* SLIDER BAR */}
      <Slider
        id="bulk-edit-probability"
        isDouble={false}
        setSelectedIndexes={(prob) => {
          if (!_.isNumber(prob)) return
          setPercentage((prob + 1) / 100)
        }}
        options={[...Array(100).keys()]}
        selectedBottomIndex={percentage ? Math.trunc(percentage * 100) - 1 : 0}
        selectedTopIndex={percentage ? Math.trunc(percentage * 100) - 1 : 0}
        showTicks={false}
      />
    </div>
  )
}

export default PercentageInput
