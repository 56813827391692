import { ChevronArrow } from "../icons"
import Tooltip from "./Tooltip"

type Props = {
    open: boolean
    toggle: (status: boolean) => void
    openDirection?: "left" | "top" | "right" | "bottom"
}

const Toggler: React.FC<Props> = ({
    open,
    toggle,
    openDirection = "right",
}) => {
    let rotationClass = ""

    switch (openDirection) {
        case "left":
            rotationClass = open ? "-rotate-90" : "rotate-90"
            break
        case "top":
            rotationClass = open ? "rotate-180" : "rotate-0"
            break
        case "right":
            rotationClass = open ? "rotate-90" : "-rotate-90"
            break
        case "bottom":
            rotationClass = open ? "rotate-0" : "rotate-180"
            break
    }

    return (
        <Tooltip
            content={open ? "Collapse" : "Expand"}
            position={openDirection}>
            <button
                className={
                    "cursor-pointer rounded-full bg-light-bg dark:bg-dark-bg elevation-1 h-[24px] w-[24px] fill-gray-60 border border-gray-14 dark:border-gray-78 " +
                    " flex flex-col items-center justify-center transition-all duration-75" +
                    " hover:bg-accent hover:fill-white hover:border-accent" +
                    " focus:outline-accent"
                }
                onClick={() => toggle(!open)}>
                <div className={"transform " + rotationClass}>
                    <ChevronArrow />
                </div>
            </button>
        </Tooltip>
    )
}

export default Toggler
