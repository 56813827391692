import CalendarView from "./CalendarView"
import { ICalendarCell } from "./CalendarCell"
import { DateTime } from "luxon"
const MonthView = ({
    viewDate,
    onClick,
    yearAgnostic = false,
}: {
    viewDate: DateTime
    onClick?: (cell: ICalendarCell) => void
    yearAgnostic?: boolean
}) => {
    const yearStart = viewDate.startOf("year")
    const today = DateTime.now().startOf("month")

    // Create a days array skipping the 0th day
    const months: ICalendarCell[] = [...Array(12).keys()].map((month) => {
        const date = yearStart.plus({ months: month })
        return {
            date,
            size: {
                width: 20,
                height: 20,
            },
            label: date.monthShort,
            current: yearAgnostic
                ? false
                : date.toISODate() === today.toISODate(),
        }
    })

    return (
        <CalendarView
            onClick={onClick}
            columns={4}
            values={months}
        />
    )
}
export default MonthView
