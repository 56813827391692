import http from "../../climateui/utils/http"
import config from "../config"

export const accountDashboardsGET = async ({ queryKey }) => {
  const accountId = queryKey[1]
  return await http.get(
    `/dashboard/?account_id=${accountId}`,
    true,
    {},
    config.dashboard_platform,
  )
}

export const dashboardPOST = (data) =>
  http.post("/dashboard/", data, true, {}, config.dashboard_platform)

export const dashboardGET = (dashboardId) =>
  http.get(`/dashboard/${dashboardId}`, true, {}, config.dashboard_platform)

export const dashboardPUT = (dashboard) =>
  http.put(
    `/dashboard/${dashboard.id}`,
    dashboard,
    true,
    {},
    config.dashboard_platform,
  )

export const dashboardDELETE = (dashboardId) =>
  http.delete(
    `/dashboard/${dashboardId}`,
    null,
    true,
    {},
    config.dashboard_platform,
  )

export const dashboardSEND = async (body) => {
  http.post(
    "report/send/",
    body,
    true,
    { timeout: 40000 },
    config.report_platform,
  )
}

export const getDashboardPDF = async (body) => {
  // local testing
  // const url = "http://localhost:5000/report/"
  const url = config.report_platform + "report/"
  return await http.post(url, body, true, {
    timeout: 900000,
    responseType: "blob",
  })
}

export const accountSchedulesGET = async ({ queryKey }) => {
  const accountId = queryKey[1]
  return await http.get(
    `/report_schedule/?account_id=${accountId}`,
    true,
    {},
    config.dashboard_platform,
  )
}

export const schedulePOST = (data) =>
  http.post("/report_schedule/", data, true, {}, config.dashboard_platform)

export const scheduleDELETE = (scheduleId) =>
  http.delete(
    `/report_schedule/${scheduleId}`,
    null,
    true,
    {},
    config.dashboard_platform,
  )

export const scheduleGET = (scheduleId) =>
  http.get(
    `/report_schedule/${scheduleId}`,
    true,
    {},
    config.dashboard_platform,
  )

export const schedulePUT = (schedule) =>
  http.put(
    `/report_schedule/${schedule.id}`,
    schedule,
    true,
    {},
    config.dashboard_platform,
  )
