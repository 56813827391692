import mapboxgl from "../mapboxgl"
import { useCallback, useEffect, useRef, useState } from "react"
import { IPolygonGroup } from "./types"
import "../mapbox.css"
import CustomMapControls from "../CustomMapControls"
import ShadedRegionsMapLegend from "./ShadedRegionsMapLegend"
import bbox from "geojson-bbox"
import { appendPolygonGroup, removePolygonGroup, setupMap } from "./utils"

const DEFAULT_MAPBOX_CONFIG = {
    style: "mapbox://styles/geramv/cl5k2k0ms001414ni9wxhbpsd",
    darkStyle: "mapbox://styles/geramv/cl5rcg0vj004k14pmk1c8wjsa",
    center: [0, 0] as [number, number],
    zoom: 1,
    attributionControl: false,
    preserveDrawingBuffer: true,
}

const ShadedRegionsMap = ({
    data,
    title,
    loading,
    showLegend = true,
    pins,
    isDarkMode,
}: {
    data: Record<string, IPolygonGroup>
    title?: string
    loading?: boolean
    showLegend?: boolean
    isDarkMode?: boolean
    pins?: {
        lng: number
        lat: number
        color: string
    }[]
}) => {
    const [mapboxMap, setMapboxMap] = useState<mapboxgl.Map>()
    const [layers, setLayers] = useState<(string | undefined)[]>([])

    const prevContainerRect = useRef<DOMRect | undefined>()
    const containerRef = useRef<HTMLElement | undefined>()

    const mapRef = useCallback(
        (container: HTMLDivElement | null) => {
            if (!container) return
            containerRef.current = container

            const mapStyle = isDarkMode
                ? DEFAULT_MAPBOX_CONFIG.darkStyle
                : DEFAULT_MAPBOX_CONFIG.style

            console.log("Initializing map with:", {
                isDarkMode,
                mapStyle,
                container: !!container,
            })

            const mapboxConfig = {
                ...DEFAULT_MAPBOX_CONFIG,
                container,
                style: mapStyle,
            }

            const mapboxMap = setupMap({
                containerRef,
                mapboxConfig,
                prevContainerRect,
            })

            // Add a style.load event handler
            mapboxMap.on("style.load", () => {
                console.log("Map style loaded:", mapboxMap.getStyle().name)
            })

            setMapboxMap(mapboxMap)
        },
        [isDarkMode]
    )
    // Add or remove pins from the map
    useEffect(() => {
        if (!mapboxMap) return

        // Remove existing markers
        mapboxMap
            .getCanvasContainer()
            .querySelectorAll(".mapboxgl-marker")
            .forEach((el) => el.remove())

        // Add new markers if pins are available
        if (pins && pins.length > 0) {
            pins.forEach((pin) => {
                new mapboxgl.Marker({ color: pin.color })
                    .setLngLat([pin.lng, pin.lat])
                    .addTo(mapboxMap)
            })
        }
    }, [pins, mapboxMap])

    useEffect(() => {
        if (!mapboxMap) return
        if (loading) {
            // Clear all layers if there are any
            if (layers.length > 0) {
                layers.forEach((polyGroupId) => {
                    if (!polyGroupId) return
                    removePolygonGroup(mapboxMap, polyGroupId)
                })
            }
            mapboxMap.flyTo({
                center: DEFAULT_MAPBOX_CONFIG.center,
                zoom: DEFAULT_MAPBOX_CONFIG.zoom,
            })
        } else {
            // Paint each region onto the map
            const bounds = new mapboxgl.LngLatBounds()
            const layerGroups = Object.entries(data).map(([id, polyGroup]) => {
                const geojsonBounds = bbox(polyGroup.geojson)
                if (Math.abs(geojsonBounds[0]) === Infinity) return
                const appended = appendPolygonGroup(mapboxMap, id, polyGroup)
                bounds.extend(geojsonBounds)
                mapboxMap.fitBounds(bounds, {
                    padding: 20,
                })
                if (appended) return id
            })
            setLayers(layerGroups.flat())
        }
    }, [data, mapboxMap, loading])

    // Add effect to update map style when dark mode changes
    useEffect(() => {
        if (!mapboxMap) return

        // Store current layers and data
        const currentLayers = [...layers]
        const currentData = { ...data }

        // Change the style
        mapboxMap.setStyle(
            isDarkMode
                ? DEFAULT_MAPBOX_CONFIG.darkStyle
                : DEFAULT_MAPBOX_CONFIG.style
        )

        // Wait for the style to load and reapply layers
        mapboxMap.once("style.load", () => {
            // Clear existing layers
            currentLayers.forEach((polyGroupId) => {
                if (!polyGroupId) return
                removePolygonGroup(mapboxMap, polyGroupId)
            })

            // Reapply layers with current data
            const bounds = new mapboxgl.LngLatBounds()
            const layerGroups = Object.entries(currentData).map(
                ([id, polyGroup]) => {
                    const geojsonBounds = bbox(polyGroup.geojson)
                    if (Math.abs(geojsonBounds[0]) === Infinity) return
                    const appended = appendPolygonGroup(
                        mapboxMap,
                        id,
                        polyGroup
                    )
                    bounds.extend(geojsonBounds)
                    mapboxMap.fitBounds(bounds, {
                        padding: 20,
                    })
                    if (appended) return id
                }
            )
            setLayers(layerGroups.flat())
        })
    }, [isDarkMode, mapboxMap])

    return (
        <div className="relative w-full h-full">
            <div
                ref={mapRef}
                className="w-full h-full min-w-[320px] min-h-[385px] rounded-sm">
                {mapboxMap && <CustomMapControls map={mapboxMap} />}
                {showLegend && (
                    <ShadedRegionsMapLegend
                        title={title}
                        data={data}
                    />
                )}
            </div>
        </div>
    )
}
export default ShadedRegionsMap
