import { uniqueId } from "lodash"
import { ReactNode, useEffect, useRef, useState } from "react"
import useOutsideComponentClickHandler from "../../hooks/useOutsideComponentClickHandler"
import { ArrowBottom, CancelIcon } from "../../icons"
import DebounceSearchInput from "../DebounceSearchInput"
import { MIN_NUMBER_OF_OPTIONS_TO_ALLOW_SEARCH } from "../Inputs/utils"
import { getDropdownSorter } from "../utils"

/* STYLE INTERFACES */
interface IRoundOptions {
    topLeft: boolean
    topRight: boolean
    botLeft: boolean
    botRight: boolean
}
interface IBorderOptions {
    top: boolean
    right: boolean
    bottom: boolean
    left: boolean
}

export interface ISingleSelectFilterProps {
    icon?: ReactNode
    selected?: string
    options?: Record<string, string>
    setSelected?: (key: string) => void
    textAlignClass?: string
    placeholder?: string
    leftRightClass?: string
    disabled?: boolean
    canSearch?: boolean
    searchPlaceholder?: string
    roundOpts?: IRoundOptions
    borderOpts?: IBorderOptions
    sortByKey?: boolean
    keepOrder?: boolean
    clearable?: boolean
    firstOptAsDefault?: boolean
}

const SingleSelectFilter = ({
    icon = null,
    selected = "",
    options = {},
    setSelected = () => null,
    textAlignClass = "text-left",
    placeholder = "Select an option",
    leftRightClass = "right-0",
    disabled = false,
    canSearch = false,
    searchPlaceholder = "Search",
    roundOpts = {
        topLeft: true,
        topRight: true,
        botLeft: true,
        botRight: true,
    },
    borderOpts = {
        top: true,
        right: true,
        bottom: true,
        left: true,
    },
    sortByKey = true,
    keepOrder = false,
    clearable = false,
    firstOptAsDefault = false,
}: ISingleSelectFilterProps) => {
    const [open, toggle] = useState(false)
    const [searchText, setSearchText] = useState("")

    const clearSelected = () => {
        setSelected("")
        toggle(() => false)
    }

    const dropdownRef = useOutsideComponentClickHandler(() => toggle(false))
    const inputRef = useRef<HTMLInputElement | null>(null)
    const textRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        setSearchText("")
        if (open && inputRef.current) inputRef?.current?.focus()
    }, [open])

    const dropdownSort = getDropdownSorter({
        options,
        keepOrder,
        sortByKey,
    })
    const optionsKeysList = Object.keys(options)
        .filter((key) => {
            const value = options[key]
            if (!value) return false
            return value
                .toLocaleLowerCase()
                .includes(searchText.toLocaleLowerCase())
        })
        .sort(dropdownSort)

    useEffect(() => {
        if (
            optionsKeysList.length > 0 &&
            !clearable &&
            !selected &&
            firstOptAsDefault
        ) {
            setSelected(optionsKeysList[0])
        }
    }, [options, firstOptAsDefault])

    const select = (key: string, keepOpen = false) => {
        setSelected(key)
        if (keepOpen) inputRef?.current?.focus()
        else toggle(false)
    }

    const canActuallySearch =
        canSearch &&
        Object.keys(options).length > MIN_NUMBER_OF_OPTIONS_TO_ALLOW_SEARCH

    return (
        <div
            ref={dropdownRef}
            className="bg-light-bg dark:bg-dark-bg relative min-w-0 max-w-[200px] select-none w-min">
            <button
                disabled={disabled}
                className={[
                    "flex flex-row items-center",
                    "transition-all duration-75",
                    "h-[32px] min-w-[60px] pl-[8px] pr-[2px] w-fit max-w-full",
                    borderOpts.top ? "border-t-[1px]" : "",
                    borderOpts.right ? "border-r-[1px]" : "",
                    borderOpts.bottom ? "border-b-[1px]" : "",
                    borderOpts.left ? "border-l-[1px]" : "",
                    roundOpts?.topLeft ? "rounded-tl-sm" : "",
                    roundOpts?.topRight ? "rounded-tr-sm" : "",
                    roundOpts?.botLeft ? "rounded-bl-sm" : "",
                    roundOpts?.botRight ? "rounded-br-sm" : "",
                    "cursor-pointer disabled:cursor-not-allowed",
                    "focus:outline-accent",
                    "disabled:bg-gray-5 dark:disabled:bg-gray-60 disabled:text-gray-30 disabled:fill-gray-30",
                    textAlignClass,
                    open
                        ? "border-accent"
                        : "border-gray-14 dark:border-gray-78 hover:enabled:border-gray-30",
                    "text-gray-60 dark:text-gray-30 fill-gray-60",
                ].join(" ")}
                onClick={() => toggle(!open)}>
                {icon !== null && (
                    <span className="shrink-0 grow-0 w-[20px] mr-1 -ml-1">
                        {icon}
                    </span>
                )}
                <div
                    className="truncate pointer-events-none label-lg grow whitespace-nowrap"
                    ref={textRef}>
                    {selected !== "" ? options[selected] : placeholder}
                </div>
                {(!clearable || !selected) && (
                    <span className="shrink-0 grow-0 w-[20px]">
                        <ArrowBottom />
                    </span>
                )}
                {selected && clearable && (
                    <span
                        role="button"
                        onClick={clearSelected}
                        className="shrink-0 grow-0 w-[20px]">
                        <CancelIcon />
                    </span>
                )}
            </button>
            {open && (
                <div
                    className={[
                        "absolute top-full",
                        leftRightClass,
                        "bg-light-bg dark:bg-dark-bg z-30 rounded-lg elevation-2",
                        "h-fit pb-2 max-h-[400px] overflow-y-auto",
                        "border-[1px] border-gray-14 dark:border-gray-78",
                        !canActuallySearch ? "pt-2" : "",
                    ].join(" ")}>
                    {canActuallySearch && (
                        <div className="sticky top-0 bg-light-bg dark:bg-dark-bg p-2 pb-1 min-w-[160px]">
                            <DebounceSearchInput
                                onSearch={setSearchText}
                                onSubmit={() => {
                                    if (
                                        optionsKeysList.length > 0 &&
                                        searchText !== ""
                                    ) {
                                        setSearchText("")
                                        if (inputRef?.current)
                                            inputRef.current.value = ""
                                        select(optionsKeysList[0], true)
                                    }
                                }}
                                ms={100}
                                autoFocus={false}
                                placeholder={searchPlaceholder}
                                onFocusOrTypeOrClick={() => null}
                                inputRef={inputRef}
                            />
                        </div>
                    )}
                    {optionsKeysList.length > 0 ? (
                        optionsKeysList.map((key, index) => (
                            <div
                                key={uniqueId()}
                                className={[
                                    "flex flex-row items-center gap-2",
                                    "h-[32px] px-2 py-1 min-w-[40px] w-full",
                                    "hover:bg-gray-3 dark:bg-gray-90 dark:hover:bg-gray-88 cursor-pointer",
                                    textAlignClass,
                                    selected == key ? "bg-gray-5" : "",
                                    searchText !== "" && index === 0
                                        ? "bg-gray-3 dark:bg-gray-88"
                                        : "",
                                ].join(" ")}
                                onClick={() => select(key)}>
                                <p className="w-full truncate pointer-events-none whitespace-nowrap body-md">
                                    {options[key]}
                                </p>
                            </div>
                        ))
                    ) : (
                        <button
                            disabled
                            className={[
                                "flex flex-row items-center",
                                "h-[32px] px-2 my-1 min-w-[40px] w-full",
                                "cursor-not-allowed",
                                textAlignClass,
                            ].join(" ")}>
                            <p className="w-full truncate pointer-events-none whitespace-nowrap body-md text-gray-30">
                                No available options
                            </p>
                        </button>
                    )}
                </div>
            )}
        </div>
    )
}

export default SingleSelectFilter
