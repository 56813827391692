import { useEffect, useMemo, useState } from "react"

import LabelItem from "../../../../climateui/components/Labels/LabelItem"

import LabelsForm from "../../../../climateui/components/Labels/LabelsForm"
import queryClient, {
  labelDELETE,
  labelPOST,
  labelPUT,
  locationPUT,
} from "../../../../utils/networking"

import { useTranslate } from "@tolgee/react"
import { uniqueId } from "lodash"
import { useMutation } from "react-query"
import { Chip } from "../../../../climateui/components"
import FixedLayer from "../../../../climateui/components/FixedLayer"
import { useOutsideComponentClickHandler } from "../../../../climateui/hooks"
import { PlusIcon } from "../../../../climateui/icons"
import { ILabel } from "../../../../climateui/types"
import {
  colors,
  formattedColorsMatrixForColorPicker,
} from "../../../../climateui/utils/colors"
import { useAccount } from "../../../../providers/AccountProvider"
import { ILocationEssentials } from "../../../../types"

const UNFOLDED_LABELS_COUNT = 2
const EXPANDED_UNFOLDED_LABELS_COUNT = 4
const LABELS_PER_ROW = 2

export interface ILabelPicker {
  isNewLocation?: boolean
  labels: ILabel[]
  refreshLabels: () => void
  addLabelToLocCallback?: (location: ILocationEssentials) => void
  locationData: ILocationEssentials
  modifiable: boolean
  expanded?: boolean
}

function LabelPicker({
  isNewLocation = false,
  addLabelToLocCallback = () => void 0,
  locationData,
  labels,
  refreshLabels,
  modifiable,
  expanded = false,
}: ILabelPicker) {
  const { t } = useTranslate()
  const { selectedAccount } = useAccount()

  const [isHovering, setIsHovering] = useState(false)
  const [isAddingLabel, setIsAddingLabel] = useState<ILabel | null>(null)
  const [openNewLabel, toggleNewLabel] = useState(false)
  const [openLastItems, toggleLastItems] = useState(false)

  const { mutate: addLabel } = useMutation(labelPOST)
  const { mutate: editLabel } = useMutation(labelPUT)
  const { mutate: deleteLabel } = useMutation(labelDELETE)
  const { mutate: editLocation } = useMutation(locationPUT)

  const ref = useOutsideComponentClickHandler(() => {
    openNewLabel && toggleNewLabel(false)
    openLastItems && toggleLastItems(false)
  })

  const cutpoint = expanded
    ? EXPANDED_UNFOLDED_LABELS_COUNT
    : UNFOLDED_LABELS_COUNT

  const getNewLocationItems = () => {
    const items = [] as ILabel[]
    const lastItems = [] as ILabel[]
    for (let i = 0; i < locationData.labels.length; i++) {
      for (let j = 0; j < labels.length; j++) {
        if (labels[j].id === (locationData.labels[i] as string))
          if (i < cutpoint) items.push(labels[j])
          else lastItems.push(labels[j])
      }
    }
    return { items, lastItems }
  }

  const { items, lastItems } = useMemo(() => {
    if (
      locationData.labels.length &&
      typeof locationData.labels[0] !== "string"
    )
      return {
        items: locationData.labels.slice(0, cutpoint),
        lastItems: locationData.labels.slice(
          cutpoint,
          locationData.labels.length,
        ),
      }
    else if (isNewLocation) return getNewLocationItems()
    return { items: [], lastItems: [] }
  }, [locationData])

  const itemRows = useMemo(() => {
    if (!expanded) return [] // skip unnecessary work
    // Split the items array into rows
    const rows = [] as (ILabel[] | string[])[]
    for (let i = 0; i < items.length; i += LABELS_PER_ROW) {
      rows.push(items.slice(i, i + LABELS_PER_ROW))
    }
    return rows
  }, [items])

  function handleLabelDelete(label: ILabel) {
    // Hit endpoint to delete label from account
    deleteLabel(label.id, {
      onSuccess: async () => {
        // On success, refresh labels
        refreshLabels()
        queryClient.invalidateQueries(["locations"])
      },
      onError: async () => {
        return
      },
    })
  }

  const handleExistingLabel = (
    label: ILabel,
    location: ILocationEssentials,
    add = true,
  ) => {
    // Translate label object list into label id list for PUT (inside of the location object)
    const newLocObject = { ...location }

    const newLabelsList = [] as string[]

    for (let i = 0; i < newLocObject.labels.length; i++) {
      if (typeof newLocObject.labels[i] === "string")
        newLabelsList.push(newLocObject.labels[i] as string)
      else newLabelsList.push((newLocObject.labels[i] as ILabel).id as string)
    }

    // If we are adding a label to this location, then it must not exist already on the loc
    // If we are not adding (which means we are removing), then it MUST exist already on the loc
    if (newLabelsList.includes(label.id as string) === add) return

    // Add or remove label id to list accordingly
    if (add) newLabelsList.push(label.id as string)
    else newLabelsList.splice(newLabelsList.indexOf(label.id as string), 1)

    newLocObject.labels = newLabelsList
    const location_id = newLocObject.id

    // Check if we are adding labels to a new location (AddEditLocationsForm)
    if (isNewLocation) {
      addLabelToLocCallback(newLocObject)
      return
    }

    // We need to assign the passed in label to the passed in location!
    editLocation(
      { location_id: location_id, payload: newLocObject },
      {
        onSuccess: async () => {
          queryClient.invalidateQueries(["locations"])
        },
        onError: async () => {
          return
        },
      },
    )
  }

  function removeLabelFromLoc(label: ILabel) {
    handleExistingLabel(label, locationData, false)
  }

  const handleNewLabel = (label: ILabel) => {
    if (isAddingLabel === null) setIsAddingLabel(label)
  }

  useEffect(() => {
    if (!selectedAccount || !isAddingLabel) return

    // Hit endpoint to add label to account
    addLabel(
      { ...isAddingLabel, account_id: selectedAccount },
      {
        onSuccess: async () => {
          refreshLabels()
          setIsAddingLabel(null)
        },
        onError: async () => {
          setIsAddingLabel(null)
        },
      },
    )
  }, [isAddingLabel])

  const handleEditLabel = (label: ILabel) => {
    const label_id = label.id
    delete label.id
    delete label.created_at
    delete label.updated_at
    // Hit endpoint to edit label
    editLabel(
      { label, label_id },
      {
        onSuccess: async () => {
          refreshLabels()
          queryClient.invalidateQueries(["locations"])
        },
        onError: async () => {
          return
        },
      },
    )
  }

  function newLabelButton() {
    return (
      <div className="relative flex flex-row items-center">
        {modifiable && (
          <button
            className={
              "relative h-6 w-6 fill-gray-30" +
              " transition-all duration-100" +
              " hover:scale-110" +
              (isHovering ? " opacity-100" : " opacity-0")
            }
            onClick={() => {
              toggleNewLabel(!openNewLabel)
              openLastItems && toggleLastItems(false)
            }}>
            <PlusIcon />
          </button>
        )}

        {openNewLabel && (
          <FixedLayer
            xPosition="left"
            yPosition="bottom">
            <LabelsForm
              labels={labels}
              onDeleteLabel={handleLabelDelete}
              onSelectNewLabel={handleNewLabel}
              onSelectExistingLabel={(label) =>
                handleExistingLabel(label, locationData)
              }
              onEditLabel={handleEditLabel}
              colors={formattedColorsMatrixForColorPicker}
              searchLabel={t("search")}
              specialCharsLabel={t("someSpecialCharsAreNotAllowed")}
              notFoundLabel={t("noLabelsFound")}
              pressEnterToCreateOneLabel={t("pressEnterToCreateOne")}
            />
          </FixedLayer>
        )}
      </div>
    )
  }

  function moreItemsChip() {
    return (
      <div className="relative">
        <Chip
          value={"+" + lastItems.length}
          color={colors.gray[60]}
          onClick={() => {
            toggleLastItems(!openLastItems)
            openNewLabel && toggleNewLabel(false)
          }}
        />
        {openLastItems && (
          <FixedLayer
            yPosition="bottom"
            xPosition="right">
            <div className="flex flex-col p-2 bg-light-bg dark:bg-dark-bg border rounded-lg shadow-md elevation border-gray-14 dark:border-gray-78 gap-1">
              {lastItems.map((label) => {
                if (typeof label === "string") {
                  labels.forEach((l) => {
                    if (l.id === label) label = l
                  })
                }
                return (
                  <LabelItem
                    label={label as ILabel}
                    onDelete={modifiable ? removeLabelFromLoc : undefined}
                    key={`label_${(label as ILabel).id}`}
                  />
                )
              })}
            </div>
          </FixedLayer>
        )}
      </div>
    )
  }

  const hasMoreItems = lastItems.length > 0

  if (expanded) {
    return (
      <div
        className="grid grid-cols-1 gap-1 w-[320px] h-full overflow-hidden py-1"
        ref={ref}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}>
        {itemRows.map((row, index) => {
          return (
            <div
              key={uniqueId()}
              className="flex flex-row items-center justify-start gap-1">
              {row.map((label) => {
                if (typeof label === "string") {
                  labels.forEach((l) => {
                    if (l.id === label) label = l
                  })
                }
                return (
                  <LabelItem
                    label={label as ILabel}
                    onDelete={modifiable ? removeLabelFromLoc : undefined}
                    key={`label_${(label as ILabel).id}`}
                  />
                )
              })}

              {index === itemRows.length - 1 &&
                (hasMoreItems ? moreItemsChip() : newLabelButton())}
            </div>
          )
        })}

        {hasMoreItems && (
          <div className="flex flex-row items-center justify-start gap-1">
            {newLabelButton()}
          </div>
        )}
      </div>
    )
  } else {
    return (
      <div
        className="flex flex-row items-center justify-start h-full w-[160px] flex-wrap gap-1 py-1"
        ref={ref}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}>
        {items.map((label) => {
          if (typeof label === "string") {
            labels.forEach((l) => {
              if (l.id === label) label = l
            })
          }
          return (
            <LabelItem
              label={label as ILabel}
              onDelete={modifiable ? removeLabelFromLoc : undefined}
              key={`label_${(label as ILabel).id}`}
            />
          )
        })}

        {hasMoreItems && moreItemsChip()}
        {newLabelButton()}
      </div>
    )
  }
}

export default LabelPicker
