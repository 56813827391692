import _ from "lodash"
import { DateTime } from "luxon"
import PropTypes from "prop-types"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useRiskSettingData } from "../../../../hooks/useRiskSettingStageRiskProfileQuery"
import { useRiskSettingAssetData } from "../../../../hooks/useStageRiskProfileAssetDataQuery"
import { UnitConversionContext } from "../../../../providers/UnitConversionProvider"
import RiskProfileDetailCard from "../../../../views/Seasonal/Alerts/components/RiskProfileDetailCard"
import { getAlertCardDetailsInfo } from "../../../../views/Seasonal/Dashboards/components/widgetLibrary/SeasonalCalendarWidget"
import dashboardVariables from "../../../../views/Seasonal/Dashboards/dashboardVariables"
import useCSV from "../../../hooks/widgets/useCSV"
import { DownloadIcon, FileIcon } from "../../../icons"
import { downloadBlob } from "../../../utils"
import { addDays } from "../../../utils/chart"
import Button from "../../Button"
import { LineAreaChartComponent } from "../../Charts/LineAreaChart/LineAreaChart.component"
import LoadingAnimation from "../../LoadingAnimation"
import NoResultsState from "../../NoResultsState"
import BoxPlotHover from "./BoxPlotHover"
import ChartLegends from "./ChartLegends"
import DirectionalityHover from "./DirectionalityHover"
import {
    getMiddleDate,
    getXFutureTimestep,
    groupCandleItems,
    monthsArray,
    obtainGenericGraphData,
    obtainGraphData,
} from "./genericChartWidgetUtils"

const downloadCSVs = (csvsDict, fileNamePrefix) => {
    // For each dataset, e.g. forecast, climatology, export the CSVs
    Object.entries(csvsDict).forEach(([resolution, datasetsCSVs]) => {
        Object.entries(datasetsCSVs).forEach(([datasetName, csvStr]) => {
            let fileName = `${resolution}_${datasetName}`
            if (fileNamePrefix) fileName = `${fileNamePrefix}_${fileName}`
            const blob = `data:text/csv;charset=utf-8,${encodeURIComponent(
                csvStr
            )}`
            downloadBlob(blob, fileName)
        })
    })
}

function GenericChartWidget({
    // Widget props
    loading,
    error,
    empty,
    reload,
    // LineAreaChart props
    title = "Chart title",
    labelY = "Y axis",
    zeroBasis = false,
    units = "",
    items = [],
    // TODO: A ClimateUI component should not be aware of specific project
    // objects. We need a better way to consume the selectors results or move
    // this component out of the ClimateUI and into the project
    alerts = [],
    varieties = [],
    labels = [],
    location = undefined,
    groupCandles = true,
    centerPoints = false,
    centerTicks = false,
    granularity = "daily", // hourly | daily | weekly | monthly
    directionalChart = false,
    actions = {},
    errorMessage = "",
    reloadMessage = "",
    noResultsMessage = "",
    tryAnotherFilterMessage = "",
    customHover = null,
    customLabels = null,
    enableCSVDownload = true,
    processItems = true,
    lineAreaChartProps = {},
}) {
    const t = actions.translationFn ?? ((key) => key)
    const { convertUnits, getUnit } = useContext(UnitConversionContext)
    /* CSV */
    const [showCSVOptions, setShowCSVOptions] = useState(false)
    const csvCustomHeader = useRef("")
    const csvs = useCSV({
        datasets: items,
        datasetsRename: actions.datasetsRename,
        mergeDatasets: actions.mergeDatasets,
        setCustomHeader: (oldHeader) => {
            let newHeader = oldHeader

            // Labels
            newHeader = `${t("labels")}:,"${labels
                .map(({ name }) => name)
                .join(",")}"\n${newHeader}`

            /*
            // Assets
            newHeader = `${t("assets")}:,"${varieties
                .map(
                    (variety) => variety.asset.name + " (" + variety.name + ")"
                )
                .join(",")}"\n${newHeader}`
                */

            // Location
            if (location) {
                newHeader = `${t("location")}:,"${location.name}"\n${newHeader}`
            }
            // Keep track of the custom header for the onCSVParse function
            csvCustomHeader.current = newHeader
            return newHeader
        },
        translationFn: actions.translationFn,
    })
    useEffect(() => {
        if (!actions.onCSVParse || !csvs || !Object.keys(csvs).length) return
        return actions.onCSVParse(csvs, csvCustomHeader.current)
    }, [actions, csvs])

    const { data: riskSettingData } = useRiskSettingData(
        alerts.map((d) => d.processing_run.risk_setting_id)
    )
    const {
        data: riskSettingAssetData,
        isLoading: riskSettingAssetDataLoading,
    } = useRiskSettingAssetData(riskSettingData)

    // The purposes of this hook is to:
    // 1. adjust the timezone on the point
    // 2. get time boundaries from item granularity
    const { _items, xDateMin, xDateMax } = useMemo(() => {
        // look through the chart items to find the min and max on the X axis
        let xDateMin = Number.MAX_SAFE_INTEGER
        let xDateMax = Number.MIN_SAFE_INTEGER

        if (!items.length) {
            xDateMin = new Date()
            xDateMax = new Date()
        }
        let _items = items.map((item) => {
            // make change to points in every granularity except hourly
            if (granularity === "hourly") return item
            let lastGap = 0

            const points = item.points.map((point, index) => {
                let date = new Date(point.date)
                // 1. set hour to begining of the day in current timezone
                const offset = new Date().getTimezoneOffset() / 60
                date.setHours(date.getHours() + offset)

                // 2. set chart time boundaries for the x axis
                if (!centerPoints && date.getTime() < xDateMin)
                    // if center points is not enabled then begin chart
                    // with x offset to the left
                    xDateMin = new Date(date).getTime()
                else if (centerPoints && date.getTime() < xDateMin) {
                    // if center points is enabled, chart begins in the
                    // first x point - 2 days
                    let pastDate = new Date(date.getTime())
                    pastDate.setDate(pastDate.getDate() - 2)
                    xDateMin = pastDate.getTime()
                }

                // end x stays the same for both cases
                if (date.getTime() >= xDateMax)
                    xDateMax = new Date(date).getTime()

                // 2. if center points prop is enabled, change the date of
                // all points to an intemediate step
                if (centerPoints) {
                    let nextDate =
                        index < item.points.length - 1
                            ? new Date(item.points[index + 1]?.date)
                            : new Date(date.getTime())

                    nextDate =
                        index === item.points.length - 1
                            ? new Date(nextDate.getTime() + lastGap)
                            : nextDate
                    nextDate.setHours(nextDate.getHours() + offset)

                    lastGap = nextDate.getTime() - date.getTime()

                    date = getMiddleDate(date, nextDate)
                }

                // result point
                return {
                    ...point,
                    date,
                }
            })

            // result item
            return {
                ...item,
                points,
            }
        })

        return {
            _items,
            xDateMin: new Date(xDateMin),
            xDateMax: getXFutureTimestep(new Date(xDateMax), granularity),
        }
    }, [items, granularity, centerPoints])

    // generate chart data
    const { chartData, chartLabels } = useMemo(() => {
        // no items
        if (!_items.length) return { chartData: [], chartLabels: [] }

        let chartLabels = []
        let chartData = []

        chartData = _items.map((item) => {
            if (item.variable)
                chartLabels.push({
                    name: item.variable,
                    vis: item.visualization,
                    color: item.color,
                    opacity: item.opacity,
                    type: item.dataset,
                })

            if (processItems)
                return obtainGraphData(item, items, granularity, {
                    color: item.color,
                    opacity: item.opacity,
                })
            else
                return obtainGenericGraphData(item, {
                    color: item.color,
                    opacity: item.opacity,
                })
        })

        if (groupCandles) {
            //
            const candleItems = []

            chartData = chartData.filter((item) => {
                let isCandle = item.type === "candlestick"
                if (isCandle) candleItems.push(item)

                return !isCandle
            })

            chartData = [...chartData, groupCandleItems(candleItems)]
        }

        return { chartData, chartLabels }

        // line / area / regular candles
    }, [_items, granularity, groupCandles, directionalChart])

    const renderChart = () => {
        if (loading || riskSettingAssetDataLoading)
            return (
                <div className="grow-1 flex justify-center items-center mt-36 body-lg">
                    <LoadingAnimation />
                </div>
            )
        if (empty)
            return (
                <div className="mt-36">
                    <NoResultsState
                        tryAnotherFilterMessage={tryAnotherFilterMessage}
                        noResultsMessage={noResultsMessage}
                    />
                </div>
            )
        if (error)
            return (
                <div className="mt-36">
                    <p className="flex justify-center items-center text-[16px] mt-3">
                        {errorMessage}
                    </p>
                    <div className="flex items-center justify-center">
                        <button
                            onClick={reload}
                            className="text-[12px] underline text-accent">
                            {reloadMessage}
                        </button>
                    </div>
                </div>
            )
        return (
            <>
                <LineAreaChartComponent
                    // Default or hardcoded props =============================
                    yBottomOffset={0.3}
                    yTopOffset={0.3}
                    zeroBasis={zeroBasis}
                    xAxisOffsetY={5}
                    svgHeight={600}
                    // Calculated props =======================================
                    title={title}
                    labelY={labelY}
                    varieties={varieties}
                    data={chartData}
                    units={units}
                    xDateMin={xDateMin}
                    xDateMax={xDateMax}
                    xTicksCount={chartData[0]?.points?.length}
                    centerTicks={
                        ["weekly", "monthly"].includes(granularity) ||
                        centerTicks
                    }
                    shapeTip={(e, d, arr) => {
                        const date = d.x ? new Date(d.x) : new Date()
                        const startWeek = new Date(date)
                        startWeek.setDate(date.getDate() - 3)
                        const endWeek = new Date(date)
                        endWeek.setDate(date.getDate() + 3)

                        if (customHover) {
                            return `${ReactDOMServer.renderToStaticMarkup(
                                customHover(d)
                            )}`
                        } else if (!directionalChart) {
                            return `
                                ${ReactDOMServer.renderToStaticMarkup(
                                    <BoxPlotHover
                                        t={t}
                                        d={d}
                                        items={items}
                                        granularity={granularity}
                                        importantDates={{
                                            date: date,
                                            startWeek: startWeek,
                                            endWeek: endWeek,
                                        }}
                                    />
                                )}`
                        } else {
                            return `
                                ${ReactDOMServer.renderToStaticMarkup(
                                    <DirectionalityHover
                                        directionality={d.direction}
                                        personalizedColor={d.line.color}
                                        title={
                                            granularity === "monthly"
                                                ? monthsArray[date.getMonth()] +
                                                  " " +
                                                  date.getFullYear()
                                                : startWeek.toLocaleDateString(
                                                      "en-us",
                                                      {
                                                          month: "short",
                                                          day: "numeric",
                                                      }
                                                  ) +
                                                  " to " +
                                                  endWeek.toLocaleDateString(
                                                      "en-us",
                                                      {
                                                          month: "short",
                                                          day: "numeric",
                                                      }
                                                  )
                                        }
                                        description={
                                            dashboardVariables[
                                                d.item_data.variable
                                            ].title + " Forecast Probability"
                                        }
                                        probabilities={{
                                            "prob_0-0.33":
                                                d["attributes"][
                                                    "clim_prob_0.00-0.33"
                                                ],
                                            "prob_0.33-0.67":
                                                d["attributes"][
                                                    "clim_prob_0.33-0.67"
                                                ],
                                            "prob_0.67-1":
                                                d["attributes"][
                                                    "clim_prob_0.67-1.00"
                                                ],
                                        }}
                                        quantiles={{
                                            "quantile_0.33":
                                                d["attributes"][
                                                    "quantile_0.33"
                                                ],
                                            "quantile_0.67":
                                                d["attributes"][
                                                    "quantile_0.67"
                                                ],
                                        }}
                                        granularity={granularity}
                                        units={items[0].units}
                                    />
                                )}
                                `
                        }
                    }}
                    shadedRanges={alerts.map((a) => {
                        const startDate = DateTime.fromISO(a.start_date)
                        const endDate = DateTime.fromISO(a.end_date)
                        const hoverInfo = getAlertCardDetailsInfo(
                            t,
                            a,
                            a.processing_run.risk_profile,
                            getUnit,
                            convertUnits
                        )

                        if (a.processing_run.risk_profile) {
                            return {
                                alertInfo: a,
                                alertHoverInfo: {
                                    alert: a,
                                    riskProfile: a.processing_run.risk_profile,
                                    location: a.processing_run.location,
                                    alertDetailInfo: hoverInfo,
                                    riskSettingId:
                                        a.processing_run.risk_setting_id,
                                },
                                start: startDate.toMillis(),
                                end: endDate.toMillis(),
                                icon: a.processing_run.risk_profile
                                    .hazard_profiles.results[0].hazard_variable
                                    .dashboard_variable,
                                title: a.processing_run.risk_profile.name,
                                duration: a.duration + " " + t("days"),
                                probability: a.avg_risk_value,
                                color: dashboardVariables[
                                    a.processing_run.risk_profile
                                        .hazard_profiles.results[0]
                                        .hazard_variable.dashboard_variable
                                ].chartConfig.color,
                                risk: {
                                    name: a.processing_run.risk_profile
                                        .hazard_profiles.results[0]
                                        .hazard_variable.dashboard_variable,
                                    threshold:
                                        a.processing_run.risk_profile
                                            .hazard_profiles.results[0]
                                            .threshold,
                                    conditional:
                                        a.processing_run.risk_profile
                                            .hazard_profiles.results[0]
                                            .conditional,
                                },
                            }
                        } else return {}
                    })}
                    shadedRangeTip={(e, d) => {
                        return `${ReactDOMServer.renderToStaticMarkup(
                            <div className="w-[340px]">
                                <RiskProfileDetailCard
                                    convertUnits={convertUnits}
                                    getUnit={getUnit}
                                    t={t}
                                    riskProfileInfo={d.alertHoverInfo}
                                    riskProfileSettingsAssetData={
                                        riskSettingAssetData
                                    }
                                    riskSettingAssetDataLoading={
                                        riskSettingAssetDataLoading
                                    }
                                    alertCategory={d.alertHoverInfo.alert.category.toLowerCase()}
                                />
                            </div>
                        )}`
                    }}
                    xTickFormat={
                        {
                            daily: (d, i, arr) => {
                                if (i < arr.length - 1 && i !== 0)
                                    return d.getDate()
                                if (i === 0)
                                    return (
                                        d.toLocaleString(undefined, {
                                            month: "short",
                                        }) +
                                        " " +
                                        d.getDate()
                                    )
                                return (
                                    d.getDate() +
                                    " " +
                                    d.toLocaleString(undefined, {
                                        month: "short",
                                    })
                                )
                            },
                            weekly: (d, i, arr) => {
                                // Remove last, overflowing tick item
                                if (i === arr.length - 1) return ""
                                const nextDate = addDays(d, 6)
                                const dStr = d.toLocaleString(undefined, {
                                    month: "short",
                                    day: "numeric",
                                })
                                const nextDateStr = nextDate.toLocaleString(
                                    undefined,
                                    {
                                        month: "short",
                                        day: "numeric",
                                    }
                                )
                                return dStr + " - " + nextDateStr
                                // return dStr
                            },
                            monthly: (d, i, arr) => {
                                // Remove last, overflowing tick item
                                if (i === arr.length - 1) return ""
                                return d.toLocaleString(undefined, {
                                    month: "long",
                                })
                            },
                        }[granularity]
                    }
                    {...lineAreaChartProps}
                />
                <div className="flex gap-2 mb-2 -mt-2 justify-center text-[12px]">
                    {(customLabels ?? chartLabels).map((label) => {
                        return (
                            <span key={_.uniqueId("chartLabel")}>
                                <ChartLegends
                                    label={label}
                                    t={t}
                                />
                            </span>
                        )
                    })}
                </div>
            </>
        )
    }

    return (
        <div className="w-full h-full overflow-y-hidden overflow-x-auto border border-1 border-gray-14 dark:border-gray-78 rounded-lg relative bg-light-bg dark:bg-dark-bg">
            <div className="w-full h-[48px] bg-light-bg dark:bg-dark-bg py-[28px] pl-[14px] flex justify-between items-center sticky top-0 left-0 z-10">
                {loading ? (
                    <h3 className="label-lg text-gray-30">{title}</h3>
                ) : (
                    <h3 className="label-lg"> {title}</h3>
                )}
                {/* Hiding CSV download button for directionalCharts for the time being */}
                {enableCSVDownload &&
                    !directionalChart &&
                    !loading &&
                    !empty &&
                    !error && (
                        <div className="flex flex-col flex-wrap items-end">
                            <button
                                onClick={() =>
                                    setShowCSVOptions(!showCSVOptions)
                                }
                                className={[
                                    "px-3 h-[42px] leading-4",
                                    "flex flex-row justify-center items-center space-x-2",
                                    "whitespace-nowrap group",
                                ].join(" ")}>
                                <span className="w-6 h-6 fill-gray-60">
                                    <DownloadIcon />
                                </span>
                            </button>
                            {showCSVOptions && (
                                <ul className="absolute z-10 font-medium text-white top-[42px] rounded-md font-roboto shadow-md">
                                    <li>
                                        <Button
                                            type="tertiary"
                                            extraClasses="bg-light-bg dark:bg-dark-bg text-gray-60 dark:text-gray-30 fill-gray-60"
                                            label={t("downloadChartAsCSV")}
                                            icon={<FileIcon />}
                                            onClick={() => {
                                                setShowCSVOptions(false)
                                                downloadCSVs(
                                                    csvs,
                                                    location?.name
                                                )
                                            }}
                                        />
                                    </li>
                                </ul>
                            )}
                        </div>
                    )}
            </div>
            <div className="min-h-[300px]">{renderChart()}</div>
        </div>
    )
}

GenericChartWidget.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.bool,
    empty: PropTypes.bool,
    reload: PropTypes.func,
    title: PropTypes.string,
    labelY: PropTypes.string,
    zeroBasis: PropTypes.bool,
    units: PropTypes.string,
    items: PropTypes.array,
    alerts: PropTypes.array,
    varieties: PropTypes.array,
    labels: PropTypes.array,
    location: PropTypes.object,
    groupCandles: PropTypes.bool,
    centerPoints: PropTypes.bool,
    centerTicks: PropTypes.bool,
    granularity: PropTypes.oneOf(["hourly", "daily", "weekly", "monthly"]),
    directionalChart: PropTypes.bool,
    actions: PropTypes.object,
    errorMessage: PropTypes.string,
    reloadMessage: PropTypes.string,
    noResultsMessage: PropTypes.string,
    tryAnotherFilterMessage: PropTypes.string,
    customHover: PropTypes.func,
    customLabels: PropTypes.func,
    enableCSVDownload: PropTypes.bool,
    processItems: PropTypes.bool,
    lineAreaChartProps: PropTypes.object,
}
export default GenericChartWidget
