import { useTranslate } from "@tolgee/react"
import { FocusEvent, useState } from "react"
import { EditIcon } from "../../../../climateui/icons"
import { useDashboard } from "../../../../providers/DashboardProvider"

export const COMMON_INPUT_CLASSES =
  "px-1 border border-transparent focus:outline-none focus:border-accent cursor-pointer focus:cursor-text rounded-md hover:border-gray-14 dark:border-gray-78 dark:bg-dark-bg placeholder:text-gray-30"

function DashboardNameAndDescription() {
  const { workingDashboard, setWorkingDashboard } = useDashboard()
  const [isNameTyping, setIsNameTyping] = useState(false)
  const [isDescTyping, setIsDescTyping] = useState(false)

  const { t } = useTranslate()

  return (
    <div className="flex flex-col gap-1">
      <div className="flex group mr-4">
        <input
          className={
            "title-lg text-light-text dark:text-dark-text pr-10 min-w-[150px] " +
            COMMON_INPUT_CLASSES
          }
          defaultValue={workingDashboard?.title}
          placeholder={t("clickAddName")}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            setWorkingDashboard({
              ...workingDashboard,
              title: e.target.value,
            })
            setIsNameTyping(false)
          }}
          onInput={(e) => {
            e.currentTarget.style.width = e.currentTarget.value.length + "ch"
          }}
          onClick={() => setIsNameTyping(true)}
          style={{
            width: workingDashboard?.title?.length
              ? workingDashboard?.title?.length + 2 + "ch"
              : "24ch",
          }}
        />
        {isNameTyping ? (
          <></>
        ) : (
          <span
            className={[
              workingDashboard?.title
                ? "fill-gray-100 hidden group-hover:block"
                : "fill-gray-30",
              "w-[20px] z-50 -ml-6 shrink-0",
            ].join(" ")}>
            <EditIcon />
          </span>
        )}
      </div>
      <div className="flex group mr-4">
        <input
          className={
            "label-sm text-gray-60 dark:text-gray-30 pr-6 min-w-[150px] " +
            COMMON_INPUT_CLASSES
          }
          defaultValue={workingDashboard?.description}
          placeholder={t("clickToAddDescription")}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            setWorkingDashboard({
              ...workingDashboard,
              description: e.target.value,
            })
            setIsDescTyping(false)
          }}
          onInput={(e) => {
            e.currentTarget.style.width = e.currentTarget.value.length + "ch"
          }}
          onClick={() => setIsDescTyping(true)}
          style={{
            width: workingDashboard?.description?.length
              ? workingDashboard?.description?.length + 2 + "ch"
              : "32ch",
          }}
        />
        {isDescTyping ? (
          <></>
        ) : (
          <span
            className={[
              workingDashboard?.description
                ? "fill-gray-100 hidden group-hover:block"
                : "fill-gray-30",
              "w-[15px] z-50 -ml-6 shrink-0",
            ].join(" ")}>
            <EditIcon />
          </span>
        )}
      </div>
    </div>
  )
}

export default DashboardNameAndDescription
