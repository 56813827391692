import { useMemo } from "react"
import { useLocations } from "../../../../providers"
import { useRiskProfiles } from "../../../../providers/RiskProfilesProvider"
import { useAlertsMarkerPopup } from "../../../../views/Seasonal/Alerts/components/mapUtils"
import Map from "../../Map/Map"

function AlertMapWidget(props) {
    const {
        coords = [],
        pinValues = [],
        animate = true,
        hasControls = true,
        hasGeocoder = true,
        navigate,
        pinImages,
        pinURL,
    } = props

    const { locationsObj } = useLocations()
    const { riskProfilesObj } = useRiskProfiles()
    const { getAlertsMarkerPopup } = useAlertsMarkerPopup()

    const pins = useMemo(() => {
        if (!coords) return
        const _coords = coords.map((coord, i) => {
            const _location = locationsObj[coord.id]
            const _alertCategory =
                coord.alerts?.results[0]?.category.toLowerCase() || ""
            const _riskProfiles = {}
            coord.alerts?.results?.forEach((alert) => {
                _riskProfiles[alert.processing_run.risk_id] = {
                    ...riskProfilesObj[alert.processing_run.risk_id],
                }
            })

            const _alerts = coord.alerts?.results.map((alert) => {
                return {
                    time_resolution: alert.time_resolution,
                    start_date: alert.start_date,
                    end_date: alert.end_date,
                    risk_profile_id: alert.processing_run.risk_id,
                    id: alert.id,
                }
            })

            if (!_location) return
            return {
                id: _location.id,
                lat: coord.latitude,
                lon: coord.longitude,
                data: {
                    location: _location,
                    riskProfiles: _riskProfiles,
                    alerts: _alerts,
                    category: _alertCategory,
                },
                pinStyle:
                    _alertCategory === "warning"
                        ? pinImages?.warningState
                        : pinImages?.watchState,
                events: (marker) => ({
                    click: () => {
                        navigate(pinURL + _location.id)
                    },
                    mouseenter: () => {
                        if (Object.keys(_riskProfiles).length !== 0) {
                            const markerElement = marker._element
                            const markerLabelElement = markerElement.firstChild
                            markerLabelElement.style.color =
                                _alertCategory === "watch"
                                    ? "#CC9300"
                                    : "#BF1728"
                            if (_alertCategory === "watch") {
                                markerElement.style.backgroundImage = `url(/images/${pinImages?.selectedWatchState})`
                            } else {
                                markerElement.style.backgroundImage = `url(/images/${pinImages?.selectedWarningState})`
                            }
                        }
                        marker.togglePopup()
                    },
                    mouseleave: () => {
                        if (Object.keys(_riskProfiles).length !== 0) {
                            const markerElement = marker._element
                            const markerLabelElement = markerElement.firstChild
                            markerLabelElement.style.color =
                                _alertCategory === "watch" ? "black" : "white"
                            if (_alertCategory === "watch") {
                                markerElement.style.backgroundImage = `url(/images/${pinImages?.watchState})`
                            } else {
                                markerElement.style.backgroundImage = `url(/images/${pinImages?.warningState})`
                            }
                        }
                        marker.togglePopup()
                    },
                }),
                getCount: (pin) => Object.keys(pin.data.alerts)?.length,
                popup: (pin) => getAlertsMarkerPopup(pin),
            }
        })
        return _coords.filter((coord) => !!coord)
    }, [coords, pinValues, riskProfilesObj])

    return (
        <div className="relative w-full h-full overflow-hidden bg-light-bg dark:bg-dark-bg border border-1 border-gray-14 dark:border-gray-78 rounded-lg">
            <Map
                pins={pins}
                fitToPinsCount={true}
                animate={animate}
                hasControls={hasControls}
                hasGeocoder={hasGeocoder}
            />
        </div>
    )
}

export default AlertMapWidget
