import { ReactNode, useEffect, useRef, useState } from "react"
import useOutsideComponentClickHandler from "../../hooks/useOutsideComponentClickHandler"
import { ArrowBottom } from "../../icons"
import DebounceSearchInput from "../DebounceSearchInput"
import { getDropdownSorter } from "../utils"
import {
    alwaysBorderRedClasses,
    defaultInputClasses,
    MIN_NUMBER_OF_OPTIONS_TO_ALLOW_SEARCH,
} from "./utils"

const SingleSelect = ({
    icon = null,
    selected = "",
    options = {},
    setSelected = () => null,
    textAlignClass = "text-left",
    placeholder = "Select",
    leftRightClass = "right-0",
    disabled = false,
    canSearch = false,
    searchPlaceholder = "Search",
    error = "",
    dropUp = false,
    sortByKey = true,
    keepOrder = false,
}: {
    icon?: ReactNode
    selected?: string | number
    options?: { [key: string | number]: string }
    setSelected?: (key: string | number) => void
    textAlignClass?: string
    placeholder?: string
    leftRightClass?: string
    disabled?: boolean
    canSearch?: boolean
    searchPlaceholder?: string
    error?: string
    dropUp?: boolean
    sortByKey?: boolean
    keepOrder?: boolean
}) => {
    const [open, toggle] = useState(false)
    const [searchText, setSearchText] = useState("")

    const dropdownRef = useOutsideComponentClickHandler(() => toggle(false))
    const inputRef = useRef<HTMLInputElement | null>(null)
    const textRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        setSearchText("")
        if (open && inputRef.current) inputRef?.current?.focus()
    }, [open])

    const dropdownSort = getDropdownSorter({
        options,
        keepOrder,
        sortByKey,
    })
    const optionsKeysList = Object.keys(options)
        .filter((key) => {
            const value = options[key]
            if (!value) return false
            return value
                .toLocaleLowerCase()
                .includes(searchText.toLocaleLowerCase())
        })
        .sort(dropdownSort)

    const select = (key: string | number, keepOpen = false) => {
        setSelected(key)
        if (keepOpen) inputRef?.current?.focus()
        else toggle(false)
    }

    const optHeight = 32
    const inputHeight = 42 // Based on `defaultInputClasses` in utils
    const inputBorder = 1 // Based on `defaultInputClasses` in utils

    const canActuallySearch =
        canSearch &&
        Object.keys(options).length > MIN_NUMBER_OF_OPTIONS_TO_ALLOW_SEARCH

    const borderClasses = open
        ? "border-accent z-[1]"
        : "border-gray-14 dark:border-gray-78 hover:enabled:border-gray-30"

    return (
        <div
            ref={dropdownRef}
            className="relative flex flex-col w-full min-w-0 gap-1">
            <button
                disabled={disabled}
                className={[
                    "flex flex-row items-center",
                    ...defaultInputClasses,
                    textAlignClass,
                    error !== "" ? alwaysBorderRedClasses : borderClasses,
                ].join(" ")}
                onClick={() => toggle(!open)}>
                {icon !== null && (
                    <span className="shrink-0 grow-0 w-[20px] mr-1 -ml-1 fill-gray-60">
                        {icon}
                    </span>
                )}
                <div
                    className={
                        "truncate pointer-events-none grow whitespace-nowrap " +
                        (selected === ""
                            ? "text-gray-60 dark:text-gray-30"
                            : "text-light-text dark:text-dark-text")
                    }
                    ref={textRef}>
                    {selected !== "" ? options[selected] : placeholder}
                </div>
                <span className="shrink-0 grow-0 w-[20px]">
                    <ArrowBottom />
                </span>
            </button>
            {error !== "" && (
                <div className="font-normal text-red body-sm">{error}</div>
            )}
            {open && (
                <div
                    className={[
                        "absolute",
                        error !== "" ? `top-[42px]` : "top-full",
                        "font-normal body-lg text-light-text dark:text-dark-text",
                        leftRightClass,
                        "bg-light-bg dark:bg-dark-bg z-30 rounded-lg elevation-2",
                        "h-fit pb-2 max-h-[400px] overflow-y-auto",
                        "border-[1px] border-gray-14 dark:border-gray-78",
                        !canActuallySearch ? "pt-2" : "",
                    ].join(" ")}
                    style={
                        dropUp
                            ? {
                                  top: `-${
                                      Object.keys(options).length * optHeight +
                                      Math.floor(inputHeight / 2) -
                                      inputBorder * 2
                                  }px`,
                              }
                            : {}
                    }>
                    {canActuallySearch && (
                        <div className="p-2 pb-1 min-w-[160px]">
                            <DebounceSearchInput
                                onSearch={setSearchText}
                                onSubmit={() => {
                                    if (
                                        optionsKeysList.length > 0 &&
                                        searchText !== ""
                                    ) {
                                        setSearchText("")
                                        if (inputRef && inputRef.current)
                                            inputRef.current.value = ""
                                        select(optionsKeysList[0], true)
                                    }
                                }}
                                ms={100}
                                autoFocus={false}
                                placeholder={searchPlaceholder}
                                onFocusOrTypeOrClick={() => null}
                                inputRef={inputRef}
                            />
                        </div>
                    )}
                    {optionsKeysList.length > 0 ? (
                        optionsKeysList.map((key, index) => (
                            <div
                                key={key + "-" + index}
                                className={[
                                    "flex flex-row items-center gap-2",
                                    `h-[${optHeight}px] px-2 py-1 min-w-[40px] w-full`,
                                    "hover:bg-gray-3 dark:bg-gray-90 dark:hover:bg-gray-88 cursor-pointer",
                                    textAlignClass,
                                    selected == key ? "bg-gray-5" : "",
                                    searchText !== "" && index === 0
                                        ? "bg-gray-3 dark:bg-gray-88"
                                        : "",
                                ].join(" ")}
                                onClick={() => select(key)}>
                                <p className="w-full truncate pointer-events-none whitespace-nowrap body-md">
                                    {options[key]}
                                </p>
                            </div>
                        ))
                    ) : (
                        <button
                            disabled
                            className={[
                                "flex flex-row items-center",
                                "h-[32px] px-2 my-1 min-w-[40px] w-full",
                                "cursor-not-allowed",
                                textAlignClass,
                            ].join(" ")}>
                            <p className="w-full truncate pointer-events-none whitespace-nowrap body-md text-gray-30">
                                No available options
                            </p>
                        </button>
                    )}
                </div>
            )}
        </div>
    )
}

export default SingleSelect
