import { useTranslate } from "@tolgee/react"
import { TooltipV2 } from "../climateui/components"
import { LocationPinIcon } from "../climateui/icons"
import { useAccount } from "../providers/AccountProvider"

function LocationsCount({ count }: { count: number }) {
  const { t } = useTranslate()
  const { maxLocationsAllowed } = useAccount()

  const over80 = (count / maxLocationsAllowed) * 100 >= 80
  const limitReached = count >= maxLocationsAllowed

  let colorClasses =
    "bg-gray-5 dark:bg-gray-78 fill-gray-90 text-light-text dark:text-dark-text"
  let tooltip = t(
    "accountLocationsLimitDescription",
    "Your account contract includes a limited number of locations",
  )
  if (over80) {
    colorClasses = "bg-yellow-light fill-yellow-dark text-yellow-dark"
    tooltip = t(
      "almostOverflowingLocationsContractedAmount",
      "You almost reach your contracted amount for locations, please contact your Account Manager to add more",
    )
  }
  if (limitReached) {
    colorClasses = "bg-red-light fill-red text-red"
    tooltip = t(
      "reachedLocationsContractedAmount",
      "You have reached your account locations contracted amount, please contact your Account Manager to add more",
    )
  }

  return (
    <TooltipV2
      position="bottom"
      align="right"
      contentClass="w-56"
      content={tooltip}>
      <div
        className={[
          "rounded-sm h-[32px]",
          "flex flex-row items-center gap-1 px-2",
          colorClasses,
        ].join(" ")}>
        <span className="h-5 w-5">
          <LocationPinIcon />
        </span>
        <p className="label-lg font-medium">
          {count}/{maxLocationsAllowed} {t("locations", "Locations")}
        </p>
      </div>
    </TooltipV2>
  )
}

export default LocationsCount
