import { useTranslate } from "@tolgee/react"
import { FC } from "react"
import { Button } from "../../../climateui/components"

export interface IErrorConsoleProps {
  title: string
  description: string
  error: Error
}

const ErrorConsole: FC<IErrorConsoleProps> = ({
  title,
  description,
  error,
}) => {
  const { t } = useTranslate()

  const copyErrorHandler = async () => {
    try {
      await navigator.clipboard.writeText(
        `${description} \n ${error.name} \n ${error.message} \n ${error.stack}`,
      )
    } catch (err) {
      // Hit Datadog api?
      console.log(err)
    }
  }

  const copyErrorButton = t("copyErrorButton", "Copy Error Log")

  return (
    <div className={`w-full`}>
      {/* Header */}
      <div className="flex flex-row items-start justify-end pb-3">
        <div className="flex-1">
          <h3 className="title-sm ">{title}</h3>
          <pre>
            <p className="body-sm text-gray-60 dark:text-gray-30">
              {description}
            </p>
          </pre>
        </div>
        <Button
          label={copyErrorButton}
          onClick={copyErrorHandler}
        />
      </div>
      {/* Content */}
      <div className=" bg-gray-90 rounded-xl text-gray-3 overflow-x-auto">
        <pre className="inline-block my-6 mx-8">
          <code>
            <span className="text-red-medium">{error.name}</span>
            <span> - </span>
            <span>{error.message}</span>
            <br />
            <br />
            <span className="text-gray-30">{error.stack}</span>
          </code>
        </pre>
      </div>
    </div>
  )
}

export default ErrorConsole
