import { LegacyRef, MouseEventHandler, ReactNode } from "react"

const primaryClasses =
    "bg-accent border-transparent text-white fill-white hover:bg-accent-dark disabled:bg-gray-5 dark:disabled:bg-gray-60 disabled:text-gray-60 dark:disabled:text-gray-30 disabled:fill-gray-30"
const secondaryClasses =
    "bg-transparent border-accent-medium fill-accent text-accent hover:border-accent hover:text-accent-dark hover:fill-accent-dark disabled:border-gray-14 dark:border-gray-78 disabled:text-gray-30 disabled:fill-gray-30"
const tertiaryClasses =
    "bg-transparent border-transparent fill-accent text-accent hover:text-accent-dark hover:fill-accent-dark disabled:text-gray-30 disabled:fill-gray-30"

const Button = ({
    icon = null,
    type = "primary",
    extend = false,
    disabled = false,
    label = "",
    customClasses,
    extraClasses = "",
    onClick = () => void 0,
    forwardedRef = null,
    submit = false,
    labelStyle = "",
}: {
    icon?: ReactNode | null
    type?: string
    extend?: boolean
    disabled?: boolean
    label: string | ReactNode
    customClasses?: string
    extraClasses?: string // TODO: Discuss deprecation in favor of `customClasses`
    onClick?: MouseEventHandler<HTMLButtonElement>
    forwardedRef?: LegacyRef<HTMLButtonElement> | null
    submit?: boolean
    labelStyle?: string
}) => {
    return (
        <button
            ref={forwardedRef}
            type={submit ? "submit" : "button"}
            disabled={disabled}
            className={[
                "box-border border rounded-md px-3 leading-4",
                "flex flex-row justify-center items-center space-x-2",
                "whitespace-nowrap",
                type.includes("small") ? "h-[32px]" : "h-[42px]",
                customClasses
                    ? customClasses
                    : type.includes("primary")
                    ? primaryClasses
                    : type.includes("secondary")
                    ? secondaryClasses
                    : tertiaryClasses,
                extend ? "w-full" : "",
                disabled ? "cursor-not-allowed" : "cursor-pointer",
                extraClasses,
            ].join(" ")}
            onClick={onClick}>
            {icon !== null && (
                <span
                    className={[
                        label !== ""
                            ? "w-[20px] h-[20px]"
                            : "w-[21px] h-[21px] -mx-1",
                    ].join(" ")}>
                    {icon}
                </span>
            )}
            {label !== "" && (
                <span className={labelStyle ? labelStyle : "label-lg"}>
                    {label}
                </span>
            )}
        </button>
    )
}

export default Button
