const WatchIcon = (props) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit">
            <path
                d="M12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2H13V10.275C13.3 10.4583 13.5417 10.6958 13.725 10.9875C13.9083 11.2792 14 11.6167 14 12C14 12.55 13.8042 13.0208 13.4125 13.4125C13.0208 13.8042 12.55 14 12 14C11.45 14 10.9792 13.8042 10.5875 13.4125C10.1958 13.0208 10 12.55 10 12C10 11.6167 10.0917 11.275 10.275 10.975C10.4583 10.675 10.7 10.4417 11 10.275V8.125C10.1333 8.35833 9.41667 8.82917 8.85 9.5375C8.28333 10.2458 8 11.0667 8 12C8 13.1 8.39167 14.0417 9.175 14.825C9.95833 15.6083 10.9 16 12 16C13.1 16 14.0417 15.6083 14.825 14.825C15.6083 14.0417 16 13.1 16 12C16 11.4 15.8792 10.8458 15.6375 10.3375C15.3958 9.82917 15.0667 9.38333 14.65 9L16.075 7.575C16.6583 8.125 17.125 8.77917 17.475 9.5375C17.825 10.2958 18 11.1167 18 12C18 13.6667 17.4167 15.0833 16.25 16.25C15.0833 17.4167 13.6667 18 12 18C10.3333 18 8.91667 17.4167 7.75 16.25C6.58333 15.0833 6 13.6667 6 12C6 10.5 6.475 9.19583 7.425 8.0875C8.375 6.97917 9.56667 6.30833 11 6.075V4.05C9.01667 4.3 7.35417 5.175 6.0125 6.675C4.67083 8.175 4 9.95 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 10.85 19.775 9.775 19.325 8.775C18.875 7.775 18.2583 6.90833 17.475 6.175L18.9 4.75C19.85 5.66667 20.6042 6.74583 21.1625 7.9875C21.7208 9.22917 22 10.5667 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z"
                fill={props.fill}
            />
        </svg>
    )
}

export default WatchIcon
