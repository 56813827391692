import { Area, Line } from "recharts"
import {
    TypeChartPlotUnion,
    isAreaPlotComponent,
    isDirectionalityPlotComponent,
    isLinePlotComponent,
} from "./helpers"

export const renderPlotComponent = <TMetaData,>(
    plotComponent: TypeChartPlotUnion<TMetaData>
) => {
    if (isLinePlotComponent(plotComponent)) {
        return (
            <Line
                key={plotComponent.yLabelId}
                {...plotComponent.config}
                dataKey={plotComponent.yLabelId}
                ref={null}
            />
        )
    } else if (isAreaPlotComponent(plotComponent)) {
        return (
            <Area
                key={plotComponent.yLabelId}
                {...plotComponent.config}
                dataKey={plotComponent.yLabelId}
                ref={null}
            />
        )
    } else if (isDirectionalityPlotComponent(plotComponent)) {
        return (
            <Line
                key={plotComponent.yLabelId}
                {...plotComponent.config}
                dataKey={plotComponent.yLabelId}
                ref={null}
            />
        )
    }
    return <></>
}

export const onRenderPlotComponents = <TMetaData,>(
    plotComponents: TypeChartPlotUnion<TMetaData>[]
) => {
    return plotComponents.map((plotComponent) => {
        return renderPlotComponent<TMetaData>(plotComponent)
    })
}
