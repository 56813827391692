import { IRoute } from "../../../climateui/types"
import PlanAssets from "./components/PlanAssets"
import PlanLocations from "./components/PlanLocations"
import PlanStagesAndRisks from "./components/PlanStagesAndRisks"
import AddEditPlan from "./AddEditPlan"
import PlansList from "./PlansList"
import PlanView from "./PlanView"
import PlanProvider from "./PlanProvider"

export const planAdditionAndEditionRoutes: IRoute[] = [
  {
    path: "locations",
    element: <PlanLocations />,
    labelKey: "selectLocations",
  },
  {
    path: "assets",
    element: <PlanAssets />,
    labelKey: "assignAssets",
  },
  {
    path: "stages-risks",
    element: <PlanStagesAndRisks />,
    labelKey: "setStagesAndRisks",
  },
]

const planningToolChildRoutes: IRoute[] = [
  {
    path: "",
    element: <PlansList />,
  },
  {
    path: "new",
    element: (
      <PlanProvider>
        <AddEditPlan />
      </PlanProvider>
    ),
    children: planAdditionAndEditionRoutes,
  },
  {
    path: ":planId",
    element: (
      <PlanProvider isEditingPlan={false}>
        <PlanView />
      </PlanProvider>
    ),
  },
  {
    path: ":planId/edit",
    element: (
      <PlanProvider>
        <AddEditPlan />
      </PlanProvider>
    ),
    children: planAdditionAndEditionRoutes,
  },
]

export default planningToolChildRoutes
