import { useTranslate } from "@tolgee/react"
import { useEffect, useMemo } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { StepsNavigationLayout } from "../../../layouts"
import { usePlanningTool } from "../../../providers/PlanningToolProvider"
import PlanNameAndDescription from "./components/PlanNameAndDescription"
import { planAdditionAndEditionRoutes } from "./routes"
import { useAccount } from "../../../providers/AccountProvider"
import { IStep } from "../../../climateui/components/Steps"
import FullScreen from "../../../components/FullScreen"

const AddEditPlan = ({ testId }: { testId?: string }) => {
  const { cancelPlanAdditionOrEdition, navigateToAllowedPlanRoute } =
    usePlanningTool()

  const location = useLocation()
  const { selectedAccount } = useAccount()
  const { t } = useTranslate()

  const { steps, allowedPathTerminations } = useMemo(() => {
    const steps: IStep[] = []
    const allowedPathTerminations: string[] = []
    planAdditionAndEditionRoutes.forEach((route) => {
      steps.push({ name: t(route.labelKey || "") })
      allowedPathTerminations.push(route.path || "")
    })
    return { steps, allowedPathTerminations }
  }, [])

  useEffect(() => {
    if (!selectedAccount) return

    const paths = location.pathname.split("/")
    const lastPath = paths[paths.length - 1]

    if (!allowedPathTerminations.includes(lastPath)) {
      navigateToAllowedPlanRoute()
    }
  }, [selectedAccount, allowedPathTerminations])

  const currentStep = useMemo(() => {
    let step = 0
    const paths = location.pathname.split("/")
    const lastPath = paths[paths.length - 1]
    planAdditionAndEditionRoutes.forEach((route, index) => {
      if (route.path === lastPath) {
        step = index
      }
    })
    return step
  }, [location])

  return (
    <FullScreen>
      <div
        className="h-full w-full"
        data-testid={testId}>
        <StepsNavigationLayout
          left={<PlanNameAndDescription />}
          steps={steps}
          currentStep={currentStep}
          onCancel={cancelPlanAdditionOrEdition}>
          <Outlet />
        </StepsNavigationLayout>
      </div>
    </FullScreen>
  )
}

export default AddEditPlan
