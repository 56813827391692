import { IAsset, IAssetModel, IRegion } from "../../types"
import { useQuery } from "react-query"
import { isValidResponse, QuerySet } from "../../climateui/utils/http"
import {
  regionsQuerySet,
  yieldOutlookModelsGRAPHQL,
} from "../../utils/networking"
import { useParams } from "react-router-dom"
import { useAccount } from "../../providers"

const PRODUCT_KEY = [
  "seasonal_yield_outlook",
  "adapt_yield_outlook",
  "seasonal_risk_outlook",
  "status",
] as const
export type ProductKeyEnum = (typeof PRODUCT_KEY)[number]

const PRODUCT_STATUS_KEY_MAP: Record<ProductKeyEnum, string> = {
  seasonal_yield_outlook: "seasonal_status",
  adapt_yield_outlook: "climate_status",
  seasonal_risk_outlook: "risk_status",
  status: "status",
}

const getQueryString = (productKey: ProductKeyEnum) => {
  if (productKey === "seasonal_risk_outlook") {
    return `#graphql
      query ($region_ids: [String], $asset_ids: [String], $init_time: String) {
        yield_outlook_model(
          filter: {
            region_ids: $region_ids
            asset_ids: $asset_ids
            risk_status: "active"
          }
        ) {
          results {
            id
            region_id
            asset_id
            stages(filter: { current_date: $init_time }) {
              stage_name
              start_date
              end_date
            }
            default_geography {
              id
              newest_risk_date
              hazards {
                id
                hazard_key
                hazard_name
              }
            }
          }
        }
      }
    `
  }

  const extraQueryKeys =
    productKey === "seasonal_yield_outlook"
      ? "newest_seasonal_date oldest_seasonal_date"
      : ""

  return `#graphql
    query ($asset_ids: [String], $${PRODUCT_STATUS_KEY_MAP[productKey]}: String) {
      yield_outlook_model(
        filter: {
          asset_ids: $asset_ids
          ${PRODUCT_STATUS_KEY_MAP[productKey]}: $${PRODUCT_STATUS_KEY_MAP[productKey]}
        }
      ) {
        results {
          id
          region_id
          asset_id
          unit_conversion {
            conversion
            unit
          }
          ${extraQueryKeys}
        }
      }
    }
  `
}

const assetModelsQueryFn =
  (assetIDs: string[], productKey?: ProductKeyEnum) =>
  async (): Promise<IAssetModel[]> => {
    // graphql query filters
    const queryFilters: Record<string, string | string[]> = {
      asset_ids: assetIDs,
    }

    // add product key filter + value
    if (productKey && PRODUCT_STATUS_KEY_MAP[productKey])
      queryFilters[PRODUCT_STATUS_KEY_MAP[productKey]] = "active"
    else if (productKey && !PRODUCT_STATUS_KEY_MAP[productKey])
      throw new TypeError(
        "`productKey` must be of value 'seasonal_yield_outlook', 'adapt_yield_outlook' or 'seasonal_risk_outlook'",
      )

    const response = await yieldOutlookModelsGRAPHQL(
      getQueryString(productKey ?? "status"),
      queryFilters,
    )

    if (!isValidResponse(response)) return []
    return response.data.data.yield_outlook_model.results
  }

const assetModelRegionQueryFn =
  (assetModelsRegionIDs: string[]) => async (): Promise<IRegion[]> => {
    const response = await regionsQuerySet.post(
      "/search/",
      {
        filter_by: {
          and: [
            {
              field_name: "region.Region.id",
              operator: "in",
              field_value: assetModelsRegionIDs,
            },
          ],
        },
      },
      undefined,
      {
        headers: {
          "X-Fields": "data { id, parent_id, name, resolution }",
        },
      },
    )
    if (!isValidResponse(response)) return []
    return response.data.data
  }

const getFilteredModels = (
  assetModels: IAssetModel[] | undefined,
  assetId: string | undefined,
) => {
  if (!assetModels || assetModels.length === 0) return []
  if (!assetId) return assetModels
  return assetModels.filter(({ asset_id }) => asset_id === assetId)
}
const extractModelsRegionsIDs = (assetModels: IAssetModel[]) => {
  if (!assetModels || assetModels.length === 0) return []

  const registeredModels = new Set<string>()
  assetModels.forEach(({ region_id }) => {
    registeredModels.add(region_id)
  })

  return Array.from(registeredModels)
}

export const useAssetModels = ({
  assets,
  querySet,
  productKey,
}: {
  assets: Record<string, IAsset> | undefined
  querySet: QuerySet
  productKey?: ProductKeyEnum
}) => {
  const { selectedAccount } = useAccount()
  const assetIDs = assets ? Object.keys(assets) : []

  // Retrieve all the available asset models
  const {
    data: assetModels,
    isFetched: fetchedAssetModels,
    isFetching: fetchingAssetModels,
  } = useQuery({
    queryKey: ["assetModels", selectedAccount, querySet, assetIDs, productKey],
    queryFn: assetModelsQueryFn(assetIDs, productKey),
    enabled: !!selectedAccount && assetIDs.length > 0 && !!productKey,
  })

  /* DISCUSSION: Do we have a case where we want all the regions regardless?

    const assetModelsRegionIDs = useMemo(() => {
        if (!assetModels || assetModels.length === 0) return []

        const registeredModels = new Set<string>()
        assetModels.forEach(({ region_id }) => {
            registeredModels.add(region_id)
        })

        return Array.from(registeredModels)
    }, [assetModels])

    // Retrieve all the available asset model regions
    const { data: assetModelsRegions } = useQuery({
        queryKey: ["assetModelRegions ", assetModelsRegionIDs],
        queryFn: assetModelRegionQueryFn(assetModelsRegionIDs),
        enabled: assetModelsRegionIDs.length > 0,
    })
    */

  // Get the selected asset from the URL params
  const { assetId } = useParams()

  // IFilter the asset models based on the selected asset
  const selectedAssetModels = getFilteredModels(assetModels, assetId)
  const assetModelsRegionIDs = extractModelsRegionsIDs(selectedAssetModels)

  const {
    data: assetModelsRegions,
    isFetched: fetchedAssetModelsRegions,
    isFetching: fetchingAssetModelsRegions,
  } = useQuery({
    queryKey: ["assetModelRegions", assetModelsRegionIDs],
    queryFn: assetModelRegionQueryFn(assetModelsRegionIDs),
    enabled: assetModelsRegionIDs.length > 0,
  })

  const isPending =
    !fetchedAssetModels ||
    fetchingAssetModels ||
    !fetchedAssetModelsRegions ||
    fetchingAssetModelsRegions

  return {
    assetModels,
    assetModelsRegions,
    selectedAssetModels,
    isPending,
  }
}
