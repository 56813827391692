import { Outlet } from "react-router-dom"
import TabLayout from "../../layouts/TabLayout"
import { PlanningToolProvider, TriggeredAlertsProvider } from "../../providers"
import seasonalChildRoutes from "./routes"

const seasonalPaths = ["/seasonal", "/seasonal/"]

function SeasonalTab() {
  return (
    <TriggeredAlertsProvider>
      <PlanningToolProvider>
        <TabLayout
          redirectPaths={seasonalPaths}
          basePath="/seasonal"
          sidebarOptions={seasonalChildRoutes}
          withPadding={false}
          hideOverflow={true}>
          <Outlet />
        </TabLayout>
      </PlanningToolProvider>
    </TriggeredAlertsProvider>
  )
}

export default SeasonalTab
