import { IPrimitivesDictionary } from "../../climateui/types"
import { IDashboardLocation, IInsightsLocation } from "../../types"
import useDashboardLocations from "./useDashboardLocations"

export default function useDashboardRegion(
  customLocations: IDashboardLocation[] | undefined,
  showAllLocations?: boolean,
) {
  const {
    loadingLocations,
    allLocations,
    allLocationsDict,
    customLocationsDict,
    ...args
  } = useDashboardLocations(customLocations, showAllLocations)

  // custom regions dict to be used in region filer
  const customRegionsDict = {} as IPrimitivesDictionary
  const regionsLocationsDict = {} as Record<
    string,
    // IDashboardLocation[]
    IInsightsLocation[]
  >

  Object.keys(customLocationsDict).forEach((locId) => {
    if (!locId) return
    // get loc object
    const regionName = allLocationsDict?.[locId]?.region?.name
    if (!regionName) return

    // will allow us to filter easily location ids later on
    if (!regionsLocationsDict[regionName]) regionsLocationsDict[regionName] = []
    regionsLocationsDict[regionName].push(allLocationsDict[locId])

    // set custom regions dict
    if (!customRegionsDict[regionName])
      customRegionsDict[regionName] = regionName
  })

  return {
    // loc args
    ...args,
    loadingLocations,
    allLocations,
    allLocationsDict,
    customLocationsDict,
    customRegionsDict,
    regionsLocationsDict,
  }
}
