import { useTranslate } from "@tolgee/react"
import { DateTime } from "luxon"
import { useState } from "react"
import { DatePicker, FixedElement } from "../../climateui/components"
import { DAYS_IN_WEEK } from "../../climateui/components/DatePicker"
import { DateValidatorFn } from "../../climateui/components/DatePicker/DatePicker"
import { useOutsideComponentClickHandler } from "../../climateui/hooks"
import { CalendarIcon } from "../../climateui/icons"

const DATE_FORMAT = "MMM d"

const OutlookDateSelector = ({
  initialDate,
  onChange,
  dateValidator,
}: {
  initialDate: DateTime
  onChange: (date?: DateTime, endDate?: DateTime) => void
  dateValidator?: DateValidatorFn
}) => {
  const [show, setShow] = useState(false)
  const { t } = useTranslate()
  const outsideClickRef = useOutsideComponentClickHandler(() => {
    setShow(false)
  })
  return (
    <div ref={outsideClickRef}>
      <FixedElement
        open={show}
        parentElement={
          <button
            tabIndex={0}
            className={[
              "flex flex-row items-center",
              "transition-all duration-75",
              "h-[32px] min-w-[60px] px-[8px] w-fit max-w-[200px]",
              "border-[1px] rounded-sm border-gray-14 dark:border-gray-78",
              "cursor-pointer disabled:cursor-not-allowed",
              "focus:outline-accent",
              "bg-light-bg dark:bg-dark-bg label-lg text-gray-60 dark:text-gray-30",
              "disabled:bg-gray-5 dark:disabled:bg-gray-60 disabled:text-gray-30 disabled:fill-gray-30",
            ].join(" ")}
            onClick={() => setShow((oldShow) => !oldShow)}>
            {initialDate.toFormat(DATE_FORMAT)} {t("outlook", "Outlook")}
            <span className="w-[20px] h-[20px] fill-gray-60 ml-1">
              <CalendarIcon />
            </span>
          </button>
        }>
        <DatePicker
          initialDate={initialDate}
          initialEndDate={initialDate.plus({
            days: DAYS_IN_WEEK - 1,
          })}
          validator={dateValidator}
          invalidMessage={t("noDataAvailable", "No data available")}
          range={DAYS_IN_WEEK}
          rangeWeekdayStart={1}
          canClearInput={false}
          onChange={onChange}
        />
      </FixedElement>
    </div>
  )
}

export default OutlookDateSelector
