import { useTranslate } from "@tolgee/react"
import { useState } from "react"
import {
  Button,
  DebounceSearchInput,
  Table,
} from "../../../climateui/components"
import { PlusIcon } from "../../../climateui/icons"
import { GenericPageHeader } from "../../../components"
import { usePlanningTool } from "../../../providers/PlanningToolProvider"
import { IPlan } from "../../../types"
import { columns } from "./plansTableUtils"

const PlansList = () => {
  const { goToNewPlan, plans, loadingPlans } = usePlanningTool()

  const [globalFilter, setGlobalFilter] = useState("")
  const [rowSelection, setRowSelection] = useState({})
  const [columnFilters, setColumnFilters] = useState<
    { id: string; value: string[] | unknown }[]
  >([])

  const { t } = useTranslate()

  return (
    <div className="flex flex-col h-full overflow-hidden grow">
      <GenericPageHeader
        pageTitle={t("planListTitle")}
        pageSubtitle={t(
          "planningToolListSubtitle",
          "Test out different scenarios to design your optimal plans. Your plan scenarios will not impact the configuration of your alerts or assets.",
        )}
        right={
          <div className="flex flex-row items-center gap-2">
            <div className="w-48">
              <DebounceSearchInput
                placeholder={t("search")}
                onSearch={setGlobalFilter}
              />
            </div>
            <Button
              icon={<PlusIcon />}
              label={t("newPlan")}
              onClick={goToNewPlan}
            />
          </div>
        }
        bottom={undefined}
      />
      <div className="overflow-y-auto grow">
        <Table<IPlan>
          data={plans}
          columns={columns}
          state={{
            globalFilter,
            rowSelection,
            columnFilters,
            hiddenColumns: [],
          }}
          setGlobalFilter={setGlobalFilter}
          setRowSelection={setRowSelection}
          setColumnFilters={setColumnFilters}
          noDataMessage={loadingPlans ? t("loadingPlans") : t("noPlans")}
          extraClasses=""
        />
      </div>
    </div>
  )
}

export default PlansList
