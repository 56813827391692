const Divider = ({ text }: { text: string }) => {
  return (
    <div className="flex items-center w-full">
      <div className="flex-grow h-0 border-t-[0.5px] border-gray-14 dark:border-gray-78"></div>
      <span className="mx-1 label-sm text-gray-30">{text.toUpperCase()}</span>
      <div className="flex-grow h-0 border-t-[0.5px] border-gray-14 dark:border-gray-78"></div>
    </div>
  )
}
export default Divider
