import { Outlet } from "react-router-dom"
import RiskOutlookProvider from "./provider"

const RiskOutlookView = () => {
  return (
    <RiskOutlookProvider>
      <Outlet />
    </RiskOutlookProvider>
  )
}

export default RiskOutlookView
