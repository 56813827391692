import { IRoute } from "../../../climateui/types"
import AddEditDashboard from "./AddEditDashboard"
import CreateDashboardFlow from "./components/CreateDashboardFlow"
import DashboardTemplates from "./components/DashboardTemplates"
import DashboardLocations from "./components/DashboardLocations"
import DashboardAssets from "./components/DashboardAssets"
import GenericDashboardView from "./components/GenericDashboardView"
import { ChartTest } from "./ChartTest"

export const dashboardAdditionAndEditionRoutes: IRoute[] = [
  {
    path: "template",
    element: <DashboardTemplates />,
    labelKey: "selectTemplate",
  },
  {
    path: "locations",
    element: <DashboardLocations />,
    labelKey: "selectLocations",
  },
  {
    path: "assets",
    element: <DashboardAssets />,
    labelKey: "assignAssets",
  },
]

export const dashboardChildRoutes: IRoute[] = [
  {
    path: "",
    label: "",
    element: <CreateDashboardFlow />,
  },
  {
    path: "new",
    element: <AddEditDashboard />,
    children: dashboardAdditionAndEditionRoutes,
  },
  {
    featureFlags: ["feature_charts_refactor_poc"],
    path: "recharts-test",
    element: <ChartTest />,
  },
  {
    path: ":dashboardId",
    element: <GenericDashboardView />,
  },
  {
    path: ":dashboardId/edit",
    element: <AddEditDashboard />,
    children: dashboardAdditionAndEditionRoutes,
  },
]

export default dashboardChildRoutes
