const MoonIcon = (props) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.1637 2.34544C17.8819 2.53143 18.5694 2.78907 19.2174 3.10985C18.5999 3.41554 18.0184 3.77859 17.4804 4.19163C15.0936 6.02424 13.5652 8.84107 13.5652 12C13.5652 15.1589 15.0936 17.9758 17.4804 19.8084C18.0184 20.2214 18.5999 20.5845 19.2174 20.8902C18.5694 21.2109 17.8819 21.4686 17.1637 21.6546C16.2939 21.8798 15.379 22 14.4348 22C8.67181 22 4 17.5228 4 12C4 6.47715 8.67181 2 14.4348 2C15.379 2 16.2939 2.12018 17.1637 2.34544ZM14.4348 3.66667C14.7696 3.66667 15.0996 3.68473 15.4241 3.71987C13.2033 5.83245 11.8261 8.76137 11.8261 12C11.8261 15.2386 13.2033 18.1675 15.4241 20.2801C15.0996 20.3153 14.7696 20.3333 14.4348 20.3333C9.63231 20.3333 5.73913 16.6024 5.73913 12C5.73913 7.39763 9.63231 3.66667 14.4348 3.66667Z"
                fill={props.fill}
            />
        </svg>
    )
}

export default MoonIcon
