import http from "../../climateui/utils/http"
import config from "../config"

export const analogsSearchPOST = (payload) => {
  return http.post(
    "/analogs/search",
    payload,
    true,
    {},
    config.exploration_platform,
  )
}

export const analogsGET = (location) => {
  return http.get(location, true, {}, config.exploration_platform)
}
