import { useTranslate } from "@tolgee/react"
import { TooltipV2 } from "../../../climateui/components"
import { BoxIcon, CheckmarkIcon } from "../../../climateui/icons"

function AssetButton({
  name,
  isLoading = false,
  assetIsSetup = false,
  handleClick,
}: {
  name: string
  isLoading?: boolean
  assetIsSetup?: boolean
  handleClick?: () => void
}) {
  const { t } = useTranslate()

  return (
    <TooltipV2
      contentClass="w-40"
      content={t(
        "assetButtonTooltip",
        "Assets chosen based on your interest during our sales process. Contact us for any changes.",
      )}>
      <button
        disabled={assetIsSetup}
        onClick={handleClick}
        className={[
          "h-16 bg-light-bg dark:bg-dark-bg pr-8 pl-7 w-fit shrink-0",
          "flex flex-row items-center gap-2",
          "border rounded-md",
          "transition-all duration-75",
          "fill-gray-30 text-gray-30 border-gray-14 dark:border-gray-78",
          "disabled:cursor-not-allowed",
          isLoading
            ? "enabled:fill-gray-30 enabled:text-gray-60 dark:text-gray-30 enabled:border-gray-14 dark:border-gray-78"
            : "",
          !isLoading
            ? "enabled:fill-accent enabled:text-accent enabled:border-accent-medium"
            : "",
          !isLoading
            ? "enabled:hover:bg-accent-light enabled:hover:border-accent"
            : "",
        ].join(" ")}>
        <span
          className={[
            isLoading ? "animate-pulse" : "",
            "w-6 h-6 shrink-0",
          ].join(" ")}>
          {assetIsSetup ? <CheckmarkIcon /> : <BoxIcon />}
        </span>
        {isLoading ? (
          <div className="w-24 h-4 rounded-sm bg-gray-10 animate-pulse"></div>
        ) : (
          <div className="title-sm">{name}</div>
        )}
      </button>
    </TooltipV2>
  )
}

export default AssetButton
