import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { TrashIcon, EditIcon } from "../../../../climateui/icons"
import { ModalContext, ToastContext } from "../../../../climateui/providers"
import queryClient, { locationDELETE } from "../../../../utils/networking"
import { TableActions } from "../../../../climateui/components/Table/tableUtils"
import { IInsightsLocation } from "../../../../types"
import { useTranslate } from "@tolgee/react"
import { useAccount } from "../../../../providers/AccountProvider"
import { useMutation } from "react-query"
import { isValidResponse } from "../../../../climateui/utils/http"

const AdminLocationsTableActions = ({
  rowData,
}: {
  rowData: IInsightsLocation
}) => {
  const navigate = useNavigate()
  const { t } = useTranslate()
  const { mutate: deleteLocation } = useMutation(locationDELETE)
  const { confirmationModal } = useContext(ModalContext)
  const { enqueueAlert } = useContext(ToastContext)
  const { selectedAccount } = useAccount()
  return (
    <TableActions
      rowData={rowData}
      actions={[
        {
          tooltip:
            selectedAccount === rowData.account_id
              ? t("editLocation")
              : t("switchToChildToEditLocation"),
          icon: (
            <EditIcon
              fill={selectedAccount === rowData.account_id ? "" : "#B3B6BA"}
            />
          ),
          onClick: () => {
            if (selectedAccount === rowData.account_id)
              navigate("./edit/" + rowData.id)
          },
        },
        {
          tooltip:
            selectedAccount === rowData.account_id
              ? t("deleteLocation")
              : t("switchToChildToDeleteLocation"),
          icon: (
            <TrashIcon
              fill={selectedAccount === rowData.account_id ? "" : "#B3B6BA"}
            />
          ),
          onClick: () => {
            if (selectedAccount === rowData.account_id)
              confirmationModal({
                title: t("areYouSureDeleteLOCATION", {
                  location: rowData.name,
                }),
                text: t("thisActionCantBeUndone"),
                onContinueLabel: t("delete"),
                onCancelLabel: t("cancel"),
                onContinue: () => {
                  deleteLocation(rowData.id, {
                    onSuccess: async (response) => {
                      queryClient.invalidateQueries("locations")

                      if (!isValidResponse(response)) {
                        enqueueAlert(
                          t(
                            "NLocationsDeletedFailed",
                            "Location failed to be deleted.",
                          ),
                        )
                      } else {
                        enqueueAlert(
                          t(
                            "NLocationsDeletedSuccessfully",
                            "Location deleted successfully.",
                            {
                              count: 1,
                            },
                          ),
                        )
                      }
                    },
                    onError: async (data) => {
                      console.log("ERROR!", data)
                      // On error, hit the error callback
                      // errorCallback()
                    },
                  })
                },
              })
          },
        },
      ]}
    />
  )
}

export default AdminLocationsTableActions
