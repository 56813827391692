import { createContext, ReactNode, useContext, useMemo } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { FileIcon, ImageIcon, LinkIcon } from "../climateui/icons"
import { IRoute } from "../climateui/types"
import { isValidResponse } from "../climateui/utils/http"
import { customReportsGET } from "../utils/networking/customReports"
import { useAccount } from "./AccountProvider"

export interface ICustomReport {
  id: number
  name: string
  type: string
  url: string
  updated_at: Date
}

interface ICustomReportsContext {
  selectedCustomReport?: ICustomReport
  reportsRoutes: IRoute[]
}

const CustomReportsContext = createContext({} as ICustomReportsContext)

export enum CustomReportType {
  PNG = "image",
  PDF = "pdf",
  URL = "url",
}

function getReportIcon(type: string) {
  if (type === CustomReportType.PNG) return <ImageIcon />
  else if (type === CustomReportType.PDF) return <FileIcon />
  else if (type === CustomReportType.URL) return <LinkIcon />
  return <ImageIcon />
}
function buildReportRoute(report: ICustomReport) {
  return {
    path: report.id + "",
    label: report.name,
    labelKey: report.name,
    icon: getReportIcon(report.type),
  }
}

export const useCustomReports = () => useContext(CustomReportsContext)

function CustomReportsProvider({ children }: { children: ReactNode }) {
  const { reportId } = useParams()
  const { selectedAccount } = useAccount()

  const { data, isLoading, isFetching } = useQuery(
    ["reports", selectedAccount],
    customReportsGET,
  )

  const { reportsRoutes, reportsObject } = useMemo(() => {
    if (isLoading || isFetching || !isValidResponse(data)) {
      if (!isValidResponse(data)) {
        // enqueue alert
      }
      return {
        reportsRoutes: [],
        reportsObject: {},
      }
    }

    const reportsData = data.data as ICustomReport[]
    const reportsObject: Record<string, ICustomReport> = {}
    const reportsRoutes = [] as IRoute[]

    const reportsByType = {} as Record<string, IRoute[]>

    if (reportsData.length > 0) {
      reportsData.forEach((report) => {
        reportsObject[report.id] = report
        const reportRoute = buildReportRoute(report)
        reportsByType[report.type] = [
          ...(reportsByType[report.type] || []),
          reportRoute,
        ]
      })
    }

    for (const type in reportsByType) {
      const reports = reportsByType[type]
      // sort report routes by item.label
      reports.sort((a, b) => {
        if (a.label && b.label) return a.label.localeCompare(b.label)
        return 0
      })
      reportsRoutes.push(...reports)
    }

    return {
      reportsRoutes: reportsRoutes,
      reportsObject,
    }
  }, [data, isLoading, isFetching])

  const providerValue = useMemo(() => {
    let selectedCustomReport
    if (reportId && reportsObject && reportsObject[reportId])
      selectedCustomReport = reportsObject[reportId]

    return {
      selectedCustomReport,
      reportsRoutes,
    }
  }, [reportId, reportsObject, reportsRoutes])

  return (
    <CustomReportsContext.Provider value={providerValue}>
      {children}
    </CustomReportsContext.Provider>
  )
}

export default CustomReportsProvider
