import { createContext, ReactNode, useEffect, useState } from "react"
import CancelIcon from "../climateui/icons/CancelIcon"
import { useAuth } from "../providers/AuthProvider"

// TYPES
import { IUser } from "../climateui/types"
import { T } from "@tolgee/react"

export const SudoContext = createContext({})

export default function SudoProvider({
  children,
}: {
  children: ReactNode | ReactNode[]
}) {
  const [sudoedToken, setSudoedToken] = useState<string>()
  const [sudoedUser, setSudoedUser] = useState<IUser>()

  const auth = useAuth()

  const exitSudo = () => {
    setSudoedToken(undefined)
    setSudoedUser(undefined)
    auth.logout()
  }

  useEffect(() => {
    const storageToken = localStorage.getItem("climateai-sudoed-token")
    const storageUser = localStorage.getItem("climateai-sudoed-user")
    if (!storageToken || !storageUser) return
    setSudoedToken(storageToken)
    setSudoedUser(JSON.parse(storageUser))
  }, [])

  return (
    <SudoContext.Provider value={{ exitSudo }}>
      <div
        className={
          "flex flex-col h-full " +
          (auth.user && sudoedToken ? "border-4 border-accent" : "")
        }>
        {auth.user && sudoedToken && (
          <div className="flex items-center justify-center w-full py-2 text-white body-md bg-gray-90 grow-0 shrink-0">
            <T keyName="youAreSudoingAs">You are sudoing as&nbsp;</T>
            <span className="font-medium">
              {sudoedUser?.username} ({sudoedUser?.email})
            </span>
            &nbsp;
            <button
              onClick={() => exitSudo()}
              className="flex items-center content-center link">
              <T keyName="exitSudoMode">Exit Sudo Mode </T>
              <span className="w-5 h-5 underline fill-accent">
                <CancelIcon />
              </span>
            </button>
          </div>
        )}
        <div className="grow">{children}</div>
      </div>
    </SudoContext.Provider>
  )
}
