import { useTranslate } from "@tolgee/react"
import { Fragment, useEffect, useMemo, useState } from "react"
import { Switch, Tooltip } from "../../../climateui/components"
import {
  ChevronArrow,
  CollapseAllIcon,
  ExpandAllIcon,
} from "../../../climateui/icons"
import { RiskProfileForm } from "../../Admin/RiskProfiles/components"
import { useAssetDependentSteps } from "../components/OnboardingAssetDependentLayoutProvider"
import RiskAndStageSuggestion from "../components/RiskAndStageSuggestion"

interface IEditingInfo {
  variableKey: string
  riskProfileIndex: number
}

function UsingGenericSuggestionsMsg() {
  const { t } = useTranslate()

  return (
    <p className="flex flex-row flex-wrap items-center gap-x-1 [&_span]:shrink-0">
      <span>{t("reviewOur", "Review our")}</span>
      <Tooltip
        contentClass="w-52"
        content={t(
          "usingGenericRiskProfilesSuggestionsTooltip",
          "Suggestions for this asset are currently unavailable. A generic list of risk profiles is provided as a starting point",
        )}
        customStyle="relative inline-flex items-center justify-center group transition-all duration-200 shrink-0">
        <span className="link">
          {t("genericRiskProfiles", "generic risk profiles")}
        </span>
      </Tooltip>
      <span>
        {t("suggestionAndEditIfNeeded", "suggestion and edit if needed.")}
      </span>
      <span>
        {t(
          "youWillReceiveAlertsWhenThresholdMet",
          "You will receive an alert when the forecast shows that a threshold will be met.",
        )}
      </span>
    </p>
  )
}

function OnboardingRiskProfiles() {
  const { t } = useTranslate()
  const {
    asset,
    isLoadingSuggestions,
    workingRPPerVariable,
    setWorkingRPPerVariable,
    areGenericSuggestions,
  } = useAssetDependentSteps()

  const [openRiskProfiles, setOpenRiskProfiles] = useState<
    Record<string, boolean>
  >({})
  const [isEditing, setIsEditing] = useState(false)
  const [editingInfo, setEditingInfo] = useState<IEditingInfo>()

  useEffect(() => {
    setOpenRiskProfiles((prev) =>
      Object.keys(workingRPPerVariable).reduce((acc, curr) => {
        return { ...acc, [curr]: prev[curr] ?? true }
      }, {}),
    )
  }, [workingRPPerVariable])

  const areAllOpen = useMemo(
    () => Object.values(openRiskProfiles).every((isOpen) => isOpen),
    [openRiskProfiles],
  )

  function toggleAll() {
    setOpenRiskProfiles(
      Object.keys(openRiskProfiles).reduce((acc, curr) => {
        return { ...acc, [curr]: !areAllOpen }
      }, {}),
    )
  }

  const riskProfileVariables = Object.keys(workingRPPerVariable)

  const editingRiskProfile = editingInfo
    ? workingRPPerVariable[editingInfo.variableKey][
        editingInfo.riskProfileIndex
      ].riskProfile
    : undefined
  if (editingRiskProfile) {
    editingRiskProfile.labels = []
    editingRiskProfile.varieties = []
  }

  return (
    <>
      <div className="flex flex-col gap-4 px-6 pb-6 h-full">
        <div className="flex flex-row items-center justify-between">
          <p className="body-lg">
            {areGenericSuggestions ? (
              <UsingGenericSuggestionsMsg />
            ) : (
              t(
                "onboardingRiskProfilesStepInstructionsForASSET",
                "Review the suggested risk profiles for {asset} and edit if needed.",
                {
                  asset: asset?.name ?? t("yourAsset", "your asset"),
                },
              ) +
              " " +
              t(
                "youWillReceiveAlertsWhenThresholdMet",
                "You will receive an alert when the forecast shows that a threshold will be met.",
              )
            )}
          </p>
          <div
            className="flex flex-row items-center gap-2 border rounded-sm border-gray-14 dark:border-gray-78 h-8 px-2 cursor-pointer shrink-0"
            onClick={() => setIsEditing((prev) => !prev)}>
            <p className="label-lg text-gray-60 dark:text-gray-30 select-none">
              {t("editMode", "Edit mode")}
            </p>
            <span className="pointer-events-none">
              <Switch
                size="sm"
                checked={isEditing}
                onChange={(checked) => setIsEditing(checked ?? false)}
              />
            </span>
          </div>
        </div>
        <div className="grow overflow-y-auto">
          <table
            className={[
              "w-full table-fixed relative",
              "border-separate border-spacing-0",
              "[&_th]:border-b [&_th]:border-gray-14 dark:border-gray-78",
              "[&_th]:label-lg",
              "[&_th]:p-0",
              "[&_td]:p-0",
            ].join(" ")}>
            <thead>
              <tr
                className={[
                  "bg-gray-3 dark:bg-gray-88 h-[42px] sticky top-0 text-left",
                ].join(" ")}>
                <th className="w-10">
                  <div className="flex flex-row items-center justify-center">
                    <Tooltip
                      position="right"
                      content={t(areAllOpen ? "collapseAll" : "expandAll")}>
                      <span
                        className="w-5 h-5 fill-gray-60 cursor-pointer"
                        onClick={toggleAll}>
                        {areAllOpen ? <CollapseAllIcon /> : <ExpandAllIcon />}
                      </span>
                    </Tooltip>
                  </div>
                </th>
                <th className="w-1/3">
                  {t("riskProfileName", "Risk profile name")}
                </th>
                <th className="w-1/2">{t("threshold", "Threshold")}</th>
                <th className="w-1/6">{t("stage", "Stage")}</th>
                <th className="w-10"></th>
              </tr>
            </thead>
            <tbody className="[&_td]:border-b [&_td]:border-gray-14 dark:border-gray-78">
              {isLoadingSuggestions && (
                <tr className="h-[42px]">
                  <td
                    colSpan={5}
                    className="text-gray-60 dark:text-gray-30 animate-pulse text-center">
                    {t("loading")}
                  </td>
                </tr>
              )}
              {!isLoadingSuggestions && riskProfileVariables.length === 0 && (
                <tr className="h-[42px]">
                  <td
                    colSpan={5}
                    className="text-gray-60 dark:text-gray-30 text-center">
                    {t(
                      "noRiskProfileSuggestionsForAsset",
                      "We don't have template Risk Profiles for this asset",
                    )}
                  </td>
                </tr>
              )}
              {!isLoadingSuggestions &&
                riskProfileVariables.length > 0 &&
                [...riskProfileVariables]
                  .sort((a) => {
                    return a.endsWith("Stacked Risks") ? 1 : -1
                  })
                  .map((variableKey) => {
                    const isOpen = openRiskProfiles[variableKey]
                    return (
                      <Fragment key={variableKey}>
                        <tr
                          className="bg-gray-1.5 dark:bg-gray-88 h-[42px] cursor-pointer"
                          onClick={() =>
                            setOpenRiskProfiles((prev) => {
                              return {
                                ...prev,
                                [variableKey]: !prev[variableKey],
                              }
                            })
                          }>
                          <td>
                            <div className="flex flex-row items-center justify-center">
                              <Tooltip
                                position="right"
                                content={t(isOpen ? "collapse" : "expand")}>
                                <span
                                  className={[
                                    "w-5 h-5",
                                    "fill-gray-60",
                                    "transition-all",
                                    isOpen ? "" : "-rotate-90",
                                  ].join(" ")}>
                                  <ChevronArrow />
                                </span>
                              </Tooltip>
                            </div>
                          </td>
                          <td
                            colSpan={4}
                            className="label-sm text-gray-60 dark:text-gray-30">
                            {t(variableKey, variableKey)}
                          </td>
                        </tr>
                        {workingRPPerVariable[variableKey].map(
                          (riskProfileAndStage, riskProfileIndex) => (
                            <RiskAndStageSuggestion
                              onEdit={
                                isEditing
                                  ? () =>
                                      setEditingInfo({
                                        variableKey,
                                        riskProfileIndex,
                                      })
                                  : undefined
                              }
                              key={
                                riskProfileAndStage.riskProfile.id +
                                riskProfileAndStage.stageName
                              }
                              riskProfileAndStage={riskProfileAndStage}
                              isOpen={isOpen}
                            />
                          ),
                        )}
                      </Fragment>
                    )
                  })}
            </tbody>
          </table>
        </div>
      </div>

      <RiskProfileForm
        isOpen={!!editingInfo}
        riskProfile={editingRiskProfile}
        onCancel={() => setEditingInfo(undefined)}
        showFullVersion={false}
        customOnSave={(payload) => {
          if (!editingInfo) return

          const varKey = editingInfo.variableKey
          const newVarRiskProfiles = [...workingRPPerVariable[varKey]]
          const newRiskProfile =
            newVarRiskProfiles[editingInfo.riskProfileIndex].riskProfile
          newRiskProfile.name = payload.name
          payload.hazard_profiles.forEach((editedHazardProfiles, index) => {
            const currHazardProfile = newRiskProfile.hazard_profiles[index]
            currHazardProfile.threshold = editedHazardProfiles.threshold ?? 0
            currHazardProfile.window = editedHazardProfiles.window ?? 1
          })

          setWorkingRPPerVariable({
            ...workingRPPerVariable,
            [varKey]: newVarRiskProfiles,
          })
          setEditingInfo(undefined)
        }}
        bottomLine={t(
          "youWillBeAbleToDetailEditLater",
          "You will be able to make more detailed edits later.",
        )}
      />
    </>
  )
}

export default OnboardingRiskProfiles
