import { useTranslate } from "@tolgee/react"
import SearchWidget from "../../../../../climateui/components/Widgets/SeasonalWidgets/SearchWidget"

type Props = {
  title: string
  loading: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  units: any
}

function ExpectedChangeWidget(props: Props) {
  const { data, units } = props
  const formatter = Intl.NumberFormat(undefined, {
    maximumFractionDigits: 2,
  })
  const { t } = useTranslate()

  let yieldOutlookMeanValue = ""
  let yieldDeviationMeanValue = ""
  let expectedWidgetValueString = ""

  if (data?.results[0]) {
    const results = data.results[0]
    yieldOutlookMeanValue = formatter.format(results.outlook_mean)
    yieldDeviationMeanValue = formatter.format(results.deviation_mean * 100)
    expectedWidgetValueString = `${yieldDeviationMeanValue}% (${yieldOutlookMeanValue} ${units})`
  } else {
    expectedWidgetValueString = "_"
  }

  return (
    <SearchWidget
      value={expectedWidgetValueString}
      description={t("vsHistorialBaseline")}
      errorMessage={t("errorLoadingData")}
      reloadMessage={t("clickReload")}
      noResultsMessage={t("noResultCurrentFilters")}
      tryAnotherFilterMessage={t("tryAnotherFilter")}
      {...props}
    />
  )
}

export default ExpectedChangeWidget
