import { useTranslate } from "@tolgee/react"
import { useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"
import {
  Button,
  DebounceSearchInput,
  Table,
} from "../../../../climateui/components"
import { PlusIcon } from "../../../../climateui/icons"
import { CustomResponse } from "../../../../climateui/utils/http"
import { GenericPageHeader } from "../../../../components"
import { ResponsivePaddingWrapper } from "../../../../layouts/TabLayout"
import { useAccount } from "../../../../providers/AccountProvider"
import { useDashboard } from "../../../../providers/DashboardProvider"
import { IDashboard, IScheduler, ISchedulerAccount } from "../../../../types"
import { accountSchedulesGET } from "../../../../utils/networking"
import { columns } from "../SchedulerTableUtils"
import { OpenScheduleForm } from "./NewScheduleForm"

const SchedulerView = () => {
  const { t } = useTranslate()
  const { selectedAccount } = useAccount()
  const [globalFilter, setGlobalFilter] = useState("")
  const [dashNameFilter, setDashNameFilter] = useState("")
  const [rowSelection, setRowSelection] = useState({})
  const [columnFilters, setColumnFilters] = useState<
    { id: string; value: string[] | unknown }[]
  >([])
  const { dashboards, dashboardsObject } = useDashboard()
  const [dashboardToSchedule, setDashboardToSchedule] = useState<IDashboard>()
  const [dropdownList, setDropdownList] = useState(false)
  const [openForm, setOpenForm] = useState(false)

  const {
    refetch,
    data,
    isLoading: loadingSchedules,
  } = useQuery(["schedules", selectedAccount], accountSchedulesGET, {
    enabled: !!selectedAccount,
  })

  const { schedules } = useMemo(() => {
    let schedules: ISchedulerAccount[] = []
    if (data && dashboardsObject && Object.keys(dashboardsObject).length != 0) {
      schedules = [...(data as CustomResponse).data]
      schedules.forEach((schedule) => {
        delete schedule.dashboard
      })

      schedules.sort((a: IScheduler, b: IScheduler) => {
        const dashboardA =
          dashboardsObject[a.dashboard_id as string].title ?? " "
        const dashboardB =
          dashboardsObject[b.dashboard_id as string].title ?? " "
        if (dashboardA > dashboardB) {
          return 1
        } else {
          return -1
        }
      })
    }

    return { schedules }
  }, [data, dashboardsObject])

  useEffect(() => {
    if (selectedAccount) refetch()
  }, [selectedAccount])

  const dropDownDashboardList = () => {
    dropdownList ? setDropdownList(false) : setDropdownList(true)
  }

  // const scheduleRecurrenceDict = {
  //     daily: t("daily"),
  //     weekly: t("weekly"),
  //     monthly: t("monthly"),
  // } as IPrimitivesDictionary

  const dashboardNames = useMemo(() => {
    const dashboardNames = dashboards
      .filter((dashboard: IDashboard) => {
        const dashTitle = dashboard.title?.toLocaleLowerCase()
        return dashTitle?.includes(dashNameFilter.toLocaleLowerCase())
      })
      .sort((a: IDashboard, b: IDashboard) =>
        (a.title as string).toLowerCase() > (b.title as string).toLowerCase()
          ? 1
          : -1,
      )
      .map((dashboard) => {
        return (
          <li
            className="p-1 cursor-pointer hover:bg-gray-3 dark:bg-gray-90 dark:hover:bg-gray-88"
            key={dashboard.id}
            onClick={() => {
              setDashboardToSchedule(dashboard)
              setOpenForm(true)
            }}>
            {dashboard.title}
          </li>
        )
      })
    return dashboardNames
  }, [dashNameFilter, loadingSchedules])

  useEffect(() => {
    if (!dropdownList) {
      setDashNameFilter("")
    }
  }, [dropdownList])

  return (
    <>
      <ResponsivePaddingWrapper>
        <div className="flex flex-col h-full overflow-hidden grow">
          <GenericPageHeader
            pageTitle={t("reportScheduler")}
            right={
              <div className="flex gap-2 justify-end">
                <div className="w-48">
                  <DebounceSearchInput
                    placeholder={t("search")}
                    onSearch={setGlobalFilter}
                  />
                </div>
                <div className="flex justify-end">
                  <Button
                    label={t("newSchedule")}
                    onClick={dropDownDashboardList}
                    icon={<PlusIcon />}
                  />
                  {dropdownList ? (
                    <div className="absolute z-50 bg-light-bg dark:bg-dark-bg w-1/5 max-h-[50%] overflow-y-auto rounded-md border border-gray-14 dark:border-gray-78 mt-12 p-3">
                      <DebounceSearchInput
                        placeholder={t("searchDashboard")}
                        onSearch={setDashNameFilter}
                        onSubmit={() => {
                          if (
                            dashboardNames.length > 0 &&
                            dashNameFilter !== ""
                          ) {
                            setDashNameFilter("")
                          }
                        }}
                      />
                      <ul className="flex-wrap my-3 font-roboto">
                        {dashboardNames}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            }
            bottom={undefined}
          />
          <div className="overflow-y-auto grow">
            <Table<IScheduler>
              data={schedules}
              columns={columns}
              state={{
                globalFilter,
                rowSelection,
                columnFilters,
                hiddenColumns: [],
              }}
              noDataMessage={
                loadingSchedules ? "Loading Schedules" : "No Schedules"
              }
              setGlobalFilter={setGlobalFilter}
              setRowSelection={setRowSelection}
              setColumnFilters={setColumnFilters}
              extraClasses=""
            />
          </div>
        </div>
      </ResponsivePaddingWrapper>
      {openForm ? (
        <OpenScheduleForm
          isOpen={openForm}
          sendNow={false}
          dashboard={dashboardToSchedule}
          onCancel={() => {
            setOpenForm(false)
            setDropdownList(false)
          }}
          reloadSchedules={() => {
            refetch().catch((err) => console.error(err))
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default SchedulerView
