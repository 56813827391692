export const downloadBlob = (blob: string, fileName: string) => {
  const fakeLink = document.createElement("a")
  fakeLink.setAttribute("style", "display:none;")
  fakeLink.download = fileName
  fakeLink.href = blob
  document.body.appendChild(fakeLink)
  fakeLink.click()
  document.body.removeChild(fakeLink)
  fakeLink.remove()
}
