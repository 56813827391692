import { useTranslate } from "@tolgee/react"
import { useEffect, useMemo, useState } from "react"
import { useTimeline } from ".."
import { Button, Table } from "../../../climateui/components"
import { CancelIcon } from "../../../climateui/icons"
import EmptyModal from "../../../climateui/providers/Modal/EmptyModal"
import { useRiskProfiles } from "../../../providers/RiskProfilesProvider"
import {
  IRiskProfile,
  ITimelineRiskProfileStage,
  ITimelineStage,
  TIMELINE_EDITION_ACTIONS,
} from "../../../types"
import StageRisksTableFilters from "../../SeasonalCalendar/components/StageRisksTableFilters"
import { useTimelineRow } from "./TimelineRow"
import { columns, getRiskProfileExistsOrNotSortingFunction } from "./utils"

const ROW_SELECTION = {}

interface ITimelineImportRiskProfiles {
  open: boolean
  setOpen: (open: boolean) => void
}

function TimelineImportRiskProfiles({
  open,
  setOpen,
}: ITimelineImportRiskProfiles) {
  const { t } = useTranslate()
  const { riskProfileOpts, riskProfileOptsObj } = useTimeline()
  const {
    row,
    updateRow,
    selectedStagesPerRP,
    setSelectedStagesPerRP,
    baseSelectedStagesPerRP,
  } = useTimelineRow()
  const { riskProfilesObj } = useRiskProfiles()

  useEffect(() => {
    if (open) setSelectedStagesPerRP({ ...baseSelectedStagesPerRP })
  }, [open])

  const sortedRiskProfilesToDisplay = useMemo<IRiskProfile[]>(() => {
    const relatedRP: Record<string, boolean> = {}
    const result: IRiskProfile[] = [...riskProfileOpts]
    row.stages.forEach((stage) => {
      stage.riskProfileStages.forEach((riskProfileStage) => {
        const rpid = riskProfileStage.risk_profile_id
        relatedRP[rpid] = true
      })
    })

    const existsOrNotSort =
      getRiskProfileExistsOrNotSortingFunction(riskProfilesObj)

    const getStagesCount = (riskProfile: IRiskProfile) => {
      if (!selectedStagesPerRP[riskProfile.id]) return 0
      return Object.keys(selectedStagesPerRP[riskProfile.id]).length
    }
    const hasStages = (riskProfile: IRiskProfile) => {
      return getStagesCount(riskProfile) > 0
    }

    const tempResult = result.sort((a, b) => {
      if (hasStages(a) && hasStages(b))
        return getStagesCount(a) > getStagesCount(b) ? -1 : 1
      else if (hasStages(a)) return -1
      else if (hasStages(b)) return 1
      else if (relatedRP[a.id] && relatedRP[b.id]) return existsOrNotSort(a, b)
      else if (relatedRP[a.id]) return -1
      else if (relatedRP[b.id]) return 1

      return existsOrNotSort(a, b)
    })

    return tempResult
  }, [riskProfileOpts, riskProfilesObj, selectedStagesPerRP, row.stages])

  const [globalFilter, setGlobalFilter] = useState("")
  const [columnFilters, setColumnFilters] = useState<
    { id: string; value: unknown }[]
  >([])

  const closeModal = () => {
    setOpen(false)
    setGlobalFilter("")
    setColumnFilters([])
  }

  const save = () => {
    const newRPPerStage: Record<string, string[]> = {}
    Object.keys(selectedStagesPerRP).forEach((rpid) => {
      Object.keys(selectedStagesPerRP[rpid]).forEach((sid) => {
        if (!newRPPerStage[sid]) newRPPerStage[sid] = []
        newRPPerStage[sid].push(rpid)
      })
    })

    const newStages: ITimelineStage[] = []
    row.stages.forEach((stage) => {
      const sid = stage.id
      if (newRPPerStage[sid]) {
        const newStageRiskProfiles: ITimelineRiskProfileStage[] = []
        newRPPerStage[sid].forEach((rpid) => {
          newStageRiskProfiles.push({
            id: rpid,
            row_id: row.id,
            risk_profile_id: rpid,
            risk_profile: riskProfileOptsObj[rpid],
          })
        })
        stage.riskProfileStages = newStageRiskProfiles
      } else {
        stage.riskProfileStages = []
      }
      stage.action = stage.action ?? TIMELINE_EDITION_ACTIONS.EDITED
      newStages.push({ ...stage })
    })

    updateRow({
      stages: newStages,
    })

    closeModal()
  }

  return (
    <EmptyModal
      customClasses="w-[660px] h-[560px] flex flex-col divide-y divide-gray-14 dark:divide-gray-78"
      open={open}>
      <div className="pl-4 pr-4 h-[60px] flex flex-row items-center justify-between shrink-0">
        <div>
          <h3 className="title-sm">
            {t(
              "linkStagesToImportRiskProfiles",
              "Link stages to import risk profiles",
            )}
          </h3>
          <p className="body-sm text-gray-60 dark:text-gray-30">
            {row.data.varietyName}
          </p>
        </div>
        <span
          className="w-6 h-6 fill-gray-60 hover:fill-gray-60/80 transition-all cursor-pointer"
          onClick={closeModal}>
          <CancelIcon />
        </span>
      </div>
      <div className="grow overflow-y-auto p-4 flex flex-col gap-2">
        <div className="shrink-0">
          <StageRisksTableFilters
            searchIndependentRow={false}
            setColumnFilters={setColumnFilters}
            setGlobalFilter={setGlobalFilter}
            columnFilters={columnFilters}
          />
        </div>
        <div className="grow overflow-y-auto">
          <Table
            extraClasses="[&_th]:sticky [&_th]:top-0"
            data={sortedRiskProfilesToDisplay}
            columns={columns}
            getRowId={(riskProfile: IRiskProfile, index: number) =>
              riskProfile.id || index.toString()
            }
            state={{
              rowSelection: ROW_SELECTION,
              globalFilter,
              columnFilters,
              hiddenColumns: ["labels", "variable", "name"],
            }}
            setGlobalFilter={setGlobalFilter}
            setColumnFilters={setColumnFilters}></Table>
        </div>
      </div>
      <div className="h-[60px] flex flex-row items-center justify-end px-4 shrink-0">
        <Button
          label={t("save", "Save")}
          onClick={save}
        />
      </div>
    </EmptyModal>
  )
}

export default TimelineImportRiskProfiles
