import MaxTemperatureIcon from "../../../climateui/icons/variableIcons/MaxTemperatureIcon"
import MeanHumidityIcon from "../../../climateui/icons/variableIcons/MeanHumidityIcon"
import MeanTemperatureIcon from "../../../climateui/icons/variableIcons/MeanTemperatureIcon"
import MinTemperatureIcon from "../../../climateui/icons/variableIcons/MinTemperatureIcon"
import SumPrecipitationIcon from "../../../climateui/icons/variableIcons/SumPrecipitation"
import {
  EvapotranspirationIcon,
  MaxWindSpeedIcon,
  PrecipitationIcon,
  SoilTemperatureIcon,
  SolarRadiationIcon,
  WindSpeedIcon,
} from "../../../climateui/icons/weatherVariableIcons"
import { IVariable } from "../../../types"

const dashboardVariables: Record<string, IVariable> = {
  // variables: {
  temp_max: {
    title: "Max Temperature",
    label: "maxTempChartTitle",
    name: "Max Temperature",
    value: "temp_max",
    chartConfig: {
      color: "#FF7100",
      units_metric: "°C",
      units_imperial: "°F",
    },
    icon: MaxTemperatureIcon,
  },
  temp_min: {
    title: "Min Temperature",
    label: "minTempChartTitle",
    name: "Min Temperature",
    value: "temp_min",
    chartConfig: {
      color: "#039BE5",
      units_metric: "°C",
      units_imperial: "°F",
    },
    icon: MinTemperatureIcon,
  },
  temp_mean: {
    title: "Mean Temperature",
    label: "meanTempChartTitle",
    name: "Mean Temperature",
    value: "temp_mean",
    chartConfig: {
      color: "#A220B7",
      units_metric: "°C",
      units_imperial: "°F",
    },
    icon: MeanTemperatureIcon,
  },
  precipitation: {
    title: "Mean Precipitation",
    label: "precipitationMeanChartTitle",
    name: "Precipitation",
    value: "precipitation",
    chartConfig: {
      color: "#237CB5",
      units_metric: "mm",
      units_imperial: "in",
      zeroBasis: true,
    },
    icon: PrecipitationIcon,
  },
  precipitation_sum: {
    title: "Sum Precipitation",
    label: "precipitationSumChartTitle",
    name: "Sum Precipitation",
    value: "precipitation_sum",
    chartConfig: {
      color: "#237CB5",
      units_metric: "mm",
      units_imperial: "in",
      zeroBasis: true,
    },
    icon: SumPrecipitationIcon,
  },
  soil_temperature: {
    title: "Soil Temperature",
    label: "soilTempChartTitle",
    name: "Soil Temperature",
    value: "soil_temperature",
    chartConfig: {
      color: "#EE6900",
      units_metric: "°C",
      units_imperial: "°F",
    },
    icon: SoilTemperatureIcon,
  },
  humidity: {
    title: "Mean Humidity",
    label: "humidityChartTitle",
    name: "Mean Humidity",
    value: "humidity",
    chartConfig: {
      color: "#3B77B5",
      units_metric: "%",
      units_imperial: "%",
    },
    icon: MeanHumidityIcon,
  },
  solar_radiation: {
    title: "Solar Radiation",
    label: "solarRadiationChartTitle",
    name: "Solar Radiation",
    value: "solar_radiation",
    chartConfig: {
      color: "#e2813b",
      units_metric: "w/m^2",
      units_imperial: "w/m^2",
    },
    icon: SolarRadiationIcon,
  },
  evapotranspiration: {
    title: "Evapotranspiration",
    label: "evapotranspirationChartTitle",
    name: "Evapotranspiration",
    value: "evapotranspiration",
    chartConfig: {
      color: "#a9a875",
      units_metric: "mm/day",
      units_imperial: "in/day",
    },
    icon: EvapotranspirationIcon,
  },
  wind_speed: {
    title: "Wind Speed",
    label: "windSpeedChartTitle",
    name: "Wind Speed",
    value: "wind_speed",
    chartConfig: {
      color: "#db620a",
      units_metric: "km/h",
      units_imperial: "mph",
    },
    icon: WindSpeedIcon,
  },
  max_wind_speed: {
    title: "Max Wind Speed",
    label: "maxWindSpeedChartTitle",
    name: "Max Wind Speed",
    value: "max_wind_speed",
    chartConfig: {
      color: "#db620a",
      units_metric: "km/h",
      units_imperial: "mph",
    },
    icon: MaxWindSpeedIcon,
  },
  // },
}

export default dashboardVariables
