import { useTranslate } from "@tolgee/react"
import { CancelIcon } from "../../../climateui/icons"
import EmptyModal from "../../../climateui/providers/Modal/EmptyModal"
import { BulkUploadLocations } from "../../../components"
import { IInsightsLocation } from "../../../types"
import { useAssetDependentSteps } from "./OnboardingAssetDependentLayoutProvider"

interface IBulkUploadLocationsModal {
  open: boolean
  close: () => void
}

function BulkUploadLocationsModal({ open, close }: IBulkUploadLocationsModal) {
  const { t } = useTranslate()
  const { asset, setAssetNewLocations } = useAssetDependentSteps()

  return (
    <EmptyModal
      customClasses="relative rounded-lg bg-light-bg dark:bg-dark-bg w-[390px]"
      open={open}>
      <div className="pl-5 pr-4 h-[56px] flex flex-row items-center justify-between border-b border-gray-14 dark:border-gray-78">
        <h3 className="title-sm">{t("uploadLocations", "Upload Locations")}</h3>
        <span
          className="w-6 h-6 fill-gray-60 hover:fill-gray-60/80 transition-all cursor-pointer"
          onClick={() => close()}>
          <CancelIcon />
        </span>
      </div>
      <div className="p-5 pb-6">
        <BulkUploadLocations
          handleLocationAddition={async (location: IInsightsLocation) => {
            setAssetNewLocations((prev) => [
              ...prev,
              {
                ...location,
                assets: asset ? [asset] : [],
              },
            ])
            return true
          }}
          doneCallback={(wasSuccessful) => {
            if (wasSuccessful) close()
            // else Do nothing
          }}
          hideLabels
        />
      </div>
    </EmptyModal>
  )
}

export default BulkUploadLocationsModal

// const existingLabelsByName: Record<string, ILabel> =
//     labels.reduce((acc, curr) => {
//         return { ...acc, [curr.name]: curr }
//     }, {})

// // Upload labels if required
// const newLocationLabels: string[] = []
// for (const labelName of item.labels) {
//     const existingLabel = existingLabelsByName[labelName]
//     if (existingLabel?.id) {
//         newLocationLabels.push(existingLabel.id)
//     } else {
//         const labelCreationResponse = await labelPOST({
//             account_id: selectedAccount,
//             name: labelName,
//             color: _.sample(colorsArr) || "",
//         })
//         if (!isValidResponse(labelCreationResponse)) {
//             updateStatus(ERROR)
//             continue
//         }
//
//         const newLabel = labelCreationResponse.data[0]
//         existingLabelsByName[labelName] = newLabel
//         newLocationLabels.push(newLabel.id)
//     }
// }
// TODO: CREATION WILL BE AT THE END OF THE ASSET ONBOARDING.
// const locationCreationResponse = await locationPOST(item)
// if (!isValidResponse(locationCreationResponse)) {
//     updateStatus(ERROR)
//     continue
// }
