import http, { QuerySet } from "../../climateui/utils/http"
import config from "../config"

export const accountAssetQuerySet = new QuerySet(
  "/account_asset",
  ["get", "put"],
  config.asset_platform,
)

export const assetQuerySet = new QuerySet(
  "/asset",
  ["get", "post", "put", "delete"],
  config.asset_platform,
)

export const stageRiskProfileQuerySet = new QuerySet(
  "/stage_risk_profile",
  ["get", "post", "put", "delete"],
  config.asset_platform,
)

export const varietyQuerySet = new QuerySet(
  "/variety",
  ["get", "post", "put", "delete"],
  config.asset_platform,
)

export const stageQuerySet = new QuerySet(
  "/stage",
  ["post", "put", "delete"],
  config.asset_platform,
)

const stageRiskProfilesXFields =
  "stage_risk_profiles{id,stage_id,risk_profile_id,risk_setting_ids,risk_profile}"
const stagesXFields = `stages{id,days_from_start,duration,color,name,variety_id,is_first_stage,${stageRiskProfilesXFields}}`

export const suggestionsPOST = (variety_id: string) =>
  varietyQuerySet.get(
    `suggestion/${variety_id}`,
    {},
    {},
    {
      headers: {
        "X-Fields": `{id,default_initial_date,${stagesXFields}}`,
      },
    },
  )

export const timelinePOST = (variety_id: string) =>
  varietyQuerySet.get(
    `timeline/${variety_id}`,
    {},
    {},
    {
      headers: {
        "X-Fields": `{id,default_initial_date,${stagesXFields}}`,
      },
    },
  )

export const planningToolTimelinePOST = (
  payload: { variety_id: string; location_id: string }[],
) =>
  http.post(
    "/variety/planning_timeline",
    payload,
    true,
    {
      // TODO: Add X-Fields, somehow is breaking the response
      headers: {},
    },
    config.asset_platform,
  )
